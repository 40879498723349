import React, { FC, useState } from 'react'
import { observer } from "mobx-react"
import { t } from 'ttag'
import { ITermAndMeaning, Passage, PassageGloss, PassageVideo, Project, ProjectTerm } from '../../models3/ProjectModels'
import { LexMeaning, MarbleLemma, MarbleLemmas } from '../../scrRefs/Lemmas'
import './EnhancedResources.css'
import ERTermModal, { ERPlaySign } from './ERTermModal'
import { Root, IRoot } from '../../models3/Root';
import { Modal, Table } from 'react-bootstrap';
import { CheckIcon, PasteIcon, PencilIcon, ReferencesIcon, StarIcon } from '../utils/Icons';
import { RefRange } from '../../scrRefs/RefRange'
import { ReferenceInput } from '../utils/ReferenceInput';
import { displayError, displayInfo } from '../utils/Errors';
import { CancelEditSegmentButton, DontTransliterateButton, OkEditSegmentButton, StarButton, TransliterateButton } from '../utils/Buttons'
import { IsKeyTermView } from './ERTermView'
import { fmt, s } from '../utils/Fmt'
import { IKeyTermRecording, KeyTermGlosses, KeyTermGlossRecorder } from './KeyTermGlosses'
import { GlossesViewer } from './GlossesViewer'
import _ from 'underscore'

import _debug from "debug"; let log = _debug('sltt:PassageKeyTermsViewer') 

// (ProjectTerm)
//!!! move new passage to alphabetic position

interface IPassageReferencesModal {
    rt: Root,
    passage: Passage,
    setEditing: (editing: boolean) => void
}

const PassageReferencesModal: FC<IPassageReferencesModal> = ({ rt, passage, setEditing }) => {
    let [references, setReferences] = useState(passage.references)
    let [referencesError, setReferencesError] = useState(false)

    let { project } = rt
    let portion = project.findPortion(passage._id)
    let passageName = portion?.name + ' / ' + passage.name

    function save() {
        if (!referencesError) {
            passage.setReferences(references)
                .catch(displayError)
        }
        setEditing(false)
    }

    return (
        <Modal style={{ top: '1%' }}
            bsSize="medium"
            show={true}
            onHide={() => setEditing(false)} >
            <Modal.Header closeButton> {t`Reference for Passage` + ': ' + passageName} </Modal.Header>
            <Modal.Body>
                <hr />
                <div className="passage-reference-input">
                    <ReferencesIcon
                        className="passage-references-icon"
                        tooltip={t`References for passage.`} />
                    <ReferenceInput
                        refInput={rt}
                        onEnter={save}
                        refs={references}
                        setRefs={setReferences}
                        errored={referencesError}
                        setErrored={setReferencesError}
                        includeGotoReferenceButton={false} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <OkEditSegmentButton
                    enabled={!referencesError}
                    onClick={save} />
                <CancelEditSegmentButton
                    enabled={true}
                    onClick={() => setEditing(false)} />
            </Modal.Footer>
        </Modal>
    )
}

interface IPassageReferences {
    rt: Root,
    passage: Passage,
    references: RefRange[],
    isGlossary: boolean,
}

const PassageReferences: FC<IPassageReferences> = observer(({ rt, passage, references, isGlossary }) => 
{
    let { iAmConsultant } = rt

    let [editing, setEditing] = useState(false)

    let portion = rt.project.findPortion(passage._id)
    let passageName = portion?.name + ' / ' + passage.name

    let displayableReferences = RefRange.refRangesToDisplay(references, rt.project)

    if (editing) {
        return (<PassageReferencesModal {...{rt, passage, setEditing}} />)
    }

    if (isGlossary) {
        return (
            <div className="keyterms-reference-input">
                {passageName}
            </div>
        )
    }

    return (
        <div className="keyterms-reference-input">
            {passageName}:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            (<ReferencesIcon
                className="passage-references-icon"
                tooltip={t`References for passage.`} />
            <span>{displayableReferences}</span>&nbsp;)&nbsp;&nbsp;&nbsp;
                {(iAmConsultant && references.length === 0) && 
                    <span className={references.length ? "keyterms-menu-button" : "show-keyterms-menu-button"}
                        data-toggle="tooltip"
                        data-id={'edit-' + passage.name}
                        title={t`Edit reference for passage.`}
                        onClick={() => setEditing(true)}>
                            <i className="fas fa-fw fa-pencil-alt"></i>
                    </span>
                }
        </div>
    )
})



async function pasteGlossAndVideoIntoTerm(
    rt: IRoot, term: ProjectTerm | null, meaning: LexMeaning, gloss: PassageGloss) 
{
    let { project } = rt

    log('term', s(term?.dbg()))
    log('meaining', s(meaning.dbg()))

    let { text } = gloss
    text = text.trim()

    if (!text) {
        debugger
        log('### gloss has no text')
        return
    }

    // If we have not yet created a term corresponding to this meaning (e.g. sense), create a term
    if (term === null) {
        term = project.createProjectTerm(meaning.lexicalLink)
        await project.addProjectTerm(term)
    }

    // Add gloss to target term if not already present
    if (!term.findGloss(text)) {
        let glosses = term.glosses.trim()
        if (glosses) glosses = glosses + '; '
        await term.setGlosses(glosses + text, project)
    }

    log('term after', s(term.toDocument()))

    // glossaryPassage is the passage in the Glossary portion that we will add a gloss video to
    let glossaryPassage = await ProjectTerm.getPassage(text, project)

    // glossedPassage is the passage with the gloss we will add to glossaryPassage
    let glossedPassage = project.findPassage(gloss._id)
    if (!glossedPassage) {
        log('### could not find glossed passage')
        debugger
        return
    }
    log('glossedPassage', s(glossedPassage.dbg()))

    let { name: projectName } = rt.project

    // glossedVideo is that video that owns the dropped gloss
    let glossedVideo = glossedPassage.findVideo(gloss._id)
    if (!glossedVideo) {
        log('### could not find glossed video')
        debugger
        return
    }
    log('glossedVideo', s(glossedVideo.dbg(glossedPassage)))

    let _position = gloss.position

    // If this is not the last gloss on the glossedVideo, set its endPosition
    // to the start of the next gloss.
    // Otherwise set the endPosition to the end of the glossedVideo
    let _endPosition = 0
    let i = glossedVideo.glosses.findIndex(g => g._id === gloss._id )
    if (i >= 0 && i+1 <glossedVideo.glosses.length) {
        _endPosition = glossedVideo.glosses[i+1].position
    } else {
        _endPosition = glossedVideo.duration
    }
    log('positions', fmt({position: _position, endPosition: _endPosition}))

    // If we have already added a video exactly corresponding to the gloss being dropped,
    // we can ignore this drop.
    let currentGlossaryVideo = glossaryPassage.getDefaultVideo(null)
    if (currentGlossaryVideo) {
        if (glossedVideo.url === currentGlossaryVideo.url && currentGlossaryVideo.segments.length === 1) {
            let _segment = currentGlossaryVideo.segments[0]
            if (_segment.position === _position && _segment.endPosition === _endPosition) {
                log('duplicate video drop ignored')
                return
            }
        }
    }

    // Create a video to add to the glossary passage containing just the gloss selection
    let glossaryVideo = glossaryPassage.createVideo(projectName)
    glossaryVideo.url = glossedVideo.url
    glossaryVideo.duration = _endPosition
    log('glossaryVideo raw', s(glossaryVideo.dbg(null)))

    glossaryVideo = await glossaryPassage.addVideoWithDefaultSegment(glossaryVideo)

    let glossarySegment = glossaryVideo.segments[0]
    await glossarySegment.setPositions(_position, _endPosition, glossaryVideo)
    log('glossaryVideo', s(glossaryVideo.dbg(glossaryPassage)))
}

type IPassageKeyTermView = {
    rt: IRoot,
    term: ProjectTerm | null,
    meaning: LexMeaning,
    glosses: PassageGloss[],
    setTermId: (termId: string) => void,
    signPlaying: PassageVideo | null,
    setSignPlaying: (sign: PassageVideo | null) => void,
    setRecording: (x: IKeyTermRecording | null) => void,
    setGlossShowing: (glossText: string) => void,
}

const PassageKeyTermView = observer(({ rt, term, meaning, glosses, setTermId,
    signPlaying, setSignPlaying, setRecording, setGlossShowing }: IPassageKeyTermView) => {

    let [selected, setSelected] = useState(0)
    let [drops, setDrops] = useState(0)

    let sourceGlosses = meaning.glosses(rt.uiLanguage)
    let senseName = meaning.senseName(rt.transliterateLemmas)

    //!!! search glosses and set this
    let numberTimesUsed = 0

    function onDragEnter(e: React.DragEvent<HTMLTableRowElement>) {
        log('dragEnter', e.target)
        e.preventDefault()
        e.stopPropagation()

        setSelected(selected => selected+1)
    }

    function onDragLeave(e: React.DragEvent<HTMLTableRowElement>) {
        log('dragLeave')
        e.preventDefault()
        e.stopPropagation()

        setSelected(selected => selected - 1)
    }

    function onDragOver(e: React.DragEvent<HTMLTableRowElement>) {
        log('dragOver', e.target)
        // The default action for dragOver is "Reset the current drag operation to none".
        // So unless you cancel it the drop doesn't work.
        e.preventDefault()
        e.stopPropagation()
    }

    function onDrop(e: React.DragEvent<HTMLTableRowElement>) {
        e.preventDefault()
        e.stopPropagation()

        let _id = e.dataTransfer.getData('text/plain')
        log('onDrop', fmt({_id}))
        let gloss = glosses.find(g => g._id === _id)
        log('onDrop', s(gloss?.dbg()))

        if (!gloss) {
            displayError('###could not find gloss')
            return
        }

        pasteGlossAndVideoIntoTerm(rt, term, meaning, gloss)
            .then(() => setDrops(_drops => _drops + 1)) // force re-render
            .catch(displayError)

        setSelected(0)
    }

    let className = 'keyterms-row' + (selected > 0 ? ' keyterms-row-selected' : '')

    return (
        <tr key={meaning.id} {...{ className, onDragEnter, onDragLeave, onDragOver, onDrop }}>
            <td><IsKeyTermView {...{ rt, term, meaning }} /></td>
            <td>{numberTimesUsed > 0 && <CheckIcon className='er-term-found' tooltip='' />}</td>
            <td>
                <span className='passage-key-term-link' onClick={() => setTermId(meaning.lexicalLink)}>
                    {senseName}
                </span>
            </td>
            <td>
                <KeyTermGlosses 
                    {...{ rt, term, meaning, signPlaying, setRecording, setSignPlaying, drops, setGlossShowing }} />
            </td>
            <td className='passage-key-term-source-glosses'>{sourceGlosses}</td>
        </tr>
    )
})

interface IPassageKeyTermsToolbar {
    rt: Root,
    passage: Passage,
    references: RefRange[],
    isGlossary: boolean,
}

const PassageKeyTermsToolbar = observer(({ rt, passage, references, isGlossary }: IPassageKeyTermsToolbar) => 
{
    return (
        <div className="keyterms-references-toolbar">
            <PassageReferences {...{rt, references, passage, isGlossary}} />
            <div>
                <div className="keyterms-transliterate">
                    {rt.transliterateLemmas &&
                        <DontTransliterateButton
                            buttonClassName=''
                            className='transliterate-icon'
                            onClick={() => rt.setTransliterateLemmas(false)}
                            tooltip={t`Click to show Greek or Hebrew letters`}
                            enabled={true} />}
                    {!rt.transliterateLemmas &&
                        <TransliterateButton
                            buttonClassName=''
                            className='transliterate-icon'
                            onClick={() => rt.setTransliterateLemmas(true)}
                            tooltip={t`Click to show transliteration`}
                            enabled={true} />}
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="keyterms-keytermsonly">
                    {rt.keytermsOnly && 
                        <StarButton
                            isHollow={false}
                            className='keyterms-star-icon'
                            onClick={() => rt.setKeytermsOnly(false)}
                            tooltip={t`Click to show all terms`}  
                            enabled={true} />}
                    {!rt.keytermsOnly &&
                        <StarButton
                            isHollow={true}
                            className='keyterms-star-icon'
                            onClick={() => rt.setKeytermsOnly(true)}
                            tooltip={t`Click to show only key terms`}
                            enabled={true} />}
                </div>
            </div>
        </div>
    )
})

// Find all the project terms that have the specified gloss
function getEntriesWithGloss(project: Project, gloss: string) {
    let entries: ITermAndMeaning[] = []

    for (let term of project.terms) {
        if (term.findGloss(gloss)) {
            let meaning = MarbleLemmas.meaningsDict.get(term.lexicalLink)
            if (meaning) {
                entries.push({ term, meaning })
            }
        }
    }

    return entries
}

type PassageKeyTermsViewerProps = {
    rt: Root,
    passage: Passage | null,
    passageVideo: PassageVideo | null,
}

const PassageKeyTermsViewer = observer(({ rt, passage, passageVideo } :PassageKeyTermsViewerProps) => 
{
    let { project, keytermsOnly } = rt

    const [termId, setTermId] = useState('')
    let [transliterate, setTransliterate] = useState(true)
    let [signPlaying, setSignPlaying] = useState<PassageVideo | null>(null)
    let [recording, setRecording] = useState<IKeyTermRecording | null>(null)
    let [glossShowing, setGlossShowing] = useState('')

    if (!passage) {
        return (<div>{t`No passage selected.`}</div>)
    }

    if (signPlaying) {
        return (<ERPlaySign {...{ signPlaying, setSignPlaying }} />)
    }

    if (glossShowing) {
        return (<GlossesViewer {...{rt, glossShowing, setGlossShowing}} />)
    }

    // Fetch glosses present so that we can show which terms have been glossed in passage
    let glosses: PassageGloss[] = []
    if (passage && passageVideo) {
        glosses = passageVideo.getVisibleGlosses(passage)
    }

    let passageReferences = passage.references
    let dbsReferences: RefRange[] = []

    // don't user dbsRefs if no video, it is left over from some other video.
    // NEEED BETTER WAY TO DEAL WITH THIS!!!
    if (passage.videos.length) {
        dbsReferences = rt.dbsRefs
    }
    let references = dbsReferences.length ? dbsReferences : passageReferences
    
    let portion = project.findPortion(passage._id)
    let isGlossary = (portion !== undefined) && portion?.isGlossary

    let entries: ITermAndMeaning[] = []

    if (isGlossary) {
        entries = getEntriesWithGloss(project, passage.name)
    } else {
        entries = project.getTermsAndMeaningsByRef(references, keytermsOnly)
    }

    // Sort terms with glosses before terms without glosses (use gloss as sort key).
    // Otherwise sort based on the glosses from MARBLE.
    function entrySortKey(entry: ITermAndMeaning) {
        let { term, meaning } = entry
        let meaningGloss = meaning.glosses(rt.uiLanguage).toLowerCase()
        if (term && term.glosses) return '!' + term.glosses.toLowerCase() + '!' + meaningGloss
        return meaningGloss
    }

    entries = _.sortBy(entries, entrySortKey)
    
    if (recording) {
        return (
            <KeyTermGlossRecorder {...{ rt, recording, setRecording }} />
        )
    }

    if (termId) {
        return (<ERTermModal {...{rt, termId, setTermId}} />)
    }

    return (
        <div className="keyterms-body">
            <PassageKeyTermsToolbar {...{ rt, references, passage, transliterate, setTransliterate, isGlossary }} />
            { !isGlossary && references.length === 0 && 
                <p>{`Click the pencil icon to set the reference for this passage.`}</p>}
            {!isGlossary && references.length > 0 && entries.length === 0 &&
                <p>{`This passage has no keyterms. Click star to see all terms.`}</p>}
            { entries.length > 0 && (
                <Table>
                    <thead className="keyterms-thead">
                        <tr>
                            <th></th>
                            <th></th>
                            <th className="keyterms-lemma">{t`Lemma`}</th>
                            <th className="keyterms-gloss">{t`Gloss`}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {entries.map(entry => (<PassageKeyTermView {...{ 
                            key: entry.meaning.lexicalLink,
                            rt, 
                            term: entry.term, 
                            meaning: entry.meaning, 
                            glosses,
                            setTermId, 
                            transliterate,
                            signPlaying, 
                            setSignPlaying,
                            setRecording,
                            setGlossShowing }} />))}
                    </tbody>
                </Table>
            )}
        </div>
    )
})

export default PassageKeyTermsViewer