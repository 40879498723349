import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import { EventEmitter } from 'events'


import _debug from "debug"; const log = _debug('sltt:TRLVideoPlayer')

const getIsVideoPlaying = (video: HTMLVideoElement) => !!(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
interface TRLVideoPlayerProps {
    videoEventEmitter: EventEmitter
    videoUrl: string
    rootBaseCurrentTime: number
    setRootBaseCurrentTime: (time: number) => void
    selectionPresent: () => boolean
    selectionStartTime: number
    selectionEndTime: number
    onTimeUpdate: (time: number, isPlaying: boolean) => void
}
export const TRLVideoPlayer: FC<TRLVideoPlayerProps> = observer(({
    videoEventEmitter, videoUrl, rootBaseCurrentTime, setRootBaseCurrentTime, selectionPresent, selectionStartTime, selectionEndTime, onTimeUpdate }
) => {
    const videoRef = React.createRef<HTMLVideoElement>()

    const { addListener, removeListener } = videoEventEmitter

    useEffect(() => {
        addListener('play', play)
        addListener('pause', pause)
        // addListener('stop', stopAndReset)
        // addListener('playAll', playAll)
        addListener('setCurrentTime', onSetCurrentTime)
        // addListener('setPassageVideo', setPassageVideo)
        // addListener('onEnded', onEnded)
        return () => {
            removeListener('play', play)
            removeListener('pause', pause)
            // removeListener('stop', stopAndReset)
            // removeListener('playAll', playAll)
            removeListener('setCurrentTime', onSetCurrentTime)
            // removeListener('setPassageVideo', setPassageVideo)
            // removeListener('onEnded', onEnded)
        }
    })

    // adjust to any changes in rootBaseCurrent time
    // ignore if we're playing, to prevent feedback loop
    useEffect(() => {
        const video = getVideoPlayer()
        if (!video) return
        const isPlaying = getIsVideoPlaying(video)
        if (!isPlaying) {
            video.currentTime = rootBaseCurrentTime
        }
        // console.log('uef currentTime', {currentTime: video.currentTime})
    }, [rootBaseCurrentTime])

    // onSetCurrenTime comes from external controls
    const onSetCurrentTime = (time: number) => {
        const video = getVideoPlayer()
        if (!video) return
        const isPlaying = getIsVideoPlaying(video)
        log('onSetCurrentTime', { time, isPlaying })
        updateCurrentTimeCommon(time)
        // if we're playing then stop
        if (isPlaying) {
            log('pausing video', { time })
            video.pause()
        }
        video.currentTime = time
    }

    const updateCurrentTimeCommon = (time: number) => {
        const video = getVideoPlayer()
        if (!video) return
        const isPlaying = getIsVideoPlaying(video)
        log('updateCurrentTimeCommon', { video, isPlaying, time, selectionStartTime, selectionEndTime })
        onTimeUpdate(time, isPlaying)
        if (isPlaying && selectionStartTime >= 0 && time >= selectionEndTime) {
            log('pausing video', { time, selectionStartTime, selectionEndTime })
            video.pause()
        }
    }

    // time updates from our own video
    const handleOnVideoElementTimeUpdate = (time: number) => {
        setRootBaseCurrentTime(time)
        updateCurrentTimeCommon(time)
    }

    const getVideoPlayer = () => videoRef?.current

    const play = (startTime?: number, endTime?: number, resetTime?: number) => {

        const newStartTime = startTime || selectionPresent() && selectionStartTime || rootBaseCurrentTime
        
        const vp = getVideoPlayer()
        if (!vp) return
        vp.currentTime = newStartTime
        vp.play()
    }

    const pause = () => {
        const vp = getVideoPlayer()
        vp?.pause()
    }

    return (<video
        className="trl-video-player-video-video"
        ref={videoRef}
        src={videoUrl}
        onTimeUpdate={() => handleOnVideoElementTimeUpdate(videoRef.current!.currentTime)}
    >
    </video>)
})
