// Controls for fine positon adjustment of main video. Controls for zooming timeline.

import React, { Component, FC, useContext, useState } from 'react'
import {observer} from 'mobx-react'

import { AdjustCurrentTimeButtons, ZoomInTimelineButton, ZoomOutTimelineButton } from '../utils/Buttons'
import { Passage, PassageSegmentApproval, PassageVideo, ProjectPlan } from '../../models3/ProjectModels'
import { RefRange } from '../../scrRefs/RefRange'
import { NoteSelector } from '../notes/NoteSelector'
import { NotesSelectorDropdown } from '../notes/NotesSelectorDropdown'
import { overallApprovalState } from '../passages/PassageView'
import { StatusApproval } from '../segments/SegmentToolbar'
import { displayError } from '../utils/Errors'
import { RootContext } from '../app/RootContext'
import { fmt } from '../utils/Fmt'
import TimeInput from './TimeInput'
import { IVideoPosition } from './VideoPositionBar'
import { TaskSelectorDropdown } from '../status/TaskSelectorDropdown'

import _debug from "debug"; let log = _debug('sltt:VideoPositionBarControls')

/* 
 * Display position in form hh:mm:ss.sss (omit hh: if less than 1 hour)
 */
export function secondsToHms(seconds: number) {
    const h = Math.floor(seconds / 3600)
    let hh = h > 0 ? `${h}:` : ''

    const mm = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0')
    const ss = (seconds % 60).toFixed(3).padStart(6, '0')
    return `${hh}${mm}:${ss}`
}

interface IPassageStatusEditor {
    passage: Passage,
}

const PassageStatusEditor: FC<IPassageStatusEditor> = ({ passage }) => {
    const video = passage.getDefaultVideo(null)

    // Get the segments being displayed taking into account patching
    const segments = video?.visibleSegments(passage) || []

    const segmentApprovals = segments.map(segment => segment.approved)
    const [status, _setStatus] = useState(overallApprovalState(segmentApprovals))

    const rt = useContext(RootContext)

    if (!rt || segments.length === 0) return null // happens if not video present for passage

    function setStatus(newStatus: PassageSegmentApproval ) {
        log('setStatus', fmt({newStatus}))
        for (const segment of segments) {
            segment.setApproved(newStatus, rt?.username ?? '').catch(displayError)
        }
        _setStatus(newStatus)
    }

    const approvalDate = segments[0].approvalDate
    const approvedBy = segments[0].approvedBy

    return (
        <div className="passage-status-dropdown">
            <StatusApproval {...{ status, setStatus, approvalDate, approvedBy, enabled: true }} />
        </div>
    )
}

export interface IVideoPositionBarControls {
    videoPositionControls: IVideoPositionControls,
    videoPosition: IVideoPosition,
    plan?: ProjectPlan,
}

export interface IVideoPositionControls {
    currentTime: number,
    adjustCurrentTime: (delta: number) => void,
    timelineZoom: number,
    setTimelineZoom: (zoom: number) => void,
    adjustTimelineZoomLimits: (forceAdjustment: boolean) => void,
    useMobileLayout: boolean,
    passage: Passage | null,
    passageVideo: PassageVideo | null,
    canPlayThrough: boolean,
    dbsRefs: RefRange[],
    displayableReferences: (refs: RefRange[]) => string,
    noteSelector?: NoteSelector,
    setNoteSelector?: (noteSelector: NoteSelector) => void,
    noteColors?: string[],
    iAmConsultant: boolean,
}

class VideoPositionBarControls extends Component<IVideoPositionBarControls> {
    render() {
        let { videoPositionControls, videoPosition, plan } = this.props
        let { currentTime, timelineZoom, useMobileLayout, adjustCurrentTime, passage,
            canPlayThrough, passageVideo, dbsRefs, displayableReferences,
            noteSelector, setNoteSelector, noteColors, iAmConsultant } = videoPositionControls

        // eslint-disable-next-line
        passageVideo && passageVideo._rev // for render on any passage video changes
        // eslint-disable-next-line
        passage && passage._rev // for render on any passage changes

        // Check to see if segment time have changed and if so reset them
        passage && passageVideo && passageVideo.setSegmentTimes(passage)

        let enabled = !passage?.videoBeingCompressed
        let adjustTimeEnabled = canPlayThrough && enabled
        let zoomInEnabled = timelineZoom < 64 && enabled
        let zoomOutEnabled = timelineZoom > 1 && enabled

        let refs = displayableReferences(dbsRefs)
        let displayedRefs = refs.trim() ? `(${refs})` : ''

        const taskSelectorEnabled = passageVideo !== null 
            && passage !== null
            && !!passage?.getLatestVideo()?._id
            && iAmConsultant

        return (
            <div data-id='video-positionbar-controls'>
                <div className='sl-adjust-current-time'>
                    <div className="video-timecode">
                        <TimeInput {...{ currentTime, videoPosition }} /> 
                        {displayedRefs}
                        { plan && passage && <TaskSelectorDropdown {...{
                            passage,
                            video: passage?.getLatestVideo(),
                            plan, 
                            enabled: taskSelectorEnabled,
                        }} />}
                    </div>
                    {!useMobileLayout && (
                        <AdjustCurrentTimeButtons
                            enabled={adjustTimeEnabled}
                            adjustCurrentTime={adjustCurrentTime}
                            includeSeconds={true} />
                    )}
                    <div className="placeholder">
                        {   (noteSelector!== undefined && setNoteSelector !== undefined && noteColors !== undefined) &&
                            <NotesSelectorDropdown {...{noteSelector, setNoteSelector, noteColors}} />
                        }
                        <div style={ {marginLeft: 'auto'} }>
                            {passage && <PassageStatusEditor {...{passage}} />}
                            <ZoomInTimelineButton enabled={zoomInEnabled} onClick={this.zoomInTimeline.bind(this)} />
                            <ZoomOutTimelineButton enabled={zoomOutEnabled} onClick={this.zoomOutTimeline.bind(this)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    zoomInTimeline() {
        let { timelineZoom, setTimelineZoom, adjustTimelineZoomLimits } = this.props.videoPositionControls
        setTimelineZoom(timelineZoom * 2)
        adjustTimelineZoomLimits(true)
    }

    zoomOutTimeline() {
        let { timelineZoom, setTimelineZoom, adjustTimelineZoomLimits } = this.props.videoPositionControls
        setTimelineZoom(timelineZoom / 2)
        adjustTimelineZoomLimits(true)
    }
}

export default observer(VideoPositionBarControls)
