import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import { PaneCloseButton } from './Buttons'
import { t } from 'ttag'

import VideoDownloading from '../utils/VideoDownloading'
import InputSlider from 'react-input-slider'
import { RootConsumer } from '../app/RootContext'
import Vimeo from '@u-wave/react-vimeo'
import { onLoad } from '@sentry/browser'
import { LoadingIcon } from '../utils/Icons'

import _debug from "debug"; let log = _debug('sltt:Player') 

interface IPlayer {
    videoUrl: string,
    onEnded: () => void,
    onLoaded?: () => void, // called when video is ready to play
    className?: string,
    autoClose?: boolean,
    startTime?: number,
    stopTime?: number,
    height?: number, // height in pixels, AFAICT only way to contol height of vimeo player
}

const Player: FC<IPlayer> = ({videoUrl, onEnded, className, autoClose, startTime, stopTime, height}) => {

    if (videoUrl.includes('vimeo')) {
        return <VimeoVideoPlayer {...{videoUrl, onEnded, className, autoClose, startTime, stopTime, height}} />
    }

    return <CachedVideoPlayer {...{ videoUrl, onEnded, className, autoClose, startTime, stopTime }} />
}

// videoUrl example = 'https://vimeo.com/714120159'
export const VimeoVideoPlayer: FC<IPlayer> = observer(({ videoUrl, onEnded, className, 
    autoClose, startTime, stopTime, height, onLoaded }) => {
    
    const [vimeoError, setVimeoError] = useState('')
    const [loaded, setLoaded] = useState(false)
    // Long reloading comment (purpose):
    // After the player loads, sometimes changing the url can cause an error
    // that shows up inside a black player box as a message like:
    // "Sorry This video does not exist."
    // Unfortunately, that error message does not trigger onError(), which leaves us
    // in a weird state where we can see the Error, and the loading icon at the same time
    // which is what we want to avoid.
    // To work around this, we set a reloading flag to force the player to unmount and remount
    // so that any error that happens during the loading process will trigger onError. 
    const [reloading, setReloading] = useState(false)
    
    useEffect(() => { 
        setVimeoError('') 
        setLoaded(false)
        if (onLoaded) {
            // unmount the video player (see long reloading comment above)
            setReloading(true)
        }
    }, [videoUrl])

    useEffect(() => {
        if (reloading) {
            // remount the video player (see long reloading comment above)
            setReloading(false)
        }
    }, [reloading])

    if (!videoUrl) return (<div></div>)
    if (vimeoError) return (<div>{vimeoError}</div>)

    const getLoadingAnimation = () => {
        return (
            <div>
                <LoadingIcon className="loading-vimeo" />
                <span>{t`Loading...`}</span>
            </div>
        )
    }
    return (<>
        {!loaded && onLoaded && getLoadingAnimation()}
        {!reloading && <Vimeo className={className ?? ''}
            // speed={true}
            height={height ?? 400}
            video={videoUrl}
            showTitle={false}
            showByline={false}
            showPortrait={false}
            // onReady={() => { log('!!! onReady') }}
            onLoaded={() => { onLoaded && onLoaded(); setLoaded(true) }} // called when video is ready to play
            onError={(error) => { setVimeoError(error.message) }} />
        }
    </>)
})

@observer
class CachedVideoPlayer extends React.Component<IPlayer> {
    @observable private videoBlob: Blob | null = null
    @observable private error = ''
    @observable playbackRate = 1.0
    @observable src = ''
    firstUpdate = true
    
    private vc: HTMLVideoElement | null = null

    constructor(props: IPlayer) {
        super(props)
        makeObservable(this);
        let { className } = this.props
        let rateString = localStorage.getItem(`videoPlaybackRate${className}`)
        let rate = rateString ? parseFloat(rateString) : 1.0
        this.playbackRate = rate

        this.onTimeUpdate = this.onTimeUpdate.bind(this)
    }

    startTime() {
        let { startTime } = this.props
        return (startTime === undefined) ? .2 : startTime
    }

    componentDidUpdate() {
        let { vc } = this
        if (!vc) return

        // WARNING - hacky code!
        // Users want to be able to click into the player timeline to
        // go to a particular position. Sadly if we just start up a video
        // the timeline is disabled. I suspect this has to do with how
        // the video is encoded by the Chrome webcam.
        //
        // What to do? It appears that if we first seek out to the end
        // of the video the timeline becomes enabled. We can then
        // see back and play it. Sigh.
        if (this.firstUpdate) {
            vc.currentTime = 10000  // seek to end
            setTimeout(() => { 
                vc!.currentTime = this.startTime() // back to origin
                vc!.play()
            }, 200)
        }

        vc.playbackRate = this.playbackRate

        this.firstUpdate = false
    }

    render() {
        let { videoUrl, onEnded, className } = this.props
        let { videoBlob, error, playbackRate, src } = this

        if (error) return (<div>{t`Video download failed!`}</div>)

        let roundedPlaybackRate = playbackRate.toFixed(1)
        let tooltip = /* translator: important */ t`Speed = ${roundedPlaybackRate}`

        return (
            <div className={className}>
                {videoBlob ? (
                    <div className={className + '-video-area'}>
                        <div className={className + '-video-content'}>
                            <video 
                                className={className + '-video'}
                                controls
                                src={src}
                                ref={vc => this.vc = vc}
                                autoPlay
                                onTimeUpdate={this.onTimeUpdate}
                                onPause={() => console.log('done')}
                            />
                        </div>
                        <div className='video-player-controls'>
                            <div className="video-player-slider">
                                <InputSlider
                                    className="slider video-rate-input-slider"
                                    slidertooltip={tooltip}
                                    axis="y"
                                    y={2.0 - playbackRate}
                                    ymax={2}
                                    ystep={0.1}
                                    onChange={this.rateChange}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <VideoDownloading
                        className={className + '-video-placeholder'}
                        videoUrl={videoUrl}
                        creator=''
                        fontSizePt={28}
                        onEnded={(blob: Blob) => {
                            this.videoBlob = blob
                            this.src = window.URL.createObjectURL(blob)
                        }}
                    />
                )}
                <div className={className + '-close-button'}>
                    <PaneCloseButton 
                        onClick={() => onEnded()}
                        enabled={true}
                        tooltip={t`Close pane`}
                        className='sl-pane-close-button' />
                </div>
            </div> 
        )
    }

    onTimeUpdate() {
        // https://thewebdev.info/2021/01/31/create-a-video-player-with-react-and-javascript/
        let { vc } = this
        if (!vc) return

        let { stopTime } = this.props
        if (stopTime === undefined) return

        if (vc.currentTime > stopTime) {
            vc.pause()
            vc.currentTime = this.startTime()
        }
    }

    rateChange = (pos: any) => {
        let { vc } = this
        if (vc === null) return

        vc.pause()

        let rate = 2.0 - pos.y
        rate = rate > 0.1 ? rate : 0.1

        let { className } = this.props
        className = className || ''
        localStorage.setItem(`videoPlaybackRate${className}`, rate.toString())
        this.playbackRate = rate
        vc.playbackRate = rate
    }
}

export default Player