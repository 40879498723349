import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import '../../utils/Buttons.css'
import './TRLPanel.css'
import { TRLPassage, TRLPortion, TRLResource } from '../../../models3/TRLModel'
import { Switch } from '../../utils/Switch'
import { LoadingIcon, TitleIcon } from '../../utils/Icons'
import { t } from 'ttag'
import { getLicenseName } from '../../utils/TRL'
import { CancelButton } from '../../utils/Buttons'
import { PassageBrowseItem, TRLPassageWithPreloadedUrl } from './PassageBrowseItem'
import { PortionBrowseItem } from './PortionBrowseItem'
import ReactTooltip from 'react-tooltip'

import _debug from "debug"; const log = _debug('sltt:TRLPanel')

const TRL_PANEL_BROWSE_SELECTION_LOCAL_STORAGE_KEY = 'trl-panel-browse-selection'

interface INavBackStep {
    stepName: string,
    goBack: () => void
}

interface ITRLPanel {
    trlResources: TRLResource[]
    trlPortion?: TRLPortion
}

// create functional component
export const TRLPanel: FC<ITRLPanel> = observer(({ trlResources, trlPortion }) => {
    
    const [showTitles, setShowTitles] = useState(true)
    const [selectedResource, setSelectedResource] = useState<TRLResource | undefined>(trlPortion ? trlResources[0] : undefined)
    const [selectedPortion, setSelectedPortion] = useState<TRLPortion | undefined>(trlPortion)
    const [selectedPassage, setSelectedPassage] = useState<TRLPassage>()
    const [portionPlaying, setPortionPlaying] = useState(false)
    const [playing, setPlaying] = useState(false)
    const [restoringSelection, setRestoringSelection] = useState(true)

    useEffect(() => {
        log('restoringSelection', restoringSelection)
        try {
            // restore selection on mount
            // but not if finished restoring or already has selectedResource
            if (!restoringSelection || selectedResource) return
            restoreSelection()
        } finally {
            setRestoringSelection(false)
        }
    }, [restoringSelection])

    useEffect(() => {
        if (restoringSelection)
            return
        // store selection on selection changes
        storeSelection()
    }, [selectedResource, selectedPortion, selectedPassage, restoringSelection])


    const storeSelection = () => {
        log('storeSelection')
        const serializedSelection = serializeSelection()
        localStorage.setItem(
            TRL_PANEL_BROWSE_SELECTION_LOCAL_STORAGE_KEY,
            serializedSelection
        )
    }

    const _restoreSelection = (selection: ITRLBrowseSelection) => {
        log('_restoreSelection 1', { selection })
        const resource = trlResources.find(r => r.resourceName === selection.resourceName)
        setSelectedResource(resource)
        
        const portion = resource?.portions.find(p => p.portion_id === selection.portionId)
        setSelectedPortion(portion)

        // Sigh. restoring passage is a little complicated
        // originally when the user had selected the passage it opened the video
        // to start playing it. If they switch context (passage/portion/passage/browser refresh)
        // we lose the state of the video that was playing.
        //
        // For now, restore filtered by the selected passage until we can save/restore currentTime (paused)
        //
        const passage = portion?.passages.find(p => p.videoUrl === selection.passageVideoUrl)
        setSelectedPassage(passage)
        setPlaying(false)
        log('_restoreSelection 2', { selection, resource, portion, passage })
    }

    const restoreSelection = () => {
        log('restoreSelection')
        const serializedSelection = localStorage.getItem(TRL_PANEL_BROWSE_SELECTION_LOCAL_STORAGE_KEY)
        if (!serializedSelection) return

        // TODO: zod validation?
        const selection: ITRLBrowseSelection = JSON.parse(serializedSelection)
        log('restoreSelection', { selection })
        _restoreSelection(selection)
    }

    const serializeSelection = () => {
        return createSerializedSelection(selectedResource, selectedPortion, selectedPassage)
    }

    const onResourceClick = (resource: TRLResource|undefined) => {
        setPortionPlaying(false)
        setSelectedResource(resource)
        onPortionClick(undefined)
    }

    const onPortionClick = (portion: TRLPortion|undefined) => {
        log('onPortionClick', { portion })
        setPortionPlaying(false)
        setSelectedPortion(portion)
        onPassageClick(undefined, undefined)
    }

    const onPassageClick = (e: React.MouseEvent|React.KeyboardEvent|undefined, passage: TRLPassage|undefined) => {
        if (e && e.type === 'keydown' && !['Enter', ' '].includes((e as React.KeyboardEvent).key)) {
            return
        }
        setPortionPlaying(false)
        setSelectedPassage(passage)
        setPlaying(passage !== undefined)
        e?.stopPropagation()
        e?.preventDefault()
    }

    const getBackNavigationStep = (): INavBackStep => {
        log('getBackNavigationStep', {
            selectedPassage, selectedPortion, selectedResource
        })
        if (selectedPassage) {
            return {
                stepName: 'passages',
                goBack: goBackToPassages
            }
        }
        if (selectedPortion) {
            return {
                stepName: 'portions',
                goBack: goBackToPortions
            }
        }
        return {
            stepName: 'resources',
            goBack: goBackToResources
        }
    }

    const goBackToPassages = () => onPortionClick(selectedPortion)
    const goBackToPortions = () => onResourceClick(selectedResource)
    const goBackToResources = () => onResourceClick(undefined)

    const handleNavigationBackButton = () => {
        const enabled = !!selectedResource
        log('handleNavigationBackButton 1', { enabled })
        if (!enabled) return
        const backStep = getBackNavigationStep()
        log('handleNavigationBackButton 2', { backStep })
        backStep.goBack()
    }

    const getBackNavigationTitle = () => {
        if (!selectedResource) {
            return t`Back navigation`
        }
        const navStep = getBackNavigationStep()
        const title = t`Back to ` + navStep.stepName 
        return title
    }

    const navigationBackButton = () => {
        return <div
            tabIndex={0}
            role="button"
            className="trl-navigation-back-button fa fa-fw fa-solid fa-chevron-left"
            onClick={handleNavigationBackButton}
            title={getBackNavigationTitle()}
        />
    }

    return <div className="trl-browse-panel">
        <div className="trl-browse-panel-toolbar">
            <div className="trl-browse-panel-toolbar-left">
                {navigationBackButton()}
            </div>
            <div className="trl-browse-panel-toolbar-right">
                <label className="trl-display-control">
                    <div className="trl-title-switch">
                        <Switch
                            className="trl-title-switch"
                            value={showTitles}
                            setValue={setShowTitles}
                            enabled={true}
                            tooltip={t`Show titles`}>
                            <TitleIcon className="trl-title-tag" tooltip={undefined} />
                        </Switch>
                    </div>
                </label>
            </div>
        </div>
        <div className="trl-area-cards">
            {restoringSelection && <div className="trl-loading-indicator"><LoadingIcon className='' /></div>}
            {selectedResource && selectedPortion && <>
                <TRLPassages
                    selectedPortion={selectedPortion}
                    selectedPassage={selectedPassage}
                    showTitles={showTitles}
                    selectedResource={selectedResource}
                    playing={playing}
                    onPassageClick={onPassageClick}
                />
                <hr />
            </>}
            {selectedResource && <>
                <TRLPortions
                    selectedResource={selectedResource}
                    selectedPortion={selectedPortion}
                    showTitles={showTitles}
                    portionPlaying={portionPlaying}
                    onPortionClick={onPortionClick}
                    onPortionPlaying={setPortionPlaying}
                />
                <hr />
            </>}
            <TRLResources
                trlResources={trlResources}
                selectedResource={selectedResource}
                showTitles={showTitles}
                onLogoClick={onResourceClick}
            />
            <hr />
        </div>
    </div>
})

interface ITRLResources {
    trlResources: TRLResource[]
    selectedResource: TRLResource | undefined
    showTitles: boolean
    onLogoClick: (resource: TRLResource | undefined) => void
}

const TRLResources: FC<ITRLResources> = ({
    trlResources,
    selectedResource,
    showTitles,
    onLogoClick,
}) => {
    const notVisibleMessage = (<div className="trl-unpublished-resource">Resource is not yet visible to other projects. Contact Caio to make it visible.</div>)
    return (
        <div className="trl-area-grid">
            {trlResources
                .filter(r => !selectedResource || r.resourceName === selectedResource.resourceName)
                .map(resource => (
                    <div
                        key={resource.resourceName}
                        className={`toggle-selection selected-${!!selectedResource} `}
                    >
                        <div onClick={() => onLogoClick(resource)}>
                            <div data-tip data-for={getToolTipId(resource.resourceName)} data-place={'bottom'}
                                    className={resource.published ? 'trl-published-resource' : 'trl-unpublished-resource'}>
                                <input
                                    type="image"
                                    className="image-preview-autosize"
                                    src={resource.logoUrl}
                                />
                                {showTitles && 
                                    <div>
                                        <CopyrightAndLicense resource={resource} />
                                        {!resource.published && notVisibleMessage}
                                    </div>
                                }
                                {!showTitles && <ReactTooltip id={getToolTipId(resource.resourceName)}
                                    backgroundColor="gray"
                                    effect="solid"
                                    delayShow={500}
                                    delayHide={500}
                                    clickable={false} >
                                    <CopyrightAndLicense resource={resource} />
                                    {!resource.published && notVisibleMessage}
                                </ReactTooltip>}
                            </div>
                        </div>
                        <CancelButton
                            enabled={true}
                            onClick={() => onLogoClick(undefined)}
                            className="cancel-button"
                            tooltip={t`Cancel`}
                        />
                    </div>
                ))}
        </div>
    )
}

function getToolTipId(resourceName: string) {
    return `tooltip-${resourceName}`
}

interface ICopyrightAndLicense {
    resource: TRLResource
}

const CopyrightAndLicense: FC<ICopyrightAndLicense> = ({ resource }) => {
    return <div><div>{resource.copyright}</div><div>License: {getLicenseName(resource.license)}</div></div>
}

interface ITRLPortions {
    selectedResource: TRLResource
    selectedPortion: TRLPortion | undefined
    showTitles: boolean
    portionPlaying: boolean
    onPortionClick: (portion: TRLPortion | undefined) => void
    onPortionPlaying: (playing: boolean) => void
}

const TRLPortions: FC<ITRLPortions> = ({
    selectedResource,
    selectedPortion,
    showTitles,
    portionPlaying,
    onPortionClick,
    onPortionPlaying
}) => {
    return (
        <div className="trl-area-grid">
            {selectedResource.portions
                .filter(p => !selectedPortion || p.portion_id === selectedPortion.portion_id)
                .map(portion => (
                    <PortionBrowseItem
                        key={portion.portion_id}
                        portion={portion}
                        selectedPortion={selectedPortion}
                        showTitles={showTitles}
                        portionPlaying={portionPlaying}
                        onPortionClick={onPortionClick}
                        onPortionPlaying={onPortionPlaying}
                        selectedResource={selectedResource}
                    />
                ))}
        </div>
    )
}

interface ITRLPassages {
    selectedPortion: TRLPortion
    selectedPassage?: TRLPassage | TRLPassageWithPreloadedUrl
    showTitles: boolean
    selectedResource: TRLResource
    playing: boolean
    onPassageClick: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement> | undefined, passage: TRLPassage | undefined) => void
}

export const TRLPassages: FC<ITRLPassages> = ({
    selectedPortion,
    selectedPassage,
    showTitles,
    selectedResource,
    playing,
    onPassageClick
}) => {
    return (
        <div {...(!playing && { className: "trl-area-grid"})}>
            {selectedPortion.passages
                .filter(
                    (p) =>
                        (!(selectedPortion.thumbnail?.srcUrl === p.videoUrl)) &&
                        (!selectedPassage || p.videoUrl === selectedPassage.videoUrl)
                )
                .map((passage) => (
                    <PassageBrowseItem key={passage.videoUrl}
                        passage={passage}
                        selectedPassage={selectedPassage}
                        showTitles={showTitles}
                        selectedResource={selectedResource}
                        playing={playing}
                        onPassageClick={onPassageClick}
                    />
                ))}
        </div>
    )
}

interface ITRLBrowseSelection {
    resourceName: string|undefined
    portionId: string|undefined
    passageVideoUrl: string|undefined
}

function createBrowseSelection(selectedResource: TRLResource | undefined, selectedPortion: TRLPortion | undefined, selectedPassage: TRLPassage | undefined) {
    const selection: ITRLBrowseSelection = {
        resourceName: selectedResource?.resourceName,
        portionId: selectedPortion?.portion_id,
        passageVideoUrl: selectedPassage?.videoUrl
    }
    return selection
}

function createSerializedSelection(
    resource: TRLResource|undefined, portion: TRLPortion|undefined, passage: TRLPassage|undefined
) {
    const selection = createBrowseSelection(resource, portion, passage)
    return JSON.stringify(selection)
}
