export const videoTooltipIds = ["IS/ASL a1|SLTTLoc/220707_214913/220707_214925/221229_212145-1",
"IS/ASL a2|SLTTLoc/220707_214913/221229_222855/221229_222856-1",
"IS/ASL a3|SLTTLoc/220707_214913/220707_214933/221229_222920-1",
"IS/ASL a4|SLTTLoc/220707_214913/220707_214937/221229_223460-1",
"IS/ASL a5|SLTTLoc/220707_214913/220707_214942/221230_043259-1",
"IS/ASL a6|SLTTLoc/220707_214913/220707_214946/221230_044839-1",
"IS/ASL a7|SLTTLoc/220707_214913/220707_214957/221230_044846-1",
"IS/ASL a8|SLTTLoc/220707_214913/220707_215004/221230_044853-2",
"IS/ASL a9|SLTTLoc/220707_214913/220707_215010/221230_044858-1",
"IS/ASL a10|SLTTLoc/220707_214913/221230_044917/221230_044918-1",
"IS/ASL a11|SLTTLoc/220707_214913/220707_215028/221230_044931-3",
"IS/ASL a12|SLTTLoc/220707_214913/220707_215039/221230_044938-1",
"IS/ASL a13|SLTTLoc/220707_214913/220707_215044/221230_044943-1",
"IS/ASL a14|SLTTLoc/220707_214913/220707_215049/221230_044955-1",
"IS/ASL a15|SLTTLoc/220707_214913/220707_215119/221230_045018-1",
"ASL a1|SLTTLoc/220707_214913/221229_222526/221229_222527-1",
"ASL a2|SLTTLoc/220707_214913/220707_214929/221229_222911-1",
"ASL a3|SLTTLoc/220707_214913/221229_223245/221229_223418-1",
"ASL a4|SLTTLoc/220707_214913/221229_223255/221229_223449-1",
"ASL a5|SLTTLoc/220707_214913/221229_223303/221230_045023-1",
"ASL a6|SLTTLoc/220707_214913/221229_223310/221230_045032-1",
"ASL a7|SLTTLoc/220707_214913/221229_223321/221230_045037-1",
"ASL a8|SLTTLoc/220707_214913/221229_223338/221230_045042-2",
"ASL a9|SLTTLoc/220707_214913/221229_223348/221230_045048-1",
"ASL a10|SLTTLoc/220707_214913/220707_215020/221230_045053-1",
"ASL a11|SLTTLoc/220707_214913/221229_223401/221230_045060-3",
"ASL a12|SLTTLoc/220707_214913/221229_223405/221230_045106-1",
"ASL a13|SLTTLoc/220707_214913/221229_223409/221230_045111-1",
"ASL a14|SLTTLoc/220707_214913/221229_223413/221230_045114-1",
"ASL a15|SLTTLoc/220707_214913/221229_223417/221230_045120-1"];