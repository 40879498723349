import _debug from 'debug'; const log = _debug('sltt:LocationHistory')

export function clearHistorySearchParameters(logContext: string, state = {}, title = document.title) {
    const targetHref = window.location.href.replace(/\?.*$/, '')
    log(`${logContext} - location - resetting history`, { from: window.location.href, to: targetHref })
    window.history.replaceState(state, title, targetHref)
}

/*
 * returns `window.location.origin` unless it starts with 'file://'
 * in which case it returns ELECTRON_BASE_URL
 */
export function getOriginOrBaseUrl() {
    if (window.location.origin.startsWith('file://'))
        return ELECTRON_BASE_URL
    return window.location.origin
}

export const ELECTRON_BASE_URL = '.' // import.meta.env.BASE_URL is './' which can't be used in strings like ${ELECTRON_BASE_URL}/index.html
