export default class ImageResizer {
    constructor(private file: File) {
    }

    async resize(width: number, height: number, mimeType='image/jpeg') {
        let dataURL = await this.getDataURL()
        return await this.resizeImage(dataURL, width, height, mimeType)
    }

    private async getDataURL(): Promise<string> {
        let { file } = this
        let reader = new FileReader()
        return new Promise((resolve, reject) => {
            reader.onerror = reject
            reader.onload = (e: any) => resolve(e.target.result)
            reader.readAsDataURL(file)
        })
    }

    private async resizeImage(dataURL: string, width: number, height: number, mimeType: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let image = new Image()
            image.src = dataURL
            image.onload = () => {
                let desiredSize = new Rectangle(width, height)
                let existingSize = new Rectangle(image.width, image.height)
                let newSize = Rectangle.scaleToFit(existingSize, desiredSize)

                const canvas = document.createElement('canvas')
                canvas.width = newSize.width
                canvas.height = newSize.height
                const ctx = canvas.getContext('2d')
                ctx?.drawImage(image, 0, 0, newSize.width, newSize.height)
                let compressedDataURL = ctx?.canvas.toDataURL(mimeType, 1)
                resolve(compressedDataURL || '')
            }
            image.onerror = reject
        })
    }
}

export const MAX_RESIZER_FILE_SIZE = 50 * 1024 * 1024  // 50 MB limit for resizer

export class Rectangle {
    constructor(public width: number, public height: number) {

    }

    // Maintain aspect ratio
    static scaleToFit(existingSize: Rectangle, desiredSize: Rectangle) {
        let scalingFactor = this.getScalingFactor(existingSize, desiredSize)
        return new Rectangle(existingSize.width / scalingFactor, existingSize.height / scalingFactor)
    }

    private static getScalingFactor(existingSize: Rectangle, desiredSize: Rectangle) {
        let largestDimension = Math.max(existingSize.width, existingSize.height)
        let smallestDesiredDimension = Math.min(desiredSize.width, desiredSize.height)
        return largestDimension / smallestDesiredDimension
    }
}