import React, { FC } from 'react'
import { CustomPicker, CustomPickerInjectedProps } from 'react-color'
import { Hue, Saturation } from 'react-color/lib/components/common'
import './ColorPicker.css'
import { CircleIcon } from './Icons'

const ColorPickerBridge: FC<CustomPickerInjectedProps> = ({ hex, hsl, rgb, hsv, onChange }) => {
    return (
        <div className='flex-display'>
            <div className='saturation-slider'>
                <Saturation {...{ hex, hsl, hsv, rgb, onChange }} pointer={SaturationPointer} />
            </div>
            <div className='hue-slider'>
                <Hue {...{ hex, hsl, hsv, rgb, onChange }} direction="vertical" pointer={HuePointer} />
            </div>
        </div>
    )
}

const HuePointer: FC = () => <div className='hue-pointer'/>
const SaturationPointer: FC = () => <div className='saturation-pointer' />

const CustomColorPicker = CustomPicker(ColorPickerBridge)

interface IColorPicker {
    color: string,
    setColor?: (hexColor: string) => void,
}

const ColorPicker: FC<IColorPicker> = ({ color, setColor }) => {
    return (
        <div className='flex-display'>
            <CustomColorPicker color={color} onChangeComplete={color => setColor?.(color.hex)} />
            <CircleIcon className='note-marker-color-icon' style={{ color }} tooltip={''} />
        </div>
    )
}

export default ColorPicker