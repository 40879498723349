import { computed, observable, makeObservable } from 'mobx';
import { RefRange } from '../scrRefs/RefRange'

import { Passage, PassageNote, PassageSegment, PassageVideo, Portion } from './ProjectModels'
import { Root, IRoot } from './Root'
import { RootBase } from './RootBase'

import _debug from "debug"; let log = _debug('sltt:DraftVideoRoot') 
export class DraftVideoRoot extends RootBase implements IRoot {
    private rt: Root

    @computed get bothPlaying() {
        return this.playing && this.bothPlayingRequested
    }

    @computed get bothCanPlayThrough() {
        return this.rt.canPlayThrough && this.canPlayThrough
    }

    // User has requested playback of multiple videos. Necessary because other video
    // might finish playing before this one does. In that case, we want this.bothPlaying
    // to be true
    @observable bothPlayingRequested = false

    constructor(rt: Root) {
        super()
        makeObservable(this);
        this.rt = rt

        this.setPlaying = this.setPlaying.bind(this)
        this.setNote = this.setNote.bind(this)
        this.initialize = this.initialize.bind(this)
        this.restoreDefaults = this.restoreDefaults.bind(this)
        this.setPassage = this.setPassage.bind(this)
        this.setDefaultPassageVideo = this.setDefaultPassageVideo.bind(this)
        this.setPassageVideo = this.setPassageVideo.bind(this)
        this.addListener = this.addListener.bind(this)
        this.removeListener = this.removeListener.bind(this)
        this.stopRoot = this.stopRoot.bind(this)
        this.displayableReferences = this.displayableReferences.bind(this)
        this.setLexicalLink = this.setLexicalLink.bind(this)
    }

    setLexicalLink(lexicalLink: string): void {
        this.rt.setLexicalLink(lexicalLink)    
    }

    stopRoot() {
        this.rt.stop()
    }

    async initialize() {
        await this.restoreDefaults()
    }

    async restoreDefaults() {
        this.passage = this.rt.passage
        if (!this.passage) return

        this.glossScale = this.rt.glossScale
        this.timelineZoom = this.rt.timelineZoom
        let passageVideo = this.rt.passageVideo
        if (passageVideo) {
            await this.setPassageVideo(passageVideo)
        }
    }

    async setPortion(portion: Portion | null) {
        await this.rt.setPortion(portion)
    }

    async setPassageVideo(passageVideo: PassageVideo | null) {
        let { passage } = this
        let { name } = this.rt
        await super._setPassageVideo(name, passage, passageVideo)
    }

    setPassageSegment(passageSegment: PassageSegment) {
        this.rt.setPassageSegment(passageSegment)
    }

    get passageSegment() {
        return this.rt.passageSegment
    }

    @computed get draftVideoSegment() {
        const position = this.currentTime
        const video = this.passageVideo
        const segmentData = video?.positionToSegment(position)
        return segmentData?.segment || null 
    }

    async setPassage(passage: Passage | null) {
        if (!passage) {
            this.passage = null
            await this.setPassageVideo(null)
            return
        }

        // Set passageVideo to null because otherwise we can have a situation
        // where passage and passageVideo temporarily refer to different passages.
        await this.setPassageVideo(null)

        this.passage = passage
        await this.setDefaultPassageVideo()
    }

    async setDefaultPassageVideo() {
        if (!this.passage) {
            this.passageVideo = null
            return
        }

        let passageVideo = this.passage!.getDefaultVideo('')
        await this.setPassageVideo(passageVideo)
    }

    get name() {
        return this.rt.name
    }

    get portion() {
        return this.rt.portion
    }

    get iAmAdmin() {
        return this.rt.iAmAdmin
    }

    get iAmConsultant() {
        return this.rt.iAmConsultant
    }

    get iAmInterpreter() {
        return this.rt.iAmInterpreter
    }

    get hardNotificationCutoff() {
        return this.rt.hardNotificationCutoff
    }

    get canViewConsultantOnlyFeatures() {
        return this.rt.canViewConsultantOnlyFeatures
    }

    get dateFormatter() {
        return this.rt.dateFormatter
    }

    setPlaying(playing: boolean) {
        super.setPlaying(playing)
        if (!playing) {
            this.bothPlayingRequested = false
        }
    }

    get username() {
        return this.rt.username
    }

    get useMobileLayout() {
        return this.rt.useMobileLayout
    }

    get project() {
        return this.rt.project
    }

    get plans() {
        return this.project.plans
    }

    get playbackRate() {
        return this.rt.playbackRate
    }

    setPlaybackRate(rate: number) {
        this.rt.setPlaybackRate(rate)
    }

    get editingSegment() {
        return this.rt.editingSegment
    }

    displayableReferences(references: RefRange[] | undefined | null) {
        return this.rt.displayableReferences(references)
    }

    resetCurrentTime(newTime: number, duration?: number) {
        super.resetCurrentTime(newTime, duration)

        let { portion, passageSegment } = this
        this.setDbsRefs(portion, passageSegment)
    }

    setCurrentTime(currentTime: number) {
        super.setCurrentTime(currentTime)

        let { passage, passageVideo, portion, passageSegment } = this
        // If the video was just created, it may not have any segments yet.
        // If so, we cannot select a segment yet.
        if (!passage || !passageVideo || passageVideo.segments.length === 0 || !portion) return

        try {
            this.setDbsRefs(portion, passageSegment)
        } catch (error) {
            log('###setCurrentTime', error)            
        }
    }

    parseReferences(references: string) {
        return this.rt.parseReferences(references)
    }

    setDefault(tag: string, value: string | null) {
        return this.rt.setDefault(tag, value)
    }

    getDefault(tag: string) {
        return this.rt.getDefault(tag)
    }

    @computed get videoPlaybackKeydownEnabled() {
        return !this.editingSegment && !this.note && this.rt.videoPlaybackKeydownEnabled
    }

    // Overridden methods from EventEmitter
    addListener(event: string | symbol, listener: (...args: any[]) => void) {
        let eventString = event.toString()
        if (eventString === 'record') {
            this.rt.addListener(event, listener)
            return this
        } else if (eventString === 'stop-main-video') {
            this.rt.addListener('stop', listener)
            return this
        } else {
            return super.addListener(event, listener)
        }
    }

    removeListener(event: string | symbol, listener: (...args: any[]) => void) {
        let eventString = event.toString()
        if (eventString === 'record') {
            this.rt.removeListener(event, listener)
            return this
        } else if (eventString === 'stop-main-video') {
            this.rt.removeListener('stop', listener)
            return this
        } else {
            return super.removeListener(event, listener)
        }
    }

    get uiLanguage() {
        return this.rt.uiLanguage
    }

    get transliterateLemmas() {
        return this.rt.transliterateLemmas
    }

    get termId() {
        return this.rt.termId
    }
}