import React, { FC, useState, useEffect, useRef } from 'react'

import { displayError } from '../utils/Errors'
import { RefRange } from '../../scrRefs/RefRange'
import { ADBTextItem, ApiDotBible } from '../../models3/ApiDotBible'
import './ResourceViewer.css'
import { fmt } from '../utils/Fmt'
import InitializationMessage from '../utils/InitializationMessage'

import _debug from "debug"; let log = _debug('sltt:ResourceViewer')

const SelectedClassName = 'er-selected'
const FirstSelectedClassName = 'er-first-selected' // marks beginning of first selection so we can scroll to it

function isText(item: ADBTextItem) {
    return item?.type === 'text'
}

function hasName(item: ADBTextItem, name: string) {
    return item?.name === name
}

/*
 * We display resource text by chapter.
 * We want to highlight the verses selected by dbsRefs.
 * We want to mark the first selected verse number so we can scroll to it.
 */
class SelectionState {
    bbbccc = '000000' // most recently seen book and chapter
    selected = false // current verse is selected
    firstSelected = false // We have found a selected verse
}

// Combine verse number with bbbccc to form a complete reference.
// Check this against dbsRefs to see if it is selected.
// Verse numbers can be single or ranges, e.g. 1-3
function updateSelection(item: ADBTextItem, selection: SelectionState, dbsRefs: RefRange[]) {
    const verse: string = item.attrs?.number || '*missing*'

    const parts = verse.match(/^(\d+)(-\d+)?$/) || []
    if (parts.length < 3) {
        console.log('### not a valid verse number:', verse)
        return
    }

    const firstVerse = parts[1]
    const lastVerse = parts[2] ? parts[2].slice(1) : firstVerse

    function vvv(_verse: string) { return ('000' + _verse).slice(-3) }
    
    const { bbbccc } = selection
    const ref = new RefRange(bbbccc + vvv(firstVerse), bbbccc + vvv(lastVerse))
    selection.selected = dbsRefs.some(r => r.overlaps([ref]))
    // log('updateSelection:', fmt({verse, bbbcccvvv, selection, dbsRefs}))
}

function textItemToHtml(item: ADBTextItem, selected: boolean) {
    const { text } = item

    if (selected) {
        return `<span class="${SelectedClassName}">${text}</span>`
    }

    return text || ''
}

function itemToHtml(item: ADBTextItem, selection: SelectionState, dbsRefs: RefRange[], noParagraphs: boolean): string {
    // Should not happen but bible.api is occasionally returning null items
    if (item === null) return ''

    let { items, attrs } = item
    items = items || []

    // Keep track of the current book and chapter, we combine this with the verse number to form a complete reference
    const _bbbccc = item.attrs?.bbbccc
    if (_bbbccc) {
        selection.bbbccc = _bbbccc
    }

    if (isText(item)) {
        return textItemToHtml(item, selection.selected)
    }

    if (hasName(item, 'verse')) {
        updateSelection(item, selection, dbsRefs)
        let { selected, firstSelected } = selection

        // When we find the first selected verse, mark it so we can scroll to it
        let verseClass = 'v'
        if (selected && !firstSelected) {
            verseClass += ' ' + FirstSelectedClassName
            selection.firstSelected = true
        }

        let verseText = `<span class="${verseClass}">${items.length ? textItemToHtml(items[0], selected) : '???'}</span>`

        // Text has no paragraphs, so make every verse start on a new line
        if (noParagraphs) {
            verseText = '<br/>' + verseText
        }
        return verseText
    }     

    // Styled phrase, e.g. 'add' - implied text
    if (hasName(item, 'char')) {
        const spanText = items.map(item => itemToHtml(item, selection, dbsRefs, noParagraphs)).join('')
        const spanStyle = item.attrs?.style || ''
        return `<span class="${spanStyle}">${spanText}</span>`
    }

    if (hasName(item, 'ref')) {
        const refText = items.map(item => itemToHtml(item, selection, dbsRefs, noParagraphs)).join('')
        return `<span>${refText}</span>`
    }

    if (hasName(item, 'sidebar')) {
        // Sidebars contain complicated structure like tables, ignore them for now
        return ''
    }

    if (hasName(item, 'note')) {
        // We don't currently (in either this code nor the previously fetched api.bible html) display notes
        return ''
    }

    const style = attrs?.style || 'p'
    const text2 = items.map(item => itemToHtml(item, selection, dbsRefs, noParagraphs)).join('')
    return `<p class="${style}">${text2}</p>`
}

function itemsToHtml(items: ADBTextItem[], rtl: boolean, dbsRefs: RefRange[]) {
    const selection = new SelectionState()

    // Count the number of paragraphs in the resource because if there is only 1 paragraph
    // we want to make each verse a separate paragraph to improve readability.
    const paragraphCount = items.filter(item => hasName(item, 'para')).length

    let html = items.map(item => itemToHtml(item, selection, dbsRefs, paragraphCount === 1)).join('')

    if (rtl) {
        html = '<div dir="rtl">' +  html + '</div>'
    }

    return html
}

interface IRBody {
    resourceId: string,
    refs: RefRange[], // verse range selected in the box in the resource viewer
    displayableBookNames: string[],
    dbsRefs: RefRange[], // verses currently selected by user, normally based on position in video timeline
}

const RBody: FC<IRBody> = ({ resourceId, refs, displayableBookNames, dbsRefs }) => {
    log('RBody:', fmt({dbsRefs}))

    const [items, setItems] = useState<ADBTextItem[]>([])
    const [html, setHtml] = useState('')
    const [rtl, setRtl] = useState(false)

    // Fetch chapter data for resource/refs
    useEffect(() => {
        const fetchResource = async () => {
            if (!refs.length) return

            try {
                const version = await ApiDotBible.getBibleVersion(resourceId)
                setRtl(version ? version.language.scriptDirection === 'RTL' : false)

                const items = await ApiDotBible.fetchRefs(resourceId, refs, displayableBookNames)
                setItems(items)
            } catch (error) {
                displayError(error)
            }
        }

        fetchResource()
    }, [resourceId, refs])

    // Convert resource data to HTML.
    // Redo this whenever dbsRefs changes because we want to highlight the selected verses.
    useEffect(() => {
        setHtml(itemsToHtml(items, rtl, dbsRefs))
    }, [items, rtl, dbsRefs])

    // After the HTML is updated, scroll to the first selected verse
    useEffect(() => {
        log('scrollIntoView')
        const element = document.querySelector(`.${FirstSelectedClassName}`)
        if (element) element.scrollIntoView({ behavior: 'smooth' })
    }, [html])

    if (items.length === 0) {
        if (refs.length === 0) {
            return null // no refs to display, don't show loading spinner
        }
        return (<InitializationMessage loadingMessage="" />)
    }

    return (
        <div className="resource-container">
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    )
}

export default RBody
