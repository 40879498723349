import { useContext, useEffect, useState } from "react"
import { RootContext } from "../app/RootContext"

// Could add zoomInEnalbed and zoomOutEnabled, not sure it is worth extra complexity

// Allow tracking and changing zoom level.
// Value is persisted in local storage under the key `${tag ?? 'document'}Zoom`
export function useZoom(tag?: string) {
    const rt = useContext(RootContext)
    const [zoom, setZoom] = useState<number>(1)

    const zoomKey = `${tag ?? 'document'}Zoom`

    function _setZoom(_zoom: number) {
        setZoom(_zoom)
        rt?.setDefault(zoomKey, _zoom.toString())
    }

    useEffect(() => {
        const zoomString = rt?.getDefault(zoomKey) ?? '1.0'
        const zoomNumber = parseFloat(zoomString) || 1.0
        _setZoom(zoomNumber)
    }, [])

    const zoomIn = () => {
        if (zoom >= 5) return
        _setZoom(1.1 * zoom)
    }

    const zoomOut = () => {
        if (zoom <= .1) return
        _setZoom(.9 * zoom)
    }

    return { zoom, zoomIn, zoomOut }
} 