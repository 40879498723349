import React, { FC, useEffect, useRef } from 'react'
import { observer, /* inject */ } from 'mobx-react'
import { t } from 'ttag'

import { OkEditSegmentButton, CancelEditSegmentButton } from '../utils/Buttons'
import { PassageSegmentLabel } from '../../models3/ProjectModels'
import { RootConsumer } from '../app/RootContext'
import SegmentLabelTextEditor from './SegmentLabelTextEditor'
import { useOnClickOutside } from '../utils/Hooks'
import { Root } from '../../models3/Root'
import { displayError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'

import _debug from "debug"; let log = _debug('sltt:SegmentLabelsEditor')

interface ILabelEditor {
    label: PassageSegmentLabel,
    onUpdate: (value: string) => void,
    onEnter: () => void,
    onEscape: () => void,
    className: string,
}
let LabelEditor: FC<ILabelEditor> = ({ label, onEnter, onEscape, onUpdate, className }) => {
    let { x, y, text } = label

    let xTextPosition = x
    let yTextPosition = y

    if (y < 20) {
        yTextPosition = 20
    } else if (y > 85) {
        yTextPosition = 85
    }

    if (x < 2) {
        xTextPosition = 2
    } else if (x > 58) {
        xTextPosition = 58
    }

    return (
        <RootConsumer>
            {rt => (
                <div className={className}>
                    <svg width="50" height="30" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle' }}>
                        <g>
                            <rect height="27" width="42" y="0" x="0" strokeWidth="1" stroke="#000" fill="#fff" />
                            <text stroke="#000" textAnchor="start" fontFamily="Helvetica, Arial, sans-serif" fontSize="6"
                                x={`${xTextPosition}%`} y={`${yTextPosition}%`} strokeWidth="0" fill="#000000">{t`ABC`}</text>
                        </g>
                    </svg>
                    <SegmentLabelTextEditor text={text} update={onUpdate} save={onEnter} cancel={onEscape} />
                </div>
            )}
        </RootConsumer>
    )
}

interface ISegmentLabelsEditor {
    rt: Root,
    labels: PassageSegmentLabel[],
    updateLabels: (labels: PassageSegmentLabel[]) => void,
    onClose: () => void,
}

const SegmentLabelsEditor: FC<ISegmentLabelsEditor> = observer(({ rt, labels, onClose, updateLabels }) => {
    async function save() {
        await rt.saveSegmentLabelsDraftChanges()
        onClose()
    }

    useEffect(() => {
        return () => {
            // If the user does somthing to navigate away from the editor while it is still open, save the labels
            if (rt.editingSegmentLabels) {
                save()
            }
        }
    }, [])
    
    function _updateLabels(value: string, index: number) {
        let _labels = labels.map(label => new PassageSegmentLabel(label.x, label.y, label.xText, label.yText, label.text))
        _labels[index].text = value
        updateLabels(_labels)
    }

    return (
        <div className="segment-labels-editor">
            <div className="segment-dialog-heading">{t`Edit Segment Labels:` + ' '}</div> 
            {labels.map((label, i) => (
                <LabelEditor
                    key={i}
                    className=''
                    label={label}
                    onUpdate={value => _updateLabels(value, i)}
                    onEnter={save}
                    onEscape={onClose}
                />
            ))}
            <div className="segment-labels-editor-buttons">
                <OkEditSegmentButton
                    enabled={true}
                    onClick={() => save().catch(displayError)} />
                <CancelEditSegmentButton
                    enabled={true}
                    onClick={onClose} />
            </div>
        </div>
    )
})

export default SegmentLabelsEditor
