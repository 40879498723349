// Display note selected by user OR create new note

import React, { Component, FunctionComponent, useEffect, useState, FC } from 'react'
import { t } from 'ttag'

import { Dropdown, MenuItem } from 'react-bootstrap'
import { fmt } from '../utils/Fmt'
import { NoteMarker } from './NoteDialog'
import { NoteSelector } from './NoteSelector'
import { SlttHelp2 } from '../utils/SLTTHelp2'
import { PassageNote } from '../../models3/ProjectModels'

import _debug from "debug"; let log = _debug('sltt:NotesSelectorDropdown')

interface INotesSelectorDropdown {
    noteSelector: NoteSelector,
    setNoteSelector: (noteSelector: NoteSelector) => void,
    noteColors: string[],
}

export const NotesSelectorDropdown: FC<INotesSelectorDropdown> = 
    ( { noteSelector, setNoteSelector, noteColors }) => {

    let selectors: NoteSelector[] = [
        new NoteSelector(-1, -1), // select all
        new NoteSelector(0, -1), // selectors for shapes
        new NoteSelector(1, -1),
        new NoteSelector(2, -1),
    ]

    // Add selectors for marker color (ignore deleted colors)
    for (let i=0; i<noteColors.length; ++i) {
        if (noteColors[i] === PassageNote.DeletedColor) continue
        
        selectors.push(new NoteSelector(-1, i))
    }
    
    let className = "note-selector-dropdown sl-note-selector-in-timeline-button"
    if (noteSelector.active) className += ' note-selector-active'

    let tooltip = /* translator: important */ t`Select notes by color or shape`

    return (
        <div className={className} data-tip data-place="right" >
            <SlttHelp2 {...{ id: 'noteselectordropdown', tooltip, video: 'a11' }} place="bottom">
                <Dropdown id="note-selector-dropdown">
                    <Dropdown.Toggle className="note-selector-dropdown-item">
                        <NoteMarker {...{ noteSelector, noteColors }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {selectors.map((noteSelector, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    className='note-selector-dropdown-item'
                                    onClick={() => setNoteSelector(noteSelector)}
                                >
                                    <NoteMarker {...{ noteSelector, noteColors }} />
                                </MenuItem>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </SlttHelp2>
        </div>
    )
}

