import React, { Component, useContext, useEffect, useState } from "react"
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react"
import { t } from 'ttag'

import { Root } from "../../models3/Root"
import '../video/NoteLocationEditor.css'
import { PaneCloseButton } from "../utils/Buttons"
import { displayError } from "../utils/Errors"
import { ProjectReference } from "../../models3/ProjectReferences"
import RangeVideoPlayer from "../video/RangeVideoPlayer"
import { PositionSetter, VideoTimeline } from "../video/VideoTimeline"
import { ViewableVideoCollection } from "../video/ViewableVideoCollection"
import VideoTimelinePlayButtons from "../video/VideoTimelinePlayButtons"
import { Passage, PassageGloss, PassageSegment, PassageVideo } from "../../models3/ProjectModels"
import { RootContext } from "../app/RootContext"
import { fmt, s } from "../utils/Fmt"

import _debug from "debug"; let log = _debug('sltt:ProjectReferenceVideoPlayer')

interface IProjectReferenceVideoPlayer {
    rt: Root,
    projectReference: ProjectReference,
    setProjectReference: (projectRefernce: ProjectReference | null) => void,
}

@observer
export class ProjectReferenceVideoPlayer extends Component<IProjectReferenceVideoPlayer> {
    @observable isPlaying = false

    @observable currentTime = 0

    rangeVideoPlayer: RangeVideoPlayer | null = null

    /**
     * There is one setter for each (potentially) dragable handle in VideoTimeline.
     * For this control the handles are: start of range to play, end of range to play, current time [dragable]
     */
    setters: PositionSetter[] = []

    // These must be non-decreasing
    domainStartingTime = 0  // start of viewable range in VideoTimeline
    startingTime = 0 // start of range to be played
    endingTime = 0 // end of range to be played
    domainEndingTime = 0  // end of viewable range

    currentVideos: ViewableVideoCollection | null = null

    constructor(props: IProjectReferenceVideoPlayer) {
        super(props)
        makeObservable(this);
        this.setup().catch(displayError)
    }

    // Setup noteVideo to contain only the patches were available
    // at the time the note was originally created.
    //
    // Start download process
    async setup() {
        let { rt, projectReference } = this.props
        let { passageVideo, startingTime, endingTime } = projectReference
        let { passage } = projectReference.info(rt)
        log('setupVideo', fmt({ name: passage?.name, startingTime, endingTime }))

        this.startingTime = startingTime
        this.endingTime = endingTime

        this.currentVideos = new ViewableVideoCollection()
        this.currentVideos.setup(passage!, projectReference.passageVideo)
        await this.currentVideos.download()

        const timeBuffer = 15 // show 15 seconds before and after range to be played

        // domain start position is position-30 or start of video (whichever is later)
        this.domainStartingTime = Math.max(this.startingTime - timeBuffer, 0)

        let duration = passageVideo.computedDuration
        this.domainEndingTime = Math.min(this.endingTime + timeBuffer, duration)
        let { domainStartingTime: domainStartPosition, domainEndingTime: domainEndPosition } = this
        log('times', fmt({ domainStartPosition, startingTime, endingTime, domainEndPosition }))

        this.setters = [
            // start of range to be played, fixed
            new PositionSetter(
                '0',
                () => 0,
                () => this.domainEndingTime,
                (value: number) => {}
            ),
            // end of range to be played, fixed
            new PositionSetter(
                '1',
                () => 0,
                () => this.domainEndingTime,
                (value: number) => {}
            ),
            // currentTime, dragable
            new PositionSetter(
                '2',
                () => this.domainStartingTime,
                () => this.domainEndingTime,
                (value: number) => { 
                    this.setCurrentTime(value) 
                }
            ),
        ]

        this.setters[0].value = this.startingTime
        this.setters[1].value = this.endingTime
        this.setters[2].value = this.startingTime
        
        this.setCurrentTime(this.startingTime + .03) // forces rerender
        log('end setup')
    }

    // Convert relative position to time and pass to player
    setCurrentTime(currentTime: number) {
        this.currentTime = currentTime
        log('setCurrentTime', fmt({currentTime}))
        this.setters[2].value = currentTime
        
        if (!this.isPlaying) {
            // if we are not playing move the player to the selected point in time
            this.rangeVideoPlayer?.setCurrentTime(this.currentTime)
        }
    }

    render() {
        let { rt, projectReference } = this.props
        let { passageVideo } = projectReference
        let { currentVideos, currentTime } = this

        let { passage } = projectReference.info(rt)
        let { playbackRate, setPlaybackRate } = rt

        // to force re-rendering of toolbar
        let { isPlaying } = this

        // let values = this.setters.map(setter => setter.value)
        // log('render2', fmt({ values }))

        return (
            <div className="project-reference-video-player-content">
                <div className='note-location-editor-video'>
                    <div className="project-reference-video-player-title">{projectReference.info(rt).tooltip}</div>
                    <RangeVideoPlayer
                        className="project-reference-video-player-player"
                        ref={(rvp:any) => this.rangeVideoPlayer = rvp}
                        passage={passage!}
                        playbackRate={playbackRate}
                        setPlaybackRate={setPlaybackRate}
                        video={passageVideo}
                        currentVideos={currentVideos!}
                        startTime={this.startingTime}
                        endTime={this.endingTime}
                        onPlayingStatus={(isPlaying: boolean) => this.isPlaying = isPlaying }
                        onTick={(time: number) => {
                            this.setters[2].setValue(time)
                        } }
                    />
                    <div className="video-timeline-area">
                        <VideoTimelinePlayButtons
                            isPlaying={this.isPlaying}
                            playAll={this.playAll}
                            pause={this.pause}
                        />
                        <div className="video-timeline">
                                <VideoTimeline
                                    setters={this.setters}
                                    domainStartPosition={this.domainStartingTime}
                                    domainEndPosition={this.domainEndingTime}
                                    adjustTime={time => {
                                        this.setters[2].setValue(time)
                                    }}
                                    enabled={true}
                                    allowAdjustingPositions={false}
                                />
                            </div>
                    </div>
                </div>
                <div className="note-location-editor-close">
                    <PaneCloseButton
                        onClick={() => this.props.setProjectReference(null)}
                        enabled={true}
                        tooltip={t`Close pane`}
                        className='sl-pane-close-button' />
                </div>
            </div>
        )
    }

    playAll = async () => {
        let { currentTime, endingTime, startingTime } = this
        
        let _endingTime = (Math.abs(currentTime-startingTime) < .5) ? endingTime : undefined
        log('playAll', fmt({ currentTime, _endingTime }))

        this.isPlaying = true
        let { rangeVideoPlayer } = this
        if (!rangeVideoPlayer) return
        
        await rangeVideoPlayer.playRange(currentTime, _endingTime)
    }

    pause = () => {
        let { rangeVideoPlayer } = this
        rangeVideoPlayer && rangeVideoPlayer.stop()
    }
}
