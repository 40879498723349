import React, { Component } from 'react'
import {observer} from 'mobx-react'
import { t } from 'ttag'

import { displayError } from '../utils/Errors'
import { PassageGloss, PassageVideo, Passage, IDrawablePassageGloss, PassageSegment, Project } from '../../models3/ProjectModels'
import { observable, makeObservable } from 'mobx';
import { DeleteButton, PlayButton, GlossPositionAdjustTimeButtons, PreviousSegmentButton, NextSegmentButton } from '../utils/Buttons'
import { SearchBox } from '../utils/SearchBox'
import { RefRange } from '../../scrRefs/RefRange'

// Edit name (rename) existing Gloss
// It must be a valid Gloss name and not create a duplicate Gloss name

import _debug from "debug"; let log = _debug('sltt:GlossEditor')

const getDrawable = (p: IGlossEditor) => p.drawables[p.glossIndex]

interface IGlossEditor {
    passage: Passage | null,
    passageVideo: PassageVideo | null,
    project: Project,
    drawableGloss: IDrawablePassageGloss | null,
    resetCurrentTime: (newTime: number) => void,
    stop: () => void,
    play: (startTime?: number, endPosition?: number, resetTime?: number) => void,
    drawables: IDrawablePassageGloss[],
    glossIndex: number, // glossIndex of gloss in drawables
    setGlossIndex: (glossIndex: number) => void,
    onDelete: () => Promise<void>,
    onDone: () => void,
}

class GlossEditor extends Component<IGlossEditor> {
    @observable text = ''
    position = 0    // position in the view
    width = 0

    minPosition = 0
    maxPosition = 0

    glossEditorRef = React.createRef<HTMLDivElement>()

    constructor(props: IGlossEditor) {
        super(props)
        makeObservable(this);
        this.initializeGloss()

        this.onPlay = this.onPlay.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.clickOutside = this.clickOutside.bind(this)
    }

    // If _index is a valid array glossIndex in drawables return the corresponding gloss, video, segment
    glossInfo(_index: number) { 
        let { drawables, passage } = this.props

        let gloss = null
        let video: PassageVideo | null = null
        let segment: PassageSegment | null = null

        if (_index >= 0 && _index < drawables.length) {
            gloss = drawables[_index].gloss
            video = (passage && gloss.toVideo(passage)) ?? null
            segment = video?.positionToSegment(gloss.position)?.segment ?? null
        }

        return { gloss, video, segment }
    }

    initializeGloss() {
        let { glossIndex, drawables } = this.props

        let dr = drawables[glossIndex]
        this.text = dr.gloss.text
        this.position = dr.gloss.position 
        this.width = dr.width

        const MIN_GLOSS_WIDTH = .5 // seconds
        const MAX_GLOSS_WIDTH = 2.0 // seconds

        let { segment } = this.glossInfo(glossIndex)
        let { segment: prevSegment } = this.glossInfo(glossIndex - 1)
        let { segment: nextSegment, gloss: nextGloss } = this.glossInfo(glossIndex + 1)

        if (!prevSegment || prevSegment?._id !== segment?._id) {
            // No previous gloss or previous gloss in a different segment
            // Min position is the start of this segment
            this.minPosition = segment?.position ?? 0
        } else {
            // Previous gloss is in the same segment as this gloss.
            // Ensure that previous gloss maintains min width
            this.minPosition = prevSegment.position + MIN_GLOSS_WIDTH
        }

        if (!nextSegment || segment?._id !== nextSegment?._id) {
            // No next gloss or next gloss in a different segment
            // Max position is the end of this segment
            this.maxPosition = segment?.endPosition ?? MAX_GLOSS_WIDTH
        } else {
            // Next gloss is in the same segment as this gloss.
            // Ensure that the gloss ends before start of next gloss
            this.maxPosition = nextGloss?.position ?? MAX_GLOSS_WIDTH
        }

        // this.onPlay()
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clickOutside)
        document.addEventListener('touchstart', this.clickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutside)
        document.removeEventListener('touchstart', this.clickOutside)
    }

    clickOutside(event: any) {
        let { drawableGloss, onDone, stop } = this.props
        let { glossEditorRef } = this
        if (!glossEditorRef.current || glossEditorRef.current.contains(event.target)) {
            return
        }

        if (drawableGloss) {
            this.saveChanges(drawableGloss.gloss).catch(displayError)
        }
        stop()
        onDone()
    }

    componentDidUpdate(prevProps: IGlossEditor) {
        let gloss = getDrawable(prevProps).gloss

        // If we are switching to a new gloss, save changes (if any) in this gloss
        if (gloss._id !== getDrawable(this.props).gloss._id) {
            this.saveChanges(gloss).
                then(() => { this.initializeGloss() }).
                catch(displayError)
        }
    }

    async saveChanges(gloss: PassageGloss) {
        let { text, position } = this
        
        await gloss.set(position, text)
    }

    render() {
        let { text, props, position, minPosition, maxPosition, glossEditorRef } = this
        let { onDelete, passage, passageVideo, drawableGloss, stop, onDone, project } = props
        if (!passage || !passageVideo || !drawableGloss) {
            return null
        }

        let { gloss } = getDrawable(this.props)

        // log(`render glossIndex=${props.glossIndex}`)

        let delta = 0.05

        //{ t`Type gloss. <Enter> = save.` } <br />
        //{ t`<Esc> = cancel. <Tab> = next sign.` }

        let refRanges: RefRange[] = []
        if (passage) {
            refRanges = passage.references
        }

        let glosses = project.getGlossesFromVerses(refRanges)

        return (
            <div className="gloss-editor" ref={glossEditorRef}>
                <div className="gloss-pre-commands">
                    <PlayButton
                        tooltip={/* translator: important */ t`Play sign ( ] ), play longer ( } ).`}
                        className="gloss-play-button"
                        enabled={true}
                        selectionPresent={false}
                        onClick={() => this.onPlay(.25)} />
                    <GlossPositionAdjustTimeButtons
                        previousFrameEnabled={position - delta >= minPosition}
                        nextFrameEnabled={position + delta <= maxPosition}
                        adjustCurrentTime={(delta: number) => (
                            this.adjustPosition.bind(this)(delta)
                        )} />
                    <DeleteButton
                        className="gloss-delete"
                        tooltip={/* translator: important */ t`Delete gloss`}
                        enabled={true}
                        onClick={onDelete} />
                </div>
                <div className="gloss-editor-input">
                    <PreviousSegmentButton
                        enabled={this.gotoPreviousGlossEnabled()}
                        onClick={() => this.gotoPreviousGloss()}
                        tooltip={/* translator: important */ t`Go to previous gloss (Shift+Tab).`} />
                    <SearchBox
                        searchParameter={text}
                        options={glosses}
                        onEnter={() => {
                            stop()
                            this.saveChanges(gloss).
                                then(() => { onDone() }).
                                catch(displayError)
                        }}
                        onEscape={() => {
                            stop()
                            onDone()
                        }}
                        searchParameterChanged={(newReferences: string) => {
                            this.text = newReferences
                        }}
                        tooltip=''
                        keyboardShortcuts={[
                            {
                                key: ']',
                                metaKey: false,
                                shiftKey: false,
                                handler: (e) => {
                                    e.preventDefault()
                                    this.onPlay(.25)
                                }
                            },
                            {
                                key: '}',
                                metaKey: false,
                                shiftKey: true,
                                handler: (e) => {
                                    e.preventDefault()
                                    this.onPlay(1.5)
                                }
                            },
                            {
                                key: 'ArrowLeft',
                                metaKey: true,
                                shiftKey: false,
                                handler: (e) => {
                                    this.adjustPosition(-.05)
                                }
                            },
                            {
                                key: 'ArrowRight',
                                metaKey: true,
                                shiftKey: false,
                                handler: (e) => {
                                    this.adjustPosition(.05)
                                }
                            },
                            {
                                key: 'Tab',
                                metaKey: false,
                                shiftKey: false,
                                handler: (e) => {
                                    e.preventDefault()
                                    this.gotoNextGloss()
                                }
                            },
                            {
                                key: 'Tab',
                                metaKey: false,
                                shiftKey: true,
                                handler: (e) => {
                                    e.preventDefault()
                                    this.gotoPreviousGloss()
                                }
                            },
                        ]}
                        disableTab
                        autoFocus
                    />
                    <NextSegmentButton
                        enabled={this.gotoNextGlossEnabled()}
                        onClick={() => this.gotoNextGloss()}
                        tooltip={t`Go to next gloss (Tab).`} />
                </div>
                {/* <div className="gloss-post-commands">
                </div> */}
            </div>
        )
    }

    adjustPosition(adjustment: number) {
        log(`adjustPosition ${adjustment}`)

        let { position, minPosition, maxPosition } = this
        let { glossIndex, resetCurrentTime } = this.props
        let segment = this.glossInfo(glossIndex).segment
        if (!segment) return
        
        let newPosition = position + adjustment
        if (newPosition < minPosition || newPosition > maxPosition) return

        this.position = newPosition
    
        resetCurrentTime(segment.positionToTime(newPosition))
    
        this.width += position - newPosition
    }

    gotoPreviousGlossEnabled = () => {
        let { glossIndex } = this.props
        return glossIndex > 0
    }

    gotoPreviousGloss = () => {
        if (!this.gotoPreviousGlossEnabled()) return
        let { glossIndex, setGlossIndex } = this.props
        setGlossIndex(glossIndex - 1)
    }

    gotoNextGlossEnabled = () => {
        let { glossIndex, drawables } = this.props
        return glossIndex < drawables.length - 1
    }

    gotoNextGloss = () => {
        if (!this.gotoNextGlossEnabled()) return
        let { glossIndex, setGlossIndex } = this.props
        setGlossIndex(glossIndex + 1)
    }

    onPlay(margin: number) {
        let { play } = this.props
        let { time, duration } = getDrawable(this.props)
        log(`onPlay time=${time.toFixed(1)}, duration=${duration.toFixed(1)}`)

        let start = Math.max(0, time-margin)
        play(start, time + duration + margin, time)
    }
}

export default observer(GlossEditor)