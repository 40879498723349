import React, { Component, FC, useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { observable, makeObservable } from "mobx";
import { t } from 'ttag'

import { Root } from "../../models3/Root"
import DropTarget from "../utils/DropTarget"
import { displayError } from "../utils/Errors"
import './Images.css'
import { DropTargetViewLarge } from "../utils/DropTargetView"
import { ImageMetadata, ImageDefinition } from "../../resources/ImageMetadata"
import ImageMetadataEditor from "./ImageMetadataEditor"
import { LoadingIcon, TitleIcon } from "../utils/Icons"
import { ExpandButton, PreviousSegmentButton, NextSegmentButton, PaneCloseButton, EditButton } from "../utils/Buttons"
import VideoDownloading from "../utils/VideoDownloading"
import { Optional } from "../utils/Optional"
import { Switch } from "../utils/Switch"
import _ from "underscore"
import { ImagesRoot } from "./ImagesRoot"
import { ReferenceInput } from "../utils/ReferenceInput"
import { RefRange } from "../../scrRefs/RefRange"
import { fmt } from "../utils/Fmt"
import { ProjectImages } from "../../resources/ProjectImages"

import _debug from "debug"; let log = _debug('sltt:Images')

/*
    Displays images for search parameter references.
    Images are either from MARBLE or from projects.
    Project images may be shared with other projects.
    Captures/uploads project image file drops.

    components
        Images.tsx
            ImageDropTarget
                ImageSearchToolbar - allow user to enter search parameter, e.g. Luke 1.1-3
                ImagesList - show images matching search parameter
                    ImageViewer - show currently selected image
                        ImageMetadataEditor
                        FullSizeImageViewer
                    ImageThumbnail
                ImageUploader - upload a dropped image
                    ImageMetadataEditor.tsx
        ImagesRoot.ts - shared variables for images components
        ImmageCollection.ts - handle search by reference for both project and MARBLE images
    resources
        ImageMetadata.ts
        ProjectImages.ts - get/put project images from DynamoDB via API
        MARBLEImages.ts - get MARBLE images from S3
 */

/* Functions

   - drag/drop new image, cancel
   - drag/drop new image, edit all fields, save
   - delete existing image
   - edit metadata for existing image
   - select image and show
        - step through images
   - share image, verify other project can see (disable sharing, should not appear)
   - images with numbered citations should be shown at front and in correct order
   - show solid border for shared project images
   - show dotted border for non-shared project images
 */


interface IImages {
    rt: Root,
}

@observer
export class Images extends Component<IImages> {
    irt: ImagesRoot

    constructor(props: IImages) {
        super(props)

        let irt = new ImagesRoot(this.props.rt)
        this.irt = irt

        let _window = window as any
        _window.irt = irt // easy access to irt for debugging

        irt.setSearchRefs(irt.rt.defaultReferences())
    }

    render() {
        let { irt } = this

        return (
            <ImagesDropTarget irt={irt}>
                <div className='images-area'>
                    { !irt.rt.editingImage && <ImageSearchToolbar irt={irt} /> }
                    <ImagesList irt={irt} />
                </div>
            </ImagesDropTarget>
        )
    }
}


interface IImagesDropTarget {
    irt: ImagesRoot,
}

@observer
export class ImagesDropTarget extends Component<IImagesDropTarget> {
    @observable imageFileList: FileList | null = null

    constructor(props: IImagesDropTarget) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { irt, children } = this.props
        let { imageFileList } = this

        let dropTargetMessage = <div>{t`Drop an image here to upload.`}</div>
        let dropTargetView = <DropTargetViewLarge message={dropTargetMessage} />

        if (imageFileList) {
            return (
                <div className='images-tab'>
                    <ImageUploader
                        irt={irt}
                        imageFileList={imageFileList}
                        onDone={() => { this.imageFileList = null }}
                    />
                </div>
            )
        }

        return (
            <div className='images-tab'>
                <DropTarget 
                        upload={async (files) => { this.imageFileList = files} } 
                        dropTargetView={dropTargetView}>
                    {children}
                </DropTarget>
            </div>
        )
    }
}

interface IImageUploader {
    irt: ImagesRoot,
    imageFileList: FileList, // images being dropped for upload
    onDone: () => void,
}

@observer
class ImageUploader extends Component<IImageUploader> {
    @observable image: ImageMetadata | null = null

    @observable message = ''

    constructor(props: IImageUploader) {
        super(props)
        makeObservable(this);
        this.createDefaultImage()
    }

    createDefaultImage() {
        let references = this.props.irt.searchRefs

        let { irt } = this.props
        let { rt } = irt

        let image = new ImageMetadata()

        let fileName = (new Date()).toISOString() // Use date/time as file name
        fileName = fileName.replace(/[\-:.]/g, '_')
        image.fileName = fileName

        image.isProjectImage = true

        let projectName = rt.project.name

        image.id = `${projectName}/${fileName}`
        image.project = projectName
        image.path = projectName

        image.references = references
        image.copyright = rt.project.copyrightStatement
        let definition = new ImageDefinition()
        definition.languageCode = 'en'
        image.definitions = [definition]

        this.image = image
        this.message = ''

        log('createDefaultImageFromFile', JSON.stringify(image, null, 4))
    }

    render() {
        let { image, message } = this
        let { irt, imageFileList, onDone } = this.props
        let { rt, images } = irt // ref images to ensure redraw on changes
        let { iAmConsultant } =rt

        // log('ImageUploader render', fmt({image, message}))

        if (!iAmConsultant) {
            displayError(t`Observers cannot upload images`)
            onDone()
            return null
        }

        if (imageFileList.length > 1) {
            displayError(t`You must drop exactly one files`)
            onDone()
            return null
        }

        let file = imageFileList[0]

        const allowedImageTypes = ['image/jpeg', 'image/png']
        if (!allowedImageTypes.includes(file.type)) {
            displayError(t`Cannot upload this type of file`)
            onDone()
            return null
        }

        if (image) {
            let src = window.URL.createObjectURL(file)
            return (
                <div>
                    <div>
                        <img src={src} className='image-thumbnail-editing' />
                    </div>
                    <ImageMetadataEditor
                        irt={irt}
                        showDeleteButton={false} // Image has not been created yet, nothing to delete
                        close={async (saved) => {
                            if (saved) {
                                await this.uploadImage()
                                await irt.refresh()
                            }
                            irt.rt.editingImage = false
                            onDone()
                        }}
                        image={image} />
                </div>
            )
        }

        return (<span>{message || ''} <LoadingIcon className='' /></span>)
    }

    uploadImage = async () => {
        let { imageFileList, irt, onDone } = this.props
        let { image } = this

        this.message = t`Uploading image...`
        log(this.message)

        try {
            await ProjectImages.putProjectImageDataAndMetadata(imageFileList[0], image!)
            irt.adjustSearchRefs(image!.references)

            // remember copyright used so that we can supply it as the default
            // for this project in the future.
            await this.props.irt.rt.project.setCopyrightStatement(image!.copyright)
        } catch (error) {
            log('###uploadImage', error)
            displayError(error, t`Cannot upload image at this time.`)
        }

        this.message = ''
        onDone()
    }
}


interface IImageSearchToolbar {
    irt: ImagesRoot
}

@observer
class ImageSearchToolbar extends Component<IImageSearchToolbar> {
    @observable search = ''  // e.g. Luke 6.1-4
    @observable errored = false

    constructor(props:IImageSearchToolbar) {
        super(props)

        makeObservable(this);

        let { irt } = this.props
        this.search = irt.rt.displayableReferences(irt.searchRefs)
        log(`ImageSearchToolbar constructor /${this.search}/`)
    }

    render() {
        let { irt } = this.props
        let { rt, showImageTitles } = irt
        let { searchRefs } = irt // to force redraw on change
        let { search, errored } = this

        return (
            <div className="image-references-toolbar">
                {!rt.editingImage && <ReferenceInput 
                    refInput={rt}
                    refs={[]}
                    setRefs={refRanges => { irt.setSearchRefs(refRanges)}}
                    errored={errored}
                    setErrored={value => this.errored = value}
                    includeGotoReferenceButton={true}
                    defaultReferenceId={'ImagesReference'} />}
                <label className='image-display-control'>
                    <div className="image-title-switch">
                        <Switch
                                className="image-title-switch"
                                value={showImageTitles}
                                setValue={
                                    (value: boolean) => {
                                        irt.showImageTitles = value
                                        localStorage.setItem('showImageTitles', value ? 'true' : '')
                                    }}
                                enabled={true}
                                tooltip={t`Show image titles`}>
                            <TitleIcon className="image-title-tag" tooltip={undefined} />
                        </Switch>
                    </div>
                </label>
            </div>
        )
    }

}


// =======================================================================
// Display thumbnails for a list of images or the currently selected image.

interface IImagesList {
    irt: ImagesRoot,
}

@observer
class ImagesList extends Component<IImagesList> {
    @observable currentImage: ImageMetadata | null = null

    constructor(props: IImagesList) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { irt } = this.props
        let { currentImage } = this
        let { images } = irt
        let { loading } = irt.imageCollection

        // log('ImagesList render', fmt({loading, length: images.length, currentImage}))

        if (loading) {
            return (<LoadingIcon className="" />)
        }

        // Need to do this to force a re-render of when this changes
        let { showImageTitles } = irt

        if (currentImage) return (
            <ImageViewer
                irt={irt}
                image={currentImage}
                images={images}
                onClose={() => this.currentImage = null }
            />
        )

        if (images.length === 0) return ( t`No results found` )

        let projectImages = images.filter(img => img.isProjectImage)
        let marbleImages = images.filter(img => !img.isProjectImage)

        return (
            <div>
                <div className="image-area-images">
                    <div className="image-area-grid">
                        {projectImages.map(image => (
                            <ImageThumbnail {...{irt, key: image.id, image}}
                                viewImage={() => { this.currentImage = image }}
                                editImage={() => { this.currentImage = image; irt.rt.editingImage = true }} />
                        ))}
                    </div>

                    {(projectImages.length && marbleImages.length) ? <hr/> : undefined }

                    <div className="image-area-grid">
                        {marbleImages.map(image => (
                            <ImageThumbnail {...{ irt, key: image.id, image }}
                                viewImage={() => { this.currentImage = image }}
                                editImage={() => { this.currentImage = image; irt.rt.editingImage = true }} />
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}


interface IImageThumbnail {
    irt: ImagesRoot,
    image: ImageMetadata,
    viewImage: () => void,
    editImage: () => void,
}

const ImageThumbnail: FC<IImageThumbnail> = ({irt, image, viewImage, editImage}) => {
    let [src, setSrc] = useState(image.isProjectImage ? '' : image.thumbnail)

    const componentIsMounted = useRef(true)
    useEffect(() => { return () => {componentIsMounted.current = false} }, [])

    if (!src && image.isProjectImage) {
        return (
            <div className='image-search-item'>
                <VideoDownloading
                    className='media-placeholder'
                    videoUrl={image.thumbnail}
                    creator=''
                    fontSizePt={28}
                    onEnded={blob => componentIsMounted.current && setSrc(window.URL.createObjectURL(blob))}
                />
            </div>
        )
    }

    let { rt } = irt
    let { name } = rt.project

    let definition = image.getDefinition(rt.uiLanguage)
    let title = definition.title

    let tooltip = imageTooltip(image, rt)

    let projectNamesMatch = name === image.project
    let canEditImage = image.isProjectImage && projectNamesMatch

    let className = 'image-search-item'

    if (image.isProjectImage) {
        className = (image.shared ? 'shared-' : '') + 'project-image-thumbnail ' + className
    }

    if (src) {
        return (
            <div className={className}>
                <input
                    type='image'
                    src={src}
                    className={`image-container image-thumbnail image-button`}
                    data-toggle='tooltip'
                    title={tooltip}
                    onClick={viewImage}
                />
                {canEditImage &&
                    <EditButton enabled={true}
                        className='image-search-edit-button'
                        onClick={editImage}
                        tooltip={t`Edit image`} />
                }
                {irt.showImageTitles &&
                    <span className='image-title'>{title}</span>
                }
            </div>
        )
    }

    return null
}


const imageTooltip = (image: ImageMetadata, rt: Root) => {
    let { copyright, references } = image

    let definition = image.getDefinition(rt.uiLanguage)

    let { title, description } = definition

    let lines: string[] = []
    if (title || description) { lines.push(title + (description ? ' / ' + description : '')) }

    if (copyright) { lines.push('\u00a9' + copyright) }

    lines.push(rt.displayableReferences(references))
    
    return lines.join('\n')
}

/*
    This is the control that is seen after the user has selected a specific image.
    It allows viewing that image and stepping through the list of images.
    Allow user to step through list.
 */

interface IImageViewerProps {
    irt: ImagesRoot,
    image: ImageMetadata,
    images: ImageMetadata[], // List of images from which image was chosen
    onClose: () => void, // called when user is done viewing individual images
}

@observer
class ImageViewer extends Component<IImageViewerProps> {
    @observable src = ''
    @observable image: ImageMetadata
    index = 0

    constructor(props: IImageViewerProps) {
        super(props)

        makeObservable(this);

        let { image, images } = this.props

        this.image = image
        this.index = images.findIndex(img => img.id === this.image.id)
    }

    render() {
        let { irt, onClose, images } = this.props
        let { rt } = irt

        let { src, index, image } = this

        log('ImageViewer render', JSON.stringify(image, null, 4))

        let _index1 = index + 1

        return (
            <div className='image-viewer' onKeyDown={this.onKeyDown}>
                <Optional show={!rt.editingImage} >
                    <div className='image-viewer-toolbar'>
                        <div className='image-viewer-toolbar-start'>
                            {src &&
                                <ExpandButton
                                    className='image-expand-button'
                                tooltip={/* translator: important */ t`View full size`}
                                    src={src}
                                />
                            }
                        </div>
                        {images.length > 1 &&
                            <div className='image-viewer-toolbar-middle'>
                                <PreviousSegmentButton
                                    enabled={index > 0}
                                    onClick={() => this.goToImage(index - 1)}
                                    tooltip={/* translator: important */ t`Go to previous image`}
                                />
                                <div className='image-pane-header-label'>
                                    {t`${_index1} of ${images.length}`}
                                </div>
                                <NextSegmentButton
                                    enabled={index !== -1 && index < images.length - 1}
                                    onClick={() => this.goToImage(index + 1)}
                                    tooltip={/* translator: important */ t`Go to next image`}
                                />
                            </div>
                        }
                        <div className='image-viewer-toolbar-end'>
                            <PaneCloseButton
                                className='image-viewer-close-button'
                                tooltip={t`Close pane`}
                                enabled={true}
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div className="image-viewer-title">{this.imageTitle()}</div>
                </Optional>
                <FullSizeImageViewer
                    irt={irt}
                    image={image}
                    setSrc={src => this.src = src}
                />
                {rt.editingImage &&
                    <ImageMetadataEditor 
                        irt={irt} 
                        showDeleteButton={true}
                        image={image} 
                        close={async (saved) => {
                            if (saved) {
                                await ProjectImages.putProjectImageMetadataToDynamoDB(image)
                                irt.adjustSearchRefs(image.references)
                                await irt.refresh()
                            }
                            irt.rt.editingImage = false
                            onClose()
                        }} />
                }
            </div>
        )
    }

    imageTitle = () => {
        let { image } = this

        let title = image.getDefinition(this.props.irt.rt.uiLanguage).title

        return title
    }

    goToImage = (index: number) => {
        let { images } = this.props
        log('goToImage', index, images.length)
        
        this.src = ''
        this.index = index
        this.image = images[index]
    }

    onKeyDown = (e: React.KeyboardEvent) => {
        let { onClose: close } = this.props
        e.stopPropagation()
        if (e.key === 'Escape') {
            close()
        }
    }
}


interface IFullSizeImageViewer {
    irt: ImagesRoot,
    image: ImageMetadata,
    setSrc: (src: string) => void,
}

const FullSizeImageViewer: FC<IFullSizeImageViewer> = ({ irt, image, setSrc }) => {
    log('FullSizeImageViewer')

    let [source, setSource] = useState('')

    const componentIsMounted = useRef(true)
    useEffect(() => { return () => { componentIsMounted.current = false } }, [])

    useEffect(() => {
        async function fetchImage() {
            log('FullSizeImageViewer fetchImage', image.id)

            let src = await image.fetchImageSrc()
            if (componentIsMounted.current) {
                setSrc(src)
                setSource(src)
            }
        }

        fetchImage()
    }, [image.id])

    let className = 'image-thumbnail'
    if (irt.rt.editingImage) className += ' image-thumbnail-editing'

    if (source) {
        return (<img src={source} className={className} />)
    }

    return (<div className='media-placeholder' />)
}






