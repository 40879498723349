import React, { FC, useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import debug from "debug"
import { Button } from 'react-bootstrap'


import { AppRoot } from "../../models3/AppRoot"
import { parseJwt } from "./Login"
import { fmt, s } from "../utils/Fmt"
import { t } from "ttag"
import { LoadingIcon } from "../utils/Icons"

/**
 * Testing:
 * 1) Can login using email address and a password.
 * 2) Can login using gmail account.
 * 3) Can login using emailed link.
 * 4) Can logout.
 * 5) Test id_token expiration (when using 8 day tokens from Auth0)
 */


const log = debug('sltt:Auth0') 

interface IAuth0Login { appRoot: AppRoot }

export const Auth0Login: FC<IAuth0Login> = ({ appRoot }) => {
    const { isAuthenticated, isLoading, error: auth0Error, getIdTokenClaims, loginWithRedirect } = useAuth0()

    let [error, setError] = useState('')

    log('Auth0Login', fmt({ isAuthenticated, isLoading, auth0Error, error, getIdTokenClaims: !!getIdTokenClaims }))

    // Automatically take user to login screen if no valid id_token
    useEffect(() => {
        log('loginWithRedirect', fmt({ isLoading, isAuthenticated, id_token: appRoot.id_token }))

        if (isLoading || isAuthenticated || appRoot.id_token) return
        const fn = async () => { 
            await loginWithRedirect() 
        }
        fn()
    }, [isLoading, isAuthenticated, loginWithRedirect, appRoot.id_token])

    // After authentication is complete get id_token.
    useEffect(() => {
        const getIdToken = async () => {
            log('getIdToken Before', fmt({ isAuthenticated}))

            if (!isAuthenticated) { return }

            try {
                let claims = await getIdTokenClaims()
                log('getIdToken AFTER', s(claims))
                
                if (!claims) {
                    log('### empty claims')
                    return
                }

                let {email_verified, __raw: id_token} = claims
                if (!email_verified) {
                    const message = t`Please do the following:
1) Go to your email account and find the message "Verify you email".
2) In that email click the button "VERIFY YOUR ACCOUNT".
3) Refresh this web page (or close it and open it again)`
                    setError(message)
                    return
                }

                let decoded = parseJwt(id_token)
                appRoot.setUser(decoded.email, id_token)
            } catch (e) {
                console.log(e)
            }
        }

        getIdToken()
    }, [getIdTokenClaims, isAuthenticated])

    // If we already have an id_token there is nothing to do
    if (appRoot.id_token) return null

    if (auth0Error) {
        return <div>{t`Something went wrong with Sign On...` + auth0Error}</div>
    }

    if (error) {
        return <pre>{error}</pre>
    }

    if (isLoading) {
        return (<LoadingIcon className='initialization-message-icon' />)
    }

    // Should we login automatically or wait for the user to click a button?
    // At the moment this is automatic, not sure if that is good or not.

    // return (
    //     <Button
    //         color="primary"
    //         className="btn-margin"
    //         onClick={() => loginWithRedirect()}
    //     >
    //         Log in
    //     </Button>
    // )

    return null
}


