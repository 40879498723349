import React from 'react'
import { t } from 'ttag'
import { Root } from '../../models3/Root'
import { Portion } from '../../models3/ProjectModels'
import { getByIso639dash1 } from './Languages'
import { TRLLicense } from '../../models3/TRLModel'


export function getLicenseName(option: TRLLicense) {
    switch (option) {
        default:
        case TRLLicense.ASK:
            return t`Ask`
        case TRLLicense.CC0:
            return t`Creative Commons - No Rights Reserved`
        case TRLLicense.CC_BY:
            return t`Creative Commons - Attribution`
        case TRLLicense.CC_BY_SA:
            return t`Creative Commons - Attribution-ShareAlike`
        case TRLLicense.CC_BY_NC:
            return t`Creative Commons - Attribution-NonCommercial`
        case TRLLicense.CC_BY_NC_SA:
            return t`Creative Commons - Attribution-NonCommercial-ShareAlike`
        case TRLLicense.CC_BY_ND:
            return t`Creative Commons - Attribution-NoDerivatives`
    }
}

export enum ProjectTRLIssue {
    None,
    MissingCopyrightNotice,
    MissingLogo,
}

export function getProjectTRLIssue(rt: Root) {
    if (rt.project.copyrightNotice.trim().length === 0)
        return ProjectTRLIssue.MissingCopyrightNotice
    if (rt.project.organizationLogoUrl.trim().length === 0)
        return ProjectTRLIssue.MissingLogo
    else
        return ProjectTRLIssue.None
}

export function getProjectTRLIssueTooltip(
    issue: ProjectTRLIssue,
    defaultToolTip: string = `Publish to the Translation Resource Library`
) {
    switch (issue) {
        default:
        case ProjectTRLIssue.None:
            return defaultToolTip
        case ProjectTRLIssue.MissingCopyrightNotice:
            return `Complete project's publication copyright notice before publishing`
        case ProjectTRLIssue.MissingLogo:
            return `Complete project's publication organization logo before publishing`
    }
}

export function getPortionTitleStatus(trlContext: IPortionsListTRLContext, portion: Portion) {
    const { titleLanguage, isTRLProject } = trlContext
    const canShowTitleEditor = Boolean(titleLanguage) && isTRLProject
    const isMissingTitle = !canShowTitleEditor || portion.hasMissingTitle(titleLanguage)
    return { canShowTitleEditor, isMissingTitle }
}

export function getMissingTitlesMessage(titleLanguage: string) {
    const titleLanguageLocalName = getByIso639dash1(titleLanguage)?.local || ''
    return `There are titles missing in following language:` + ' ' + titleLanguageLocalName
} 

export function getTitlesMessage(trlContext: IPortionsListTRLContext, portion: Portion) {
    const { titleLanguage } = trlContext
    const { isMissingTitle } = getPortionTitleStatus(trlContext, portion)
    const trlTooltip = isMissingTitle && getMissingTitlesMessage(titleLanguage) || undefined
    return trlTooltip
}

export function portionIsPublishedInTRL(rt: Root, portionId: string) {
    return getPublishedPortion(rt, portionId) !== undefined
}

export function getPublishedPortion(rt: Root, portionId: string) {
    const publishedResource = rt.trlResourcesIndexedByResourceName[rt.project.name]
    return publishedResource?.portions.find(p => p.portion_id === portionId)
}

export function getTRLIconBadgeClass({ isTRLPublishedPublished, hasTrlError, hasTrlWarning }: 
    { isTRLPublishedPublished: boolean; hasTrlError: boolean, hasTrlWarning: boolean }
) {
    return hasTrlError && `error-true fas fa-exclamation-circle` ||
        hasTrlWarning && `warning-true fas fa-exclamation-triangle` || 
        isTRLPublishedPublished && 'published-true fas fa-check-circle' || 'warning-false'
}

PortionsListTRLContextComponent.defaultProps = {
    titleLanguage: '',
    isTRLProject: false,
    projectTrlIssue: ProjectTRLIssue.None,
    portionsEditorRef: null,
}

export interface IPortionsListTRLContext {
    titleLanguage: string,
    isTRLProject: boolean,
    projectTrlIssue: ProjectTRLIssue,
    portionsEditorRef: any,
}

export const PortionsListTRLContext = React.createContext<IPortionsListTRLContext>(
    { ...PortionsListTRLContextComponent.defaultProps }
)

interface IPortionsListTRLContextComponent extends IPortionsListTRLContext {
    children: any,
}

export function PortionsListTRLContextComponent(props: IPortionsListTRLContextComponent) {
    const { isTRLProject, projectTrlIssue, titleLanguage, portionsEditorRef } = props
    return <PortionsListTRLContext.Provider value={
        {
            isTRLProject, projectTrlIssue, titleLanguage, portionsEditorRef
        }
    }>{props.children}</PortionsListTRLContext.Provider>
}
