// This is the component that displays in the main window when 
// 1) A video is being compressed
// 2) There is no video available for the current passage

import React, { Component, FC, useEffect, useRef, useState } from 'react'
// import { extendObservable } from 'mobx'
import { observer } from 'mobx-react'
import { t, jt } from 'ttag'

import { PassageAddButton } from '../utils/Buttons'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import LoadingMessage from '../utils/InitializationMessage'
import { useCreatorName } from '../utils/useCreatorName'

import _debug from "debug"; let log = _debug('sltt:VideoMessage')

interface IVideoMessage {
    rt: Root,
}

class VideoMessage extends Component<IVideoMessage> {
    render() {
        let { portion, passage, iAmTranslator } = this.props.rt

        let videod = (passage && passage.videos && passage.videos.length > 0) || false

        if (passage?.videoBeingCompressed) {
            return (
                <div className="video-message">
                    <LoadingMessage loadingMessage={passage.compressionProgressMessage}/>
                </div>
            )
        }

        if (passage && videod)
            return (
                null

                // Following message is too 'busy'. I am supressing it for now.
                // <div className="video-message">
                //     <ul><li>Click <PlayButton enabled={true} />&nbsp;above or type Ctrl-space to play passage.</li></ul>
                // </div>
            )
        
        if (passage && iAmTranslator)
            return (
                null

                // Following message is too 'busy'. I am supressing it for now.
                // <div className="video-message">
                //     <ul><li>Click <RecordButton enabled={true} /> above to record passage.</li></ul>
                // </div>
            )
        
        if (passage && !videod && !iAmTranslator)
            return (
                <div className="video-message">
                    <ul><li>{/* translator: important */ t`No video available yet for this passage.`}</li></ul>
                </div>
            )
            
        // At this point we have a portion selected
                        
        let passagesPresent = (portion?.passages?.length ?? 0) > 0
                    
        if (!passagesPresent && !iAmTranslator)
            return (
                <div className="video-message">
                    <ul><li>{/* translator: important */ t`No passages available yet for this portion`}</li> </ul>
                </div>
        )
                        
        let portionsLabel = <strong>{/* translator: important */ t`Portions`}</strong>
        let passagesLabel = <strong>{/* translator: important */ t`passages`}</strong>
        let passageAddButton = <PassageAddButton onClick={null} tooltip="" enabled={true} />
        if (!passagesPresent && iAmTranslator)
            return (
                <div className="video-message">
                    <ul>
                        <li>{/* translator: important */ jt`${portionsLabel} are divided into ${passagesLabel}.`}<br/>
                            {/* translator: important */ t`Each passage is a single video.`}</li>
                    </ul>
                </div>
            )
                            
        // At this point we have a portion with passages selected
        if (iAmTranslator)
            return (
                <div className="video-message">
                    <ul><li>{/* translator: important */ jt`At left, select a passage or click 
                        ${passageAddButton} to add a passage.`}</li></ul>
                </div>
        )
                                
        return (
            <div className="video-message">
                <ul><li>{/* translator: important */ t`At left, select a passage.`}</li></ul>
            </div>
        )
    }
}

type VideoDownloadingMessageProps = {
    urls: string[],
    creator: string,
    fontSizePt: number,
}

interface ILine {
    x1: number,
    y1: number,
    x2: number,
    y2: number,
}

async function getProgress(urls: string[]) {
    let result = {uploaded: 0, downloaded: 0, total: 0}

    for (let url of urls) {
        let response = await VideoCache.queryProgress(url)
        
        result.uploaded += response.numberUploaded
        result.downloaded += response.numberDownloaded
        result.total += response.totalBlobs
    }

    return result
}

export const VideoDownloadingMessage: FC<VideoDownloadingMessageProps> = observer(({ urls, creator, fontSizePt }) => {
    const [numberUploaded, setNumberUploaded] = useState(0)
    const [numberDownloaded, setNumberDownloaded] = useState(0)
    const [totalBlobs, setTotalBlobs] = useState(0)
    const [showCanvas, setShowCanvas] = useState(false)
    const [progressQueried, setProgressQueried] = useState(false)
    const { creatorName } = useCreatorName(creator)

    function getProgressLoop() {
        getProgress(urls)
            .then(({uploaded, downloaded, total}) => {
                setNumberUploaded(uploaded)
                setNumberDownloaded(downloaded)
                setTotalBlobs(total)
                setProgressQueried(true)
            })
    }

    useEffect(() => {
        let intervalId = setInterval(getProgressLoop, 1000)
        return () => { clearInterval(intervalId) }
    }, [urls])

    // We only want to show progress if it is taking a while, otherwise there is unnecessary flashing
    useEffect(() => {
        let timer = setTimeout(() => {
            setShowCanvas(true)
        }, 3000)
        return () => { timer && clearTimeout(timer) }
    }, [])

    const canvasWidth = 500
    const canvasHeight = 400

    function draw(ctx: CanvasRenderingContext2D) {
        ctx.clearRect(0, 0, canvasWidth, canvasHeight)
        ctx.font = '900 48px "Font Awesome 5 Free"'
        ctx.fillStyle = 'gray'
        ctx.fillText('\uf0c2', 220, 70)

        const uploadArrow: ILine = { x1: 110, y1: 300, x2: 220, y2: 100 }
        const downloadArrow: ILine = { x1: 280, y1: 100, x2: 390, y2: 300 }

        const TOP_LEFT_CORNER_X = uploadArrow.x1
        const TOP_LEFT_CORNER_Y = uploadArrow.y2
        const TOP_LEFT_CORNER_DOWNLOAD_X = downloadArrow.x1
        const TOP_LEFT_CORNER_DOWNLOAD_Y = downloadArrow.y1

        ctx.beginPath()
        ctx.lineWidth = 2
        ctx.moveTo(uploadArrow.x1, uploadArrow.y1)
        ctx.lineTo(uploadArrow.x2, uploadArrow.y2)
        ctx.moveTo(downloadArrow.x1, downloadArrow.y1)
        ctx.lineTo(downloadArrow.x2, downloadArrow.y2)

        // Arrowheads
        ctx.moveTo(uploadArrow.x2, uploadArrow.y2)
        ctx.lineTo(uploadArrow.x2 - 20, uploadArrow.y2 + 20)
        ctx.moveTo(uploadArrow.x2, uploadArrow.y2)
        ctx.lineTo(uploadArrow.x2 - 6, uploadArrow.y2 + 28)

        ctx.moveTo(downloadArrow.x2, downloadArrow.y2)
        ctx.lineTo(downloadArrow.x2 - 6, downloadArrow.y2 - 28)
        ctx.moveTo(downloadArrow.x2, downloadArrow.y2)
        ctx.lineTo(downloadArrow.x2 - 20, downloadArrow.y2 - 20)
        ctx.stroke()

        ctx.font = `${fontSizePt}pt sans-serif`
        ctx.fillStyle = 'black'
        ctx.fillText(creatorName, uploadArrow.x1 - ctx.measureText(creatorName).width / 2, uploadArrow.y1 + 40)

        function drawUploadProgress() {
            let width = Math.abs(uploadArrow.x2 - uploadArrow.x1)
            let height = Math.abs(uploadArrow.y2 - uploadArrow.y1)

            let pos = (percent: number) => ({
                x: (width / 100) * percent + TOP_LEFT_CORNER_X,
                y: (height / 100) * (100 - percent) + TOP_LEFT_CORNER_Y
            })

            let percent = numberUploaded / totalBlobs * 100
            if (isNaN(percent) || percent === Infinity) { // Divided by 0
                percent = 0
            }

            let _pos = pos(percent)
            ctx.beginPath()
            ctx.fillStyle = 'red'
            let radius = 7.5
            ctx.arc(_pos.x, _pos.y, radius, 0, 2 * Math.PI, false)
            ctx.fill()
        }

        function drawDownloadProgress() {
            let width = Math.abs(downloadArrow.x2 - downloadArrow.x1)
            let height = Math.abs(downloadArrow.y2 - downloadArrow.y1)

            let pos = (percent: number) => ({
                x: (width / 100) * percent + TOP_LEFT_CORNER_DOWNLOAD_X,
                y: (height / 100) * percent + TOP_LEFT_CORNER_DOWNLOAD_Y
            })

            let percent = numberDownloaded / totalBlobs * 100
            if (isNaN(percent) || percent === Infinity) { // Divided by 0
                percent = 0
            }

            let _pos = pos(percent)
            ctx.beginPath()
            ctx.fillStyle = 'red'
            let radius = 7.5
            ctx.arc(_pos.x, _pos.y, radius, 0, 2 * Math.PI, false)
            ctx.fill()
        }

        if (numberUploaded === 0 && !progressQueried) {
            return
        }

        if (numberUploaded < totalBlobs) {
            drawUploadProgress()
        } else {
            drawDownloadProgress()
        }
    }

    return (
        <div className='video-loading-progress-message'>
            {showCanvas &&
                <Canvas className='video-loading-message-canvas' draw={draw} width={canvasWidth} height={canvasHeight} /> }
        </div>
    )
})

type CanvasProps = {
    draw: (context: CanvasRenderingContext2D) => void,
    width: number,
    height: number,
    className?: string,
}

const Canvas: FC<CanvasProps> = ({ draw, width, height, className }) => {
    const ref = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const context = ref.current?.getContext('2d')
        if (context) {
            draw(context)
        }
    })

    return <canvas {...{ className, ref, width, height }} />
}


export default observer(VideoMessage)
