// NOT CURRENTLY USED. PROBABLY NEVER. 

import React, { Component, FC, useContext, useEffect, useState } from "react"
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react"
import { t } from 'ttag'

import { Root } from "../../models3/Root"
import '../video/NoteLocationEditor.css'
import { NextSegmentButton, PaneCloseButton, PreviousSegmentButton } from "../utils/Buttons"
import { displayError } from "../utils/Errors"
import RangeVideoPlayer from "../video/RangeVideoPlayer"
import { PositionSetter, VideoTimeline } from "../video/VideoTimeline"
import { ViewableVideoCollection } from "../video/ViewableVideoCollection"
import VideoTimelinePlayButtons from "../video/VideoTimelinePlayButtons"
import { fmt, s } from "../utils/Fmt"
import { GlossReference, GlossReferences } from "../../models3/GlossReferences"
import { RefRange } from "../../scrRefs/RefRange"

import _debug from "debug"; let log = _debug('sltt:GlossViewer')

interface IGlossesViewer {
    rt: Root,
    glossShowing: string,
    setGlossShowing: (glossText: string) => void,
}

export const GlossesViewer: FC<IGlossesViewer> = ({ rt, glossShowing, setGlossShowing }) => {
    let { project, passage } = rt
    if (!passage) return null

    let [glossReferences, setGlossReferences] = useState<GlossReferences|null>(null)
    let [index, setIndex] = useState(0)

    useEffect(() => {
        log('!!!useEffect', glossShowing)
        let _glossReferences = new GlossReferences(project, glossShowing, rt.passage!)
        setGlossReferences(_glossReferences)
        setIndex(_glossReferences.index)
    }, [glossShowing])

    let count = glossReferences?.glossReferences.length ?? 0

    let portion = rt.project.findPortion(passage._id)
    let passageName = portion?.name + ' / ' + passage.name

    let { references } = passage
    let displayableReferences = RefRange.refRangesToDisplay(references, rt.project)

    let glossReference = glossReferences ? glossReferences.glossReferences[index] : null

    return (
        <div>
            <div className="segment-selector">
                <PreviousSegmentButton
                    enabled={index > 0}
                    tooltip={t`Go to previous gloss.`}
                    onClick={() => setIndex(_index => _index-1)} />
                <span className="segment-selector-text">
                    {`${index+1} / ${count}`}
                </span>
                <NextSegmentButton
                    enabled={index < count-1}
                    tooltip={t`Go to next gloss.`}
                    onClick={() => setIndex(_index => _index + 1)} />
            </div>
            <div>{passageName}&nbsp;&nbsp;&nbsp;({displayableReferences})</div>
            <div>glossShowing: {glossShowing}</div>
            {glossReference && <GlossVideoPlayer rt={rt} glossReference={glossReference} />}
        </div>
    )
}

interface IGlossVideoPlayer {
    rt: Root,
    glossReference: GlossReference,
    // setGlossReference: (reference: GlossReference | null) => void,
}

@observer
export class GlossVideoPlayer extends Component<IGlossVideoPlayer> {
    @observable isPlaying = false

    @observable currentTime = 0

    rangeVideoPlayer: RangeVideoPlayer | null = null

    setters: PositionSetter[] = [] // passed to VideoTimeline to allow updating of positions relating to note
        // in order the are note.startPosition, note.position, note.endPosition, currentTime

    startingTime = 0
    endingTime = 0

    currentVideos: ViewableVideoCollection | null = null


    constructor(props: IGlossVideoPlayer) {
        super(props)

        makeObservable(this);

        this.setupSetters()
        this.setupVideo()
    }

    // Setup noteVideo to contain only the patches were available
    // at the time the note was originally created.
    //
    // Start download process
    setupVideo() {
        let { rt, glossReference } = this.props
        let { passageVideo } = glossReference
        let { passage } = glossReference.info(rt)

        let { gloss } = glossReference
        log('gloss', s(gloss.dbg()))

        this.startingTime = glossReference.gloss.time
        this.currentTime = this.startingTime
        this.endingTime = this.startingTime

        if (passage) {
            this.endingTime = this.startingTime + glossReference.gloss.duration(passage, passageVideo)
        }

        let { startingTime, endingTime } = this
        log('setupVideo', fmt({startingTime, endingTime}))

        this.currentVideos = new ViewableVideoCollection()
        this.currentVideos.setup(passage!, glossReference.passageVideo)
        this.currentVideos.download().catch(displayError)
    }

    setupSetters() {
        this.setters = [
            // set currentTime
            new PositionSetter(
                '3',
                () => 0,
                () => this.endingTime - this.startingTime,
                (value: number) => {
                    this.setCurrentTime(value + this.startingTime)
                }
            ),
        ]
    }

    // Convert relative position to time and pass to player
    setCurrentTime(currentTime: number) {
        this.currentTime = currentTime
        // log('setCurrentTimePosition', fx(this.currentTime))
        
        if (!this.isPlaying) {
            // if we are not playing move the player to the selected point in time
            this.rangeVideoPlayer?.setCurrentTime(this.currentTime)
        }
    }

    render() {
        let { rt, glossReference } = this.props
        let { passageVideo } = glossReference
        let { startingTime, endingTime, currentVideos } = this

        let { passage } = glossReference.info(rt)
        let { playbackRate, setPlaybackRate } = rt

        // to force re-rendering of toolbar
        let { isPlaying } = this

        return (
            <div className="project-reference-video-player-content">
                <div className='note-location-editor-video'>
                    <div className="project-reference-video-player-title">{glossReference.info(rt).tooltip}</div>
                    <RangeVideoPlayer
                        className="project-reference-video-player-player"
                        ref={(rvp:any) => this.rangeVideoPlayer = rvp}
                        passage={passage!}
                        playbackRate={playbackRate}
                        setPlaybackRate={setPlaybackRate}
                        video={passageVideo}
                        currentVideos={currentVideos!}
                        startTime={startingTime}
                        endTime={endingTime}
                        onPlayingStatus={(isPlaying: boolean) => this.isPlaying = isPlaying }
                        onTick={(time: number) => {
                            this.setters[0].setValue(time - this.startingTime)
                        } }
                    />
                    <div className="video-timeline-area">
                        <VideoTimelinePlayButtons
                            isPlaying={this.isPlaying}
                            playAll={this.playAll}
                            pause={this.pause}
                        />
                        <div className="video-timeline">
                                <VideoTimeline
                                    setters={this.setters}
                                    domainStartPosition={0}
                                    domainEndPosition={endingTime - startingTime}
                                    adjustTime={time => {
                                        this.setters[0].setValue(time)
                                    }}
                                    enabled={true}
                                    allowAdjustingPositions={true}
                                />
                            </div>
                    </div>
                </div>
                {/* <div className="note-location-editor-close">
                    <PaneCloseButton
                        onClick={() => this.props.setGlossReference(null)}
                        enabled={true}
                        tooltip={t`Close pane`}
                        className='sl-pane-close-button' />
                </div> */}
            </div>
        )
    }

    playAll = async () => {
        this.isPlaying = true
        let { rangeVideoPlayer } = this
        if (!rangeVideoPlayer) return

        await rangeVideoPlayer.playRange(this.currentTime, this.endingTime)
    }

    pause = () => {
        let { rangeVideoPlayer } = this
        rangeVideoPlayer && rangeVideoPlayer.stop()
    }
}

