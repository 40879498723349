import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { VideoThumbnailPlayer } from '../../utils/MediaThumbnail'
import VideoDownloading from '../../utils/VideoDownloading'
import './TRLDownloadThumbnailVideoPlayer.css'
import { getOriginOrBaseUrl } from '../../utils/LocationHistory'

import _debug from "debug"; const log = _debug('sltt:TRLDownloadThumbnailVideoPlayer')

// This player will first download the thumbnail video and then show it in the VideoThumbnailPlayer

interface ITRLDownloadThumbnailVideoPlayer {
    loadedThumbnailUrl: string | undefined
    passageThumbnailVideoUrl: string | undefined
    setLoadedThumbnailUrl: React.Dispatch<React.SetStateAction<string | undefined>>
    contentCreator: string
    contentVideoUrl: string | undefined
    playThumbnailOnHoverOver?: boolean
    isTRLContentVideoPlaying: boolean
    setIsTRLContentVideoPlaying: React.Dispatch<React.SetStateAction<boolean>>
}
export const TRLDownloadThumbnailVideoPlayer: FC<ITRLDownloadThumbnailVideoPlayer> = observer(({
    loadedThumbnailUrl, passageThumbnailVideoUrl, setLoadedThumbnailUrl, playThumbnailOnHoverOver = false,
    contentCreator, contentVideoUrl, isTRLContentVideoPlaying, setIsTRLContentVideoPlaying
}) => {
    // log('render', { loadedThumbnailUrl, passageThumbnailVideoUrl, contentVideoUrl, isTRLContentVideoPlaying })
    
    const origin = getOriginOrBaseUrl()
    const missingThumbnailImageSrc = `${origin}/trl/video-16x9.svg`
    return <div className="video-placeholder">
        {!loadedThumbnailUrl && passageThumbnailVideoUrl && <VideoDownloading
            className={'media-placeholder'}
            videoUrl={passageThumbnailVideoUrl}
            creator={contentCreator}
            fontSizePt={30}
            onEnded={blob => setLoadedThumbnailUrl(window.URL.createObjectURL(blob))}
        />}
        {((loadedThumbnailUrl || !passageThumbnailVideoUrl) && contentVideoUrl) && <VideoThumbnailPlayer
            videoUrl={contentVideoUrl}
            preloadedThumbnailUrl={loadedThumbnailUrl || missingThumbnailImageSrc}
            thumbnailIsImage={!loadedThumbnailUrl && !passageThumbnailVideoUrl}
            creator={contentCreator}
            playOnHover={playThumbnailOnHoverOver}
            playbackRateOnHover={1}
            playing={isTRLContentVideoPlaying}
            setPlaying={(playing) => setIsTRLContentVideoPlaying(playing)}
        />}
    </div>
})
