// Accept dropped video files and upload them

import React, { Component, SFC } from 'react'
import { observable, makeObservable } from 'mobx';
import {observer} from 'mobx-react'
import { t } from 'ttag'

import './Passage.css'
import { displayError, displayInfo } from '../utils/Errors'
import { FfmpegParameters, Passage, PassageSegment } from '../../models3/ProjectModels'
import { Root } from '../../models3/Root'
import DropTarget from '../utils/DropTarget'
import { FileDateParser } from '../../models3/FileDateParser'
import { confirmAlert } from '../utils/ConfirmAlert'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import { SegmentPositionDialog } from '../segments/SegmentPositionDialog'
import { downloadFcpxml } from '../../finalcutpro/finalcutpro'
import VideoCompressor from '../utils/VideoCompressor'
import { fmt, s } from '../utils/Fmt'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import { importEAFFile } from '../../elan/ELANCreator'
import { canUploadWithCompression, canUploadWithoutCompression } from '../images/AllowedVideos'

import _debug from "debug";import { delay } from '../utils/delay';
 let log = _debug('sltt:VideoDropTarget') 

interface IUploadProgress {
    remaining: number,
}

let UploadProgress: SFC<IUploadProgress> = ({ remaining }) => {
    return (
        <span className="video-upload-progress">
            (<span className="video-upload-bounce fas fa-arrow-up" />
            {remaining.toFixed()})
        </span>
    )
}

interface IVideoDropTarget {
    rt: Root,
    passage: Passage,
    ondone?: () => void,
    videoIsPatch: boolean,
    segment?: PassageSegment,
    dropTargetView: JSX.Element,
}

function displayCompressorError(error: Error, maxVideoSizeMB: number) {
    if (error.name === 'NotEnoughFreeSpace') {
        displayError(/* translator: important */ t`Your hard drive does not have enough free space.`)
    } else if (error.name === 'PayloadTooLarge') {
        displayError(/* translator: important */ t`File upload is too large.`)
    } else if (error.name === 'CompressedFileTooLarge') {
        displayError(t`Could not compress video to less than ${maxVideoSizeMB}MB. Retry with different compression settings.`)
    } else {
        displayError(t`Could not compress video.`)
    }
}

class VideoDropTarget extends Component<IVideoDropTarget> {
    @observable percent = 0
    @observable remaining = 0
    @observable editingSegmentPosition = EditingSegmentPosition.None

    constructor(props: IVideoDropTarget) {
        super(props)
        makeObservable(this);
        this.setEditingSegmentPosition = this.setEditingSegmentPosition.bind(this)
    }

    render() {
        let { rt, children, dropTargetView } = this.props
        let { passage, passageVideo, passageSegment } = rt
        let { remaining, upload, editingSegmentPosition } = this

        if (editingSegmentPosition !== EditingSegmentPosition.None) {
            return (
                <SegmentPositionDialog {...{
                    passage: passage!, 
                    video: passageVideo!,
                    segmentIndex: passageVideo!.findSegmentIndex(passageSegment!._id), 
                    editingSegmentPosition,
                    close: (canceled, time, duration) => {
                        if (!canceled) {
                            rt.resetCurrentTime(time, duration)
                        } else {
                            
                        }
                        this.setEditingSegmentPosition(EditingSegmentPosition.None)
                    },
                }} />
            )
        }

        return (
            <DropTarget upload={upload} dropTargetView={dropTargetView}>
                {remaining > 0 && <UploadProgress remaining={remaining} />}
                {children}
            </DropTarget>
        )
    }

    setEditingSegmentPosition(value: EditingSegmentPosition) {
        log('setEditingSegmentPosition', value)
        this.editingSegmentPosition = value
    }

    upload = async (files: FileList, shiftKey: boolean, ctrlKey: boolean) => {
        let { rt, passage } = this.props
        let { iAmTranslator, passageVideo, recording } = rt

        if (recording) {
            displayError(t`Cannot upload a video while recording.`)
            return
        }

        if (passage.videoBeingCompressed) {
            displayError(t`A video in this passage is already being compressed.`)
            return
        }

        // Files with names starting __video are treated as test files and directy
        // loaded to the video cache
        if (files.length > 0 && files[0].name.startsWith('__video')) {
            for (let file of files) {
                await this.writeTestFileToCache(file)
            }

            return
        }

        if (!iAmTranslator) {
            displayError(/* translator: important */ t`Only admins or translators can upload videos to project.`)
            return
        }

        let setProgressMessage = (message: string) => {
            passage.compressionProgressMessage = message
        }

        if (files.length !== 1) {
            displayError(t`You must drop exactly one file.`)
            return
        }
        let file = files[0]

        if (file.name.startsWith('_test_comp_')) {
            await this.testCompressionServer(file, setProgressMessage)
            return
        }

        
        // Process Fincal Cut Pro file
        if (file.name.endsWith('.fcpxml')) {
            downloadFcpxml(rt, passage, passageVideo!, file)
                .catch(displayError)
            return
        }

        // Import ELAN gloss file
        if (file.name.endsWith('.eaf')) {
            importEAFFile(rt, passage, passageVideo!, file)
                .catch(displayError)
            return
        }

        if (this.needsCompression(file, shiftKey)) {
            await this.compressAndUpload(file, setProgressMessage)
            return
        }

        let creationDate = await this.getCreationDate(file)
        await this.uploadVideo(file, creationDate)
    }

    needsCompression(file: File, shiftKey: boolean) {
        if (file.name.includes('_force_compression_')) return true

        let sizeMB = file.size / (1024*1024)
        log('needsCompression?', fmt({sizeMB: sizeMB.toFixed(0)}))
        
        if (canUploadWithCompression(file) === '' && canUploadWithoutCompression(file) !== '') return true

        // Use configured maxVideoSizeMB or 256mb is shift key is pressed
        let { maxVideoSizeMB } = this.props.rt.project
        if (shiftKey) {
            maxVideoSizeMB = 256
        }

        return sizeMB > maxVideoSizeMB
    }

    async compressAndUpload(file: File, setProgressMessage: (message: string) => void) {
        log('compressAndUpload start')

        let isCompressorRunning = await VideoCompressor.checkIfServerRunning()
        if (!isCompressorRunning) { return }

        let { rt, videoIsPatch } = this.props
        let { maxVideoSizeMB, compressedVideoQuality, compressedVideoResolution } = rt.project
        try {
            // The original file may not exist after the compression. So get file
            // statistics beforehand.
            let creationDate = new Date()
            if (!rt.selectionPresent() && !videoIsPatch) {
                creationDate = await this.getCreationDate(file)
            }

            while (true) {
                let compressor = new VideoCompressor(compressedVideoQuality, compressedVideoResolution, maxVideoSizeMB, setProgressMessage)
                let { compressedFile, ffmpegParameters } = await compressor.compressVideo(file)
                
                let sizeMB = compressedFile.size/(1024*1024)
                log('compressAndUpload compressVideo', fmt({ sizeMB: sizeMB, compressedVideoResolution, compressedVideoQuality}))

                if (sizeMB <= maxVideoSizeMB) {
                    this.uploadVideo(compressedFile, creationDate, ffmpegParameters)
                    break
                }

                if (compressedVideoResolution > 480) {
                    displayInfo(t`Compressed 720p file too large. Try compressing to 480p.` + ` [${sizeMB.toFixed(0)}mb]`)
                    compressedVideoResolution = 480
                    continue
                }

                if (compressedVideoQuality < 28) {
                    compressedVideoQuality = Math.min(compressedVideoQuality+3, 28)
                    displayInfo(t`Compressed file still too large, trying again at lower quality.` + ` [${sizeMB.toFixed(0)}mb, crf=${compressedVideoQuality}]`)
                    continue
                }

                displayError(t`Compressed video still too large, upload failed.`)
                break
            }
        } catch (error) {
            let {name, message} = error as Error
            log('compressAndUpload ERROR', fmt({name, message}))

            setProgressMessage('')
            displayCompressorError(error as Error, maxVideoSizeMB)
        }
    }

    async uploadVideo(file: File, creationDate: Date, ffmpegParametersUsed?: FfmpegParameters) {
        try {
            let { rt, videoIsPatch, segment } = this.props
            let { maxVideoSizeMB } = rt.project
            this.validateFile(file, maxVideoSizeMB)
            if (rt.selectionPresent()) await this.uploadSelectionPatchFile(file, ffmpegParametersUsed)
            else if (videoIsPatch) await this.uploadSegmentPatchFile(file, segment, ffmpegParametersUsed)
            else await this.uploadFile(file, creationDate, ffmpegParametersUsed)
        } catch (err) {
            displayError(err)
        }

        this.remaining = 0
    }

    validateFile(file: File, maxFileSizeMB: number) {
        if (file.size > maxFileSizeMB*1024*1024) {
            throw new Error(/* translator: important */ t`File size too large, greater than ${maxFileSizeMB}M.`)
        }

        const errorMessage = canUploadWithCompression(file)
        if (errorMessage) {
            throw new Error(errorMessage)
        }
    }

    async testCompressionServer(file: File, setProgressMessage: (message: string) => void) {
        let isCompressorRunning = await VideoCompressor.checkIfServerRunning()
        if (!isCompressorRunning) { return }

        let { maxVideoSizeMB, compressedVideoResolution, compressedVideoQuality } = this.props.rt.project

        try {
            let compressor = new VideoCompressor(compressedVideoQuality, compressedVideoResolution, maxVideoSizeMB, setProgressMessage)
            let compressedData = await compressor.compressVideo(file)
            
            // Download compressed video file
            let href = window.URL.createObjectURL(compressedData.compressedFile)
            let link = document.createElement('a')
            link.setAttribute('href', href)
            link.setAttribute('download', 'compressed-video.mp4')
            link.click()
        } catch (error) {
            setProgressMessage('')
            displayError((error as Error).name)
        }
    }

    /* Check if selection is patchable.
     * If so, create a segment for the selection and upload a patch file to that segment.
     */
    async uploadSelectionPatchFile(file: File, ffmpegParametersUsed?: FfmpegParameters) {
        let { rt } = this.props
        let { passage, passageVideo, selectionStartTime, selectionEndTime } = rt
        if (!passage || !passageVideo) return
        
        if (!rt.patchableSelectionPresent(displayError)) { return }

        passageVideo.log(passage, 'before createSelectionSegment')

        let { segment } = await passageVideo.createSelectionSegment(passage, selectionStartTime, selectionEndTime)
        rt.setPassageSegment(segment) // set current segment to newly created segment
        passageVideo.log(passage, 'after createSelectionSegment')

        await this.uploadSegmentPatchFile(file, segment, ffmpegParametersUsed)
    }

    async uploadSegmentPatchFile(file: File, segment?: PassageSegment, ffmpegParametersUsed?: FfmpegParameters) {
        let { rt, passage, ondone } = this.props

        if (!segment) { throw new Error('Something went wrong. No segment for patch.') }

        let { passageVideo, name, portion } = rt
        if (!passageVideo || !portion) return

        let _onprogress = (remaining: number) => { this.remaining = remaining }

        // Always use todays date for patches. If we allow older dates for patch files
        // the patch may be excuded by VisiblePassageVideo later.
        let creationDate = new Date()
        let patch = await passage.uploadFile(file, creationDate, name, portion.name, _onprogress)

        if (ffmpegParametersUsed !== undefined) {
            patch.ffmpegParametersUsed = ffmpegParametersUsed
        }

        await passage.addPatchVideo(passageVideo, patch, segment)
        passageVideo.log(passage, 'after addPatchVideo')

        await rt.setPassageVideo(passageVideo)
        rt.setPassageSegment(segment)
        await this.waitForVideoElementsToLoad()

        let actualSegment = segment.actualSegment(passage)
        rt.resetCurrentTime(actualSegment.time + 0.01)

        ondone?.()
        setTimeout(() => { this.remaining = 0 }, 2000)

        this.setEditingSegmentPosition(EditingSegmentPosition.Both)
    }

    async writeTestFileToCache(file: File) {
        let _id = file.name.slice(2, -4) + '-1'
        log('writeTestFileToCache', _id)
        
        let vcr = await VideoCacheRecord.get(_id)
        await vcr.deleteBlobs() // if existing video data for this file, remove it
        vcr.size = file.size
        await vcr.addBlob(file)
        
        log('!!!writeTestFileToCache DONE', _id)
    }
    
    async uploadFile(file: File, creationDate: Date, ffmpegParametersUsed?: FfmpegParameters) {
        let _onprogress = (remaining: number) => {
            this.remaining = remaining
        }

        let { rt, passage } = this.props

        let { name, portion } = rt
        let video = await passage.uploadFile(file, creationDate, name, portion!.name, _onprogress)

        if (ffmpegParametersUsed !== undefined) {
            video.ffmpegParametersUsed = ffmpegParametersUsed
        }

        // This will indirectly trigger uploading the video blob in the cache to S3
        // when DBAcceptor calls VideoCache.acceptPassageVideo
        await passage.addVideoWithDefaultSegment(video)
        let _video = passage.videos.find(v => v._id === video._id)
        await rt.setPassage(passage)

        // We set passageVideo to null first in order to ensure that
        // VideoMain will update. Otherwise there is a race condition
        // where DBAcceptor partially set up the passageVideo and triggers
        // a VideoMain render before the passageVideo has all the info
        // to correctly display.
        await rt.setPassageVideo(null)
        await rt.setPassageVideo(_video!)

        setTimeout(() => { this.remaining = 0 }, 2000)
    }

    async waitForVideoElementsToLoad() {
        while (true) {
            if (this.props.rt.currentVideos.downloaded) return
            await delay(50)
        }
    }

    async getCreationDate(file: File) {
        let { passage } = this.props
        let { videos } = passage
        let fileDateParser = new FileDateParser(videos, file)
        let creationDate = fileDateParser.getCreationDate()
        if (creationDate.getTime() > Date.now()) {
            throw new Error(t`Video creation date is in the future. If the video's
                filename contains a date, check that it is in ISO format.`)
        }

        let useToday = await this.shouldUseTodaysDate(creationDate)
        if (useToday) {
            creationDate = new Date()
        }
        return creationDate
    }

    async shouldUseTodaysDate(fileCreationDate: Date) {
        let { passage } = this.props
        let { videos } = passage

        // If no videos already present, always use date from file.
        if (videos.length === 0) return false

        let mostRecentVideo = videos[videos.length - 1]
        let mostRecentCreationDate = new Date(mostRecentVideo.creationDate)

        if (fileCreationDate.getTime() < mostRecentCreationDate.getTime()) {
            return await this.confirmUseTodaysDate(fileCreationDate, mostRecentCreationDate)
        }
        return false
    }

    async confirmUseTodaysDate(fileCreationDate: Date, mostRecentCreationDate: Date): Promise<boolean> {
        let { rt } = this.props
        let { dateFormatter } = rt
        let fileDateString = dateFormatter.format(fileCreationDate)
        let mostRecentCreationDateString = dateFormatter.format(mostRecentCreationDate)
        return new Promise(resolve => {
            confirmAlert({
                title: /* translator: important */ t`File date warning`,
                message: /* translator: important */ t`The date you encoded in the filename or the file's modification date
                    (${fileDateString}) is earlier than the latest video already present (${mostRecentCreationDateString})
                    for this passage. Do you want SLTT to make this video use today's date
                    instead?`,
                confirmLabel: /* translator: important */ t`Yes`,
                cancelLabel: /* translator: important */ t`No`,
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
            })
        })
    }
}

export default observer(VideoDropTarget)