import React, { Component, FC, useContext } from 'react'
import { confirmAlert } from '../utils/ConfirmAlert'
import { t } from 'ttag'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'

import '../utils/Buttons.css'
import './Notifications.css'
import { Root } from '../../models3/Root'
import { Passage, PassageVideo, PassageNote, Portion } from '../../models3/ProjectModels'
import { ClearNotificationsButton } from '../utils/Buttons'
import { newerThanCutoffDate } from '../../models3/DateUtilities'

interface NotificationProps {
    className: string,
    onClick?: (event: React.MouseEvent) => Promise<void> | undefined,
    tooltip: string,
}

export class VideoNotification extends Component<NotificationProps> {
    render() {
        let { className, tooltip, onClick } = this.props
        return (
            <span
                className={className}
                data-id="video-notification"
                data-toggle="tooltip"
                title={tooltip}
                onClick={e => onClick && onClick(e)}
            >
                <i className="fas fa-fw fa-video" />
            </span>
        )
    }
}

export class TextNotification extends Component<NotificationProps> {
    render() {
        let { className, tooltip, onClick } = this.props
        return (
            <span
                className={className}
                data-id="note-notification"
                data-toggle="tooltip"
                title={tooltip}
                onClick={e => onClick && onClick(e)}
            >
                <i className="fas fa-fw fa-comment note-notification" />
            </span>
        )
    }
}

export class UnresolvedTextNotification extends Component<NotificationProps> {
    render() {
        let { className, tooltip, onClick } = this.props
        return (
            <span
                className={className}
                data-id="unresolved-note-notification"
                data-toggle="tooltip"
                title={tooltip}
                onClick={e => onClick && onClick(e)}
            >
                <i className="fas fa-fw fa-comment unresolved-note" />
            </span>
        )
    }
}

interface ITitleNotification extends NotificationProps {
    onToggleTitles: () => void,
}

enum keyCodes {
    ENTER = 13,
    SPACE = 32,
}

export const TitleNotification: FC<ITitleNotification> = (
    { tooltip, className, onToggleTitles }
) => {
    return (
        <Link to="#" onKeyDown={e=> {
            if ([keyCodes.ENTER, keyCodes.SPACE].includes(e.keyCode)) {
                // toggle titles and prevent other keyboard side-effects
                e.preventDefault()
                e.stopPropagation()
                onToggleTitles()
            }
        }}>
            <span
                className={className}
                data-id="title-notification"
                data-toggle="tooltip"
                title={tooltip}
                onClick={(e) => {
                    // prevent Link onClick side-effects
                    e.preventDefault()
                    e.stopPropagation()
                    onToggleTitles()
                }}
            >
                <i className="fas fa-fw fa-font"></i>
            </span>
        </Link>
    )
}

interface IPublishTRLNotification extends NotificationProps {
    badgeClass: string,
    syncClass: string,
    onToggleTRL: () => void
}

export const PublishTRLNotification: FC<IPublishTRLNotification> = observer((
    { className, tooltip, badgeClass, syncClass, onToggleTRL }
) => {
    return (
        <Link to="#" onKeyDown={e=> {
            if ([keyCodes.ENTER, keyCodes.SPACE].includes(e.keyCode)) {
                // toggle titles and prevent other keyboard side-effects
                e.preventDefault()
                e.stopPropagation()
                onToggleTRL()
            }
        }}>
            <span
                className={className}
                data-toggle="tooltip"
                title={tooltip}
                onClick={(e) => {
                    // prevent Link onClick side-effects
                    e.preventDefault()
                    e.stopPropagation()
                    onToggleTRL()
                }}
            >
                <span className="fa-stack custom">
                    <i className="fa-solid fa-folder-tree fa-stack-2x"></i>
                    <i className={`${badgeClass} fa-stack-1x`}></i>
                    <i className={`${syncClass} fas fa-sync fa-stack-1x`}></i>
                </span>
            </span>
        </Link>
    )
})

interface IPassageNotificationList {
    rt: Root,
    passage: Passage,
}

export const PassageNotificationList: FC<IPassageNotificationList> = observer(({ rt, passage }) => {
    const showUnviewedVideo = async (path: string, video: PassageVideo) => {
        rt.selectPage(path)
        await rt.setPassage(passage)
        await rt.setPassageVideo(video)
    }

    const showUnviewedNote = async (path: string, note: PassageNote) => {
        let video = passage.findVideo(note._id) || null
        video = video?.baseVideo(passage) || video
        rt.selectPage(path)
        await rt.setPassage(passage)
        await rt.setPassageVideo(video)
        rt.setNote(note)
    }

    let { hardNotificationCutoff, canViewConsultantOnlyFeatures, username } = rt

    let cutoff = hardNotificationCutoff()

    let unviewedVideo = passage.firstUnviewedVideo(username, cutoff)
    let unviewedNote = passage.firstUnviewedNote(username, cutoff, canViewConsultantOnlyFeatures)
    let unresolvedNote = passage.getUnresolvedNote(cutoff, canViewConsultantOnlyFeatures)

    let mostRecentVideo = passage.videosNotDeleted[passage.videosNotDeleted.length - 1]

    let showUnviewedNoteIcon = !!unviewedNote
    let showUnresolvedNoteIcon = !!unresolvedNote && !showUnviewedNoteIcon

    return (
        <div>
            {unviewedVideo && (
                <Link to={`/index.html`}>
                    <VideoNotification
                        className='portion-button'
                        onClick={() => showUnviewedVideo('/', mostRecentVideo)}
                        tooltip={/* translator: important */ t`There are unwatched videos.`}
                    />
                </Link>
            )}
            {unresolvedNote && showUnresolvedNoteIcon && (
                <Link to={`/index.html`}>
                    <UnresolvedTextNotification
                        className='portion-button'
                        onClick={() => showUnviewedNote('/', unresolvedNote!)}
                        tooltip={/* translator: important */ t`There are unresolved notes.`}
                    />
                </Link>
            )}
            {unviewedNote && showUnviewedNoteIcon && (
                <Link to={`/index.html`}>
                    <TextNotification
                        className='portion-button'
                        onClick={() => showUnviewedNote('/', unviewedNote!)}
                        tooltip={/* translator: important */ t`There are unviewed notes.`}
                    />
                </Link>
            )}
        </div>
    )
})

export interface IPortionNotificationList {
    rt: Root,
    portion: Portion
}

export const PortionNotificationList: FC<IPortionNotificationList> = observer((
    { rt, portion }
) => {
    const goToPortion = async (path: string) => {
        rt.selectPage(path)
        await rt.setPortion(portion)
        await rt.setPassage(portion.passages[0])
    }

    let { hardNotificationCutoff, username, canViewConsultantOnlyFeatures } = rt

    let cutoff = hardNotificationCutoff()

    let isUnviewedVideo = portion.unviewedVideoExists(username, cutoff)
    let isUnviewedNote = portion.unviewedNoteExists(username, cutoff, canViewConsultantOnlyFeatures)
    let isUnresolvedNote = portion.unresolvedNoteExists(cutoff, canViewConsultantOnlyFeatures)

    let showUnviewedNoteIcon = isUnviewedNote
    let showUnresolvedNoteIcon = isUnresolvedNote && !showUnviewedNoteIcon

    return (
        <div>
            {isUnviewedVideo && (
                <Link to={`/index.html`}>
                    <VideoNotification
                        className='portion-button'
                        onClick={() => goToPortion('/')}
                        tooltip={/* translator: important */ t`There are unwatched videos.`}
                    />
                </Link>
            )}
            {isUnresolvedNote && showUnresolvedNoteIcon && (
                <Link to={`/index.html`}>
                    <UnresolvedTextNotification
                        className='portion-button'
                        onClick={() => goToPortion('/')}
                        tooltip={/* translator: important */ t`There are unresolved notes.`}
                    />
                </Link>
            )}
            {isUnviewedNote && showUnviewedNoteIcon && (
                <Link to={`/index.html`}>
                    <TextNotification
                        className='portion-button'
                        onClick={() => goToPortion('/')}
                        tooltip={/* translator: important */ t`There are unviewed notes.`}
                    />
                </Link>
            )}
        </div>
    )
})

interface IClearNotifications {
    rt: Root,
    passages: Passage[]
}

export class ClearNotifications extends Component<IClearNotifications> {
    clearNotifications = () => {
        confirmAlert({
            title: t`Clear Notifications?`,
            message: t`You are about to clear notifications for the displayed portion(s) and/or passage(s). Are you sure you want to do this?`,
            confirmLabel: t`Clear notifications`,
            cancelLabel: t`Cancel`,
            onConfirm: this.doDeletion,
            onCancel: () => {},
        })
    }

    doDeletion = async () => {
        const { rt, passages } = this.props
        let { canViewConsultantOnlyFeatures, hardNotificationCutoff } = rt

        let cutoff = hardNotificationCutoff()

        for (let passage of passages) {
            let activeVideos = passage.videosNotDeleted
            let mostRecentVideo = activeVideos[activeVideos.length - 1]
            if (mostRecentVideo && newerThanCutoffDate(mostRecentVideo.creationDate, cutoff)) {
                await mostRecentVideo.addViewedBy(rt.username)
            }

            for (let video of activeVideos) {
                let visibleNotes = video.getVisibleNotes(passage, canViewConsultantOnlyFeatures)
                let noteItems = visibleNotes.flatMap(note => note.items)
                for (let item of noteItems) {
                    if (newerThanCutoffDate(item.creationDate, cutoff)) {
                        await item.addViewedBy(rt.username)
                    }
                }
            }
        }
    }
    
    render() {
        return (
            <ClearNotificationsButton
                className='clear-notifications-button'
                onClick={() => this.clearNotifications()}
                tooltip={t`Clear notifications for displayed portion(s) and/or passage(s)`}
            />
        )
    }
}