import { DropResult } from 'react-beautiful-dnd';
import { t } from 'ttag'
import { observable, makeObservable } from 'mobx';

import { RefRange } from '../../scrRefs/RefRange'
import { ImageMetadata } from '../../resources/ImageMetadata'
import { MARBLEImages } from '../../resources/MARBLEImages'
import API from '../../models3/API'
import _ from 'underscore'
import { fmt } from '../utils/Fmt';
import { ProjectImages } from '../../resources/ProjectImages'

import _debug from "debug"; let log = _debug('sltt:ImageCollection')


/*
    A collection of images that match search refs (RefRange[]).
    The routines to do async queries to load these.
 */

class ImageCollection {
    _controller = new AbortController()
    _numFinished = 0
    _numStarted = 0
    @observable loading = false

    /**
     * If the user pass a large range it may trigger reading the MARBLE image data
     * for many chapters. Because of this we use an AbortController to allow canceling
     * all these reads if we move to a different reference.
     * This controller only affects the MARBLE images (not the project images) to it
     * is odd for it to be in a class that I think should just contain the code
     * that is commont to MARBLE and project images.
     */

    fetchImages = async (projectName: string, refs: RefRange[]) => {
        try {
            log('fetchImgages', JSON.stringify(refs))
            this.stopSearching()

            if (refs.length === 0) return []

            let newController = new AbortController()       // Need a new controller since current one has been aborted
            this._controller = newController

            this._numStarted++
            if (this._numStarted !== this._numFinished) {
                this.loading = true
            }
            
            let images = await this.getImagesForRefs(projectName, refs, newController.signal)
            
            this._numFinished++  // Will get called when request is aborted or finishes
            if (this._numStarted === this._numFinished) {
                this.loading = false
            }

            return images
        } catch (error) {
            this._numStarted = 0
            this._numFinished = 0
            this.loading = false
            throw Error(String(error))
        }
    }

    stopSearching = () => this._controller.abort()

    private getImagesForRefs = async (projectName: string, refs: RefRange[], signal: AbortSignal) => {
        try {
            let images = await this.getImagesInRange(projectName, refs, signal)
            log('getImagesForRefs', fmt({refs, projectName, length: images.length}))

            let ids = new Set<string>()
            let first = (id: string) => {
                if (ids.has(id)) return false
                ids.add(id)
                return true
            }

            // Filter out all but the first occurence of each image
            images = images.filter(img => first(img.id))
            images = _.sortBy(images, img => img.sortKey)

            return images
        } catch (error) {
            if ((error instanceof Error) && error.name === 'AbortError') {
                return []
            } else {
                log('###', String(error))
                throw Error(t`Cannot get images at this time`)
            }
        }
    }

    // WARNING: Passing too many references may cause long search times
    private getImagesInRange = async (projectName: string, references: RefRange[], signal: AbortSignal) => {
        // fetch images for all chapters in range
        let bbbcccs = references.flatMap(ref => [...ref.chapterIterator()])
        let images = await this.fetchChapterInfo(projectName, bbbcccs, signal)
        
        // Filter out images that don't fall in exact verse ranges specified
        return images.filter(image => image.fallsInRanges(references))
    }

    private fetchChapterInfo = async (projectName: string, chapters: string[], signal: AbortSignal) => {
        let results: ImageMetadata[] = []

        for (let bbbccc of chapters) {
            try {
                let result = await MARBLEImages.fetchInfo(bbbccc, { signal })
                result = result.concat(await ProjectImages.fetchInfo(projectName, bbbccc))
                results = results.concat(result)
            } catch (error) {
                log('fetchChapterInfo ERROR', error)
                // If user enters invalid chapter number we end up here                    
            }
        }

        return results
    }

    constructor() {
        makeObservable(this);
    }
}

export default ImageCollection