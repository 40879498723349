import { throttle } from 'underscore'

type OmitFirstParameter<T> = T extends (first: any, ...rest: infer R) => infer U ? (...args: R) => U : never

export function summarizingThrottle(
    func: (summary: [string, number][], ...args: any[]) => void,
    wait: number,
    customStringify?: (args: any[]) => string
): OmitFirstParameter<typeof func> {
    let hashCounts: { [stringified: string]: number } = {}
    const throttledFunc = throttle(function (...args: any[]) {
        const summary: [string, number][] = Object.entries(hashCounts).map(([key, value]) => [key, value])
        func(summary, ...args)
        hashCounts = {}
    }, wait, { leading: false })

    return function (...args: any[]) {
        const stringified = (customStringify || JSON.stringify)(args)
        hashCounts[stringified] = (hashCounts[stringified] || 0) + 1
        throttledFunc(...args)
    }
}

// // Example usage
// function myFunction() {
//     console.log('Function executed')
// }

// const throttledFunction = summarizingThrottle(myFunction, 2000)
