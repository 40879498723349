import { PassageVideo, Passage, PassageGloss, PassageSegment } from "../../models3/ProjectModels";

export function getAllVisibleGlosses(passage: Passage, passageVideo: PassageVideo) {
    let glosses: PassageGloss[] = []
    passageVideo.segments.forEach(s => {
        let { videoPatchHistory } = s
        if (videoPatchHistory.length > 0) {
            let last = videoPatchHistory.length - 1
            let mostRecent = videoPatchHistory[last]
            let video = passage.videos.find(v => v._id === mostRecent)
            if (video) {
                let visibleGlossesInSegment = findVisibleGlossesInSegment(video, video.segments[0])
                glosses.push(...visibleGlossesInSegment)
            }
        } else {
            let visibleGlossesInSegment = findVisibleGlossesInSegment(passageVideo, s)
            glosses.push(...visibleGlossesInSegment)
        }
    })
    return glosses
}

function findVisibleGlossesInSegment(video: PassageVideo, segment: PassageSegment) {
    let exists = video.segments.find(s => s._id === segment._id)
    if (exists) {
        return video.glosses.filter(g => g.position >= segment.position && g.position <= segment.endPosition)
    }
    return []
}

export function sortGlossesByViewPosition(glosses: PassageGloss[], passage: Passage, passageVideo: PassageVideo) {
    let viewPositions: { x: number, g: PassageGloss }[] = []

    for (let g of glosses) {
        let video = g.toVideo(passage)
        if (!video) continue

        const currentSegment = (video: PassageVideo, currentTime: number) => 
            video.segments.slice().reverse().find(s => s.position <= currentTime)

        let segment = currentSegment(video, g.position)

        // ignore glosses we shouldn't be able to see
        if (!segment || segment.videoPatchHistory.length > 0) continue

        // let viewPosition = video.getSegmentInfo(passage, g.position).position
        let viewPosition = 0
        viewPositions.push({ x: viewPosition, g })
    }
    viewPositions.sort((a, b) => a.x - b.x)
    return viewPositions
}

export function minPermittedGlossPosition(passage: Passage, passageVideo: PassageVideo, drawables: { x: number, g: PassageGloss }[], gloss: PassageGloss) {
    let index = drawables.map(dr => dr.g).findIndex(g => g._id === gloss._id)
    let currentDrawable = drawables[index]
    let previousGlossViewPosition = index > 0 ? drawables[index - 1].x : -1

    let video = gloss.toVideo(passage)
    let currentSegment = passageVideo.timeToSegment(passage, currentDrawable.x)
    let { segments } = passageVideo

    // Find the earliest permitted segment in the underlying video
    let currentSegmentIndex = segments.findIndex(s => s._id === currentSegment!._id)
    let earliestPermittedSegment = segments.filter((s, i) => i <= currentSegmentIndex)
        .slice()
        .reverse()
        .find(s => {
            let currentVideo = s.patchVideo(passage)
            return currentVideo!._id !== video!._id
        })

    // Find the start position (as shown to the user) of the earliest permiited segment
    let videoStartPosition = 0
    if (earliestPermittedSegment) {
        let index = segments.findIndex(s => s._id === earliestPermittedSegment!._id)
        if (index >= 0 && index < segments.length - 1) {
            let nextSegment = segments[index + 1]
            let actualNextSegment = nextSegment.actualSegment(passage)
            videoStartPosition = actualNextSegment.time
        }
    }

    return Math.max(previousGlossViewPosition, videoStartPosition, 0)
}

export function maxPermittedGlossPosition(passage: Passage, passageVideo: PassageVideo, drawables: { x: number, g: PassageGloss }[], gloss: PassageGloss) {
    let index = drawables.map(dr => dr.g).findIndex(g => g._id === gloss._id)
    let currentDrawable = drawables[index]
    let nextGlossViewPosition = index < drawables.length - 1 ? drawables[index + 1].x : Number.MAX_SAFE_INTEGER

    let video = gloss.toVideo(passage)
    let currentSegment = passageVideo.timeToSegment(passage, currentDrawable.x)
    let { segments } = passageVideo

    let currentSegmentIndex = segments.findIndex(s => s._id === currentSegment!._id)
    let lastPermittedSegment = segments.filter((s, i) => i >= currentSegmentIndex)
        .find(s => {
            let currentVideo = s.patchVideo(passage)
            return currentVideo!._id !== video!._id
        })

    let videoEndPosition = Number.MAX_SAFE_INTEGER
    if (lastPermittedSegment) {
        let index = segments.findIndex(s => s._id === lastPermittedSegment!._id)
        if (index > 0 && index < segments.length - 1) {
            let actualVisibleSegments = passageVideo.visibleSegments(passage)
            let actualLastPermittedSegment = lastPermittedSegment.actualSegment(passage)
            videoEndPosition = actualLastPermittedSegment.time
        }
    } else {
        let actualVisibleSegments = passageVideo.visibleSegments(passage)
        let lastSegment = actualVisibleSegments[actualVisibleSegments.length - 1]
        videoEndPosition = lastSegment.time + lastSegment.duration
    }

    return Math.min(nextGlossViewPosition, videoEndPosition, Number.MAX_SAFE_INTEGER)
}