import React, { FC } from 'react'
import { t } from 'ttag'

import './DropTarget.css'
import { UploadIcon } from './Icons'

interface IDropTargetView {
   message: JSX.Element,
}

export const DropTargetViewLarge: FC<IDropTargetView> = ({ message }) => (
    <div className='drop-target-area'>
        <div className='drop-target-center-area'>
            <UploadIcon className='drop-target-icon' tooltip={t`Upload`} />
            {message}
        </div>
    </div>
)

export const DropTargetViewSmall: FC<IDropTargetView> = ({}) => (
    <div className='drop-target-area'>
        <div className='drop-target-center-area'>
            <UploadIcon className='drop-target-icon-small' tooltip={t`Upload`} />
        </div>
    </div>
)