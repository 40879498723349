
import React, { Component, FC, FunctionComponent, useEffect, useRef, useState } from 'react'
import { observable } from 'mobx'
import {observer} from 'mobx-react'
import { t } from 'ttag'

import { displayError, displayInfo, systemError } from '../utils/Errors'
import VideoRecorder, { RecordingState } from '../video/VideoRecorder'
import { NoteItem } from './NoteItem'
import { Passage, PassageNote, PassageNoteItem, PassageVideo } from '../../models3/ProjectModels'
import VideoUploader from '../video/VideoUploader'
import NoteLocationEditor from '../video/NoteLocationEditor'
import MediaThumbnail from '../utils/MediaThumbnail'
import { WarningIcon } from '../utils/Icons'
import { CancelButtonWithLabel, PauseButtonWithLabel, RecordButtonWithLabel, StopButtonWithLabel } from '../utils/Buttons'
import { ClipboardButton, PencilButton, SlttHelp } from '../utils/Buttons'
import TextInput from '../utils/TextInput'
import { IDateFormatter } from '../../models3/DateUtilities'
import routePrefix from '../app/routePrefix'
import { NoteTextEditor } from './NoteTextEditor'
import { fmt, s } from '../utils/Fmt'
import { IRoot } from '../../models3/Root'
import { useResumableRecording } from '../utils/useResumableRecording'

import _debug from "debug"; let log = _debug('sltt:ResumableVideoRecorder')
const intest = (localStorage.getItem('intest') === 'true')

export type IOnRecordingDone = (err: any, blobsCount?: number, url?: string, duration?: number) => Promise<void>

/**
 * Whenever this is changed there are a lot of sequences that need to be tested
 *    R - record, P - pause, C - cancel, S - stop
 * 
 *    C
 *    RS
 *    RC
 *    RPS
 *    RPPS
 *    RPC
 *
 * Variations
 *     Mouse control / keyboard control
 *     Record: Main video / patch video / term rendering viewo
 */

type ResumableVideoRecorderProps = {
    projectName: string,
    url: string,
    cancel: () => void,
    save: IOnRecordingDone,
    className?: string,
}

export function ResumableVideoRecorder({ cancel, projectName, url, save, className }: ResumableVideoRecorderProps) {
    const [recordingState, setRecordingState] = useState<RecordingState>('NOT_INITIALIZED')
    const [videoUploader] = useState(() => new VideoUploader(projectName, url, save))
    const vr = useRef<VideoRecorder>(null)
    const { setResumableRecording } = useResumableRecording()

    useEffect(() => {
        setResumableRecording(true)
        return () => { setResumableRecording(false) }
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', keydown)
        return () => {
            window.removeEventListener('keydown', keydown)
        }
    })

    return (
        <div className={className ?? 'note-video-recorder'} style={{ maxHeight: window.innerHeight }}>
            <VideoRecordingToolbar
                start={() => vr.current?.startRecording()}
                stopAndSave={() => vr.current?.stop()}
                cancel={() => {
                    vr.current?.cancel()
                    cancel()
                }}
                pause={() => vr.current?.pause()}
                resume={() => vr.current?.resume()}
                recordingState={recordingState} />
            <VideoRecorder
                ref={vr}
                setRecordingState={setRecordingState}
                videoUploader={videoUploader}
                usePauseAndResume />
        </div>
    )

    function keydown(e: KeyboardEvent) {
        log(`keydown code=${e.code}`,e)
        // We are assuming that other components that listen for keyboard events are
        // either unmounted, or ignore any events that occur while the ResumableVideoRecorder is open.
        e.stopPropagation()
        e.preventDefault()

        if (e.code === 'Escape') {
            vr.current?.cancel()
            return cancel()
        }

        if (e.code === 'Space') {
            if (recordingState === 'INITIALIZED') {
                return vr.current?.startRecording()
            } else if (recordingState === 'RECORDING') {
                return vr.current?.pause()
            } else if (recordingState === 'PAUSED') {
                return vr.current?.resume()
            }
        }

        if (e.code === 'Enter'
            && recordingState !== 'NOT_INITIALIZED'
            && recordingState !== 'INITIALIZED'
            && recordingState !== 'STOPPED'
        ) {
            return vr.current?.stop()
        }
    }
}

interface IVideoRecordingToolbar {
    start: () => void,
    cancel: () => void,
    pause: () => void,
    resume: () => void,
    stopAndSave: () => void,
    recordingState: RecordingState,
}

function VideoRecordingToolbar({ start, stopAndSave, resume, pause, cancel, recordingState }: IVideoRecordingToolbar) {
    // RecordingState = 'NOT_INITIALIZED' | 'INITIALIZED' | 'RECORDING' | 'PAUSED' | 'STOPPED'

    return (
        <div className="video-recording-toolbar" >
            {['RECORDING'].includes(recordingState) &&
                <PauseButtonWithLabel
                    enabled={true}
                    buttonClassName='note-video-button'
                    className='note-video-pause-recording-button'
                    onClick={pause}
                tooltip={/* translator: important */ t`Pause recording`}
                />
            }
            {!['RECORDING'].includes(recordingState) &&
                <RecordButtonWithLabel
                    enabled={['INITIALIZED', 'PAUSED'].includes(recordingState)}
                    onClick={recordingState === 'PAUSED' ? resume : start}
                    buttonClassName='note-video-button'
                    className='note-video-record-button'
                    tooltip={recordingState === 'INITIALIZED' ? 
                        /* translator: important */ t`Start recording` : /* translator: important */ t`Continue recording`}
                    selectionPresent={false}
                />
            }
            <CancelButtonWithLabel
                enabled={['INITIALIZED', 'RECORDING', 'PAUSED'].includes(recordingState)}
                onClick={cancel}
                tooltip={/* translator: important */ t`Cancel recording`}
                buttonClassName='note-video-button'
                className='note-video-cancel-record-button'
            />
            <StopButtonWithLabel
                className='note-video-stop-button'
                enabled={['RECORDING', 'PAUSED'].includes(recordingState)}
                tooltip={/* translator: important */ t`Stop and save recording.`}
                onClick={stopAndSave} />
        </div>
    )

}