import React, { Component } from 'react'
import { t } from 'ttag'

import { Root } from '../../../models3/Root'
import { Member, MemberRole } from '../../../models3/ProjectModels'
import { observer } from 'mobx-react'

interface IMemberRole {
    member: Member,
    rt: Root,
    isSoleAdmin: boolean,
    _onChange: (role: MemberRole) => void,
}

class MemberRoleView extends Component<IMemberRole> {
    render() {
        let { rt, isSoleAdmin, member } = this.props
        let { role } = member
        let { iAmAdmin } = rt

        let displayedRole = ''
        switch (role) {
            case 'admin': {
                displayedRole = t`admin`
                break
            }
            case 'translator': {
                displayedRole = t`translator`
                break
            }
            case 'interpreter': {
                displayedRole = t`interpreter`
                break
            }
            case 'consultant': {
                displayedRole = t`consultant`
                break
            }
            default: {
                displayedRole = t`observer`
            }
        }
        if (iAmAdmin && !isSoleAdmin) {
            return (
                <select value={role} onChange={e => this.props._onChange(e.target.value as MemberRole)}>
                    <option
                        title={t`Admins are allowed to do all operations.`}
                        value='admin'
                    >
                        {t`admin`}
                    </option>
                    <option
                        title={t`Translators can add and record passages and create notes.`}
                        value='translator'
                    >
                        {t`translator`}
                    </option>
                    <option
                        title={t`Consultants can view passages and notes. They can also create notes. They can control the visibility of note items.`}
                        value='consultant'
                    >
                        {t`consultant`}
                    </option>
                    <option
                        title={t`Can view passages and notes. Can also create notes.`}
                        value='interpreter'
                    >
                        {t`interpreter/exegete/advisor`}
                    </option>
                    <option
                        title={t`Observers can view passages and notes. They cannot record anything.`}
                        value='observer'
                    >
                        {t`observer`}
                    </option>
                </select>
            )
        } else {
            return ( <span> {displayedRole} </span> )
        }
    }
}

export default observer(MemberRoleView)
