import React, { useEffect, useMemo } from 'react'
import { EventEmitter } from 'events'

import { IVideoPositionControls } from '../../video/VideoPositionBarControls'
import { IVideoPlayerEmmitter, IVideoPositionUtils } from '../../../models3/RootBase'
import { IVideoPosition } from '../../video/VideoPositionBar'
import { Root } from '../../../models3/Root'
import { observer } from 'mobx-react'
import { TRLVideoPosition } from './TRLVideoPosition'

import _debug from "debug"; const log = _debug('sltt:TRLVideoBarControlsContextComponent')

export interface IVideoControlsContext {
    videoPositionControls: IVideoPositionControls
    videoPosition: IVideoPosition
    videoPositionUtils: IVideoPositionUtils
    videoEventEmitter: EventEmitter
    videoPlayerEmitter: IVideoPlayerEmmitter
    currentTime: number
    selectionStartTime: number
    selectionEndTime: number
}

export const TRLVideoPositionControlContext = React.createContext<IVideoControlsContext | null>(null)
export const TRLVideoBarControlsContextComponent = observer((props: {
    children: any
    rt: Root
}) => {
    const { rt } = props
    const videoPosition = useMemo(() => {
        log('videoPosition')
        return new TRLVideoPosition(rt)
    }, [rt])

    useEffect(() => {
        const { name, passage, passageVideo } = rt
        videoPosition._setPassageVideo(name, passage, passageVideo, true).then(() => {
            videoPosition.resetCurrentTime(rt.currentTime)
            videoPosition.selectionStartTime = rt.selectionStartTime
            videoPosition.selectionEndTime = rt.selectionEndTime
        }
        ).catch()
    }, [rt, rt.name, rt.passage, rt.passageVideo, rt.currentTime, rt.selectionStartTime, rt.selectionEndTime])

    useEffect(() => {
        const { name, passage, passageVideo } = rt
        videoPosition._setPassageVideo(name, passage, passageVideo).then().catch()
    }, [rt, rt.name, rt.passage, rt.passageVideo])

    useEffect(() => {
        const { currentTime, selectionStartTime, selectionEndTime } = videoPosition
        log('useEffect videoPosition', { currentTime, selectionStartTime, selectionEndTime })
    }, [videoPosition.currentTime, videoPosition.selectionStartTime, videoPosition.selectionEndTime])

    return <TRLVideoPositionControlContext.Provider value={{
        videoPositionControls: videoPosition as IVideoPositionControls,
        videoPosition,
        videoPositionUtils: videoPosition as IVideoPositionUtils,
        videoEventEmitter: videoPosition as EventEmitter,
        videoPlayerEmitter: videoPosition as IVideoPlayerEmmitter,
        currentTime: videoPosition.currentTime,
        selectionStartTime: videoPosition.selectionStartTime,
        selectionEndTime: videoPosition.selectionEndTime,
    }}>
        {props.children}
    </TRLVideoPositionControlContext.Provider>
})
