import { useContext } from "react"
import { RootContext } from "../app/RootContext"

export function useResumableRecording() {
    const rt = useContext(RootContext)
    const resumableRecording = rt?.resumableRecording

    function setResumableRecording(inProgress: boolean) {
        console.log("setResumeableRecording", inProgress)
        rt!.resumableRecording = inProgress
    }
    
    return { resumableRecording, setResumableRecording }
}
