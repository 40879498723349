import _ from "underscore"
import { t } from "ttag"

import { fmt, s } from "../components/utils/Fmt"
import { RefRange } from "../scrRefs/RefRange"
import { ptxBookIds } from "../scrRefs/bookNames"
import { displayError } from "../components/utils/Errors"

// ADB = ApiDotBible

import _debug from "debug";import { delay } from "../components/utils/delay"
 let log = _debug('sltt:ApiDotBible')

const API_KEY = 'bb5dd32f6205ef9b9da164c545a2db86'

const ADB_CACHE_NAME = 'api-bible-cache'
const CACHE_INVALIDATION_TIMEOUT = 1000 * 60 * 60 * 24 // 1 day
    // We don't want to cache the results for too long because new Bibles get created
    // and old Bibles get updated.
const CACHE_LIMIT = 20 // Max number of items in cache

// Return json from cache (null if not present), also flag indicating if data is expired.
async function getFromCache(url: string) {
    let dataExpired = false
    const cache = await caches.open(ADB_CACHE_NAME)
    const response = await cache.match(url)
    if (!response) return { json: null, dataExpired }

    const json = await response.json()
    const { created, data } = json

    dataExpired = (Date.now() - created) > CACHE_INVALIDATION_TIMEOUT

    log('getFromCache success', fmt({ url, created, dataExpired }))
    return { json: JSON.parse(data), dataExpired }
}

async function clearCacheIfFull() {
    // If we have reached the limit of the number of items in the cache,
    // delete all items and start over.
    const cache = await caches.open(ADB_CACHE_NAME)
    const keys = await cache.keys()

    if (keys.length > CACHE_LIMIT) {
        log('clearCacheIfFull: deleting cache items', keys.length)
        for (const key of keys) {
            await cache.delete(key)
        }
    }
}

async function putToCache(url: string, response: Response) {
    log('putToCache', fmt({url, response}))
    const cache = await caches.open(ADB_CACHE_NAME)

    // Create a new response containing the original response and the time it was created.
    // AFAICT this is the only way to tell when the response is too old and needs to be refetched.
    const _response = new Response(JSON.stringify({
        created: Date.now(),
        data: await response.clone().text()}))
    await cache.put(url, _response)
}

// fetch data from api.bible. Return [error, json]. Does not throw.
// Error is a string like '404 - Not Found' or '503 - Service Unavailable'
// On some browser statusText is sometimes returned as '' so you should not depend on it.
async function _fetchADB(url: string, contentType?: string): Promise<[string, any]> {
    // Uncomment the following line to test the error handling.
    // return ['Service Unavailable', null]

    let options: any = {
        method: 'GET',
    }

    options.headers = {}
    options.headers['api-key'] = API_KEY
    if (contentType) { options.headers['Content-Type'] = contentType }

    let _url = `https://api.scripture.api.bible/v1/${url}`

    try {
        let { json, dataExpired } = await getFromCache(_url)
        if (json && !dataExpired) return ['', json]

        const response = await fetch(_url, options)
        
        if (response.status === 200) {
            await clearCacheIfFull()
            await putToCache(_url, response.clone())

            json = await response.json()
            return ['', json]
        }
        
        // If fetch failed, return the expired json data (its better than nothing)
        if (json) return ['', json]

        let statusText = response.statusText
        if (!statusText) {
            // some browsers may not return response.statusText
            // but api.bible response could have its own error message
            const responseBody = await response.json()
            statusText = responseBody?.message || responseBody?.error || ''
        }
        return [`${response.status} - ${statusText}`, null]
    } catch (error) {
        return [`${error}`, null]        
    }
}

// Returns [error, json]. Does not throw.
// Error is a string like '404 - Not Found' or '503 - Service Unavailable'
// On some browser statusText is sometimes returned as '' so you should not depend on it.
async function fetchADB(url: string, contentType?: string): Promise<[string, any]> {
    let errorDisplayed = false

    while (true) {
        const [error, json] = await _fetchADB(url, contentType)
        if (!error) return [error, json]

        if (error.startsWith('503')) {
            log('Service Unavailable')

            if (!errorDisplayed) {
                displayError(t`The service SLTT uses to fetch scripture is currently unavailable. We will keep trying.`)
                errorDisplayed = true
            }

            // Try again after a delay
            await(delay(4000))
            continue
        }

        return [error, null]
    }
}

const languageNames = [
    "Arabic, Standard", 
    "Assamese", 
    "Belarusan", 
    "Belarusian", 
    "Bengali", 
    "Chinese, Mandarin",
    "Czech", 
    "Kurdish, Central", 
    "German", 
    "German, Standard", 
    "English", 
    "French",
    "Greek, Ancient", 
    "Gujarati", 
    "Hausa", 
    "Hindi", 
    "Indonesian", 
    "Italian", 
    "Kannada", 
    "Malayalam", 
    "Marathi", 
    "Nepali",
    "Dutch", 
    "Chichewa", 
    "Panjabi", 
    "Polish", 
    "Portuguese", 
    "Romani, Carpathian", 
    "Romani, Balkan", 
    "Romani, Vlax", 
    "Russian",
    "Spanish", 
    "Serbian", 
    "Sinhala",
    "Swedish", 
    "Swahili", 
    "Tamil", 
    "Telugu", 
    "Thai", 
    "Ukrainian",
    "Urdu", 
    "Vietnamese", 
    "Yoruba",
]

const excludedIds = [
    "de4e12af7f28f599-02",
    "9879dbb7cfe39e4d-02",
    "9879dbb7cfe39e4d-03",
    "9879dbb7cfe39e4d-04",
    "7142879509583d59-02",
    "7142879509583d59-03",
    "7142879509583d59-04",
    "3548ab6114a312d4-01",
    "0ab0c764d56a715d-01",
    "e952663db2e91691-01",
    "6c696cd1d82e2723-04",
]

export interface ADBVersion {
    id: string,
    name: string,
    language: any, // language.name
    abbreviation: string,
}

export interface ADBTextItem {
    type: 'tag' | 'text',
    name?: string,
    text?: string, // if type === 'text'
    items?: ADBTextItem[], // if type === 'tag'
    attrs?: Record<string, string>,
}

export class ApiDotBible {
    static versions: ADBVersion[] = []

    // Retturn list of scripture versions available from api.bible.
    // On error returns an empty list.
    static async getBibleVersions() {
        if (this.versions.length === 0) {
            let [error, json] = await fetchADB(`bibles`)
            if (error) {
                log('###getBibleVersions', error)
                return []
            }

            let versions: ADBVersion[] = json?.data || []
            versions = versions.filter((version: any) => languageNames.includes(version.language.name))
            versions = versions.filter((version: any) => !excludedIds.includes(version.id))
            versions = _.sortBy(versions, (version: any) => version.abbreviation.toLowerCase())

            this.versions = versions
        }

        return this.versions
    }

    static async getBibleVersion(bibleVersionID: string) {
        let versions = await this.getBibleVersions()
        return versions.find(version => version.id === bibleVersionID)
    }
    
    // Returns books present in resource as [error, books]. Does not throw.
    // Books is ['GEN', 'EXO', ...]
    static async getBooks(bibleVersionID: string) {
        // bibleVersionID = "685d1470fe4d5c3b-01" // ASV

        const [error, json] = await fetchADB(`bibles/${bibleVersionID}/books`)
        if (error) return [error]

        const data = json?.data
        if (!data) return [`No books found for ${bibleVersionID}`, []]

        const ids: string[] = data.map((book: any) => book?.id ?? '*missing*')
        return ['', ids]
    }

    // Returns chapters present in book as [error, chapters]. Does not throw.
    // Chapters is ['JAS.1', 'JAS.2' ...]
    static async getChapters(bibleVersionID: string, bibleBookId: string) {
        // bibleVersionID = "685d1470fe4d5c3b-01" // ASV
        // bibleBookId = 'JAS'

        const [error, json] = await fetchADB(`bibles/${bibleVersionID}/books/${bibleBookId}/chapters`)
        if (error) return [error]

        const data = json?.data
        if (!data) return [t`No chapters found for ${bibleBookId}`, []]

        const chapters: string[] = data.map((chapter: any) => chapter?.id ?? '*missing*')  
        return ['', chapters]
    }

    // Returns [error, items]. Does not throw.
    // Error is a string like 'status: 404'
    static async _getChapterText(bibleVersionID: string, chapterId: string): Promise<[string, ADBTextItem[]]> {
        // bibleVersionID = bibleVersionID || "685d1470fe4d5c3b-01" // ASV
        bibleVersionID = bibleVersionID || "592420522e16049f-01" // RVR

        // const [_error, _books] = await this.getBooks(bibleVersionID)
        // log('books present', fmt({ _error, bibleVersionID, _books }))

        log('getChapterText:', fmt({bibleVersionID, chapterId}))
        
        chapterId = chapterId || 'JAS.1'

        const url = `bibles/${bibleVersionID}/chapters/${chapterId}?content-type=json`
        const [error, json] = await fetchADB(url, 'json')
        if (error) return [error, []]

        const items = (json?.data?.content || []) as ADBTextItem[]
        return ['', items]
    }

    static async getChapterText(bibleVersionID: string, chapterId: string): Promise<[string, ADBTextItem[]]> {
        // localStorage.debugAllResources='true' in console to fetch all resources for debugging
        if (localStorage.getItem('debugAllResources') !== 'true') {
            return this._getChapterText(bibleVersionID, chapterId)
        }
        
        /**
         * WARNING: This code is for debugging purposes only. It fetches all available versions.
         * It is ONLY executed when localStorage.debugAllResources='true' in the console.
         * It is useful when you have made a change to how resources are formatted and you want
         * to see the effect on all available resources.
         */
        const items: ADBTextItem[] = []

        const first = 0
        const last = 500
        for (let i=first; i<=last; i++) {
            const version = ApiDotBible.versions[i]
            if (!version) break

            const versionItem: ADBTextItem = {
                type: 'tag',
                attrs: { style: 'ms' },
                items: [
                    { type: 'text', text: `${version.name} - ${i}` },
                ],
            }
            items.push(versionItem)

            const [error, _items] = await this._getChapterText(version.id, chapterId)
            if (error.startsWith('404')) continue
            if (error) {
                return [error, []]
            }

            log('getChapterText', fmt({name: version.name, _items}))

            items.push(..._items)

            await delay(1000)
        }

        return ['', items]
    }

    // This is the main function for fetching chapters of scripture from api.bible.
    // It fetches entire chapters even if the refs are for less than an entire chapter because
    // AFAIK api.bible does not support fetching partial chapters.
    static async fetchRefs(bibleVersionID: string, refs: RefRange[], displayableBookNames: string[]) {
        // Create a list of all the bbbccc (book chapter) values in the refs.
        const bbbcccs: string[] = []
        for (const ref of refs) {
            for (const bbbccc of ref.chapterIterator()) {
                if (!bbbcccs.includes(bbbccc)) {
                    bbbcccs.push(bbbccc)
                }
            }
        }

        let items: ADBTextItem[] = []

        // For each bbbccc, fetch the chapter text and add it to the items.
        for (let bbbccc of bbbcccs) {
            let bookNum = parseInt(bbbccc.slice(0, 3))
            let chapterNum = parseInt(bbbccc.slice(3, 6))
            let bookId = ptxBookIds[bookNum-1]
            const displayableBookName = displayableBookNames[bookNum - 1] || bookId

            const [error, _items] = await this.getChapterText(bibleVersionID, `${bookId}.${chapterNum}`)
            
            if (error.startsWith('404')) {
                const notFoundItem: ADBTextItem = {
                    type: 'tag',
                    attrs: { style: 'p' },
                    items: [
                        { type: 'text', text: t`Chapter is not present in this Bible` + `: ${displayableBookName} ${chapterNum}` },
                    ],
                }
                items.push(notFoundItem)
                continue
            }

            if (error) {
                log('###fetchRefs:', error)
                continue
            }

            if (_items.length === 0) continue

            // If we are fetching more than one chapter, add a heading for each chapter
            if (bbbcccs.length > 1) {
                const chapterItem: ADBTextItem = {
                    type: 'tag',
                    attrs: { style: 'ms' },
                    items: [
                        { type: 'text', text: `${displayableBookName} ${chapterNum}` },
                    ],

                }
                items.push(chapterItem)
            }

            // Add the book and chapter for these items because some resources do not include
            // this information
            const first = _items[0]
            first.attrs = first.attrs || {}
            first.attrs['bbbccc'] = bbbccc

            items = items.concat(_items)
        }

        return items
    }

}

const _window = window as any
_window.ApiDotBible = ApiDotBible