import { t } from 'ttag'

export const GIGABYTE = 1024 * 1024 * 1024
export const MIN_STORAGE_GB = 2  // 2 GB limit for storage

interface IStorageLimitStatus {
    remainingStorageGB: number
    isStorageLow: boolean
    message: string
}

export function calculateStorageLimitStatus(storageUsage: number, storageQuota: number): IStorageLimitStatus {
    const quotaGB = storageQuota / GIGABYTE // Convert quota to GB
    const usedStorageGB = storageUsage / GIGABYTE // Convert usage to GB
    const remainingStorageGB = quotaGB - usedStorageGB // Calculate remaining storage in GB
    const isStorageLow = remainingStorageGB < MIN_STORAGE_GB // Determine if storage is low
    // whether quota is low (< 2GB) or usage is high compared to quota just beyond the 10GB hard limit (< 12GB)
    // the user should be warned to free up storage
    // (Review EricP): I'm not sure that the browser's quota will ever become under 12GB. 
    // On my computer, the quota is 284 GB, but I have less than 20 GB of storage left.
    // So it's likely that users can run out of space and never see the storage warning.
    // Probably the best solution is to check for an I/O error when writing to storage, and 
    // display a warning if the write fails, and also try to clean up some older videos.
    const messageLowStorage = t`Remaining browser storage: ` + remainingStorageGB.toFixed(1) + ' ' +  t`gigabytes (GB).` + `<br>`
    const message = t`Please free up at least 2 gigabytes(GB) of computer storage.` + `<br>` + messageLowStorage
    return {
        remainingStorageGB,
        isStorageLow,
        message
    }
}
