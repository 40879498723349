import React, { FC, useEffect, useRef, useState } from 'react'
import { observable } from 'mobx'
import { observer, /* inject */ } from 'mobx-react'
import { t } from 'ttag'

import './EnhancedResources.css'
import { LexMeaning, DefinitionLink, MarbleLemma } from '../../scrRefs/Lemmas'
import { RefRange } from '../../scrRefs/RefRange'
import { Root, IRoot } from '../../models3/Root'
import { ProjectReference } from '../../models3/ProjectReferences'
import { convertReferenceStrings } from '../../resources/Versifications'
import { Passage, PassageGloss, PassageVideo, Project, ProjectTerm } from '../../models3/ProjectModels'
import { CancelButton, OKButton, PencilButton, PlayButton, RecordButton, StarButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { fmt, s } from '../utils/Fmt'
import { IKeyTermRecording, KeyTermGlosses } from './KeyTermGlosses'
import { GlossReference } from '../../models3/GlossReferences'
import _ from 'underscore'

// Show the definition and references for the currently selected Biblical Term

import _debug from "debug"; let log = _debug('sltt:ERLexMeaningView')

type IIsKeyTermView = {
    rt: IRoot,
    term: ProjectTerm | null,
    meaning: LexMeaning,
}

export const IsKeyTermView: FC<IIsKeyTermView> = observer(({ rt, term, meaning }) => {
    let { project, iAmConsultant } = rt
    let isKeyTerm  = term?.isKeyTerm ?? false

    async function toggleIsKeyTerm() {
        if (term) {
            await term.setIsKeyTerm(!isKeyTerm)
            return
        }

        let _term = project.createProjectTerm(meaning.lexicalLink)
        _term.isKeyTerm = true
        await project.addProjectTerm(_term)
    }

    return (
        <StarButton
            isHollow={!isKeyTerm}
            enabled={iAmConsultant}
            onClick={toggleIsKeyTerm}
            className='er-star-icon'
            tooltip={isKeyTerm ? `Unset as key term` : `Set as key term`}
        />
    )
})

type IERMeaningView = {
    rt: Root,
    lemma: MarbleLemma,
    _meaning: LexMeaning, // LexMeaning corresponding to this view
    meaning: LexMeaning | null, // meaning selected by user, if any
    setProjectReference: (projectRefernce: ProjectReference | null) => void,
    setSignPlaying: (signVideo: PassageVideo) => void,
    termId: string, // For example, "SDBG:γένεσις:000001"
    setTermId: (resource: string) => void, // setTermId('') to close parent dialog
    setRecording: (recording: IKeyTermRecording | null) => void,
    playGlossReference: (reference: GlossReference) => void,
    setGlossShowing: (glossText: string) => void,
}

/**
 * Show information for one meaning, i.e. "sense" of selected MarbleTerm
 */
const ERMeaningView: FC<IERMeaningView> =
    observer(({ rt, lemma, _meaning, meaning, setProjectReference, termId, setTermId, 
        setRecording, setSignPlaying, playGlossReference, setGlossShowing }) => 
{
    let { project } = rt
    let meaningRef = useRef<HTMLDivElement>(null)
    let isSelected = (_meaning === meaning)
    let [passages, setPassages] = useState<Passage[]>([])

    let projectTerm = rt.project.termsMap.get(_meaning.lexicalLink) ?? null

    // When this meaning becomes selected, scroll it into view
    useEffect(() => {
        if (isSelected) {
            meaningRef.current?.scrollIntoView()
        }
    }, [isSelected])

    let senseName = _meaning.senseName(rt.transliterateLemmas)

    ///log('getPassages render', fmt({_meaning: _meaning.id, passages: passages.map(p => p.name)}))

    return (
        <div ref={meaningRef} className={isSelected ? 'er-highlighted-sense' : ''}>
            <div className="er-header">
                <div className="er-lemma-title">{ senseName }</div>
                <div className="er-iskeyterm">
                    <IsKeyTermView {...{rt, term: projectTerm, meaning: _meaning}} />
                </div>
            </div>
            <div className="er-glosses">
                <span className="er-explanation">{_meaning.glosses(rt.uiLanguage) || '***'}</span>
            </div>
            { _meaning.definitionShort(rt.uiLanguage).map((definitionLink, key) => (
                <ERDefinitionLinkView {...{ key, definitionLink, setTermId }} />
            )) }
            <div className="er-term-glosses">
                <KeyTermGlosses {...{
                    label: /* translator: important */ t`Glosses`,
                    rt, 
                    term: projectTerm, 
                    meaning: _meaning, 
                    setSignPlaying,
                    setRecording,
                    setGlossShowing }} />
            </div>
            <ERReferences {...{ 
                rt, 
                references: _meaning.references, 
                setProjectReference, 
                projectTerm, 
                playGlossReference }} />
        </div>
    )
})

type IERTermView = {
    rt: Root,
    lemma: MarbleLemma,
    setProjectReference: (projectRefernce: ProjectReference | null) => void,
    meaning: LexMeaning | null,
    setMeaning: (meaning: LexMeaning) => void,
    termId: string,
    setTermId: (resource: string) => void,
    setRecording: (recording: IKeyTermRecording | null) => void,
    setSignPlaying: (signVideo: PassageVideo) => void,
    playGlossReference: (reference: GlossReference) => void,
    setGlossShowing: (glossText: string) => void,
}

const ERTermView: FC<IERTermView> =
    ({ rt, lemma, setProjectReference, termId, setTermId, setRecording, setSignPlaying, playGlossReference, meaning, 
                setMeaning, setGlossShowing }) => {
        return (
            <div className="er-term-div">
                {lemma.meanings.map((_meaning, index) => (
                    <div key={_meaning.id} onClick={() => setMeaning(_meaning)}>
                        {(index > 0) && <hr />}
                        <ERMeaningView {...{
                            lemma, _meaning,
                            key: index,
                            rt, setSignPlaying, meaning, setProjectReference, termId, setTermId,  
                            playGlossReference, 
                            setRecording,
                            setGlossShowing
                        }} />
                    </div>
                    )
                )}
            </div>
        )
    }

type IERDefinitionLinkView = {
    definitionLink: DefinitionLink,
    setTermId: (resource: string) => void,
}

const ERDefinitionLinkView: FC<IERDefinitionLinkView> = ({ definitionLink, setTermId }) => {
    let { text, link } = definitionLink
    if (!link) return (<span>{text}</span>)
    return (<span className="er-definition-link" 
            onClick={() => {
                setTermId(link)
            }}>
        {text}
    </span>)
}

type IERReferences = {
    rt: Root,
    references: string[],
    setProjectReference: (projectRefernce: ProjectReference | null) => void,
    projectTerm: ProjectTerm | null,
    playGlossReference: (reference: GlossReference) => void,

}
//!!! Add 'other' book

const ERReferences: FC<IERReferences> = ({ rt, references, setProjectReference, projectTerm, playGlossReference }) => {
    let newReferences = convertReferenceStrings(references, 'Original', rt.project.versification)
    let refRanges = RefRange.refsToRefRanges(newReferences)

    //  e.g. "Luke 1.3", "Acts 18.25-26",
    let displayParts = RefRange.refRangesToDisplayParts(refRanges)

    log('ERReferences', fmt({references, newReferences, refRanges, displayParts}))

    let newBook = (i: number) => refRanges[i - 1].startRef.slice(0, 3) !== refRanges[i].startRef.slice(0, 3)


    return (
        <div className="er-references">
            {displayParts.map((part, index) => (
                <span key={index}>
                    {index > 0 && newBook(index) ? <br/> : (index > 0 ? '; ' : '')}
                    <ERReference
                        rt={rt}
                        index={index}
                        refRange={refRanges[index]}
                        setProjectReference={setProjectReference}
                        projectTerm={projectTerm}
                        playGlossReference={playGlossReference}
                        part={part} />
                </span>
            ))}
        </div>
    )
}


type IERReference = { 
    rt: Root,
    part: string, 
    index: number,
    refRange: RefRange,
    setProjectReference: (projectRefernce: ProjectReference | null) => void,
    projectTerm: ProjectTerm | null,
    playGlossReference: (reference: GlossReference) => void,
}

const ERReference: FC<IERReference> = ({ rt, part, index, refRange, setProjectReference, projectTerm, playGlossReference }) => {
    let projectReferences = rt.projectReferences.get(refRange)
    
    if (projectReferences.length === 0) return (
        <span className="er-reference er-reference-link" key={index}>
            {part}
        </span>
    )

    return (
        <ERReferenceLink
            part={part}
            projectReferences={projectReferences}
            setProjectReference={setProjectReference}
            rt={rt}
            projectTerm={projectTerm} />
    )
}

type ERReferenceLinkProps = {
    part: string,
    projectReferences: ProjectReference[],
    setProjectReference: (projectRefernce: ProjectReference | null) => void,
    rt: Root,
    projectTerm: ProjectTerm | null,
}

const ERReferenceLink = observer((
        { part, projectReferences, setProjectReference, rt, projectTerm }: ERReferenceLinkProps) => 
{
    log('!!!ERReferenceLink', fmt({part, projectReferences: projectReferences.map(pr => pr.dbg(rt.project))}))

    projectReferences = _.sortBy(projectReferences, 'startingTime')
    
    return (
        <span className="er-reference">
            {projectReferences.map((projectReference, i) => {
                return (
                    <span className="er-reference-link er-reference-link-enabled"
                            onClick={() => setProjectReference(projectReference) } >
                        {i === 0 ? part : ''}
                        <PlayButton
                            enabled={true}
                            onClick={() => {}}
                            className='er-play-verse-button'
                            tooltip={projectReference.info(rt).tooltip}
                            selectionPresent={false} />
                    </span>
                )
            })}
        </span>
    )
})

// Find all glosses in this section of verses
function getGlossReferencesThatOverlapProjectReference(projectReference: ProjectReference, project: Project) {
    let glossReferences: GlossReference[] = []

    let { passageVideo } = projectReference
    let portion = project.portions.find(_portion => passageVideo._id.startsWith(_portion._id))
    let passage = portion?.passages.find(_passage => passageVideo._id.startsWith(_passage._id))

    if (!passage) {
        return []
    }

    let glosses = passageVideo.getVisibleGlosses(passage)
    glosses.forEach((gloss, index) => {
        const duration = gloss.durationByIndex(glosses, index)
        if (glossOverlapsProjectReference(gloss, projectReference, duration)) {
            glossReferences.push(new GlossReference(passage!, gloss, passageVideo))
        }
    })

    return glossReferences
}

function glossOverlapsProjectReference(gloss: PassageGloss, projectReference: ProjectReference, duration: number) {
    // either gloss is fully contained within the verse:
    //      verse.start <= gloss.start && verse.end >= gloss.end
    // OR gloss is partially contained within the verse:
    //      verse.start <= gloss.start && verse.end >= gloss.start
    //      || verse.start >= gloss.start && verse.start <= gloss.end
    let glossEnd = gloss.time + duration
    let isFullyContained = projectReference.startingTime <= gloss.time && projectReference.endingTime >= glossEnd
    let verseStartsBeforeGloss = projectReference.startingTime <= gloss.time && projectReference.endingTime >= gloss.time
    let verseStartsInGloss = projectReference.startingTime >= gloss.time && projectReference.startingTime <= glossEnd
    let isPartiallyContained = verseStartsBeforeGloss || verseStartsInGloss
    return isFullyContained || isPartiallyContained
}

export default ERTermView
