import React, { FC, useEffect, useRef, useState } from "react"
import { t } from 'ttag'
import { observer } from 'mobx-react'
import _ from "underscore"
import Modal from 'react-bootstrap/lib/Modal'

import { Root } from "../../models3/Root"
import { systemError, displayError } from "../utils/Errors"
import { Member, Project, ProjectMessage } from "../../models3/ProjectModels"
import "./ProjectMessages.css"
import DropTarget from "../utils/DropTarget"
import { DropTargetViewLarge } from "../utils/DropTargetView"
import { EditableRichText } from "../utils/RichTextEditor"
import { ResumableVideoRecorder } from "../notes/ResumableVideoRecorder"
import { CancelButton, DeleteButton, OKButton, FAButton } from "../utils/Buttons"
import { fmt, s } from "../utils/Fmt"
import { VideoThumbnailPlayer } from "../utils/MediaThumbnail"
import { MemberDisplay } from "../utils/MemberDisplay"
import { _LevelupDB } from '../../models3/_LevelupDB'
import { AppRoot } from "../../models3/AppRoot"
import { downloadUrl } from "../notes/NoteItem"
import { confirmAlert } from '../utils/ConfirmAlert'
import { useCreatorName } from "../utils/useCreatorName"
import { IndexedDB } from "../../models3/IndexedDB"
import { canUploadWithoutCompression } from '../images/AllowedVideos'

import _debug from "debug"; let log = _debug('sltt:ProjectMessages')

// show message counts for other projects???

interface IMessageAddItemButtons {
    addText: () => void;
    addVideo: () => void;
    iAmInterpreter: boolean,
    recordMessage: string,
    writeMessage: string,
    dropMessage: string,
}

const MessageAddItemButtons: FC<IMessageAddItemButtons> = ({ 
        iAmInterpreter, addText, addVideo,
        recordMessage, writeMessage, dropMessage }) => {
    if (!iAmInterpreter) {
        return null
    }

    return (
        <div className="note-add-buttons">
            <div className="p-n-record-button">
                <div className=" note-record-button-video et-right fa fa-3x fa-circle text-danger"
                    data-id="create-video-note-item"
                    onClick={addVideo} />
                <div>{recordMessage}</div>
            </div>
            <div className="note-record-button">
                <div className="p-n-record-button-write et-right fa fa-3x fa-edit"
                    data-id="create-text-note-item"
                    onClick={addText} />
                <div>{writeMessage}</div>
            </div>
            <div className="p-n-record-button p-n-drop-button">
                <div className="et-right fa fa-3x fa-cloud-upload-alt"
                    data-id="create-drop-note-item" />
                <div>{dropMessage}</div>
            </div>
        </div>
    )
}


interface IProjectMessageSubject {
    rt: Root,
    subject: string,
    setSubject: (subject: string) => void,
}

const ProjectMessageSubject: FC<IProjectMessageSubject> = ({ rt, subject, setSubject }) => {
    return (
        <div className={"p-n-subject"}>
            <input type="text"
                autoFocus
                className={subject.trim() === '' ? ' p-n-error' : ''}
                value={subject}
                onChange={e => setSubject(e.target.value)}
                placeholder={t`Subject...`} />
        </div>
    )
}

interface IProjectMessageText {
    rt: Root,
    text: string,
    setText: (text: string) => void,
    editing: boolean,
    setEditing: (editing: boolean) => void,
}

const ProjectMessageText: FC<IProjectMessageText> = 
        ({ rt, text, setText, editing, setEditing }) => {
    return (
        <div className={"p-n-text"}>
            <EditableRichText
                savedText={text}
                save={(_text?: string) => {
                    setText(_text ?? '') 
                    setEditing(false)
                }}
                cancel={() => { setEditing(false) }}
                editorOpen={editing}
                setEditorOpen={setEditing}
                prefix={'message-document'}
                sizeLimit={50000}
            />        
        </div>
    )
}

async function uploadDroppedVideo(files: FileList, baseUrl: string, creationDate: string): Promise<string> {
    const errorMessage = canUploadWithoutCompression(files[0])
    if (errorMessage) {
        displayError(errorMessage)
        return ''
    }

    return await Project.copyFileToVideoCache(files[0], baseUrl, creationDate)
}

interface ICreateProjectMessage {
    rt: Root,
    creatingMessage: boolean, 
    setCreatingMessage: (creating: boolean) => void,
} 

const CreateProjectMessage: FC<ICreateProjectMessage> = 
        ({ rt, creatingMessage, setCreatingMessage }) => {
    
    let [subject, setSubject] = useState('') // subject line
    let [editing, setEditing] = useState(false) // written text editing in process
    let [text, setText] = useState('')
    let [recording, setRecording] = useState(false) // video recording in process
    let [videoUrl, setVideoUrl] = useState('')
    let [playing, setPlaying] = useState(false)
    
    let messageRef = useRef(rt.project.createProjectMessage())

    let { iAmInterpreter } = rt
    
    function addText() { setEditing(true) }

    const baseUrl = () => `${rt.project.name}/${messageRef.current._id}`

    function startRecording() {
        // If we have already recorded a video we need to create a new message _id in order
        // to ensure that the uploaded video files to not conflict with the previously
        // recorded video files (which we are abandoning)
        if (videoUrl) {
            let message = messageRef.current!
            let parts = message._id.split('_')
            let _id = `${parts[0]}_${(parseInt(parts[1])+1).toString().padStart(6, ' ')}`
            message._id = _id
        }

        setRecording(true) 
    }

    async function finishRecording(err: any, blobsCount?: number) {
        if (err) {
            setRecording(false)
            displayError(err)
            return
        }

        setVideoUrl(baseUrl() + '-' + blobsCount)
        setRecording(false)
    }

    async function createMessage() {
        let _notif = messageRef.current
        _notif.subject = subject
        _notif.text = text
        _notif.videoUrl = videoUrl

        log('createMessage', s(_notif.dbg()))
        await rt.project.addProjectMessage(_notif)

        setCreatingMessage(false)
    }

    let dropTargetMessage = <div>{t`Drop video notice here.`}</div>
    let dropTargetView = <DropTargetViewLarge message={dropTargetMessage} />

    let { creator, creationDate } = messageRef.current

    let recordMessage = t`Record message.`
    let writeMessage = t`Write message.`
    let dropMessage = t`Drop message`

    return (
        <Modal keyboard={true} dialogClassName={""} show={true} onHide={() => {}} bsSize="large">
            <Modal.Header>
                <Modal.Title>
                    {t`Create Message`}
                </Modal.Title>
            </Modal.Header>
            <DropTarget
                upload={async (files) => { 
                    uploadDroppedVideo(files, baseUrl(), creationDate)
                        .then(_url => setVideoUrl(_url))
                        .catch(systemError)
                }}
                dropTargetView={dropTargetView}>
            <Modal.Body>
                <ProjectMessageSubject {...{rt, subject, setSubject}} /> 
                <hr/>
                {recording && 
                    (<div className="p-n-video">
                        <ResumableVideoRecorder
                            cancel={() => setRecording(false)}
                            url={baseUrl()}
                            projectName={rt.project.name}
                            save={finishRecording} /> 
                    </div>)}
                {(videoUrl && !recording) && <VideoThumbnailPlayer {...{videoUrl, creator, playing, setPlaying}}/>}
                { ((text || editing) && !recording) && 
                    <ProjectMessageText {...{rt, text, setText, editing, setEditing}} /> }
                {(!recording && !editing) && <MessageAddItemButtons {...{
                    iAmInterpreter, addText, addVideo: startRecording,
                    recordMessage, writeMessage, dropMessage
                }} />}
            </Modal.Body>
            </DropTarget>
            <Modal.Footer>
                <div className="p-n-dialog-footer">
                    <OKButton enabled={subject.trim() !== '' && !recording && !editing}
                        buttonClassName=''
                        className='ok-button'
                        tooltip={t`Create notice.`}
                        onClick={createMessage} />
                    <CancelButton enabled={(!recording && !editing)}
                        className='ok-button pull-right'
                        tooltip={t`Cancel. Do not create notice.`}
                        onClick={() => setCreatingMessage(false)} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

function getMember(rt: Root, creator: string) {
    let member = rt.project.findMember(creator)
    if (!member) {
        member = new Member(creator)   //  Create fake member
    }

    return member
}

interface IProjectMessageDetails {
    rt: Root,
    notif: ProjectMessage,
    removable: boolean,
    firstUnviewed: boolean, // true if this is the first unread message
}

const ProjectMessageDetails: FC<IProjectMessageDetails> = ({ rt, notif, removable, firstUnviewed }) => {
    let { dateFormatter, username } = rt
    let { videoUrl, text, creator, creationDate, viewed, subject } = notif

    // you have always 'viewed' your own videos
    if (creator === rt.username) viewed = true

    let allowDelete = removable && (username === creator || rt.iAmAdmin || rt.iAmRoot)
    
    let member = rt.project.findMemberWithDefault(creator)
    let _creationDate = notif.displayedCreationDate(dateFormatter)

    let downloadFilename = `${creationDate.slice(0,16)} ${rt.project.name} ${subject}`
    downloadFilename = downloadFilename.replace(/[^a-z0-9]/gi, '_') + '.webm'

    // When there are a lot of items it is useful to scroll the first
    // unread item into view
    let itemRef = useRef<HTMLDivElement | null>(null)
    useEffect(() => {
        if (!itemRef.current) {
            log('### itemRef null')
            return
        }
        if (firstUnviewed) {
            itemRef.current.scrollIntoView()
        }
    }, [firstUnviewed])

    let [playing, setPlaying] = useState(false)

    let className = viewed ? '' : 'p-n-unviewed-font'

    return (
        <div className="p-n-item" ref={itemRef}>
            <div className='p-n-item-header'>
                <div className='p-n-item-creation-date'>
                    {_creationDate}
                </div>
                <i {...{className}}>
                    <MemberDisplay member={member} imageSize='large' />
                </i>
            </div>
            <div>
                {videoUrl && <VideoThumbnailPlayer {...{ videoUrl, creator, playing, setPlaying }} />}
                {text &&
                    <ProjectMessageText {...{ rt, text,
                        setText: () => { },
                        editing: false,
                        setEditing: () => { }
                    }} />}
            </div>
            <div>
                <span className='p-n-response-item-buttons'>
                    {notif.videoUrl && <span className="p-n-response-download-button"
                        onClick={() => downloadUrl(notif.videoUrl, downloadFilename)}>
                        <i className="fas fa-fw fa-download"></i>
                    </span>}
                    {allowDelete && <DeleteButton
                        className='p-n-response-delete-button'
                        tooltip={t`Delete response.`}
                        enabled={true}
                        onClick={() => {
                            rt.project.removeProjectMessage(notif)
                                .catch(displayError)
                        }} />}
                </span>
            </div>
        </div>
    )
}

interface IProjectMessageUsername {
    rt: Root,
    notif: ProjectMessage,
    last: boolean,
}

const ProjectMessageUsername: FC<IProjectMessageUsername> = observer(({ rt, notif, last }) => {
    let { creator, viewed, _id } = notif
    let { creatorName, title } = useCreatorName(creator)

    if (creator === rt.username) {
        creatorName = t`me`
        viewed = true
    }

    let className = viewed ? 'p-n-viewed-font' : 'p-n-unviewed-font'

    return (
        <>
            <span {...{ className, title }}>{creatorName}</span>
            {last ? "" : ", "}
        </>
    )
})

interface IProjectMessageUsernames {
    rt: Root,
    notif: ProjectMessage,
}

const ProjectMessageUsernames: FC<IProjectMessageUsernames> = observer(({ rt, notif }) => {
    let responses = notif.responses

    // need to access these to force view to update when they change
    let vieweds = responses.map(r => r.viewed)

    let shownResponses = responses.slice(-2)

    return (
        <div>
            <ProjectMessageUsername {...{ rt, notif, last: responses.length === 0, key: notif._id }} />
            { responses.length > 2 && '..., '}
            {shownResponses.map((n, i) => (
                <ProjectMessageUsername {...{rt, notif: n, last: i === shownResponses.length-1, key: n._id}} />
            ))}
            {responses.length > 0 ? ` ${responses.length+1}` : ''}
        </div>
    )
})

interface IProjectMessageSummary {
    rt: Root,
    notif: ProjectMessage,
    showMessage(_notif: ProjectMessage): void,
    appRoot: AppRoot,
}

const ProjectMessageSummary: FC<IProjectMessageSummary> = observer(({ rt, notif, showMessage, appRoot }) => {
    let { username, dateFormatter, iAmRoot } = rt
    let { creator, subject, viewed, responses } = notif
    let allowDelete = (username === creator || rt.iAmAdmin || rt.iAmRoot)

    let allowPublish = (!notif.globalMessage && iAmRoot && ProjectMessage.isGlobalProject(rt.name))

    if (notif.globalMessage) {
        subject = '(SLTT) ' + subject
    }

    async function deleteProjectMessageAndChildren() {
        let { project } = rt
        for (let _notif of notif.responses) {
            await project.removeProjectMessage(_notif)
        }

        await project.removeProjectMessage(notif)
    }

    async function publishProjectMessage(shiftKey: boolean) {
        // might use shiftKey in the future to dp limited publishing
        let testPublishMessage = localStorage.getItem('testPublishMessage')

        await notif.setGlobalMessage(true)
        for (let _rt of appRoot.rts) {
            if (_rt.name === rt.name) continue
            if (testPublishMessage && _rt.name !== 'TESTnm') continue

            await _rt.project.addProjectMessage(notif)
        }
    }

    return (
        <>
            <td className="p-n-cell" onClick={() => showMessage(notif)}>
                <ProjectMessageUsernames {...{rt, notif}} />
            </td>
            <td className="p-n-cell" onClick={() => showMessage(notif)}>{subject}</td>
            <td className="p-n-cell" onClick={() => showMessage(notif)}>
                {notif.displayedCreationDate(dateFormatter)}</td>
            <td className="p-n-cell">
                <span className='p-n-item-buttons'>
                    {allowPublish && <span className="p-n-publish-button"
                        onClick={(e) => confirmAlert({
                            title: t`Publish message to *ALL* projects?`,
                            message: t`This is NOT easily undone.`,
                            confirmLabel: t`Publish!`,
                            cancelLabel: t`Cancel.`,
                            onConfirm: () => {
                                publishProjectMessage(e.shiftKey).catch(displayError)
                            },
                            onCancel: () => { },
                        })}>
                        <i className="fas fa-fw fa-rss"></i>
                    </span>}
                    {allowDelete && <DeleteButton
                        className='p-n-delete-button'
                        tooltip={t`Delete response.`}
                        enabled={true}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.preventDefault()
                            deleteProjectMessageAndChildren()
                                .catch(displayError)
                        }} />}
                </span>
            </td>
        </>
    )
})

async function createResponse(rt: Root, 
        notif: ProjectMessage,
        response: ProjectMessage, 
        text: string | undefined, 
        videoUrl: string | undefined) 
{
    response.subject = ''
    response.text = text || ''
    response.videoUrl = videoUrl || ''
    response.parent = notif._id

    if (notif.globalMessage) {
        response.isGlobalResponse = true
    }

    log('createResponse', s(response.dbg()))
    await rt.project.addProjectMessage(response)
}

interface ICreateProjectMessageTextResponse {
    rt: Root,
    notif: ProjectMessage,
    setEditing: (editing: boolean) => void,
}

const CreateProjectMessageTextResponse: FC<ICreateProjectMessageTextResponse> = (
    { rt, notif, setEditing }) => 
{
    let messageRef = useRef(rt.project.createProjectMessage())

    function createTextResponse(_text?: string) {
        setEditing(false)
        if (!_text) return

        createResponse(rt, notif, messageRef.current, _text, undefined).catch(displayError)
    }

    return (
        <div className={"p-n-text"}>
            <EditableRichText
                savedText={''}
                save={createTextResponse}
                cancel={() => { setEditing(false) }}
                editorOpen={true}
                setEditorOpen={setEditing}
                prefix={'message-document'}
            />
        </div>
    )
}

interface ICreateProjectMessageVideoResponse {
    rt: Root,
    notif: ProjectMessage,
    setRecording: (editing: boolean) => void,
}

const CreateProjectMessageVideoResponse: FC<ICreateProjectMessageVideoResponse> = (
    { rt, notif, setRecording }) => 
{
    let messageRef = useRef(rt.project.createProjectMessage())

    const baseUrl = () => `${rt.project.name}/${messageRef.current._id}`

    async function finishRecording(err: any, blobsCount?: number) {
        if (err) {
            setRecording(false)
            displayError(err)
            return
        }
        if (blobsCount === undefined) return

        let videoUrl = baseUrl() + '-' + blobsCount
        createResponse(rt, notif, messageRef.current, undefined, videoUrl).catch(displayError)

        setRecording(false)
    }

    return (
        <div className={"p-n-video"}>
            <ResumableVideoRecorder
                cancel={() => setRecording(false)}
                url={baseUrl()}
                projectName={rt.project.name}
                save={finishRecording} />
        </div>
    )
}

interface IShowMessage {
    rt: Root,
    selectedMessage: ProjectMessage,
    setShowingMessage: (showing: boolean) => void,
    previousMessage?: () => void,
    nextMessage?: () => void,
}

const ShowProjectMessage: FC<IShowMessage> = 
    ({ rt, selectedMessage, setShowingMessage, previousMessage, nextMessage }) => 
{
        let { iAmInterpreter } = rt
        let dropTargetMessage = <div>{t`Drop video response here.`}</div>
        let dropTargetView = <DropTargetViewLarge message={dropTargetMessage} />

        async function upload(files: FileList) {
            if (!iAmInterpreter) {
                displayError(t`Observers cannot upload files.`)
                return
            }

            let response = rt.project.createProjectMessage()
            let baseUrl = `${rt.project.name}/${response._id}`

            response.videoUrl = await uploadDroppedVideo(files, baseUrl, response.creationDate)
            if (response.videoUrl === '') {
                return // upload failed, so don't add a new message
            }

            response.parent = selectedMessage._id

            log('create dropped response', s(response.dbg()))
            await rt.project.addProjectMessage(response)
        }

        return (
            <DropTarget 
                    upload={async (files) => { upload(files).catch(displayError) }}
                    dropTargetView={dropTargetView}>
                <ShowProjectMessage2 
                    {...{ rt, selectedMessage, setShowingMessage, 
                            previousMessage, nextMessage }} />
            </DropTarget>
        )
}

interface IMessageSelectionArrows {
    notif: ProjectMessage,
    setShowingMessage: (showing: boolean) => void,
    previousMessage?: () => void,
    nextMessage?: () => void,

}

const MessageSelectionArrows: FC<IMessageSelectionArrows> = 
        ({ notif, setShowingMessage, previousMessage, nextMessage }) => {
    return (
        <div className="p-n-message-selection-arrows">
            <div>
                <FAButton
                    className={'fa-arrow-left p-n-arrow'}
                    tooltip={t`View all messages`}
                    enabled={true}
                    onClick={async () => { 
                        await notif.setViewed(true)
                        setShowingMessage(false); 
                    }} />
            </div>
            <div className="text-right">
                <FAButton
                    className={'fa-arrow-down p-n-small-arrow'}
                    tooltip={t`Go to next message`}
                    enabled={!!nextMessage}
                    onClick={() => nextMessage!()} />
                <FAButton
                    className={'fa-arrow-up p-n-small-arrow'}
                    tooltip={t`Go to previous message`}
                    enabled={!!previousMessage}
                    onClick={() => { previousMessage!() }} />
            </div>
        </div>
    )
}

const ShowProjectMessage2: FC<IShowMessage> = observer(
            ({ rt, selectedMessage, setShowingMessage,
                previousMessage, nextMessage }) =>
{
    let { iAmInterpreter } = rt
    let notif = selectedMessage

    let [editing, setEditing] = useState(false) // written text editing in process
    let [recording, setRecording] = useState(false) // video recording in process

    let recordMessage = t`Record response.`
    let writeMessage = t`Write response.`
    let dropMessage = t`Drop response.`

    let firstUnviewed = notif.responses.find(r => !r.viewed)

    return (
        <div>
            <h2 className="p-n-subject">{selectedMessage.subject}</h2>
        {(!editing && !recording) && (
            <>
                <MessageSelectionArrows {...{notif, setShowingMessage, previousMessage, nextMessage}} />
                <hr />
                <ProjectMessageDetails 
                    {...{ rt, notif: selectedMessage, removable: false, firstUnviewed: false}} />
                <hr/>
                {notif.responses.map(response => (
                    <ProjectMessageDetails 
                        {...{ rt, notif: response, key: response._id, removable: true,
                            firstUnviewed: response === firstUnviewed,
                        }} />
                ))}
                <hr />
                <MessageAddItemButtons 
                        {...{ iAmInterpreter, recordMessage, writeMessage, dropMessage }} 
                    addText={() => setEditing(true)}
                    addVideo={() => setRecording(true)} /> 
                <hr />
                <MessageSelectionArrows {...{ notif, setShowingMessage, previousMessage, nextMessage }} />
            </>)}
            {editing &&
                <CreateProjectMessageTextResponse {...{ rt, notif: selectedMessage, setEditing }} />}
            {recording &&
                <CreateProjectMessageVideoResponse {...{ rt, notif: selectedMessage, setRecording }} />}
        </div>
    )
})

interface ICreateProjectMessageButton {
    setCreatingMessage: (creating: boolean) => void,
}

const CreateProjectMessageButton: FC<ICreateProjectMessageButton> = 
        ({ setCreatingMessage }) => {

    return (
        <a className="p-n-create-button">
            <i className="fa-comment-dots fas fa-2x edit-project-settings-button"
                onClick={() => setCreatingMessage(true)}
                data-toggle="tooltip"
                title = { t`Create a video or text message that can be seen by all project members.`} />
            <span>+</span>
        </a>
    )
}

interface IMessageCount {
    projectName: string,
    count: number,
}

async function getProjectMessageCount( projectName: string ): Promise<IMessageCount> 
{
    log('getProjectMessageCount', projectName)

    let ids: Set<string> = new Set()

    let records = await IndexedDB.readDBRecords(projectName)
    for (let record of records) {
        let {_id, viewed, removed} = record.doc
        if (!_id.startsWith('notify/')) continue
        if (viewed) continue
        if (removed) {
            ids.delete(_id)
            continue
        }
        ids.add(_id)
    }

    log('getProjectMessageCount DONE', ids.size)
    return ({projectName, count: ids.size})
}

async function getOtherProjectsMessageCounts(
            projectName: string,
            appRoot: AppRoot,
            setMessageCounts: (messageCounts:IMessageCount[]) => void,
        ): Promise<void> 
{
    let _messageCounts: IMessageCount[] = []
    
    for (let _rt of appRoot.rts) {
        let { name } = _rt.project
        if (name === projectName) continue
        
        let messageCount = await getProjectMessageCount(name)
        if (messageCount.count) {
            _messageCounts.push(messageCount)
        }
    }

    setMessageCounts(_messageCounts)
}

interface IOtherProjectMessageCounts {
    rt: Root, 
    appRoot: AppRoot,
    messageCounts: IMessageCount[],
}

const OtherProjectMessageCounts: FC<IOtherProjectMessageCounts> = 
    ({ rt, appRoot, messageCounts }) => 
{
    if (messageCounts.length === 0) return null

    return (
        <div className="p-n-message-counts">
            {messageCounts.map((mc, i) => (
                <a key={mc.projectName} onClick={() => appRoot.setCurrentProject(mc.projectName)}>
                    {`${mc.projectName}* (${mc.count})${i < messageCounts.length-1 ? ', ' : ''}`}
                </a>
            ))}
        </div>
    )
}

interface IProjectMessages {
    rt: Root,
    appRoot: AppRoot,
}

const ProjectMessages: FC<IProjectMessages> = observer(({ rt, appRoot }) => {
    let { project, iAmInterpreter } = rt
    let messages = _.sortBy(project.messages, n => n.creationDate).reverse()

    let [creatingMessage, setCreatingMessage] = useState(false)
    let [showingMessage, setShowingMessage] = useState(false)
    let[selectedMessage, setSelectedMessage] = useState<ProjectMessage | undefined>(undefined)
    let [messageCounts, setMessageCounts] = useState<IMessageCount[]>([])

    useEffect(() => {
        // Root has access to all projects. We don't try to search every project on the system.
        if (!rt.iAmRoot) {
            getOtherProjectsMessageCounts(project.name, appRoot, setMessageCounts).catch(displayError)
        }
    }, [rt.project.name])

    if (creatingMessage) return (
        <CreateProjectMessage {...{ rt, creatingMessage, setCreatingMessage}} />
    )

    if (showingMessage) {
        let i = messages.findIndex(n => n === selectedMessage!)
        return (
            <ShowProjectMessage {...{ 
                rt, 
                selectedMessage: selectedMessage!, 
                setShowingMessage,
                nextMessage: (i >=0 && i < messages.length-1) ?
                    () => setSelectedMessage(messages[i+1]) : undefined,
                previousMessage: (i >= 1) ?
                    () => setSelectedMessage(messages[i-1]) : undefined,
            }} />
        )
    }

    function showMessage(_notif: ProjectMessage) {
        setSelectedMessage(_notif)
        setShowingMessage(true)
    }

    return (
        <div>
            <div className="p-n-header">
                <div className="p-n-header-item1">
                    { iAmInterpreter && <CreateProjectMessageButton {...{ setCreatingMessage}} /> }
                </div>
                <div className="p-n-header-item2">
                    {t`Project Messages`}
                </div>
            </div>
            <div className="p-n-table">
                <table className="table .table-bordered p-n-viewed">
                    <tbody>
                        {messages.map(notif => (
                            <tr className={`p-n ${notif.anyUnviewed(rt.username) ? 
                                    'p-n-unviewed-color' : 'p-n-viewed-color'}`}
                                        key={notif._id} >
                                <ProjectMessageSummary {...{rt, notif, showMessage, appRoot}} /> 
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <OtherProjectMessageCounts {...{rt, appRoot, messageCounts}} />
        </div>
    ) 
})

export default ProjectMessages