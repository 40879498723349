import { t } from "ttag"
import { DateFormat } from "./ProjectModels"

export function dateExists(existingDates: Date[], date: Date) {
    let existingDate = existingDates.find(e => {
        return (
            (e.getFullYear() === date.getFullYear())
            && (e.getMonth() === date.getMonth())
            && (e.getHours() === date.getHours())
            && (e.getMinutes() === date.getMinutes())
        )
    })
    return (existingDate !== undefined)
}

export function adjustForDuplicates(existing: Date[], date: Date) {
    let adjustedDate = new Date(date)
    let minutes = adjustedDate.getMinutes()
    let foundUnusedDate = !dateExists(existing, adjustedDate)

    let numTries = 1
    while (!foundUnusedDate && minutes < 59) {
        adjustedDate.setMinutes(minutes + 1)
        minutes = adjustedDate.getMinutes()
        numTries++
        foundUnusedDate = !dateExists(existing, adjustedDate)
    }

    if (!foundUnusedDate && numTries < 60) {
        adjustedDate.setMinutes(0)
        minutes = adjustedDate.getMinutes()
        foundUnusedDate = !dateExists(existing, adjustedDate)
        numTries++
        while (!foundUnusedDate && numTries !== 60) {
            adjustedDate.setMinutes(minutes + 1)
            minutes = adjustedDate.getMinutes()
            numTries++
            foundUnusedDate = !dateExists(existing, adjustedDate)
        }
    }

    if (!foundUnusedDate) {
        throw Error(t`Could not upload file because another file with the same creation date has already been uploaded.`)
    }

    return adjustedDate
}

export function isNewerThanOneMonth(date: string) {
    let dateDate = Date.parse(date)
    if (isNaN(dateDate)) {
        return true
    }
    const millisecondsPerMonth = 30*24*3600*1000
    let aMonthAgo = Date.now() - millisecondsPerMonth
    let newerThanOneMonth = dateDate - aMonthAgo > 0
    return newerThanOneMonth
}

export const newerThanCutoffDate = (creationDateString: string, cutoff: Date) => {
    let creationDate = Date.parse(creationDateString)
    if (isNaN(creationDate)) {
        return false
    }

    return creationDate > cutoff.getTime()
}

export function daysAgo(numDays: number) {
    const millisecondsPerDay = 60*60*24*1000
    return Date.now() - millisecondsPerDay * numDays
}

export class DateFormatterFactory {
    getDateFormatter(dateFormat: DateFormat, locale: string) {
        switch (dateFormat) {
            case DateFormat.ddmmyyyy: {
                return new DdmmyyyyFormatter(locale)
            }
            case DateFormat.mmddyyyy: {
                return new MmddyyyyFormatter(locale)
            }
            case DateFormat.mmmmddyyyy: {
                return new MmmmddyyyyFormatter(locale)
            }
            case DateFormat.yyyymmdd: {
                return new Yyyymmddformatter(locale)
            }
        }
    }
}

export interface IDateFormatter {
    format: (date: Date) => string
}

class MmddyyyyFormatter implements IDateFormatter {
    constructor(private locale: string) {
        this.locale = locale
    }

    format(date: Date) {
        let year = date.getFullYear()
        let monthString = (date.getMonth() + 1).toString().padStart(2, '0')
        let day = date.getDate().toString().padStart(2, '0')
        let localizedTimeString = date.toLocaleTimeString(this.locale)
        let formattedDate = `${monthString}/${day}/${year}`
        return `${formattedDate}, ${localizedTimeString}`
    }
}

class DdmmyyyyFormatter implements IDateFormatter {
    constructor(private locale: string) {
        this.locale = locale
    }

    format(date: Date) {
        let year = date.getFullYear()
        let monthString = (date.getMonth() + 1).toString().padStart(2, '0')
        let day = date.getDate().toString().padStart(2, '0')
        let localizedTimeString = date.toLocaleTimeString(this.locale)
        let formattedDate = `${day}/${monthString}/${year}`
        return `${formattedDate}, ${localizedTimeString}`
    }
}

class MmmmddyyyyFormatter implements IDateFormatter {
    constructor(private locale: string) {
        this.locale = locale
    }

    format(date: Date) {
        let options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
        return date.toLocaleString(this.locale, options)
    }
}

class Yyyymmddformatter implements IDateFormatter {
    constructor(private locale: string) {
        this.locale = locale
    }

    format(date: Date) {
        let year = date.getFullYear()
        let monthString = (date.getMonth() + 1).toString().padStart(2, '0')
        let day = date.getDate().toString().padStart(2, '0')
        let localizedTimeString = date.toLocaleTimeString(this.locale)
        let formattedDate = `${year}-${monthString}-${day}`
        return `${formattedDate}, ${localizedTimeString}`
    }
}