import { Sign } from "./Project"

/*
    static async getProject(project: string)
    static async putSign(project: string, sign: any)
    static async deleteSign(project: string, id: number) 
    static async getUploadUrl(project: string, name: string, contentType: string)
    static async uploadFile(uploadUrl: string, file: File, contentType: string)
    static async uploadBlob(uploadUrl: any, blob: Blob, contentType: string)
*/

export class Backend {
    public static URL = 'https://lubd7ktldl.execute-api.us-east-1.amazonaws.com/dev/'

    // Get information for all signs for project from the dynamodb signs table.
    // Data is formatted to match the layout of projects/demoASL/project.json.
    // That is the format that the Project() constructor currently expects.

    static async getProject(project: string) {
        let options: RequestInit = {
            method: 'GET',
        }

        let path = `${Backend.URL}/signs/${project}`
        let response = await fetch(path, options)

        if (response.status !== 200) throw Error(`status: ${response.status} - ${response.statusText}`)

        let result = await response.json()

        // extract signs from returned data
        let signs = result.map((x: any) => x.data)

        // currently we are using fake projectData
        projectData.signs = signs

        return projectData
    }

    // Write the information for 'sign' to the dynamodb signs table.
    // Sign must have a numeric id attribute.
    static async putSign(project: string, sign: any) {
        sign = removeEmptyStringElements(sign) // dyanmodb chokes on attributes with empty string values

        let options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(sign),
        }

        let path = `${Backend.URL}/sign/${project}`
        let response = await fetch(path, options)

        if (response.status !== 200) throw Error(`${response.status} - ${response.statusText}`)

        console.log('putSign response', response.text())
    }

    static async deleteSign(project: string, id: number) {
        let options: RequestInit = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id}),
        }

        let path = `${Backend.URL}/sign/${project}`
        let response = await fetch(path, options)

        if (response.status !== 200) throw Error(`${response.status} - ${response.statusText}`)
    }


    // We need a signed url in order to upload data to S3.
    // Request this URL via the backend API server.

    static async getUploadUrl(project: string, name: string, contentType: string) {
        console.log(`putObjectUrl ${name}/${contentType}`)

        let fullName = `projects/${project}/${name}`

        let options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: fullName, type: contentType}),
        }
        
        let path = `${Backend.URL}/putObjectUrl`
        let response = await fetch(path, options)
        
        if (response.status !== 200) throw Error(`${response.status} - ${response.statusText}`)
        
        let result: any = await response.json()
        return result.uploadURL
    }

    static async uploadFile(uploadUrl: string, file: File, contentType: string) {
        console.log(`putFile ${contentType}`)

        let options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': contentType },
            body: file,
        }

        let response = await fetch(uploadUrl, options)

        if (response.status !== 200) throw Error(`${response.status} - ${response.statusText}`)
    }

    static async uploadBlob(uploadUrl: any, blob: Blob, contentType: string) {
        console.log(`putBlob ${contentType}`)

        let options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': contentType },
            body: blob,
        }

        let response = await fetch(uploadUrl, options)
        if (response.status !== 200) throw Error(`${response.status} - ${response.statusText}`)
    }
}

// Stub version of project data.
// Needs to be stored/retrieved from a dynamodb projects table.
let projectData: any = {
    "soosl_version": "0.8.11_190410",
    "project_name": "Demo (ASL) 0.8.10",
    "project_description": "This sample SooSL project uses real ASL data to demonstrate the features of the SooSL program and help people learn how to use it. It provides examples of good practices in making a dictionary, such as including synonyms and dialect variants (different signs for the same idea), more than one meaning for some signs, example sentences, indexing by components (handshapes, locations, motions), and extra explanatory material (in text, video, and images).\nIt is not intended as a complete, published dictionary of ASL. Rather, it shows what a dictionary might look like while it is being developed. For example, it only includes about 100 signs and covers only a few dialects, it includes notes about things that need to be improved,  and there are many gaps in the information about individual signs.\nWe hope it is useful to you as you learn how to use SooSL. Happy SooSLing!\nACKNOWLEDGEMENTS/THANKS:\nPrimary compilers: Stuart M. Thiessen, J. Albert Bickford, with technical support and suggestions from Tim Grove and Geoffrey Hunt.\nSigners: Adan Burke, Sharon Rosen, Steve Townsend, Philippe Gallant, Stuart Thiessen.\nPhoto illustrations in this database were gleaned from Wikipedia (if attribution free) and MorgueFile (http://www.morguefile.com). All such illustrations are either in the public domain or available under a license that permits their use.",
    "written_languages": [
        {
            "lang_id": 1,
            "lang_name": "English",
            "order": 1
        },
    ],
    "dialects": [
        {
            "id": 1,
            "abbr": "USA",
            "focal": true,
            "name": "USA"
        },
    ],
    "grammar_categories": [
        { "id": 1, "name": "Noun" },
        { "id": 2, "name": "Verb" },
        { "id": 3, "name": "Adj" },
        { "id": 4, "name": "Quant" },
        { "id": 5, "name": "Interj" },
        { "id": 6, "name": "Adv" },
        { "id": 7, "name": "Fingerspelling" },
        { "id": 8, "name": "Namesign" },
        { "id": 9, "name": "Conj." },
        { "id": 10, "name": "Interrog." }
    ],
}

function removeEmptyStringElements(obj: any) {
    for (let prop in obj) {
        if (typeof obj[prop] === 'object') {
            removeEmptyStringElements(obj[prop])
        } else if (obj[prop] === '') {
            delete obj[prop]
        }
    }

    return obj
}


