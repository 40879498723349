import React from 'react'
import { t } from 'ttag'

import { LogoutButton } from './Login'

export default function NotAMember({appRoot}) {
    let { username } = appRoot

    return (<div className="app-no-project-message">
        <div>
            <p>{username}: {t`You are not currently a member of any project.`}</p>
        </div>
        <div>&nbsp;</div>
        <div>
            {t`1) Click the logout button to login again with a different email.`} <LogoutButton {...{ appRoot }} />
        </div>
        <div>
            <p>{t`2) Or ask your project administrator to go to the Settings / Members tab and add: (${username})`}</p>
        </div>
    </div>)
}
