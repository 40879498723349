import { addLocale, useLocale } from 'ttag'
import _debug from 'debug'
const log = _debug('sltt:loadTranslation')

export const DEFAULT_LOCALE = 'en'

export const loadTranslation = async (uiLanguageTarget: string) => {
    log('loadTranslation', uiLanguageTarget)
    if (uiLanguageTarget !== DEFAULT_LOCALE) { // Strings are already in English
        try {
            const translationModule = await import(`../../resources/translations/${uiLanguageTarget}.po.json`)
            const translationObject = translationModule.default
            addLocale(uiLanguageTarget, translationObject)
        } catch (error) {
            console.error(`Failed to load translation for language: ${uiLanguageTarget}`, error)
        }
    }
    useLocale(uiLanguageTarget)
}
