import React, { FC, useRef, useState } from 'react'
import { t } from 'ttag'
import _debug from 'debug'

import { Member, MemberNotifications, MemberRole, Project } from '../../../models3/ProjectModels'
import { DeleteButton, EditButton, EmailRegistrationButton } from '../../utils/Buttons'
import API from '../../../models3/API'
import MemberRoleView from './MemberRole'
import { displayError, displaySuccess, systemError } from '../../utils/Errors'
import { Root } from '../../../models3/Root'
import { observer } from 'mobx-react'
import { DropTargetViewSmall } from '../../utils/DropTargetView'
import DropTarget from '../../utils/DropTarget'
import { allowedImageTypes, isAllowedImage } from '../../images/AllowedImages'
import '../ProjectSettings.css'
import { EditableMemberDisplay, MemberDisplay } from '../../utils/MemberDisplay'
import ImageResizer, { MAX_RESIZER_FILE_SIZE } from '../../utils/ImageResizer'
import { Switch } from '../../utils/Switch'
import { useFlags } from 'launchdarkly-react-client-sdk'
import TextInput from '../../utils/TextInput'
import { GenericIcon } from '../../utils/Icons'
import { useOnClickOutside } from '../../utils/Hooks'
import { confirmAlert } from '../../utils/ConfirmAlert'
import { TrashIcon } from '../../utils/Icons'; 
import './MemberView.css';

let log = _debug('sltt:MemberView')

const notificationsText = t`Notifications`

interface IMemberNameEditor {
    rt: Root,
    member: Member,
    allowEditing: boolean,
}

export const MemberNameEditor: FC<IMemberNameEditor> = observer(({ member, rt, allowEditing }) => {
    let [editing, setEditing] = useState(false)
    const { project } = rt
    const { name } = member

    if (editing) {
        return (
            <div className='member-name-editor-editing'>
                <TextInput
                    validate={() => '' /* validation always succeeds */ }
                    initialValue={name}
                    message={t`Type <Enter> to change user name or type <Esc> to cancel`}
                    _onEnter={_name => {
                        project.setMemberName(member.email, _name.trim()).catch(systemError)
                        setEditing(false)
                    }}
                    _onEscape={() => setEditing(false)}
                    allowEmptyValue={false} />
            </div>
        )
    }

    return (
        <div className='member-name-editor'>
            <span className='member-name-edit-name'>{name}</span>
            {allowEditing && 
                <div className='member-name-edit-button'>
                    <EditButton
                        className=''
                        enabled={true}
                        onClick={() => setEditing(true)}
                        tooltip={t`Edit`} />
                </div>}
        </div>
    )
})

interface IMemberNotificationsEditor {
    rt: Root,
    member: Member,
}

export const MemberNotificationsEditor: FC<IMemberNotificationsEditor> = observer(({ member, rt }) => 
{
    const { project, iAmAdmin } = rt

    if (!iAmAdmin && rt.username !== member.email) { 
        return <div />
    }

    const isMemberNotificationsEnabled = member.notifications === MemberNotifications.HOURLY
    const notificationsToolTip = isMemberNotificationsEnabled ?
        t`Turn OFF notifications (which could take an hour to stop)` :
        t`Turn ON notifications (sent once an hour when there is activity)`

    return (
        <div className="member-notifications-editor">
            <label className="notifications-switch-control">
                <div className="notifications-switch">
                    <Switch
                        className="notifications-switch"
                        value={isMemberNotificationsEnabled}
                        setValue={
                            async (value: boolean) => {
                                await project.setMemberNotifications(
                                    member.email,
                                    value ? MemberNotifications.HOURLY : MemberNotifications.NONE
                                )
                            }}
                        enabled={true}
                        tooltip={notificationsToolTip}>
                    </Switch>
                </div>
            </label>
        </div>
    )
})

interface ISingleMemberView {
    rt: Root,
    member: Member | undefined,
}

export const SingleMemberView: FC<ISingleMemberView> = observer(({ member, rt }) => {
    const { emailNotifications } = useFlags()

    if (!member) {
        return <div />
    }

    return (<div className='single-member-view'>
        <div className='member-name-editor'>
            <span style={{fontSize: '125%'}}>{t`Name`}:&nbsp;&nbsp;</span>
            <MemberNameEditor {...{rt, member, allowEditing: true}} />
        </div>
        <br />
        <div>
            <MemberDropTarget {...{ rt, member }} >
                <EditableMemberDisplay 
                    member={member} 
                    project={rt.project} />
            </MemberDropTarget>
        </div>
        <br />
        {emailNotifications && <div>
            <GenericIcon iconName="fa-bell" className="single-member-notification-icon" tooltip="" />
            <span style={{ fontSize: '125%' }}>{notificationsText}:</span>&nbsp;&nbsp;
            <MemberNotificationsEditor {...{ rt, member }} />&nbsp;&nbsp;
            <span style={{ fontSize: '125%' }}>{member.notifications === MemberNotifications.HOURLY ? t`On` : t`Off`}</span>
        </div>}
    </div>)
})


interface IMemberView {
    member: Member,
    rt: Root,
    isSoleAdmin: boolean,
}

export const MemberView: FC<IMemberView> = observer(({ member, rt, isSoleAdmin }) => {
    const flags = useFlags()

    const onSetRole = (role: MemberRole) => {
        rt.project.setMemberRole(member.email, role)
            .catch(displayError)
    }

    const removeMember = () => {
        rt.project.removeMember(member.email)
            .catch(displayError)
    }

    const deleteThisUser = /* translator: important */ t`Delete this user`
    // Function to display the remove member confirmation dialog
    const displayRemoveMemberDialog = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            confirmAlert({
                title: /* translator: important */ t`Delete this user?!`,
                message: /* translator: important */ t`Are you sure you want to remove this member from the project?`,
                confirmLabel: (
                    <>
                        <TrashIcon className="" tooltip={deleteThisUser} />
                        {deleteThisUser}
                    </>
                ),
                cancelLabel: /* translator: important */ t`Cancel`,    
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
            });
        });
    };

    // Function to prompt for removing a member
    const removeMemberPrompt = async () => {
        if (await displayRemoveMemberDialog()) {
            // Call the removeMember function if confirmed
            removeMember()
        }
    }

    const emailRegistration = () => {
        let { email } = member
        email = email.trim()

        log(`emailRegistration ${member.email}`)

        API.register(email)
            .then(() => displaySuccess(t`SLTT Registration email sent to ${email}`))
            .catch(displayError)
    }

    let { iAmAdmin, project } = rt

    const canEditMemberDisplay = iAmAdmin || rt.username === member.email

    return (
        <tr className='member-row'>
            <td>
                {canEditMemberDisplay && 
                    <MemberDropTarget {...{ rt, member }} >
                        <EditableMemberDisplay {...{ project, member }} />
                    </MemberDropTarget>}
                {!canEditMemberDisplay && <MemberDisplay member={member} imageSize='large' showEmail />}
                
            </td>
            <td className='member-name-cell'>
                <MemberNameEditor {...{ rt, member, allowEditing: iAmAdmin }} />
            </td>
            <td className='vertically-centered-text'>
                <MemberRoleView
                    member={member}
                    rt={rt}
                    _onChange={onSetRole}
                    isSoleAdmin={isSoleAdmin} />
            </td>
            <td>
                {(!!flags.emailNotifications && rt.iAmAdmin) && <MemberNotificationsEditor {...{ rt, member }} />}
            </td>
            <td>
                <div style={{ display: 'flex' }}>
                    {iAmAdmin && !isSoleAdmin &&
                        <DeleteButton
                            className="sl-delete-button"
                            tooltip={t`Remove this member from project`}
                            enabled={true}
                            onClick={removeMemberPrompt} />
                    }
                    {iAmAdmin && flags.emailRegistrationButtonOld &&
                        <EmailRegistrationButton
                            tooltip={t`Email a registration link to member`}
                            enabled={true}
                            onClick={emailRegistration} />
                    }
                </div>
            </td>
        </tr>
    )
})

async function uploadImage(project: Project, member: Member, files: FileList) {
    if (files.length !== 1) {
        displayError(t`You must upload exactly one file.`)
        return
    }

    const file = files[0]
    if (!isAllowedImage(file.type)) {
        displayError(t`Cannot upload this type of file.` + ' ' + t`Supported types:` + ` ${allowedImageTypes.join(', ')}`)
        return
    }

    if (file.size > MAX_RESIZER_FILE_SIZE) {
        displayError(t`File must be smaller than 50MB.`)
        return
    }

    try {
        let resizer = new ImageResizer(file)
        let resizedUrl = await resizer.resize(64, 64)
        await project.setMemberImage(member.email, resizedUrl)
    } catch (error) {
        systemError(error)
    }
}

// <MemberImagePicker {...{project, member}} />
interface IMemberImagePicker {
    project: Project,
    member: Member,
    setPickImage: (value: boolean) => void,
}

export const MemberImagePicker: FC<IMemberImagePicker> = observer(({ project, member, setPickImage }) => {
    const ref = useRef<HTMLDivElement>(null)
    
    useOnClickOutside(ref, () => {
        log('useOnClickOutside')
        setPickImage(false)
    })
    
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.target.files
        if (files) {
            uploadImage(project, member, files).catch(systemError)
            setPickImage(false)
        }
    }

    const memberName = member.displayName

    return (
        <div className='member-image-picker' ref={ref}>
            <div className='member-image-picker-header'>
                <span>{t`Choose an image for ${memberName}`}</span>
            </div>
            <div>
                <input type="file" accept={`${allowedImageTypes.join(',')}`}
                    onChange={handleImageUpload} />
                <span className={`sl-fa-button far fa-times-circle member-image-picker-cancel-button`}
                        onClick={() => setPickImage(false)} />
            </div>
        </div>
    )
})

interface IMemberDropTarget {
    member: Member,
    rt: Root,
}

const MemberDropTarget: FC<IMemberDropTarget> = observer(({ member, rt, children }) => {
    const message = <div />
    const dropTargetView = <DropTargetViewSmall {...{ message }} />
    const upload = (files: FileList) => uploadImage(rt.project, member, files)

    return (
        <DropTarget {...{ upload, dropTargetView }} >
            {children}
        </DropTarget>
    )
})
