import levelup, { LevelUp } from 'levelup'
import leveljs from 'level-js'
import _ from 'underscore'

import _debug from "debug"; const log = _debug('sltt:IndexedDB')
const dbg = _debug('slttdbg:Levelup')

import { openDB } from 'idb'
import { fmt, s } from '../components/utils/Fmt'
import { DBEntry, IDBIterator, IDBModDoc, IDBOperations } from './DBTypes'

/**
 * This class contains all the code that directly accesses the IndexedDB database.
 */
export class IndexedDB implements IDBOperations {
    public db: LevelUp<leveljs>

    constructor(public name: string) {
        log(`constructor ${name}`)
        this.db = levelup(leveljs(name + '-db'))
        this.name = name
    }

    async readKeys() {
        return await IndexedDB.readKeys(this.name)
    }

    async readDBRecords(): Promise<DBEntry[]> {
        return await IndexedDB.readDBRecords(this.name)
    }

    static buildDbName(name: string) {
        return `level-js-${name}-db`
    }

    static buildStoreName(name: string) {
        return `${name}-db`
    }

    static getDbAndStoreName(name: string) {
        const dbname = this.buildDbName(name)
        const storeName = this.buildStoreName(name)
        return { dbname, storeName }
    }

    static async readKeys(name: string): Promise<(number | string)[]> {
        const { dbname, storeName } = this.getDbAndStoreName(name)

        const idb = await openDB(dbname, 1)

        const allKeys = await idb.getAllKeys(storeName)
        idb.close()

        return allKeys.map(key => key as number | string)
    }

    /** This is remarkably faster than get() when reading all records. */
    static async readDBRecords(name: string): Promise<DBEntry[]> {
        const { dbname, storeName } = this.getDbAndStoreName(name)

        let idb = await openDB(dbname, 1)

        const keys = await idb.getAllKeys(storeName)
        const values = await idb.getAll(storeName)
        idb.close()

        let entries: DBEntry[] = []

        for (let i = 0; i < keys.length; ++i) {
            let key = keys[i] as number | string
            let doc = JSON.parse(values[i])
            entries.push({ key, doc })
        }

        return entries
    }

    async deleteDoc(key: number | string) {
        await this.db.del(key)
    }

    async writeItems(items: any[]) {
        if (items.length === 0) return

        // Writing all the new items to IndexDB in a batch operation is much
        // faster than writing them individually.
        let ops: any[] = items.map(item => ({
            type: 'put',
            key: item.seq,
            value: JSON.stringify(item.doc),
        }))
        await this.db.batch(ops)
    }
    
    // Get documents from local db
    get(seqStart: number, seqEnd: number): Promise<IDBModDoc[]> {
        let decoder = new TextDecoder("utf-8")

        let docs: any[] = []

        return new Promise((resolve, reject) => {
            if (seqStart === seqEnd) resolve(docs)  // nothing to get

            let limits = { gt: seqStart, lte: seqEnd }
            this.db.createReadStream(limits)
                .on('data', function (data) {
                    try {
                        let json = decoder.decode(data.value)
                        let doc = JSON.parse(json)
                        docs.push(doc)
                    } catch (error) {
                        console.error('_LevelupDB get', error)
                    }
                })
                .on('error', function (err) {
                    console.error('_LevelupDB get', err)
                    reject(err)
                })
                .on('end', function () {
                    if (docs.length) {
                        log(`get [${seqStart}-${seqEnd}] docs=${docs.length}`)
                    }
                    resolve(docs)
                })
        })
    }

    async put(seq: number, doc: IDBModDoc) {
        dbg('put', fmt({seq, doc}))

        await this.db.put(seq, JSON.stringify(doc))
    }

    async deleteDB(): Promise<void> {
        await this.db.close()
        return new Promise((resolve, reject) => {
            let deleteRequest = indexedDB.deleteDatabase(IndexedDB.buildDbName(this.name))

            deleteRequest.onerror = event => { reject('Error deleting db') }

            deleteRequest.onsuccess = event => { resolve() }

            deleteRequest.onblocked = event => { reject('Delete db request blocked') }
        })
    }

    close() {
        this.db.close().catch()
    }

    /**
     * should be used in a try-finally block where finally calls iterator.end()
     * @param currentKey (inclusive)
     */
    getIterator<T extends number | string>(currentKey: T) {
        const iter = this.db.iterator({ gte: currentKey })
        // await iter.next() => { key: 'foo', value: 'bar' })
        // await iter.end()
        class Iterator implements IDBIterator {
            private iter: ReturnType<LevelUp<leveljs>['iterator']>
            constructor(iter: ReturnType<LevelUp<leveljs>['iterator']>) {
                this.iter = iter
            }
            async next() {
                return new Promise<{ key: number | string | undefined, value: IDBModDoc | undefined}>((resolve, reject) => {
                    this.iter.next((err: any, key: Uint8Array, value: Uint8Array) => {
                        if (err) reject(err)
                        if (key === undefined) {
                            resolve({ key, value: undefined })
                            return
                        }
                        const stringKey = new TextDecoder().decode(key)
                        const numberKey = Number(stringKey)
                        const finalKey = isNaN(numberKey) ? stringKey : numberKey
                        const stringValue = new TextDecoder().decode(value)
                        resolve({ key: finalKey, value: JSON.parse(stringValue) })
                    })
                })
            }
            async end() {
                return new Promise<{ key: null, value: null}>((resolve, reject) => {
                    this.iter.end((err: any) => {
                        if (err) {
                            // it's okay to encounter: Error: end() already called on iterator
                            if (err.message !== 'end() already called on iterator') {
                                log('### iterator.end', err)
                            }
                            // probably better to catch other errors here, than to throw them
                            // the worst that can happen is that the iterator is not closed
                            // otherwise, we could end up stopping upsyncs 
                            // reject(err)
                        }
                        resolve({ key: null, value: null })
                    })
                })
            }
        }
        return new Iterator(iter)
    }

    async count(): Promise<number> {
        const idb = await openDB(IndexedDB.buildDbName(this.name), 1)
        const countRequest = await idb.count(IndexedDB.buildStoreName(this.name))
        idb.close()
        return countRequest
    }
}
