import React, { Component } from 'react'
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react'
import { confirmAlert } from '../../utils/ConfirmAlert'
import { t } from 'ttag'

import { ProjectStage } from '../../../models3/ProjectModels'
import './EditableProjectStage.css'
import TextInput from '../../utils/TextInput'
import { Root } from '../../../models3/Root'
import { EditableTaskList } from './Task'
import Stage from './Stage'

interface IEditableProjectStage {
    stage: ProjectStage,
    index: number,
    showStageDetails: boolean,
    deleteStage?: (_id: string) => void,
    rt: Root,
}

export class EditableProjectStage extends Component<IEditableProjectStage> {
    @observable editingName = false

    constructor(props: IEditableProjectStage) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { stage, index, rt, showStageDetails, deleteStage } = this.props
        let { editingName, validate } = this
        let { iAmAdmin, useMobileLayout } = rt

        let editableStageName = stage.name
        const startsWithNumber = new RegExp(/^(\d+\.?\d*\s*)*/)
        let parts = stage.name.split(startsWithNumber)
        if (parts.length > 0) {
            editableStageName = parts[parts.length - 1]
        }

        return (
            <div>
                <div className="stage-header">
                    {editingName ? (
                        <TextInput
                            initialValue={editableStageName}
                            message={t`Type Enter to change name or Esc to cancel.`}
                            _onEscape={() => {
                                this.editingName = false
                            }}
                            _onEnter={this.renameStage}
                            validate={validate}
                        />
                    ) : stage.displayedName}
                    {iAmAdmin && !useMobileLayout && (
                        <div className="stage-menu">
                            <span className="portion-button"
                                    data-toggle="tooltip"
                                    data-id={'edit-' + stage.name}
                                    title={t`Rename stage.`}
                                    onClick={e => {
                                        e.preventDefault()
                                        this.editingName = true
                                    }}
                            >
                                <i className="fas fa-fw fa-pencil-alt"></i>
                            </span>
                            {deleteStage && 
                                <span className="portion-button"
                                    data-toggle="tooltip"
                                    data-id={'delete-' + stage.name}
                                    title={t`Delete stage.`}
                                    onClick={this.onDelete}>
                                        <i className="fas fa-fw fa-trash-alt"></i>
                                </span>
                            }
                        </div>
                    )}
                </div>
                <div className="plan-editor-task-list">
                    <EditableTaskList
                        stage={stage}
                        rt={rt}
                    />
                </div>
            </div>
        )
    }

    validate = (value: string) => {
        value = value.trim()
        let { rt, stage } = this.props
        let plan = rt.project.plans[0]
        if (!plan) {
            return t`No project plan exists`
        }
        let otherStages = plan.stages.filter(s => s._id !== stage._id)
        if (otherStages.find(stage => stage.name === value)) {
            return t`Duplicate name`
        }
        if (value === '') {
            return t`Empty name`
        }
        return ''
    }

    renameStage = (newName: string) => {
        let { stage, rt } = this.props
        let plan = rt.project.plans[0]
        if (!plan) {
            return
        }
        if (!this.validate(newName)) {
            stage.setName(plan, newName)
        }
        this.editingName = false
    }

    onDelete = (e: any) => {
        let { stage } = this.props
        e.preventDefault()
        this.confirmDeletion(() => {
            this.props.deleteStage!(stage._id)
        })
    }

    confirmDeletion = (doDeletion: () => void) => {
        confirmAlert({
            title: t`Delete stage?`,
            message: t`Are you sure you want to delete stage "${this.props.stage.name}"? All tasks belonging to this stage will be removed as well.`,
            confirmLabel: t`Delete stage`,
            cancelLabel: t`Keep stage`,
            onConfirm: doDeletion,
            onCancel: () => {},
        })
    }
}

export default observer(EditableProjectStage)