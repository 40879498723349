import React, { FunctionComponent } from "react";
import { SliderItem, GetHandleProps } from "react-compound-slider";

interface IHandle {
    handle: SliderItem,
    getHandleProps: GetHandleProps,
    className?: string,
    onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

export const TimeCursorHandle: FunctionComponent<IHandle> = ({ handle, getHandleProps, className, onMouseUp }) => {
    if (!className) className = ''
    return (
        <div
            className={`${className} clickable`}
            style={{
                left: `${handle.percent}%`,
            }}
            onMouseUp={onMouseUp}
            {...getHandleProps(handle.id)}
        />
    )
}

interface ITrack {
    source: SliderItem,
    target: SliderItem,
    className?: string,
    onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onMouseMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

export const Track: FunctionComponent<ITrack> = ({ source, target, className, onMouseDown, onMouseMove, onMouseUp }) => {
    if (!className) className = ''
    return (
        <div
            className={`${className} clickable`}
            style={{
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`,
            }}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
        />
    )
}