import React, { Component } from "react";
import { observer } from "mobx-react";
import { t } from 'ttag'

import BookNameRow from "./BookNameRow";
import { Root } from "../../../models3/Root";
import { getBookNames } from "../../../scrRefs/bookNames";
import { languagesForBookNames } from "../../utils/Languages";
import { LocaleSelector } from "../../app/LocaleSelector";
import { observable, makeObservable } from "mobx";

interface IProjectReferences {
    rt: Root,
}

class ProjectReferences extends Component<IProjectReferences> {
    saveProjectBookName = (newBookName: string, index: number) => {
        let bbbccc = `${index + 1}`.padStart(3, '0')
        this.props.rt.project.setBookName(bbbccc, newBookName)
    }

    prepopulateBookNames = (language: string) => {
        let { project } = this.props.rt
        let { bookNames } = project
        let names = getBookNames(language)
        let existingBookEntries = Object.keys(bookNames)
        existingBookEntries.forEach((entry, i) => {
            project.setBookName(entry, names[i])
        })
    }

    render() {
        let { saveProjectBookName, prepopulateBookNames } = this
        let { rt } = this.props
        let { uiLanguage } = rt
        let { iAmAdmin } = rt
        let { bookNames } = rt.project

        let entries = Object.entries(bookNames)

        let uiNames = getBookNames(uiLanguage)

        let projectBookNames = entries.map(entry => entry[1])

        if (uiNames.length !== projectBookNames.length) {
            return null
        }

        return (
            <div>
                <h3>{t`Book Names`}</h3>
                {iAmAdmin && <PrepopulateBookNamesControl
                    key={uiLanguage}
                    initialValue={uiLanguage}
                    populateBookNames={prepopulateBookNames}
                />}
                <table>
                    <thead className='project-references-table-header'>
                        <tr>
                            <th className='project-references-table-item'>{t`Name`}</th>
                            <th className='project-references-table-item'>{t`Name in Project`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {uiNames.map((name, i) => (
                            <BookNameRow
                                rt={rt}
                                key={name}
                                uiBookName={name}
                                projectBookName={projectBookNames[i]}
                                saveProjectBookName={newBookName => saveProjectBookName(newBookName, i)}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

interface IPrepopulateBookNamesControl {
    initialValue: string,
    populateBookNames: (language: string) => void,
}

@observer
class PrepopulateBookNamesControl extends Component<IPrepopulateBookNamesControl> {
    @observable language = ''

    constructor(props: IPrepopulateBookNamesControl) {
        super(props)
        makeObservable(this);
        this.language = this.props.initialValue
    }

    chooseOption = (value: string) => {
        this.language = value
    }

    render() {
        let { populateBookNames } = this.props
        let { language, chooseOption } = this
        return (
            <label className='project-references-populate-book-names-control'>
                <span>{t`Set book names to...`}</span>
                <span className='font-weight-normal'>
                    <LocaleSelector
                        options={languagesForBookNames}
                        currentValue={language}
                        chooseOption={chooseOption}
                    />
                </span>
                <button className='btn btn-default project-references-fill-button' onClick={e => populateBookNames(language)}>{t`Fill in book names`}</button>
            </label>
        )
    }
}

export default observer(ProjectReferences)