import React, {Component} from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import { t } from 'ttag'

import './TaskColumn.css'
import './PassageCard.css'
import { Root } from '../../models3/Root';
import { PassageData } from './DragAndDropData';
import DifficultyView from './DifficultyView';
import { DragHandle, EditDifficultyButton } from '../utils/Buttons';
import { RefRange } from '../../scrRefs/RefRange';
import { ReferencesIcon, EditDifficultyIcon, MembersIcon } from '../utils/Icons';
import { PassageNotificationList } from '../notifications/Notifications';
import { MemberSelector } from '../utils/MemberDisplay';
import { Passage } from '../../models3/ProjectModels';
import { Link } from 'react-router-dom';

interface IPassageCard {
    rt: Root,
    passageData: PassageData,
    index: number,
    updateDifficulty: (difficulty: number) => void,
    selectPassage: (passage: Passage) => void,
}

class PassageCard extends Component<IPassageCard> {
    @observable editingDifficulty = false
    @observable oneSecondPassed = false
    @observable mouseOver = false
    private timer: NodeJS.Timeout | null = null

    editDifficulty = () => {
        this.editingDifficulty = true
    }

    stopEditingDifficulty = () => {
        this.editingDifficulty = false
        if (!this.mouseOver) {
            this.cancelTimer()
        }
    }

    setMouseOver = (value: boolean) => {
        this.mouseOver = value
        let { editingDifficulty } = this
        if (!editingDifficulty) {
            if (this.mouseOver) {
                this.setTimer()
            } else {
                this.cancelTimer()
            }
        }
    }

    setTimer = () => {
        if (!this.timer) {
            this.timer = setTimeout(() => this.oneSecondPassed = true, 1000)
        }
    }

    cancelTimer = () => {
        this.oneSecondPassed = false
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
        }
    }

    constructor(props: IPassageCard) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { editingDifficulty, oneSecondPassed, setMouseOver, editDifficulty, stopEditingDifficulty } = this
        let { rt, index, passageData, updateDifficulty, selectPassage } = this.props
        let { iAmAdmin, iAmInterpreter, useMobileLayout, project } = rt
        let { passage, portion, showLongName } = passageData
        let { difficulty } = passage

        let displayableReferences = rt.displayableReferences(passage.references)

        let showReferences = oneSecondPassed && displayableReferences.length > 0
        let showEditDifficultyButton = oneSecondPassed && !editingDifficulty
        let showDifficultyView = oneSecondPassed && editingDifficulty
        let showAssignee = oneSecondPassed || rt.project.members.find(m => m.email === passage.assignee.trim())

        return (
            <Draggable draggableId={passage._id} index={index} isDragDisabled={!iAmInterpreter}>
                {provided => (
                    <div
                        className='task-container'
                        ref={provided.innerRef}
                        data-id={"task-" + passage.name}
                        onMouseEnter={() => setMouseOver(true)}
                        onMouseLeave={() => setMouseOver(false)}
                        {...provided.draggableProps}
                    >
                        <div className='task-title'>
                            {iAmInterpreter && (
                                <div {...provided.dragHandleProps}>
                                    <DragHandle className='passage-card-drag-handle' tooltip=''/>
                                </div>
                            )}
                            <Link to="/">
                                <span onClick={() => selectPassage(passage)}>
                                    {showLongName ? `${portion.name}/${passage.name}` : passage.name}
                                </span>
                            </Link>
                        </div>
                        <div className={`passage-assignee ${showAssignee ? 'visible' : ''}`}>
                            {showAssignee && (
                                <>
                                    <MembersIcon className='passage-assignee-icon' tooltip={t`Passage assignee`} />
                                    <MemberSelector project={project} passage={passage} allowChange={iAmInterpreter} />
                                </>
                            )}
                        </div>
                        <div className={`task-references ${showReferences ? 'visible' : ''}`}>
                            {showReferences && (
                                <>
                                    <ReferencesIcon className='task-references-icon' tooltip={t`References`} />
                                    {displayableReferences}
                                </>
                            )}
                        </div>
                        {!useMobileLayout && (
                            <div className={`edit-difficulty-button-area ${showEditDifficultyButton ? 'visible' : ''}`}>
                                {showEditDifficultyButton && (
                                    iAmAdmin ? (
                                        <EditDifficultyButton
                                            enabled={true}
                                            onClick={editDifficulty}
                                            className='edit-difficulty-button'
                                            tooltip={t`Current difficulty: ${difficulty}.\nClick to change.`}
                                        />
                                    ) : (
                                        <EditDifficultyIcon className='edit-difficulty-button' tooltip={t`Current difficulty: ${difficulty}.\n`}/>
                                    ))
                                }
                            </div>
                        )}
                        {showDifficultyView && (
                            <DifficultyView
                                stopEditing={stopEditingDifficulty}
                                difficulty={difficulty}
                                setDifficulty={updateDifficulty}
                            />
                        )}
                        <div className="task-notifications">
                            <PassageNotificationList rt={rt} passage={passage} />
                        </div>
                    </div>
                )}
            </Draggable>
        )
    }
}

export default observer(PassageCard)