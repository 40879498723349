import React, { FC, useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'

import { Root } from '../../models3/Root'
import './EnhancedResources.css'
import { EnhancedResources, ERDiv, ERSpan } from '../../resources/EnhancedResources'
import { displayError } from '../utils/Errors'
import Modal from 'react-bootstrap/lib/Modal'
import ERTermModal from './ERTermModal'
import { PassageHighlight, ProjectTerm } from '../../models3/ProjectModels'
import { RefRange } from '../../scrRefs/RefRange'
import { ReferenceInput } from '../utils/ReferenceInput'
import RBody from './ResourceViewer'
import { ADBVersion, ApiDotBible } from '../../models3/ApiDotBible'
import { fmt } from '../utils/Fmt'
import { displayableBookNames } from '../../scrRefs/bookNames'
import _ from 'underscore'
import { StarButton } from '../utils/Buttons'
import { useResumableRecording } from '../utils/useResumableRecording'

/*
    EnhancedResourcesViewer
        ERTermModal - show info for user selected term
            ERTermView - show info from Lexicon for term
 */


import _debug from "debug"; let log = _debug('sltt:EnhancedResourcesViewer')

class HighlightRange {
    constructor(public firstId: string, public lastId: string) {
    }
}


interface IEnhancedResourcesViewer {
    rt: Root,
}

function isSLTTReference(resource: string) {
    // The resources from api.bible are 19 characters long, e.g. 880ff5e2c6bd8944-01.
    // The SLTT resources are human readable short names, e.g. NRS89.
    return (resource.length !== 19)
}

const EnhancedResourcesViewer: FC<IEnhancedResourcesViewer> = observer(({ rt }) => {
    const ENHANCED_RESOURCE = 'enhancedResource'

    let initialResource = rt.getDefault(ENHANCED_RESOURCE) || 'NRS89'
    let [resource, _setResource] = useState(initialResource)
    let [highlightRange, setHighlightRange] = useState<HighlightRange | null>(null)

    let [refs, setRefs] = useState<RefRange[]>([])
    const [errored, setErrored] = useState(false)

    const { resumableRecording } = useResumableRecording()

    let setResource = (resource: string) => {
        rt.setDefault(ENHANCED_RESOURCE, resource)
        _setResource(resource)
    }

    const dbsRefs = rt.dbsRefs // to force rerender when dbsRefs changes

    let defaultReferenceId = ENHANCED_RESOURCE+ 'Reference'

    let refInput = rt

    let _displayableBookNames = displayableBookNames(rt.project)

    return (
        <div className="er-viewer"
            onKeyDown={(event) => {
                // We stop propagation of keydown events so that they do not affect the main window.
                // However if we are recording we must let them pass through to the resumable recorder.
                if (!resumableRecording) {
                    event.stopPropagation()
                }
            }}>
            <div className="er-viewer-toolbar">
                <div className="er-viewer-reference-editor">
                    <div className="er-reference-input">
                        <ReferenceInput {...{ refInput, refs, setRefs, defaultReferenceId, 
                            errored, setErrored, includeGotoReferenceButton: true } }/>
                    </div>
                    {isSLTTReference(resource) && (
                        <span>
                            <HighlightButton {...{ rt, highlightRange, resource, setHighlightRange, color: 1 }} />
                            <HighlightButton {...{ rt, highlightRange, resource, setHighlightRange, color: 2 }} />
                            <HighlightButton {...{ rt, highlightRange, resource, setHighlightRange, color: 3 }} />
                            <HighlightButton {...{ rt, highlightRange, resource, setHighlightRange, color: 0 }} />
                        </span>)}
                </div>
                <div className="er-viewer-resource-selector">
                    <ERResourceSelector {...{resource, setResource}} />
                </div>
            </div>
            <div className="er-viewer-body">
                {isSLTTReference(resource) ?
                    <ERBody {...{ rt, resource, refs, setHighlightRange}} /> :
                    <RBody {...{ 
                        resourceId: resource, 
                        refs, 
                        dbsRefs,
                        displayableBookNames: _displayableBookNames 
                    }} />
                }
            </div>
        </div>
    )
})


interface IERResourceSelector {
    resource: string, 
    setResource: (resource: string) => void
}

const ERResourceSelector: FC<IERResourceSelector> = ({ resource, setResource }) => {
    let [versions, setVersions] = useState<_.Dictionary<ADBVersion[]>>({})

    useEffect(() => {
        const fetchVersions = async () => {
            try {
                let _versions = _.groupBy(await ApiDotBible.getBibleVersions(), v => v.language.name)
                setVersions(_versions)
            } catch (error) {
                displayError(error)
            }
        }

        fetchVersions()
    }, [])

    let languageNames = Object.keys(versions).sort()

    const isAVTT = false

    let resources = [
        { value: 'ARA93', text: 'ARA93+ Almeida Revista e Atualizada (Enhanced Resource)', avttOnly: false },
        { value: 'NBS11', text: 'NBS11+ Nouvelle Bible Segond (Enhanced Resource)', avttOnly: false },
        { value: 'NLT', text: 'NLT', avttOnly: true},
        { value: 'NRS89', text: 'NRS89+ New Revised Standard (Enhanced Resource)', avttOnly: false},
        { value: 'PDV17', text: 'PDV17', avttOnly: true},
        { value: 'RVR60', text: 'RVR60+ Reina-Valera Revisada (Enhanced Resource)', avttOnly: false},
        { value: 'TLA', text: 'TLA', avttOnly: true},
        { value: 'BHS', text: 'BHS', avttOnly: true},
        { value: 'UBSGNT5', text: 'UBSGNT5', avttOnly: true },
    ].filter(r => (!r.avttOnly) || isAVTT)

    return (
        <div className='app-selector-icon'>
            <select
                    className='er-resources-select'
                    value={resource}
                    onChange={e => { setResource(e.target.value) }} >
                {resources.map(({ value, text }) => (<option value={value} key={value}>{text}</option>))}
                {languageNames.map(languageName => (
                    <optgroup label={languageName} key={languageName}>
                        {versions[languageName].map(version => (
                            <option value={version.id} key={version.id}>
                                {version.abbreviation} - {version.name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
        </div>
    )
}

function findSelectedSpanIds(dbsRefs: RefRange[], erDivs: ERDiv[]) {
    return erDivs
        .flatMap(div => div.spans)
        .filter(span => dbsRefs.some(ref => span.includedIn(ref)))
        .map(span => span.id ?? '???')   
}

interface IERBody {
    rt: Root,
    setHighlightRange: (highlightRange: HighlightRange | null) => void,
    resource: string,
    refs: RefRange[],
}

const ERBody: FC<IERBody> = ({ rt, resource, refs, setHighlightRange }) => {
    let [termId, setTermId] = useState('')
    let [erDivs, setErDivs] = useState<ERDiv[]>([])
    let latestResourceRef = useRef<string>('')
    let [selectedSpanIds, setSelectedSpanIds] = useState<string[]>([])

    useEffect(() => {
        const fetchERDivs = async () => {
            if (!refs.length) return

            let refsString = refs.map(r => r.startRef + r.endRef).toString()
            let resourceRef = resource + refsString
            latestResourceRef.current = resourceRef
            try {
                let _erDivs = await EnhancedResources.fetchRefs(resource, refs)

                // Make sure the last call to this function is the one that takes precendence
                // so we avoid a race condition.
                if (resourceRef === latestResourceRef.current) {
                    setSelectedSpanIds(findSelectedSpanIds(rt.dbsRefs, _erDivs))
                    setErDivs(_erDivs)
                }
            } catch (error) {
                log('###', error)
                if (resourceRef === latestResourceRef.current) {
                    setErDivs([])
                }
            }
        }

        fetchERDivs()
    }, 
    [resource, refs, rt.dbsRefs])
    
    // Whenever the selectedSpanIds change, scroll to the first selected span
    useEffect(() => {
        const id = selectedSpanIds[0] ?? '*dont-scroll*'
        log(`scrollIntoView ${id}`)
        const element = document.querySelector(`[data-marbleid="${id}"]`)
        if (element) element.scrollIntoView({ behavior: 'smooth' })
    }, [selectedSpanIds])

    return (
        <div>
            {termId && <ERTermModal {...{ rt, termId, setTermId }} />}
            <ERDivs {...{ rt, erDivs, setHighlightRange, setTermId, resource, selectedSpanIds }} />
        </div>
    )
}


interface IHighlightButton {
    rt: Root,
    highlightRange: HighlightRange | null,
    setHighlightRange: (highlightRange: HighlightRange | null) => void,
    color: number,
    resource: string,
}

const HighlightButton: FC<IHighlightButton> = 
        ({ rt, highlightRange, setHighlightRange, resource, color }) => {
    const setColor = (color: number) => {
        if (!highlightRange) return

        rt.passageVideo?.addHighlight(
                color, 
                highlightRange.firstId, 
                highlightRange.lastId, 
                resource)
            .then(() => setHighlightRange(null))
            .catch(displayError)
    }

    return (
        <button className={`er-highlight-button er-highlight-${color}${highlightRange ? '' : '-disabled'}`}
                onClick={() => { setColor(color) }} /> 
        )
}


interface IERDivs {
    rt: Root,
    erDivs: ERDiv[],
    resource: string,
    setHighlightRange: (highlightRange: HighlightRange | null) => void
    setTermId: (resource: string) => void,
    selectedSpanIds: string[],
}

const ERDivs: FC<IERDivs> = ({ rt, erDivs, setHighlightRange, setTermId, resource, selectedSpanIds }) => {
    let highlights = rt.passageVideo?.highlights ?? []

    let className = ''
    if (resource === 'BHS') {
        className = 'bhs-text'
    } else if (resource === 'UBSGNT5') {
        className = 'ubsgnt5-text'
    }

    return (
        <div className={`er er-divs ${className}`}
                dir={resource === 'BHS' ? 'rtl' : undefined}
                onMouseUp={(e: any) => erDivsMouseUpHandler(e, setHighlightRange)} >
            {erDivs.map((erDiv, key) => (
                <ERDivView {...{ rt, erDiv, setTermId, key, highlights, resource, previousErDiv: key > 0 ? erDivs[key-1] : null, selectedSpanIds }} />
            ))}
        </div>
    )
}

function getMarbleId(node: any) {
    while (node) {
        let id = node.getAttribute && node.getAttribute('data-marbleid')
        if (id) return { node, id }
        node = node.parentNode
    }

    return { node: undefined, id: undefined }
}

function erDivsMouseUpHandler(e: any, 
        setHighlightRange: (highlightRange: HighlightRange | null) => void) {

    const selectionObj = window.getSelection()
    if (!selectionObj) {
        setHighlightRange(null)
        return
    }

    // if start and end of selection are the same no text was selected.
    // Nothing to highlight, return
    if (selectionObj.anchorNode === selectionObj.focusNode &&
        selectionObj.anchorOffset === selectionObj.focusOffset) {
        log('onMouseUp empty-selection')
        setHighlightRange(null)
        return
    }

    let { node: firstNode, id: firstId } = getMarbleId(selectionObj.anchorNode)
    const { node: lastNode, id: lastId } = getMarbleId(selectionObj.focusNode)

    // If both notes are from the same paragraph and that paragraph is a header
    // or reference paragraph then the user has not selected any body text.
    // Ignore the selection.
    if (firstNode && (firstNode === lastNode)) {
        let classNames = firstNode.className.split(' ')
        if (classNames.includes('er-s') || classNames.includes('er-r')) {
            setHighlightRange(null)
            return
        }
    }

    if (firstId && lastId) {
        setHighlightRange(new HighlightRange(firstId, lastId))
    } else {
        setHighlightRange(null)
    }
}


interface IERDivView {
    rt: Root,
    erDiv: ERDiv,
    highlights: PassageHighlight[],
    setTermId: (id: string) => void,
    resource: string,
    previousErDiv: ERDiv | null,
    selectedSpanIds: string[],
}

const ERDivView: FC<IERDivView> = ({ erDiv, highlights, setTermId, resource, rt, previousErDiv, selectedSpanIds }) => {
    // Divide spans up into groups that all have the same value for highlighting
    let spanGroups: SpanGroup[] = []

    erDiv.spans.forEach(span => {
        let color = PassageHighlight.highlighted(highlights, span.id || '', resource)
        let lastGroup = spanGroups.slice(-1)[0]

        if (!lastGroup || lastGroup.color !== color) {
            let newGroup = new SpanGroup()
            newGroup.color = color
            newGroup.spans.push(span)
            spanGroups.push(newGroup)
        } else {
            lastGroup.spans.push(span)
        }
    })

    let heading = ''
    if (previousErDiv && erDiv.bbbccc !== previousErDiv.bbbccc) {
        let BBB = erDiv.bbbccc.slice(0, 3)
        let CCC = erDiv.bbbccc.slice(3, 6)
        let rr = new RefRange(BBB, BBB)
        heading = `${rt.displayableReferences([rr])} ${parseInt(CCC)}`
    }

    return (
        <div data-marbleid={erDiv.id} className={`er-${erDiv.style}`}>
            {heading && <div className="er-new-chapter">{heading}</div>}
            {spanGroups.map((spanGroup, key) => (
                <ERSpanGroup {...{ rt, spanGroup, key, setTermId, selectedSpanIds }} />))}
        </div>
    )
}

class SpanGroup {
    color = 0
    spans: ERSpan[] = []
}


// A list of spans with the same background color

interface IERSpanGroup {
    rt: Root,
    spanGroup: SpanGroup,
    setTermId: (id: string) => void,
    selectedSpanIds: string[],
}

const ERSpanGroup: FC<IERSpanGroup> = ({ spanGroup, setTermId, rt, selectedSpanIds }) => {
    return (
        <span className={`er-highlight-${spanGroup.color}`}>
            {spanGroup.spans.map((span, key) => (
                <ERSpanView {...{ rt, span, key, setTermId, selectedSpanIds }} />
            ))}
        </span>
    )
}


// A span representing a word or very short phrase in the enhanced resource.
// If it has lexical_links you can click on it to see info for that term.
// If it has image_links we display an image icon to click to see the image.

interface IERSpanView {
    rt: Root,
    span: ERSpan,
    setTermId: (id: string) => void,
    selectedSpanIds: string[],
}

const ERSpanView: FC<IERSpanView> = ({ span, setTermId, rt, selectedSpanIds }) => {
    let isSelected = selectedSpanIds.includes(span.id ?? '*no_id*')

    if (span.type === 'verse')
        return (<span data-marbleid={span.id || ''} 
                    className={`er-v ${isSelected ? 'er-selected' : ''}`}>
                {span.verse}
            </span>)

    let isImage = span.attributes && span.attributes.image_links

    let postText = ''
    let text = span.text || ''
    if (text.endsWith(' ')) {
        text = text.slice(0, -1)
        postText = ' '
    }

    return (
        <span data-marbleid={span.id || ''} className={isSelected ? 'er-selected' : ''}>
            {isImage && <ERImageView span={span} />}
            <ERTextView
                span={span}
                setTermId={setTermId}
                getProjectTerm={rt.project.getProjectTerm.bind(rt.project)}
            />
            <ERKeyTermLinks
                span={span}
                setTermId={setTermId}
                getProjectTerm={rt.project.getProjectTerm.bind(rt.project)}
            />
            {postText}
        </span>
    )
}

type ERKeyTermLinksProps = {
    span: ERSpan,
    setTermId: (id: string) => void,
    getProjectTerm: (id: string) => ProjectTerm | undefined,
}

const ERKeyTermLinks = ({ span, setTermId, getProjectTerm }: ERKeyTermLinksProps) => {
    function doesLinkToKeyTerm(termId: string) {
        return getProjectTerm(termId)?.isKeyTerm ?? false
    }

    let termIds = span.getLexicalLinks()

    return (
        <>
            { termIds.filter(doesLinkToKeyTerm).map((termId) => (
                // Is it okay to use termId as a key? AFAICT, each item in this list links
                // to a different term.
                <StarButton
                    isHollow={false}
                    key={termId}
                    enabled={true}
                    onClick={() => setTermId(termId)}
                    className='star-icon'
                    tooltip=''
                />
            ))}
        </>
    )
}

type ERTextViewProps = {
    span: ERSpan,
    setTermId: (id: string) => void,
    getProjectTerm: (id: string) => ProjectTerm | undefined,
}

const ERTextView = ({ span, setTermId, getProjectTerm }: ERTextViewProps) => {
    let text = span.text || ''
    if (text.endsWith(' ')) {
        text = text.slice(0, -1)
    }

    let termIds = span.getLexicalLinks()

    return (
        <>
            { termIds.map((termId, index) => {
                let _text = text
                if (index !== 0) {
                    _text = '*'
                }

                // Is it okay to use termId as a key? AFAICT, this component only displays
                // a single link to a single term or several links to different terms, but
                // not several links to the same term.
                if (termId.trim() === '') {
                    return (
                        <ERPlainText key={termId} text={_text} />
                    )
                }

                return (
                    <ERLink
                        key={termId}
                        text={_text}
                        termId={termId}
                        spanType={span.type}
                        getProjectTerm={getProjectTerm}
                        setTermId={setTermId}
                    />
                )
            })}
        </>
    )
}

type ERPlainTextProps = {
    text: string,
}

const ERPlainText = ({ text }: ERPlainTextProps) => {
    return (
        <span className={`er-text`}>
            { text }
        </span>
    )
}

type ERLinkProps = {
    text: string,
    termId: string,
    spanType: string,
    getProjectTerm: (lexicalLink: string) => ProjectTerm | undefined,
    setTermId: (id: string) => void,
}

const ERLink = ({ text, termId, spanType, getProjectTerm, setTermId }: ERLinkProps) => {
    let term = getProjectTerm(termId)

    let className = `er-${spanType}`
    if (term?.hasVideo()) {
        className += ' er-sign-video-present'
    }

    return (
        <span className={className}
            onClick={() => {
                // termId = "SDBG:γένεσις:000001|SDBG:γένεσις:000002"
                log('ERSpanView click', JSON.stringify(termId.split(':')))
                setTermId(termId)
            }}>
            { text }
        </span>
    )
}

interface IERImageView {
    span: ERSpan,
}

const ERImageView: FC<IERImageView> = ({ span }) => {
    let [imageModalOpen, setImageModalOpen] = useState(false)

    let imagePaths = span.attributes.image_links.split('|')

    return (
        <span>
            {imageModalOpen && <ERImageModal {...{imagePaths, setImageModalOpen}} />}
            <span className="er-image fa-fw fa-image" onClick={() => setImageModalOpen(true)} />
        </span>
    )
}

// Modal dialog to display an image associated with a word.

interface IERImageModal {
    imagePaths: string[],
    setImageModalOpen: (open: boolean) => void,
}

const ERImageModal: FC<IERImageModal> = ({ imagePaths, setImageModalOpen }) => {
    let basePath = 'https://s3.amazonaws.com/sltt-resources/SLMARBLE/images/images_resolutions/Medium'

    return (
        <Modal style={{ top: '1%' }}
            bsSize="large"
            show={true}
            onHide={() => setImageModalOpen(false)} >
            <Modal.Header closeButton> </Modal.Header>
            <Modal.Body>
                <div className="er-images-div">
                    {imagePaths.map((ip, key) => (
                        <div key={key}>
                            <img className="er-image-display"
                                src={`${basePath}/${ip}`} />
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default EnhancedResourcesViewer
