import _debug from "debug"; const log = _debug('sltt:Auth0Utils')

const slttWebAppClientId = 'RobMhqcqwGmNIkM90gPTx0r8JCotBk0K' // [|dev.|tst.]sltt-bible.net
const slttAppClientId = 'eTewsjcscudtGHteG3u86YEDwHUTRd6Z' // electron app (gitub.com/ubsicap/sltt-app)
export const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID || slttWebAppClientId
export const isPrgWebAppClient = auth0ClientId === slttWebAppClientId
export const isSlttAppClient = auth0ClientId === slttAppClientId

enum AppClient {
    slttPwa = 'sltt-pwa',
    slttApp = 'sltt-app',
}

export const getCurrentAppClient = () => {
    if (isPrgWebAppClient) return AppClient.slttPwa
    if (isSlttAppClient) return AppClient.slttApp
    throw new Error(`Unknown auth0 client id: ${auth0ClientId}`)
}

const buildRedirectUri = (): string => {
    if (isSlttAppClient) {
        if (window.location.origin.startsWith('http://localhost:')) {
            return window.location.href // dev mode
        } else {
            // let packaged electron handle reloading the page
            return 'http://localhost/callback'
        }
    }
    return window.location.origin
}
export const getRedirectUri = () => {
    // const url = new URL('/callback', baseUrl).toString()
    const url = buildRedirectUri()
    log('getRedirectUri', url)
    return url
}
log('client info', { auth0ClientId, getCurrentAppClient: getCurrentAppClient(), isPrgWebAppClient, isSlttAppClient })
log('getRedirectUri', getRedirectUri())
