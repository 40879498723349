import { useEffect, useState, useCallback } from "react"

// NOTE: Adapted useToggle code from https://usehooks.com/useToggle/
// Parameter is the boolean, with default "false" value
export const useToggle = <T>(initialState: T): [T, any] => {
    // Initialize the state
  const [state, setState] = useState<T>(initialState)
  // Define and memorize toggler function in case we pass down the comopnent,
  // by default, set value to its opposite
  // if a value is equal to the state, then set state to its opposite 
  // otherwise, set the passed value.
  // This behavior allows a string value to establish a new state
  // but passing the same string value twice will act as a toggle
  const toggle = useCallback(
    (value): void => {
      return setState((state) => {
        if (typeof value === 'undefined' && typeof state === 'boolean') {
          return !state
        }
        const newState = value === state ? !state : value
        // console.log({ value, state, opposite: !state, newState })
        return newState
      })
    },
    []
  );

  return [state, toggle]
}

export function useOnClickOutside(ref: any, handler: () => void) {
  useEffect(
    () => {
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler()
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export function useHoverDelay(mouseOver: boolean, delay: number = 1000) {
    const [mouseOverTimer, setMouseOverTimer] = useState<NodeJS.Timeout | null>(null)
    const [hideDelayTimer, setHideDelayTimer] = useState<NodeJS.Timeout | null>(null)
    const [doAction, setDoAction] = useState(false)

    useEffect(() => {
        if (mouseOver) {
            if (!doAction && !mouseOverTimer) {
                let timer = setTimeout(() => setDoAction(true), delay)
                setMouseOverTimer(timer)
            }

            if (hideDelayTimer) {
                clearTimeout(hideDelayTimer)
                setHideDelayTimer(null)
            }
        } else {
            if (mouseOverTimer) {
                clearTimeout(mouseOverTimer)
                setMouseOverTimer(null)
            }

            if (doAction && !hideDelayTimer) {
                let timer = setTimeout(() => setDoAction(false), delay/2)
                setHideDelayTimer(timer)
            }
        }
    }, [mouseOver])

    useEffect(() => {
        return () => {
            mouseOverTimer && clearTimeout(mouseOverTimer)
            hideDelayTimer && clearTimeout(hideDelayTimer)
            setMouseOverTimer(null)
            setHideDelayTimer(null)
        }
    }, [])

  return doAction
}