import React, { Component } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { t } from 'ttag'

import PassageCard from './PassageCard'
import './TaskColumn.css'
import { ColumnData } from './DragAndDropData';
import { Root } from '../../models3/Root';
import DifficultyView from './DifficultyView';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { EditDifficultyButton } from '../utils/Buttons';
import { Passage } from '../../models3/ProjectModels'
import { DisplayStatusPieChart } from './TaskSelectorDropdown'

interface ITaskColumn extends ColumnData {
    rt: Root,
    updateDifficulty: (difficulty: number) => void,
    selectPassage: (passage: Passage) => void,
}

class TaskColumn extends Component<ITaskColumn> {
    @observable editingDifficulty = false

    constructor(props: ITaskColumn) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { task, id, items, rt, updateDifficulty, selectPassage } = this.props
        let { useMobileLayout } = rt
        let { difficulty } = task
        let { editingDifficulty } = this
        
        let name = task.displayedName
        if (task.name === 'Not started') {
            name = t`Not started`
        } else if (task.name === 'Finished') {
            name = t`Finished`
        }
        return (
            <div className="column-container">
                <div className='task-column-header'>
                    <h4>
                        <DisplayStatusPieChart status={task.id} plan={rt.project.plans[0]} />&nbsp;
                        <b title={task.details}>{name}</b>
                    </h4>
                    {!useMobileLayout && task.name !== 'Not started' && task.name !== 'Finished' && (
                        <>
                            <div className='edit-difficulty-button-area'>
                                {!editingDifficulty && (rt.iAmAdmin ? (
                                    <EditDifficultyButton
                                        enabled={true}
                                        onClick={() => this.editingDifficulty = true}
                                        className='edit-difficulty-button clickable'
                                        tooltip={t`Current difficulty: ${difficulty}.\nClick to change.`}
                                    />
                                ) : (
                                    <EditDifficultyButton
                                        enabled={true}
                                        onClick={() => {}}
                                        className='edit-difficulty-button'
                                        tooltip={t`Current difficulty: ${difficulty}.\n`}
                                    />
                                ))}
                            </div>
                            {editingDifficulty && (
                                <DifficultyView
                                    stopEditing={() => {
                                        this.editingDifficulty = false
                                    }}
                                    difficulty={difficulty}
                                    setDifficulty={updateDifficulty}
                                />
                            )}
                        </>
                    )}
                </div>
                <Droppable droppableId={id}>
                    {provided => (
                        <div
                            className="task-list"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {items.map((item, index) => (
                                <PassageCard
                                    key={item.passage._id}
                                    passageData={item}
                                    index={index}
                                    rt={rt}
                                    updateDifficulty={item.passage.setDifficulty.bind(item.passage)}
                                    selectPassage={selectPassage}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        )
    }
}

export default observer(TaskColumn)