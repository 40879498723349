import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { t } from 'ttag'
import debug from 'debug'
import { Theme } from '../utils/LocalStorage'
import { observer } from 'mobx-react'

const log = debug('sltt:ToggleThemeButton')

interface IToggleThemeButton {}

export const ToggleThemeButton: FC<IToggleThemeButton> = observer(({}) => {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        console.log('handleKeyDown', { key: e.key })
        if (!['Enter', ' '].includes(e.key)) return
        Theme.toggleTheme()
        e?.stopPropagation()
        e?.preventDefault()
    }

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        Theme.toggleTheme()
        e.currentTarget.blur() // disable focus after click
        e?.stopPropagation()
        e?.preventDefault()
    }

    const tooltip = t`Switch theme to ` + (Theme.theme === 'light' ? t`Dark mode` : t`Light mode`)
    const actionIcon = Theme.theme === 'light' ? 'fa-moon' : 'fa-sun'
    return <Link to="#" title={tooltip} onClick={handleClick} onKeyDown={handleKeyDown}>
        <i className={`${actionIcon} fa-solid fa-2x toggle-theme-button`} />
    </Link>
})
