// A range consisting of a starting and ending bbbcccvvv
// Allow iterating over all verses in range.
// The range may span chapter boundaries but not book boundaries

import * as P from 'parsimmon'

import { dbsBookIdParser, displayableBookNames, languageBookNameParser, projectBookNameParser, ptxBookIds } from './bookNames'
import { Project } from '../models3/ProjectModels'
import { chapterCount } from '../scrRefs/bookNames'
import { versificationConverter } from '../resources/Versifications'
import { ReferencesIcon } from '../components/utils/Icons'
import { fmt } from '../components/utils/Fmt'
import _debug from "debug"; let log = _debug('sltt:RefRange') 

function pad(value: number) {
    return value.toString().padStart(3, '0')
}

export class RefRange {
    constructor(
        public startRef: string, // bbbcccvvv or bbbccc or bbb
        public endRef: string,    // ditto
    ) {
    }

    copy() {
        return new RefRange(this.startRef, this.endRef)
    }

    isBBBOnly() {
        return this.startRef.length === 3 // just bbb
    }

    // Return RefRange containing all the chapters in the current book.
    fullBook() {
        let bbb = this.startRef.slice(0, 3)
        let book = parseInt(bbb)
        return new RefRange(`${bbb}001`, `${bbb}${nnn(chapterCount[book-1])}`)
    }

    // Iterate over all the bbbcccvvv strings in a RefRange.
    // If we don't have any information about chapter length just assume
    // there are a lot (180) verses in the chapter.
    // This is ok because our primary use case for this function is to try to determine
    // whether something is present in a range of verses. Looking at extra verses
    // that don't exist will not affect this. (Looking at too few verses would break
    // that however)

    * iterator(versesPerChapter?: Map<string, number> /* bbbccc => lastVerse */) {
        let { startRef, endRef } = this

        if (startRef.slice(0,3) !== endRef.slice(0,3)) throw Error('RefRange cannot iterate over book boundaries')

        let bbbccc = startRef.slice(0, 6)
        while (true) {
            let v = startRef.startsWith(bbbccc) && startRef.length === 9 ? 
                         parseInt(startRef.slice(6, 9), 10) : 1

            let maxVInChapter = versesPerChapter ? (versesPerChapter.get(bbbccc) || 180) : 180

            let maxV = endRef.startsWith(bbbccc) && endRef.length === 9 ? 
                            parseInt(endRef.slice(6, 9), 10) : maxVInChapter

            for (; v <= maxV; ++v) {
                yield bbbccc + v.toString().padStart(3, '0')
            }

            if (bbbccc === endRef.slice(0, 6)) break
            bbbccc = this.nextChapter(bbbccc)
        }
    }

    // Iterate over all bbbccc in range
    * chapterIterator() {
        let { startRef, endRef } = this

        if (startRef.slice(0, 3) !== endRef.slice(0, 3)) throw Error('RefRange cannot iterate over book boundaries')

        let bbbccc = startRef.slice(0, 6)
        while (true) {
            yield bbbccc

            if (bbbccc === endRef.slice(0, 6)) break
            bbbccc = this.nextChapter(bbbccc)
        }
    }

    nextChapter(bbbccc: string) {
        let ccc = parseInt(bbbccc.slice(3, 6), 10)
        if (ccc > 150) throw Error('nextChapter limit exceeded')
        return bbbccc.slice(0, 3) + (ccc + 1).toString().padStart(3, '0')
    }

    // ! This is a remarkably ineffecient way to compute this but maybe we
    // don't use this enough to care

    static nextVerse(bbbcccvvv: string, versification: string) {
        try {
            let allVerses = versificationConverter.getValidVerses(versification)
            let verseIndex = allVerses.findIndex(verse => verse === bbbcccvvv)
            if (verseIndex < 0 || verseIndex === allVerses.length - 1) {
                return bbbcccvvv
            }

            return allVerses[verseIndex + 1]
        } catch (error) {
            return bbbcccvvv
        }
    }

    static previousVerse(bbbcccvvv: string, versification: string) {
        try {
            if (bbbcccvvv.length < 9) return bbbcccvvv

            let vvv = bbbcccvvv.slice(6, 9)
            if (vvv !== '001') {
                return bbbcccvvv.slice(0, 6) + pad(parseInt(vvv) - 1)
            }
            
            let allVerses = versificationConverter.getValidVerses(versification)
            let verseIndex = allVerses.findIndex(verse => verse === bbbcccvvv)
            if (verseIndex <= 0) {
                return bbbcccvvv
            }

            return allVerses[verseIndex - 1]
        } catch (error) {
            return bbbcccvvv
        }
    }

    /** References can use the book names defined by the current project,
     * the UI language, or English.
     * When we presist them in the DB we always use English.
     * @throws Throws if parse fails
     */
    static parseReferences(refs: string, uiLanguageCode: string, projectBookNames: string[]) {
        if (!refs.trim()) return []

        return multiLanguageBcvListParser(uiLanguageCode, projectBookNames)
            .map(bcvs => bcvs.map(bcvToRefRange))
            .tryParse(refs)
    }

    // Parse displayable references into RefRanges.
    // Throw an exception if parse fails.
    // refs: GN/0012-3;4.5     // Gen 1.2-3; 4.5
    static parseDbsRefRanges(refs: string): RefRange[] {
        if (!refs.trim()) return []

        return dbsBcvListParser
            .map(bcvs => bcvs.map(bcvToRefRange))
            .tryParse(refs)
    }

    static parseParatextReferences(refs: string): RefRange[] {
        if (!refs.trim()) return []

        return multiLanguageBcvListParser('en', ptxBookIds)
            .map(bcvs => bcvs.map(bcvToRefRange))
            .tryParse(refs)
    }

    // Convert array of bbbcccvvv refs to an array of RefRange collapsing
    // consecutive verses into a single RefRange
    static refsToRefRanges(refs: string[]) {
        let rrs: RefRange[] = []

        let isNextVerse = function (bcv1: string, bcv2: string) {
            return bcv1.slice(0,6) === bcv2.slice(0,6) &&
                parseInt(bcv1.slice(6,9))+1 === parseInt(bcv2.slice(6,9))
        }

        refs.forEach(ref => {
            if (rrs.length === 0 || !isNextVerse(rrs.slice(-1)[0].endRef, ref)) {
                rrs.push(new RefRange(ref, ref))
            } else {
                rrs.slice(-1)[0].endRef = ref
            }
        })

        return rrs
    }

    // Convert array of RefRange to an array of human readable strings
    // where each array element corresponds to an input RefRange
    static refRangesToDisplayParts(refs: Array<RefRange>, project?: Project) {
        let parts: Array<string> = refs.map((rr, i) => {
            let book = rr.startRef.slice(0, 3)
            let prevBook = i > 0 ? refs[i - 1].startRef.slice(0, 3) : ''

            let bookName = ''
            if (book !== prevBook) {
                bookName = displayableBookNames(project)[parseInt(book, 10) - 1] + ' '
            }

            if (rr.isBBBOnly()) { return bookName }

            let c1 = rr.startRef.slice(3, 6).replace(/^0+/, '')
            let v1 = rr.startRef.slice(6, 9).replace(/^0+/, '')
            let c2 = rr.endRef.slice(3, 6).replace(/^0+/, '')
            let v2 = rr.endRef.slice(6, 9).replace(/^0+/, '')

            let verse = (v: string) => v ? `.${v}` : ''
            if (c1 !== c2) return bookName + `${c1}${verse(v1)}-${c2}${verse(v2)}`
            if (v1 !== v2) return bookName + `${c1}.${v1}-${v2}`
            return bookName + `${c1}${verse(v1)}`
        })

        return parts
    }

    // Convert array of RefRange to human readable string
    static refRangesToDisplay(refs: Array<RefRange>, project?: Project) {
        let parts = this.refRangesToDisplayParts(refs, project)
        return parts.join('; ')
    }

    // Returns true iff this overlaps with anything in refRanges
    overlaps(refRanges: RefRange[]) {
        const min = (x: string, y: string) => x < y ? x : y
        const max = (x: string, y: string) => x < y ? y : x

        let convertStartToBBBCCCVVV = (ref: string) => ref.length > 6 ? ref : ref + '001'
        let convertEndToBBBCCCVVV = (ref: string) => ref.length > 6 ? ref : ref + '999'

        let x1 = convertStartToBBBCCCVVV(this.startRef)
        let x2 = convertEndToBBBCCCVVV(this.endRef)

        // https://stackoverflow.com/questions/3269434/whats-the-most-efficient-way-to-test-two-integer-ranges-for-overlap
        return refRanges.some(rr => max(x1, convertStartToBBBCCCVVV(rr.startRef)) <= min(x2, convertEndToBBBCCCVVV(rr.endRef)))
    }

    // getAllVerses(versification: string) {
    //     try {
    //         let versesPerChapter = Object.fromEntries(versificationConverter.getNumberOfVersesPerChapter(versification))
    //         let verses = Array.from(this.iterator(versesPerChapter))
    //         return verses
    //     } catch (error) {
    //         return []
    //     }
    // }

    static getAllVersesInRefRanges(references: RefRange[], versification: string) {
        let versesPerChapter = versificationConverter.getNumberOfVersesPerChapter(versification)
        let versesSet = new Set<string>()
        for (let ref of references) {
            for (let bbbcccvvv of ref.iterator(versesPerChapter)) {
                versesSet.add(bbbcccvvv)
            }
        }

        return Array.from(versesSet)
    }

    spread() {
        function _spread(bcv: string) {
            let book = parseInt(bcv.slice(0,3))
            let chapter = parseInt(bcv.slice(3,6) || '000')
            let verse = parseInt(bcv.slice(6,9) || '000')
            return {book, chapter, verse}
        }

        let { book: startBook, chapter: startChapter, verse: startVerse } = _spread(this.startRef)
        let { book: endBook, chapter: endChapter, verse: endVerse } = _spread(this.endRef)
        
        return {startBook, startChapter, startVerse, endBook, endChapter, endVerse}
    }
}

function nnn(value: number | number) { return value.toString().padStart(3, '0') }
const hyphenSep = P.regex(/ *- */)
const semiSep = P.regex(/ *; */)
const commaSep = P.regex(/ *, */)
const cvSep = P.regex(/ *[.:] */)
const bcSep = P.regex(/ +/)

// ----- dbs references parsers, e.g. GN/0013-4 = Gen 1.3-4

const dbsVerseParser = P.regexp(/\d+[a-z]?/).map(v => {
    v = v.replace(/[a-z]$/, '')
    return nnn(parseInt(v))
})

const dbsVersesParser = P.alt(
    P.seq(dbsVerseParser, hyphenSep, dbsVerseParser)
        .map(([verse1, unused, verse2]) => [verse1, verse2]),
    dbsVerseParser
        .map(verse => [verse, verse])
)

const dbsVersesListParser = dbsVersesParser.sepBy1(commaSep)

const dbsCvParser = P.seq(P.regex(/\d\d\d/), dbsVersesListParser)
    .map(([chapter, versess]) => 
        versess.map(verses => `${chapter}${verses[0]}-${chapter}${verses[1]}`)
    )

const dbsCvsListParser = dbsCvParser.sepBy1(semiSep)
    .map(cvs => cvs.reduce((x, y) => x.concat(y)))

const dbsBcvParser = P.seq(dbsBookIdParser, P.regex(/\//), dbsCvsListParser)
    .map(([book, unused, cvs]) => cvs.map(cv => {
        let parts = cv.split('-')
        let bbb = nnn(book)
        return `${bbb}${parts[0]}-${bbb}${parts[1]}`
    }))

const dbsBcvListParser = dbsBcvParser.sepBy1(semiSep)
    .map(cvs => cvs.reduce((x, y) => x.concat(y)))

// --------------


const verseParser = P.regexp(/\d+[abc]?/).map(s => { 
    s = s.replace(/[abc]/, '')
    return nnn(parseInt(s))
})

const chapterParser = P.regexp(/0*[1-9]+[0-9]*/).map(s => nnn(parseInt(s)))

const versesParser = P.alt(
    P.seq(verseParser, hyphenSep, verseParser)
        .map(([verse1, unused, verse2]) => verse1 + '-' + verse2),
    verseParser
        .map(s => s + '-' + s)
)

export const versesListParser = versesParser.sepBy1(commaSep)

const cvParser1 = 
    P.seq( chapterParser, cvSep, verseParser,
                         P.regex(/ *- */), 
                         chapterParser, cvSep, verseParser)
      .map(([c1, _1, v1, _2, c2, _3, v2]) => [`${c1}${v1}-${c2}${v2}`])

const cvParser2 = P.seq( chapterParser, cvSep, versesListParser)
    .map(([chapter, _unused, vrs]) => vrs.map(vr => {
        let parts = vr.split('-')
        return `${chapter}${parts[0]}-${chapter}${parts[1]}`
    }))

const cvParser3 = P.seq(chapterParser, hyphenSep, chapterParser)
    .map(([chapter1, _unused, chapter2]) => [`${chapter1}-${chapter2}`])

const cvParser4 = chapterParser
    .map(chapter => [`${chapter}-${chapter}`])

export const cvParser = P.alt(cvParser1, cvParser2, cvParser3, cvParser4)

export const cvsListParser = cvParser.sepBy1(semiSep)
    .map(cvs => cvs.reduce((x,y) => x.concat(y)))

// const bcvParser1 = P.seq(languageBookNameParser('en'), bcSep, cvsListParser)
//     .map(([book, unused, cvs]) => cvs.map(cv => {
//         let parts = cv.split('-')
//         let bbb = nnn(book)
//         return `${bbb}${parts[0]}-${bbb}${parts[1]}`
//     }))

// const bcvParser2 = P.seq(languageBookNameParser('en'))
//     .map(([book]) => {
//         let bbb = nnn(book)
//         return [`${bbb}`]
//     })

const multiLanguageBcvParser3 = (uiLanguageCode: string, projectBookNames: string[]) => (
    P.alt(projectBookNameParser(projectBookNames),
        languageBookNameParser(uiLanguageCode),
        languageBookNameParser('en'))
)

const multiLanguageBcvParser1 = (uiLanguageCode: string, projectBookNames: string[]) => (
    P.seq(multiLanguageBcvParser3(uiLanguageCode, projectBookNames), bcSep, cvsListParser)
        .map(([book, unused, cvs]) => cvs.map(cv => {
            let parts = cv.split('-')
            let bbb = nnn(book)
            return `${bbb}${parts[0]}-${bbb}${parts[1]}`
        }))
)

const multiLanguageBcvParser2 = (uiLanguageCode: string, projectBookNames: string[]) => (
    P.seq(multiLanguageBcvParser3(uiLanguageCode, projectBookNames))
        .map(([book]) => {
            let bbb = nnn(book)
            return [`${bbb}`]
        })
)

// export const bcvParser = P.alt(bcvParser1, bcvParser2)

const multiLanguageBcvParser = (uiLanguageCode: string, projectBookNames: string[]) => (
    P.alt(multiLanguageBcvParser1(uiLanguageCode, projectBookNames), multiLanguageBcvParser2(uiLanguageCode, projectBookNames))
)

const multiLanguageBcvListParser = (uiLanguageCode: string, projectBookNames: string[]) => (
    multiLanguageBcvParser(uiLanguageCode, projectBookNames).sepBy1(semiSep)
            .map(cvs => cvs.reduce((x, y) => x.concat(y)))
)

// const bcvListParser = bcvParser.sepBy1(semiSep)
//     .map(cvs => cvs.reduce((x, y) => x.concat(y)))

function bcvToRefRange(bcv: string) {
    if (bcv.length === 3) { // only bbb
        return new RefRange(bcv, bcv)
    }

    let parts = bcv.split('-')
    return new RefRange(parts[0], parts[1])
}

const atLeastBCProvidedParser = (uiLanguageCode: string, projectBookNames: string[]) => (
    multiLanguageBcvParser1(uiLanguageCode, projectBookNames).sepBy1(semiSep)
        .map(cvs => cvs.reduce((x, y) => x.concat(y)))
)

export function parseBCRefRanges(refs: string, uiLanguageCode: string, projectBookNames: string[]) {
    let trimmed = refs.trim()
    if (!trimmed) return []

    return atLeastBCProvidedParser(uiLanguageCode, projectBookNames)
        .map(bcvs => bcvs.map(bcvToRefRange))
        .tryParse(trimmed)
}

export function bcvSingleToRefRange(bcv: string) {
    let parts = bcv.split('-')
    let part1 = parts[0]
    let part2 = parts.length === 2 ? parts[1] : parts[0]
    return new RefRange(part1, part2)
}

export class RefRangeMap<TargetType> {
    map = new Map<string, TargetType[]>()
    versification = 'English'

    add(target: TargetType, refs: RefRange[]) {
        let verses = RefRange.getAllVersesInRefRanges(refs, this.versification)

        for (let verse of verses) {
            let targets = this.map.get(verse) ?? []
            if (!targets.includes(target)) {
                targets.push(target)
                this.map.set(verse, targets)
            }
        }
    }

    // verse = 'bbbcccvvv'
    addBbbvvvs(target: TargetType, verses: string[]) {
        for (let verse of verses) {
            let targets = this.map.get(verse) ?? []
            if (!targets.includes(target)) {
                targets.push(target)
                this.map.set(verse, targets)
            }
        }
    }

    get(refs: RefRange[]) {
        let verses = RefRange.getAllVersesInRefRanges(refs, this.versification)
        let targets: TargetType[] = []

        for (let verse of verses) {
            let _targets = this.map.get(verse) ?? []
            for (let target of _targets) {
                if (!targets.includes(target)) {
                    targets.push(target)
                }
            }
        }

        return targets
    }
}