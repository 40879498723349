import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { t } from 'ttag'
import { OKButton, CancelButton } from '../utils/Buttons'

interface IToggleBoxEditorButtons {
    confirmChanges: (closeAfterConfirm?: boolean) => Promise<void>
    cancelChanges: () => void
}
export const ToggleBoxEditorButtons: FC<IToggleBoxEditorButtons> = observer(
    ({ confirmChanges, cancelChanges }
    ) => {
        return <div className="togglebox-editor-buttons">
            <OKButton
                className="ok-button"
                buttonClassName=""
                enabled={true}
                tooltip={t`Confirm`}
                onClick={confirmChanges} />
            <CancelButton
                enabled={true}
                onClick={cancelChanges}
                className='cancel-button'
                tooltip={t`Cancel`} />
        </div>
    })
