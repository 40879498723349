import React, { Component } from "react"
import { observer } from "mobx-react"
import { observable, makeObservable } from "mobx";
import Autosuggest, { ChangeEvent, SuggestionHighlightedParams, RenderSuggestionParams } from "react-autosuggest"

// import { DownArrowButton } from "./Buttons";
import './Autosuggest.css'
import { WarningIcon } from "./Icons"

import _debug from "debug"; let log = _debug('sltt:SearchBox') 


interface ISearchBox {
    searchParameter: string,
    options: string[],
    onEnter: () => void,
    onEscape: () => void,
    searchParameterChanged: (searchParameter: string) => void,
    tooltip: string,
    autoFocus?: boolean,
    isValidInput?: boolean,
    invalidTooltip?: string,
    keyboardShortcuts?: { key: string, metaKey: boolean, shiftKey: boolean, handler: (event: any) => void, }[],
    disableTab?: boolean,
}

@observer
class SearchBox extends Component<ISearchBox> {
    @observable suggestions: string[] = []
    input: any = null
    suggestionHighlighted = false

    constructor(props: ISearchBox) {
        super(props);
        makeObservable(this);
    }

    focus() {
        this.input && this.input.focus()
    }

    onSuggestionsFetchRequested = ({ value }: { value: string }) => {
        this.suggestions = this.getSuggestions(value)
    }

    getSuggestions = (value: string) => {
        let { options } = this.props
        return options.filter(e => e.toLowerCase().indexOf(value.toLowerCase()) > -1)
    }

    onSuggestionsClearRequested = () => {
        this.suggestions = []
    }

    onSuggestionHighlighted = (params: SuggestionHighlightedParams) => {
        let { suggestion } = params
        this.suggestionHighlighted = suggestion !== null
    }

    onChange = (event: any, params: ChangeEvent) => {
        let { newValue, method } = params
        let { searchParameterChanged } = this.props
        if (method !== 'escape') {
            searchParameterChanged(newValue)
        }
    }

    storeInputReference = (autosuggest: any) => {
        if (autosuggest !== null) {
            this.input = autosuggest.input
        }
    }

    onClick = (e: any) => {
        const isFocused = document.activeElement === this.input

        if (isFocused) {
            this.input.blur()
        } else {
            this.input.focus()
        }

        e.preventDefault()
    }

    onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        let { onEnter, onEscape, keyboardShortcuts, disableTab } = this.props
        let { suggestionHighlighted, input } = this
        
        event.stopPropagation()

        if (event.key === 'Tab' && !disableTab) {
            event.preventDefault()

            if (suggestionHighlighted) {
                input && input.blur()
                input && input.focus()
                this.suggestions = []
            }
            else if (this.suggestions.length) {
                this.props.searchParameterChanged(this.suggestions[0])
            }

            return
        }

        if (event.key === 'Enter') {
            if (!suggestionHighlighted) {
                onEnter()
                if (input) {
                    input.blur()
                }
            }

            return
        } 
        
        if (event.key === 'Escape') {
            onEscape()
            if (input) {
                input.blur()
            }
            return
        }

        // The key is not 'Tab', 'Enter' or 'Escape'
        let shortcutHandler = keyboardShortcuts?.find(shortcut => shortcut.key === event.key && shortcut.metaKey === event.metaKey && shortcut.shiftKey === event.shiftKey)
        if (shortcutHandler) {
            shortcutHandler.handler(event)
        }
    }

    render() {
        let { tooltip, searchParameter, autoFocus, isValidInput, invalidTooltip } = this.props
        let { onChange, storeInputReference, onClick, suggestions, onSuggestionsFetchRequested,
            onSuggestionsClearRequested, onSuggestionHighlighted, onKeyDown, } = this

        // Even though this isn't used in the render method, we must reference it so
        // the component will rerender when keyboardShortcuts changes. This is so that
        // onKeyDown will get access to the updated list of shortcuts.
        let { keyboardShortcuts, disableTab } = this.props

        const inputProps = {
            value: searchParameter,
            onChange,
            onKeyDown,
            autoFocus: !!autoFocus,     // Coerce autoFocus to boolean
        }

        let prefix = 'search-box'

        return (
            <div className='search-box-container'>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getItemValue}
                    renderSuggestion={renderSuggestion}
                    renderInputComponent={inputProps => (
                        isValidInput !== undefined && invalidTooltip !== undefined ?(
                            renderInputWithValidityIndicator(inputProps, isValidInput, prefix, invalidTooltip)
                        ) : (
                            renderRegularInput(inputProps)
                        )
                    )}
                    inputProps={inputProps}
                    shouldRenderSuggestions={() => true}
                    onSuggestionHighlighted={onSuggestionHighlighted}
                    ref={storeInputReference}
                    theme={{
                        input: isValidInput !== undefined ? `${prefix}__input--with-validity` : `${prefix}__input--plain`,
                        suggestionsContainer: `${prefix}__suggestions-container`,
                        suggestionsContainerOpen: `${prefix}__suggestions-container--open`,
                        suggestion: `${prefix}__suggestion`,
                        suggestionHighlighted: `${prefix}__suggestion--highlighted`,
                        suggestionsList: `${prefix}__suggestions-list`,
                    }}
                />
                {/* <DownArrowButton
                    onClick={onClick}
                    className='search-box-down-arrow'
                    tooltip={tooltip} /> */}
            </div>
        )
    }
}

const getItemValue = (item: string) => item

const renderRegularInput = (inputProps: any) => (
    <input {...inputProps} />
)

const renderInputWithValidityIndicator = (inputProps: any, valid: boolean, prefix: string, invalidTooltip: string) => (
    <div className={`${prefix}__input-wrapper`}>
        <input {...inputProps} />
        {!valid && (
            <WarningIcon
                className={`${prefix}__invalid-icon`}
                tooltip={invalidTooltip}
            />
        )}
    </div>
)

const renderSuggestion = (suggestion: string, params: RenderSuggestionParams) => (
    <div>
        {suggestion}
    </div>
)

export { SearchBox }