import React, { Component } from "react"
import { observer } from "mobx-react"
import { t } from 'ttag'

import NumberInput from "./NumberInput"
import { RootConsumer } from "../app/RootContext"

interface IDifficultyView {
    stopEditing: () => void,
    difficulty: number,
    setDifficulty: (difficulty: number) => void,
}

class DifficultyView extends Component<IDifficultyView> {
    render() {
        let { difficulty, setDifficulty, stopEditing } = this.props
        return (
            <RootConsumer>
                {rt => (
                    <span className='difficulty-editor'>
                        <NumberInput
                            message={t`Type Enter to set new difficulty or Esc to cancel.`}
                            initialValue={difficulty}
                            min={0}
                            onEnter={value => {
                                if (!isNaN(value)) {
                                    setDifficulty(value)
                                }
                                stopEditing()
                            }}
                            onEscape={stopEditing}
                        />
                    </span>
                )}
            </RootConsumer>
        )
    }
}

export default observer(DifficultyView)