import { t } from 'ttag'

export const canUploadWithoutCompressionTypes = ['video/mp4', 'video/webm']

const canUploadWithoutCompressionExtensions = ['mp4', 'webm']
const canUploadWithCompressionExtensions = ['mxf', 'mov', 'wmv', 'avi']

const allowedVideoTypesMessage = t`Allowed video file types:`

// If video file can be uploaded WITHOUT compression, return empty string.
// Otherwise, return a message explaining what video files can be uploaded
export function canUploadWithoutCompression(file: File) {
    if (canUploadWithoutCompressionTypes.includes(file.type)) return ''

    const extension = file.name.split('.').pop() || ''
    if (canUploadWithoutCompressionExtensions.includes(extension.toLowerCase())) return ''

    const extensions = canUploadWithoutCompressionExtensions
    return `${allowedVideoTypesMessage} ${extensions.sort().join(', ')}`
}

// If video file can be uploaded WITH compression, return empty string.
// Otherwise, return a message explaining what video files can be uploaded
export function canUploadWithCompression(file: File) {
    if (canUploadWithoutCompression(file) === '') return ''

    const extension = file.name.split('.').pop() || ''
    if (canUploadWithCompressionExtensions.includes(extension.toLowerCase())) return ''

    let extensions = canUploadWithoutCompressionExtensions
        .concat(canUploadWithCompressionExtensions)
    return `${allowedVideoTypesMessage} ${extensions.sort().join(', ')}`
}
