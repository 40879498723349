// Periodically poll active video controller.
// Issue callback with current time and playing/not-playing status.

import _debug from "debug"; let log = _debug('sltt:Poller') 


export class VideoPlayerPoller {
    vc: HTMLVideoElement
    onTick ?: (currentTime: number) => void
    onPlayingStatus?: (playing: boolean) => void

    private timerId: any

    constructor(
        vc: HTMLVideoElement,
        onTick?: (currentTime: number) => void,
        onPlayingStatus?: (playing: boolean) => void,
    ) 
    {
        this.vc = vc
        this.onTick = onTick
        this.onPlayingStatus = onPlayingStatus
    }

    timeUpdate() {
        let { vc, timerId } = this

        // https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing
        // Looks like knowing if video is planning is messy.
        // The readState check causes patch videos to quit so I removed it.
        let playing = (vc.currentTime > 0 && !vc.paused && !vc.ended /* && vc.readyState > 2 */)
        this.onPlayingStatus && this.onPlayingStatus(playing)
        
        if (!playing) {
            this.stopUpdater('!playing')
        }

        this.onTick && this.onTick(vc.currentTime)
    }

    // Currently the only way I know to reliably inform other components
    // what the current position of the player is is to set a timer
    // and report this based on the currentTime setting of the <video>.
    startUpdater() {
        this.stopUpdater('') // no need to log this, it is only ensuring any existing timer is removed
        // log('startUpdater')
        this.timerId = setInterval(this.timeUpdate.bind(this), 33)
    }

    stopUpdater(message: string) {
        if (this.timerId) {
            // message && log(`stopUpdater ${message}`)
            clearInterval(this.timerId)
            this.timerId = null
        }
    }
}