// Allow user to select which version of the video for the package

import React, { Component } from 'react'
import {observer} from 'mobx-react'
import _ from 'underscore'
import { MenuItem, Dropdown } from 'react-bootstrap'
import { t } from 'ttag'

import PassageStatus from './PassageStatus'
import { Passage, PassageVideo, ProjectPlan } from '../../models3/ProjectModels'
import { TrashButton } from '../utils/Buttons'
import { UnresolvedTextNotification } from '../notifications/Notifications'
import { IDateFormatter } from '../../models3/DateUtilities'
import '../passages/PassageVideoSelector.css'

import _debug from "debug"; let log = _debug('sltt:PassageVideoSelector')

interface IPassageVideoSelector {
    enabled: boolean,
    videos: PassageVideo[],
    currentVisibleVideo: PassageVideo,
    onSelect: (video: PassageVideo) => void,
    dateFormatter: IDateFormatter,
    passageVideoNotification: IPassageVideoNotification,
    plan?: ProjectPlan,
}

interface IPassageVideoNotification {
    passage: Passage | null,
    hardNotificationCutoff: () => Date,
    canViewConsultantOnlyFeatures: boolean,
}

class PassageVideoSelector extends Component<IPassageVideoSelector> {
    render() {
        let { videos, currentVisibleVideo, enabled, passageVideoNotification: passageVideoDisplayFormat, dateFormatter, plan } = this.props
        let { passage, hardNotificationCutoff, canViewConsultantOnlyFeatures } = passageVideoDisplayFormat
        
        let activeVideos = videos.filter(v => !v.removed)
        let removedVideos = videos.filter(v => v.removed)

        activeVideos = _.sortBy(activeVideos, 'rank').reverse()

        let cutoff = hardNotificationCutoff()
        let creationDate = currentVisibleVideo.displayedCreationDate(dateFormatter)

        const getMenuItemJsx = (pv: PassageVideo) => {
            const creationDate = pv.displayedCreationDate(dateFormatter)
            const { label, removed } = pv
            const uploadIndicator = this.uploadIndicator(pv)
            const unresolvedNote = pv.mostRecentUnresolvedNote(passage!, cutoff, canViewConsultantOnlyFeatures)
            const deletedTooltip = removed ? /* translator: important */ t`This video has been deleted. Click on it to undelete it.` : undefined
            return <MenuItem
                className="passage-video-selector-2"
                key={pv._id}
                onClick={() => this.props.onSelect(pv)}
                value={creationDate}
                title={deletedTooltip}
            >
                <div className={`video-passage-selector-menu-line ${removed ? 'deleted-video-item' : ''}`}>
                    <div className="menu-column1">
                        {removed && <TrashButton
                            enabled={true}
                            buttonClassName='deleted-video-item-trash'
                            className=""
                            onClick={() => { }}
                            tooltip={''}
                        />}
                        {creationDate} {uploadIndicator}
                    </div>
                    <div className="menu-column2">
                        {label ? <span className="videoLabel">{label} {uploadIndicator}</span> : ''}
                        {plan && <PassageStatus passageVideo={pv} plan={plan} />}
                    </div>
                    <div className="menu-column3">
                        {unresolvedNote && (
                            <UnresolvedTextNotification className='portion-button'
                                tooltip={/* translator: important */ t`Unresolved notes exist`} />
                        )}
                    </div>
                </div>
            </MenuItem>
        }

        const { label } = currentVisibleVideo
        const displayName = label || creationDate
        const uploadIndicator = this.uploadIndicator(currentVisibleVideo)
        const dropdownToggleTitle = label ? creationDate : undefined

        return (
            <div className="passage-video-selector">
                <Dropdown id='dropdown-selector' disabled={!enabled} pullRight>
                    <Dropdown.Toggle
                        className="passage-video-selector-2 passage-video-selector-menu-item-flex"
                        title={dropdownToggleTitle}
                    >
                        {currentVisibleVideo.removed && (
                            <TrashButton
                                enabled={true}
                                buttonClassName="deleted-video-item-trash"
                                className=""
                                onClick={() => {}}
                                tooltip={/* translator: important */ t`This video has been deleted.`}
                            />
                        )}
                        {displayName} {uploadIndicator}&nbsp;
                        {plan && <PassageStatus passageVideo={currentVisibleVideo} plan={plan} />}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="scrollable-dropdown">
                        {activeVideos.map(getMenuItemJsx)}
                        {removedVideos.map(getMenuItemJsx)}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }

    uploadIndicator = (video: PassageVideo) => video.uploaded ? '' : '*'
}

export default observer(PassageVideoSelector)
