import React, { Component } from 'react'
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react'
import _ from 'underscore'
import { t, jt } from 'ttag'

import TextInput from '../utils/TextInput'
import { displayError } from '../utils/Errors'
import { Root } from '../../models3/Root'

import _debug from "debug"; let log = _debug('sltt:PortionAdder')

interface IPortionAdder {
    rt: Root,
}

class PortionAdder extends Component<IPortionAdder> {
    @observable adding = false
    @observable name = ''

    constructor(props: IPortionAdder) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { rt } = this.props
        let { project, iAmTranslator } = rt
        let { portions } = project

        if (portions.length === 0 && !iAmTranslator) {
            return (<p>{/* translator: important */ t`No portions present yet for this project`}</p>)
        }
     
        if (!iAmTranslator) return null

        let boldProjects = <strong>{/* translator: important */ t`Projects`}</strong>
        let boldPortions = <strong>{/* translator: important */ t`portions`}</strong>

        if (!this.adding)
            return (
                <div>
                    { portions.length === 0 &&
                        <span>{/* translator: important */ jt`${boldProjects} are divided into ${boldPortions}.`}<br/><br/> 
                                    {/* translator: important */ t`Typically a portion will contain all the passages in a single user video.`}<br/><br/>
                                    {/* translator: important */ t`For example, a portion might be called 'Mark 1'.`}<br/><br/>
                                    {/* translator: important */ t`Click the plus sign below to add a portion to this project.`}<br/><br/></span>
                    }
                    <span onClick={ () => { this.adding = true; this.name = '' } }
                                data-toggle="tooltip" 
                                title={/* translator: important */ t`Add portion.`}
                                className="far fa-fw fa-plus-square portion-add-button" />
                </div>
            )

        return (
            <div className="passage-box">
                <TextInput
                    message={/* translator: important */ t`Type Enter to add new portion or Esc to cancel.`}
                    initialValue=""
                    validate={this.validate.bind(this)}
                    _onEscape={this.onCancel.bind(this)}
                    _onEnter={this.onEnter.bind(this)} />
            </div>
        )
    }

    onCancel() { 
        this.adding = false
    }

    onEnter(newPortionName: string) { 
        let { rt } = this.props
        let { project } = rt
        let { portions } = project

        if (this.validate(newPortionName)) {
            this.adding = false
            return
        }

        project.addPortion(newPortionName)
            .then(() => {
                let portion = _.find(portions, p => p.name === newPortionName)
                rt.setPortion(portion!)
                this.adding = false
            })
            .catch((err: any) => {
                displayError(err)
                this.adding = false
            })
    }

    validate(newPortionName: string) {
        let { rt } = this.props

        newPortionName = newPortionName.trim()

        if (newPortionName === '') {
            return /* translator: important */ t`Empty name`
        }

        if (rt.project.portions.some(portion => portion.name === newPortionName)) {
            return /* translator: important */ t`Duplicate name`
        }

        return ''
    }
}

export default observer(PortionAdder)