// Allow user to edit the label of the current video

import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import _ from 'underscore'
import { t } from 'ttag'
import '../passages/PassageVideoLabelEditor.css'
import { PassageVideo, ProjectPlan } from '../../models3/ProjectModels'
import TextInput from '../utils/TextInput'
import { IDateFormatter } from '../../models3/DateUtilities'
import { ToggleEditor } from '../utils/ToggleEditor'

import _debug from "debug"; const log = _debug('sltt:PassageVideoLabelEditor')
interface IPassageVideoLabelEditor {
    enabled: boolean,
    videos: PassageVideo[],
    currentVisibleVideo: PassageVideo,
    dateFormatter: IDateFormatter,
    plan: ProjectPlan | undefined,
    setEditingVideoLabel: React.Dispatch<React.SetStateAction<boolean>>,
}

export const PassageVideoLabelEditor: React.FC<IPassageVideoLabelEditor> = observer(({ currentVisibleVideo, dateFormatter, setEditingVideoLabel }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [textInputValue, setTextInputValue] = React.useState(currentVisibleVideo.label)
    const handleOk = () => {
        currentVisibleVideo.setLabel(textInputValue)
        setEditingVideoLabel(false)
    }
    const handleCancel = () => setEditingVideoLabel(false)
    const placeholder = t`Edit label...`
    const creationDate = currentVisibleVideo.displayedCreationDate(dateFormatter)
    return (
        <div
            ref={ref}
            className="passage-video-label-editor passage-video-label-editor-menu-item-flex toggle-editor-container">
            <ToggleEditor
                allowEditing={true}
                isEditing={true}
                setIsEditing={(e: any) => { setEditingVideoLabel }}
                title={creationDate  + '\u00A0' + currentVisibleVideo.label}
                iconClass="text-input-edit-button passage-video-label-editor-menu-item-flex"
                handleCancel={handleCancel}
                handleClickOk={handleOk}
            >
                <div id="input-text" className={`text-input passage-video-name-input passage-video-label-edit-name`}>
                    <TextInput
                        initialValue={currentVisibleVideo.label}
                        allowEmptyValue={true}  // Allows the user to delete the text
                        placeholder={placeholder}
                        maxlength={17} //This is the max amount of characters displayable in PassageVideoSelector.Dropdown.Toggle
                        message=""
                        _onEnter={handleOk}
                        _onEscape={handleCancel}
                        refOnClickOutside={ref}
                        validate={(value: string) => {
                            // validate is used as an onChange
                            setTextInputValue(value)
                            return ''
                        }}
                    />
                </div>
            </ToggleEditor>
        </div>
    )
})
