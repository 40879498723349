import React, { Component } from 'react'
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { t } from 'ttag'

import { Root } from '../../../models3/Root'
import TextInput from '../../utils/TextInput'
import { displayError } from '../../utils/Errors'

interface IMemberAdder {
    rt: Root,
}

class MemberAdder extends Component<IMemberAdder> {
    @observable adding = false

    constructor(props: IMemberAdder) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { rt } = this.props
        let { project, iAmAdmin } = rt
        let { canAddMember } = project
        if (!iAmAdmin) return null

        if (!this.adding)
            return (
                <tr>
                    <td> <button className='btn btn-default add-user' onClick={() => {
                        this.adding = true
                    }}>{t`Add User`}</button> </td>
                </tr>
            )

        return (
            <tr>
                <td>
                    <div className="passage-box">
                        <TextInput
                            type={'email'}
                            message={t`Type Enter to add new member or Esc to cancel.`}
                            initialValue=""
                            validate={canAddMember}
                            _onEscape={() => { this.adding = false }}
                            _onEnter={this.onEnter.bind(this)} />
                    </div>
                </td>
            </tr>
        )
    }

    onEnter(email: string) {
        let { rt } = this.props
        let { project } = rt

        if (!project.canAddMember(email)) { // canAddMember returns string. '' means we can add member.
            project.addMember(email) 
                .catch(displayError)
        }
        this.adding = false
    }
}

export default observer(MemberAdder)