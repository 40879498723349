import React, { Component } from 'react'
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react'
import { t } from 'ttag'

import { Root } from '../../models3/Root'
import { PassageSegment } from '../../models3/ProjectModels'
import { EditSegmentButton } from '../utils/Buttons'
import SegmentGlossIdentitySelector from './SegmentGlossIdentitySelector'
import { EditableRichText } from '../utils/RichTextEditor'
import SegmentGlossEditor from './SegmentGlossEditor'

import _debug from "debug"; let log = _debug('sltt:SegmentGlosses')

interface ISegmentGlosses {
    rt: Root,
    segment: PassageSegment,
    editing: boolean,
    setEditing: (editing: boolean) => void,
    tourSelector: string,
}

class SegmentGlosses extends Component<ISegmentGlosses> {
    @observable identity = ''
    @observable editable = false

    constructor(props: ISegmentGlosses) {
        super(props)
        makeObservable(this);
        this.setDefaultIdentity()
    }

    setDefaultIdentity = () => {
        let { rt } = this.props
        let identities = rt.project.members.map(m => m.email)

        if (!identities.length) return 

        let identity = rt.getDefault('gloss_identity') ?? ''
        if (!identities.includes(identity)) {
            // If the current user is a member of the project user their id,
            // otherwise default to first user in the list.
            // Normally the only way you would see a project if you are not a member
            // is if you are a root user.
            let selected = identities.includes(rt.username) ? rt.username : identities[0]

            rt.setDefault('gloss_identity', selected)
            this.signalChange(selected)
            return
        }
        
        let selected = identity
        this.signalChange(selected)
    }

    signalChange = (selected: string) => {
        let { rt } = this.props
        let { iAmRoot, iAmAdmin, iAmInterpreter, username } = rt

        let editable = iAmRoot || iAmAdmin ||
            (iAmInterpreter && selected === username)            
        log(`signalChange ${editable} [${iAmRoot}, ${iAmAdmin}, ${iAmInterpreter}, ${username}, ${selected}]`)

        this.identity = selected
        this.editable = editable
    }

    render() {
        let { segment, rt, setEditing, tourSelector, editing } = this.props
        let { identity, editable, onChange } = this
        let { useMobileLayout, project, iAmInterpreter } = rt
        
        if (!segment) return null
        
        // tigger render on change to segment
        let { _rev } = segment
        
        let sg = segment.glosses.find(g => g.identity === identity)
        let value = sg ? sg.gloss : ''
        let tooltip = editable ? t`Edit segment text.`
                               : t`You do not have permissions to edit this segment text.`

        let identities = rt.project.members.map(m => m.email)

        // For now, use the old text editor. We don't do this for text that has
        // already been edited in the new editor. If we did, the old text editor
        // would display HTML strings, which would be surprising for users.
        let prefix = 'segment-gloss-editor'
        let useNextTextEditor = value.trim().startsWith('<') && value.trim().endsWith('>')
        if (useNextTextEditor) {
            return (
                <div className='segment-glosses-rich-text'>
                    {!editing && (
                        <div>
                            <div className='segment-gloss-selector'>
                                <div className='segment-gloss-selector-dropdown'>
                                    <SegmentGlossIdentitySelector 
                                        selected={identity}
                                        identities={identities}
                                        onChange={onChange}
                                    />
                                </div>
                                {!useMobileLayout && (
                                    <EditSegmentButton
                                        className="sl-edit-segment-caption-button"
                                        tourSelector={tourSelector}
                                        tooltip={tooltip}
                                        enabled={editable}
                                        onClick={() =>  { 
                                            setEditing(true)
                                        } } />
                                )}&nbsp;
                            </div>
                        </div>
                    )}
                    {editing && (
                        <div className="segment-captions-identity">{identity}</div>
                    )}
                    <EditableRichText
                        savedText={value}
                        save={text => segment.setGloss(identity, text)}
                        cancel={() => {}}
                        editorOpen={editing}
                        setEditorOpen={setEditing}
                        placeholder={t`Segment text...`}
                        prefix={prefix}
                    />
                </div>
            )
        }

        if (editing)
            return (<SegmentGlossEditor 
                segment={segment}
                identity={identity}
                onClose={() => {
                    setEditing(false)
                } } />)

        return (
            <div className='segment-glosses'>
                <div className='segment-gloss-selector'>
                    <div className='segment-gloss-selector-dropdown'>
                        <SegmentGlossIdentitySelector 
                            selected={identity}
                            identities={identities}
                            onChange={onChange}
                        />
                    </div>
                    {!useMobileLayout && (
                        <EditSegmentButton
                            className="sl-edit-segment-caption-button"
                            tourSelector={tourSelector}
                            tooltip={tooltip}
                            enabled={editable}
                            onClick={() =>  { 
                                setEditing(true)
                            } } />
                    )}&nbsp;
                </div>
                <div className="segment-gloss">
                    {value.split('\n').map((para, i) => {
                        return (<div key={i}> {para} </div>)
                    })}
                </div>
            </div>
        )
    }

    onChange = (identity: string) => {
        let { rt } = this.props
        rt.setDefault('gloss_identity', identity)
        this.signalChange(identity)
    }
}

export default observer(SegmentGlosses)
