import React, { FC, useEffect, useState } from 'react'
import { t } from 'ttag'
import { DrawMode, ReactSketchCanvasRefSLTT, ReactSketchCanvasSLTT } from './ReactSketchCanvasSLTT'
import { CanvasPath } from 'react-sketch-canvas'
import { fmt } from '../utils/Fmt'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { CirclePicker } from 'react-color'

import { Root } from '../../models3/Root'
import { CancelEditSegmentButton, OkEditSegmentButton } from '../utils/Buttons'
import { systemError } from '../utils/Errors'
import { PencilIcon, SquareIcon, TrashIcon, UndoIcon } from '../utils/Icons'
import { Passage, PassageSegment } from '../../models3/ProjectModels'

import _debug from "debug"; const log = _debug('sltt:VideoSketcher')

// Use scale = 1/height to scale paths to the video display height (1.0 = 100%).
// Use scale = height to scale paths to pixels.
function scalePaths(paths: CanvasPath[], scale: number) {
    const scaled = paths.map(_path => {
        return {
            ..._path,
            paths: _path.paths.map(point => {
                return {
                    x: point.x * scale,
                    y: point.y * scale
                }
            })
        }
    })

    return scaled
}

const SKETCH_STROKE_COLOR = 'sketchStrokeColor'

export class VideoSketchData {
    // This is the actual segment (if any) for which we are currently editing the sketchPaths.
    // Note that when we are in the process of switching from one passage, video, or segment to another
    // this may be different than the current segment in the rt until save() is complete.
    // We set this to null after save or cancel to avoid double saves.
    @observable actualSegment: PassageSegment | null = null
    
    canvas = React.createRef<ReactSketchCanvasRefSLTT>()

    @observable strokeColor = localStorage.getItem(SKETCH_STROKE_COLOR) ?? 'red'

    // Paths with x and y where 1.0 = 100% of video display height, this is what is stored in the db
    sketchPaths: CanvasPath[] = []
    height = 0

    constructor() {
        makeObservable(this)
    }

    editingCurrentSegment(passage: Passage | null, passageSegment: PassageSegment | null) { 
        if (!passage || !passageSegment) { return false }

        // When a segment has been patched, get the actual segment for the patched video (if no patch, this is the same as the segment)
        const actualSegment = passageSegment.actualSegment(passage)
        return !!actualSegment && !!this.actualSegment && actualSegment._id === this.actualSegment._id
    }

    startEditingVideoSketch = (passage: Passage | null, passageSegment: PassageSegment | null) => {
        if (!passage || !passageSegment) {
            log('### could not start editing because passage or passageSegment is null')
            this.actualSegment = null
            return
        }

        this.actualSegment = passageSegment.actualSegment(passage)
        this.sketchPaths = this.actualSegment?.sketchPaths ?? []
    }

    async save() {
        const { actualSegment, sketchPaths } = this
        if (actualSegment === null) { return } // nothing to save

        log('save', fmt({ actualSegment, sketchPaths }))
        await actualSegment.setSketchPaths(sketchPaths)

        this.actualSegment = null
    }

    async cancel() {
        this.actualSegment = null
    }
}

interface IVideoSketchEditor {
    data: VideoSketchData,
}

// Drwaing commands for drawing on a video segment
export const VideoSketchEditor: FC<IVideoSketchEditor> = observer(({ data }) => {
    const [strokeColor, setStrokeColor] = useState(data.strokeColor)
    const [pencilSelected, setPencilSelected] = useState('')
    const [rectangleSelected, setRectangleSelected] = useState('')

    const VIDEO_SKETCH_DRAW_MODE = 'videoSketchDrawMode'

    const setDrawMode = (drawMode: DrawMode) => {
        data.canvas.current?.setDrawMode(drawMode)
        setPencilSelected(drawMode === DrawMode.Pencil ? 'selected' : '')
        setRectangleSelected(drawMode === DrawMode.Rectangle ? 'selected' : '')
        localStorage.setItem(VIDEO_SKETCH_DRAW_MODE, drawMode)
    }

    // Initialize drawing mode to most recent mode used
    useEffect(() => {
        const drawMode = localStorage.getItem(VIDEO_SKETCH_DRAW_MODE) ?? DrawMode.Rectangle
        setDrawMode(drawMode as DrawMode)
    }, [])

    const _setStrokeColor = (color: string) => {
        setStrokeColor(color)
        data.strokeColor = color
        localStorage.setItem(SKETCH_STROKE_COLOR, color)
    }

    const handlePencilClick = () => { setDrawMode(DrawMode.Pencil) }

    const handleRectangleClick = () => { setDrawMode(DrawMode.Rectangle) }

    const handleTrashClick = () => {
        data.canvas.current?.clearCanvas()
        data.sketchPaths = []
    }

    const handleUndoClick = () => { data.canvas.current?.undo() }

    return (
        <div className="video-sketch-editor">
            <div className="">
                <div className="video-sketch-commands" style={{ display: 'flex' }}>
                    <div onClick={handlePencilClick}>
                        <PencilIcon className={`video-sketch-icon video-sketch-pencil ${pencilSelected}`}
                            tooltip={t`Draw smooth lines on the video for this segment`} />
                    </div>
                    <div title="Rectangle" onClick={handleRectangleClick} >
                        <SquareIcon className={`video-sketch-icon video-sketch-rectangle ${rectangleSelected}`}
                            tooltip={t`Draw rectangles on the video for this segment`} />
                    </div><div onClick={handleUndoClick}>
                        <UndoIcon className={`video-sketch-icon video-sketch-undo`}
                            tooltip={t`Undo last drawing action`} />
                    </div>
                    <div className="video-sketch-trash" onClick={handleTrashClick}>
                        <TrashIcon className={`video-sketch-icon`}
                            tooltip={`Delete entire drawing`} />
                    </div>
                </div>
                <div className="video-sketch-color-picker">
                    <CirclePicker
                        color={strokeColor}
                        width='100%'
                        colors={[
                            // NOTE: color names don't show current selected. Use hex values instead
                            '#000000' /* black */,
                            '#ffffff' /* white */,
                            '#a9a9a9' /* darkgray */,
                            '#ff0000' /* red */,
                            '#ff7f00' /* orange */,
                            '#ffff00' /* yellow */,
                            '#00ff00' /* lime */,
                            '#00ffff' /* aqua */,
                            '#0000ff' /* blue */,
                            '#ff00ff' /* fuchsia */,
                        ]}
                        onChangeComplete={colorState => _setStrokeColor(colorState.hex)} />
                </div>
            </div>
            <div className="video-sketch-editor-buttons">
                <OkEditSegmentButton
                    enabled={true}
                    onClick={() => { data.save().catch(systemError) }} />
                <CancelEditSegmentButton
                    enabled={true}
                    onClick={() => { data.cancel().catch(systemError) }} />
            </div>
        </div>
    )
})

interface IVideoSketcher {
    data: VideoSketchData
    width: number
    height: number
}

const videoPlayerControlsMargin = 70 // uncover speed control and full page control button

// Editable drawing canvas for drawing on a video segment.
// This is transparent and overlays the video display.
export const VideoSketcher: FC<IVideoSketcher> = observer(({ data, width, height }) => {

    const [isClearingCanvas, setIsClearingCanvas] = useState(true)

    useEffect(() => {
        // log('VideoSketcher useEffect', fmt({ height, isClearingCanvas, sketchPaths }))
        const paths = scalePaths(data.sketchPaths, height)
        setIsClearingCanvas(true)
        data.canvas.current?.clearCanvas()
        setIsClearingCanvas(false)
        data.canvas.current?.loadPaths(paths)
    }, [data.sketchPaths, height])

    const { strokeColor } = data

    const styles = {
        border: '0.0625rem solid #9c9c9c',
        borderRadius: '0.25rem',
    }

    // log('VideoSketcher render', fmt({ height, isClearingCanvas, sketchPaths: data.sketchPaths }))
    return (
        <div className="video-sketcher" style={{ height, width: width - videoPlayerControlsMargin }}>
            <ReactSketchCanvasSLTT
                ref={data.canvas}
                style={styles}
                canvasColor='transparent'
                strokeWidth={4}
                onChange={paths => {
                    //log('VideoSketcher onChange enter', fmt({ height, isClearingCanvas, paths, sketchPaths: data.sketchPaths }))
                    if (isClearingCanvas) return // ignore onChange when called clearCanvas() while loading paths
                    data.sketchPaths = scalePaths(paths, 1 / height)
                    // log('VideoSketcher onChange after-scale', fmt({ height, isClearingCanvas, paths, sketchPaths: data.sketchPaths }))
                }}
                strokeColor={strokeColor} />
        </div>
    )
})

interface IVideoSketchDisplay {
    rt: Root,
    sketchPaths: CanvasPath[]
    width: number
    height: number
}

// Non-editable drawing canvas for drawing on a video segment.
// This is transparent and overlays the video display.
export const VideoSketchDisplay: FC<IVideoSketchDisplay> = ({ rt, sketchPaths, width, height }) => {
    const canvas = React.createRef<ReactSketchCanvasRefSLTT>()

    useEffect(() => {
        const paths = scalePaths(sketchPaths, height)
        canvas.current?.loadPaths(paths)
    }, [sketchPaths, height])

    if (sketchPaths.length === 0) return null

    function onClick() {
        // I wish I could just let this click propagate to the video player but
        // so far I have not been able to get that to work
        if (rt.playing) rt.pause()
        else rt.play()
    }

    return (
        <div className="video-sketch-display"
            style={{ height, width: width - videoPlayerControlsMargin }}
            onClick={() => onClick()}>
            <ReactSketchCanvasSLTT
                ref={canvas}
                canvasColor="transparent"
                strokeColor="transparent"
            />
        </div>
    )
}
