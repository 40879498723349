import { fmt } from '../components/utils/Fmt'
import { AppRoot } from './AppRoot'
import { Project, ProjectPlan, ProjectStage, ProjectTask } from './ProjectModels'

import _debug from "debug"; let log = _debug('sltt:OldProjectPlan')

// Execute this in console via:
//
//     window._cvt()


export class OldProjectTask {
    name: string = ''
    details: string = ''
    difficulty = 1.0

    constructor(task : any) {
        this.name = task.name
        this.details = task.details || ''

        if (task.difficulty === undefined) {
            if (task.name === 'Not started' || task.name === 'Finished') {
                this.difficulty = 0.0
            } else {
                this.difficulty = 1.0
            }
        } else if (task.difficulty < 0.0) {
            this.difficulty = 1.0
        } else {
            this.difficulty = task.difficulty
        }
    }
}

export class OldProjectStage {
    public tasks: OldProjectTask[]
    name: string = ''

    constructor(stage: any) {
        this.name = stage.name
        let tasks = stage.tasks || []
        this.tasks = tasks.map((task: any) => new OldProjectTask(task))
    }
}

// A project plan divides the work for each passage into stages and tasks.

export class OldProjectPlan {
    public stages: OldProjectStage[]

    constructor(plan: any) {
        let stages = plan.stages || []
        this.stages = stages.map((stage: any) => new OldProjectStage(stage))
    }
}

async function _convertOldProjectPlan(project: Project) {
    if (!project.oldPlanDoc) {
        log('oldPlanDoc missing')
        return
    }

    let oldPlan = new OldProjectPlan(project.oldPlanDoc.plan)
    
    let plan = await project.addProjectPlan() // will throw if already exists

    for (let stageIndex=0; stageIndex<oldPlan.stages.length; ++stageIndex) {
        let oldStage = oldPlan.stages[stageIndex]
        let stage = await plan.addStage(stageIndex, oldStage.name)

        for (let taskIndex=0; taskIndex<oldStage.tasks.length; ++taskIndex) {
            let oldTask = oldStage.tasks[taskIndex]
            let { name, details, difficulty } = oldTask
            await stage.addTask(plan, taskIndex, name, details, difficulty)
        }
    }
}

export function validateProjectPlan(project: Project) {
    let ids = project.plans[0].stages.flatMap(stage => stage.tasks).map(task => task.id)
    let pass = 0
    let fail = 0

    for (let pv of project.passageVideos()) {
        let { status } = pv
        if (!status) continue

        if (!ids.includes(status)) {
            let portion = project.findPortion(pv._id)?.name || '*none*'
            let passage = project.findPassage(pv._id)?.name || '*none*'
            ++fail
            log('===validate FAIL', fmt({name: project.name, portion, passage, status}))
        } else {
            ++pass
        }
    }

    // log(`===validateProjectPlan complete pass=${pass}, fail=${fail}`)
    if (fail) {
        // debugger
    }

    return ({ name: project.name, modDate: project.oldPlanDoc?.modDate, pass, fail, })
}

let _window = window as any

export async function convertOldProjectPlan() {
    let appRoot: AppRoot = _window.appRoot
    let { project } = appRoot.rt!

    if (project.plans.length) {
        log('### plan already exists')
        return
    }

    await _convertOldProjectPlan(project)
    await validateProjectPlan(project)
}

let skips = ["_log_",]

export async function convertOldProjectPlans() {
    let appRoot: AppRoot = _window.appRoot
    let results: any[] = []

    for (let rt of appRoot.rts) {
        let { project } = rt
        if (skips.includes(project.name)) continue

        await rt.initialize()
        log('===', project.name, project.plans.length)
        if (project.plans.length) continue

        try {
            await _convertOldProjectPlan(project)
        } catch (error) {
            debugger
            log(error)            
        }
        results.push(await validateProjectPlan(project))
    }

    results = results.sort(r => r.modDate)

    console.table(results)
}




