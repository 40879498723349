import React, { Component } from "react";
import { observer } from "mobx-react";
import { t } from 'ttag'

import { ProjectPlan, ProjectStage } from "../../../models3/ProjectModels";
import ProjectStageAdder from "./ProjectStageAdder";
import EditableProjectStage from "./EditableProjectStage";
import { Root } from "../../../models3/Root";
import { displayError } from "../../utils/Errors";

interface IEditableStageList {
    rt: Root,
}

@observer
export class EditableStageList extends Component<IEditableStageList> {    
    render() {
        let { rt } = this.props
        let { iAmAdmin, useMobileLayout, project } = rt
        let plan = project.plans[0]

        if (!plan) {
            return null
        }

        let viewableStages = plan.viewableStages || []
        
        if (viewableStages.length === 0) {
            if (!iAmAdmin) {
                return (<div>{t`An admin has not created a project plan yet.`}</div>)
            }
            if (useMobileLayout) {
                return (<div>{t`Switch to a larger screen to create a project plan.`}</div>)
            }
        }

        const _lastIndex = viewableStages.length + 1

        return (
            <div>
                <div className="project-plan">
                    {viewableStages.map((stage, index) => (
                        <Stage
                            key={index}
                            index={index}
                            stage={stage}
                            addStage={this.addStage}
                            deleteStage={viewableStages.length > 1 ? this.deleteStage : undefined}
                            rt={rt}
                        />
                        )
                    )}
                </div>
                <div>
                    <ProjectStageAdder plan={plan} addStage={name => this.addStage(name, _lastIndex)} />
                </div>
            </div>
        )
    }

    addStage = async (name: string, index: number) => {
        let { rt } = this.props
        let { project } = rt
        let plan = project.plans[0]
        if (plan) {
            if (plan.stages.find(stage => stage.name === 'Not started')) {
                // 'Not started' is the 1st stage
                index += 1
            }

            // Ensure stage added before 'Finished' stage
            index = Math.min(index, plan.stages.length - 1)
            try {
                await plan.addStage(index, name)
            } catch (error) {
                displayError(error)
            }
        }
    }

    deleteStage = (_id: string) => {
        let { rt } = this.props
        let { project } = rt
        project.plans[0]?.removeStage(project, _id)
    }
}

interface IStage {
    index: number,
    stage: ProjectStage,
    addStage: (name: string, index: number) => void,
    deleteStage?: (_id: string) => void,
    rt: Root,
}

class Stage extends Component<IStage> {
    render() {
        let { index, stage, addStage, deleteStage, rt } = this.props
        let { iAmAdmin } = rt
        let plan = rt.project.plans[0]
        if (!plan) {
            return null
        }
        let showStageDetails = true

        return (
            <div className="stage" >
                {iAmAdmin && <ProjectStageAdder plan={plan} addStage={stage => addStage(stage, index)} />}
                <EditableProjectStage {...{stage, index, showStageDetails, deleteStage, rt}} />
            </div>
        )
    }
}

export default observer(Stage)