import { Alert } from 'react-bootstrap'
import { t } from 'ttag'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { useEffect, useRef } from 'react'

const intervalMS = 60 * 1000 // check updates every minute

/*
 * adapted from https://github.com/vite-pwa/vite-plugin-pwa/blob/2de47c447e33df3d87084f971df3ac1911075b82/examples/react-router/src/ReloadPrompt.tsx#L20
*/
export function ServiceWorkerWrapper() {
    const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        return () => {
            // cleanup onRegisteredSW > setInterval() just in case the component is unmounted
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current)
            }
        }
    }, [])

    const {
        offlineReady: [offlineReady, setOfflineReady],
        needRefresh: [needRefresh, setNeedRefresh],
        updateServiceWorker,
    } = useRegisterSW({
        /* 
        * from https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html#handling-edge-cases
        */
        onRegisteredSW(swUrl, r) {
            console.log(`Service Worker at: ${swUrl}`)
            console.log('SW Registered: ' + swUrl)
            if (!r) return
            intervalIdRef.current  = setInterval(async () => {
                if (r.installing || !navigator)
                    return
    
                // TODO: use await getIsAppOnlineOrWait() for sltt-pwa context?
                if (('connection' in navigator) && !navigator.onLine)
                    return
    
                const resp = await fetch(swUrl, {
                    cache: 'no-store',
                    headers: {
                        'cache': 'no-store',
                        'cache-control': 'no-cache',
                    },
                })
    
                if (resp?.status === 200)
                    await r.update()
            }, intervalMS)
        },
        onRegisterError(error) {
            console.log('SW registration error', error)
        },
        onNeedRefresh() {
            console.log('SW needs refresh')
            setNeedRefresh(true)
        },
        onOfflineReady() {
            console.log('SW offline ready')
            setOfflineReady(true)
        }
    })

    const updateToLatest = () => {
        updateServiceWorker(true)
        setOfflineReady(false)
        setNeedRefresh(false)
    }

    if (offlineReady || needRefresh) {
        return (
            <Alert>
                <span className="hide-on-alert-overlap">{t`An update is available.`}</span>
                <button onClick={updateToLatest}>{t`Update`}</button>
            </Alert>
        )
    }

    return null
}
