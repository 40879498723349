import { fmt } from "../components/utils/Fmt"
import { Passage, PassageGloss, PassageVideo, Project } from "./ProjectModels"
import { Root } from "./Root"

import _debug from "debug"; let log = _debug('sltt:GlossReferences')

export class GlossReference {
    constructor(
        public passage: Passage,
        public gloss: PassageGloss,
        public passageVideo: PassageVideo,
    ) { }

    info(rt: Root) {
        let { passageVideo, gloss } = this

        let portion = rt.project.portions.find(_portion => passageVideo._id.startsWith(_portion._id))
        let passage = portion?.passages.find(_passage => passageVideo._id.startsWith(_passage._id))

        let parts = [portion?.name ?? '', passage?.name ?? '']

        // Remove redundant parts of reference name if present
        if (parts.length >= 2 && parts[1].startsWith(parts[0])) parts.splice(0, 1)
        if (parts.length >= 2 && parts[1].startsWith(parts[0])) parts.splice(0, 1)
        if (parts.length >= 3 && parts[2].startsWith(parts[1])) parts.splice(1, 1)

        let startingTime = gloss.time
        let endingTime = startingTime
        if (passage && passageVideo) {
            endingTime = startingTime + gloss.duration(passage, passageVideo)
        }

        let timestamp = startingTime > 0 ? ` (${startingTime.toFixed(2)}...${endingTime.toFixed(2)})` : ''

        let tooltip = parts.join(' / ') + timestamp

        return { portion, passage, tooltip }
    }
}

/**
 * Create a list of gloss references for the specified gloss.
 * Order by portion/passage.
 * Set index to first gloss in (or after) specified passage.
 */
export class GlossReferences {
    glossReferences: GlossReference[] = []
    index = -1

    constructor( public project: Project, public glossText: string, passage: Passage ) {
        let passageFound = false
        glossText = glossText.toLowerCase()

        for (let _portion of project.portions) {
            for (let _passage of _portion.passages) {
                if (_passage === passage) passageFound = true
                let video = _passage.getDefaultVideo(null)
                if (video) {
                    let glosses = video.getVisibleGlosses(_passage)
                    for (let _gloss of glosses) {
                        if (_gloss.text.toLowerCase() === glossText) {
                            if (passageFound && this.index === -1) this.index = this.glossReferences.length
                            this.glossReferences.push(new GlossReference(_passage, _gloss, video))
                        }
                    }
                }
            }
        }

        log('!!!constructor', fmt({glossesFound: this.glossReferences.length, index: this.index}))
    }
}


