import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Slider, Handles, GetEventData, Tracks } from 'react-compound-slider'
import { TimeCursorHandle, Track } from '../utils/TimelineItems'

interface IOldSegmentViewerTimeline {
    domainStart: number,
    domainEnd: number,
    position: number,
    updatePosition: (value: number) => void,
}

class SegmentViewerTimeline extends Component<IOldSegmentViewerTimeline> {
    mouseDown = false
    
    constructor(props: IOldSegmentViewerTimeline) {
        super(props)
        this.onMouseUp = this.onMouseUp.bind(this)
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseMove = this.onMouseMove.bind(this)
    }

    render() {
        let { domainStart, domainEnd, position } = this.props
        let { onMouseDown, onMouseMove, onMouseUp } = this
        let domain = [domainStart, domainEnd]
        let values = [position]
        return (
            <Slider
                className='old-segment-viewer-timeline'
                domain={domain}
                step={0.1}
                mode={1}
                values={values}
            >
                <div className='old-segment-viewer-timeline-rail' />
                <Handles>
                    {({ handles, getHandleProps }) => {
                        let handle = handles[0]
                        return (
                            <TimeCursorHandle
                                key={handle.id}
                                handle={handle}
                                getHandleProps={getHandleProps}
                                onMouseUp={onMouseUp}
                                className='old-segment-viewer-timeline-time-cursor'
                            />
                        )
                    }}
                </Handles>
                <Tracks>
                    {({ tracks, getEventData }) => (
                        <div>
                            {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    onMouseDown={e => onMouseDown(e, getEventData)}
                                    onMouseMove={e => onMouseMove(e, getEventData)}
                                    onMouseUp={onMouseUp}
                                    className='old-segment-viewer-timeline-track'
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
            </Slider>
        )
    }

    onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, getEventData: GetEventData) => {
        let { updatePosition } = this.props
        this.mouseDown = true
        updatePosition(getEventData(e).value)
    }

    onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, getEventData: GetEventData) => {
        if (this.mouseDown) {
            let { updatePosition } = this.props
            updatePosition(getEventData(e).value)
        }
    }

    onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.mouseDown = false
    }
}

export default observer(SegmentViewerTimeline)