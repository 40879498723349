import { observer } from 'mobx-react'
import React, { FC, useEffect, useRef, useState, useContext } from 'react'
import { t } from 'ttag'
import { Project } from '../../models3/ProjectModels'
import { TRLLicense } from '../../models3/TRLModel'
import AutowidthInput from '../utils/AutowidthInput'
import { ToggleEditor, ToggleEditorChangeHandlerContext, ToggleEditorConfig } from '../utils/ToggleEditor'
import { InputLanguageSelector } from '../utils/InputLanguageSelector'
import ImageResizer, { MAX_RESIZER_FILE_SIZE } from '../utils/ImageResizer'
import { allowedImageTypes } from '../images/AllowedImages'
import './ProjectPublicationPreferences.css'
import { getLicenseName } from '../utils/TRL'


export interface IProjectIAmAdmin {
    project: Project
    iAmAdmin: boolean    
}

export const ProjectPublicationPreferences: FC<IProjectIAmAdmin> = observer(
    ({ project, iAmAdmin }) => {
        const [copyrightNotice, setCopyrightNotice] = useState(project.copyrightNotice)
        return (
            <div className="project-publication-preferences">
                <h4>{t`Publication Preferences`}</h4>
                <PrimaryInputLanguageSelector {...{ project, iAmAdmin }} />
                <ToggleEditorConfig onChangedValue={setCopyrightNotice} value={copyrightNotice}>
                    <CopyrightNoticeEditor {...{ project, iAmAdmin }} />
                </ToggleEditorConfig>
                <LicenseEditor {...{ project, iAmAdmin }} />
                <LogoChanger {...{ project, iAmAdmin }} />
            </div>
        )
    })

const PrimaryInputLanguageSelector: FC<IProjectIAmAdmin> = observer(
    ({ project, iAmAdmin }) => {
        const [editing, setEditing] = useState(false)
        const primaryInputLanguage = project.getPrimaryInputLanguageOrDefault()
        const [currentInputLanguage, setCurrentInputLanguage] = useState(primaryInputLanguage)

        async function handleClickOk() {
            await project.setPrimaryInputLanguage(currentInputLanguage)
        }

        function handleCancel() {
            setCurrentInputLanguage(primaryInputLanguage)
        }

        const title = t`Primary Input Language`
        return (<ToggleEditor
            allowEditing={iAmAdmin}
            isEditing={editing}
            setIsEditing={setEditing}
            title={title}
            iconClass="fas fa-fw fa-keyboard"
            handleClickOk={handleClickOk}
            handleCancel={handleCancel}
        >
            <div className="project-primary-input-language">
                <InputLanguageSelector
                    primaryInputLanguage={primaryInputLanguage}
                    currentValue={currentInputLanguage}
                    selectorTooltip={title}
                    getOptionTooltip={() => ''}
                    chooseOption={setCurrentInputLanguage}
                    disabled={!editing} />
            </div>
        </ToggleEditor>)
    })
const currentYear = new Date().getFullYear()
const copyrightSymbol = '©'
interface ICopyrightFeatures {
    hasCopyrightSymbol: boolean
    hasValidYear: boolean
    hasOrg: boolean
}

function getCopyrightNoticeFeatures(copyrightNotice: string): ICopyrightFeatures {
    const hasCopyrightSymbol = copyrightNotice.includes(copyrightSymbol)
    const matchesYear = copyrightNotice.match(/\d{4}/) // match four digits any script
    const hasValidYear = matchesYear?.map(yyyy => parseInt(yyyy))
        .some(year => year >= (currentYear - 5) && year <= currentYear + 1) || false
    // match two letters any script. See https://stackoverflow.com/a/30225759
    const hasOrg = /[A-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ]{2}/.test(copyrightNotice)
    return { hasCopyrightSymbol, hasValidYear, hasOrg }
}

interface ICopyrightNoticeEditor {
    project: Project
    iAmAdmin: boolean
}
export const CopyrightNoticeEditor: FC<ICopyrightNoticeEditor> = observer(
    ({ project, iAmAdmin }) => {
        const config = useContext(ToggleEditorChangeHandlerContext)
        const {
            initialStates,
            hideEditorControls,
            value: copyrightNotice,
            onChangedValue: setCopyrightNotice
        } = config
        const [editing, setEditing] = useState(initialStates.isEditing)
        enum InputStatus {
            inactive = 'inactive',
            warning = 'warning',
            success = 'success'
        }
        // inputStatus should warn user if copyrightNotice lacks any of these features: 
        // copyright symbol, 4 digit year, organization  
        const [inputStatus, setInputStatus] = useState(InputStatus.inactive)
        const [copyrightNoticeFeatures, setCopyrightNoticeFeatures] = useState<ICopyrightFeatures>({
            hasCopyrightSymbol: false,
            hasValidYear: false,
            hasOrg: false
        })
        const inputElement = useRef<HTMLInputElement>(null)
        const exampleOrg = t`Organization`
        const placeholder = `${copyrightSymbol} ${currentYear} ${exampleOrg}`

        useEffect(() => {
            if (!editing) {
                setInputStatus(InputStatus.inactive)
                return
            }
            const newCopyrightNoticeFeatures = getCopyrightNoticeFeatures(copyrightNotice)
            setCopyrightNoticeFeatures(newCopyrightNoticeFeatures)
            setInputStatus(
                Object.values(newCopyrightNoticeFeatures).some(feature => feature === false) ?
                    InputStatus.warning : InputStatus.success
            )
        }, [copyrightNotice, editing])

        useEffect(() => {
            if (editing && !copyrightNotice) {
                setCopyrightNotice(initialCopyrightNotice)
            }
            if (editing && inputElement.current) {
                inputElement.current.focus()
            }
        }, [editing])

        const readOnly = !editing

        const initialCopyrightNotice = '© '

        async function handleClickOk() {
            await project.setCopyrightNotice(copyrightNotice)
        }

        function handleCancel() {
            setCopyrightNotice(project.copyrightNotice)
        }

        return <ToggleEditor
            allowEditing={iAmAdmin}
            isEditing={editing}
            setIsEditing={setEditing}
            hideEditorControls={hideEditorControls}
            title={t`Copyright notice`}
            iconClass="far fa-fw fa-copyright"
            handleClickOk={handleClickOk}
            handleCancel={handleCancel}
            help={{ id: 'rights.html', video: 'TODO', tooltip: t`View help on copyright and license` }}
        >
            <div className="copyright-notice-editor">
                <div>
                    <AutowidthInput
                        ref={inputElement}
                        className={`copyright-notice-input copyright-notice-input-${inputStatus}`}
                        placeholderIsMinWidth={true}
                        placeholder={placeholder}
                        value={copyrightNotice}
                        disabled={readOnly}
                        onChange={e => setCopyrightNotice(e.target.value)} />
                </div>
                {editing && <div className="copyright-notice-example" title={t`Example copyright notice`}>
                    <span>{t`Example:`}</span>&nbsp;
                    <span className={`copyright-symbol-${copyrightNoticeFeatures.hasCopyrightSymbol}`}>{copyrightSymbol}</span>&nbsp;
                    <span className={`copyright-year-${copyrightNoticeFeatures.hasValidYear}`}>{currentYear}</span>&nbsp;
                    <span className={`copyright-org-${copyrightNoticeFeatures.hasOrg}`}>{exampleOrg}</span>
                </div>}
            </div>
        </ToggleEditor>
    })

export const LicenseEditor: FC<IProjectIAmAdmin> = observer(
    ({ project, iAmAdmin }) => {
        const [currentLicense, setCurrentLicense] = useState(project.license)
        const [editing, setEditing] = useState(false)
        const selectElement = useRef<HTMLSelectElement>(null)

        useEffect(() => {
            if (editing && selectElement.current) {
                selectElement.current.focus()
            }
        }, [editing])

        async function handleClickOk() {
            await project.setLicense(currentLicense)
        }

        function handleCancel() {
            setCurrentLicense(project.license)
        }
        return <ToggleEditor
            allowEditing={iAmAdmin}
            isEditing={editing}
            setIsEditing={setEditing}
            title={t`License`}
            iconClass="fab fa-fw fa-creative-commons"
            handleClickOk={handleClickOk}
            handleCancel={handleCancel}
            help={{ id: 'rights.html', video: 'TODO', tooltip: t`View help on copyright and license` }}
        >
            <select
                ref={selectElement}
                className="custom-select input-license-selector"
                value={currentLicense}
                onChange={e => setCurrentLicense(e.target.value as TRLLicense)}
                title={t`License`}
                disabled={!editing}
            >
                <option disabled key="choose" value="">Choose License</option>
                {(Object.entries(TRLLicense)).map(([licenseKey, licenseValue]) => <option
                    key={licenseKey}
                    value={licenseValue}
                >{`${licenseValue !== TRLLicense.ASK ?
                    `(${licenseValue}) ` : ''}${getLicenseName(licenseValue)}`}
                </option>
                )}
            </select>
        </ToggleEditor>
    })

export const LogoChanger: FC<IProjectIAmAdmin> = observer((
    { project, iAmAdmin }
) => {
    const [logoUrl, setLogoUrl] = useState<string>(project.organizationLogoUrl)
    const [error, setError] = useState('')

    useEffect(() => {
        setLogoUrl(project.organizationLogoUrl)
    }, [project.organizationLogoUrl])

    const handleLogoChange = async (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement
        const file = target?.files?.item(0)
        if (file) {
            if (file.size > MAX_RESIZER_FILE_SIZE) {
                target.value = ''
                setError(t`File must be smaller than 50MB.`)
                return
            }
            const resizer = new ImageResizer(file)
            try {
                // Our Express server currently only allows 100Kb body limit. Resize to fit.
                const resizedUrl = await resizer.resize(resizedTo, resizedTo, file.type)
                setLogoUrl(resizedUrl)
                await project.setOrganizationLogoUrl(resizedUrl)
            } catch (error) {
                target.value = ''
                setError(error as string)
            }
        }
    }

    return (
        <div className="logo-changer">
            <div>
                <label htmlFor="logo-upload">{t`Organization Logo`}:</label>
                {iAmAdmin && <>
                    <div className="logo-changer-recommendations">
                        <div>{t`Downsizes image to size:` + `${resizedTo} x ${resizedTo}`}</div>
                    </div>
                    <div className="logo-changer-error">{error}</div>
                    <input
                        type="file"
                        id="logo-upload"
                        accept={acceptedResizerTypes}
                        onChange={handleLogoChange} />
                </>}
            </div>
            <div>
                {logoUrl ? (
                    <div className="image-sizes-preview">
                        <div><div className="px-label">128px</div><img className="image-preview-128px" src={logoUrl} alt="128px" /></div>
                        <div><div className="px-label">64px</div><img className="image-preview-64px" src={logoUrl} alt="64px" /></div>
                    </div>
                ) : (
                    <i className={`no-logo-uploaded-icon far fa-image fa-10x editing-${iAmAdmin}`} title={t`No logo uploaded.`}></i>
                )}
            </div>
        </div>
    )
})
const acceptedResizerTypes = allowedImageTypes.join(', ')
const resizedTo = 128
