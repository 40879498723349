import React, {Component, FC, useState} from 'react'
import {observer} from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import { t } from 'ttag'
import { RootConsumer } from '../app/RootContext'
import { EditButton } from './Buttons'

interface ITextInput {
        initialValue?: string,
        message: string,
        maxlength?: number,
        placeholder?: string,
        type?: string,
        _onEnter: (value: string) => void,
        _onEscape?: () => void,
        validate?: (value: string) => string, // check input value returns error message or ''
        asyncValidate?: (value: string) => Promise<string>, // check input value returns error message or ''
        allowEmptyValue?: boolean,
        refOnClickOutside?: React.RefObject<HTMLDivElement>,
    }

@observer
export default class TextInput extends Component<ITextInput> {
    @observable value = ''
    @observable errorMessage = ''
    textInputRef = React.createRef<HTMLDivElement>()

    constructor(props: ITextInput) {
        super(props)
        makeObservable(this);
        this.clickOutside = this.clickOutside.bind(this)
    }

    render() {
        let { message, type, _onEscape, _onEnter, placeholder, maxlength } = this.props
        let { value, errorMessage, textInputRef } = this

        type = type || 'text'

        let showCancel = (errorMessage || !value) && _onEscape

        return (
            <RootConsumer>
                {rt => (
                    <div ref={textInputRef} className="text-input-container">
                        <input 
                            autoFocus
                            placeholder={placeholder || ''}
                            type={type}
                            value={value} 
                            maxLength={maxlength}
                            onChange={this.onChange.bind(this)}
                            onKeyDown={this.onKeyDown.bind(this)}
                        />

                        { errorMessage && <div style={ {color: 'red'} } >{errorMessage}</div> }
                        
                        <div className="passage-info-text">
                            {value && !errorMessage && <span>{message}</span>}
                            {showCancel && <span>{t`Type Esc to cancel.`}</span>}
                        </div>
                    </div>
                )}
            </RootConsumer>
        )
    }

    componentDidMount() { 
        this.value = this.props.initialValue || ''
        document.addEventListener('mousedown', this.clickOutside)
        document.addEventListener('touchstart', this.clickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickOutside)
        document.removeEventListener('touchstart', this.clickOutside)
    }

    clickOutside(event: any) {
        let { textInputRef, errorMessage, value } = this
        let { _onEnter, _onEscape, refOnClickOutside } = this.props
        const ref = refOnClickOutside || textInputRef
        if (!ref.current || ref.current.contains(event.target)) {
            return
        }

        if (errorMessage) {
            _onEscape?.()
        } else {
            _onEnter(value)
        }
    }

    onChange(e: any) {
        let { validate, asyncValidate } = this.props

        this.value = e.target.value
        if (validate) {
            this.errorMessage = validate(e.target.value)
        } else if (asyncValidate) {
            asyncValidate(this.value)
                .then((message: string) => {
                    this.errorMessage = message
                })
                .catch(err => {
                    this.errorMessage = t`Validation failed`
                })
        }
    }

    onKeyDown(e: React.KeyboardEvent) { 
        let { _onEnter, _onEscape, allowEmptyValue } = this.props
        let { value, errorMessage } = this
        
        e.stopPropagation()

        // <enter> = stop editing
        if (e.keyCode === 13) { 
            if ((value || allowEmptyValue) && !errorMessage) {
                _onEnter && _onEnter(this.value)
                return
            }

            return 
        }

        // <esc> = abandon edit
        if (e.keyCode === 27) { 
            e.nativeEvent.stopImmediatePropagation()
            e.preventDefault()
            _onEscape && _onEscape()
            this.value = ''
            return 
        }
    }

}

// Allow editing of a text field with a pencil button to the right

interface ITextInputWithEditButton {
    allowEdit: boolean,
    value: string,
    setValue: (value: string) => void,
    placeholder?: string,
    className?: string,
    validate?: (value: string) => string, // check input value returns error message or ''
    explanation?: string,
    setEditing?: (editing: boolean) => void, // communicate editing state to parent
}

export const TextInputWithEditButton: FC<ITextInputWithEditButton> =
    ({ allowEdit, value, setValue, placeholder, className, 
            validate, explanation, setEditing: externalSetEditing }) => {
        let [editing, setEditing] = useState(false)

        className = className || ''
        placeholder = placeholder || ''

        const _value = value || (allowEdit && placeholder) || ''

        function _setEditing(_editing: boolean) {
            externalSetEditing && externalSetEditing(_editing)
            setEditing(_editing)
        }

        if (!editing) {
            return (
                <div className={className} style={{display: 'flex'}}>
                    <div className='text-input-edit-button-text'>{_value}</div>
                    {allowEdit &&
                        <EditButton
                            enabled='true'
                            className='text-input-edit-button'
                            onClick={() => _setEditing(true)}
                            tooltip="" />
                    }
                </div>
            )
        }

        return (
            <div className={className}>
                <div className="text-input-edit-button-text">
                    <TextInput
                        initialValue={value}
                        message=""
                        placeholder={placeholder}
                        _onEnter={(newValue) => {
                            setValue(newValue)
                            _setEditing(false)
                        }}
                        _onEscape={() => _setEditing(false)}
                        validate={validate}
                        allowEmptyValue={true} />
                </div>
                {explanation && <div className='text-input-edit-button-explanation'>{explanation}</div>}
            </div>
        )
    }
