import React, { Component, FC } from 'react'
import _ from 'underscore'
import {observer} from 'mobx-react'
import { t } from 'ttag'

import { MemberView, SingleMemberView } from './MemberView'
import MemberAdder from './MemberAdder'
import { Root } from '../../../models3/Root'
import { Member } from '../../../models3/ProjectModels'
import { systemError } from '../../utils/Errors'
import { AppRoot } from '../../../models3/AppRoot'
import { LogoutButton } from '../../app/Login'
import { useFlags } from 'launchdarkly-react-client-sdk'

import _debug from "debug"; let log = _debug('sltt:MembersEditor')

interface IMembersEditor{
    rt: Root,
    appRoot: AppRoot,
}

const MembersEditor: FC<IMembersEditor> = ({ rt, appRoot }) => {
    const { emailNotifications } = useFlags()

    const { displayName, project, username, iAmAdmin } = rt
    const { members } = project

    const isSoleAdmin = (member: Member) => {
        if (member.role !== 'admin') return false

        let { project } = rt
        return _.where(project.members, { role: 'admin' }).length === 1
    }

    const member = project.members.find(m => m.email === username)

    return (
        <div>
            <div className="container-fluid">
                <div className="sign-out-text">
                    {appRoot.username}
                    <LogoutButton {...{ appRoot }} />
                </div>
                <br/>
                <SingleMemberView {...{ rt, member }} />
            </div>
            <hr/>
            <div className="container-fluid">
                <div className="row">
                    <h3>{t`Project Members (${displayName})`}</h3>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>{t`User`}</th>
                                    <th>{t`Name`}</th>
                                    <th>{t`Role`}</th>
                                    {(iAmAdmin && emailNotifications) && 
                                        <th title={t`Receive an email each hour when there is activity`} >
                                            {t`Notifications`}
                                        </th>}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {members.map((member, ind) => {
                                    return (
                                        <MemberView
                                            key={member.email}
                                            member={member}
                                            rt={rt}
                                            isSoleAdmin={isSoleAdmin(member)} />
                                    )
                                })
                                }
                                <MemberAdder rt={rt} />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(MembersEditor)