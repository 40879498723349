import React, { Component, FC } from 'react'
import { observer } from 'mobx-react'
import _ from 'underscore'

import { Root } from '../../../models3/Root'
import './ProjectPlanEditor.css'
import { observable, makeObservable } from 'mobx';
import { EditableStageList } from './Stage'

interface IProjectPlanEditor {
    rt: Root,
}

export class ProjectPlanEditor extends Component<IProjectPlanEditor> {
    @observable mouseOver: boolean[] = []

    constructor(props: IProjectPlanEditor) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { rt } = this.props
        let { displayName } = rt.project

        return (
            <div className="project-plan-editor">
                <h4 className='project-plan-editor-project-name'>
                    <b>{displayName}</b>
                </h4>
                <EditableStageList rt={rt} />
                {/* These nbsp provide some extra hover over area so that if the user
                    goes a little ways past the end of the plan they do not suddenly collapse
                    all of the add stage/task icons.
                    I tried doing this with CSS padding but :hover does not appear
                    to trigger over padding */}
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </div>
        )
    }
}

export default observer(ProjectPlanEditor)