import { MultilingualString } from '../../models3/ProjectModels'

export function getTitle(titles: MultilingualString[], defaultLanguage: string) {
    return titles.find(t => t.language === defaultLanguage)?.text ?? ""
}

export function getTitleOrDefault(
    titles: MultilingualString[],
    language: string,
    defaultTitle: string
): string {
    return titles.find(
        t => t.language === language
    )?.text || defaultTitle
}

export function getBestTitle(
    titles: MultilingualString[],
    languages: string[],
    defaultTitle: string = ''
): string {
    // prioritized by first languages in list
    let title: MultilingualString | undefined
    for (const language of languages) {
        title = titles.find(t => t.language === language)
        if (title) {
            return title.text
        }
    }
    return title?.text || defaultTitle
}
