import _, { sortBy } from 'underscore'
import { t } from 'ttag'

import { DBChangeNotifier } from './DBChangeNotifier'

import _debug from "debug"
let log = _debug('sltt:Levelup')
let dbg = _debug('slttdbg:Levelup')

import { fmt, s } from '../components/utils/Fmt'
import { DBEntry, IDB, IDBAcceptor, IDBItem, IDBIterator, IDBModDoc, IDBObject, IDBOperations, IDBSyncApi, IRemoteDBItem, NonretriableSyncFailure, minLocalSeq } from './DBTypes'
import { isSlttAppStorageEnabled, storeRemoteDocs, storeLocalDocs, retrieveRemoteDocs, retrieveLocalClientDocs, getStoredLocalClientIds, saveLocalSpots, saveRemoteDocsSpots, getRemoteSpots, getLocalSpots, getClientId, registerClientUser, SlttAppStorageDisabledError, getAuthorizedStorageProjects } from './SlttAppStorage'
import { doOnceWhenBackOnline, getIsAppOnlineOrDefault, getIsAppOnlineOrWait, overridableUpdateOnlineStatus } from '../components/utils/ServiceStatus'
import { isHavingConnectionIssues, isNeedingLogout } from './API'
import { normalizeUsername } from './DBAcceptor.utils'
import { logDocSyncError } from '../components/utils/Errors'
import { delay } from '../components/utils/AsyncAwait'
import { generate4DigitHex } from './utils/hashUtils'
import { LocalDoc, LocalSpot } from './lanStorage/docs'

const intest = (localStorage.getItem('intest') === 'true')
export const acceptLocalThruKeyString = localStorage.acceptLocalThruKey
const acceptLocalThruKey = Number.parseInt(acceptLocalThruKeyString || '')

if (acceptLocalThruKeyString) {
    if (Number.isNaN(acceptLocalThruKey)) {
        throw Error(`acceptLocalThruKey must be a number: ${acceptLocalThruKeyString}`)
    }
}
log(`intest ${intest} acceptLocalThruKey ${acceptLocalThruKeyString}`)

const maxSeq = 9999999999

export const MAXTOSYNC = 10

// At the moment the backend Express server can only accept 100K bytes.
// We need to fix that.

const MAXTOSYNCSIZE = 80000

function isLocalBrowserKey(key: unknown) { return typeof key === 'number' && key >= minLocalSeq }

interface IAccept {
    label?: string,
    seq?: number | string
}

export type dbPutWithOnlyAcceptParam = {
    put: (doc: IDBModDoc, onlyAccept: boolean) => void
}

export class _LevelupDB implements IDB {
    public db: IDBOperations
    acceptor?: IDBAcceptor
    changeDeferrer: DBChangeDeferrer
    notifier?: DBChangeNotifier
    api: IDBSyncApi
    levelUpId =  generate4DigitHex()

    static forceSyncExceptionCount = 0

    // All documents in the LevelUp DB have a numeric keys.
    // Key values 0..minLocalSeq represent documents that exist in DynamoDB.
    //
    // Key values localSeqBase+1..localSeqLast represent documents that have been created
    // locally but not yet synced to DynamoDB.
    // These documents are deleted once they have been synced to DynamoDB.
    // Sync will return to us from DynamoDB a permanent copy of each of these documents
    // to store locally [provided conflict resolution does not cause a specific
    // update to be ignored]

    // Largest local key present in indexedDB.
    // minLocalSeq if none present.
    // Local keys start at minLocalSeq+1.
    // WARNING: This value may be updated in the middle of a sync operation.
    localSeqLast = minLocalSeq
    
    // Largest local key that has been sent to sync API.
    // When localSeqLast > localSeqBase there are local docs waiting to be synced to DynamoDB.
    localSeqBase = minLocalSeq

    // First local key present in IndexedDB found during initialization (acceptLocalDBRecords)
    // or found for first write in syncLANStorageDocs
    localLANKeyFirst?: string

    // Largest remote key that has been received from sync API (remote keys start at 1)
    remoteSeq = 0

    numPendingSyncs = 0

    // Don't do an upsync when this is > 0.
    // When Date.now() is larger than this, reset this to 0.
    retryUpsyncTime = 0

    upsyncCount = 0 // number of times upsync attempted
    downsyncCount = 0 // number of times downsync attempted
    upsyncDocCount = 0
    downsyncDocCount = 0
    deletedCount = 0

    delayBeforeSyncRetry = 5 * 60 * 1000 // 5 minutes

    systemError: (error: any) => void

    constructor(public name: string, public username: string, db: IDBOperations, api: IDBSyncApi) {
        dbg(`constructor ${name}`)
        this.db = db
        this.name = name
        this.changeDeferrer = new DBChangeDeferrer(this)
        this.doSync = this.doSync.bind(this)
        this.api = api

        this.systemError = (error: any) => { console.error(`### [${this.levelUpId} ${name}] System Error`, error) }
    }

    getMaxToSyncSize() {
        return MAXTOSYNCSIZE
    }

    getRemoteSeq() {
        return this.remoteSeq
    }

    /**
     * @param seqStart (exclusive)
     * @param seqEnd (inclusive)
     * @returns IDBObject[seq] where seqStart < seq <= seqEnd
     */
    async get(seqStart: number | string, seqEnd: number | string) {
        return await this.db.get(seqStart, seqEnd)
    }

    async initialize(acceptor: IDBAcceptor, progress: (message: string) => void): Promise<number> {
        this.acceptor = acceptor
        let benchMarkStart = Date.now()
        let dbRecordCount = await this.acceptLocalDBRecords(progress)
        await this.doSync(progress)
        doOnceWhenBackOnline(
            'setupDbChangeNotifierWebsocket',
            this.setupDbChangeNotifierWebsocket
        )
        let benchMarkEnd = Date.now()
        log(`_LevelupDB.initialize() finished in ${`${((benchMarkEnd - benchMarkStart)/1000).toFixed(2)}`}s`)
        return dbRecordCount
    }

    setupDbChangeNotifierWebsocket = () => {
        log(`_LevelupDB.setupDbChangeNotifierWebsocket()`, this.notifier ?? 'no notifier')

        // If, somehow, there was a previous notifier, disconnect it.
        this.notifier?.disconnect()

        if (!intest) {
            let onChange = async (maxseq: number) => {
                if (maxseq > this.remoteSeq) {
                    dbg('onChange', fmt({maxseq, remoteSeq: this.remoteSeq}))
                    await this.doSync()
                }
            }
            this.notifier = new DBChangeNotifier(this.name, onChange)
            this.notifier.requestNotifications()
        }
    }

    disconnect = () => {
        log(`_LevelupDB.disconnect()`, this.notifier ?? 'no notifier')
        this.notifier?.disconnect()
        this.notifier = undefined
    }

    /** Called once on initialize to accept all records in DB.
    * Side effect: sets localSeqBase, localSeqlast, remoteSeq
    */
    async acceptLocalDBRecords(progress: (message: string) => void): Promise<number> {
        let notifyLocalProgress = (percent: number) => {
            progress(t`Initializing ...` + `${percent.toFixed(1)}%`)
        }
        let percent = 0
        notifyLocalProgress(percent)
        let entries = await this.db.readDBRecords()
        reorderEntriesWithLANKeys(entries)

        let firstLocalKeyFound = false
        for (let i = 0; i < entries.length; ++i) {
            let entry = entries[i]
            if ((typeof entry.key === 'number' && entry.key <= 0) || !entry.doc || !entry.doc._id) {
                log('###BAD ENTRY', entry)
            }

            let { key } = entry

            if (typeof key === 'string') {
                // LAN key
                if (this.localLANKeyFirst === undefined) {
                    this.localLANKeyFirst = key
                }
            } else if (isLocalBrowserKey(key)) {
                /** 
                 * Each page load starts with a fresh localSeqBase (1000000000) by default.
                 * If on a previous page load we did not sync not all local records (e.g. leaving 1000000005 unsynced),
                 * we need to find the smallest local key that has not been synced yet
                 * and set localSeqBase to that key value - 1 (e.g. 1000000004) to ensure that the next sync
                 * starts with the first unsynced local record (e.g. 1000000005)
                 */
                if (!firstLocalKeyFound) { 
                    firstLocalKeyFound = true
                    this.localSeqBase = key - 1
                    console.log(`acceptLocalDBRecords [${this.levelUpId} ${this.name}]: set localSeqBase to ${this.localSeqBase} via first found local key ${key}`)
                }

                this.localSeqLast = key

                const { modEntry } = await this.tryMigration(entry)
                if (modEntry) {
                    entry = modEntry
                }
            } else {
                this.remoteSeq = key
            }

            if (i % 500 === 0) {
                let percent = 100 * i / entries.length
                await delay(1) // let ui redraw to show progress
                notifyLocalProgress(percent)
            }

            if (acceptLocalThruKey && typeof entry.key === 'number' && (entry.key > acceptLocalThruKey)) {
                if (entry.key === acceptLocalThruKey) {
                    log('acceptLocalDBRecords acceptLocalThruKey', { acceptLocalThruKey, acceptLocalThruKeyString, key, entry, })
                }
                continue // to allow remoteSeq to reflect latest remoteSeq
            }
            this.accept(entry.doc, {seq: key})
        }
        console.log(`acceptLocalDBRecords [${this.levelUpId}  ${this.name}]: localSeqBase ${this.localSeqBase} localSeqLast ${this.localSeqLast}`)

        return entries.length
    }

    accept(doc: any, arg?: IAccept) {
        let label = arg?.label ?? ''

        if (label) {
            dbg(`accept[${label}]`, doc._id)
        }
        let seq: number | string = arg?.seq ?? -1
        try {
            this.acceptor?.accept(doc, label, seq)
        } catch (error) {
            //!!! send .errors to remote log
            console.error(`_LevelupDB [${this.name}] accept seq ${seq} label '${label}' doc ${s(doc)}`, error)
        }
    }

    // Synchronize items in local DB with central  DB
    async doSync(progress?: (message: string) => void) {
        if (!this.api.loggedIn()) return

        // If we run multiple simultaneous sync we will get duplicate db records.
        // Just wait until we are done with this sync.
        if (this.numPendingSyncs > 0) {
            return
        }

        this.numPendingSyncs++
        const syncingMessage = t`Syncing`
        progress?.(syncingMessage)

        try {
            if (isSlttAppStorageEnabled()) {
                await this.syncLANStorageDocs(progress)
            }
            if (await getIsAppOnlineOrWait()) {
                // if there's an internet connection, then do apiSync
                progress?.(syncingMessage)
                await this.syncDocs()
            } else {
                // when internet comes back, try doSync() again
                doOnceWhenBackOnline('doSync', this.doSync)
            }
        } catch (error) {
            if (isHavingConnectionIssues(error)) {
                // set offline status and when internet comes back, try doSync() again
                await overridableUpdateOnlineStatus(false)
                doOnceWhenBackOnline('doSync', this.doSync)
            } else if (isNeedingLogout(error) || !this.api.loggedIn()) {
                this.systemError(error)
                // allow user to be logged out
            } else {
                this.systemError(error)
                // report to rollbar, if we haven't already done so
                if (isNeedingReporting(error) && getIsAppOnlineOrDefault(true)) {
                    let docs: IDBModDoc[] = []
                    try {
                        const { docs: sliceDocs } = await this.getDocsSlice()
                        docs = sliceDocs
                        // TODO: try again when back online?
                        logDocSyncError(error as Error, docs, this.name, this.levelUpId, false)
                    } catch (e) {
                        this.systemError(e)
                    }
                }
                this.setupRetryTimeout()
            }
        } finally {
            this.numPendingSyncs--
        }

        if (isSlttAppStorageEnabled()) {
            const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
            if (authorizedStorageProjects.has(this.name)) {
                // if there are unsynced local browser docs, then backup to LAN local storage
                await this.storeLocalBrowserDocsToLANStorage(progress)
            }
        }
    }

    async syncLANStorageDocs(progress: ((message: string) => void) | undefined) {
        let allEntries: DBEntry[] = []

        const getAllEntries = async (): Promise<DBEntry[]> => {
            if (allEntries.length === 0) {
                allEntries = await this.db.readDBRecords()
            }
            return allEntries
        }

        try {
            const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
            if (!authorizedStorageProjects.has(this.name)) {
                return
            }
            const originalDbCount = await this.db.count() // ie. was database empty before sync?
            await registerClientUser({ username: this.username }, '_LevelupDB.doSync(): LAN storage')
            await this.syncRemoteLANStorageDocs(getAllEntries, progress)
            await this.syncLocalLANStorageDocs(getAllEntries, progress, originalDbCount === 0)
        } catch (error) {
            this.systemError(error)
            if (error instanceof SlttAppStorageDisabledError) {
                // if getHasLostConnection() fails then SlttAppStorage will become disabled,
                // so avoid calling other storage api functions
                return
            }
        }
    }

    private async syncRemoteLANStorageDocs(
        getAllEntries: () => Promise<DBEntry[]>,
        progress: ((message: string) => void) | undefined,
    ) {
        const logContextRemoteDocs = '_LevelupDB.doSync(): LAN remote docs'
        progress?.(t`Syncing LAN remote docs ...`)
        let remoteSpots = await getRemoteSpots({ project: this.name }, logContextRemoteDocs)

        log(logContextRemoteDocs)
        const lastSpotSeqOrig = remoteSpots!['last']?.seq ?? 0
        if (this.remoteSeq === 0 && lastSpotSeqOrig > 0) {
            // special case when user has deleted the database: reset our spots
            remoteSpots = {}
        }
        const spot = remoteSpots!['last']
        const remoteDocsResponse = await retrieveRemoteDocs(
            { project: this.name, spot },
            logContextRemoteDocs
        )
        // save spot
        if (remoteDocsResponse) {
            await saveRemoteDocsSpots(
                {
                    project: this.name, spots: { 'last': remoteDocsResponse.spot }
                }, logContextRemoteDocs)
        }
        const lastLANRemoteSeq = remoteDocsResponse?.seqDocs.slice(-1)[0]?.seq ?? 0
        if (lastLANRemoteSeq > this.remoteSeq) {
            const newRemoteSeqDocs = remoteDocsResponse?.seqDocs
                .filter((item) => item.seq > this.remoteSeq) ?? []
            const newRemoteItems = newRemoteSeqDocs.map((seqDoc) => ({ project: this.name, doc: seqDoc.doc, seq: seqDoc.seq }))
            await this.acceptRemoteItems(newRemoteItems, true)
        } else {
            await this.storeRemoteBrowserDocsToLANStorage(
                getAllEntries,
                lastLANRemoteSeq,
                progress
            )
        }
    }

    private async syncLocalLANStorageDocs(
        getAllEntries: () => Promise<DBEntry[]>,
        progress: ((message: string) => void) | undefined,
        resetSpots = false
    ) {
        progress?.(t`Syncing LAN local docs ...`)
        const logContextLocalDocs = '_LevelupDB.doSync(): LAN local docs'
        log(logContextLocalDocs)
        const clientIds = await getStoredLocalClientIds({ project: this.name }, logContextLocalDocs)
        if (clientIds.length > 0) {
            const allEntries = await getAllEntries()
            const latestCachedUpdatesById = new Map(allEntries.map(
                entry => [
                    entry.doc._id,
                    {
                        seq: entry.key,
                        modDate: entry.doc.modDate
                    }
                ]))
            const localDocs: LocalDoc<IDBModDoc>[] = []
            let savedSpots = await getLocalSpots({ project: this.name }, logContextLocalDocs)
            if (resetSpots) {
                // special case when user has deleted the database: reset our spots
                savedSpots = {}
            }
            const spots: LocalSpot[] = []
            for (let i = 0; i < clientIds.length ; i++) {
                // TODO: calculate progress
                const clientId = clientIds[i]
                const clientSpot = (savedSpots['last'] || []).find(spot => spot.clientId === clientId)
                // NOTE: winningIncomingLocalClientDocs should exclude even our own already stored
                const localClientDocsResponse = await retrieveLocalClientDocs(
                    { localClientId: clientId, project: this.name, spot: clientSpot },
                    '_LevelupDB.doSync(): LAN local docs'
                )
                if (!localClientDocsResponse) continue
                const { localDocs: incomingLocalClientDocs, spot } = localClientDocsResponse
                const winningIncomingLocalClientDocs = incomingLocalClientDocs.filter(localDoc => {
                    const cachedEntry = latestCachedUpdatesById.get(localDoc.doc._id)
                    if (cachedEntry && cachedEntry.modDate >= localDoc.doc.modDate) {
                        log(`_LevelupDB.doSync() incoming local doc not later than cached mod doc (${fmt(cachedEntry)})`, fmt(localDoc))
                        return false
                    }
                    return true
                })
                localDocs.push(...winningIncomingLocalClientDocs)
                spots.push(spot)
            }
            // save spots
            await saveLocalSpots(
                { project: this.name, spots: { 'last': spots } },
                logContextLocalDocs
            )
            const sortedIncomingLocalDocs = sortBy(localDocs, localDoc => localDoc.doc.modDate)
            const newLocalItems = sortedIncomingLocalDocs.map(localDoc => (
                { project: this.name, doc: localDoc.doc, seq: transformDocToLANKey(localDoc.clientId, localDoc.doc) }))
            
            if (newLocalItems.length > 0) {
                await this.db.writeItems(newLocalItems)
            }
            if (this.localLANKeyFirst === undefined && newLocalItems.length > 0) {
                this.localLANKeyFirst = newLocalItems[0].seq as string
            }
            newLocalItems.forEach(item => this.accept(item.doc, { seq: item.seq }))
        } else {
            this.storeLANBrowserDocsToLANStorage(
                getAllEntries,
                progress
            )
        }
    }

    /**
     * LAN storage can lack browser cached remote docs, e.g.
     * if browser local docs got migrated to LAN storage (while offline)
       if the client then got disconnected from LAN storage but synced with remote server (back online)
       then, the new remote docs were not stored in LAN storage, so we need to do so now.
     * @param seqDocs
     * @param lastLANRemoteSeq 
     * @param progress 
     * @returns 
     */
    async storeRemoteBrowserDocsToLANStorage(
        fnGetAllEntries: () => Promise<DBEntry[]>,
        lastLANRemoteSeq: number,
        progress: ((message: string) => void) | undefined) {
        
        const newSeqDocs = (await fnGetAllEntries())
            .filter(entry => typeof entry.key === 'number' && entry.key < minLocalSeq && entry.key > lastLANRemoteSeq && entry.doc)
            .map(entry => ({ doc: entry.doc, seq: entry.key as number }))
        if (newSeqDocs.length > 0) {
            await storeRemoteDocs({ project: this.name, seqDocs: newSeqDocs }, '_LevelupDB.storeRemoteBrowserDocsToLANStorage()')
        }
    }

    /**
     * LAN storage can lack browser LAN docs, e.g. 
     * if they were deleted from the LAN storage
        or client connected to a different LAN storage device
     * @param allLocalLANfilenames
     * @param progress 
     */
    async storeLANBrowserDocsToLANStorage(
        fnGetAllEntries: () => Promise<DBEntry[]>,
        progress: ((message: string) => void) | undefined
    ) {
        if (this.localLANKeyFirst === undefined) return
        // we have localLAN keys but no clientIds. This indicates the LAN storage has changed
        // (either the client has connected to a different LAN storage device or the LAN storage has been cleared)
        // store browser cached LAN docs to the fresh LAN storage
        // Restore all local LAN data missing from LAN storage, even for other clients,
        // but treat them as if they were from our client in {our-client}.sltt-docs 
        // (this avoids a race-condition if multiple clients try to re-write {each-client}.sltt-docs) 
        // to restore this data)
        // The downside of storing all data under our clientId, 
        // is that the client data stored on disk will no longer match what we have stored in the browser cache.
        // when other clients connect to the LAN storage, they will try to fetch what appears to be data from us
        // but hopefully the logic that tries to avoid loading duplicate or outdated data will prevent any issues
        // Also the modBy will be out of sync with previously registered users for that client, 
        // but hopefully that's not the end of the world, since we store that info separately (although those users will 
        // need to be re-registered for us to know for sure).
        const newLocalDocs = (await fnGetAllEntries())
            .filter(entry => typeof entry.key === 'string')
            .map(entry => entry.doc)
        if (newLocalDocs.length === 0) return
        await storeLocalDocs({ project: this.name, docs: newLocalDocs }, '_LevelupDB.storeLANBrowserDocsToLANStorage()')
    }

    async storeLocalBrowserDocsToLANStorage(progress: ((message: string) => void) | undefined) {
        const localSeqBase = this.localSeqBase
        const localSeqLast = this.localSeqLast
        progress?.(t`Storing browser local docs to LAN local storage ... `)
        const docs = await this.db.get(localSeqBase, localSeqLast)
        // TODO: should we avoid storing docs for _ids that are already in LAN storage with a later modDate?
        // or is it okay to back them up before deleting them?
        await storeLocalDocs({ project: this.name, docs }, '_LevelupDB.storeLocalBrowserDocsToLANStorage()')
        // TODO: should we assume success means they were stored? Or should we retrieve them to confirm?
        // if we retrieve them, we should save our spot so we can ignore them in the future
        const migratedLANLocalItems = docs.map(doc => ({ project: this.name, seq: transformDocToLANKey(getClientId(), doc), doc }))
        await this.db.writeItems(migratedLANLocalItems)
        await this.deleteLocalDocs(localSeqBase, localSeqLast, '')
    }

    // Sync docs with remote server.
    // Do upsync in modest size chunks to stay within server request size limit.
    // Repeat down sync if we got a lot of records, because there may be more to get.
    async syncDocs() {
        let lastLanKey = ''
        while (true) {
            let { localSeqBase, localSeqLast  } = this
            dbg(`syncDocs`, fmt({ localSeqBase, localSeqLast, retryUpsyncTime: this.retryUpsyncTime }))

            if (!this.api.loggedIn()) break // no use trying to sync if not logged in

            if (this.retryUpsyncTime <= Date.now()) {
                dbg('syncDocs retry upsync')
                this.retryUpsyncTime = 0
            }

            if (this.retryUpsyncTime === 0) { // no nonretirable error in progress
                ++this.upsyncCount
                const { docs, lastSeq, lastLanKey: lastLanKeyFromSlice } = await this.getDocsSlice()
                if (lastLanKeyFromSlice) {
                    lastLanKey = lastLanKeyFromSlice
                }
                // If requested from console, force N sync errors.
                // In console: window._LevelupDB.forceSyncExceptionCount = 1000
                if (_LevelupDB.forceSyncExceptionCount > 0) { 
                    --_LevelupDB.forceSyncExceptionCount
                    log('syncDocs test error handling')
                    throw Error(TEST_ERROR_HANDLING_MESSAGE)
                }

                if (this.api.blockServerUpdates() && docs.length > 0) {
                    log(`### Aborting sync: API.blockServerUpdates() is true. There are ${docs.length} doc(s) in ${this.fullDbName}, e.g. [${lastSeq}: ${docs.slice(-1)[0]._id}]`)
                    return
                }
                const { error, newItems } = await this.apiSync(docs) // This will set retryUpsyncTime if there is a nonretriable upsync error.
                if (!error) {
                    await this.acceptRemoteItems(newItems)
                    await this.deleteLocalDocs(localSeqBase, lastSeq, lastLanKey)

                    if (newItems.length > 100) { continue /* repeat sync loop, likely more downsync to do  */ }
                    // since localSeqBase can be >= this.localSeqLast (thus would otherwise break out of loop)
                    // yet getDocsSlice() could still have more LAN docs to upsync...
                    if (docs.length >= MAXTOSYNC) { continue /* repeat sync loop, likely more upsync to do */ }
                }
            }

            if (this.retryUpsyncTime > 0) { // nonretriable error in progress
                ++this.downsyncCount

                const { error, newItems } = await this.apiSync([]) // do downsyc only

                if (!error) {
                    await this.acceptRemoteItems(newItems)
                }
                if (newItems.length > 100) { continue /* repeat sync loop, likely more downsync to do */ }
            }

            if (this.retryUpsyncTime > 0) { break }
            if (this.localSeqBase >= this.localSeqLast) { break }
        }
    }

    async tryMigration(originalEntry: DBEntry): Promise<{ modEntry: DBEntry | undefined }> {
        if (!isLocalBrowserKey(originalEntry.key)) { return { modEntry: undefined } }
        // create modEntry only if we need to modify the entry (preserve performance)
        // clone it from originalEntry to avoid modifying the original entry
        let modEntry: DBEntry | undefined = undefined
        const getModEntry = () => {
            if (!modEntry) {
                modEntry = JSON.parse(JSON.stringify(originalEntry))
            }
            return modEntry!
        }
        
        if (!originalEntry.doc.modBy) {
            // all new upsynced docs are expected to have a modBy field
            const modBy = normalizeUsername(this.username) // WARNING: if new user logged in, this user could potentially
            const modEntry = getModEntry()                 // be a different user, if an old DBChangeNotifier is using an old _LevelupDB.
            modEntry.doc.modBy = modBy                     // see https://github.com/ubsicap/sltt/issues/916
            console.log(`tryMigration: added modBy '${modBy}' to entry (${modEntry.key}, ${modEntry.doc._id})`)
        }
        if (originalEntry.doc._id === 'project' && 'projectBookName' in originalEntry.doc) {
            // cause of `/sync ERROR: Error: "members" field missing`
            // see https://app.rollbar.com/a/biblesocieties/fix/item/SLTT/248
            /* {
                    "_id": "project", // --> "teamPreferences" 
                    "creator": "caio.cascaes@gmail.com",
                    "creationDate": "2024/02/26 18:33:32.399Z",
                    "modDate": "2024/02/26 18:37:48.026Z",
                    "bbbccc": "001",
                    "projectBookName": "1 Mosebog"
                }, 
            */
            const modEntry = getModEntry();
            (modEntry.doc as any)._id = 'teamPreferences'
            console.log(`tryMigration: changed _id from 'project' to 'teamPreferences' in entry (${modEntry.key}) doc: ${s(modEntry.doc)}`)
        }
        if (!!modEntry) {
            const modEntry = getModEntry()
            await this.db.put(modEntry.key, modEntry.doc)
        }
        return { modEntry }
    }

    setupRetryTimeout() {
        if (this.retryUpsyncTime > 0) { return } // avoid multiple timers
        if (!this.api.loggedIn()) { return } // no use trying to sync if not logged in
        if (this.api.blockServerUpdates()) { return } // no use trying to sync if blockServerUpdates is true
        if (!getIsAppOnlineOrDefault(false)) { return } // no use trying to sync until online
        // Record the fact that future upsyncs are likely to fail.
        this.retryUpsyncTime = Date.now() + this.delayBeforeSyncRetry

        // In 5 minutes retry the sync in case something has happened to resolve the error.
        // We wait just a little longer than the delayBeforeRetry to ensure that the
        // time check in the main loop realizes we are past the delayBeforeRetry time.
        setTimeout(() => { 
            this.doSync().catch(this.systemError) 
        }, 1.1*this.delayBeforeSyncRetry)
    }

    async apiSync(docs: any[]) {
        try {
            const newItems = await this.api.sync(this.name, docs, this.remoteSeq, this.levelUpId)
            this.downsyncDocCount += newItems.length
            this.upsyncDocCount += docs.length

            return { error: null, newItems }
        } catch (_error) {
            if (isHavingConnectionIssues(_error) ||
                isNeedingLogout(_error) || 
                isNeedingReporting(_error)) {
                // offline...no point in retrying until back online
                // or user needs to login before syncing again
                // or error still needs to be reported before setupRetryTimeout() is called
                throw _error
            }
            const error = _error as Error
            this.setupRetryTimeout()
            return { error, newItems: [] }
        }
    }

    async deleteLocalDocs(localSeqBase: number , seqLast: number, lanKeyLast: string) {
        for (let i = localSeqBase+1; i <= seqLast; ++i) {
            dbg(`deleteLocalDocs[${i}]`)
            await this.db.deleteDoc(i)
            this.deletedCount++
        }

        // Record the fact that we have successfully sent local records to sync api.
        // The Math.max is probably a nop.
        // It does make sure that we never move localSeqBase backwards 
        // ... because that could result in us upsyncing the same records again.
        this.localSeqBase = Math.max(seqLast, this.localSeqBase)
        console.log(`deleteLocalDocs [${this.levelUpId}  ${this.name}]: set localSeqBase to ${this.localSeqBase} after deleting local docs ${localSeqBase+1} to ${seqLast}`)
        if (lanKeyLast) {
            // NOTE: While acceptRemoteItems() also handles deletion of LAN local keys (after downsync)
            // However, there may be a lot of remote items to downsync before receiving the newly upsynced lan docs
            // Furthermore, the server may quietly ignore some of the upsynced docs due to conflict resolution
            // on the same _id. To avoid trying to upsync the same docs over and over 
            // it's probably best to delete the LAN local keys here and trust we will
            // downsync them later
            let iterator = await this.db.getIterator('')
            try {
                let nextItem = await iterator.next()
                while(nextItem.key !== undefined && nextItem.key !== null && nextItem.key <= lanKeyLast) {
                    await this.db.deleteDoc(nextItem.key)
                    nextItem = await iterator.next()
                }
            } finally {
                await iterator.end()
            }
            await this.db.deleteDoc(lanKeyLast)
        }
        dbg('deleteLocalDocs done', fmt({ localSeqBase: this.localSeqBase, seqLast, lanKeyLast }))
    }

    acceptRemoteItems = async (items: IRemoteDBItem[], skipWriteToDisk: boolean = false) => {
        if (items.length === 0) return

        let lastSeq = items.slice(-1)[0].seq
        dbg(`acceptRemoteItems`, fmt({ lastSeq, items: items.length, remoteSeq: this.remoteSeq }))
        dbg(`acceptRemoteItems`, items)

        await this.db.writeItems(items)
        this.remoteSeq = lastSeq

        items.forEach(item => this.accept(item.doc, {seq: item.seq}))
        if (isSlttAppStorageEnabled()) {
            const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
            if (!authorizedStorageProjects.has(this.name)) {
                return
            }
            !skipWriteToDisk && await storeRemoteDocs({ project: this.name, seqDocs: items.map(item => ({ seq: item.seq, doc: item.doc })) }, '_LevelupDB.acceptRemoteItems()')
            const clientIds = await getStoredLocalClientIds({ project: this.name }, '_LevelupDB.acceptRemoteItems()')
            for (const item of items) {
                if (!item.doc.modBy) continue // remote doc before modBy was added (before LAN storage)
                // TODO: alternatively, we could get all our lan keys and figure out which clients apply
                for (let clientId of clientIds) {
                    const lanKey = transformDocToLANKey(clientId, item.doc)
                    await this.db.deleteDoc(lanKey) // delete any LAN local key for any client
                }
            }
        }
    }

    /**
     * It should not be possible to create a big doc, but if so truncate it so
     * that it does not crash the sync process.
     */
    limitDocSize(doc: any, docs: IDBModDoc[]): number {
        const length = 2*JSON.stringify(doc).length // 2* because of utf-16
        if (length < this.getMaxToSyncSize()) return length
        
        const copyOfDoc = JSON.parse(JSON.stringify(doc))
        // This assumes that the size problem in the text or src fields.
        doc.text = ''
        doc.src = ''
        doc.error = "*TOO BIG*"

        const newLength = 2*JSON.stringify(doc).length

        logDocSyncError(
            new Error(
                `${NonretriableSyncFailure}: unexpected big doc (${length} >= ${MAXTOSYNCSIZE} bytes) in getDocsSlice()...truncating last doc to ${newLength} bytes`
            ),
            [...docs, { beforeTruncation: copyOfDoc, afterTruncation: doc }], this.name, this.levelUpId, false, false
        )
        return newLength
    }

    /**
     * Return a slice of (local) docs to (up)sync starting at localSeqBase+1.
     * side-effect: possibly updates this.localSeqBase, this.localSeqLast
     * Must not be more than MAXTOSYNC docs.
     * Must not be more than MAXTOSYNCSIZE bytes.
     */
    async getDocsSlice() {
        const docs: IDBModDoc[] = []
        const items: IDBItem[] = []
        const localSeqLastOriginal = this.localSeqLast // this.localSeqLast can be go up at any time (via this.put())
        const localSeqBaseOriginal = this.localSeqBase
        let lastSeqInSlice = minLocalSeq // assume we may not find any in slice
        let lastLanKeyInSlice: string = ''
        let totalLength = 0

        const getDocSizeOrError = (seq: number | string, doc: IDBModDoc | undefined, totalLength: number, docs: IDBModDoc[]): 
            { docSize: number, flowRedirection: 'continue' | 'break' | 'none' } =>
        {
            if (!doc || !doc._id) {
                log(`###BAD DOC`, fmt({ seq, doc }))
                logDocSyncError(
                    new Error(
                        `${NonretriableSyncFailure}: unexpected bad doc in getDocsSlice(): {seq: ${seq}, doc: ${JSON.stringify(nextItem.value)}}`
                    ),
                    docs, this.name, this.levelUpId, false, false
                )
                return { docSize: 0, flowRedirection: 'continue' }
            }
            const length = this.limitDocSize(doc, docs)
            const newTotalLength = totalLength + length
            if ((newTotalLength) > MAXTOSYNCSIZE) {
                if (length > MAXTOSYNCSIZE) {
                    // truncation failed to reduce size...we've already reported in limitDocSize() so just continue
                    this.systemError(new Error(`### getDocsSlice(): limitDocSize failed to truncate enough`))
                    return { docSize: length, flowRedirection: 'continue' }
                } else {
                    console.log(`getDocsSlice(): totalLength ${newTotalLength} > ${MAXTOSYNCSIZE} bytes`)
                    // the length of this doc will exceed the total limit, so break
                    return { docSize: length, flowRedirection: 'break' }
                }
            }
            return { docSize: length, flowRedirection: 'none' }
        }

        const selfHealingSideEffectsEdgeCases = (
            { firstFoundLocalSeq, lastSeqInSlice, docs, localSeqBaseOrig, localSeqLastOrig }: { firstFoundLocalSeq: number, lastSeqInSlice: number, docs: IDBModDoc[], localSeqBaseOrig: number, localSeqLastOrig: number }) => {
            // (if somehow got out of sync (e.g. competing indexdb usage), fix now)
            // see https://github.com/ubsicap/sltt/issues/938 and
            // see https://github.com/ubsicap/sltt/issues/916
            const foundNoDocs = docs.length === 0 && localSeqBaseOrig !== localSeqLastOrig
            if (foundNoDocs) {
                const error = new Error(`### getDocsSlice(): unexpectedly found no docs with localSeqBaseOrig (${localSeqBaseOrig}) localSeqLastOrig (${localSeqLastOrig})`)
                logDocSyncError(error, docs, this.name, this.levelUpId, false, false)
                this.systemError(error)
            }
            if (firstFoundLocalSeq > minLocalSeq && localSeqBaseOrig !== (firstFoundLocalSeq - 1)) {
                // somehow got out of sync, fix now
                const error = new Error(`### getDocsSlice(): localSeqBaseOrig (${localSeqBaseOrig}) !== firstLocalSeq - 1 (${firstFoundLocalSeq} - 1)`)
                logDocSyncError(error, docs, this.name, this.levelUpId, false, false)
                this.systemError(error)
                this.localSeqBase = firstFoundLocalSeq - 1 // fix just in case
                console.log(`getDocsSlice() [${this.levelUpId}  ${this.name}]: reset localSeqBase to firstFoundLocalSeq - 1 (${firstFoundLocalSeq - 1})`)
            } else if (foundNoDocs) {
                // couldn't find any docs to sync, so reset to localSeqLastOrig
                // this should also skip bad docs and oversized docs
                // NOTE: we don't want to reset to this.localSeqLast because new docs could have been added
                // while we were processing this getDocsSlice(). We DON'T want to skip those docs.
                this.localSeqBase = localSeqLastOrig
                console.log(`getDocsSlice() [${this.levelUpId}  ${this.name}]: reset localSeqBase to localSeqLastOrig (${localSeqLastOrig})`)
            }
            // this.localSeqLast
            this.localSeqLast = Math.max(this.localSeqLast, localSeqLastOrig, lastSeqInSlice, firstFoundLocalSeq)
            if (this.localSeqLast !== localSeqLastOrig) {
                console.log(`getDocsSlice() [${this.levelUpId}  ${this.name}]: reset localSeqLast to max of localSeqLastOrig (${localSeqLastOrig}), lastSeqInSlice (${lastSeqInSlice}), firstFoundLocalSeq (${firstFoundLocalSeq})`)
            }
        }

        // Get any LAN docs that have not been synced to remote
        // NOTE: LAN local docs (string keys) will be stored after all number keys (remote and local browser keys)
        // therefore, try to get the first key after all number keys 
        let dbIterator = await this.db.getIterator('') // first string key
        let processingLANKeys = true
        type ResolvedType<T> = T extends PromiseLike<infer U> ? U : T;
        let nextItem: ResolvedType<ReturnType<IDBIterator['next'] | IDBIterator['end']>>

        const getNextItem = async () => {
            let nextItem = await dbIterator.next()
            if (nextItem.key === undefined && processingLANKeys) {
                // Switch to processing number keys if no more string keys
                // always start from minLocalSeq and fix localSeqBase if needed
                // see https://github.com/ubsicap/sltt/issues/938
                processingLANKeys = false
                await dbIterator.end()
                dbIterator = this.db.getIterator(minLocalSeq)
                nextItem = await dbIterator.next()
            }
            if (!processingLANKeys && typeof nextItem.key === 'string') {
                return await dbIterator.end()
            }
            return nextItem
        }

        let firstLocalSeq = minLocalSeq
        
        try {
            for (
                nextItem = await getNextItem();
                (nextItem.key !== undefined && nextItem.key !== null) && (docs.length < MAXTOSYNC);
                nextItem = await getNextItem()
            ) {
                if (typeof nextItem.key === 'number' && firstLocalSeq === minLocalSeq) {
                    firstLocalSeq = nextItem.key
                }
                const doc = nextItem.value!
                const { docSize, flowRedirection } = getDocSizeOrError(nextItem.key, doc, totalLength, [...docs])
                if (flowRedirection === 'break') break
                if (flowRedirection === 'continue') continue
                totalLength += docSize

                docs.push(doc)
                items.push({ project: this.name, seq: nextItem.key, doc: doc })
                if (typeof nextItem.key === 'number') {
                    lastSeqInSlice = nextItem.key
                } else {
                    lastLanKeyInSlice = nextItem.key
                }
            }
    
        } finally {
            await dbIterator.end()
        }

        selfHealingSideEffectsEdgeCases({
            firstFoundLocalSeq: firstLocalSeq, lastSeqInSlice, docs,
            localSeqBaseOrig: localSeqBaseOriginal, localSeqLastOrig: localSeqLastOriginal
        })

        // in case lastSeqInSlice was not processed during this getDocsSlice()
        // (e.g. if only LAN docs were processed) reset lastSeq to this.localSeqBase
        const lastSeq = Math.max(lastSeqInSlice, this.localSeqBase)

        // This should never happen.
        // However if it did we would be stuck in a loop of trying to sync the same records.
        if (docs.length === 0 && lastSeq < localSeqLastOriginal) {
            logDocSyncError(
                new Error(
                    `${NonretriableSyncFailure}: unexpected lastSeqInSlice ${lastSeqInSlice} lastSeq ${lastSeq} in getDocsSlice, no docs found in range ${this.localSeqBase + 1}..${localSeqLastOriginal}`
                ),
                docs, this.name, this.levelUpId, true, true
            )
            this.setupRetryTimeout()
        }

        return { docs, lastSeq, lastLanKey: lastLanKeyInSlice, items }
    }

    put = async (doc: IDBModDoc, onlyAccept: boolean = false, skipWriteToDisk: boolean = false) => {
        if (onlyAccept || this.api.blockServerUpdates()) {
            this.accept(doc)
            return
        }

        let { db } = this
        this.localSeqLast += 1
        console.log(`put [${this.levelUpId}  ${this.name}]: localSeqLast ${this.localSeqLast} localSeqBase ${this.localSeqBase} _id ${doc._id}`)
        dbg('put', fmt({localSeqlast: this.localSeqLast}))
        await db.put(this.localSeqLast, doc)

        let seq = this.localSeqLast
        this.accept(doc, {label: `put ${seq}`, seq})

        this.doSync().catch(this.systemError) // do not wait for sync to finish
    }

    /**
     * Some operations, such as dragging a gloss, create a lot of potential changes to the db.
     * For these operations do not commit a change until we go 5 seconds without getting
     * a new value and then commit only the latest value.
     */
    submitChange(doc: IDBObject) {
        if (this.api.blockServerUpdates()) {
            this.accept(doc)
        } else {
            let seq = this.localSeqLast + 1
            this.accept(doc, {label: `put ${seq}`, seq})
            this.changeDeferrer.submitChange(doc)
        }
    }

    static lastId = ''

    static dateToId(date: Date, tag: string = '') {
        // When running tests use a constant time stamp
        if (intest) {
            return tag + '190101_020304'
        }

        let _id = date.toISOString().slice(2, -5)
        _id = _id.replace('T', '_')
        _id = _id.replace(/\-/g, '')
        _id = _id.replace(/:/g, '')

        let lastId = _LevelupDB.lastId
        if (lastId >= _id) {
            let parts = lastId.split('_')
            let newTime = (parseInt(parts[1]) + 1).toString()
            _id = parts[0] + '_' + newTime.padStart(6, '0')
        }

        _LevelupDB.lastId = _id

        return tag + _id
    }

    getNewId(existing: any[], date: Date, tag: string = '') {
        // Get the last part of the _id for each existing object
        const oldIds = existing.map((dbObj: any) => dbObj._id?.split('/').slice(-1)[0])
        let newId = _LevelupDB.dateToId(date, tag)

        // It is possible, although unlikely, that the newId is already in use.
        // Keep incrementing the hhmmss at end of _id until we get a unique _id.
        // Some unit test cases cause this to happen all the time by freezing the date with a mock.
        while (oldIds.includes(newId)) {
            const parts = newId.split('_')
            const i = parts.length - 1 // index of hhmmss number
            parts[i] = (parseInt(parts[i]) + 1).toString().padStart(6, '0')
            const _newId = parts.join('_')

            if (newId === _newId) {
                throw new Error('getNewId failed') // should never happen, but if so, break out of loop
            }
            newId = _newId
        }

        return newId
    }
    
    async delete(doc: any /* really IDBObject */) {
        doc.removed = true
        await this.put(doc)
    }

    // async getOne(_id: string) {
    //     return this.db.get(_id)
    // }
    
    getDate() {
        if (intest) {
            return '2019/09/10 11:12Z'
        }

        return _LevelupDB.getDate()

    }

    // Returns a UMT date with format 2020/10/03 19:01:14.093Z
    // WARNING: we use this date to determine in the back end what change is the latest.
    // Changing the format of this will likely cause data loss due to items being judged outdated
    // and being discarded.
    static getDate() {
        return convertToCanonicalDateTimeFormat(new Date(Date.now()))
    }

    cancel() {
    }
    
    slice() {
        throw Error('Only supported in _MemoryDB for unit testing')
        return []
    }
    
    reset(nextId: number) {
        throw Error('Only supported in _MemoryDB for unit testing')
    }

    get fullDbName() { return `level-js-${this.name}-db` }

    async deleteDB(): Promise<void> {
        await this.db.close()
        return new Promise((resolve, reject) => {
            let deleteRequest = indexedDB.deleteDatabase(this.fullDbName)

            deleteRequest.onerror = event => { reject('Error deleting db') }

            deleteRequest.onsuccess = event => { resolve() }

            deleteRequest.onblocked = event => { reject('Delete db request blocked') }
        })
    }
}

interface IChange {
    doc: any,
    timer: null | ReturnType<typeof setTimeout>
}

// Batch all db changes that update the same field(s) of the same object and debounce them.
class DBChangeDeferrer {
    private changes: IChange[] = []

    constructor(private db: _LevelupDB) {
        this.submitChange = this.submitChange.bind(this)
        this.commitChange = this.commitChange.bind(this)
    }

    submitChange(doc: any) {
        // Two changes are equivalent if they have the same _id and all the field
        // names are the same
        function sameItem(doc2: any) {
            if (doc._id !== doc2._id) return false
            
            // A request to remove should we replace any other request.
            // Otherwise the item flashes back into existence when the original commit happens
            // and then re-disappears when the removal commit happens.
            if (doc.removed) return true
            
            let existingDocKeys = Object.keys(doc2).sort()
            let docKeys = Object.keys(doc).sort()
            return JSON.stringify(existingDocKeys) === JSON.stringify(docKeys)
        }

        let existingIndex = this.changes.findIndex(item => sameItem(item.doc))

        if (existingIndex > -1) {
            dbg('submitChange replacement', JSON.stringify(doc))
            let existing = this.changes[existingIndex]
            existing.timer && clearTimeout(existing.timer)
            existing.timer = setTimeout(() => this.commitChange(doc), 5000)
            existing.doc = doc
        } else {
            dbg('submitChange', JSON.stringify(doc))
            let timer = setTimeout(() => this.commitChange(doc), 5000)
            this.changes.push({ doc, timer })
        }
    }

    private async commitChange(doc: any) {
        dbg('commitChange', JSON.stringify(doc))
        await this.db.put(doc) // <-- _LevelupDB.put()

        let index = this.changes.findIndex(c => c.doc === doc)
        if (index > -1) {
            this.changes.splice(index, 1)
        } else {
            log('### commitChange doc not in changes!')
        }
    }
}

const TEST_ERROR_HANDLING_MESSAGE = '!!! TEST ERROR HANDLING'

const isNeedingReporting = (error: unknown) => (error instanceof Error) &&
    (!error.message.startsWith(NonretriableSyncFailure) &&
        error.message !== TEST_ERROR_HANDLING_MESSAGE)

/**
 *  typically (for sltt-pwa) entries are sorted by number
    however, if sltt-pwa has connected to a LAN proxy server, then
    it may also have LAN keys (string)
    If so, re-order in-place so that remote (global seq number) keys are first,
    then LAN keys (string), then isLocalBrowserKey (large number)
 * @param entries 
 * @returns 
 */
function reorderEntriesWithLANKeys<T>(entries: DBEntry[]): void {
    if (typeof entries.slice(-1)[0]?.key === 'number') {
        // no string keys so no need to reorder
        return
    }
    const firstLargeKeyIndex = entries.findIndex(
        entry => isLocalBrowserKey(entry.key)
    )
    const firstStringKeyIndex = entries.findIndex(
        entry => typeof entry.key === 'string'
    )

    if (firstLargeKeyIndex === -1 || firstStringKeyIndex === -1) {
        // If there are no large keys or no string keys, so don't reorder
        return
    }

    const stringKeys = entries.splice(firstStringKeyIndex)
    entries.splice(firstLargeKeyIndex, 0, ...stringKeys)
}

// Returns a UMT date with format 2020/10/03 19:01:14.093Z
export function convertToCanonicalDateTimeFormat(date: Date) {
    let iso = date.toISOString()

    iso = iso.replace('-', '/')
    iso = iso.replace('-', '/') // replace second occurence of -
    iso = iso.replace('T', ' ')

    return iso
}

export function transformDocToLANKey(clientId: string, doc: IDBModDoc): string {
    const lanKey = `${doc.modDate}__${clientId}__${doc.modBy}__${doc._id}`
    return lanKey
}

const _window = window as any
// allow console.log access to _LevelupDB
_window._LevelupDB = _LevelupDB
