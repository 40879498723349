/* When we host the app from localhost the base route is just '/'.
* When we host from S3 however the base route is '/sltt-hosting-dev/' or
* '/sltt-hosting-prd/' to account for the fact that we have separate
* S3 buckets for the development and production versions.
* Value is set in constructor based on an BASE_URL environment 
* variable which is set in package.json during the deploy-dev and deploy-prd
* commands.
*/

// WARNING - it is not clear that you can count on this being set early in the load process.

let rootPrefix = ''


export default rootPrefix