import { ProjectReferences } from './ProjectReferences';
import { VideoCache } from './VideoCache';
import { observable, computed, makeObservable } from 'mobx';
import _ from 'underscore'
import _debug from 'debug'
import { createBrowserHistory } from 'history'

import { Project, Portion, Passage, PassageVideo, PassageGloss, PassageNote, PassageSegment, Member, PassageSegmentLabel, ProjectTerm, IDrawablePassageGloss, PassageNoteItem } from './ProjectModels'
//import { Member } from './Members'
import { RefRange } from '../scrRefs/RefRange'
import { tourSteps, setTourRt } from '../components/translation/TranslationEditorTour'
import API from '../models3/API'
import { Project as SooSLProject } from '../soosl/Project'
import { Backend } from '../soosl/backend'
import { DateFormatterFactory, daysAgo, IDateFormatter } from './DateUtilities';
import { defaultLabels } from '../components/segments/SegmentLabelsPosition';
import { GlossTextSearchParameters, RootBase, getRefRanges } from './RootBase';
import { fmt, s } from '../components/utils/Fmt';
import { displayError } from '../components/utils/Errors';
import { redirectToHome } from '..';
import { normalizeUsername } from './DBAcceptor';
import { ViewableVideoCollection } from '../components/video/ViewableVideoCollection';
import { NoteSelector } from '../components/notes/NoteSelector';
import { getSearchParams } from './AppRoot';
import { TRLResource, TRLResources } from './TRLModel'
import { VideoSketchData } from '../components/video/VideoSketcher';
import { clearHistorySearchParameters } from '../components/utils/LocationHistory'
import { DEFAULT_LOCALE, loadTranslation } from './utils/loadTranslation'

let log = _debug('sltt:Root')
const intest = (localStorage.getItem('intest') === 'true')

// This is used when specifying what passages to display.
// It is often used with a type ProgressSelection | string.
// String beginning with hashes are hashtags.
// Other strings are portion._id field specifying a specific portion.
export enum ProgressSelection {
    showAll = 'showAll', // all passages
    currentPortion = 'currentPortion', // all passages in current portion
    currentPassage = 'currentPassage'
}


export class Root extends RootBase implements IRoot {
    @computed get name() {
        return this.project.name
    }

    @computed get displayName() {
        return this.project.displayName
    }

    project: Project
    initializing = false
    
    @observable initialized = false

    @observable uiLanguage = 'en'
    @computed get dateFormatter(): IDateFormatter {
        return new DateFormatterFactory().getDateFormatter(this.project.dateFormat, this.uiLanguage)
    }

    @observable hashtag = ''
    @observable portion: Portion | null = null
    @observable passageGloss: PassageGloss | null = null
    @observable passageSegment: PassageSegment | null = null
    
    @observable playbackRate = 1.0

    @observable progressSelection: ProgressSelection | string = ProgressSelection.currentPortion
    
    username = ''
    id_token = ''

    @observable iAmRoot = false
    @observable iAmDeveloper = false
    @observable iAmAdmin = false
    @observable iAmTranslator = false
    @observable iAmConsultant = false // anyone who can make notes
    @observable iAmInterpreter = false
    @observable iAmApprover = false // must have consultant role
    
    @observable recording = false // true when video recording is in progress in the main window

    // We separate this from 'recording' above because changing the value of 'recording'
    // causes VideoMain to re-render during the recording which causes instability.
    @observable resumableRecording = false // true when video recording is in progress anywhere
    
    @observable passageVideoUrl = ''
    @observable passageVideoBlob: Blob | null = null

    @observable segmentLabelsDraft: PassageSegmentLabel[] = []      // Only use if editingSegmentLabels is true
    @observable editingSegmentLabels = false
    segmentLabelsSegment: PassageSegment | null = null // actual segment whose labels are being edited

    @observable editingSegment = false
    @observable editingImage = false
    @observable editingPassageDocument = false
    @observable termId = '' // When non empty, ERTermModal is open a displaying this ProjectTerm

    videoSketchData = new VideoSketchData()

    @observable transliterateLemmas = true
    @observable keytermsOnly = false

    // Resources viewable by everyone + this project if it is a resource project
    @observable trlResources: TRLResource[] = []
    @computed get trlResourcesIndexedByResourceName() {
        return _.indexBy(this.trlResources, 'resourceName') 
    }

    @computed get segmentTabEditingInProgress() {
        // Access all components to ensure that if any update later, this computed value will update.
        const { editingSegment, editingSegmentLabels, videoSketchData } = this
        const { actualSegment } = videoSketchData

        return editingSegment || editingSegmentLabels || !!actualSegment
    }

    @computed get videoPlaybackKeydownEnabled() {
        // Access all components to ensure that if any update later, this computed value will update.
        const { editingPassageDocument, note, resumableRecording } = this

        return !this.segmentTabEditingInProgress && 
            !editingPassageDocument && 
            !note && 
            !resumableRecording
    }

    setEditingPassageDocument(value: boolean) {
        this.editingPassageDocument = value
    }

    setEditingSegment(value: boolean) {
        if (value) {
            this.pause()
        }
        this.editingSegment = value
    }
    
    @observable softNotificationCutoff = '2000/01/01'   // Do not generate notifications for items created before this date
    @observable notificationMaxDays = 30

    hardNotificationCutoff = () => {
        let { softNotificationCutoff, notificationMaxDays } = this
        let notificationCutoffDate = new Date(softNotificationCutoff)
        return new Date(Math.max(daysAgo(notificationMaxDays), notificationCutoffDate.getTime()))
    }

    sooslProject: SooSLProject | null = null

    selectPage: (selection: string) => void

    projectReferences = new ProjectReferences()

    @observable loadingMessage = ''

    @observable useMobileLayout = false
    @observable useNarrowWidthLayout = false

    previousGlossVideoId = ''
    previousGlossPosition = 0

    // ------------ UI Tour related -------------
    @observable tourOpen = false
    @observable tourStepNumber = 0
    @observable tourSelector = ''
    @observable videoTourSignedUrl?: string  // Set when showing video in TourVideoPlayer

    constructor(project: Project, username: string, id_token: string, iAmRoot: boolean, 
        iAmDeveloper: boolean, selectPage: (selection: string) => void) {
        super()
        makeObservable(this);
        this.project = project
        this.username = normalizeUsername(username)
        this.id_token = id_token
        this.iAmRoot = iAmRoot
        this.iAmDeveloper = iAmDeveloper
        this.selectPage = selectPage

        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.setTimelineZoom = this.setTimelineZoom.bind(this)
        this.play = this.play.bind(this)
        this.setGlossScale = this.setGlossScale.bind(this)
        this.hardNotificationCutoff = this.hardNotificationCutoff.bind(this)
        this.setSegmentLabelsDraft = this.setSegmentLabelsDraft.bind(this)
        this.addListener = this.addListener.bind(this)
        this.removeListener = this.removeListener.bind(this)
        this.setPlaybackRate = this.setPlaybackRate.bind(this)
        this.displayableReferences = this.displayableReferences.bind(this)
        this.navigateToLinkLocation = this.navigateToLinkLocation.bind(this)
        this.setEditingSegment = this.setEditingSegment.bind(this)
        this.setEditingPassageDocument = this.setEditingPassageDocument.bind(this)
        this.pause = this.pause.bind(this)

        if (this.iAmRoot) {
            log(`role = root`)
            this.iAmDeveloper = true
            this.iAmAdmin = true
            this.iAmTranslator = true
            this.iAmConsultant = true
            this.iAmInterpreter = true
            this.iAmApprover = true
            return
        }
    }

    dbg(details?: string) {
        let portion = this.portion && `${this.portion.name} | ${this.portion._id}`
        let passage = this.passage && `${this.passage.name} | ${this.passage._id}`
        let currentVideos = this.currentVideos.dbg()
        let passageGloss = this.passageGloss && this.passageGloss.dbg()
        let passageSegment = this.passageSegment && this.passageSegment.dbg(this.passage, details)

        let { hashtag, progressSelection, username, recording, playing, currentTime, duration, canPlayThrough} = this

        let note = (this.note && this.note.dbg(details)) || 'none selected'
        let verseReference = (this.verseReference && this.verseReference.dbg() || 'none selected')

        return {portion, passage, currentVideos,
            hashtag, progressSelection, username, recording, playing, currentTime, duration, canPlayThrough,
            passageGloss, passageSegment, note, verseReference,
        }
    }

    async initialize() {
        let { initialized, initializing, name, project } = this
        log(`[${name}] --- start 7 model.initialize`)

        if (initialized || initializing) {
            log(`[${name}] model.initialize duplicate initialize ignored`)
            return
        }

        log(`[${this.name}] model.initialize A`)

        this.initializing = true

        await project.initialize((message: string) => {
            this.loadingMessage = message
        })

        log(`[${this.name}] model.initialize B`)
       
        this.setRole()
        await this.restoreDefaults()
        
        log(`[${this.name}] model.initialize C`)
        await this.navigateToLinkLocation()

        this.initialized = true
    }

    @computed get isTRLProject() {
        return this.project.projectType === 'resource'
    }

    // when TRL goes Live, we always want to load it, 
    // accessing this on Root allows us to load it more often 
    // than the publishedResourceNames 
    // since new TRL content will be published more often than TRL resource names.
    async loadTRLResources() {
        const resourceNames = TRLResources.publishedResourceNames.slice()

        // If this is a TRL project but not yet published, add it to the list of resource names
        // so that project members can see what it will look like when published.
        const { name } = this.project
        if (this.isTRLProject && !TRLResources.isPublishedResource(name)) {
            resourceNames.push(name)
        }   

        const trlResources = await TRLResources.getAllResourcesFromS3(resourceNames)
        this.trlResources = trlResources
    }

    async navigateToLinkLocation() {
        let searchParams = getSearchParams()
        let _id = searchParams.get('id')
        let time = searchParams.get('time')
        if (_id && time) {
            let { project } = this
            let portion = project.findPortion(_id)
            let passage = portion && project.findPassage(_id)
            let pv = portion && passage && passage.findVideo(_id)
            let note = portion && passage && pv && passage.findNote(_id)

            // Root.setPassageVideo function assumes the video you pass is a base video
            if (pv && passage) {
                pv = pv.baseVideo(passage) || pv
            }

            if (!portion || !passage || !pv) {
                redirectToHome()
            }

            if (!portion) {
                displayError('Portion does not exist.')
                return
            }

            if (!passage) {
                displayError('Passage does not exist.')
                return
            }

            if (!pv) {
                displayError('Passage video does not exist.')
                return
            }

            let timeNumber = Number(time)
            if (isNaN(timeNumber)) {
                timeNumber = 0
            }

            portion && (await this.setPortion(portion))
            passage && (await this.setPassage(passage))
            pv && (await this.setPassageVideo(pv))
            portion && passage && pv && this.setNote(note)
            portion && passage && pv && this.setCurrentTime(timeNumber)
        }
    }

    @computed get projectMember() {
        return this.project.members.find(member => member.email === this.username)
    }

    @computed get memberRole() {
        return this.projectMember?.role
    }

    setRole() { 
        if (!this.project) throw Error('Project not set')
        if (!this.project.members) throw Error('Members not set')

        if (this.iAmRoot) {
            this.iAmAdmin = true
            this.iAmTranslator = true
            this.iAmConsultant = true
            this.iAmInterpreter = true
            this.iAmDeveloper = true
            this.iAmApprover = true
            return
        }

        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = false
        this.iAmApprover = false

        let member = this.projectMember
        if (!member) {
            log(`###setRole member [${this.username}] not found`)
            return
        }

        let role = member.role
        log(`setRole ${this.username} = ${role}, root=${this.iAmRoot}`)

        if (['admin', 'consultant'].includes(role))
            this.iAmApprover = true

        if (['admin'].includes(role))
            this.iAmAdmin = true

        if (['admin', 'translator'].includes(role))
            this.iAmTranslator = true

        if (['admin', 'translator', 'consultant'].includes(role))
            this.iAmConsultant = true

        if (['admin', 'translator', 'consultant', 'interpreter'].includes(role))
            this.iAmInterpreter = true
    }

    async getSooslProject(): Promise<SooSLProject> {
        if (!this.sooslProject) {
            let json = await Backend.getProject(this.project.name)
            this.sooslProject = new SooSLProject(json)
        }

        return this.sooslProject
    }

    getDefault(tag: string) { 
        // check if we are not running in a browser, this should only happen during unit tests
        if (typeof localStorage === 'undefined') return ''

        let key = `defaults.${this.name}.${tag}`
        log(`getDefault ${key}=${localStorage.getItem(key)}`) 
        return localStorage.getItem(key)
    }
    
    setDefault(tag: string, value: string | null) {
        // check if we are not running in a browser, this should only happen during unit tests
        if (typeof localStorage === 'undefined') return

        let key = `defaults.${this.name}.${tag}`
        value = value || ''
        log(`setDefault ${key}=${value}`) 
        return localStorage.setItem(key, value)
    }

    // Retrieve the previous user settings from local storage.
    // Attempt to set project to previously selected portion and passage.
    async restoreDefaults() {
        log(`[${this.name}] restoreDefaults`)

        let playbackRateString = localStorage.getItem('videoPlaybackRate')
        log(`initial videoPlaybackRate ${playbackRateString}`)
        let playbackRate = playbackRateString ? parseFloat(playbackRateString) : 1.0
        this.playbackRate = playbackRate

        this.glossScale = parseFloat(this.getDefault('gloss-scale') || '100.0')
        
        this.timelineZoom = parseFloat(this.getDefault('timelineZoom') || '1')

        this.progressSelection = this.getDefault('progressSelection') || ProgressSelection.currentPortion

        let uiLanguage = this.getDefault('uiLanguage')
        if (!uiLanguage) {
            this.setDefault('uiLanguage', DEFAULT_LOCALE)
        }
        this.uiLanguage = uiLanguage || DEFAULT_LOCALE
        if (this.uiLanguage !== DEFAULT_LOCALE) {
            log(`[${this.name}] restoreDefaults - loading translation for ${uiLanguage}`)
            await loadTranslation(this.uiLanguage)
        }

        let { project } = this
        let portion = project.getDefaultPortion(this.getDefault('portion'))
        if (!portion) return

        this.portion = portion
        this.setDefault('portion', portion._id)
        
        let passage = portion.getDefaultPassage(this.getDefault('passage'))
        if (!passage) return

        this.passage = passage
        this.setDefault('passage', passage._id)

        let passageVideo = passage.getDefaultVideo(this.getDefault('passagevideo'))
        if (passageVideo) {
            await this.setPassageVideo(passageVideo)
        } else {
            await this.setPassage(passage)
        }

        this.transliterateLemmas = !(this.getDefault('transliterateLemmas') === 'false')
        this.keytermsOnly = (this.getDefault('keytermsOnly') === 'true')
    }

    setProgressSelection(selection: ProgressSelection | string) {
        this.setDefault('progressSelection', selection)
        this.progressSelection = selection
    }

    getLastProjectNotificationSeen() { 
        let rank = this.getDefault('projectnotification')
        return rank ?? '0'
    }

    setLastProjectNotificationSeen(rank: string) {
        this.setDefault('projectnotification', rank)
    }

    setNotificationCutoff(value: string) {
        let cutoffMilliseconds = Date.parse(value)
        if (!isNaN(cutoffMilliseconds)) {
            this.setDefault('notificationCutoff', value)
            this.softNotificationCutoff = value
        }
    }

    setGlossScale(scale: number) {
        super.setGlossScale(scale)
        this.setDefault('gloss-scale', scale.toString())
    }

    // /**
    // * If passage has not been selected yet and this is a passage for the currently
    // * selected portion, use this passage
    // */
    // async acceptPassage(passage: Passage) {
    //     if (this.passage) return
    //     if (!this.portion) return
    //     if (!passage._id.startsWith(this.portion._id)) return

    //     log('acceptPassage')
    //     await this.setPassage(passage)
    // }

    // /**
    // * If this video is the latest video for the passage, make it the selected video
    // */
    // async acceptPassageVideo(video: PassageVideo) {
    //     if (!this.portion) return
    //     if (!this.passage) return
    //     if (!video._id.startsWith(this.passage._id)) return
    //     if (this.passage.videosNotDeleted.slice(-1)[0]._id !== video._id) return

    //     log('acceptPassageVideo')
    //     await this.setPassageVideo(video)
    // }

    async setPortion(portion: Portion | null) {
        if (this.project.videoBeingCompressed) return

        log(`[${this.name}] setPortion: ${portion && portion.name}`)

        this.setDefault('portion', portion && portion._id)
        this.portion = null
        await this.setPassage(null)
        if (!portion) return

        this.portion = portion
        
        await this.setDefaultPassage()
    }

    async setDefaultPassage() {
        let passage = this.portion!.getDefaultPassage('')
        await this.setPassage(passage)

        this.setDefaultPassageVideo()
    }

    async setPassage(passage: Passage | null) {
        // clearHistorySearchParameters('setPassage')

        if (this.project.videoBeingCompressed) return

        // If video sketch data has been changed, save it.
        this.videoSketchData.save().catch(displayError)

        log(`[${this.name}] setPassage: ${passage && passage.name}`)
        this.setDefault('passage', passage && passage._id)
        
        if (!passage) {
            this.passage = null
            this.setEditingSegment(false)
            await this.setPassageVideo(null)
            return
        }

        // Set passageVideo to null because otherwise we can have a situation
        // where passage and passageVideo temporarily refer to different passages.
        await this.setPassageVideo(null)

        this.passage = passage
        await this.setDefaultPassageVideo()
        
        // Even if there is not a video, set the dbsRefs to the passage
        if (!this.passageVideo) {
            this.setDbsRefs(this.portion, null)
        }

        // Force portion to appear to change to ensure that the new passage
        // item is seen by observers. Better way to do this???
        let portion = this.portion
        this.portion = null
        this.portion = portion
    }

    // async preloadNoteVideos(passageVideo: PassageVideo | null) {
    //     if (!passageVideo) return

    //     let notes = passageVideo.notes.filter(note => !note.resolved)
    //     for (let note of notes) {
    //         for (let item of note.items) {
    //             if (item.url) {
    //                 log('preloading', item.url)
    //                 try {
    //                     await VideoCache.requestVideo(item.url)
    //                 } catch (error) {
    //                     log(`preloadNoteVideos error`, error)                        
    //                 }
    //             }
    //         }
    //     }
    // }

    async setDefaultPassageVideo() {
        if (!this.passage) {
            this.passageVideo = null
            this.passageSegment = null
            this.passageVideoUrl = ''
            return
        }

        let passageVideo = this.passage!.getDefaultVideo('')
        await this.setPassageVideo(passageVideo)
    }

    async setPassageVideo(passageVideo: PassageVideo | null, waitForDownload?: boolean) {
        if (this.project.videoBeingCompressed) return

        // If SLTT crashes between adding a video and adding its default segment, we need
        // to add the default segment the next time the video is opened.
        if (passageVideo && passageVideo.segments.length === 0) {
            log('### no segments present, adding default segment')
            await passageVideo.addSegment(0)
        }

        let { passage, name } = this
        await super._setPassageVideo(name, passage, passageVideo, waitForDownload)

        if (passageVideo === null) {
            this.setPassageSegment(null)
        }
    }

    setPassageSegment(passageSegment: PassageSegment | null) {
        let { passage, passageVideo } = this

        // If video sketch data has been changed, save it.
        this.videoSketchData.save().catch(displayError)

        if (passageVideo === null || passage === null) {
            this.passageSegment = null
            return
        }

        if (this.passageSegment === passageSegment) return
        log('setPassageSegment', fmt({
            passageSegment, 
            time: passageSegment?.time,
            patch: passageSegment?.patchVideo(passage),
        }))

        this.passageSegment = passageSegment
    }

    resetCurrentTime(newTime: number, duration?: number) {
        super.resetCurrentTime(newTime, duration)

        let { portion, passageSegment } = this
        this.setDbsRefs(portion, passageSegment)
    }

    setCurrentTime(currentTime: number) {
        super.setCurrentTime(currentTime)

        let { passage, passageVideo, portion } = this
        // If the video was just created, it may not have any segments yet.
        // If so, we cannot select a segment yet.
        if (!passage || !passageVideo || passageVideo.segments.length === 0 || !portion) return

        try {
            let segment = passageVideo.timeToSegment(passage, currentTime)
            this.setPassageSegment(segment)
            this.setDbsRefs(portion, segment)
        } catch (error) {
            log('###setCurrentTime', error)            
        }
    }

    setPlaybackRate(rate: number) {
        rate = Math.max(0.1, Math.min(rate, 2.0))
        this.playbackRate = rate
        localStorage.setItem('videoPlaybackRate', rate.toString())
        log(`change videoPlaybackRate ${rate}`)
    }

    openTour() {
        setTourRt(this)
        this.tourStepNumber = 0
        //this.tourSelector = this.getCurrentTourStep().selector
        this.tourOpen = true
    }

    closeTour() {
        this.tourSelector = ''
        this.tourOpen = false
    }

    getCurrentTourStep(stepNumber: number) {
        let step = tourSteps[stepNumber]
        this.tourSelector = step.selector
        log(`=== ${step.selector}`)
    }

    async setVideoTourSignedUrl(url: string) {
        this.videoTourSignedUrl = await API.getUrl(url)
    }

    // createNote() {
    //     let { passageVideo } = this
    //     let creationDate = passageVideo!.db.getNewId(passageVideo!.notes, new Date(Date.now()))
    //     let _id = passageVideo!._id + '/' + creationDate

    //     this.setNote(new PassageNote(_id, passageVideo!.db))

    //     let ct = this.currentTime
    //     if(intest) ct = Math.round(ct*1000) / 1000  // stop jitter from failing test
    //     this.note!.position = ct
    //     this.note!.startPosition = ct - 10
    //     this.note!.endPosition = ct + 10
    // }

    /**
     * Play video in main window (RootVideoPlayer)
     * @param startTime - when present start playing video at this time, otherwise at time = 0
     * @param endPosition - when present stop playing video at this time, otherwise play to end
     * @param resetTime - when present, reset video time to this playing
     */
    play(startTime?: number, endPosition?: number, resetTime?: number) { 
        // console.clear()
        if (this.editingSegment) return
        super.play(startTime, endPosition, resetTime)
    }

    playAll() {
        if (this.editingSegment) return
        this.emit('playAll')
    }

    /**
     * This is called by VideoMain to tigger the start of recording.
     * It is also called by SegmentsEditor to start recording a patch.
     */
    record(projectName: string, 
        videoBeingRecorded: PassageVideo, 
        onRecordingDone: (err: any, blobsCount?: number, url?: string, duration?: number) => void
    ) { 
        if (this.recording) {
            log(`record request ignored, already recording`)
            return    // ignore if already recording
        }
        if (!this.portion || !this.passage || !this.iAmTranslator) return
        log(`record`)
        this.emit('record', projectName, videoBeingRecorded, onRecordingDone)
    }

    async createPassageGloss() {
        let { passage, passageVideo, currentTime, previousGlossVideoId, previousGlossPosition,
            selectionStartTime, selectionEndTime } = this
        if (!passage || !passageVideo) return

        function glossInfo(time: number) {
            let segment = passageVideo!.timeToSegment(passage!, time)
            let video = segment.patchVideo(passage!) || passageVideo
            let _segment = segment.actualSegment(passage!)
            let position = _segment.timeToPosition(time)
            return { video, position }
        }

        if (this.selectionPresent()) {
            log('createPassageGloss for selection', fmt({ selectionStartTime, selectionEndTime }))
            let { video, position } = glossInfo(selectionStartTime)
            if (video) { 
                await video.addGloss(position, "???") 
            }

            let { video: video2, position: position2 } = glossInfo(selectionEndTime)
            if (video2) { await video2.addGloss(position2, "") }
        } else {
            log('createPassageGloss', fmt({ currentTime }))
            let { video, position } = glossInfo(currentTime)
            // debounce creation.
            // Sometimes when the user hits the \ shortcut once, the system delivers two keydowns.
            let gap = Math.abs(position - previousGlossPosition)
            if (passageVideo._id === previousGlossVideoId && gap < .15) {
                log(`createPassageGloss reject gap=${gap.toFixed(2)}`)
                return
            }
            this.previousGlossVideoId = passageVideo._id
            this.previousGlossPosition = position

            if (video) { await video.addGloss(position, "") }
        }
    }

    setLocale(locale: string) {
        this.uiLanguage = locale
        this.setDefault('uiLanguage', locale)
    }

    getDuration(video: PassageVideo) {
        let { passage } = this
        let viewTime = 0
        if (passage) {
            video.segments.forEach(s => {
                viewTime += s.actualSegment(passage!).duration
            })
        }
        return viewTime
    }

    getProjectReferences() {
        return this.projectReferences
    }

    startEditingSegmentLabels(actualSegment: PassageSegment, initialLabels: PassageSegmentLabel[]) {
        this.segmentLabelsDraft = initialLabels
        this.segmentLabelsSegment = actualSegment
        this.editingSegmentLabels = true
    }

    /**
     * If rt.segmentLabelsSegment is set, it means there are unsaved changes
     * to the segment labels. This function will save those changes.
     */
    async saveSegmentLabelsDraftChanges() {
        const { segmentLabelsSegment, segmentLabelsDraft } = this
        if (!segmentLabelsSegment) return

        const filteredLabels = segmentLabelsDraft.map((label, i) => label.text ? label : defaultLabels[i])
        await segmentLabelsSegment.setLabels(filteredLabels)

        // Force anything that depends on passageSegment to render
        const passageSegment = this.passageSegment
        this.passageSegment = null
        this.passageSegment = passageSegment

        this.segmentLabelsDraft = []
        this.editingSegmentLabels = false
        this.segmentLabelsSegment = null
    }

    setSegmentLabelsDraft(labels: PassageSegmentLabel[]) {
        this.segmentLabelsDraft = labels
    }

    async resetSegmentLabelsDraftChanges() {
        log(`discard segment labels changes`)
        this.segmentLabelsDraft = []
        this.editingSegmentLabels = false
        this.segmentLabelsSegment = null
    }

    @computed get canViewConsultantOnlyFeatures() {
        return this.iAmDeveloper || this.iAmApprover
    }
    
    // Default reference for current time
    defaultReferences() {
        let { portion, passage, passageVideo, passageSegment, currentTime } = this
        return getRefRanges(portion, passage, passageVideo, passageSegment, currentTime)
    }

    // Convert references to a display able string based on book names defined for the project
    displayableReferences(references: RefRange[] | undefined | null) {
        if (!references) return ''
        return RefRange.refRangesToDisplay(references, this.project)
    }

    // // Parse a refernce string, Luke 7.11-12, into RefRanges.
    // // Uses book names from project.
    // // THROWS if cannot parse
    // parseReferences(references: string): RefRange[] {
    //     let { bookNames } = this.project
    //     let bookNamesList = Object.values(bookNames)
    //     let refRanges = RefRange.parsePersistedRefRanges(references, bookNamesList)
    //     return refRanges
    // }

    // Parse a refernce string, Luke 7.11-12, into RefRanges.
    // Uses book names from project, ui language, or English.
    // THROWS if cannot parse.
    parseReferences(references: string): RefRange[] {
        let { bookNames } = this.project
        let bookNamesList = Object.values(bookNames)
        let refRanges = RefRange.parseReferences(references, this.uiLanguage, bookNamesList)
        return refRanges
    }

    // Setup as Bob the interpreter.
    // This is useful when testing.
    // From browser console: window.appRoot.rt.setInterpreter()

    setInterpreter() {
        this.username = 'bob@gmail.com'

        this.iAmRoot = false
        this.iAmDeveloper = false
        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = true
        this.iAmApprover = false
    }

    setConsultant() {
        this.username = 'bob@gmail.com'

        this.iAmRoot = false
        this.iAmDeveloper = false
        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = false
        this.iAmApprover = true
    }

    setTranslator() {
        this.username = 'bob@gmail.com'

        this.iAmRoot = false
        this.iAmDeveloper = false
        this.iAmAdmin = false
        this.iAmTranslator = true
        this.iAmConsultant = false
        this.iAmInterpreter = true
        this.iAmApprover = false
    }

    setObserver() {
        this.username = 'bob@gmail.com'

        this.iAmRoot = false
        this.iAmDeveloper = false
        this.iAmAdmin = false
        this.iAmTranslator = false
        this.iAmConsultant = false
        this.iAmInterpreter = false
        this.iAmApprover = false
    }

    setTimelineZoom(zoom: number) {
        super.setTimelineZoom(zoom)
        this.setDefault('timelineZoom', zoom.toFixed(0))
    }

    static screenCaptureInProgress() {
        return localStorage.getItem('screencapture') === 'true'
    }

    get noteColors() {
        return this.project.noteColors
    }

    setTransliterateLemmas(transliterateLemmas: boolean) {
        this.setDefault('transliterateLemmas', transliterateLemmas.toString())
        this.transliterateLemmas = transliterateLemmas
    }

    setKeytermsOnly(keytermsOnly: boolean) {
        this.setDefault('keytermsOnly', keytermsOnly.toString())
        this.keytermsOnly = keytermsOnly
    }
}

/**
 * IRoot exists is implimented by Root and DraftVideoRoot.
 * Root is used by all components in the left pane.
 * DraftVideoRoot is used by CompareDraftVideoMain component in the right main.
 * This allows the left and right pane to display different video but support
 * the same feature set.
 */

export interface IRoot {
    project: Project,
    portion: Portion | null,
    passage: Passage | null,
    passageVideo: PassageVideo | null,
    passageSegment: PassageSegment | null,

    dateFormatter: IDateFormatter,

    iAmConsultant: boolean,
    iAmInterpreter: boolean,
    iAmAdmin: boolean,
    canViewConsultantOnlyFeatures: boolean,
    username: string,

    setPortion: (portion: Portion | null) => Promise<void>,
    setPassage: (passage: Passage | null) => Promise<void>,
    setPassageVideo: (passageVideo: PassageVideo | null, waitForDownload?: boolean) => Promise<void>,
    setPassageSegment: (passageSegment: PassageSegment) => void,
    play: (startTime?: number, endPosition?: number, resetTime?: number) => void,
    gtsp: GlossTextSearchParameters,
    currentVideos: ViewableVideoCollection,

    uiLanguage: string,
    transliterateLemmas: boolean,
    setLexicalLink: (lexicalLink: string) => void,
    termId: string,
    glossIndex: number, // -1 = no gloss selected
    drawableGloss: IDrawablePassageGloss | null,

    currentTime: number,
    duration: number,
    resetCurrentTime: (newTime: number, duration?: number) => void,
    note: PassageNote | undefined,
    noteSelector: NoteSelector,
    clonedNoteItem?: PassageNoteItem,
    setNote: (note: PassageNote) => void,
    createNoteIfNonexistent: () => Promise<void>,
    name: string,
    hardNotificationCutoff: () => Date,
    useMobileLayout: boolean,
    playbackRate: number,
    setPlaybackRate: (rate: number) => void,
}


