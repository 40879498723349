import { observer } from 'mobx-react'
import React, { FC, useState } from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import { CancelButton, OKButton } from '../../utils/Buttons'
import './TRLThumbnailVideoCreatorModal.css'
import TRLThumbnailVideoCreator from './TRLThumbnailVideoCreator'
import { TRLVideoPositionControlContext } from './TRLVideoBarControlsContextComponent'
import { MultilingualString, Passage, PassageThumbnailVideo, Portion } from '../../../models3/ProjectModels'
import { TRLPassages } from '../Browse/TRLPanel'
import { ITRLPassage, ITRLPortion, ITRLResource, ITRLThumbnail, TRLLicense, TRLPortion, TRLResource, ThumbnailType } from '../../../models3/TRLModel'
import { PortionBrowseItem } from '../Browse/PortionBrowseItem'
import { TRLPassageWithPreloadedUrl } from '../Browse/PassageBrowseItem'

import _debug from "debug"; const log = _debug('sltt:TRLThumbnailVideoCreatorModal')

interface ITRLThumbnailVideoCreatorModal {
    show: boolean
    setShow: (show: boolean) => void
    projectName: string
    copyright: string
    modelPortion: Portion
    modelPassage: Passage
    primaryLanguage: string
    passageThumbnailVideo: PassageThumbnailVideo|undefined
    passageVideoUrl: string
    videoUrl: string
    onSaveThumbnailVideo: (newThumbnailVideoBlob: Blob, srcVideoUrl: string, selectionStartTime: number, selectionEndTime: number) => void
}

export const TRLThumbnailVideoCreatorModal: FC<ITRLThumbnailVideoCreatorModal> = observer((
    {
        show, setShow, projectName, primaryLanguage, copyright, modelPortion, modelPassage,
        passageThumbnailVideo, videoUrl, passageVideoUrl, onSaveThumbnailVideo
    }
) => {
    const videoRef = React.createRef<HTMLVideoElement>()
    const [loadedThumbnailUrl, setLoadedThumbnailUrl] = useState<string>()
    const [newThumbnailBlob, setNewThumbnailBlob] = useState<Blob>()    
    const [recordingThumbnail, setRecordingThumbnail] = useState(!passageThumbnailVideo)
    const [startTime, setStartTime] = useState(0)
    const [endTime, setEndTime] = useState(0)
    const [firstPassageIsThumbnail, setFirstPassageIsThumbnail] = useState(modelPortion.firstPassageIsThumbnail)

    const updateCurrentTime = (time: number) => {
        // log('updateCurrentTime', { time })
        const video = videoRef.current!
        if (!video) return
        video.currentTime = time
    }

    const onThumbnailCreated = (blob: Blob, url: string, startTime: number, endTime: number) => {
        log('onThumbnailCreated')
        setLoadedThumbnailUrl(url)
        setNewThumbnailBlob(blob)
        setStartTime(startTime)
        setEndTime(endTime)
        setRecordingThumbnail(false)
    }

    const stage = recordingThumbnail ? 'Recording thumbnail video...' : 'Test and Approve (or Cancel) thumbnail video'

    const handleApproveThumbnailVideo = async () => {
        log('handleApproveThumbnailVideo', {
            changedFirstPassageIsThumbnail, firstPassageIsThumbnail,
            passageThumbnailVideo,
            newThumbnailBlob, passageVideoUrl, startTime, endTime
        }
        )
        if (changedFirstPassageIsThumbnail) {
            await modelPortion.setFirstPassageIsThumbnail(firstPassageIsThumbnail)
        }
        if (passageThumbnailVideo || !passageVideoUrl) {
            setShow(false) // no changes, just close
            return
        }
        await onSaveThumbnailVideo(newThumbnailBlob!, passageVideoUrl, startTime, endTime)
    }

    const createTRLResourceDraft = () => {
        const trlResourceProps: ITRLResource = {
            primaryLanguage,
            resourceName: projectName,
            copyright: copyright || '** Missing copyright. See Project Preferences **',
            license: TRLLicense.ASK,
            portions: [],
            logoUrl: '** only needed to pass logoUrl validation **'
        }
        return new TRLResource(trlResourceProps)
    }

    const createTRLPortionDraft = () => {
        const thumbnail = createTRLThumbnail(modelPortion)
        
        const passages = modelPortion.finalPassages
            .filter(p => p.getDefaultVideo('')?.url)
            .map((p: Passage, i) => createTRLPassageDraft(p)
        )
        const portionTitle = modelPortion.getTitleOrDefault(
            primaryLanguage, '* Missing portion title (Use portions page to enter title) *'
        )
        const titles = [new MultilingualString(primaryLanguage, portionTitle)]
        const trlPortionProps: ITRLPortion = {
            portion_id: modelPortion._id,
            rank: modelPortion.rank,
            titles,
            passages,
            thumbnail,
        }
        return new TRLPortion(
            trlPortionProps, primaryLanguage, firstPassageIsThumbnail,
            true /* skip thumbnail validation */,
            true /* skip passage validation */,
        )
    }

    const DEFAULT_THUMBNAIL_URL = ''
    const createTRLThumbnail = (p: Passage|Portion): ITRLThumbnail => {
        // NOTE: if srcURL is DEFAULT_THUMBNAIL_URL, we need to pass [pre]loadedThumbnailUrl to PassageBrowseItem
        return {
            srcUrl: p.getThumbnailVideoUrl('') || DEFAULT_THUMBNAIL_URL,
            type: ThumbnailType.video
        }        
    }

    const createTRLPassageDraft = (passage: Passage, preloadedThumbnailUrl?: string) => {
        const thumbnail = createTRLThumbnail(passage)
        const passageTitle = passage.getTitleOrDefault(
            primaryLanguage, '* Missing passage title (Use portions page to enter title) *'
        )
        const titles = [new MultilingualString(primaryLanguage, passageTitle)]

        const trlPassageProps: ITRLPassage = {
            titles,
            videoUrl: passage.getDefaultVideo('')!.url,
            thumbnail
        }

        const trlPassage = new TRLPassageWithPreloadedUrl(trlPassageProps, primaryLanguage, preloadedThumbnailUrl)
        return trlPassage
    }

    // allow exclude first passage if there are multiple final passages and the first final passage matches the modelPassage
    const isModalOnFirstPassage = modelPortion.finalPassages.find(() => true) === modelPassage
    const allowExcludeFirstPassage = modelPortion.finalPassages.length > 1 && isModalOnFirstPassage

    const trlResourceDraft = createTRLResourceDraft()
    const trlPortionDraft = createTRLPortionDraft()
    const myTrlPassageDraft = createTRLPassageDraft(modelPassage, loadedThumbnailUrl)
    // swap out the passage for the draft we just created (based on component state vs. model state)
    trlPortionDraft.passages = replacePassage(allowExcludeFirstPassage, firstPassageIsThumbnail, trlPortionDraft, myTrlPassageDraft)
    // console.log('render TRLThumbnailVideoCreatorModal', {trlPortionDraft, myTrlPassageDraft, firstPassageIsThumbnail, allowExcludeFirstPassage})

    const onToggleFirstPassageIsPortion = (e: React.MouseEvent) => {
        setFirstPassageIsThumbnail(!firstPassageIsThumbnail) // toggle
    }

    const changedFirstPassageIsThumbnail = firstPassageIsThumbnail !== modelPortion.firstPassageIsThumbnail
    const okButtonEnabled = loadedThumbnailUrl || changedFirstPassageIsThumbnail
    const fileSizeKb = getKb(passageThumbnailVideo?.size || newThumbnailBlob?.size || 0)
    return (
        <Modal style={{ top: '1%' }} 
            bsSize="large"
            show={show}
            onHide={() => setShow(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Thumbnail Video Creator'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="trl-thumbnail-creator-modal-body">
                    <h3 className="trl-thumbnail-creator-modal-stage">{stage}</h3>
                    {recordingThumbnail && !loadedThumbnailUrl && <div className="trl-video-preview-recording">
                        <div>
                            <video
                                className="trl-video-player-video-video"
                                ref={videoRef}
                                src={videoUrl}
                                onCanPlayThrough={() => {
                                    // setCanPlayThrough(true)
                                }}
                                onTimeUpdate={() => {
                                    // onTimeUpdate(videoRef.current!.currentTime)
                                }}
                            />
                        </div>
                    </div>}
                    {recordingThumbnail && videoUrl && <TRLVideoPositionControlContext.Consumer>
                        {value => <TRLThumbnailVideoCreator
                            videoUrl={videoUrl}
                            startTime={value!.videoPosition.selectionStartTime}
                            endTime={value!.videoPosition.selectionEndTime}
                            onRecordingTimeUpdate={updateCurrentTime}
                            onThumbnailCreated={onThumbnailCreated}
                        />}
                    </TRLVideoPositionControlContext.Consumer>}
                    {!recordingThumbnail && <div>
                        {isModalOnFirstPassage && <div className="checkbox-container">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    id="thumbnail-checkbox"
                                    className="checkbox-button checkbox-input"
                                    checked={firstPassageIsThumbnail}
                                    readOnly
                                    disabled={!allowExcludeFirstPassage}
                                    onClick={onToggleFirstPassageIsPortion}
                                />
                                <div>
                                    <div>
                                        {"Exclude first passage video content from the Translation Resource Library (TRL)."}
                                    </div>
                                    <div>
                                        {"(Include first passage  only as a portion thumbnail.)"}
                                    </div>
                                </div>
                            </label>
                        </div>}
                        <div className="trl-thumbnail-creator-modal-portion-passages">
                            {isModalOnFirstPassage && <div className="trl-thumbnail-creator-modal-portion-container">
                                <>
                                    <h4>Portion thumbnail (from first passage):</h4>
                                    <div className="trl-area-grid">
                                        <PortionBrowseItem key={passageVideoUrl}
                                            preloadedThumbnailUrl={loadedThumbnailUrl}
                                            portion={trlPortionDraft}
                                            selectedPortion={undefined}
                                            showTitles={true}
                                            portionPlaying={false}
                                            onPortionClick={() => {}}
                                            onPortionPlaying={() => {}}
                                            selectedResource={trlResourceDraft}
                                        />
                                    </div>
                                </>
                            </div>}
                            {<div className="trl-thumbnail-creator-modal-passages-container">
                                <h4>Passage Thumbnail(s):</h4>
                                <div className="trl-thumbnail-creator-modal-passages-scrollable">
                                    {allowExcludeFirstPassage && firstPassageIsThumbnail && <i className="checkbox-label">
                                        First passage video content will be excluded from TRL. (Shown only as portion thumbnail.)
                                    </i>}
                                    <TRLPassages
                                        selectedPortion={trlPortionDraft}
                                        selectedPassage={!allowExcludeFirstPassage && myTrlPassageDraft || undefined}
                                        showTitles={true}
                                        selectedResource={trlResourceDraft}
                                        playing={false}
                                        onPassageClick={() => {}}
                                    />
                                </div>
                            </div>}
                        </div>
                    </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="trl-thumbnail-creator-modal-footer-buttons">
                    <div className="trl-thumbnail-creator-modal-footer-buttons-left">
                        <OKButton enabled={okButtonEnabled} onClick={handleApproveThumbnailVideo} buttonClassName='' className='ok-button' tooltip={'Confirm'} />
                        <CancelButton enabled={true} onClick={() => setShow(false)} className='cancel-button' tooltip={'Cancel'} />
                    </div>
                    <div className="trl-thumbnail-creator-modal-footer-buttons-right">
                        <span>Thumbnail File Size: {fileSizeKb} KB</span>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    )
})

// convert to KB format
function getKb(bytes: number) {
    return Math.round(bytes / 1024).toFixed(2)
}

// create new set of passages replacing one with myTrlPassageDraft
const replacePassage = (
    allowExcludeFirstPassage: boolean,
    firstPassageIsThumbnail: boolean, trlPortionDraft: TRLPortion,
    myTrlPassageDraft: TRLPassageWithPreloadedUrl
) => {
    // NOTE: if allowExcludeFirstPassage && firstPassageIsThumbnail,
    // then myTrlPassageDraft should not be in trlPortionDraft.passages
    if (allowExcludeFirstPassage && firstPassageIsThumbnail)
        return trlPortionDraft.passages // exclude myTrlPassageDraft from trlPortionDraft.passages
    const indexForSwap = trlPortionDraft.passages.findIndex(p => p.videoUrl === myTrlPassageDraft.videoUrl)!
    const passagesForSwap = [...trlPortionDraft.passages]
    passagesForSwap[indexForSwap] = myTrlPassageDraft
    return passagesForSwap
}