import { IVideoPositionControls } from '../../video/VideoPositionBarControls'
import { RefRange } from '../../../scrRefs/RefRange'
import { PassageSegment, Portion, Project } from '../../../models3/ProjectModels'
import { RootBase } from '../../../models3/RootBase'
import { IVideoPosition } from '../../video/VideoPositionBar'
import { Root } from '../../../models3/Root'
import _debug from "debug"; const log = _debug('sltt:TRLVideoPosition')

export class TRLVideoPosition extends RootBase implements IVideoPosition, IVideoPositionControls {

    constructor(rt: Root) {
        super()
        this.useMobileLayout = rt.useMobileLayout
        this.project = rt.project
        this.iAmConsultant = rt.iAmConsultant
        this.portion = rt.portion
        this.passage = rt.passage
        this.passageVideo = rt.passageVideo
        this.passageSegment = rt.passageSegment
        this.editingSegment = rt.editingSegment
        const duration = rt.duration
        this.duration = duration
        this.currentTime = rt.currentTime
        this.timelineStart = rt.timelineStart
        this.timelineEnd = duration
        this.timelineZoom = rt.timelineZoom
        this.selectionStartTime = rt.selectionStartTime
        this.selectionEndTime = rt.selectionEndTime
        this.drawableGloss = null
        this.displayableReferences = rt.displayableReferences
        this.setDefault = rt.setDefault
        this.getDefault = rt.getDefault
        this.parseReferences = rt.parseReferences

        super.addListener = super.addListener.bind(this)
        super.removeListener = super.removeListener.bind(this)
    }
    project: Project
    iAmConsultant: boolean
    useMobileLayout: boolean
    portion: Portion | null
    passageSegment: PassageSegment | null
    editingSegment: boolean
    displayableReferences: (references: RefRange[] | null | undefined) => string
    setDefault: (tag: string, value: string | null) => void
    getDefault: (tag: string) => string | null
    parseReferences: (references: string) => RefRange[]
}
