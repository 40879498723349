import React, { FunctionComponent } from "react"
import { Helmet } from 'react-helmet'

interface ITitle {
    title: string,
}

const TitleComponent: FunctionComponent<ITitle> = ({ title }) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    )
}

export default TitleComponent