import { getBookNames } from '../scrRefs/bookNames'
import { RefRange } from '../scrRefs/RefRange'
import { getPassageContentTypes, Passage, PassageContentType, Project } from './ProjectModels'
import * as P from 'parsimmon'

export interface PIEPassage {
    passage: string
    passageType: string
    difficulty: string
    reference: string
    tag: string
}

export interface PIEPortion {
    portion: string
    passages: PIEPassage[]
}

export class PIECsvParser {
    static quotedCell = P.string('"')
        .then(P.regex(/(?:[^"]|"")*/))
        .skip(P.string('"'))
        .map(s => s.replace(/""/g, '"').trim())

    static nonQuotedCell = P.regex(/[^,\n"]*/).map(s => s.trim())

    static cell = P.alt(PIECsvParser.quotedCell, PIECsvParser.nonQuotedCell)

    static row = P.sepBy(PIECsvParser.cell, P.string(','))

    static csv = P.sepBy(PIECsvParser.row, P.alt(P.string('\r\n'), P.string('\n')))

    static parse(parser: P.Parser<any>, text: string) {
        const result = parser.parse(text) as any
        if (result.status) {
            return ['', result.value]
        }

        const { expected, index } = result
        const { column, line } = index
        const _line = text.split('\n')[line - 1] ?? ''
        const error = `Expected: ${expected}
    ${_line}
    ${' '.repeat(column - 1)}^`

        return [error, undefined]
    }
}

function buildPortion(rows: string[][]): PIEPortion {
    const portion = rows[0][0] ?? ''

    rows = rows.filter(row => row[1] !== '') // remove rows with no passage name

    const passages = rows.map(row => {
        const passage: PIEPassage = {
            passage: row[1],
            passageType: row[2] ?? 'Translation',
            reference: row[3] ?? '',
            difficulty: row[4] ?? '1.0',
            tag: row[5] ?? '',
        }
        return passage
    })

    return { portion, passages }
}

function isNonEmptyRow(row: string[]) {
    const nonEmptyCells = row.filter(cell => cell.trim() !== '')
    return nonEmptyCells.length > 0 && !nonEmptyCells[0].startsWith('#')
}

/**
 * Parse project plan in CSV format to PIEPortion[].
 * Returns [error, PIEPortion[]].
 */
export function csvToPIE(csv: string): [string, PIEPortion[]] {
    let [error, _rows] = PIECsvParser.parse(PIECsvParser.csv, csv)
    if (error) return [error, []]

    let rows = _rows as string[][]
    rows = rows.slice(1).filter(row => isNonEmptyRow(row))

    // Gather all the passages for each portion
    const portions: string[][][] = [[]]

    for (let row of rows) {
        if (row[0] !== '') { // start a new entry for each portion
            portions.push([row])
        } else {
            portions[portions.length - 1].push(row) // add passage row to portion
        }
    }

    return ['', portions.slice(1).map(portionRows => buildPortion(portionRows))]
}

//--------------------------------------------------------
// Validate project plan (PIE)
//--------------------------------------------------------

function validatePIEPassage(passage: PIEPassage, portionName: string): string {
    if (!passage.passage) return `Portion [${portionName}] passage name blank or missing\n`

    const { reference, passageType, difficulty } = passage

    if (reference) {
        try {
            RefRange.parseReferences(reference, 'en', getBookNames('en'))
        } catch (e) {
            return `Passage[${passage.passage}] Reference[${reference}] invalid\n`
        }
    }

    if (passageType) {
        if (!getPassageContentTypes().includes(passageType)) {
            return `Passage[${passage.passage}] passageType[${passageType}] must be ${getPassageContentTypes().join(', ')}\n`
        }
    }

    if (difficulty) {
        const diff = parseFloat(difficulty)
        if (isNaN(diff) || diff < 0) {
            return `Passage[${passage.passage}] Difficulty must be a number >= 0\n`
        }
    }

    return ''
}

function validatePIEPortion(portion: PIEPortion): string {
    if (!portion.portion) return 'Portion name blank or missing\n'
    if (!portion.passages) return ''

    if (!Array.isArray(portion.passages)) return `Portion[${portion.portion}] Passages must be an array\n`

    const duplicates = duplicatedStrings(portion.passages.map(passage => passage.passage))
    const errors1 = duplicates ? `Portion[(${portion.portion}] duplicated passages: ${duplicates}\n` : ''

    const errors2 = portion.passages.map(passage =>
        validatePIEPassage(passage, portion.portion)).join('')
    return errors1 + errors2
}

function duplicatedStrings(arr: string[]): string {
    arr = arr.filter(item => !!item)

    const set = new Set<string>()
    const duplicates = new Set<string>()
    for (let item of arr) {
        if (set.has(item)) {
            duplicates.add(item)
        }
        set.add(item)
    }
    return Array.from(duplicates).join(', ')
}

// If PIE valid, return empty string, else return error messages string.
export function validatePIE(pie: PIEPortion[]): string {
    if (!Array.isArray(pie)) return 'Project plan must be an array'

    const duplicates = duplicatedStrings(pie.map(portion => portion.portion))
    const errors1 = duplicates ? `Duplicated portions: ${duplicates}\n` : ''

    const errors2 = pie.map(portion => validatePIEPortion(portion)).join('')
    return errors1 + errors2
}

//--------------------------------------------------------
// Convert project plan to CSV
//--------------------------------------------------------

function escapeCsvCell(cell: string) {
    if (cell.includes(',') || cell.includes('"')) {
        return `"${cell.replace(/"/g, '""')}"`
    }
    return cell
}

function passageToCsvRow(piePassage: PIEPassage): string[] {
    return [
        piePassage.passage,
        piePassage.passageType,
        piePassage.reference,
        piePassage.difficulty,
        "",   // do not include tags, they are usually unique to each project
    ].map(cell => escapeCsvCell(cell))
}

function portionToCsvRows(piePortion: PIEPortion): string[][] {
    const rows = piePortion.passages.map((passage, i) =>
        [i === 0 ? piePortion.portion : '', ...passageToCsvRow(passage)])
    return rows
}

const csvHeader = `portion,passage,type,reference,difficulty,tags`.split(',')

export function pieToCsv(pie: PIEPortion[]): string {
    const rows = pie.flatMap(portionToCsvRows)
    rows.unshift(csvHeader)
    const csv = rows.map(row => row.join(',')).join('\n')
    return csv
}

//--------------------------------------------------------
// Convert project to project plan (PIE)
//--------------------------------------------------------

function passageToPIE(passage: Passage, includeTags?: boolean): PIEPassage {
    return {
        passage: passage.name,
        passageType: passage.contentType,
        difficulty: passage.difficulty.toString(),
        reference: RefRange.refRangesToDisplay(passage.references),
        tag: includeTags ? passage.hashtags : '',
    }
}

export function projectToPIE(project: Project, includeTags?: boolean): PIEPortion[] {
    return project.portions
        .filter(portion => !portion.isGlossary)
        .map(portion => ({
            portion: portion.name,
            passages: portion.passages.map(p => passageToPIE(p, includeTags)),
        }))
}

//--------------------------------------------------------
// Append portions/passages to project as specified in PIE
//--------------------------------------------------------

async function assignPassageValues(passage: Passage, piePassage: PIEPassage) {
    const { passageType, difficulty, reference, tag } = piePassage

    await passage.setContentType((passageType as PassageContentType) ?? 'Translation')

    const diff = difficulty === '' ? 1.0 : parseFloat(difficulty)
    await passage.setDifficulty(diff)

    if (reference) {
        await passage.setReferences(RefRange.parseReferences(reference, 'en', getBookNames('en')))
    }

    if (tag) {
        await passage.setHashtags(tag)
    }
}

export async function appendPIE(project: Project, portions: PIEPortion[]) {
    let skipped = 0
    let added = 0

    for (let piePortion of portions) {
        let portion = project.portions.find(portion => portion.name === piePortion.portion)
        if (!portion) {
            portion = await project.addPortion(piePortion.portion)
        }

        for (let piePassage of piePortion.passages) {
            let passage = portion!.passages.find(passage => passage.name === piePassage.passage)
            if (!passage) {
                passage = await portion.addPassage(piePassage.passage)
                await assignPassageValues(passage, piePassage)
                added++
            } else {
                skipped++
            }
        }
    }

    return { skipped, added }
}
