import { observable, computed, makeObservable } from "mobx";
import { EventEmitter } from "events";

import { IDrawablePassageGloss, Passage, PassageNote, PassageNoteItem, PassageSegment, PassageVideo, PassageVideoReference, Portion, ProjectTerm } from "./ProjectModels";
import { ViewableVideoCollection } from "../components/video/ViewableVideoCollection";
import { VideoCache } from "./VideoCache"
import { fmt } from "../components/utils/Fmt";
import { RefRange } from "../scrRefs/RefRange";
import _ from "underscore";
import { NoteSelector } from "../components/notes/NoteSelector"
import { selectGlossById } from "../components/glosses/GlossTextSearch";
import { IRoot } from "./Root";
import { delay } from "../components/utils/delay";


/**
 * Sigh.
 * There is a complication in SLTT, I doubt we handle it well.
 * Sometimes the user wants to compare two different videos.
 * They do this by picking the double rectangle icon in the right pane
 * and selecting a different draft for the current passage (or less
 * commonly a different passage ... if the user has has not kept all
 * the drafts for one video in the same passage)
 * They can then play both videos at the same time or ping/pong
 * playing back and forth.
 * 
 * How to support this (including making as many functions as practical
 * work on both the left and right)?
 * Since we use the Root structure to communicate between all the various
 * controls we need separate Root instances for the left and right panes.
 * 
 * Sometimes these instances need to share a value, e.g. the currently selected
 * Portion. Sometimes they need their own value, e.g. is my video currently playing.
 * 
 * The class structure for the LEFT pane looks like this
 * 
 *     - Root extends RootBase
 * 
 * The class structure for the RIGHT pane looks like this
 * 
 *     - DraftVideoRoot extends RootBase
 * 
 * In addition, 
 *     - TRLVideoPosition extends RootBase 
 * 
 *       (to help with passing position, selection state between
 *        TRLVideoPlayer, VideoPositionBarControls,
 *        VideoPositionBar and ThinVideoPositionBar)
 * 
 * DraftVideoRoot contains a private attribute 'rt' that contains
 * the Root instance for the left pane.
 * 
 * What are the problems with this?
 *    - it is hard to keep such a complicated structure in mind
 *    - whenever functionality is added/changed that needs to appear
 *      in both left and right panes, we almost always have to fiddle
 *      with with both Root and DraftVideo root to make it happen.
 *    - We are using both delegation and inheritance to accomplish different
 *      parts of the functionality. Does inheritance really help here or
 *      does it just muddy the waters?
 *    - Does Root have to be one giant thing?
 * 
 * How could this be better?
 * Break Root down into several different interfaces.
 * Impliment each interface with a separate class.
 * If the interface needs a different implimentation in the right pane,
 * impliment that as a separate class.
 * Use mixins to create separate roots for left and right panes.
 */

import _debug from "debug"; let log = _debug('sltt:RootBase')

// Get a reference (i.e. RefRange[]) for passage or the current time in the passage video 
export function getRefRanges(
        portion: Portion | null, 
        passage: Passage | null,
        passageVideo: PassageVideo | null,
        passageSegment: PassageSegment | null,
        currentTime: number) 
{
    if (!portion || !passage) return []

    // If there is a verse reference in the current video at or before the current time, use it
    const refs = passageVideo?.getRefRanges(passage, currentTime)
    if (refs?.length) return refs

    // If there is a reference for the entire passage, use it
    const refs2 = passage.references
    if (refs2?.length) return refs2

    // If there is a verse reference anywhere in the current video, use the first one
    const refs3 = passageVideo?.references[0]?.references
    if (refs3?.length) return refs3

    // If a reference was set for this on any preceding segment, use it
    // Segment references are going away soon.
    return (passageVideo && passageSegment?.getRefRanges(passageVideo, passage)) ?? []
}

/**
 * CheckedItem is an _id returned by <SearchableItemsSelector>.
 * It can be an _id of
 * 
 *     Portion - corresponds to no specific PassageVideo. It is
 *               present to indicate that all the passage for the portion are checked.
 *     PassageVideo - the video with this _id
 *     Passage - it's a bit complicated
 *               If there are no _ids in the list for this passage, indicates that the
 *               latest video for this passage should be used.
 *               Otherwise, ignore this, and use the specific videos in the list for this passage
 */
export type CheckedItem = string

export class GlossTextSearchParameters {
    @observable searchIsOpen = false
    @observable glossIds: string[] = []
    @observable itemNumber = 0
    @observable searchText = ''
    @observable searchChecked: CheckedItem[] = []
    previousVideoId = ''

    constructor() {
        makeObservable(this);
    }

    async nextItem(rt: IRoot) {
        let { searchIsOpen, glossIds, itemNumber } = this
        if (!searchIsOpen) return
        if (itemNumber >= glossIds.length-1) return
        this.itemNumber = itemNumber + 1
        await this.playGloss(rt)
    }

    async previousItem(rt: IRoot) {
        let { searchIsOpen, itemNumber } = this
        if (!searchIsOpen) return
        if (itemNumber <= 0) return
        this.itemNumber = itemNumber - 1
        await this.playGloss(rt)
    }

    async playGloss(rt: IRoot) {
        let { glossIds, itemNumber } = this
        let glossId = glossIds[itemNumber] || ''
        if (!glossId) return

        let { gloss, duration } = await selectGlossById(rt, glossId)
        if (gloss) {
            while (true) {
                if (rt.currentVideos.viewableVideos.every(vv => vv.canPlayThrough)) break
                await delay(100)
            }

            await delay(200)

            let { time } = gloss
            log('playGloss', fmt({ itemNumber, glossId, time, duration }))
            rt.play(gloss.time, time + duration)
        }
    }
}

export interface IVideoPositionUtils {
    selectionPresent: () => boolean
}

export interface IVideoPlayerEmmitter {
    // Inform parent that user has requested a play or a stop.
    play: (startTime?: number, endingTime?: number) => void
    pause: () => void
    stop: (hitEndingTime?: boolean) => void
}

export class RootBase extends EventEmitter implements IVideoPositionUtils, IVideoPlayerEmmitter {
    @observable passage: Passage | null = null
    
    @observable note: PassageNote | undefined // When set, note dialog is open
    @observable lexicalLink = '' // When set, ERTermModeal dialog is open
    @observable noteSelector = new NoteSelector()
    @observable clonedNoteItem?: PassageNoteItem
    @observable mostRecentNoteIdViewed = ''

    // When this is set the reference editor is open in the video toolbar
    @observable verseReference?: PassageVideoReference

    @observable playing = false
    @observable currentTime = 0.0
    @observable passageVideo: PassageVideo | null = null
    @observable currentVideos = new ViewableVideoCollection()     // Info about the videos that make up passageVideo
    @observable duration = 0.0

    @observable dbsRefs: RefRange[] = []

    // Start/end time shown in VideoPositionBar
    @observable timelineZoom = 1    // set with setTimelineZoom
    @observable timelineStart = 0
    @observable timelineEnd = 0
    @observable glossScale = 0.0 // 0..100, 0 is narrow width glosses is GlossBar
    @observable glossIndex = -1 // -1 = no gloss selected for editing

    // Gloss currently being edited, if any
    @observable drawableGloss: IDrawablePassageGloss | null = null  // Gloss currently being edited, if any

    // Times for selection in timeline.
    // -1 = not set
    // Selection can span segments
    @observable selectionStartTime = -1
    @observable selectionEndTime = -1

    rootVideoPlayerListenersAdded = false

    gtsp = new GlossTextSearchParameters()

    constructor() {
        super()
        makeObservable(this);
        this.adjustTimelineZoomLimits = this.adjustTimelineZoomLimits.bind(this)
        this.setDuration = this.setDuration.bind(this)
        this.setDrawableGloss = this.setDrawableGloss.bind(this)
        this.play = this.play.bind(this)
        this.pause = this.pause.bind(this)
        this.stop = this.stop.bind(this)
        this.adjustCurrentTime = this.adjustCurrentTime.bind(this)
        this.resetCurrentTime = this.resetCurrentTime.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.setTimelineZoom = this.setTimelineZoom.bind(this)
        this.setPlaying = this.setPlaying.bind(this)
        this.setGlossScale = this.setGlossScale.bind(this)
        this._setPassageVideo = this._setPassageVideo.bind(this)
        this.createNoteIfNonexistent = this.createNoteIfNonexistent.bind(this)
        this.selectionPresent = this.selectionPresent.bind(this)
        this.setDbsRefs = this.setDbsRefs.bind(this)
        this.setNote = this.setNote.bind(this)
        this.setNoteSelector = this.setNoteSelector.bind(this)
    }

    @computed get canPlayThrough() {
        return this.currentVideos.downloaded
    }

    // Ensure that start/end point of time shown contains current time.
    adjustTimelineZoomLimits(forceAdjustment: boolean) {
        let { timelineZoom, timelineStart, timelineEnd, currentTime, duration } = this
        
        if (timelineZoom === 1) {
            if (timelineStart !== 0 || timelineEnd !== duration) {
                this.timelineStart = 0
                this.timelineEnd = duration
            }
            return
        }

        if (currentTime >= timelineStart && currentTime <= timelineEnd && !forceAdjustment) return
        
        let durationShown = duration / timelineZoom
        this.timelineStart = Math.max(0, currentTime - durationShown / 4)
        this.timelineEnd = Math.min(duration, currentTime + durationShown)
        log(`adjustTimelineZoomLimits ${this.timelineStart.toFixed(2)}..${this.timelineEnd.toFixed(2)}`)
    }

    setDuration(duration: number) {
        this.duration = duration
        this.adjustTimelineZoomLimits(true)
    }

    setDrawableGloss(gloss: IDrawablePassageGloss | null) {
        this.drawableGloss = gloss
    }

    /**
     * Play video in main window (RootVideoPlayer)
     * @param startTime - when present start playing video at this time, otherwise at time = 0
     * @param endPosition - when present stop playing video at this time, otherwise play to end
     * @param resetTime - when present, reset video time to this playing
     */
    play(startTime?: number, endPosition?: number, resetTime?: number) { 
        log('PLAY', fmt({startTime, endPosition, resetTime}))
        this.emit('play', startTime, endPosition, resetTime)
    }

    /**
     * Pause current video. Do not reset to specified reset time.
     */
    pause() {
        log('PAUSE')
        this.emit('pause')
    }

    stop() { 
        log('STOP')
        this.emit('stop') 
    }

    adjustCurrentTime(delta: number) {
        log(`adjustCurrentTime ${this.currentTime}, ${delta}`)
        const newTime = this.currentTime + delta
        this.resetCurrentTime(Math.max(0, Math.min(newTime, this.duration)))
    }

    resetCurrentTime(newTime: number, duration?: number) {
        log(`resetCurrentTime`, fmt({ newTime, duration }))
        if (duration !== undefined) {
            this.duration = duration // must happen before adjustTimelineZoomLimits
        }
        this.currentTime = newTime
        this.adjustTimelineZoomLimits(duration !== undefined)
        this.emit('setCurrentTime', newTime)
    }

    setCurrentTime(currentTime: number) {
        //log(`setCurrentTime=${currentTime}`)
        this.currentTime = currentTime
        this.adjustTimelineZoomLimits(false)
    }

    setTimelineZoom(zoom: number) {
        if (zoom < 1.999) zoom = 1
        this.timelineZoom = zoom
    }

    setPlaying(playing: boolean) {
        this.playing = playing
    }

    setGlossScale(scale: number) {
        // if (scale < 0) scale = 0
        // if (scale > 100) scale = 100
        this.glossScale = scale
        log(`setGlossScale ${scale.toFixed(2)}`)
    }

    selectionPresent() {
        return this.selectionStartTime >= 0 && this.selectionEndTime > this.selectionStartTime
    }

    // Return true if a selection is present and it can be patched.
    // If _displayError present, display reason why selection cannot be patched.
    patchableSelectionPresent(_displayError?: (message: string) => void) {
        if (!this.selectionPresent()) return false
        
        let { passageVideo } = this
        if (!passageVideo) return false

        return passageVideo.patchable(this.selectionStartTime, this.selectionEndTime, _displayError)
    }

    async _setPassageVideo(
            name: string, 
            passage: Passage | null, 
            passageVideo: PassageVideo | null,
            waitForDownload?: boolean) 
    {
        log('_setPassageVideo', fmt({name, passage, passageVideo, waitForDownload}))

        this.currentTime = 0
        this.selectionStartTime = -1
        this.selectionEndTime = -1

        if (!passageVideo || !passage) {
            this.passageVideo = null
            return
        }

        this.currentVideos.setup(passage, passageVideo)
        if (waitForDownload) {
            await this.currentVideos.download()
        } else {
            this.currentVideos.download().catch()
        }

        log(`passageVideo.url`, passageVideo && passageVideo.url)
        passageVideo.setSegmentTimes(passage)

        this.duration = passageVideo.computedDuration

        this.passageVideo = passageVideo
        this.adjustTimelineZoomLimits(true)

        // request download of all note videos for this passage video
        for (let note of passageVideo.notes) {
            for (let item of note.items) {
                if (item.url) {
                    await VideoCache.implicitVideoDownload(item.url)
                }
            }
        }

        const firstVisibleSegment = passageVideo.segments.find(s => s.actualSegment(passage)?.ignoreWhenPlayingVideo === false)
        this.setCurrentTime(firstVisibleSegment?.time ?? 0)

        this.emit('setPassageVideo')
    }

    /** 
     * When we get here there is already a note present in root.note.
     * If no items have yet been added to this note
     *    - the note will nothave been persisted to the database
     *    - the note will not be included in the passageVideo.notes array
     * video.addNote will add the note to the video and persist it to the database
     */
    async createNoteIfNonexistent() {
        let { passage, note } = this
        if (!passage || !note) return

        let video = passage.findVideo(note._id)
        if (!video) {
            debugger
            throw Error('No video for note')
        }

        let updatedVideo = await video.addNote(note)
        let updatedNote = updatedVideo.notes.find(n => note?._id === n._id)

        // We must update the note, since the reference has changed. Other components
        // rely on note, and they will not see changes if we do not update.
        // However, do not update if note dialog is closed, since this will reopen
        // note dialog! Do not update if note is undefined, since this will close
        // note dialog.
        if (updatedNote !== undefined) {
            this.setNote(updatedNote)
        }
    }

    setDbsRefs(portion: Portion | null, passageSegment: PassageSegment | null) {
        const { passageVideo, passage, currentTime } = this
        this.dbsRefs = getRefRanges(portion, passage, passageVideo, passageSegment, currentTime)
    }

    setNote(note?: PassageNote) {
        this.note = note
        if (note) {
            this.mostRecentNoteIdViewed = note._id
            this.resetCurrentTime(note.time)
        }
    }

    setNoteSelector(noteSelector: NoteSelector) {
        this.noteSelector = noteSelector
    }

    setLexicalLink(lexicalLink: string) {
        this.lexicalLink = lexicalLink
    }

}