// Show the queue of video blobs waiting to be downloaded.
// Updates every second(ish).
// Items relating to currently selected passage are currentPassageFlaged.

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import { ScheduledDownload } from '../../models3/VideoCacheDownloader'
import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import _ from 'underscore'
import { t } from 'ttag'
import { VideoDownloadResult } from '../../models3/VideoCacheRecord'

import _debug from "debug"; let log = _debug('sltt:DebugScheduledDownloads') 

interface IScheduledDownloadView {
    rt: Root,
    sd: ScheduledDownload,
}

function videoName(rt: Root, url: string) {
    // url e.g. = "ASL_FGH/190614_000231/190614_000440/190614_000631/191128_213112/191128_213127-25"
    let parts = url.split('/')
    let [projectId, portionId, passageId, videoId] = parts
    
    let portion = _.findWhere(rt.project.portions, {_id: portionId})
    if (!portion) return url

    let passage = _.findWhere(portion.passages, { _id: portionId + '/' + passageId })
    if (!passage) return url

    videoId = videoId.split('-')[0]
    let video = _.findWhere(passage.videos, { _id: portionId + '/' + passageId + '/' + videoId})
    if (!video) return url

    return `${portion.name} / ${passage.name} [${video.creator}] ${video.isPatch ? 'patch' : ''} [${url}] ${parts.length > 4 ? '*note*' : ''}`
}

function ScheduledDownloadView(props: IScheduledDownloadView) {
    let { rt, sd } = props

    let currentPassageFlag = ''
    if (rt.passage) {
        let current = `${rt.name}/${rt.passage._id}`
        if (sd.videoUrl.startsWith(current)) currentPassageFlag = '=== '
    }

    let progress = ''
    // sd.progress is non-null when a internet fetch is in progress
    if (sd.progress) {
        let percent = sd.progress.total ? 100*sd.progress.loaded/sd.progress.total : 0
        progress = `  / ${sd.progress.total} ${percent.toFixed(1)}`
    }

    let { vcr, retries, delayUntil } = sd
    let index = vcr.downloadResults.findIndex(dr => dr !== VideoDownloadResult.SUCCESS)
    let result = index >= 0 ? `${vcr.downloadResults[index]}-${index}` : 'DONE'
    let _delayUntil = ''
    if (delayUntil > 0) {
        _delayUntil = ` [delay=${((delayUntil - Date.now())/1000).toFixed(0)}s]`
    }

    return (
        <div>
            {currentPassageFlag}
            {`[${sd.priority}] `}
            {`${videoName(rt, sd.videoUrl)}`}
            {progress}
            {result}
            {retries > 0 ? ` [retries=${retries}]` : ''}
            {_delayUntil}
        </div>
    )
}

interface IDebugScheduledDownloads {
    rt: Root,
}

class DebugScheduledDownloads extends Component<IDebugScheduledDownloads> {
    @observable scheduledDownloads: ScheduledDownload[] = []
    @observable usage = ''
    @observable quota = ''
    handler: any = null

    constructor(props: IDebugScheduledDownloads) {
        super(props)

        makeObservable(this);

        VideoCache.videoCacheDownloader.getQuotaAndUsage()
            .then(quotaAndUsage => {
                let { quota, usage } = quotaAndUsage
                usage = (usage / (1024 * 1024)).toFixed(0)
                quota = (quota / (1024 * 1024)).toFixed(0)
                this.usage = usage
                this.quota = quota
            })
    }

    componentDidMount() {
        let _fetch = () => {
            this.scheduledDownloads = VideoCache.queryScheduledDownloads()
        }

        this.handler = setInterval(_fetch , 500)
    }

    componentWillUnmount() {
        this.handler && clearInterval(this.handler)
        this.handler = null
    }

    render() {
        let { scheduledDownloads } = this
        let { rt } = this.props

        return (
            <div>
                {(scheduledDownloads.length === 0) && <div>{t`Nothing waiting to be downloaded.`}</div>}
                { scheduledDownloads.map(sd => (
                    <ScheduledDownloadView
                        rt={rt}
                        sd={sd}
                    />
                ))}
                <div>
                    {this.usage !== '' && this.quota !== '' && t`cached(MB)=${this.usage}, quota=${this.quota}`}
                </div>
            </div> 
        )
    }

}

export default observer(DebugScheduledDownloads)
