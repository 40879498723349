import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { getByIso639dash1, iso639dash1Langs } from './Languages'
import { uiLanguages } from '../app/NavigationBar'
import './InputLanguageSelector.css'

interface IInputLanguageSelector {
    primaryInputLanguage: string,
    currentValue: string,
    selectorTooltip: string,
    disabled: boolean,
    getOptionTooltip: (option: string, primaryInputLanguage: string) => string,
    chooseOption: (option: string) => void,
}

export const InputLanguageSelector: FC<IInputLanguageSelector> = observer(
    ({ primaryInputLanguage, currentValue, selectorTooltip, disabled, getOptionTooltip, chooseOption }) => {
        const isPrimaryInputLanguage = currentValue === primaryInputLanguage
        return <select
                className={`input-language-selector ${isPrimaryInputLanguage ? 'primary-input' : 'secondary-input'}`}
                value={currentValue}
                style={{
                    fontSize: getFontSizeBasedOnAlphabet(
                        getByIso639dash1(currentValue)?.local,
                        'inherit'
                    )
                }}
                onChange={e => chooseOption(e.target.value)}
                title={getOptionTooltip(currentValue, primaryInputLanguage) || selectorTooltip}
                disabled={disabled}
            >
                <option disabled key="choose" value="">{selectorTooltip}</option>
                {uiLanguages.map(option => <option
                    className={isPrimaryInputLanguage ? 'primary-input' : 'secondary-input'}
                    key={option.code}
                    value={option.code}
                    label={`${option.fullName} (${option.code})`}
                    title={getOptionTooltip(option.code, primaryInputLanguage)}
                >
                    {option.fullName}
                </option>)}
                <option disabled>_________</option>
                {iso639dash1Langs.map(option => (
                    <option
                        key={option['1']}
                        value={option['1']}
                        className={isPrimaryInputLanguage ? 'primary-input' : 'secondary-input'}
                        style={{ fontSize: getFontSizeBasedOnAlphabet(option.local) }}
                        title={getOptionTooltip(option['1'], primaryInputLanguage)}
                        label={`${option.local} (${option['1']})`}
                    >{option.local}</option>
                ))}
        </select>
    })

function getFontSizeBasedOnAlphabet(
    local: string,
    latinFontSize: string = '18px',
    nonRomanFontSize: string = '20px'
) {
    return local?.match(/[a-z]/i) ? latinFontSize : nonRomanFontSize
}

