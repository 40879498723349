import React, { FC, useContext, useMemo } from 'react'
import { observer } from 'mobx-react'
import { PortionsListTRLContext, ProjectTRLIssue, getMissingTitlesMessage, getPortionTitleStatus, getProjectTRLIssueTooltip, getTRLIconBadgeClass, portionIsPublishedInTRL } from '../utils/TRL'
import { TitleNotification, PublishTRLNotification } from './Notifications'
import { Root } from '../../models3/Root'
import { Portion } from '../../models3/ProjectModels'


interface ITrlNotificationList {
    rt: Root,
    portion: Portion,
    onToggleTitles: () => void,
    onToggleTRL: () => void,
}

export const TrlNotificationList: FC<ITrlNotificationList> = observer((
    { rt, portion, onToggleTitles, onToggleTRL }
) => {
    const isTRLPublishedPublished = useMemo(() => portionIsPublishedInTRL(rt, portion._id),
        [rt.trlResources]
    )
    const trlContext = useContext(PortionsListTRLContext)
    const { titleLanguage, projectTrlIssue } = trlContext
    const { canShowTitleEditor, isMissingTitle } = getPortionTitleStatus(trlContext, portion)
    if (!canShowTitleEditor) 
        return <div /> 
    const missingTitlesMessage = getMissingTitlesMessage(titleLanguage)
    const hasUnfinishedPassages = portion.finalPassages.some(passage => passage.task !== 'Finished')
    const missingTitleLanguage = isMissingTitle ? titleLanguage : ''
    const trlIssueToolTip = getTrlToolTip({ missingTitleLanguage, hasUnfinishedPassages, projectTrlIssue, isTRLPublishedPublished })
    const { trlBadgeClass, hasTrlError, hasTrlWarning } = getTrlBadge({ isMissingTitle, hasUnfinishedPassages, projectTrlIssue, isTRLPublishedPublished })
    const syncClass = `sync-${Boolean(isTRLPublishedPublished && (hasTrlError || hasTrlWarning))}`
    return (
        <div>
            <TitleNotification
                className={`portion-button ${isMissingTitle && 'portion-title-notification' || 
                    'portion-shadow-menu-item'}`}
                onToggleTitles={onToggleTitles}
                tooltip={isMissingTitle &&
                    missingTitlesMessage ||
                    `View titles.`}
            />
            <PublishTRLNotification
                className={`portion-button ${isMissingTitle && 'portion-shadow-menu-item' || 
                    'portion-publish-trl-notification'}`}
                tooltip={trlIssueToolTip}
                badgeClass={trlBadgeClass}
                syncClass={syncClass}
                onToggleTRL={() => {
                    if (isMissingTitle) {
                        onToggleTitles()
                    }
                    else {
                        onToggleTRL()
                    }
                }} 
            />
        </div>
    )
})
function getTrlToolTip({ missingTitleLanguage, hasUnfinishedPassages, projectTrlIssue, isTRLPublishedPublished }: 
    { missingTitleLanguage: string, hasUnfinishedPassages: boolean; projectTrlIssue: ProjectTRLIssue; isTRLPublishedPublished: boolean} ) {    
    if (missingTitleLanguage) {
        return getMissingTitlesMessage(missingTitleLanguage)
    }
    const trlTooltipDefault = getToolTipDefault({ hasUnfinishedPassages, isTRLPublishedPublished })
    const trlIssueToolTip = getProjectTRLIssueTooltip(projectTrlIssue, trlTooltipDefault)
    return trlIssueToolTip
}
function getTrlBadge({ isMissingTitle, hasUnfinishedPassages, projectTrlIssue, isTRLPublishedPublished }:
    { isMissingTitle: boolean, hasUnfinishedPassages: boolean; projectTrlIssue: ProjectTRLIssue; isTRLPublishedPublished: boolean} ) {
    const hasTrlProjectError = projectTrlIssue !== ProjectTRLIssue.None
    // if published, don't show unfinishedPassages badge
    const hasTrlError = isMissingTitle || hasTrlProjectError
    const hasTrlWarning = !isTRLPublishedPublished && hasUnfinishedPassages
    const trlBadgeClass = getTRLIconBadgeClass({ isTRLPublishedPublished, hasTrlError, hasTrlWarning })
    return { trlBadgeClass, hasTrlError, hasTrlWarning }
}
function getToolTipDefault({ hasUnfinishedPassages, isTRLPublishedPublished }: { hasUnfinishedPassages: boolean; isTRLPublishedPublished: boolean} ) {
    // if published, don't show unfinishedPassages tooltip
    if (isTRLPublishedPublished) {
        return `Published to Translation Resource Library`
    } else {
        return hasUnfinishedPassages && `Some passages do not have status "finished"` || undefined
    }
}
