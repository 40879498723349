import { useContext } from "react"
import { RootContext } from "../app/RootContext"
import { Project } from "../../models3/ProjectModels"

// Hook to get the creator name from the creator email
export function useCreatorName(creator: string) {
    const rt = useContext(RootContext)
    const creatorName = getCreatorName(rt!.project, creator)
    const title = creatorName !== creator ? creator : undefined
    return { creatorName, title }
}


// Function to get creator name from project / creator email
export function getCreatorName(project: Project, creator: string) {
    return project.creatorName(creator)
}
