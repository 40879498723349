import { _LevelupDB } from './_LevelupDB'
import API from "./API"
import { fetchBlob2, IReportProgress } from './API2'
import { VideoCache } from './VideoCache'
import { Root } from './Root'
import { systemError } from '../components/utils/Errors'

import _debug from "debug"
const log = _debug('sltt:VideoCacheRecord')
const debug = _debug('slttdbg:VideoCacheRecord')
import { getAuthorizedStorageProjects, isSlttAppStorageEnabled, retrieveBlob, storeBlob, storeVideoCacheRecord } from './SlttAppStorage'
import { delayUntilOnline } from '../components/utils/ServiceStatus'

const intest = (localStorage.getItem('intest') === 'true')

export enum VideoDownloadResult {
    SUCCESS = 'success', // Download of blob worked 
    MISSING = 'missing', // Blob has not been uploaded yet
    ERROR = 'error', // Retriable error
    FAILED = 'failed', // Permanent error 
    NONE = '', // Have not tried to download blob yet
}

/*
 * Cache signedUrl so that we do not have to repeatedly fetch them from backend when doing
 * error or missing retries.
 */
export class SignedUrlFactory {
    signedUrlMap = new Map<string, string>()

    constructor() {
        setInterval(() => this.clearSignedUrlMap(), 20*3600*1000)
    }

    clearSignedUrlMap() {
        this.signedUrlMap = new Map<string, string>()
    }

    // if url created for this check in the last 20 hours, return it
    // otherwise fetch a new signed URL.
    // Returns [error, signedUrl]
    // Does not throw.
    async getSignedUrl(projectName: string, url: string): Promise<[Error | null, string]> {
        let { signedUrlMap } = this

        let signedUrl = signedUrlMap.get(url)
        if (signedUrl) return [null, signedUrl]

        let [error, _signedUrl] = await API._getUrl(url)
        if (error) return [error, '']
        signedUrlMap.set(url, _signedUrl)

        return [null, _signedUrl]
    }
}

/*
 * A VideoBlob is a video file or a section of a video file.
 * Sections are uploaded individually to S3 then concatenated to reform original file.
 * Example id: TESTnm/210202_183235/211208_164428/220119_201027-5
 *     The 5th blob of a video created on Jan 19, 2022
 * 
 * We store these in a separate table from the video cache records because the cache
 * records are small and can ge kept in memory, but these records are videos and are large.
 */

export class VideoBlob {
    constructor(public _id: string, public blob?: Blob) {
        if (!this._id) throw Error('No _id')
    }

    async saveToDB() {
        if (!this.blob) throw Error('No blob to save')
        try {
            const blobString = await serializeBlob(this.blob)
            // This regeneratedBlob may be overkill for all contexts (e.g. downloading), but...
            // the `Data Error: Failed to write blobs (Invalid Blob)` error will happen in browsers with the latest versions of 
            // Chromium (129.0.6668.71) (see https://issues.chromium.org/issues/369670458, and see https://github.com/ubsicap/sltt/issues/982)
            // As far as I (EricP) can this only happens in the context of attempting to cache (4 MB) blob chunks 
            // which were sliced from a file bound to an input element (e.g. from a file upload dialog or drop-and-drop target).
            // It does NOT happen when the same blobs are downloaded from s3.
            // TODO: research whether it's sufficient to regenerate the blob for `vcr.addBlob(blob)` in `copyMultipartFileToVideoCache()`
            // For now, we'll always regenerate the blob to make sure we catch all possible paths with the hope that this
            // will not cause a noticeable performance issue for downloading.
            const regeneratedBlob = deserializeBlob(blobString)
            // log(`blobString (${this._id})`, blobString)
            await VideoCache._db.put(VideoCache.VIDEOBLOBS, { _id: this._id, blob: regeneratedBlob })
        } catch (error) {
            throw Error(`saveToDB ${this._id} ${error}`)
        }
        log(`saveToDB ${this._id}`)
    }

    async loadFromDB(mustBePresent?: boolean) {
        let doc = await VideoCache._db.get(VideoCache.VIDEOBLOBS, this._id)
        if (!doc || !doc.blob) {
            if (mustBePresent) {
                debugger
                throw Error(`not present in VIDEOBLOBS ${this._id}`)
            }
            this.blob = undefined
            return
        }

        this.blob = doc.blob
    }
}

async function serializeBlob(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            resolve(reader.result as string)
        }
        reader.onerror = reject
        reader.readAsDataURL(blob) // You can also use readAsBinaryString or readAsArrayBuffer
    })
}

function deserializeBlob(blobString: string): Blob {
    const byteString = atob(blobString.split(',')[1]);
    const mimeString = blobString.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}

/*
 *  We keep a record for each locally cached video.
 */
export class VideoCacheRecord {
    static systemError = (err: any) => { throw Error(err) }
    static _count = 0

    public _id = ''  // e.g. TESTnm/210202_183235/211208_164428/220119_201027-5
    public size = 0  // number of bytes in this video

    // When this array is non-empty, the video has been broken into segments.
    // videBlobId(i) names the segments starting with index 0.
    // The i'th entry is true iff the corresponding segment blob has been uploaded.
    public uploadeds: boolean[] = []

    /*
     * When a video has been downloaded locally the following two arrays will be non empty.
     * There will be one entry for each Blob.
     */
    public downloadeds: boolean[] = []
    public downloadResults: VideoDownloadResult[] = []

    static forcedDownloadResult = (localStorage.getItem('forcedDownloadResult') ?? '') as VideoDownloadResult

    static signedUrlFactory = new SignedUrlFactory()

    // Show what has been uploaded so far.
    // If the video is being uploaded from another computer we don't know for sure.
    public get uploadedBlobs() {
        // uploadeds is non-empty iff video uploaded from current computer,
        // we know what is uploaded so far
        if (this.uploadeds.length > 0) return [...this.uploadeds]

        // Video was uploaded from another computer, start by guessing everything uploaded
        let uploadeds = Array(this.downloadeds.length).fill(true)

        // If no record is currently showing with a 403 error assume (for now)
        // that everything has been successfully uploaded at the other end
        let i = this.downloadResults.indexOf(VideoDownloadResult.MISSING)
        if (i < 0) return uploadeds

        // We found a 403, assume everything from that point on has not been uploaded yet
        uploadeds.fill(false, i, uploadeds.length)
        return uploadeds
    }

    public downloadMessage = ''

    public accessDate = ''

    public uploadStartTimeMs = -1
    public uploadFinishTimeMs = -1

    public path = ''
    private token = ''
    public serverUrl = ''

    // If the download/upload for this video failed, the error reason is here.
    public error = ''

    private static cache = new Map<string, VideoCacheRecord>()

    static updateCache(vcr: VideoCacheRecord) {
        let { cache } = VideoCacheRecord
        cache.set(vcr._id, vcr)
    }

    // Return vcr if present in cache
    static _get(_id: string) {
        let { cache } = VideoCacheRecord
        return cache.get(_id)
    }

    static async get(_id: string) {
        let { cache } = VideoCacheRecord
        let vcr = cache.get(_id)
        if (vcr) return vcr

        /* Sigh, there was a bad error for 2 months starting mid June 2020
        * when creating url fields for PassaageNoteItem.
        * the project name, which is the first thing in the url, was left empty.
        * Add a check to see if the video was cached under the no project name url.
        * This avoids needing to redownload the video which may be slow.
        */

        let parts = _id.split('/')
        let _idNoProjectName = '/' + parts.slice(1).join('/')
        vcr = cache.get(_idNoProjectName)
        if (vcr) { 
            log('cache HIT _idNoProjectName')
            return vcr 
        }

        // No cache entry for this video, create one
        vcr = new VideoCacheRecord({_id})
        cache.set(_id, vcr)
        return vcr
    }

    // Normally you should not use this.
    // Use static get() above so there is a cached copy
    constructor(doc: { _id: string }) {
        if (!doc._id) throw Error('No _id') // must at least have _id
        this.downloadResults = new Array(this.seqNum(doc._id)).fill(VideoDownloadResult.NONE)
        
        this.clone(doc)
    }

    dbg() {
        let { _id, uploadeds, downloadeds, error } = this
        return { _id, uploadeds, downloadeds, error }
    }

    // WARNING if new attributes added to this class, they MUST be added here
    // or constructor will not work correctly.
    private clone(doc: any) {
        this._id = doc._id
        this.size = doc.size || 0
        // this.blobsCount = doc.blobsCount || 0
        this.uploadeds = doc.uploadeds || []
        this.downloadeds = doc.downloadeds || []
        this.accessDate = doc.accessDate || ''
        this.error = doc.error || ''
        this.downloadMessage = doc.downloadMessage || ''

        this.uploadStartTimeMs = doc.uploadStartTimeMs || -1
        this.uploadFinishTimeMs = doc.uploadFinishTimeMs || -1
    }

    
    get downloadProgress() {
        if (this.downloadeds.length === 0) return 100

        let downloadedBlobs = (this.downloadeds.filter(x => x)).length
        return Math.floor((100 * downloadedBlobs / this.downloadeds.length) + 0.5)
    }

    // The video data is present in cache if download is complete OR
    // it was generated locally
    get hasBeenCached() {
        return this.downloaded /* generated remotely and downloaded */ ||
            this.uploadeds.length /* generated locally so started out in cache */
    }

    // All blobs for this video have been downloaded from S3
    get downloaded() {
        return this.downloadeds.length && this.downloadeds.every(d => d)
    }

    // All blobs for this locally generated video have been uploaded to S3
    get uploaded() {
        return this.uploadeds.length > 0 && this.uploadeds.every(d => d)
    }

    // Some blobs for this VCR need uploaded
    get needsUploaded() {
        return this.uploadeds.length > 0 && !this.uploadeds.every(d => d)
    }

    get uploadCount() {
        return this.uploadeds.filter(d => d === false).length
    }

    // If video did not originate on this computer, this is partly a guess
    get numberUploaded() {
        return this.uploadedBlobs.filter(Boolean).length
    }

    get isLocallyCreatedVideo() {
        return this.uploadeds.length > 0
    }

    get numberDownloaded() {
        // If locally created they are by definition already downloaded
        if (this.isLocallyCreatedVideo) return this.totalBlobs

        return this.downloadeds.filter(Boolean).length
    }

    get totalBlobs() {
        let { _id } = this
        // _id is similar to: TESTnm/200829_201205/210519_153942/210519_153943-9
        // The -9 is the blob count.

        try {
            return parseInt(_id.slice(_id.lastIndexOf('-')+1))
        } catch (err) {
            throw Error('Video _id missing blob count ' + _id)
        }
    }

    // Id without the project and sequence number
    get docId() {
        let { _id } = this
        return _id.slice(_id.indexOf('/') + 1, _id.lastIndexOf('-'))
    }

    findPortion(rt: Root) {
        return rt.project.findPortion(this.docId)
    }

    findPassage(rt: Root) {
        return rt.project.findPassage(this.docId)
    }

    findNote(rt: Root) {
        return this.findPassage(rt)?.findNote(this.docId)
    }

    findVideo(rt: Root) {
        return this.findPassage(rt)?.findVideo(this.docId)
    }

    /**
     * Setup to download the s3 items for this video, if this has not already been done
     */
    async setupDownload() {
        if (!this.downloadeds || !this.downloadeds.length) {
            this.downloadeds = new Array(this.seqNum(this._id)).fill(false)
            await this.saveToDB()
        }      
    }

     /**
     * Access this cache record. This will make it less likely
     * that it is deleted from cache when more space is needed.
     */
    async touch() {
        this.accessDate = _LevelupDB.getDate()
        await this.saveToDB()
    }

    async resetError() {
        if (this.error) {
            this.error = ''
            await this.saveToDB()
        }
    }

    async setError(_id: string, error: string) {
        log(`setError ${error}, ${_id}`)

        this.error = error ?? '*** error message missing ***'
        await this.saveToDB()
    }

    async setInvalidSeqNumError(_id: string) {
        this.error = `BUG: Invalid sequence number ${_id}`
        await this.saveToDB()
    }

    private async tryLoadBlobFromDisk(blobId: string) {
        if (!isSlttAppStorageEnabled()) return null

        const blob = await retrieveBlob({ blobId }, 'tryLoadBlobFromDisk') || null
        if (!blob) return null
        let videoBlob = new VideoBlob(blobId, blob)
        await videoBlob.saveToDB()
        this.size += blob.size
        await this.saveToDB()
        return videoBlob
    }

    
    async storeBlobsNeedingUploadToDisk() {
        if (!isSlttAppStorageEnabled()) return
        const { uploadeds } = this
        // save all to disk before uploading to ensure they all get stored
        await this.resetError()
        const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
        for (let i = 0; i < uploadeds.length; ++i) {
            if (uploadeds[i]) continue // skip uploaded

            // copied/refactored from this.uploadOneBlobToS3()
            const blobId = this.videoBlobId(i)
            if (!authorizedStorageProjects.has(this.projectName())) {
                // if the project is not authorized to store blobs, skip writing to disk
                continue
            }
            const seqNum = await this.tryGetSeqNum(blobId)
            if (Number.isNaN(seqNum)) return false
            let videoBlob = await this.tryLoadVideoBlob(blobId, false) // might set error
            if (!videoBlob?.blob) {
                // try load from disk first. maybe the video cache got cleared?
                videoBlob = await this.tryLoadBlobFromDisk(blobId)
            }
            if (!videoBlob?.blob) return false
            await storeBlob(videoBlob.blob!, { blobId }, 'storeBlobsNeedingUploadToDisk')
        }
        return true
    }

    // should not throw due to network errors
    async uploadToServer(decrementUploadCount: () => void) {
        let { uploadeds } = this
        await this.resetError()
        for (let i = 0; i < uploadeds.length; ++i) {
            // If VideoBlob already uploaded, skip it
            if (uploadeds[i]) continue

            let successful = await this.uploadOneBlobToS3(this.videoBlobId(i)) // should not throw due to network errors
            if (successful) {
                decrementUploadCount()
                uploadeds[i] = true
                await this.saveToDB()
            } else {
                break
            }
        }
    }

    async saveUploadStartTime() {
        this.uploadStartTimeMs = Date.now()  // current time in ms
        await this.saveToDB()
    }

    async saveUploadFinishTime() {
        this.uploadFinishTimeMs = Date.now()  // current time in ms
        await this.saveToDB()
    }

    async saveUploadRequest() {
        this.path = VideoCacheRecord.baseUrl(this._id)
        this.token = API.id_token
        this.serverUrl = API.getHostUrl()
        await this.saveToDB()
    }

    async sanitize() {
        this.token = ''
        this.serverUrl = ''
        await this.saveToDB()
    }

    /**
     * Side-effect saves error to vcr and returns Number.NaN
     * @param blobId
     * @returns seqNum: number | Number.NaN
     */
    private async tryGetSeqNum(blobId: string) {
        let seqNum = this.seqNum(blobId)
        if (seqNum === -1) {
            await this.setInvalidSeqNumError(blobId)
            return Number.NaN
        }
        return seqNum
    }

    /**
     * Side-effect: If error: saves error to vcr and returns null
     * @param blobId 
     * @returns VideoBlob | null
     */
    private async tryLoadVideoBlob(blobId: string, mustBePresent = true) {
        const videoBlob = new VideoBlob(blobId)
        try {
            await videoBlob.loadFromDB(mustBePresent)
            return videoBlob
        } catch (error) {
            this.error = `BUG: loadFromDB failed ${blobId}`
            this.uploadeds = []
            await this.saveToDB()
            return null
        }
    } 

    /* ===== Uploader Routines ===== */
    // Upload a single cached blob to server
    // Return true if successful.
    // Network related error should not cause a throw.
    private async uploadOneBlobToS3(_id: string) {
        log(`uploadToS3 start [${_id}]`)

        let projectName = this.projectName()
        let seqNum = await this.tryGetSeqNum(_id)
        if (Number.isNaN(seqNum)) return false

        let videoBlob = await this.tryLoadVideoBlob(_id)
        if (!videoBlob) return false

        let retryLimit = 5
        while (retryLimit > 0) {
            try {
                await API.pushBlob(projectName, VideoCacheRecord.baseUrl(_id), seqNum, videoBlob.blob!)
                log(`uploadToS3 done [${_id}]`)
                await this.resetError()
                return true
            } catch (error) {
                log(`uploadToS3 error`, error)
                await this.setError(_id, (error as Error).message)
            } finally {
                --retryLimit
            }
        }

        return false
    }

    uploadRate() {
        let { uploadStartTimeMs, uploadFinishTimeMs, size } = this
        if (uploadStartTimeMs === -1 || uploadFinishTimeMs === -1) {
            return 0
        }
        return (size / 1024) / ((uploadFinishTimeMs - uploadStartTimeMs + 1) / 1000)    // KB/s
    }

    /* ===== Downloader Routines ===== */

    /* Fetch the j'th (0 index) blob for this videos.
     * Return emoty string on success, otherwise error message.
     * Set downloadResults[j] to status from enum VideoDownloadResult.
     * Sets downloads[j] = true on success.
     * Do NOT throw an exception
    **/
    async fetchBlob(j: number, reportProgress: IReportProgress): Promise<VideoDownloadResult> {
        let result: VideoDownloadResult

        // You can test handling of missing (not yet uploaded) blob by entering this is the console
        //    localStorage.forcedDownloadResult='missing'   [refresh window]
        // Temporarily remove forced download response
        //    window.VideoCacheRecord.forcedDownloadResult=''
        // Permanently remove forced download response
        //    localStorage.forcedDownloadResult=''    [refresh window]
        // Similarly for 'error' and 'failed'
        let { forcedDownloadResult } = VideoCacheRecord
        if (forcedDownloadResult !== VideoDownloadResult.NONE) {
            let jStart = parseInt(localStorage.getItem('forcedDownloadResultJ') ?? '0')
            if (j >= jStart) {
                log('fetchBlob forcedDownloadResult', `${this._id}[${j + 1}]`)
                this.downloadResults[j] = forcedDownloadResult
                return forcedDownloadResult
            }
        }

        try {
            result = await this._fetchBlob(j, reportProgress)
            log('fetchBlob result', `${this._id}[${j + 1}]`)
        } catch (error) {
            systemError(error)
            result = VideoDownloadResult.FAILED
        }

        this.downloadResults[j] = result
        return result
    }

    async _fetchBlob(j: number, reportProgress: IReportProgress): Promise<VideoDownloadResult> {
        let url = this.videoBlobId(j)
        log('_fetchBlob', url)

        let vb = new VideoBlob(url)
        await vb.loadFromDB()
        if (vb.blob) {
            // Someone got here first and already downloaded this
            this.downloadeds[j] = true
            await this.saveToDB()
            return VideoDownloadResult.SUCCESS
        }
        const blobFromSlttAppContext = isSlttAppStorageEnabled() &&
            await retrieveBlob({ blobId: url }, '_fetchBlob') || null
        let blob = blobFromSlttAppContext || new Blob()
        if (!blobFromSlttAppContext) {
            await delayUntilOnline('_fetchBlob', 1000)
            let [error1, signedUrl] = await VideoCacheRecord.signedUrlFactory.getSignedUrl(this.projectName(), url)
            if (error1) return VideoDownloadResult.ERROR
            try {
                blob = await fetchBlob2(signedUrl, reportProgress)
            } catch (error) {
                return VideoDownloadResult.ERROR
            }
        }

        let videoBlob = new VideoBlob(url, blob)
        await videoBlob.saveToDB()

        this.size += blob.size
        this.downloadeds[j] = true
        await this.saveToDB()
        if (isSlttAppStorageEnabled() && !blobFromSlttAppContext) {
            const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
            if (authorizedStorageProjects.has(this.projectName())) {
                await storeBlob(blob, { blobId: url }, '_fetchBlob')
            }
        }
        return VideoDownloadResult.SUCCESS
    }    

    async setDownloadMessage(message: string) {
        debug(`setDownloadMessage ${message}`)

        this.downloadMessage = message
        // Dont do this, causes too many DB writes and we don't really need to persist
        //await this.saveToDB()
    }

    // Extract the sequence number (-n) at end of id
    seqNum(_id?: string) {
        let id = _id || this._id
        let i = id.lastIndexOf('-')
        if (i === -1) {
            return -1
        }
        return parseInt(id.slice(i + 1))
    }

    // Get the id without the sequence number
    static baseUrl(_id: string) {
        let i = _id.lastIndexOf('-')
        if (i === -1) {
            throw Error(`no seqnum in _id [${_id}]`)
        }
        return _id.slice(0, i)
    }

    // Return the id of the i'th blob (0 origin)
    videoBlobId(i: number) { 
        return `${VideoCacheRecord.baseUrl(this._id)}-${i + 1}` 
    }

    projectName() { return this._id.split('/')[0] }

    async saveToDB(tag?: string, skipWriteToDisk?: boolean) { 
        tag && log(tag, JSON.stringify(this, null, 4))

        try {
            await VideoCache._db.put(VideoCache.CACHEDVIDEOS, this)
            VideoCacheRecord.updateCache(this)
            if (isSlttAppStorageEnabled() && !skipWriteToDisk) {
                const authorizedStorageProjects = new Set(await getAuthorizedStorageProjects())
                if (authorizedStorageProjects.has(this.projectName())) {
                    await storeVideoCacheRecord({ videoCacheRecord: this }, 'VideoCacheRecord.saveToDB')
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                this.error = error.message
            } else {
                // not sure what does `throw null` but it has happened
                // see https://app.rollbar.com/a/biblesocieties/fix/item/SLTT/977
                // and https://app.rollbar.com/a/biblesocieties/fix/item/SLTT/540
                this.error = `Unknown Error: ${JSON.stringify(error)}`
            }
        }
    }

    /**
     * @return {boolean} true iff a record with this id is already present in DB
     */
    async loadFromDB(tag?: string) {
        let doc = await VideoCache._db.get(VideoCache.CACHEDVIDEOS, this._id)
        if (doc) {
            tag && log(`loadFromDB [${tag}] ${this._id}`, doc)
            this.clone(doc)
        } else {
            tag && log(`loadFromDB ${this._id} [not present yet]`)
            return false
        }

        return true
    }

    // Delete cached blobs and reset state of cache entry to not downloaded.
    // This frees up space in our disk quota.
    async deleteBlobs() {
        let { _id } = this

        for (let i = 0; i < this.seqNum(_id); ++i) {
            await VideoCache._db.delete(VideoCache.VIDEOBLOBS, this.videoBlobId(i))
        }
        
        this.uploadeds = []
        this.downloadeds = []
        await this.saveToDB()
    }

    // Add a blob to be uploaded and save blob in database
    async addBlob(blob: Blob) {
        this.uploadeds.push(false)
        this.size += blob.size

        let id = this.videoBlobId(this.uploadeds.length-1)
        debug('addBlob', id, blob.size)

        let videoBlob = new VideoBlob(id, blob)
        await videoBlob.saveToDB()
    }

    // Read and concatentate individual blobs to create complete video blob
    async getVideoBlob(_type?: string): Promise<Blob | null> {
        let { _id } = this
        let blobs: Blob[] = []

        await this.touch()

        let seqNum = this.seqNum(_id)
        if (seqNum <= 0) throw Error(`no seq number ${_id}`)

        let failed = false

        for (let i = 0; i < seqNum; ++i) {
            let videoBlob = new VideoBlob(this.videoBlobId(i))
            await videoBlob.loadFromDB(false)
            if (videoBlob.blob) {
                blobs.push(videoBlob.blob)
            } else {
                // Something has gone wrong, blob is not present in cache
                this.downloadeds[i] = false
                failed = true                
            }
        }

        if (failed) {
            await this.saveToDB('### must re-download video blob: ' + this._id)
            return null
        }

        return new Blob(blobs, { type: _type || blobs[0].type })
    }

    // ---- Utilities for unit testing ----

    async delete() {
        await this.deleteBlobs()
        await VideoCache._db.delete(VideoCache.CACHEDVIDEOS, this._id)
    }

}


// For cypress testing, write a file with a name like '__video1.mp4' to cache

async function _writeTestFileToCache_(file: File) {
    let _id = file.name.slice(2, -4) + '-1'
    log('writeTestFileToCache', _id)

    let vcr = await VideoCacheRecord.get(_id)
    await vcr.deleteBlobs() // if existing video data for this file, remove it
    vcr.size = file.size
    await vcr.addBlob(file)

    log('!!!writeTestFileToCache DONE', _id)
}

let _window: any = window
_window._writeTestFileToCache_ = _writeTestFileToCache_
_window.VideoCacheRecord = VideoCacheRecord

// console.log(await window.VideoCacheRecord.get('TESTnm/200829_201205/210519_194355/210519_194356-9'))


