import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { extendObservable } from 'mobx'
import { observer, /* inject */ } from 'mobx-react'
import { confirmAlert } from '../utils/ConfirmAlert'
import { t } from 'ttag'

import { Root } from '../../models3/Root'
import { displayError } from '../utils/Errors'
import { DeleteSegmentButton } from '../utils/Buttons'
import { PassageSegment } from '../../models3/ProjectModels'

import _debug from "debug"; let log = _debug('sltt:SegmentDeleter') 

interface ISegmentDeleter {
    rt: Root,
    segment: PassageSegment,
}

class SegmentDeleter extends Component<ISegmentDeleter>  {

    render() {
        let { segment, rt } = this.props
        let { iAmInterpreter, passageVideo, editingSegment } = rt
        if (!segment) return null
        
        if (!segment || !passageVideo) return null

        let segments = (passageVideo && passageVideo.segments) || []
        if (segments.length === 0) return

        let index = segments.findIndex(s => s._id === segment._id)
        let previousIndex = index - 1
        let previousSegmentIsPatch = false
        if (previousIndex >= 0) {
            previousSegmentIsPatch = segments[previousIndex].videoPatchHistory.length > 0
        }

        let enabled = !editingSegment && iAmInterpreter && segment._id !== segments[0]._id && segment.videoPatchHistory.length === 0 && !previousSegmentIsPatch
        let tooltip = t`Delete this segment.`

        if (segment._id === segments[0]._id) {
            tooltip = t`You cannot delete the first segment.`
        }

        return (
            <DeleteSegmentButton
                enabled={enabled}
                tooltip={tooltip}
                onClick={this.delete.bind(this)} />
        )
    }

    confirmDeletion(doDeletion: () => void) {
        // No need to confirm, if no video has been recorded yet
        confirmAlert({
            title: t`DELETE this segment?!`,
            message: t`Only the segment bar and labels will be deleted. The recorded video is not affected. You cannot undo this operation.`,
            confirmLabel: t`Delete!!!`,
            cancelLabel: t`Keep.`,
            onConfirm: doDeletion,
            onCancel: () => { },
        })
    }

    delete() {
        let { rt, segment } = this.props
        let { passageVideo, passage } = rt
        if (!passageVideo || !passage) return

        let newTime = segment.time

        this.confirmDeletion(() => {
            if (!passageVideo) return
            passageVideo.removeSegment(segment._id)
                .then(() => rt.resetCurrentTime(newTime))
                .catch(err => {
                    displayError(err)
                })
        })
    }
}

export default observer(SegmentDeleter)
