import React from 'react'
import { confirmAlert as _confirmAlert } from 'react-confirm-alert'

/**
 * Wrapper around v2.x react-confirm-alert to make it compatible with the
 * v1.x calling convention used (in many places) in SLTT
 */

interface IConfirmAlert {
    title?: string,
    message: string,
    confirmLabel: string | React.ReactNode,
    cancelLabel?: string,
    onConfirm: () => void,
    onCancel?: () => void,
}

export function confirmAlert(options: IConfirmAlert) {
    let { title, message, confirmLabel, cancelLabel, onConfirm, onCancel } = options

    let buttons = [{
        label: confirmLabel,
        onClick: onConfirm,
    }]

    if (cancelLabel) {
        buttons.unshift({
            label: cancelLabel,
            onClick: onCancel!,
        })
    }

    const _options = {
        message,
        title: title,
        buttons,
    }

    _confirmAlert(_options)
}
