import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { t } from 'ttag'

import { IRoot, Root } from '../../models3/Root'
import GlossBar from '../glosses/GlossBar'
import NoteBar from '../notes/NoteBar'
import ThinVideoPositionBar from './ThinVideoPositionBar'
import VideoPositionBar from './VideoPositionBar'
import VideoPositionBarControls from './VideoPositionBarControls'
import "./Video.css"
import { DoublePlayButton, PauseButton, PlayButton, StopButton } from '../utils/Buttons'
import PassageVideoSelector from '../passages/PassageVideoSelector'
import { Passage, PassageVideo, Portion, ProjectPlan } from '../../models3/ProjectModels'
import { IDateFormatter } from '../../models3/DateUtilities'
import DraftVideoPlayer from './DraftVideoPlayer'
import { DraftVideoRoot } from '../../models3/DraftVideoRoot'
import { displayError } from '../utils/Errors'
import { ViewableVideoCollection } from './ViewableVideoCollection'
import { DropdownButton, MenuItem } from 'react-bootstrap'
import NoteDialog from '../notes/NoteDialog'
import { VerseReferenceEditor } from './VerseReferenceEditor'
import VideoMessage from './VideoMessage'

import _debug from "debug"; let log = _debug('sltt:CompareDraftVideoMain')

const htmlElementVisible = (el: HTMLElement) => el.offsetWidth > 0 && el.offsetHeight > 0

type CompareDraftVideoMainProps = {
    rt: Root,
}

const CompareDraftVideoMain: FC<CompareDraftVideoMainProps> = observer(({ rt }) => {
    const [videoAreaWidth, setVideoAreaWidth] = useState(640)
    const [videoAreaHeight, setVideoAreaHeight] = useState(360)
    const [videoMainWidth, setVideoMainWidth] = useState(640)
    const [draftVideoRoot] = useState(new DraftVideoRoot(rt))

    useEffect(() => {
        let _interval = setInterval(() => {
            setNoteBarWidth()
            setComponentWidth()
        }, 100)

        return () => {
            clearInterval(_interval)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('keydown', keydown)
        return () => window.removeEventListener('keydown', keydown)
    }, [])

    useEffect(() => {
        draftVideoRoot.initialize()
    }, [])

    useEffect(() => {
        draftVideoRoot.addListener('record', playWhileRecording)
        return () => {
            draftVideoRoot.removeListener('record', playWhileRecording)
            draftVideoRoot.removeListener('stop-main-video', stopAndReset)
        }
    })

    function playWhileRecording() {
        let _play = () => {
            draftVideoRoot.play()
            draftVideoRoot.addListener('stop-main-video', stopAndReset)
        }

        // There is a 1000ms delay in VideoRecorder before recording starts.
        // Not sure what delay we want here.
        // How far behind does the signer want to be?
        setTimeout(_play, 3000)
    }

    function stopAndReset() {
        draftVideoRoot.stop()
        draftVideoRoot.removeListener('stop-main-video', stopAndReset)
    }

    function setComponentWidth() {
        let videoMain = document.querySelectorAll("[data-id='video-main']")
        for (let element of videoMain) {
            let _element = element as HTMLElement
            let isVisible = htmlElementVisible(_element)
            const BUFFER = 12   // 5px left padding + 5px right padding + 1px left border + 1px right border
            let width = _element.getBoundingClientRect().width - BUFFER
            if (isVisible) {
                setVideoMainWidth(width)
            }
        }
    }

    function setNoteBarWidth() {
        let videoArea = document.querySelectorAll("[data-id='compare-video-area']")
        for (let element of videoArea) {
            let _element = element as HTMLElement
            let isVisible = htmlElementVisible(_element)
            if (isVisible) {
                setVideoAreaWidth(_element.offsetWidth)
            }
        }
    }

    function keydown(e: KeyboardEvent) {
        let { playing, stop, play, pause, currentTime, videoPlaybackKeydownEnabled, passage } = draftVideoRoot
        
        // Ignore keydown handlers if this event meets certain conditions
        let element = e.target?.toString() || ''
        let el = e.target as Element
        let shouldReject = element.includes('HTMLInputElement')
                        || element.includes('HTMLTextAreaElement')
                        || el.getAttribute && el.getAttribute('contenteditable') === 'true'
                        || !videoPlaybackKeydownEnabled
                        || passage?.videoBeingCompressed
        if (shouldReject) {
            log('keydown rejected non-global')
            return
        }

        e.stopPropagation()

        log(`keydown code=${e.code}`, e)

        if (e.code === 'Space') {
            if (playing) {
                pause()
            } else {
                play(undefined, undefined, currentTime)
                draftVideoRoot.bothPlayingRequested = true
            }
        }

        if (e.code === 'Escape') {
            stop()
        }
    }

    let draftVideoPlayer = draftVideoRoot
    let compareDraftToolbar = draftVideoRoot

    let playBothVideos = () => {
        draftVideoRoot.play(undefined, undefined, draftVideoRoot.currentTime)
        rt.play(undefined, undefined, rt.currentTime)
        draftVideoRoot.bothPlayingRequested = true
    }

    let pauseBothVideos = () => {
        draftVideoRoot.pause()
        rt.pause()
    }

    let stopBothVideosAndReset = () => {
        draftVideoRoot.stop()
        rt.stop()
    }

    let { note, passage, setLexicalLink } = draftVideoRoot

    let adjustVideoTime = false

    // Is this the right thing to do? All we want is to ensure note is never
    // undefined in the note dialog
    let noteRoot = draftVideoRoot
    let closeNoteDialog = () => draftVideoRoot.setNote(undefined)

    let compressingVideo = !!passage?.videoBeingCompressed

    return (
        <div className='compare-draft-video-main' data-id='compare-draft-video-main' style={{ width: videoMainWidth }}>
            { note && <NoteDialog {...{ adjustVideoTime, noteRoot, closeDialog: closeNoteDialog }} /> }
            <CompareDraftToolbar {...{ compareDraftToolbar, playBothVideos, pauseBothVideos, stopBothVideosAndReset }} />
            <div className='video-area' data-id='compare-video-area' style={{ height: videoAreaHeight }}>
                { !compressingVideo && <DraftVideoPlayer {...{ draftVideoPlayer, setContainerHeight: setVideoAreaHeight }} /> }
                { compressingVideo && <VideoMessage rt={rt} /> }
            </div>
            <VideoFooter {...{ rt, draftVideoRoot, videoAreaWidth, setLexicalLink }} />
        </div>
    )
})

type CompareDraftToolbarProps = {
    compareDraftToolbar: ICompareDraftToolbar,
    playBothVideos: () => void,
    pauseBothVideos: () => void,
    stopBothVideosAndReset: () => void,
}

interface ICompareDraftToolbar {
    plans: ProjectPlan[],
    portion: Portion | null,
    passage: Passage | null,
    passageVideo: PassageVideo | null,
    iAmAdmin: boolean,
    hardNotificationCutoff: () => Date,
    canViewConsultantOnlyFeatures: boolean,
    dateFormatter: IDateFormatter,
    playing: boolean,
    setPassage: (passage: Passage) => Promise<void>,
    setPassageVideo: (video: PassageVideo) => Promise<void>,
    canPlayThrough: boolean,
    bothCanPlayThrough: boolean,
    currentVideos: ViewableVideoCollection,
    bothPlaying: boolean,
    play: () => void,
    pause: () => void,
    selectionPresent: () => boolean,
}

const CompareDraftToolbar: FC<CompareDraftToolbarProps> = observer(
    ({ compareDraftToolbar, playBothVideos, pauseBothVideos, stopBothVideosAndReset }) => (
        <div className='video-toolbar'>
            <VideoPlaybackControls {...{ compareDraftToolbar, pauseBothVideos, playBothVideos, stopBothVideosAndReset }} />
            <VideoSelector {...{ compareDraftToolbar }} />
        </div>
))

type VideoPlaybackControlsProps = {
    pauseBothVideos: () => void,
    playBothVideos: () => void,
    stopBothVideosAndReset: () => void,
    compareDraftToolbar: ICompareDraftToolbar,
}

const VideoPlaybackControls: FC<VideoPlaybackControlsProps> = observer(
    ({ compareDraftToolbar, pauseBothVideos, playBothVideos, stopBothVideosAndReset }) => {
        let { play, pause, playing, bothPlaying, canPlayThrough, bothCanPlayThrough, passage } = compareDraftToolbar
        let playShown = !playing || bothPlaying
        let enabled = !passage?.videoBeingCompressed
        let playEnabled = canPlayThrough && !playing && enabled
        let doublePlayShown = !bothPlaying
        let doublePlayEnabled = bothCanPlayThrough && !playing && enabled
        let pauseShown = playing
        let stopShown = bothPlaying
        let selectionPresent = compareDraftToolbar.selectionPresent()

        return (
            <div className='video-toolbar-left'>
                {playShown && (
                    <PlayButton
                        enabled={playEnabled}
                        selectionPresent={selectionPresent}
                        tooltip={t`Play.`}
                        className='main-video-play-button'
                        onClick={() => play()}
                    />
                )}
                {pauseShown && (
                    <PauseButton
                        enabled={true}
                        className='main-video-pause-button'
                        onClick={bothPlaying ? pauseBothVideos : () => pause()}
                        tooltip={bothPlaying ? t`Pause playing both videos.` : t`Pause.`}
                    />
                )}
                {doublePlayShown && (
                    <DoublePlayButton
                        enabled={doublePlayEnabled}
                        selectionPresent={doublePlayEnabled && selectionPresent}
                        className='main-video-play-button'
                        tooltip={t`Play both videos.`}
                        onClick={playBothVideos}
                    />
                )}
                {stopShown && (
                    <StopButton
                        enabled={true}
                        className='main-video-stop-button'
                        tooltip={t`Stop playing both videos and reset both to starting point.`}
                        onClick={stopBothVideosAndReset}
                    />
                )}
            </div>
        )
})

type VideoSelectorProps = {
    compareDraftToolbar: ICompareDraftToolbar
}

const VideoSelector: FC<VideoSelectorProps> = observer(({ compareDraftToolbar }) => {
    let { passage, iAmAdmin, setPassageVideo, passageVideo, portion, setPassage,
        hardNotificationCutoff, canViewConsultantOnlyFeatures, dateFormatter, plans } = compareDraftToolbar
    async function selectVideo(video: PassageVideo) {
        if (video.removed) {
            return await undeleteVideo(video)
        }
        return await makeSelection(video)
    }

    async function undeleteVideo(passageVideo: PassageVideo) {
        if (!passage || !iAmAdmin) {
            return
        }

        try {
            await passage.undeleteVideo(passageVideo)
            await makeSelection(passageVideo)
        } catch(error) {
            displayError(error)
        }
    }

    async function makeSelection(passageVideo: PassageVideo) {
        if (!passage) {
            return
        }

        try {
            await setPassageVideo(passageVideo)
        } catch(error) {
            displayError(error)
        }
    }

    let videos = passage ? (iAmAdmin ? passage.videos : passage.videosNotDeleted).filter(v => !v.isPatch) : []
    let currentVisibleVideo = passageVideo || videos.length > 0 && videos[videos.length - 1]
    let passages = portion?.passages || []
    let onSelectPassage = (passage: Passage) => setPassage(passage)
    let passageVideoNotification = {
        passage,
        hardNotificationCutoff,
        canViewConsultantOnlyFeatures,
        dateFormatter,
    }

    return (
        <div className='video-toolbar-right'>
            {passage && <PassageSelector {...{ passages, passage, onSelect: onSelectPassage }} />}
            {currentVisibleVideo && plans.length > 0 && (
                <PassageVideoSelector
                    enabled={true}
                    videos={videos}
                    currentVisibleVideo={currentVisibleVideo}
                    onSelect={selectVideo}
                    dateFormatter={dateFormatter}
                    passageVideoNotification={passageVideoNotification}
                    plan={plans[0]}
                />
            )}
        </div>
    )
})

type VideoFooterProps = {
    rt: IRoot,
    draftVideoRoot: DraftVideoRoot,
    videoAreaWidth: number,
}

const VideoFooter: FC<VideoFooterProps> = observer(({ rt, draftVideoRoot, videoAreaWidth }) => {
    let w = videoAreaWidth
    let videoPosition = draftVideoRoot 
    let videoPositionControls = draftVideoRoot 
    let noteBarDisplay = draftVideoRoot
    let glossBarDisplay = draftVideoRoot
    let { verseReference, setLexicalLink } = draftVideoRoot

    return (
        <div>
            <VideoPositionBarControls {...{ videoPositionControls, videoPosition }} />
            <VideoPositionBar {...{ videoPosition }} />
            { verseReference && <VerseReferenceEditor {...{ videoPosition }} />}
            <ThinVideoPositionBar {...{ videoPosition }} />
            <NoteBar {...{ noteBarDisplay, w }} />
            <GlossBar {...{ rt: draftVideoRoot, glossBarDisplay, w, setLexicalLink }} />
        </div>
    )
})

type PassageSelectorProps = {
    passages: Passage[],
    passage: Passage,
    onSelect: (passage: Passage) => void,
}

export const PassageSelector: FC<PassageSelectorProps> = ({ passages, passage, onSelect }) => (
    <DropdownButton
        title={passage.name}
        id='passage-dropdown'
        className='sl-dropdown passage-dropdown'
    >
        {passages.map(p => (
            <MenuItem key={p._id} onSelect={() => onSelect(p)}>{p.name}</MenuItem>
        ))}
    </DropdownButton>
)

export default CompareDraftVideoMain