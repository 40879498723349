import React, { FunctionComponent } from 'react';
import { Language } from '../utils/Languages';
import _debug from 'debug'
const log = _debug('sltt:LocaleSelector')

interface ILocaleSelector {
    options: Language[],
    currentValue: string,
    chooseOption: (option: string) => void,
}

export const LocaleSelector: FunctionComponent<ILocaleSelector> = ({ options, currentValue, chooseOption }) => {
    // log('LocaleSelector', { currentValue, options: { ...options } })
    return (<div className='app-selector-icon'>
        <select
            className='custom-select'
            value={currentValue}
            onChange={e => chooseOption(e.target.value)}
        >
            { options.map(item => {
                let { code, fullName } = item
                return (
                    <option key={code} value={code}> { `${code} - ${fullName}` } </option>
                )
            })}
        </select>
    </div>)
}
