import _ from 'underscore'
import langs from '../../resources/languages/langs.json'

export const iso639dash1Langs = langs

export interface Language {
	code: string,
	fullName: string,
}

const iso639dash1Dict = _.indexBy(langs, '1')

export function getByIso639dash1(iso639dash1Code: string) {
    return iso639dash1Dict[iso639dash1Code]
}

export const languagesForBookNames: Language[] = [
    { code: 'en', fullName: 'English' },
    { code: 'es', fullName: getByIso639dash1('es').local }
]

export interface ILang {
    '1': string,  // iso 639-1
    '2': string,  // iso 639-2
    '3': string,  // iso 639-3
    '2T': string, // iso 639-2T (terminology)
    '2B': string, // iso 639-2B (bibliographic)
    local: string,
    name: string
}


export function validateIso639dash1LanguageCode(languageCode: string) {
    if (languageCode.length !== 2) {
        throw Error(`Invalid iso-639-1 languageCode '${languageCode}'`)
    }
    if (!langs.some(lang => lang['1'] === languageCode)) {
        throw Error(`Unknown iso-639-1 languageCode '${languageCode}'`)
    }
}