import React, { Component } from "react";
import { PassageVideo } from "../../models3/ProjectModels";
import '../translation/Translation.css'
import './Segments.css'
import { DropdownButton, MenuItem } from "react-bootstrap";
import { Root } from "../../models3/Root";

interface IOldSegmentDropdown {
    rt: Root,
    options: PassageVideo[],
    onSelect: (id: string) => void,
    enabled: boolean,
}

class OldSegmentDropdown extends Component<IOldSegmentDropdown> {
    render() {
        let { options, onSelect, rt, enabled } = this.props
        let { dateFormatter } = rt
        let newestVersion = options[options.length - 1].version
        return (
            <DropdownButton
                bsStyle='default'
                title=''
                id='old-segment-dropdown'
                className='sl-dropdown old-segment-dropdown'
                disabled={!enabled}
            >
                {options.map((item, i) => {
                    let date = new Date(item.creationDate)
                    let dateString = dateFormatter.format(date)
                    let version = newestVersion - i
                    let suffix = version > 0 ? `-${version}` : ''
                    let displayedName = `${dateString}${suffix}`
                    return (
                        <MenuItem
                            key={item._id}
                            eventKey={item._id}
                            onClick={() => onSelect(item._id)}
                        >
                            {displayedName}
                        </MenuItem>
                    )
                })}
            </DropdownButton>
        )
    }
}

export default OldSegmentDropdown