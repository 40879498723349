// Allow user to select which portion in the current project they wish to work with
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { t } from 'ttag'

import { PassageVideo, ProjectPlan } from '../../models3/ProjectModels'

import _debug from "debug"; let log = _debug('sltt:PassageStatus')

interface IPassageStatus {
    passageVideo: PassageVideo,
    plan: ProjectPlan,
}

class PassageStatus extends Component<IPassageStatus> {
    render() {
        let { passageVideo, plan } = this.props
        let { status } = passageVideo
        let task = plan.tasks.find(task => task.id === status)

        let visibleStatus = ''

        if (!task) {
            return null
        }

        if (task.name === 'Finished') {
            visibleStatus = /* translator: important */ t`(Finished)`
        } else if (task.name !== 'Not started') {
            visibleStatus = `(${task.stagePosition}.${task.taskPosition})`
        }
        
        return ( 
            <span>
                {visibleStatus}
            </span>
        )
    }
}

export default observer(PassageStatus)
