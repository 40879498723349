import React, { FunctionComponent } from 'react'
import { Root } from '../../models3/Root'

export const RootContext = React.createContext<Root | null>(null)

interface IRootProvider {
    initialValue: Root | null
}

export const RootProvider: FunctionComponent<IRootProvider> = ({ initialValue, children }) => {
    return (
        <RootContext.Provider value={initialValue}>
            {children}
        </RootContext.Provider>
    )
}

interface IRootConsumer {
    children: (context: Root | null) => JSX.Element | null
}

export const RootConsumer: FunctionComponent<IRootConsumer> = ({ children }) => {
    return (
        <RootContext.Consumer>
            {context => children(context)}
        </RootContext.Consumer>
    )
}
