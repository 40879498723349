import React, { Component } from 'react'
import { observer } from 'mobx-react'

import { ProjectTask, ProjectStage } from '../../../models3/ProjectModels'
import { Root } from '../../../models3/Root'
import ProjectTaskAdder from './ProjectTaskAdder'
import EditableProjectTask from './EditableProjectTask'
import { displayError } from '../../utils/Errors'

interface IEditableTaskList {
    stage: ProjectStage,
    rt: Root,
}

@observer
export class EditableTaskList extends Component<IEditableTaskList> {
    render() {
        let { stage, rt } = this.props
        let { iAmAdmin, useMobileLayout, project } = rt
        return (
            <div>
                {stage.tasks.length > 0 && (
                    <div className="tasks">
                        {stage.tasks.map((task, index) => (
                                <Task
                                    key={index}
                                    index={index}
                                    task={task}
                                    stage={stage}
                                    addTask={this.addTask}
                                    rt={rt}
                                />
                            )
                        )}
                    </div>
                )}
                {stage.tasks.length === 0 && iAmAdmin && !useMobileLayout && (
                    <ProjectTaskAdder
                        stage={stage}
                        buttonClassName='task-adder'
                        addTask={name => this.addTask(name, 0)} 
                    />
                )}
            </div>
        )
    }

    addTask = async (name: string, taskIndex: number) => {
        let { stage, rt } = this.props
        let plan = rt.project.plans[0]
        if (!plan) {
            return
        }
        try {
            await stage.addTask(plan, taskIndex, name)
        } catch (error) {
            displayError(error)
        }
    }
}

interface ITask {
    index: number,
    task: ProjectTask,
    stage: ProjectStage,
    addTask: (name: string, index: number) => void,
    rt: Root,
}

class Task extends Component<ITask> {    
    render() {
        let { index, task, stage, addTask, rt } = this.props
        let { iAmAdmin, useMobileLayout, project } = rt
        return (
            <div className="task">
                {index === 0 && iAmAdmin && !useMobileLayout && (
                    <ProjectTaskAdder
                        stage={stage}
                        buttonClassName='task-adder'
                        addTask={name => addTask(name, index)} />
                )}
                <EditableProjectTask
                    task={task}
                    stage={stage}
                    deleteTask={() => stage.removeTask(task._id, project)}
                    rt={rt}
                />
                {iAmAdmin && !useMobileLayout && (
                    <ProjectTaskAdder
                        stage={stage}
                        buttonClassName='task-adder'
                        addTask={name => addTask(name, index + 1)} />
                )}
            </div>
        )
    }
}

export default observer(Task)