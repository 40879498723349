// SegmentToolbar allows controlling playing and recording

import React, { Component, FunctionComponent, useContext } from 'react'
import {observer} from 'mobx-react'
import { t, jt } from 'ttag'

import { Root } from '../../models3/Root'
import { PlayButton, RepeatButton, RecordButton, StopButton, SlttHelp, SyncButton, ApprovalIcon, SegmentLabelButton, SegmentReferencesButton, EyeButton, SegmentVideoSketchButton } from '../utils/Buttons'
import '../utils/Buttons.css'
import { PatchVideoDeleter } from '../video/PatchVideoDeleter'
import { Member, PassageSegment, PassageSegmentApproval, PassageVideo } from '../../models3/ProjectModels'
import OldSegmentDropdown from './OldSegmentDropdown'
import { DropdownButton, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CheckCircle, CheckIcon, ThinCircleIcon } from '../utils/Icons'
import { RootContext } from '../app/RootContext'
import { MemberDisplay } from '../utils/MemberDisplay'
import VideoSegmentPosition from './VideoSegmentPosition'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'
import SegmentDeleter from './SegmentDeleter'
import { canCreatePatchFromNoteItem, createPatchFromNoteItem } from '../notes/PatchFromNote'
import { displayError, systemError } from '../utils/Errors'
import { fmt } from '../utils/Fmt'

// function enable(cns, enabled) { return enabled ? cns : cns + ' sl-button-disabled' }

// NOT IMPLIMENTED YET
// function PlayPreviousDraftSegmentButton({ onClick, enabled, tooltip }) {
//     let d = `M 0,0
//              L 20,15
//              L 0,30`

//     return (
//         <span className={enable('sl-play-previous-draft-segment-button', enabled)}
//             onClick={() => onClick && onClick()}
//             data-toggle="tooltip"
//             title={tooltip} >
//             <svg className="sl-play-previous-draft-segment-svg" width="35" height="50" xmlns="http://www.w3.org/2000/svg">
//                 <g>
//                     <path stroke="#337ab7"
//                         d={d}
//                         strokeWidth="1.5" fill="#337ab7" />
//                     <text stroke="#337ab7" textAnchor="start" fontFamily="Helvetica, Arial, sans-serif"
//                         fontSize="16" y="10" x="15" strokeWidth="0" fill="#337ab7">-1</text>
//                 </g>
//             </svg >
//         </span>
//     )
// }

// function SelectPreviousDraftSegmentButton({ onClick, enabled, tooltip }) {
//     return (
//         <span className={enable('sl-fa-button sl-select-previous-draft-segment-button fa-caret-down', enabled)}
//             onClick={() => onClick && onClick()}
//             data-toggle="tooltip"
//             title={tooltip} >
//         </span>
//     )
// }

import _debug from "debug"; let log = _debug('sltt:SegmentToolbar')

interface ISegmentToolbar {
    rt: Root,
    segment: PassageSegment,
    oldVersionOptions: PassageVideo[],
    chooseVersionToPlay: (id: string) => void,
    play: () => void,
    pause: () => void,
    record: () => void,
    stopRecording: () => void,
    convertReferences: () => void,
    editingSegmentPosition: EditingSegmentPosition, 
    setEditingSegmentPosition: (value: EditingSegmentPosition) => void,
    startEditingLabels: () => void,
    setEditingReferences: (value: boolean) => void,
}

class SegmentToolbar extends Component<ISegmentToolbar>  {

    defaultH = 60

    render() {
        let { rt, record, stopRecording, chooseVersionToPlay,
            oldVersionOptions, play, pause, convertReferences, segment, editingSegmentPosition,
            setEditingSegmentPosition, setEditingReferences } = this.props
        let { startEditingLabels } = this
        let { playing, passage, passageVideo, passageSegment, recording, editingSegment,
            iAmInterpreter, username } = rt

        let selectionPresent = rt.selectionPresent()
        let patchableSelectionPresent = rt.patchableSelectionPresent()

        const actualSegment = passage && passageSegment?.actualSegment(passage)
        const _enabled = !rt.segmentTabEditingInProgress && !!passageVideo && !!passageSegment
        const enabled = _enabled && (actualSegment?.ignoreWhenPlayingVideo === false)
        const ignoreSegmentEnabled = _enabled

        let pauseShown = playing
        let playEnabled = enabled && !recording
        
        let recordShown = !playing && !recording
        let recordEnabled = enabled && rt.iAmTranslator
        let recordTooltip = /* translator: important */ t`Record patch for this segment.`
        if (selectionPresent) {
            recordEnabled = enabled && rt.iAmTranslator && patchableSelectionPresent
            recordTooltip = /* translator: important */ t`Record patch for the selection.`
            if (!patchableSelectionPresent) {
                recordTooltip += ' ' + /* translator: important */ t`Patch selections cannot cross segments boundaries.`
            }
        }

        if (canCreatePatchFromNoteItem(rt)) {
            recordTooltip += ' ' + t`<shift> click to use video note item as a patch.`
        }

        let stopShown = recording
        let oldSegmentDropdownShown = passageSegment && passageSegment.videoPatchHistory.length > 0

        const editingEnabled = enabled && iAmInterpreter

        let playButtonClass = `sl-segment-toolbar-button ${!oldSegmentDropdownShown && 'sl-play-segment-button'}`

        let eyeToolTip = actualSegment?.ignoreWhenPlayingVideo ? t`Show this segment when playing through this video.` : t`Hide this segment when playing through this video.`

        return (
            <div className="segment-toolbar" >
                <div className='segment-toolbar-first-group'>
                    { !pauseShown &&
                        <PlayButton
                            tooltip={/* translator: important */ t`Play`}
                            className={playButtonClass}
                            enabled={playEnabled}
                            selectionPresent={false}
                            onClick={play} /> 
                    }

                    { pauseShown &&
                        <RepeatButton
                            className='sl-pause-segment-button'
                            enabled={true}
                            tooltip={/* translator: important */ t`Return to the start of the video and pause playback.`}
                            onClick={pause} /> 
                    }

                    { oldSegmentDropdownShown &&
                        <SlttHelp id='patches.html#patchplay' tooltip={/* translator: important */ t`Play a previous version this segment.`} place="top">
                            <OldSegmentDropdown
                                rt={rt}
                                options={oldVersionOptions}
                                enabled={!!enabled}
                                onSelect={chooseVersionToPlay} />
                        </SlttHelp>
                    }

                    {recordShown && 
                        <SlttHelp id="patches.html" tooltip={recordTooltip} place="top">
                                <RecordButton
                                    tooltip=""
                                    className='sl-record-patch-button'
                                    enabled={recordEnabled}
                                    selectionPresent={selectionPresent}
                                    onClick={(shiftKey: boolean) => {
                                        if (shiftKey) {
                                            createPatchFromNoteItem(rt, setEditingSegmentPosition)
                                                .catch(displayError)
                                            return
                                        }

                                        record()
                                    }} />
                                
                        </SlttHelp>
                    }

                    {stopShown &&
                        <StopButton
                            className='segment-stop-button'
                            enabled={enabled}
                            onClick={stopRecording}
                            tooltip={/* translator: important */ t`Stop recording.`}
                        />
                    }

                    {!recording && passage && passageSegment && passageVideo && passageSegment.videoPatchHistory.length > 0 &&
                        <PatchVideoDeleter {...{ 
                            enabled: !!enabled && rt.iAmTranslator, 
                            deleter: { segment: passageSegment, rt, passage, passageVideo }}} />
                    }
                    <VideoSegmentPosition {...{ enabled: !!enabled, editingSegmentPosition, setEditingSegmentPosition }} />
                </div>

                <div className='segment-toolbar-middle-group'>
                    <SegmentApproval {...{ enabled: !!enabled, segment, username }} />
                    <SegmentLabelButton
                        className='segment-labels-button'
                        tooltip={t`Edit labels for this segment.`}
                        enabled={editingEnabled}
                        onClick={startEditingLabels}
                        />
                    <SegmentReferencesButton
                        enabled={editingEnabled}
                        className='segment-references-button'
                        onClick={() => setEditingReferences(true)}
                        tooltip={t`Edit references for this segment.`}
                    />
                    <SegmentVideoSketchButton
                        enabled={editingEnabled && actualSegment}
                        className='segment-video-sketch-button'
                        onClick={() => rt.videoSketchData.startEditingVideoSketch(rt.passage, rt.passageSegment)}
                        tooltip={t`Draw on video for this segment.`}
                    />
                </div>

                <div className='segment-toolbar-last-group'>
                    {/* {syncShown &&
                        <SyncButton
                            className='sl-segment-toolbar-button sl-segment-sync-button'
                            enabled={syncEnabled}
                            onClick={convertReferences}
                            tooltip={t`Convert segment references for this video to verse references`}
                        />
                    } */}
                    {passageSegment && actualSegment && (
                        <EyeButton
                            className='ignore-segment-button'
                            enabled={ignoreSegmentEnabled}
                            tooltip={eyeToolTip}
                            value={actualSegment.ignoreWhenPlayingVideo}
                            setValue={actualSegment.setIgnoreWhenPlayingVideo}
                        />
                    )}
                    {passageSegment && <SegmentDeleter segment={passageSegment} rt={rt} />}
                    {/* <PlayPreviousDraftSegmentButton enabled={true} /> */}
                </div>
            </div> 
        )
    }

    startEditingLabels = () => {
        log(`setEditingLabels true`)
        let { startEditingLabels } = this.props
        startEditingLabels()
    }
}

interface IStatusApproval {
    status: PassageSegmentApproval,
    setStatus: (status: PassageSegmentApproval) => void,
    approvalDate: string, 
    approvedBy: string,
    enabled: boolean,
}

/* UI to select approval status: none, check, circle, circle+check
 * This is used in the SegmentToolbar and PassageEditor
 */
export const StatusApproval: FunctionComponent<IStatusApproval> = observer(
    ({ status, setStatus, approvalDate, approvedBy, enabled }) => 
{
    let rt = useContext(RootContext)
    if (!rt) return null

    let { iAmConsultant, iAmAdmin, username, dateFormatter, project } = rt
    let isApproved = status !== PassageSegmentApproval.State0
    let date = new Date(approvalDate)
    let formattedApprovalDate = dateFormatter.format(date)

    let options = [
        <span className='dash-icon sl-fa-button'>&mdash;</span>,
        <CheckIcon className='segment-approval-status-icon' tooltip='' />,
        <ThinCircleIcon className='segment-approval-status-icon' tooltip='' />,
        <CheckCircle className='segment-approval-status-icon' tooltip='' />,
    ]

    let currentOption = options[status]
    let allowedToEdit = iAmConsultant || iAmAdmin

    let member = project.findMemberWithDefault(approvedBy)
    let approver = <MemberDisplay member={member} imageSize='small' />

    let tooltip = (
        <Tooltip id='segment-approval-tooltip'>
            {isApproved ? jt`Approved by ${approver} on ${formattedApprovalDate}` : t`Approved?`}
        </Tooltip>
    )

    return (
        <form className={`${allowedToEdit ? '' : 'segment-approval-not-allowed'} flex-centered-items`}>
            <span className='segment-approval-current-status'>
                <OverlayTrigger overlay={tooltip} placement='bottom'>
                    <div>
                        {currentOption}
                    </div>
                </OverlayTrigger>
                {allowedToEdit && (
                    <DropdownButton
                        bsStyle='default'
                        title=''
                        id='segment-approval-dropdown'
                        className='sl-dropdown segment-approval-dropdown'
                        disabled={!enabled}
                    >
                        {options.map((icon, i) => (
                            <MenuItem
                                key={i}
                                value={i}
                                onClick={() => {
                                    allowedToEdit && setStatus(i)
                                }}
                            >
                                <div className='segment-approval-option'>
                                    {icon}
                                </div>
                            </MenuItem>
                        ))}
                    </DropdownButton>
                )}
            </span>
        </form>
    )
})

interface ISegmentApproval {
    segment: PassageSegment,
    username: string,
    enabled: boolean,
}

const SegmentApproval: FunctionComponent<ISegmentApproval> = observer(({ segment, username, enabled }) => {
    const status = segment.approved
    const approvalDate = segment.approvalDate
    const approvedBy = segment.approvedBy

    function setStatus(i: number) {
        segment.setApproved(i, username).catch(systemError)
    }

    return (
        <StatusApproval {...{ status, setStatus, approvalDate, approvedBy, enabled }} />
    )
})

export default observer(SegmentToolbar)
