// Accept dropped Note files and upload them

import React, { Component, SFC } from 'react'
import {observer} from 'mobx-react'
import { t } from 'ttag'

import './Note.css'
import { displayError, systemError } from '../utils/Errors'
import { Project, PassageNote, PassageVideo, Passage } from '../../models3/ProjectModels'

import { allowedImageTypes, isAllowedImage } from '../images/AllowedImages'
import DropTarget from '../utils/DropTarget'
import { IRoot } from '../../models3/Root'
import { canUploadWithoutCompression, canUploadWithoutCompressionTypes } from '../images/AllowedVideos'

import _debug from "debug"; let log = _debug('sltt:NoteDropTarget')

interface IUploadProgress {
    remaining: number,
}

let UploadProgress: SFC<IUploadProgress> = ({ remaining }) => {
    return (
        <span className="Note-upload-progress">
            (<span className="Note-upload-bounce fas fa-arrow-up" />
            {remaining.toFixed()})
        </span>
    )
}

interface INoteDropTarget {
    noteRoot: IRoot,
    dropTargetView: JSX.Element,
}

class NoteDropTarget extends Component<INoteDropTarget> {
    render() {
        let { children, dropTargetView } = this.props

        let { upload } = this

        return (
            <DropTarget upload={upload} dropTargetView={dropTargetView}>
                {children}
            </DropTarget>
        )
    }

    upload = async (files: FileList) => {
        await uploadNoteIfCan(this.uploadFile, this.props.noteRoot, files)
    }

    uploadFile = async (file: File) => {
        let { name, note, passage, setNote, username } = this.props.noteRoot
        if (!note) throw Error('note not set')
        await uploadNote(note, username, name, file, passage, setNote)
    }

    async uploadJsonFile(file: File) {
        let { passage, passageVideo } = this.props.noteRoot
        await uploadJsonFile(file, passage, passageVideo)
    }
}

export default observer(NoteDropTarget)

type NoteRoot = {
    iAmInterpreter: boolean,
    passage: Passage | null,
    passageVideo: PassageVideo | null,
}

export async function uploadNoteIfCan(
    uploadFile: (file: File) => Promise<void>,
    noteRoot: NoteRoot,
    files: FileList
) {
    const { iAmInterpreter, passage, passageVideo } = noteRoot

    if (!iAmInterpreter) {
        displayError(t`Observers cannot upload Notes to project.`)
        return
    }

    if (files.length !== 1) {
        displayError(t`You must drop exactly one file.`)
        return
    }
    const file = files[0]

    if (file.type === 'application/json') {
        uploadJsonFile(file, passage, passageVideo)
            .catch((err: any) => {
                displayError(err)
            })
        return
    }

    if ((isAllowedImage(file.type) || canUploadWithoutCompression(file) === '')) {
        try {
            await uploadFile(file)
        } catch (err) {
            systemError(err)
        }
    } else {
        displayError(t`Cannot upload this type of file.` + ' ' + t`Supported types:` + ` ${[...allowedImageTypes, ...canUploadWithoutCompressionTypes, 'application/json (elan)'].join(', ')}`)
        return
    }
}

export async function uploadNote(
    note: PassageNote,
    username: string,
    name: string,
    file: File,
    passage: Passage | null,
    setNote: (note: PassageNote) => void
) {
    const item = note.createItem()
    await item.addViewedBy(username)

    const baseUrl = `${name}/${item._id}`
    const obj = 'Project.copyFileToVideoCache'
    if (!Project.copyFileToVideoCache) throw Error(t`${obj} not set`)
    item.url = await Project.copyFileToVideoCache(file, baseUrl, item.creationDate)
    item.fileType = file.type

    const _note = await note.addItem(item, passage)
    setNote(_note)
}

// should be moved to ProjectModels PassageVideo class
async function importNote(passage: Passage, video: PassageVideo, noteData: any, creator: string) {
    let note = video.createNote(noteData.position)
    note.startPosition = noteData.startPosition
    note.endPosition = noteData.endPosition

    await video.addNote(note)

    let _note = video.notes.find(n => note._id === n._id)
    if (!_note) {
        displayError('could not find newly created note')
        return
    }

    let item = _note.createItem()
    item.text = noteData.text
    await note.addItem(item, passage)
}

// should be moved to ProjectModels PassageVideo class
async function importSegment(video: PassageVideo, segData: any) {
    let position: number = segData.position

    let _seg = video.segments[0]

    if (position > .1) {
        await video.addSegment(position, [])
        let _seg2 = video.segments.find(s => s.position === segData.position)
        if (!_seg2) {
            debugger
            displayError('could not find newly created segment')
            return
        }

        _seg = _seg2
    }

    if (segData.references) {
        await _seg.setReferences(segData.references)
    }

    if (segData.glosses) {
        for (let gloss of segData.glosses) {
            await _seg.setGloss(gloss.identity, gloss.gloss)
        }
    }
}

async function importGloss(video: PassageVideo, gloss: any) {
    await video.addGloss(gloss.position, gloss.text)
}

async function getText(file: File): Promise<string> {
    let reader = new FileReader()
    return new Promise(resolve => {
        reader.onload = (e: any) => resolve(e.target.result)
        reader.readAsText(file)
    })
}

/*
    The user can drop a file in this format onto the Note Dialog in order to
    create notes or segments corresponding to info that has been extracted
    from Elan files (Nathan has code to do this)
    
    {
        "creator": "mpenner@biblesocieties.org",
        "notes": [
            { "position": 5, "startPosition": 4.95, "endPosition": 7, "text": "The sign here ..." }
        ],
        "segments": [
            { 
                "position": 3, 
                "references": "Leviticus 10:11",
                "glosses": [
                    {"identity": "milesnlwork@gmail.com", "gloss": "A fine kettle of fish..."}
                ]
            }
        ],
        "glosses": [
            {"position": 3.2, "text": "mother"}
        ]
    }
*/

//! will always attach imports to base video even if it has been patched over
async function uploadJsonFile(file: File, passage: Passage | null, passageVideo: PassageVideo | null) {
    let jsonText = await getText(file)
    let json: any = JSON.parse(jsonText)

    if (json.notes) {
        for (let note of json.notes) {
            await importNote(passage!, passageVideo!, note, json.creator)
        }
    }

    if (json.segments) {
        for (let seg of json.segments) {
            await importSegment(passageVideo!, seg)
        }
    }

    if (json.glosses) {
        for (let gloss of json.glosses) {
            await passageVideo!.addGloss(gloss.position, gloss.gloss)
        }
    }
}
