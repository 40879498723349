import Rollbar from 'rollbar'
import { summarizingThrottle } from './summarizingThrottle'
import { tinyHash } from './tinyHash'
import { unique } from 'underscore'


export class RollbarSingleton {
    static rollbarInstance: Rollbar | null = null

    static checkInstance(data: any) {
        if (!this.rollbarInstance) {
            console.error('### RollbarSingleton not initialized', data)
            return true
        }

        return false
    }

    static configure(config: Rollbar.Configuration) {
        if (this.checkInstance(config)) return

        try {
            this.rollbarInstance!.configure(config)
        } catch (error) {
            console.error('### RollbarSingleton.configure failed', error, config)
        }
    }

    static critical(message: any, preConfiguration = {}, postConfiguration = {}) {
        if (this.checkInstance(message)) return

        this.throttledRequest(this.rollbarInstance!, preConfiguration, postConfiguration, 'critical', message)
    }

    static error(message: any, preConfiguration = {}, postConfiguration = {}) {
        if (this.checkInstance(message)) return

        this.throttledRequest(this.rollbarInstance!, preConfiguration, postConfiguration, 'error', message)
    }

    static warning(message: any, preConfiguration = {}, postConfiguration = {}, disableThrottle = false) {
        if (this.checkInstance(message)) return
        if (disableThrottle) {
            this.handleErrorRequest(this.rollbarInstance!, preConfiguration, postConfiguration, 'warning', message)
            return
        } else {
            this.throttledRequest(this.rollbarInstance!, preConfiguration, postConfiguration, 'warning', message)
        }
    }

    static info(message: any, preConfiguration = {}, postConfiguration = {}) {
        if (this.checkInstance(message)) return

        this.throttledRequest(this.rollbarInstance!, preConfiguration, postConfiguration, 'info', message)
    }

    static handleErrorRequest = (rollbarInstance: Rollbar, preConfiguration = {}, postConfiguration = {}, level: RollbarLevel, message: string) => {
        console.log(`RollbarSingleton.throttledRequest (level: ${level}) message:`, message)
        rollbarInstance.configure(preConfiguration)
        try {
            switch (level) {
                case 'critical':
                    rollbarInstance.critical(message)
                    break
                case 'error':
                    rollbarInstance.error(message)
                    break
                case 'warning':
                    rollbarInstance.warning(message)
                    break
                case 'info':
                    rollbarInstance.info(message)
                    break
                default:
                    console.error('### RollbarSingleton.throttledRequest unknown level', level, message)
            }
        } catch (error) {
            console.error(`### RollbarSingleton.${level} failed`, error, message)
        } finally {
            rollbarInstance.configure(postConfiguration)
        }
    }

    static throttleWait = 5000

    static summarizeThrottledRequests = (summary: [string, number][], rollbarInstance: Rollbar, preConfiguration: object, postConfiguration: object, level: RollbarLevel, message: any) => {
        // given that summary is an array of arrays, each containing a key and a value
        // e.g. [['key1', 1], ['key2', 2]]. find total count of all calls
        const totalCallCount = summary.reduce((acc, [, value]) => acc + value, 0)
        if (totalCallCount > 1) {
            const callCountsJoined = summary.map(([key, value]) => `${key}: ${value} call(s)`).join('\n ')
            const callsHash = tinyHash(JSON.stringify(summary))
            const summarizeLevels = unique(summary.map(([key]) => JSON.parse(key)[0])).sort().join(', ')
            const throttledMessage = `[${callsHash}] throttled (${summarizeLevels}): ${totalCallCount} calls in ${(RollbarSingleton.throttleWait / 1000).toFixed(1)}s: {${callCountsJoined}}`
            RollbarSingleton.handleErrorRequest(rollbarInstance, preConfiguration, postConfiguration, 'critical', throttledMessage)
        } else {
            RollbarSingleton.handleErrorRequest(rollbarInstance, preConfiguration, postConfiguration, level, message)
        }
    }

    static throttledRequest = summarizingThrottle(
        RollbarSingleton.summarizeThrottledRequests, RollbarSingleton.throttleWait, 
        (args: any[]) => {
            return JSON.stringify(args.slice(3).map(arg => arg instanceof Error ? {
                message: arg.message,
                name: arg.name,
                constructor_name: arg.constructor.name,
                stack: arg.stack,
            } : arg))
        }
    )

    static rollbarThrottleTest(level: RollbarLevel, identical: boolean = false, maxTries = 5) {
        console.log(`RollbarSingleton.rollbarThrottleTest: trying '${level}' ${maxTries} times...`)
        for (let i = 0; i < maxTries; ++i) {
            const message = `testing ${identical ? 'identical' : `unique-${i}`} throttle ${level}`
            switch (level) {
                case 'critical':
                    RollbarSingleton.critical(message)
                    break
                case 'error':
                    RollbarSingleton.error(message)
                    break
                case 'warning':
                    RollbarSingleton.warning(message)
                    break
                case 'info':
                    RollbarSingleton.info(message)
                    break
                default:
                    console.error('### RollbarSingleton.rollbarThrottleTest unknown level', level)
            }
        }
    }
}

export type RollbarLevel = 'critical' | 'error' | 'warning' | 'info'

const _window = window as any
_window.rollbarThrottleTest = RollbarSingleton.rollbarThrottleTest
