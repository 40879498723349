import React, { Component } from 'react'
import { observable, makeObservable } from 'mobx';
import {observer} from 'mobx-react'
import { t } from 'ttag'
import { displayError } from '../utils/Errors'

import { Root } from '../../models3/Root'
import TextInput from '../utils/TextInput'

import _debug from "debug"; let log = _debug('sltt:PassageAdder') 

interface IPassageAdder {
    rt: Root,
}

class PassageAdder extends Component<IPassageAdder> {

    @observable adding = false    // entering name of passage to add
    @observable name = ''         // name of passage to add

    constructor(props: IPassageAdder) {
        super(props)

        makeObservable(this);

        let { rt } = this.props
        this.adding = rt.portion?.passages.length === 0
    }

    render() {
        let { rt } = this.props
        let { iAmTranslator } = rt

        if (!iAmTranslator) return null

        if (!this.adding)
            return (
                <div
                    className="passage-adder"
                    data-toggle="tooltip" title={/* translator: important */ t`Add new passage.`} 
                    onClick={ this.onAdding.bind(this) }>
                    <i className="far fa-fw fa-plus-square"></i>
                </div>
            )

        return (
            <div className="passage-box">
                <TextInput
                    message={/* translator: important */ t`Type Enter to add new passage or Esc to cancel.`}
                    placeholder={/* translator: important */ t`Passage Name`}
                    initialValue=""
                    validate={this.validate.bind(this)}
                    _onEscape={this.onCancel.bind(this)}
                    _onEnter={this.onEnter.bind(this)} />
            </div>
        )
    }

    // Start showing input box for name of passage to add
    onAdding() {
        this.adding = true
        this.name = ''
    }

    // Cancel adding a passage
    onCancel() { 
        this.adding = false
    }

    async addPassage(newPassageName: string) {
        let { rt } = this.props
        let { portion } = rt

        await portion!.addPassage(newPassageName)

        let newPassage = portion!.passages.slice(-1)[0]
        await rt.setPassage(newPassage)
    }

    onEnter(newPassageName: string) { 
        if (!this.validate(newPassageName)) {
            this.addPassage(newPassageName)
                .catch(displayError)
        }
        this.adding = false
    }

    validate(newPassageName: string) {
        let { rt } = this.props
        return rt.portion!.checkNewPassageName(newPassageName)
    }

}

export default observer(PassageAdder)