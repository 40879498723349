import React, { Component } from 'react'
import { Slider, Handles, SliderItem, GetHandleProps, Tracks, GetTrackProps, 
    Rail, GetEventData } from 'react-compound-slider'
import _ from 'underscore'


import './VideoTimeline.css'
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react'
import { fmt } from '../utils/Fmt'
import { Theme } from '../utils/LocalStorage'

import _debug from "debug"; let log = _debug('sltt:VideoTimeline') 
const fx = (val: number) => val.toFixed(2)


export class PositionSetter {
    @observable public value = 0

    constructor(public key: string,
        public getLowLimit: () => number,
        public getHighLimit: () => number,
        public onSetValue: (value: number) => void) {
        makeObservable(this);
    }

    setValue(_value: number) {
        let lowLimit = this.getLowLimit()
        let highLimit = this.getHighLimit()

        let value = limit(_value, lowLimit, highLimit)
        let changed = Math.abs(value - this.value) > 0.001
        
        // log(`setValue[${this.key}/${changed}] ${fx(lowLimit)}> ${fx(_value)} <${fx(highLimit)} `)

        if (!changed) { return value }

        this.value = value
        this.onSetValue(value)

        return value
    }
}

interface IVideoTimeline {
    setters: PositionSetter[],
    domainStartPosition: number,
    domainEndPosition: number,
    adjustTime: (newTime: number) => void,
    allowAdjustingPositions: boolean,
    enabled: boolean,
}

@observer
export class VideoTimeline extends Component<IVideoTimeline> {
    mouseDown = false

    step = 0.01

    render() {
        const { domainStartPosition, domainEndPosition, setters, enabled, allowAdjustingPositions } = this.props
        let { step } = this

        // The number of values = the number of draggable handles generated
        let values = setters.map(setter => setter.value)
        // log('render', fmt({values}))

        return (
            <div>
                <Slider
                    className="timeline-slider"
                    domain={[domainStartPosition, domainEndPosition]}
                    step={step}
                    mode={this.mode}
                    values={values}
                    disabled={!enabled}
                >
                    <Rail>
                        {({ getEventData }) => (
                            <ClickableRail
                                onMouseDown={(e) => this.onMouseDown(e, getEventData)}
                                onMouseMove={(e) => this.onMouseMove(e, getEventData)}
                                onMouseUp={this.onMouseUp}
                            />
                        )}
                    </Rail>
                    <Handles>
                        {({ handles, getHandleProps }) => {
                            // Hacky code.
                            // We use the VideoTimeline in 3 different ways
                            // 4 draggable handles, adjust note citation. [NoteLocationEditor]
                            // 3 handles, only currentTime handle draggable [ProjectReferenceVideoPlayer]
                            // 1 draggable handle, current time [GlossesViewer which is not currently used]
                            let leftMarkerHandle: SliderItem | undefined
                            let handle: SliderItem | undefined
                            let rightmarkerHandle: SliderItem | undefined
                            let timeHandle: SliderItem | undefined

                            if (handles.length === 1) { 
                                timeHandle = handles.find(e => e.id === '$$-0')
                            }
                            else if (handles.length === 3) { 
                                leftMarkerHandle = handles.find(e => e.id === '$$-0')
                                rightmarkerHandle = handles.find(e => e.id === '$$-1')
                                timeHandle = handles.find(e => e.id === '$$-2')
                            }
                            else if (handles.length === 4) { 
                                leftMarkerHandle = handles.find(e => e.id === '$$-0')
                                handle = handles.find(e => e.id === '$$-1')
                                rightmarkerHandle = handles.find(e => e.id === '$$-2')
                                timeHandle = handles.find(e => e.id === '$$-3')
                            }
                            else {
                                log('### unsupported number of handles', handles.length)
                            }
                            
                            return (
                                <div>
                                    {leftMarkerHandle && <LeftMarkerHandle
                                        key={leftMarkerHandle.id}
                                        handle={leftMarkerHandle}
                                        getHandleProps={getHandleProps}
                                        enabled={enabled && allowAdjustingPositions}
                                    />}
                                    {handle && <Handle
                                        key={handle.id}
                                        handle={handle}
                                        getHandleProps={getHandleProps}
                                        enabled={enabled && allowAdjustingPositions}
                                    />}
                                    {rightmarkerHandle && <RightMarkerHandle
                                        key={rightmarkerHandle.id}
                                        handle={rightmarkerHandle}
                                        getHandleProps={getHandleProps}
                                        enabled={enabled && allowAdjustingPositions}
                                    />}
                                    {timeHandle && <TimeCursorHandle
                                        key={timeHandle.id}
                                        handle={timeHandle}
                                        getHandleProps={getHandleProps}
                                        onMouseUp={this.onMouseUp}
                                        enabled={enabled}
                                    />}
                                </div>
                            )
                        }}
                    </Handles>
                    <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div>
                                {tracks.map(({ id, source, target }) => (
                                    <Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>
                </Slider>
            </div>
        )
    }

    mode = (curr: any[], next: any[]) =>
    {
        // SliderModeValue = { key, val }  key = '$$-0', '$$-1', '$$-2', '$$-3'
        let { setters } = this.props

        // ensure values in ascending order by key so we can use a fixed index
        let _next = _.sortBy([...next], 'key')
        log('_next[pre]', _next.map(x => x.val.toFixed(2))) 

        if (_next.length === 1) {
            setters[0].setValue(_next[0].val)  // set currentTime
            return _next
        }

        if (_next.length === 3) {
            // With 3 handles we have a fixed start position, fixed stop position, settable current time
            setters[2].setValue(_next[2].val)  // set currentTime
            return _next
        }

        // With 4 settable handle (used for note citations) we need to perform
        // the updates in a specific order to ensure startPosition <= position <= endPosition

        setters[3].setValue(_next[3].val)  // set currentTime

        // position establishes the limits for startPosition and endPosition
        // so set it first
        _next[1].val = setters[1].setValue(_next[1].val)

        // Set start and end position
        _next[0].val = setters[0].setValue(_next[0].val)
        _next[2].val = setters[2].setValue(_next[2].val)
        
        // Return updated values for cursor positions
        return _next
    }

    onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, getEventData: GetEventData) => {
        let { adjustTime } = this.props
        log('onMouseDown', getEventData(e).value)
        this.mouseDown = true
        adjustTime(getEventData(e).value)
    }

    onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, getEventData: GetEventData) => {
        if (this.mouseDown) {
            let { adjustTime } = this.props
            adjustTime(getEventData(e).value)
        }
    }

    onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.mouseDown = false
    }
}

interface IClickableRail {
    onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onMouseMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

let ClickableRail: React.FunctionComponent<IClickableRail> = ({ onMouseDown, onMouseMove, onMouseUp }) => {
    return (
        <div    className='timeline-rail-outer'
                onMouseUp={onMouseUp}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}>
            <div className='timeline-rail'></div>    
        </div>
    )
}

class Handle extends Component<IEditableHandle> {
    render() {
        let { enabled } = this.props
        return enabled ? (
            <div
                className="marker-handle clickable note-handle"
                style={{
                    left: `${this.props.handle.percent}%`,
                    color: this.props.enabled ? 'green' : 'gray'
                }}
                {...this.props.getHandleProps(this.props.handle.id)}
            >
                <i className="fas fa-fw fa-circle" />
            </div>
        ) : (
            <div
                className='marker-handle note-handle'
                style={{
                    left: `${this.props.handle.percent}%`,
                    color: 'gray'
                }}
            >
                <i className="fas fa-fw fa-circle" />
            </div>
        )
    }
}

interface IEditableHandle {
    handle: SliderItem,
    enabled: boolean,
    getHandleProps: GetHandleProps,
}

class LeftMarkerHandle extends Component<IEditableHandle> {
    render() {
        let { enabled } = this.props
        return enabled ? (
            <div
                className={`marker-handle clickable arrow-handle`}
                style={{
                    left: `${this.props.handle.percent}%`,
                    color: Theme.getColor('#333333', '#ffffff')
                }}
                {...this.props.getHandleProps(this.props.handle.id)}
            >
                <i className="fas fa-fw fa-caret-left fa-flip-horizontal" />
            </div>
        ) : (
            <div
                className={`marker-handle arrow-handle`}
                style={{
                    left: `${this.props.handle.percent}%`,
                    color: Theme.getColor('gray', '#ffffff')
                }}
            >
                <i className="fas fa-fw fa-caret-left fa-flip-horizontal" />
            </div>
        )
    }
}

class RightMarkerHandle extends Component<IEditableHandle> {
    render() {
        let { enabled } = this.props
        return enabled ? (
            <div
                className={`marker-handle clickable arrow-handle`}
                style={{
                    left: `${this.props.handle.percent}%`,
                    color: Theme.getColor('#333333', '#ffffff')
                }}
                {...this.props.getHandleProps(this.props.handle.id)}
            >
                <i className="fas fa-fw fa-caret-left" />
            </div>
        ) : (
            <div
                className={`marker-handle arrow-handle`}
                style={{
                    left: `${this.props.handle.percent}%`,
                    color: Theme.getColor('gray', '#ffffff')
                }}
            >
                <i className="fas fa-fw fa-caret-left" />
            </div>
        )
    }
}

interface ITimeCursorHandle {
    handle: SliderItem,
    getHandleProps: GetHandleProps,
    onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    enabled: boolean,
}

class TimeCursorHandle extends Component<ITimeCursorHandle> {
    render() {
        return (
            <div
                className={`marker-handle ${this.props.enabled && 'clickable'} time-cursor-handle`}
                style={{
                    left: `${this.props.handle.percent}%`,
                }}
                onMouseUp={this.props.onMouseUp}
                {...this.props.getHandleProps(this.props.handle.id)}
            />
        )
    }
}

interface ITrack {
    source: SliderItem,
    target: SliderItem,
    getTrackProps: GetTrackProps
}

class Track extends Component<ITrack> {
    render() {
        return (
            <div
                style={{
                    left: `${this.props.source.percent}%`,
                    width: `${this.props.target.percent - this.props.source.percent}%`,
                }}
            />
        )
    }
}

function limit(value: number, low: number, high: number) {
    value = Math.max(value, low)
    value = Math.min(value, high)
    return value
}

