import React, { FC, useContext, useState } from 'react'
import { t } from 'ttag'
import { observer } from 'mobx-react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { PassageNote, Project } from '../../models3/ProjectModels'
import { displayError } from '../utils/Errors'
import { Root } from '../../models3/Root'
import { OKButton, CancelButton, CircleButton, OkEditSegmentButton, CancelEditSegmentButton } from '../utils/Buttons'
import { Modal } from 'react-bootstrap'
import ColorPicker from '../utils/ColorPicker'
import { DateFormat, VideoTimeCodeFormat } from '../../models3/ProjectModels'

import './ProjectSettings.css'
import { NoteSelector } from '../notes/NoteSelector'
import { RootContext } from '../app/RootContext'
import { TextInputWithEditButton } from '../utils/TextInput'

interface ITeamPreferences {
    rt: Root,
}

const TeamPreferences: FC<ITeamPreferences> = observer(({ rt }) => {
    let { project } = rt
    let { noteColors, dateFormat, setDateFormat } = project
    setDateFormat = setDateFormat.bind(rt.project) // TODO: remove this by making setDateFormat an arrow function () => {} on Project

    return (
        <div className="team-preferences">
            <h3>{t`Team Preferences`}</h3>
            <div className="container-fluid">
                <NoteMarkerColors project={project} colors={noteColors} />
                <NoteReactionEmojis project={project} />
                <DateFormatSelector {...{ dateFormat, setDateFormat }} />
                <VideoTimeCodeFormatSelector {...{ project }} />
            </div>
            <VideoCompressionPreferences {...{ project }} />
        </div>
    )
})

interface IDateFormatSelector {
    dateFormat: DateFormat,
    setDateFormat: (format: DateFormat) => void,
}

const DateFormatSelector: FC<IDateFormatSelector> = observer(({ dateFormat, setDateFormat }) => {
    let values = Object.values(DateFormat)
    return (
        <div className='flex-centered team-preference-option date-format-selector'>
            <div className='team-preference-option-label'>
                {t`Date format`}
            </div>
            <select value={dateFormat} onChange={e => setDateFormat(e.target.value as DateFormat)}>
                {values.map(item => (
                    <option key={item} value={item}>{item}</option>
                ))}
            </select>
        </div>
    )
})

interface INoteMarkerColors {
    colors: string[],
    project: Project,
}

const NoteMarkerColors: FC<INoteMarkerColors> = observer(({ colors, project }) => {
    const [editingIndex, setEditingIndex] = useState(-1)
    const [adding, setAdding] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const flags = useFlags()

    const rt = useContext(RootContext)

    const colorCount = PassageNote.nonDeletedColors(colors).length

    const canAdd = colorCount < NoteSelector.maxColors
    const canDelete = (colorCount > 3) && rt?.iAmAdmin

    async function saveColor(color: string, index: number) {
        let newColors = colors.slice()
        newColors[index] = color
        try {
            await project.setNoteColors(newColors)
        } catch (error) {
            displayError(error)
        }
    }

    if (adding) {
        return (<NoteMarkerColorEditor {...{ color: '#ff0000', setEditing: setAdding, 
            setColor: (color:string) => {
                // reuse deleted color index if any, otherwise add to end
                let colorIndex = colors.indexOf(PassageNote.DeletedColor)
                if (colorIndex === -1) {
                    colorIndex = colors.length
                }
                saveColor(color, colorIndex).catch(displayError)
            } }} />)
    }

    if (deleting) {
        return (<NoteMarkerColorDeleter {...{project, deleting, setDeleting }} />)
    }

    return (
        <div className='note-marker-color-list'>
            <div className='note-marker-colors-label'>
                {t`Note marker colors`}
            </div>
            {colors.map((color, i) => (
                <EditableNoteMarkerColor
                    key={i}
                    editing={editingIndex === i}
                    setEditing={editing => rt?.iAmAdmin && setEditingIndex(editing ? i : -1)}
                    color={color}
                    setColor={color => saveColor(color, i)}
                />
            ))}
            {canAdd && <span onClick={() => setAdding(true)}
                data-toggle="tooltip"
                title={t`Add a new note color.`}
                className="far fa-fw fa-plus-square portion-add-button" /> }
            {flags.allowDeleteNoteColor && canDelete && <span onClick={() => setDeleting(true)}
                data-toggle="tooltip"
                title={t`Delete a note color.`}
                className="far fa-fw fa-trash-alt portion-add-button" />}
        </div>
    )
})


interface INoteReactionEmojis {
    project: Project,
}

const NoteReactionEmojis: FC<INoteReactionEmojis> = observer(({ project }) => {
    const rt = useContext(RootContext)
    const editable = rt?.iAmAdmin || false

    const explanation = t`Enter emojis or short texts for note reactions. Separate with commas. To add emojis on macOS, use Control-Command-Space. To add emojis on Windows, use Windows-Period.`

    return (
        <div className='note-reaction-emojis'>
            <div className="note-reaction-emojis-label">{t`Note emojis`}</div>
            <TextInputWithEditButton 
                explanation={explanation}
                allowEdit={editable} 
                value={project.emojis} 
                setValue={value => project.setEmojis(value).catch(displayError)} 
                placeholder={t`Emojis`} />
        </div>
    )
})


function findColoredNotes(project: Project, colorIndex: number) {
    if (colorIndex === -1) return []

    return [...project.passageVideos()].
        flatMap(video => video.notes.filter(note => note.colorIndex() === colorIndex))  
}



interface IEditableNoteMarkerColor {
    color: string,
    editing: boolean,
    setEditing: (value: boolean) => void,
    setColor: (color: string) => void
}

const EditableNoteMarkerColor: FC<IEditableNoteMarkerColor> = ({ editing, setEditing, color, setColor }) => {
    if (color === PassageNote.DeletedColor) return null
    
    let circleButtonStyle = {
        color,
        outline: editing ? '1px solid black' : '',
        borderRadius: '4px',
    }

    return (
        <div className='note-marker-color'>
            <CircleButton style={circleButtonStyle} className={'note-marker-color-icon'} tooltip={t`Edit note marker color`} onClick={() => setEditing(true)} />
            {editing && <NoteMarkerColorEditor {...{ color, setEditing, setColor }} />}
        </div>
    )
}

interface INoteMarkerColorEditor {
    color: string,
    setEditing: (value: boolean) => void,
    setColor: (color: string) => void,
}

const NoteMarkerColorEditor: FC<INoteMarkerColorEditor> = ({ color, setEditing, setColor }) => {
    const [draftColor, setDraftColor] = useState(color)

    function save() {
        setColor(draftColor)
        setEditing(false)
    }

    return (
        <Modal show={true} onHide={save}>
            <Modal.Header>
                <h3>{t`Edit note marker color`}</h3>
            </Modal.Header>
            <Modal.Body>
                <ColorPicker color={draftColor} setColor={setDraftColor} />
            </Modal.Body>
            <Modal.Footer>
                <div className='note-marker-color-modal-buttons'>
                    <OKButton enabled={true} onClick={save} buttonClassName='' className='ok-button' tooltip={t`Save changes`} />
                    <CancelButton enabled={true} onClick={() => setEditing(false)} className='cancel-button' tooltip={t`Cancel editing`} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

interface ISelectableNoteMarkerColor {
    color: string,
    colorIndex: number,
    unselectableColorIndices: number[],
    selectedColorIndex: number,
    setSelectedColorIndex: (colorIndex: number) => void,
}

const SelectableNoteMarkerColor: FC<ISelectableNoteMarkerColor> =
    ({ color, colorIndex, unselectableColorIndices, selectedColorIndex, setSelectedColorIndex }) => 
{
    if (color === PassageNote.DeletedColor) return null
    
    const selectable = !unselectableColorIndices.includes(colorIndex)

    const circleButtonStyle = {
        color,
        borderRadius: '4px',
        opacity: selectable ? 1 : 0,
    }

    let className = (colorIndex === selectedColorIndex) ? 'selected-note-marker-color-icon' : ''

    return (
        <div className='note-marker-color'>
            <CircleButton style={circleButtonStyle}
                className={className}
                tooltip={''}
                onClick={() => selectable && setSelectedColorIndex(colorIndex)} />
        </div>
    )
}

interface ISelectableNoteMarkerColors {
    title: string,
    colors: string[],
    unselectableColorIndices: number[],
    selectedColorIndex: number,
    setSelectedColorIndex: (colorIndex: number) => void,
}

const SelectableNoteMarkerColors: FC<ISelectableNoteMarkerColors> =
    ({ title, colors, unselectableColorIndices, selectedColorIndex, setSelectedColorIndex }) => 
{
    return (
        <div className="selectable-note-marker-colors">
            <div className="selectable-note-marker-colors-list-item selectable-note-markers-colors-label">
                {title + ':'}
            </div>
            <div className="selectable-note-marker-colors-list">
                {colors.map((color, colorIndex) => (
                    <div key={colorIndex}>
                        <SelectableNoteMarkerColor
                            {...{ color, colorIndex, unselectableColorIndices, selectedColorIndex, setSelectedColorIndex }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

/*
 * Show a modal dialog to delete a note color.
 * If any notes are colored with the deleted color, the user must select a replacement color.
 * When the user clicks the OK button, all notes with the deleted color will be recolored
 * with the replacement color, and the deleted color will be replaced with PassageNote.DeletedColor.
 */

interface NoteMarkerColorDeleter {
    project: Project,
    deleting: boolean,
    setDeleting: (value: boolean) => void,
}

const NoteMarkerColorDeleter: FC<NoteMarkerColorDeleter> = ({ project, deleting, setDeleting }) => {
    const [deletedColorIndex, setDeletedColorIndex] = useState(-1)
    const [replacementColorIndex, setReplacementColorIndex] = useState(-1)

    const matchingNotes = findColoredNotes(project, deletedColorIndex)
    const matchingNotesLabel = matchingNotes.length ? matchingNotes.length.toString() : ''
    
    const nondeletableColorIndices = [0, 1, 2]

    const canDelete = deletedColorIndex !== -1 && (replacementColorIndex !== -1 || matchingNotesLabel === '')

    async function deleteColor() {
        for (let note of matchingNotes) {
            await note.setColor(replacementColorIndex)
        }

        await project.deleteColor(deletedColorIndex)

        setDeleting(false)
    }

    return (
        <Modal style={{ top: '1%' }}
            bsSize="large"
            show={deleting}
            onHide={() => setDeleting(false)} >
            <Modal.Header closeButton> {t`Delete Note Color`} </Modal.Header>
            <Modal.Body>
                <div>
                    <SelectableNoteMarkerColors 
                        {...{ 
                            title: t`Select color to delete`, 
                            colors: project.noteColors,
                            unselectableColorIndices: nondeletableColorIndices,
                            selectedColorIndex: deletedColorIndex,
                            setSelectedColorIndex: setDeletedColorIndex,
                        }}
                    />
                    {matchingNotesLabel  && <SelectableNoteMarkerColors
                        {...{
                            title: t`Select replacement color for ${matchingNotesLabel} note(s)`,
                            colors: project.noteColors,
                            unselectableColorIndices: [deletedColorIndex],
                            selectedColorIndex: replacementColorIndex,
                            setSelectedColorIndex: setReplacementColorIndex,
                        }}
                    />}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <OkEditSegmentButton
                    enabled={canDelete}
                    onClick={deleteColor} />
                <CancelEditSegmentButton
                    enabled={true}
                    onClick={() => setDeleting(false)} />
            </Modal.Footer>
        </Modal>
    )
}

type VideoCompressionPreferencesProps = {
    project: Project,
}

type IVideoTimeCodeFormatSelector = {
    project: Project,
}

const VideoTimeCodeFormatSelector: FC<IVideoTimeCodeFormatSelector> = observer(({ project }) => {
    const { setVideoTimeFormat, videoTimeCodeFormat } = project
    const values = Object.values(VideoTimeCodeFormat)
    const handleTimeFormatChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const videoTimeCodeFormat = event.target.value as VideoTimeCodeFormat
        setVideoTimeFormat(videoTimeCodeFormat)
    }

    return (
        <div className='flex-centered team-preference-option'>
            <div className='team-preference-option-label'>
                {t`Video time code format`}
            </div>
            <select value={videoTimeCodeFormat} onChange={handleTimeFormatChange}>
                {values.map(item => (
                    <option key={item} value={item}>{item}</option>
                ))}
            </select>
        </div>
    )
})

const VideoCompressionPreferences: FC<VideoCompressionPreferencesProps> = observer(({ project }) => {
    let { compressedVideoResolution, compressedVideoQuality,
        maxVideoSizeMB, setCompressedVideoQuality, setCompressedVideoResolution,
        setMaxVideoSizeMB } = project

    return (
        <div>
            <h4>{t`Video Compression`}</h4>
            <div className="container-fluid">
                <div className='flex-centered team-preference-option'>
                    <div className='team-preference-option-label'>
                        {t`Resolution`}
                    </div>
                    <select value={compressedVideoResolution} onChange={e => setCompressedVideoResolution(parseInt(e.target.value))}>
                        <option value='480'>480</option>
                        <option value='720'>720</option>
                    </select>
                </div>
                <div className='flex-centered team-preference-option'>
                    <div className='team-preference-option-label'>
                        {t`CRF (video quality)`}
                    </div>
                    <select value={compressedVideoQuality} onChange={e => setCompressedVideoQuality(parseInt(e.target.value))}>
                        <option value='18'>18 (Very high)</option>
                        <option value='19'>19</option>
                        <option value='20'>20</option>
                        <option value='21'>21</option>
                        <option value='22'>22</option>
                        <option value='23'>23</option>
                        <option value='24'>24</option>
                        <option value='25'>25</option>
                        <option value='26'>26</option>
                        <option value='27'>27</option>
                        <option value='28'>28 (Very low)</option>
                    </select>
                </div>
                <div className='flex-centered team-preference-option'>
                    <div className='team-preference-option-label'>
                        {t`Maximum size of compressed video (MB)`}
                    </div>
                    <select value={maxVideoSizeMB} onChange={e => setMaxVideoSizeMB(parseInt(e.target.value))}>
                        <option value='100'>100</option>
                        <option value='250'>250</option>
                    </select>
                </div>
            </div>
        </div>
    )
})

export default TeamPreferences