import React, { useRef, useEffect, useState } from 'react'
import _debug from "debug"; const log = _debug('sltt:TRLThumbnailVideoCreator')

interface ThumbnailVideoProps {
    videoUrl: string
    startTime: number
    endTime: number
    onRecordingTimeUpdate: (time: number) => void
    onThumbnailCreated: (thumbnailBlob: Blob, thumbnailUrl: string, startTime: number, endTime: number) => void
}

const TRLThumbnailVideoCreator: React.FC<ThumbnailVideoProps> = (
    { videoUrl, startTime, endTime, onRecordingTimeUpdate, onThumbnailCreated }
) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const mediaRecorderRef = useRef<MediaRecorder>()
    const chunksRef = useRef<Blob[]>([])

    useEffect(() => {
        const video = videoRef.current
        if (!video) return
        log('initializing', {startTime, endTime})

        return () => {
            mediaRecorderRef.current!.state !== 'inactive' && mediaRecorderRef.current!.stop()
        }
    }, [startTime, endTime])

    const handleDataAvailable = (event: BlobEvent) => {
        const chunks = chunksRef.current
        if (event.data.size > 0) {
            chunks.push(event.data)
        }
    }

    const handleStop = () => {
        const chunks = chunksRef.current
        const thumbnailBlob = new Blob(chunks, { type: 'video/mp4' })
        const thumbnailUrl = URL.createObjectURL(thumbnailBlob)
        log('handleStop', {thumbnailUrl})
        onThumbnailCreated(thumbnailBlob, thumbnailUrl, startTime, endTime)
    }

    const handleOnPlay = () => {
        const video = videoRef.current
        if (!video) return

        let options = undefined
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
            options = { mimeType: 'video/webm; codecs=vp9' }
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
            options = { mimeType: 'video/webm; codecs=vp8' }
        } else {
            // ...
        }
        const stream = (video as any).captureStream(11)
        const recorder = new MediaRecorder(stream, {
            ...options,
            videoBitsPerSecond: 1000000
        })
        mediaRecorderRef.current = recorder
        recorder.ondataavailable = handleDataAvailable
        recorder.onstop = handleStop
        recorder.start()
        log('handleOnPlay: recording thumbnail video')
    }

    const onCanPlayThrough = () => {
        const video = videoRef.current
        if (!video) return
        log('canplaythrough', {currentTime: video.currentTime, startTime })
        if (video.currentTime < startTime) {
            video.currentTime = startTime
        }
        video.play()
    }

    const onTimeUpdate = () => {
        const video = videoRef.current
        if (!video) return
        // log('timeupdate', {currentTime: video.currentTime, endTime })
        if (video.currentTime >= endTime) {
            video.pause()
            mediaRecorderRef.current!.stop()
        }
        onRecordingTimeUpdate(video.currentTime)
    }

    return (
        <>
            <video
                ref={videoRef} src={videoUrl} style={{ display: 'none' }}
                onPlay={handleOnPlay}
                onCanPlayThrough={onCanPlayThrough}
                onTimeUpdate={onTimeUpdate}
            />
        </>
    )
}

export default TRLThumbnailVideoCreator