import React from 'react'
import { observer } from 'mobx-react'

import { VideoCache } from '../../models3/VideoCache'
import { systemError } from './Errors'
import { VideoDownloadingMessage } from '../video/VideoMessage'
import { fmt } from './Fmt'

import _debug from "debug"; let log = _debug('sltt:VideoDownloading') 

interface IVideoDownloading {
    videoUrl: string,
    creator: string,
    onEnded: (blob: Blob) => void,
    fontSizePt: number,
    className?: string
}

class VideoDownloading extends React.Component<IVideoDownloading> {
    timer: any

    constructor(props: IVideoDownloading)
    {
        super(props)
        this.clearMyTimer.bind(this)
        this.fetchStatus.bind(this)
    }

    componentDidMount() {
        this.fetchStatus()
    }

    componentWillUnmount() {
        this.clearMyTimer()
    }

    clearMyTimer() {
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
        }
    }

    fetchStatus() {
        VideoCache.queryVideoDownload(this.props.videoUrl)
            .then(response => {
                if (response.blob) {
                    this.props.onEnded(response.blob)
                } else {
                    this.timer = setTimeout(() => this.fetchStatus(), 2000)
                }
            })
            .catch((error: any) => {
                this.clearMyTimer()
                systemError(error)
            })
    }

    render() {
        let { className, videoUrl, creator, fontSizePt: fontSize } = this.props
        return (
            <div className={className || ''}>
                <VideoDownloadingMessage urls={[videoUrl]} creator={creator} fontSizePt={fontSize} />
            </div>
        )
    }
}

export default observer(VideoDownloading)