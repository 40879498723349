
import { VideoCache } from './VideoCache'
import { VideoCacheRecord, VideoBlob, VideoDownloadResult } from './VideoCacheRecord'
import { IProgress } from './API2'
import _, { Dictionary } from 'underscore'
import { observable } from 'mobx'
import { isSlttAppStorageEnabled } from './SlttAppStorage'
import { getIsAppOnlineOrWait } from '../components/utils/ServiceStatus'

import _debug from "debug"
let log = _debug('sltt:VideoCacheDownloader')
let dbg = _debug('slttdbg:_VideoCacheDownloader')

const fetchLimit = 4 // max simultaneous fetches
const perUrlFetchLimit = 3 // max simultaneous fetches to different chunks of same url

/**
 * Goal: Download the highest priority videos first.
 * 
 * Constraints:
 *   - No more than fetchLimit (4) simultaneous downloads from S3
 *   - If this block is has been marked 'notUploaded' and there is an active fetch for
 *     a lower numbered block, this black cannot be started
 */

export interface IVideoDownloadQuery {
    message: string,
    blob: Blob | undefined,
}

// Request to download a chunk of a video blob
export class ScheduledDownload {
    public progress: IProgress | null = null // { total: number, loaded: number }
        // when non-null there is a fetch in progress for this chunk

    public delayUntil = 0
        // When non-zero, don't allow this fetch to be started until this date

    public retries = 0

    constructor(
        public videoUrl: string,
        public chunkNum: number,   // 0 origin chunk number for this blob
        public priority: number,   // bigger is more urgent
        public vcr: VideoCacheRecord,
    ) { }

    match(vcr: VideoCacheRecord, chunkNum: number) {
        return this.videoUrl === vcr._id && this.chunkNum === chunkNum
    }

    exponentialBackoffRetry(result: VideoDownloadResult) {
        let backoff = 3600
        if (result !== VideoDownloadResult.FAILED) {
            let backoffs = [2, 2, 2, 2, 2, 5, 5, 5, 5, 5, 5, 20, 20, 20, 20, 20, 60, 60, 60, 60, 60, 10*60]
            backoff = backoffs[Math.min(this.retries, backoffs.length - 1)]
        }

        this.delayUntil = Date.now() + 1000*backoff
        
        ++this.retries
    }

    clone() {
        let c = new ScheduledDownload(this.videoUrl, this.chunkNum, this.priority, this.vcr)
        if (this.progress) {
            let {total, loaded} = this.progress
            c.progress = {total, loaded}
        }
        
        c.delayUntil = this.delayUntil
        c.retries = this.retries

        return c
    }

    static eq(s1: ScheduledDownload, s2: ScheduledDownload) {
        return s1.videoUrl === s2.videoUrl && s1.chunkNum === s2.chunkNum
    }
}

export class VideoCacheDownloader {
    static unittestQuota = 0
    static unittestUsage = 0

    static systemError = (err: any) => {
        console.error(err)
    }

    requestCount = 0   
       // track request number so that latest requests
       // can be given higher priority

    scheduledDownloads: ScheduledDownload[] = []
    @observable downloadCount = 0

    constructor(public displayError?: (message: any) => void) {
        if (displayError) VideoCacheDownloader.systemError = displayError

        setInterval(this.startDownloads.bind(this), 2000)
    }

    // Return a clone of the scheduled downloads queue.
    // Sort items in progress to the top.
    // This is used for debugging.
    queryScheduledDownloads() {
        let sds: ScheduledDownload[] = []

        this.scheduledDownloads.forEach(sd => {
            let index = sds.findIndex(_sd => _sd.videoUrl === sd.videoUrl)
            if (index < 0) {
                sds.push(sd)
            } else if (sd.priority > sds[index].priority) {
                sds[index] = sd
            }
        })

        sds = _.sortBy(sds, sd => -sd!.priority)
        return sds
    }

    async getProgress(videoUrl: string) {
        let vcr = await VideoCacheRecord.get(videoUrl)
        let { numberUploaded, numberDownloaded, totalBlobs } = vcr
        return { numberUploaded, numberDownloaded, totalBlobs }
    }

    // This method gets called every few seconds from a control that needs the
    // specified video data. If the download is complete the blob is eturned.
    // If no download has been scheduled yet for the url it is scheduled.
    // Otherwise a progress message for the download is returned.
    async queryVideoDownload(videoUrl: string): Promise<IVideoDownloadQuery> {
        let vcr = await VideoCacheRecord.get(videoUrl)

        // If data is already present because the video was locally created, return video blob
        if (vcr.uploadeds.length) {
            dbg(`queryVideoDownload locally created`)
            let blob = await vcr.getVideoBlob()
            if (blob) {
                return { message: '', blob }
            }
        }

        // Ensure status array for downloads of individual chunks of video initialized
        await vcr.setupDownload()
        
        // If already compltedly downloaded, return video blob
        if (vcr.downloaded) {
            dbg(`queryVideoDownload downloaded`)
            let blob = await vcr.getVideoBlob()
            if (blob) {
                return { message: '', blob }
            }
            // If we did not get the blob, it means that we incorrect in
            // thinking it was already downloaded, fall through to download
        }

        if (!isSlttAppStorageEnabled() && !(await getIsAppOnlineOrWait())) {
            return { message: "No internet connection!", blob: undefined }
        }

        this.scheduleDownload(vcr, true)

        return { message: vcr.downloadMessage, blob: undefined }
    }

    async implicitVideoDownload(videoUrl: string) {
        log(`implicitVideoDownload ${videoUrl}`)

        let vcr = await VideoCacheRecord.get(videoUrl)

        // If data is already present because the video was locally created, return video blob
        if (vcr.uploadeds.length) return true

        // Ensure status array for downloads of individual chunks of video initialized
        await vcr.setupDownload()

        // If already compltedly downloaded, done
        if (vcr.downloaded) return true

        this.scheduleDownload(vcr, false)

        return false
    }

    // Add any entries to scheduledDownloads.
    // Each entry represents a chunk that needs to be downloaded
    scheduleDownload(vcr: VideoCacheRecord, explicitRequest: boolean) {
        const explicitRequestPriorityBump = 1000000

        // process chunks backwards because we want to start downloading
        // with first chunk and the last chunk processed has the hightest
        // priority
        for (let i = vcr.downloadeds.length-1; i >= 0; --i) {
            if (!vcr.downloadeds[i]) {
                // chunk has not downloaded yet
                let j = this.scheduledDownloads.findIndex(sd => sd.match(vcr, i))
                if (j >= 0) {
                    // Download already scheduled.
                    // Nothing to do unless this video was not explicitly requested before
                    // and now it is. If so, bump priority]
                    let sd = this.scheduledDownloads[j]
                    if (explicitRequest && sd.priority < explicitRequestPriorityBump) {
                        sd.priority += explicitRequestPriorityBump
                    }
                } else {
                    // Download not scheduled yet, schedule it.
                    // The recently requested videos have the higher download priority
                    // If a video has been explictly requested for display by a user
                    // it gets a big priority bump.
                    this.requestCount += 1
                    let priority =this.requestCount
                    if (explicitRequest) { priority += explicitRequestPriorityBump }
                    log('add ScheduledDownload', vcr.videoBlobId(i), priority)

                    let sd = new ScheduledDownload(vcr._id, i, priority, vcr)
                    this.scheduledDownloads.push(sd)
                    this.downloadCount = this.scheduledDownloads.length
                }
            }
        }

        this.startDownloads()
    }

    startDownloads() {
        let sds = this.scheduledDownloads
        if (!sds.length) return

        let now = Date.now()
        let urlCount = _.countBy(sds.filter(sd => sd.progress), sd => sd.videoUrl)

        let calcPriority = (sd: ScheduledDownload) => {
            let {delayUntil, progress, videoUrl, chunkNum, priority, vcr} = sd

            // After errors, we delay a while before retrying
            if (delayUntil && delayUntil > now) return -1
            
            // Should not restart if already in progress
            if (progress) return -2
            
            // Only allow a limited number fetches for each url (so that
            // one video cannot hog all the download bandwidth)
            if ((urlCount[videoUrl] || 0) >= perUrlFetchLimit) return -3

            // If an earlier chunk has not been uploaded or had an exception, don't try to download this chunk
            let previous = vcr.downloadResults.slice(0, chunkNum)
            if (previous.includes(VideoDownloadResult.MISSING)) return -4
            if (previous.includes(VideoDownloadResult.FAILED)) return -5

            return priority
        }

        while (true) {
            let inProgress = this.scheduledDownloads.filter(sd => sd.progress)
            dbg(`inProgress = ${inProgress.length}`)
            
            if (inProgress.length >= fetchLimit) break;

            if (sds.length === 0) break
            let topSd = _.max(sds, calcPriority) as ScheduledDownload
            
            // Break if best download items have negative priority
            let priority = calcPriority(topSd)
            if (priority < 0) { 
                dbg(`negative priority [${topSd.videoUrl}] ${priority}`)
                break
            }

            urlCount[topSd.videoUrl] = urlCount[topSd.videoUrl] + 1
            topSd.progress = {total: 0, loaded: 0}

            this.startDownload(topSd)
                .catch(error => {
                    // NEVER supposed to happen
                    VideoCacheDownloader.systemError(error)
                })
        }
    }

    // Start download for a single video chunk.
    // DO NOT throw an exception.
    // On error
    //      - write message to vcr
    //      - remove request from scheduledDownloads
    async startDownload(sd: ScheduledDownload) {
        let _id_ = `${sd.videoUrl}[${sd.chunkNum}]`

        dbg(`startDownload ${_id_}`)

        try {
            let vcr = await VideoCacheRecord.get(sd.videoUrl)

            // Ensure that the video record is touched (which updates accessDate) so that it is not deleted
            await vcr.touch()

            // Clear out space in cache if necessary
            if (!await this.makeSpaceAvailable()) {
                await vcr.setDownloadMessage('No disk space available')
                this.downloadCompleted(sd)
                return
            }

            if (vcr.downloadeds[sd.chunkNum]) { 
                this.downloadCompleted(sd)
                return
            }

            let recordProgress = (progress: IProgress) => {
                //log(`reportProgress ${_id_}`, progress)

                sd.progress!.total = progress.total
                sd.progress!.loaded = progress.loaded

                this.setProgressMessage(sd.videoUrl)
                    .catch()
            }

            let result = await vcr.fetchBlob(sd.chunkNum, recordProgress)

            if (result !== VideoDownloadResult.SUCCESS) {
                // fetch failed
                sd.exponentialBackoffRetry(result)
                await vcr.setDownloadMessage(result)
            } else {
                // fetchBlob succeeded
                await this.setProgressMessage(sd.videoUrl)
                this.downloadCompleted(sd)
            }

            sd.progress = null
        } 
        catch (err) {
            sd.progress = null
            sd.exponentialBackoffRetry(VideoDownloadResult.FAILED)
            VideoCacheDownloader.systemError(err)
        }
    }

    // Set progress message by totaling the number of partially downloaded
    // blocks and fully downloaded blocks.

    async setProgressMessage(videoUrl: string) {
        let vcr = await VideoCacheRecord.get(videoUrl)

        let downloadedBlocks = vcr.downloadeds.filter(d => d).length

        let partialBlocks = 0
        for (let sd of this.scheduledDownloads) {
            if (sd.videoUrl === videoUrl && sd.progress && sd.progress.loaded) {

                // If a bug causes a block that has been previously downloaded to
                // start downloading again, avoid double counting the block
                // when calculating progress
                if (vcr.downloadeds[sd.chunkNum]) {
                    downloadedBlocks -= 1
                }

                partialBlocks += sd.progress.loaded / sd.progress.total
            }
        }
        
        let totalBlocks = vcr.seqNum()
        let percent = 100 * (partialBlocks + downloadedBlocks) / totalBlocks

        await vcr.setDownloadMessage(`Downloading ... ${percent.toFixed(1)}%`)
    }

    downloadCompleted(sd: ScheduledDownload) {
        let _id_ = `${sd.videoUrl}[${sd.chunkNum}]`
        log('downloadCompleted', _id_)

        let before = this.scheduledDownloads.length
        this.scheduledDownloads = this.scheduledDownloads.filter(_sd => !ScheduledDownload.eq(_sd, sd))
        this.downloadCount = this.scheduledDownloads.length

        if (before === this.scheduledDownloads.length) {
            debugger
        }
        
        this.startDownloads()
    }

    /**
     *  Limit cache size  to 90% of available space by deleting old entries.
     *  @return {boolean} True iff we can delete enough items to fall within our space quota
     */
    async makeSpaceAvailable() {
        let tries = 0

        while (true) {
            let requiredToFree = await this.checkQuota()
            if (requiredToFree === 0) return true

            let vcrs = await VideoCache.getAllVcrs()
            vcrs = _.sortBy(vcrs, x => x.accessDate)
            log(`makeSpaceAvailable freeing ${(requiredToFree/(1024*1024)).toFixed(0)}`)

            for (let i = 0; i < vcrs.length; ++i) {
                let vcr = vcrs[i]

                // Do not delete blobs that have not been uploaded yet!
                if (vcr.uploadeds.length > 0 && !vcr.uploaded) continue

                // If nothing has been downloaded for this, there is nothing to delete
                if (!vcr.uploaded && !vcr.downloadeds.some(d => d)) continue

                requiredToFree -= vcr.size
                await vcr.deleteBlobs()

                // if (VideoCacheDownloader.unittestQuota) {
                //     // simulate usage going down due to deletion
                //     VideoCacheDownloader.unittestUsage -= vcrs[i].size
                // }

                if (requiredToFree <= 0) break
            }

            tries += 1
            if (tries > 10) {
                return false // give up
            }
        }
    }

    /**
     *  @return {number} 0 if we are within quota, otherwise desired amount to free
     */
    async checkQuota() {
        let estimate = await this.getQuotaAndUsage()

        return estimate.usage <= estimate.quota ? 0 : estimate.usage - .9 * estimate.quota
    }

    // Get structure with current storage 'usage' and 'quota'.
    // Quota is what the browser is willing to give us or the hard limit of 10G,
    // whichever is less.
    async getQuotaAndUsage(): Promise<any> {
        let estimate: any

        estimate = await navigator.storage.estimate()

        if (VideoCacheDownloader.unittestQuota) estimate.quota = VideoCacheDownloader.unittestQuota
        if (VideoCacheDownloader.unittestUsage) estimate.usage = VideoCacheDownloader.unittestUsage
        
        if (estimate.usage === undefined || estimate.quota === undefined)
            throw Error('Undefined storage estimage')
        
        let persistedHardQuota = localStorage.getItem('videoCacheLimitGB') || ''
        let persistedHardQuotaGB = parseFloat(persistedHardQuota) || 10
        let hardQuota = persistedHardQuotaGB * 1024 * 1024 * 1024
        let quota = .8 * estimate.quota
        estimate.quota = quota < hardQuota ? quota : hardQuota
        log(`quota=${(estimate.quota / (1024 * 1024)).toFixed(0)}, usage=${(estimate.usage / (1024 * 1024)).toFixed(0)}`)
        
        return estimate
    }

    // Run stress test on cacheing mechanism by creating a ton of VideoCacheRecords
    // and associated VideoBlobs.
    // In theory this should be able to run indefinitely since makeSpaceAvailable
    //should keep freeing blobs.
    // Execut from console: window.videoCacheStressTest()

    async stressTest() {
        //create blob
        const buffer = new ArrayBuffer(8*1024*1024);
        let blob = new Blob([buffer])
        let blobCount = 0

        VideoCacheDownloader.unittestQuota = 4*1024*1024*1024

        while (true) {
            let url = String(blobCount).padStart(8, '0')

            blobCount += 1
            if (Math.round(blobCount) % 50 === 0) {
                log(`created ${url}`)
                if (!await this.makeSpaceAvailable()) {
                    console.error('makeSpaceAvailable failed')
                    debugger
                    return
                }
            }

            let vcr = new VideoCacheRecord({ _id: `${url}-1`})
            if (blobCount % 2 === 0) {
                // for every other video, mark it as uploaded instead of downloaded
                vcr.uploadeds = [true]
            } else {
                vcr.downloadeds = [true]
            }
            vcr.size = 8*1024*1024

            try {
                await vcr.saveToDB()
            } catch (error) {
                log(`vcr.saveToDB failed`, error)
                debugger
                return
            }

            // store it in cache
            let videoBlob = new VideoBlob(`${url}-1`, blob)

            try {
                await videoBlob.saveToDB()
            } catch (error) {
                log(`fetchBlob videoBlob.saveToDB failed`, error)
                debugger
                return
            }
        }
    }

}