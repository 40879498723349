// Utilities to convert between the versification found in the BHS/GNT text and other major
// versifications, such as the 'English' versification found in most western protestant
// texts.

// The following data is from the default .vrs files found in Paratext. We only care
// about the part of those files that lays out the differences between the versification
// and the original. That is why these tables don't include anything else in them.

// What this format means:
// Verse(s) in English (or Septuagint, or RSO, etc.) text = verse(s) in original (BHS Hebrew or GNT Greek) text

let engTable = `# Versification  "English"
# Version=2.0
#
# modifications by Reinier de Blois 13/March/2012
# modified mappings Psalms by adding verse #0 to the mappings
# many of the subscripts that are part of verses 1,2 in the original text end up as verse #0 in English translations

# modifications by Studge 26/June/2009
# book definitions are for all books printed in any English of Spanish Bible
# this includes books for Protestant, Catholic and Protestant-Catholic-EasternOrthodox Interconfessional editions
#
# This is the versification used by most English (e.g. RSV) and Spanish Bibles (e.g. RVR)
#
# List of books, chapters, verses
# One line per book.
# One entry for each chapter.
# Verse number is the maximum verse number for that chapter.
# See the lines containing ='s below for verse mappings.
#
#--------------------------------------------------
# Old Testament
GEN 1:31 2:25 3:24 4:26 5:32 6:22 7:24 8:22 9:29 10:32 11:32 12:20 13:18 14:24 15:21 16:16 17:27 18:33 19:38 20:18 21:34 22:24 23:20 24:67 25:34 26:35 27:46 28:22 29:35 30:43 31:55 32:32 33:20 34:31 35:29 36:43 37:36 38:30 39:23 40:23 41:57 42:38 43:34 44:34 45:28 46:34 47:31 48:22 49:33 50:26
EXO 1:22 2:25 3:22 4:31 5:23 6:30 7:25 8:32 9:35 10:29 11:10 12:51 13:22 14:31 15:27 16:36 17:16 18:27 19:25 20:26 21:36 22:31 23:33 24:18 25:40 26:37 27:21 28:43 29:46 30:38 31:18 32:35 33:23 34:35 35:35 36:38 37:29 38:31 39:43 40:38
LEV 1:17 2:16 3:17 4:35 5:19 6:30 7:38 8:36 9:24 10:20 11:47 12:8 13:59 14:57 15:33 16:34 17:16 18:30 19:37 20:27 21:24 22:33 23:44 24:23 25:55 26:46 27:34
NUM 1:54 2:34 3:51 4:49 5:31 6:27 7:89 8:26 9:23 10:36 11:35 12:16 13:33 14:45 15:41 16:50 17:13 18:32 19:22 20:29 21:35 22:41 23:30 24:25 25:18 26:65 27:23 28:31 29:40 30:16 31:54 32:42 33:56 34:29 35:34 36:13
DEU 1:46 2:37 3:29 4:49 5:33 6:25 7:26 8:20 9:29 10:22 11:32 12:32 13:18 14:29 15:23 16:22 17:20 18:22 19:21 20:20 21:23 22:30 23:25 24:22 25:19 26:19 27:26 28:68 29:29 30:20 31:30 32:52 33:29 34:12
JOS 1:18 2:24 3:17 4:24 5:15 6:27 7:26 8:35 9:27 10:43 11:23 12:24 13:33 14:15 15:63 16:10 17:18 18:28 19:51 20:9 21:45 22:34 23:16 24:33
JDG 1:36 2:23 3:31 4:24 5:31 6:40 7:25 8:35 9:57 10:18 11:40 12:15 13:25 14:20 15:20 16:31 17:13 18:31 19:30 20:48 21:25
RUT 1:22 2:23 3:18 4:22
1SA 1:28 2:36 3:21 4:22 5:12 6:21 7:17 8:22 9:27 10:27 11:15 12:25 13:23 14:52 15:35 16:23 17:58 18:30 19:24 20:42 21:15 22:23 23:29 24:22 25:44 26:25 27:12 28:25 29:11 30:31 31:13
2SA 1:27 2:32 3:39 4:12 5:25 6:23 7:29 8:18 9:13 10:19 11:27 12:31 13:39 14:33 15:37 16:23 17:29 18:33 19:43 20:26 21:22 22:51 23:39 24:25
1KI 1:53 2:46 3:28 4:34 5:18 6:38 7:51 8:66 9:28 10:29 11:43 12:33 13:34 14:31 15:34 16:34 17:24 18:46 19:21 20:43 21:29 22:53
2KI 1:18 2:25 3:27 4:44 5:27 6:33 7:20 8:29 9:37 10:36 11:21 12:21 13:25 14:29 15:38 16:20 17:41 18:37 19:37 20:21 21:26 22:20 23:37 24:20 25:30
1CH 1:54 2:55 3:24 4:43 5:26 6:81 7:40 8:40 9:44 10:14 11:47 12:40 13:14 14:17 15:29 16:43 17:27 18:17 19:19 20:8 21:30 22:19 23:32 24:31 25:31 26:32 27:34 28:21 29:30
2CH 1:17 2:18 3:17 4:22 5:14 6:42 7:22 8:18 9:31 10:19 11:23 12:16 13:22 14:15 15:19 16:14 17:19 18:34 19:11 20:37 21:20 22:12 23:21 24:27 25:28 26:23 27:9 28:27 29:36 30:27 31:21 32:33 33:25 34:33 35:27 36:23
EZR 1:11 2:70 3:13 4:24 5:17 6:22 7:28 8:36 9:15 10:44
NEH 1:11 2:20 3:32 4:23 5:19 6:19 7:73 8:18 9:38 10:39 11:36 12:47 13:31
EST 1:22 2:23 3:15 4:17 5:14 6:14 7:10 8:17 9:32 10:3
JOB 1:22 2:13 3:26 4:21 5:27 6:30 7:21 8:22 9:35 10:22 11:20 12:25 13:28 14:22 15:35 16:22 17:16 18:21 19:29 20:29 21:34 22:30 23:17 24:25 25:6 26:14 27:23 28:28 29:25 30:31 31:40 32:22 33:33 34:37 35:16 36:33 37:24 38:41 39:30 40:24 41:34 42:17
PSA 1:6 2:12 3:8 4:8 5:12 6:10 7:17 8:9 9:20 10:18 11:7 12:8 13:6 14:7 15:5 16:11 17:15 18:50 19:14 20:9 21:13 22:31 23:6 24:10 25:22 26:12 27:14 28:9 29:11 30:12 31:24 32:11 33:22 34:22 35:28 36:12 37:40 38:22 39:13 40:17 41:13 42:11 43:5 44:26 45:17 46:11 47:9 48:14 49:20 50:23 51:19 52:9 53:6 54:7 55:23 56:13 57:11 58:11 59:17 60:12 61:8 62:12 63:11 64:10 65:13 66:20 67:7 68:35 69:36 70:5 71:24 72:20 73:28 74:23 75:10 76:12 77:20 78:72 79:13 80:19 81:16 82:8 83:18 84:12 85:13 86:17 87:7 88:18 89:52 90:17 91:16 92:15 93:5 94:23 95:11 96:13 97:12 98:9 99:9 100:5 101:8 102:28 103:22 104:35 105:45 106:48 107:43 108:13 109:31 110:7 111:10 112:10 113:9 114:8 115:18 116:19 117:2 118:29 119:176 120:7 121:8 122:9 123:4 124:8 125:5 126:6 127:5 128:6 129:8 130:8 131:3 132:18 133:3 134:3 135:21 136:26 137:9 138:8 139:24 140:13 141:10 142:7 143:12 144:15 145:21 146:10 147:20 148:14 149:9 150:6
PRO 1:33 2:22 3:35 4:27 5:23 6:35 7:27 8:36 9:18 10:32 11:31 12:28 13:25 14:35 15:33 16:33 17:28 18:24 19:29 20:30 21:31 22:29 23:35 24:34 25:28 26:28 27:27 28:28 29:27 30:33 31:31
ECC 1:18 2:26 3:22 4:16 5:20 6:12 7:29 8:17 9:18 10:20 11:10 12:14
SNG 1:17 2:17 3:11 4:16 5:16 6:13 7:13 8:14
ISA 1:31 2:22 3:26 4:6 5:30 6:13 7:25 8:22 9:21 10:34 11:16 12:6 13:22 14:32 15:9 16:14 17:14 18:7 19:25 20:6 21:17 22:25 23:18 24:23 25:12 26:21 27:13 28:29 29:24 30:33 31:9 32:20 33:24 34:17 35:10 36:22 37:38 38:22 39:8 40:31 41:29 42:25 43:28 44:28 45:25 46:13 47:15 48:22 49:26 50:11 51:23 52:15 53:12 54:17 55:13 56:12 57:21 58:14 59:21 60:22 61:11 62:12 63:19 64:12 65:25 66:24
JER 1:19 2:37 3:25 4:31 5:31 6:30 7:34 8:22 9:26 10:25 11:23 12:17 13:27 14:22 15:21 16:21 17:27 18:23 19:15 20:18 21:14 22:30 23:40 24:10 25:38 26:24 27:22 28:17 29:32 30:24 31:40 32:44 33:26 34:22 35:19 36:32 37:21 38:28 39:18 40:16 41:18 42:22 43:13 44:30 45:5 46:28 47:7 48:47 49:39 50:46 51:64 52:34
LAM 1:22 2:22 3:66 4:22 5:22
EZK 1:28 2:10 3:27 4:17 5:17 6:14 7:27 8:18 9:11 10:22 11:25 12:28 13:23 14:23 15:8 16:63 17:24 18:32 19:14 20:49 21:32 22:31 23:49 24:27 25:17 26:21 27:36 28:26 29:21 30:26 31:18 32:32 33:33 34:31 35:15 36:38 37:28 38:23 39:29 40:49 41:26 42:20 43:27 44:31 45:25 46:24 47:23 48:35
DAN 1:21 2:49 3:30 4:37 5:31 6:28 7:28 8:27 9:27 10:21 11:45 12:13
HOS 1:11 2:23 3:5 4:19 5:15 6:11 7:16 8:14 9:17 10:15 11:12 12:14 13:16 14:9
JOL 1:20 2:32 3:21
AMO 1:15 2:16 3:15 4:13 5:27 6:14 7:17 8:14 9:15
OBA 1:21
JON 1:17 2:10 3:10 4:11
MIC 1:16 2:13 3:12 4:13 5:15 6:16 7:20
NAM 1:15 2:13 3:19
HAB 1:17 2:20 3:19
ZEP 1:18 2:15 3:20
HAG 1:15 2:23
ZEC 1:21 2:13 3:10 4:14 5:11 6:15 7:14 8:23 9:17 10:12 11:17 12:14 13:9 14:21
MAL 1:14 2:17 3:18 4:6
#-----------------------------------------------
# New Testament
MAT 1:25 2:23 3:17 4:25 5:48 6:34 7:29 8:34 9:38 10:42 11:30 12:50 13:58 14:36 15:39 16:28 17:27 18:35 19:30 20:34 21:46 22:46 23:39 24:51 25:46 26:75 27:66 28:20
MRK 1:45 2:28 3:35 4:41 5:43 6:56 7:37 8:38 9:50 10:52 11:33 12:44 13:37 14:72 15:47 16:20
LUK 1:80 2:52 3:38 4:44 5:39 6:49 7:50 8:56 9:62 10:42 11:54 12:59 13:35 14:35 15:32 16:31 17:37 18:43 19:48 20:47 21:38 22:71 23:56 24:53
JHN 1:51 2:25 3:36 4:54 5:47 6:71 7:53 8:59 9:41 10:42 11:57 12:50 13:38 14:31 15:27 16:33 17:26 18:40 19:42 20:31 21:25
ACT 1:26 2:47 3:26 4:37 5:42 6:15 7:60 8:40 9:43 10:48 11:30 12:25 13:52 14:28 15:41 16:40 17:34 18:28 19:41 20:38 21:40 22:30 23:35 24:27 25:27 26:32 27:44 28:31
ROM 1:32 2:29 3:31 4:25 5:21 6:23 7:25 8:39 9:33 10:21 11:36 12:21 13:14 14:23 15:33 16:27
1CO 1:31 2:16 3:23 4:21 5:13 6:20 7:40 8:13 9:27 10:33 11:34 12:31 13:13 14:40 15:58 16:24
#2CO 1:24 2:17 3:18 4:18 5:21 6:18 7:16 8:24 9:15 10:18 11:33 12:21 13:14
# Note sometimes 2CO 13 has 14 verses e.g. KJV. but 13 verses in modern translations
2CO 1:24 2:17 3:18 4:18 5:21 6:18 7:16 8:24 9:15 10:18 11:33 12:21 13:14
GAL 1:24 2:21 3:29 4:31 5:26 6:18
EPH 1:23 2:22 3:21 4:32 5:33 6:24
PHP 1:30 2:30 3:21 4:23
COL 1:29 2:23 3:25 4:18
1TH 1:10 2:20 3:13 4:18 5:28
2TH 1:12 2:17 3:18
1TI 1:20 2:15 3:16 4:16 5:25 6:21
2TI 1:18 2:26 3:17 4:22
TIT 1:16 2:15 3:15
PHM 1:25
HEB 1:14 2:18 3:19 4:16 5:14 6:20 7:28 8:13 9:28 10:39 11:40 12:29 13:25
JAS 1:27 2:26 3:18 4:17 5:20
1PE 1:25 2:25 3:22 4:19 5:14
2PE 1:21 2:22 3:18
1JN 1:10 2:29 3:24 4:21 5:21
2JN 1:13
3JN 1:15
JUD 1:25
REV 1:20 2:29 3:22 4:11 5:14 6:17 7:17 8:13 9:21 10:11 11:19 12:18 13:18 14:20 15:8 16:21 17:18 18:24 19:21 20:15 21:27 22:21
# sometimes called the Apocalypse
#---------------------------------------------------------
# Deuterocanonical books
TOB 1:22 2:14 3:17 4:21 5:21 6:17 7:18 8:21 9:6 10:13 11:19 12:22 13:18 14:15
JDT 1:16 2:28 3:10 4:15 5:24 6:21 7:32 8:36 9:14 10:23 11:23 12:20 13:20 14:19 15:13 16:25
#-----------------------
# This is the definition for "Additions to Daniel" as in the KJV [Studge]
# ESG 1:13 2:12 3:6 4:18 5:19 6:16 7:24
# more commonly UBS Bibles have ESG which is the full Esther Greek for modern Bibles e.g. RSV, CEV, GNB etc
ESG 1:39 2:23 3:22 4:47 5:28 6:14 7:10 8:39 9:32 10:13
#-----------------------
WIS 1:16 2:24 3:19 4:20 5:23 6:25 7:30 8:21 9:18 10:21 11:26 12:27 13:19 14:31 15:19 16:29 17:21 18:25 19:22
SIR 1:30 2:18 3:31 4:31 5:15 6:37 7:36 8:19 9:18 10:31 11:34 12:18 13:26 14:27 15:20 16:30 17:32 18:33 19:30 20:32 21:28 22:27 23:27 24:34 25:26 26:29 27:30 28:26 29:28 30:25 31:31 32:24 33:31 34:26 35:20 36:26 37:31 38:34 39:35 40:30 41:23 42:25 43:33 44:23 45:26 46:20 47:25 48:25 49:16 50:29 51:30
#
# In English Bibles Baruch sometimes has 5 chapters and sometimes 6 in Catholic Bibles [Studge]
BAR 1:21 2:35 3:37 4:37 5:9 6:73
#
LJE 1:73
S3Y 1:68
SUS 1:64
BEL 1:42
1MA 1:64 2:70 3:60 4:61 5:68 6:63 7:50 8:32 9:73 10:89 11:74 12:53 13:53 14:49 15:41 16:24
2MA 1:36 2:32 3:40 4:50 5:27 6:31 7:42 8:36 9:29 10:38 11:38 12:45 13:26 14:46 15:39
#-----------------------------------------------------
# Additional Orthodox Books in Interconfessional Bibles e.g. RSV, NRSV
3MA 1:29 2:33 3:30 4:21 5:51 6:41 7:23
4MA 1:35 2:24 3:21 4:26 5:38 6:35 7:23 8:29 9:32 10:21 11:27 12:19 13:27 14:20 15:32 16:25 17:24 18:24
1ES 1:58 2:30 3:24 4:63 5:73 6:34 7:15 8:96 9:55
2ES 1:40 2:48 3:36 4:52 5:56 6:59 7:140 8:63 9:47 10:59 11:46 12:51 13:58 14:48 15:63 16:78
MAN 1:15
PS2 1:7
#-----------------------------------------------------
# ODA and PSS are only used in LXX and SYR projects and are not needed here
# ODA 1:19 2:43 3:10 4:19 5:12 6:8 7:20 8:37 9:22 10:9 11:11 12:15 13:4 14:46
# PSS 1:8 2:37 3:12 4:25 5:19 6:6 7:10 8:34 9:11 10:8 11:9 12:6 13:12 14:10 15:13 16:15 17:46 18:12
#-----------------------------------------------------
# Obselete books used for LXX variant texts, in LXX only in PT 6, and obselete in PT 7, not used in English, Spanish or any other Bibles.  If these codes were used they were used for the wrong books and these definitions were not relevant
JSA 1:18 2:24 3:17 4:24 5:15 6:27 7:26 8:35 9:27 10:43 11:23 12:24 13:33 14:15 15:63 16:10 17:18 18:28 19:51 20:9 21:45 22:34 23:16 24:33
JDB 1:36 2:23 3:31 4:24 5:31 6:40 7:25 8:35 9:57 10:18 11:40 12:15 13:25 14:20 15:20 16:31 17:13 18:31 19:30 20:48 21:25
TBS 1:22 2:14 3:17 4:21 5:23 6:19 7:17 8:21 9:6 10:14 11:19 12:22 13:18 14:15
SST 1:64
DNT 1:21 2:49 3:97 4:37 5:30 6:29 7:28 8:27 9:27 10:21 11:45 12:13
BLT 1:42
#------------------------------------------------------
# Daniel Greek used in some English Catholic Bibles
DAG 1:21 2:49 3:97 4:37 5:31 6:28 7:28 8:27 9:27 10:21 11:45 12:13 13:64 14:42
# Letter to the Laodiceans which was in the John Wycliffe Bible
LAO 1:20
#
#-----------------------------------------------------
# Mapping
#-----------------------------------------------------
# English = BHS (see org.vrs)
#
# (Note: ranges must not span a chapter, e.g. 4:10-5:11 is illegal)
#
GEN 31:55 = GEN 32:1
GEN 32:1-32 = GEN 32:2-33
EXO 8:1-4 = EXO 7:26-29
EXO 8:5-32 = EXO 8:1-28
EXO 22:1 = EXO 21:37
EXO 22:2-31 = EXO 22:1-30
LEV 6:1-7 = LEV 5:20-26
LEV 6:8-30 = LEV 6:1-23
NUM 16:36-50 = NUM 17:1-15
NUM 17:1-13 = NUM 17:16-28
# NUM 26:1a = NUM 25:19b  # no support for splits yet
# NUM 26:1b = NUM 26:1  # no support for splits yet
NUM 29:40 = NUM 30:1
NUM 30:1-16 = NUM 30:2-17
DEU 12:32 = DEU 13:1
DEU 13:1-18 = DEU 13:2-19
DEU 22:30 = DEU 23:1
DEU 23:1-25 = DEU 23:2-26
DEU 29:1 = DEU 28:69
DEU 29:2-29 = DEU 29:1-28
#
# removed see PTSIL-113
#1SA 20:42 = 1SA 20:41
#
1SA 20:42 = 1SA 21:1
1SA 21:1-15 = 1SA 21:2-16
1SA 23:29 = 1SA 24:1
1SA 24:1-22 = 1SA 24:2-23
2SA 18:33 = 2SA 19:1
2SA 19:1-43 = 2SA 19:2-44
1KI 4:21-34 = 1KI 5:1-14
1KI 5:1-18 = 1KI 5:15-32
# 1KI 18:33a = 1KI 18:33  # no support for splits yet
# 1KI 18:33b = 1KI 18:34  # no support for splits yet
# 1KI 22:43a = 1KI 22:43b  # no support for splits yet
1KI 22:43-53 = 1KI 22:44-54
2KI 11:21 = 2KI 12:1
2KI 12:1-21 = 2KI 12:2-22
1CH 6:1-15 = 1CH 5:27-41
1CH 6:16-81 = 1CH 6:1-66
# 1CH 12:4b = 1CH 12:4  # no support for splits yet
1CH 12:4-40 = 1CH 12:5-41
2CH 2:1 = 2CH 1:18
2CH 2:2-18 = 2CH 2:1-17
2CH 14:1 = 2CH 13:23
2CH 14:2-15 = 2CH 14:1-14
NEH 4:1-6 = NEH 3:33-38
NEH 4:7-23 = NEH 4:1-17
NEH 7:69-73 = NEH 7:68-72
NEH 9:38 = NEH 10:1
NEH 10:1-39 = NEH 10:2-40
JOB 41:1-8 = JOB 40:25-32
JOB 41:9-34 = JOB 41:1-26
PSA 3:0-8 = PSA 3:1-9
PSA 4:0-8 = PSA 4:1-9
PSA 5:0-12 = PSA 5:1-13
PSA 6:0-10 = PSA 6:1-11
PSA 7:0-17 = PSA 7:1-18
PSA 8:0-9 = PSA 8:1-10
PSA 9:0-20 = PSA 9:1-21
PSA 12:0-8 = PSA 12:1-9
PSA 13:0-5 = PSA 13:1-6
PSA 18:0-50 = PSA 18:1-51
PSA 19:0-14 = PSA 19:1-15
PSA 20:0-9 = PSA 20:1-10
PSA 21:0-13 = PSA 21:1-14
PSA 22:0-31 = PSA 22:1-32
PSA 30:0-12 = PSA 30:1-13
PSA 31:0-24 = PSA 31:1-25
PSA 34:0-22 = PSA 34:1-23
PSA 36:0-12 = PSA 36:1-13
PSA 38:0-22 = PSA 38:1-23
PSA 39:0-13 = PSA 39:1-14
PSA 40:0-17 = PSA 40:1-18
PSA 41:0-13 = PSA 41:1-14
PSA 42:0-11 = PSA 42:1-12
PSA 44:0-26 = PSA 44:1-27
PSA 45:0-17 = PSA 45:1-18
PSA 46:0-11 = PSA 46:1-12
PSA 47:0-9 = PSA 47:1-10
PSA 48:0-14 = PSA 48:1-15
PSA 49:0-20 = PSA 49:1-21
PSA 51:0 = PSA 51:1
PSA 51:0 = PSA 51:2
PSA 51:1-19 = PSA 51:3-21
PSA 52:0 = PSA 52:1
PSA 52:0 = PSA 52:2
PSA 52:1-9 = PSA 52:3-11
PSA 53:0-6 = PSA 53:1-7
PSA 54:0 = PSA 54:1
PSA 54:0 = PSA 54:2
PSA 54:1-7 = PSA 54:3-9
PSA 55:0-23 = PSA 55:1-24
PSA 56:0-13 = PSA 56:1-14
PSA 57:0-11 = PSA 57:1-12
PSA 58:0-11 = PSA 58:1-12
PSA 59:0-17 = PSA 59:1-18
PSA 60:0 = PSA 60:1
PSA 60:0 = PSA 60:2
PSA 60:1-12 = PSA 60:3-14
PSA 61:0-8 = PSA 61:1-9
PSA 62:0-12 = PSA 62:1-13
PSA 63:0-11 = PSA 63:1-12
PSA 64:0-10 = PSA 64:1-11
PSA 65:0-13 = PSA 65:1-14
PSA 67:0-7 = PSA 67:1-8
PSA 68:0-35 = PSA 68:1-36
PSA 69:0-36 = PSA 69:1-37
PSA 70:0-5 = PSA 70:1-6
PSA 75:0-10 = PSA 75:1-11
PSA 76:0-12 = PSA 76:1-13
PSA 77:0-20 = PSA 77:1-21
PSA 80:0-19 = PSA 80:1-20
PSA 81:0-16 = PSA 81:1-17
PSA 83:0-18 = PSA 83:1-19
PSA 84:0-12 = PSA 84:1-13
PSA 85:0-13 = PSA 85:1-14
PSA 88:0-18 = PSA 88:1-19
PSA 89:0-52 = PSA 89:1-53
PSA 92:0-15 = PSA 92:1-16
PSA 102:0-28 = PSA 102:1-29
PSA 108:0-13 = PSA 108:1-14
PSA 140:0-13 = PSA 140:1-14
PSA 142:0-7 = PSA 142:1-8
ECC 5:1 = ECC 4:17
ECC 5:2-20 = ECC 5:1-19
SNG 6:13 = SNG 7:1
SNG 7:1-13 = SNG 7:2-14
ISA 9:1 = ISA 8:23
ISA 9:2-21 = ISA 9:1-20
ISA 64:2-12 = ISA 64:1-11
JER 9:1 = JER 8:23
JER 9:2-26 = JER 9:1-25
EZK 20:45-46 = EZK 21:1-2
EZK 20:47 = EZK 21:3
EZK 20:48-49 = EZK 21:4-5
EZK 21:1-32 = EZK 21:6-37
DAN 4:1-3 = DAN 3:31-33
DAN 4:4-37 = DAN 4:1-34
DAN 5:31 = DAN 6:1
DAN 6:1-28 = DAN 6:2-29
HOS 1:10-11 = HOS 2:1-2
HOS 2:1-23 = HOS 2:3-25
HOS 11:12 = HOS 12:1
HOS 12:1-14 = HOS 12:2-15
HOS 13:16 = HOS 14:1
HOS 14:1-9 = HOS 14:2-10
JOL 2:28-32 = JOL 3:1-5
JOL 3:1-21 = JOL 4:1-21
JON 1:17 = JON 2:1
JON 2:1-10 = JON 2:2-11
MIC 5:1 = MIC 4:14
MIC 5:2-15 = MIC 5:1-14
NAM 1:15 = NAM 2:1
NAM 2:1-13 = NAM 2:2-14
ZEC 1:18-21 = ZEC 2:1-4
ZEC 2:1-13 = ZEC 2:5-17
MAL 4:1-6 = MAL 3:19-24
# 40 + 41 -> 40 (per Peter Kirk)
#! &ACT 19:40-41 = ACT 19:40
#
#---------------------------------
# map Baruch 6 onto Letter of Jeremiah [Studge]
BAR 6:1-73 = LJE 1:1-73
#
#------------------------------
# Susanna
DAG 13:1-63 = SUS 1:1-63
# Bel and the Dragon
DAG 14:1-42 = BEL 1:1-42
#----------------------------------
# Mapping Esther Greek onto LXX Esther Greek
#
# This maps the standard verses generated by Create Book
# to the actual verse numbers and segments found in the LXX
# ESG chapter 1
ESG 1:1 = ESG 1:1a
ESG 1:2 = ESG 1:1b
ESG 1:3 = ESG 1:1c
ESG 1:4 = ESG 1:1d
ESG 1:5 = ESG 1:1e
ESG 1:6 = ESG 1:1f
ESG 1:7 = ESG 1:1g
ESG 1:8 = ESG 1:1h
ESG 1:9 = ESG 1:1i
ESG 1:10 = ESG 1:1k
ESG 1:11 = ESG 1:1l
ESG 1:12 = ESG 1:1m
ESG 1:13 = ESG 1:1n
ESG 1:14 = ESG 1:1o
ESG 1:15 = ESG 1:1p
ESG 1:16 = ESG 1:1q
ESG 1:17 = ESG 1:1r
ESG 1:18 = ESG 1:1s
ESG 1:19-39 = ESG 1:2-22
# ESG chapter 3
ESG 3:14 = ESG 3:13a
ESG 3:15 = ESG 3:13b
ESG 3:16 = ESG 3:13c
ESG 3:17 = ESG 3:13d
ESG 3:18 = ESG 3:13e
ESG 3:19 = ESG 3:13f
ESG 3:20 = ESG 3:13g
ESG 3:21 = ESG 3:14
ESG 3:22 = ESG 3:15
# ESG chapter 4
ESG 4:18 = ESG 4:17a
ESG 4:19 = ESG 4:17b
ESG 4:20 = ESG 4:17c
ESG 4:21 = ESG 4:17c
ESG 4:22 = ESG 4:17d
ESG 4:23 = ESG 4:17d
ESG 4:24 = ESG 4:17e
ESG 4:25 = ESG 4:17f
ESG 4:26 = ESG 4:17g
ESG 4:27 = ESG 4:17h
ESG 4:28 = ESG 4:17i
ESG 4:29 = ESG 4:17k
ESG 4:30 = ESG 4:17k
ESG 4:31 = ESG 4:17k
ESG 4:32 = ESG 4:17l
ESG 4:33 = ESG 4:17m
ESG 4:34 = ESG 4:17n
ESG 4:35 = ESG 4:17n
ESG 4:36 = ESG 4:17o
ESG 4:37 = ESG 4:17o
ESG 4:38 = ESG 4:17p
ESG 4:39 = ESG 4:17q
ESG 4:40 = ESG 4:17r
ESG 4:41 = ESG 4:17s
ESG 4:42 = ESG 4:17t
ESG 4:43 = ESG 4:17u
ESG 4:44 = ESG 4:17w
ESG 4:45 = ESG 4:17x
ESG 4:46 = ESG 4:17y
ESG 4:47 = ESG 4:17z
# ESG chapter 5
ESG 5:2 = ESG 5:1a
ESG 5:3 = ESG 5:1a
ESG 5:4 = ESG 5:1a
ESG 5:5 = ESG 5:1b
ESG 5:6 = ESG 5:1c
ESG 5:7 = ESG 5:1d
ESG 5:8 = ESG 5:1e
ESG 5:9 = ESG 5:1f
ESG 5:10 = ESG 5:1f
ESG 5:11 = ESG 5:2
ESG 5:12 = ESG 5:2
ESG 5:13 = ESG 5:2a
ESG 5:14 = ESG 5:2a
ESG 5:15 = ESG 5:2b
ESG 5:16 = ESG 5:2b
ESG 5:17-28 = ESG 5:3-14
# ESG chapter 8
ESG 8:13 = ESG 8:12a
ESG 8:14 = ESG 8:12b
ESG 8:15 = ESG 8:12c
ESG 8:16 = ESG 8:12d
ESG 8:17 = ESG 8:12e
ESG 8:18 = ESG 8:12f
ESG 8:19 = ESG 8:12g
ESG 8:20 = ESG 8:12h
ESG 8:21 = ESG 8:12i
ESG 8:22 = ESG 8:12k
ESG 8:23 = ESG 8:12l
ESG 8:24 = ESG 8:12m
ESG 8:25 = ESG 8:12n
ESG 8:26 = ESG 8:12o
ESG 8:27 = ESG 8:12p
ESG 8:28 = ESG 8:12q
ESG 8:29 = ESG 8:12r
ESG 8:30 = ESG 8:12s
ESG 8:31 = ESG 8:12t
ESG 8:32 = ESG 8:12u
ESG 8:33 = ESG 8:12x
ESG 8:34 = ESG 8:12y
ESG 8:35 = ESG 8:12y
ESG 8:36 = ESG 8:12y
ESG 8:37-41 = ESG 8:13-17
# ESG chapter 10
ESG 10:4 = ESG 10:3a
ESG 10:5 = ESG 10:3b
ESG 10:6 = ESG 10:3c
ESG 10:7 = ESG 10:3d
ESG 10:8 = ESG 10:3e
ESG 10:9 = ESG 10:3f
ESG 10:10 = ESG 10:3g
ESG 10:11 = ESG 10:3h
ESG 10:12 = ESG 10:3i
ESG 10:13 = ESG 10:3k
ESG 10:14 = ESG 10:3l
# S3Y is a small section of the DAG LXX pulled out and translated as a separate book.
# Map it back to the LXX.  This section allow's texts such as TOB (French) which do
# this to scroll correctly with the LXX.
# S3Y is not present in the GRK, HEB, or LXX(Ralphs) text.
# If a text has DAG present, this section must NOT be included in its versification file because
# that would cause references in other texts to DAG to be redirected to a non-existant S3Y.
S3Y 1:1-29 = DAG 3:24-52
S3Y 1:30-31 = DAG 3:52-53
S3Y 1:33 = DAG 3:54
S3Y 1:32 = DAG 3:55
S3Y 1:34-35 = DAG 3:56-57
S3Y 1:37 = DAG 3:58
S3Y 1:36 = DAG 3:59
S3Y 1:38-68 = DAG 3:60-90`

let orgTable = `# Versification  "Original" 
# Version=1.200
# (not a very good name but I have not heard a better suggestion)
#
# modifications by Studge 26/June/2009
#
# BHS versification for OT, UBS GNT versification for NT
# following the Masoretic order
# OT translations using Masorteic verse structures should map onto this text
# NT versifications maps onto Nestle-Aland
#
# List of books, chapters, verses
# One line per book.
# One entry for each chapter.
# Verse number is the maximum verse number for that chapter.
# See the lines containing ='s below for verse mappings.
#
#---------------------------------------------------------------
# Old Testament
GEN 1:31 2:25 3:24 4:26 5:32 6:22 7:24 8:22 9:29 10:32 11:32 12:20 13:18 14:24 15:21 16:16 17:27 18:33 19:38 20:18 21:34 22:24 23:20 24:67 25:34 26:35 27:46 28:22 29:35 30:43 31:54 32:33 33:20 34:31 35:29 36:43 37:36 38:30 39:23 40:23 41:57 42:38 43:34 44:34 45:28 46:34 47:31 48:22 49:33 50:26
EXO 1:22 2:25 3:22 4:31 5:23 6:30 7:29 8:28 9:35 10:29 11:10 12:51 13:22 14:31 15:27 16:36 17:16 18:27 19:25 20:26 21:37 22:30 23:33 24:18 25:40 26:37 27:21 28:43 29:46 30:38 31:18 32:35 33:23 34:35 35:35 36:38 37:29 38:31 39:43 40:38
LEV 1:17 2:16 3:17 4:35 5:26 6:23 7:38 8:36 9:24 10:20 11:47 12:8 13:59 14:57 15:33 16:34 17:16 18:30 19:37 20:27 21:24 22:33 23:44 24:23 25:55 26:46 27:34
NUM 1:54 2:34 3:51 4:49 5:31 6:27 7:89 8:26 9:23 10:36 11:35 12:16 13:33 14:45 15:41 16:35 17:28 18:32 19:22 20:29 21:35 22:41 23:30 24:25 25:19 26:65 27:23 28:31 29:39 30:17 31:54 32:42 33:56 34:29 35:34 36:13
DEU 1:46 2:37 3:29 4:49 5:33 6:25 7:26 8:20 9:29 10:22 11:32 12:31 13:19 14:29 15:23 16:22 17:20 18:22 19:21 20:20 21:23 22:29 23:26 24:22 25:19 26:19 27:26 28:69 29:28 30:20 31:30 32:52 33:29 34:12
JOS 1:18 2:24 3:17 4:24 5:15 6:27 7:26 8:35 9:27 10:43 11:23 12:24 13:33 14:15 15:63 16:10 17:18 18:28 19:51 20:9 21:45 22:34 23:16 24:33
JDG 1:36 2:23 3:31 4:24 5:31 6:40 7:25 8:35 9:57 10:18 11:40 12:15 13:25 14:20 15:20 16:31 17:13 18:31 19:30 20:48 21:25
RUT 1:22 2:23 3:18 4:22
1SA 1:28 2:36 3:21 4:22 5:12 6:21 7:17 8:22 9:27 10:27 11:15 12:25 13:23 14:52 15:35 16:23 17:58 18:30 19:24 20:42 21:16 22:23 23:28 24:23 25:44 26:25 27:12 28:25 29:11 30:31 31:13
2SA 1:27 2:32 3:39 4:12 5:25 6:23 7:29 8:18 9:13 10:19 11:27 12:31 13:39 14:33 15:37 16:23 17:29 18:32 19:44 20:26 21:22 22:51 23:39 24:25
1KI 1:53 2:46 3:28 4:20 5:32 6:38 7:51 8:66 9:28 10:29 11:43 12:33 13:34 14:31 15:34 16:34 17:24 18:46 19:21 20:43 21:29 22:54
2KI 1:18 2:25 3:27 4:44 5:27 6:33 7:20 8:29 9:37 10:36 11:20 12:22 13:25 14:29 15:38 16:20 17:41 18:37 19:37 20:21 21:26 22:20 23:37 24:20 25:30
1CH 1:54 2:55 3:24 4:43 5:41 6:66 7:40 8:40 9:44 10:14 11:47 12:41 13:14 14:17 15:29 16:43 17:27 18:17 19:19 20:8 21:30 22:19 23:32 24:31 25:31 26:32 27:34 28:21 29:30
2CH 1:18 2:17 3:17 4:22 5:14 6:42 7:22 8:18 9:31 10:19 11:23 12:16 13:23 14:14 15:19 16:14 17:19 18:34 19:11 20:37 21:20 22:12 23:21 24:27 25:28 26:23 27:9 28:27 29:36 30:27 31:21 32:33 33:25 34:33 35:27 36:23
EZR 1:11 2:70 3:13 4:24 5:17 6:22 7:28 8:36 9:15 10:44
NEH 1:11 2:20 3:38 4:17 5:19 6:19 7:72 8:18 9:37 10:40 11:36 12:47 13:31
EST 1:22 2:23 3:15 4:17 5:14 6:14 7:10 8:17 9:32 10:3
JOB 1:22 2:13 3:26 4:21 5:27 6:30 7:21 8:22 9:35 10:22 11:20 12:25 13:28 14:22 15:35 16:22 17:16 18:21 19:29 20:29 21:34 22:30 23:17 24:25 25:6 26:14 27:23 28:28 29:25 30:31 31:40 32:22 33:33 34:37 35:16 36:33 37:24 38:41 39:30 40:32 41:26 42:17
PSA 1:6 2:12 3:9 4:9 5:13 6:11 7:18 8:10 9:21 10:18 11:7 12:9 13:6 14:7 15:5 16:11 17:15 18:51 19:15 20:10 21:14 22:32 23:6 24:10 25:22 26:12 27:14 28:9 29:11 30:13 31:25 32:11 33:22 34:23 35:28 36:13 37:40 38:23 39:14 40:18 41:14 42:12 43:5 44:27 45:18 46:12 47:10 48:15 49:21 50:23 51:21 52:11 53:7 54:9 55:24 56:14 57:12 58:12 59:18 60:14 61:9 62:13 63:12 64:11 65:14 66:20 67:8 68:36 69:37 70:6 71:24 72:20 73:28 74:23 75:11 76:13 77:21 78:72 79:13 80:20 81:17 82:8 83:19 84:13 85:14 86:17 87:7 88:19 89:53 90:17 91:16 92:16 93:5 94:23 95:11 96:13 97:12 98:9 99:9 100:5 101:8 102:29 103:22 104:35 105:45 106:48 107:43 108:14 109:31 110:7 111:10 112:10 113:9 114:8 115:18 116:19 117:2 118:29 119:176 120:7 121:8 122:9 123:4 124:8 125:5 126:6 127:5 128:6 129:8 130:8 131:3 132:18 133:3 134:3 135:21 136:26 137:9 138:8 139:24 140:14 141:10 142:8 143:12 144:15 145:21 146:10 147:20 148:14 149:9 150:6
PRO 1:33 2:22 3:35 4:27 5:23 6:35 7:27 8:36 9:18 10:32 11:31 12:28 13:25 14:35 15:33 16:33 17:28 18:24 19:29 20:30 21:31 22:29 23:35 24:34 25:28 26:28 27:27 28:28 29:27 30:33 31:31
ECC 1:18 2:26 3:22 4:17 5:19 6:12 7:29 8:17 9:18 10:20 11:10 12:14
SNG 1:17 2:17 3:11 4:16 5:16 6:12 7:14 8:14
ISA 1:31 2:22 3:26 4:6 5:30 6:13 7:25 8:23 9:20 10:34 11:16 12:6 13:22 14:32 15:9 16:14 17:14 18:7 19:25 20:6 21:17 22:25 23:18 24:23 25:12 26:21 27:13 28:29 29:24 30:33 31:9 32:20 33:24 34:17 35:10 36:22 37:38 38:22 39:8 40:31 41:29 42:25 43:28 44:28 45:25 46:13 47:15 48:22 49:26 50:11 51:23 52:15 53:12 54:17 55:13 56:12 57:21 58:14 59:21 60:22 61:11 62:12 63:19 64:11 65:25 66:24
JER 1:19 2:37 3:25 4:31 5:31 6:30 7:34 8:23 9:25 10:25 11:23 12:17 13:27 14:22 15:21 16:21 17:27 18:23 19:15 20:18 21:14 22:30 23:40 24:10 25:38 26:24 27:22 28:17 29:32 30:24 31:40 32:44 33:26 34:22 35:19 36:32 37:21 38:28 39:18 40:16 41:18 42:22 43:13 44:30 45:5 46:28 47:7 48:47 49:39 50:46 51:64 52:34
LAM 1:22 2:22 3:66 4:22 5:22
EZK 1:28 2:10 3:27 4:17 5:17 6:14 7:27 8:18 9:11 10:22 11:25 12:28 13:23 14:23 15:8 16:63 17:24 18:32 19:14 20:44 21:37 22:31 23:49 24:27 25:17 26:21 27:36 28:26 29:21 30:26 31:18 32:32 33:33 34:31 35:15 36:38 37:28 38:23 39:29 40:49 41:26 42:20 43:27 44:31 45:25 46:24 47:23 48:35
DAN 1:21 2:49 3:33 4:34 5:30 6:29 7:28 8:27 9:27 10:21 11:45 12:13
HOS 1:9 2:25 3:5 4:19 5:15 6:11 7:16 8:14 9:17 10:15 11:11 12:15 13:15 14:10
JOL 1:20 2:27 3:5 4:21
AMO 1:15 2:16 3:15 4:13 5:27 6:14 7:17 8:14 9:15
OBA 1:21
JON 1:16 2:11 3:10 4:11
MIC 1:16 2:13 3:12 4:14 5:14 6:16 7:20
NAM 1:14 2:14 3:19
HAB 1:17 2:20 3:19
ZEP 1:18 2:15 3:20
HAG 1:15 2:23
ZEC 1:17 2:17 3:10 4:14 5:11 6:15 7:14 8:23 9:17 10:12 11:17 12:14 13:9 14:21
MAL 1:14 2:17 3:24
#---------------------------------------------------------
# New Testament books
MAT 1:25 2:23 3:17 4:25 5:48 6:34 7:29 8:34 9:38 10:42 11:30 12:50 13:58 14:36 15:39 16:28 17:27 18:35 19:30 20:34 21:46 22:46 23:39 24:51 25:46 26:75 27:66 28:20
MRK 1:45 2:28 3:35 4:41 5:43 6:56 7:37 8:38 9:50 10:52 11:33 12:44 13:37 14:72 15:47 16:20
LUK 1:80 2:52 3:38 4:44 5:39 6:49 7:50 8:56 9:62 10:42 11:54 12:59 13:35 14:35 15:32 16:31 17:37 18:43 19:48 20:47 21:38 22:71 23:56 24:53
JHN 1:51 2:25 3:36 4:54 5:47 6:71 7:53 8:59 9:41 10:42 11:57 12:50 13:38 14:31 15:27 16:33 17:26 18:40 19:42 20:31 21:25
ACT 1:26 2:47 3:26 4:37 5:42 6:15 7:60 8:40 9:43 10:48 11:30 12:25 13:52 14:28 15:41 16:40 17:34 18:28 19:40 20:38 21:40 22:30 23:35 24:27 25:27 26:32 27:44 28:31
ROM 1:32 2:29 3:31 4:25 5:21 6:23 7:25 8:39 9:33 10:21 11:36 12:21 13:14 14:23 15:33 16:27
1CO 1:31 2:16 3:23 4:21 5:13 6:20 7:40 8:13 9:27 10:33 11:34 12:31 13:13 14:40 15:58 16:24
2CO 1:24 2:17 3:18 4:18 5:21 6:18 7:16 8:24 9:15 10:18 11:33 12:21 13:13
GAL 1:24 2:21 3:29 4:31 5:26 6:18
EPH 1:23 2:22 3:21 4:32 5:33 6:24
PHP 1:30 2:30 3:21 4:23
COL 1:29 2:23 3:25 4:18
1TH 1:10 2:20 3:13 4:18 5:28
2TH 1:12 2:17 3:18
1TI 1:20 2:15 3:16 4:16 5:25 6:21
2TI 1:18 2:26 3:17 4:22
TIT 1:16 2:15 3:15
PHM 1:25
HEB 1:14 2:18 3:19 4:16 5:14 6:20 7:28 8:13 9:28 10:39 11:40 12:29 13:25
JAS 1:27 2:26 3:18 4:17 5:20
1PE 1:25 2:25 3:22 4:19 5:14
2PE 1:21 2:22 3:18
1JN 1:10 2:29 3:24 4:21 5:21
2JN 1:13
3JN 1:15
JUD 1:25
REV 1:20 2:29 3:22 4:11 5:14 6:17 7:17 8:13 9:21 10:11 11:19 12:18 13:18 14:20 15:8 16:21 17:18 18:24 19:21 20:15 21:27 22:21
#------------------------------------------------
# Deuterocanonical books from the LXX which are in the Catholic tradition
TOB 1:22 2:14 3:17 4:21 5:23 6:19 7:17 8:21 9:6 10:14 11:19 12:22 13:18 14:15
JDT 1:16 2:28 3:10 4:15 5:24 6:21 7:32 8:36 9:14 10:23 11:23 12:20 13:20 14:19 15:14 16:25
ESG 1:39 2:23 3:22 4:47 5:28 6:14 7:10 8:39 9:32 10:13
WIS 1:16 2:24 3:19 4:20 5:23 6:25 7:30 8:21 9:18 10:21 11:26 12:27 13:19 14:31 15:19 16:29 17:20 18:25 19:22
SIR 1:30 2:18 3:31 4:31 5:15 6:37 7:36 8:19 9:18 10:31 11:34 12:18 13:26 14:27 15:20 16:30 17:32 18:33 19:30 20:31 21:28 22:27 23:27 24:34 25:26 26:29 27:30 28:26 29:28 30:25 31:31 32:24 33:33 34:26 35:24 36:27 37:31 38:34 39:35 40:30 41:27 42:25 43:33 44:23 45:26 46:20 47:25 48:25 49:16 50:29 51:30
BAR 1:22 2:35 3:38 4:37 5:9
LJE 1:72
S3Y 1:67
SUS 1:64
BEL 1:42
1MA 1:64 2:70 3:60 4:61 5:68 6:63 7:50 8:32 9:73 10:89 11:74 12:53 13:53 14:49 15:41 16:24
2MA 1:36 2:32 3:40 4:50 5:27 6:31 7:42 8:36 9:29 10:38 11:38 12:45 13:26 14:46 15:39
#---------------------------------------------------
# Additional Orthodox books from the LXX
3MA 1:29 2:33 3:30 4:21 5:51 6:41 7:23
4MA 1:35 2:24 3:21 4:26 5:38 6:35 7:23 8:29 9:32 10:21 11:27 12:19 13:27 14:20 15:32 16:25 17:24 18:24
1ES 1:55 2:26 3:24 4:63 5:71 6:33 7:15 8:92 9:55
# This was the wrong definition for 2ES
# 2ES 1:11 2:70 3:13 4:24 5:17 6:22 7:28 8:36 9:15 10:44 11:11 12:20 13:37 14:17 15:19 16:19 17:73 18:18 19:37 20:40 21:36 22:47 23:31
# This is the correct definition of 2ES, the "original" book was EZA
2ES 1:40 2:48 3:36 4:52 5:56 6:59 7:140 8:63 9:47 10:59 11:46 12:51 13:58 14:48 15:63 16:78
MAN 1:15
PS2 1:7
#-------------------------------------------------------
# ODA and PSS are only in the LXX and SYR projects. 
ODA 1:19 2:43 3:10 4:19 5:12 6:8 7:20 8:37 9:22 10:9 11:11 12:15 13:4 14:46
PSS 1:8 2:37 3:12 4:25 5:19 6:6 7:10 8:34 9:11 10:8 11:9 12:6 13:12 14:10 15:13 16:15 17:46 18:12
#-------------------------------------------------------
# the following codes are for obselete LXX variants only in LXX and not supported in PT 7
JSA 1:18 2:24 3:17 4:24 5:15 6:27 7:26 8:35 9:27 10:43 11:23 12:24 13:33 14:15 15:63 16:10 17:18 18:28 19:51 20:9 21:45 22:34 23:16 24:33
JDB 1:36 2:23 3:31 4:24 5:31 6:40 7:25 8:35 9:57 10:18 11:40 12:15 13:25 14:20 15:20 16:31 17:13 18:31 19:30 20:48 21:25
TBS 1:22 2:14 3:17 4:21 5:23 6:19 7:17 8:21 9:6 10:14 11:19 12:22 13:18 14:15
SST 1:64
DNT 1:21 2:49 3:97 4:37 5:30 6:29 7:28 8:27 9:27 10:21 11:45 12:13
BLT 1:42
#
# No mappings are present for this versification since it represents
# the "standard" versification to which all OT and NT texts are mapped in these files.
# (DC texts follow the LXX)
#
#-------------------------------------------------------
# Apocalypse of Ezra [Studge]
EZA 1:36 2:52 3:56 4:59 5:139 6:63 7:47 8:60 9:46 10:51 11:58 12:48
#--------------------------------------------------------
# Jubilees and Enoch have both been found in the Hebrew [Studge]
# might be needed if we add Dead Sea Scrolls
# Jubilees (Ethiopian canon)
JUB 1:26 2:17 3:19 4:24 5:36 6:34 7:37 8:40 9:27 10:48 11:39 12:56 13:34 14:55 15:20 16:28 17:30 18:31 19:59 20:66 21:30 22:38 23:59 24:30 25:50 26:48 27:37 28:38 29:27 30:29 31:31 32:24 33:30 34:20
# Enoch (Ethiopian canon)
ENO 1:28 2:42 3:30 4:88 5:40 6:42 7:39 8:46 9:42 10:16 11:19 12:40 13:34 14:35 15:45 16:41 17:69 18:42 19:29 20:53 21:57 22:14 23:26 24:16 25:30 26:37 27:21 28:34 29:28 30:23 31:29 32:82 33:59 34:49 35:36 36:30 37:34 38:36 39:24 40:40 41:22 42:16
#
# S3Y is a small section of the DAG LXX pulled out and translated as a separate book.
# Map it back to the LXX.  This section allow's texts such as TOB (French) which do
# this to scroll correctly with the LXX.
# S3Y is not present in the GRK, HEB, or LXX(Ralphs) text.
# If a text has DAG present, this section must NOT be included in its versification file because
# that would cause references in other texts to DAG to be redirected to a non-existant S3Y.
S3Y 1:1-29 = DAG 3:24-52
S3Y 1:30-31 = DAG 3:52-53
S3Y 1:33 = DAG 3:54
S3Y 1:32 = DAG 3:55
S3Y 1:34-35 = DAG 3:56-57
S3Y 1:37 = DAG 3:58
S3Y 1:36 = DAG 3:59
S3Y 1:38-68 = DAG 3:60-90`

const bookIds = [ 
    "GEN", "EXO", "LEV", "NUM", "DEU", "JOS", "JDG", "RUT", "1SA", "2SA", // 10
    "1KI", "2KI", "1CH", "2CH", "EZR", "NEH", "EST", "JOB", "PSA", "PRO", // 20
    "ECC", "SNG", "ISA", "JER", "LAM", "EZK", "DAN", "HOS", "JOL", "AMO", // 30
    "OBA", "JON", "MIC", "NAM", "HAB", "ZEP", "HAG", "ZEC", "MAL", "MAT", // 40
    "MRK", "LUK", "JHN", "ACT", "ROM", "1CO", "2CO", "GAL", "EPH", "PHP", // 50
    "COL", "1TH", "2TH", "1TI", "2TI", "TIT", "PHM", "HEB", "JAS", "1PE", // 60
    "2PE", "1JN", "2JN", "3JN", "JUD", "REV"]

enum LineType {
    Comment,
    ChapterVerse,
    Mapping,
    ExcludedVerse,
    OneToManyMapping,
    verseSegments,
}

const COMMENT_MARKER = '#'
const MAPPING_MARKER = '='
const CHAPTER_VERSE_SEPARATOR = ':'
const EXCLUDED_VERSE_MARKER = '-'
const ONE_TO_MANY_MARKER = '&'
const VERSE_SEGMENTS_MARKER = '*'

function processFile(table: string) {
    let versification = new Versification(new Map(), new Map(), new Map(), [])
    let rows = table.split(/\r?\n/)
    for (let row of rows) {
        try {
            processLine(row, versification)
        } catch (error) {
            // could not process line
        }
    }
    return versification
}

function processLine(line: string, versification: Versification) {
    let lineType = parseLine(line)

    switch (lineType) {
        case LineType.Comment: {
            break;
        }
        case LineType.ExcludedVerse: {
            break;
        }
        case LineType.OneToManyMapping: {
            break;
        }
        case LineType.verseSegments: {
            break;
        }
        case LineType.ChapterVerse: {
            processChapterVerse(line, versification)
            break;
        }
        case LineType.Mapping: {
            processMappingLine(line, versification)
            break;
        }
    }
}

function parseLine(line: string) {
    if (line.trim().length === 0 || line.startsWith(COMMENT_MARKER)) {
        return LineType.Comment
    }

    if (line.startsWith(EXCLUDED_VERSE_MARKER)) {
        return LineType.ExcludedVerse
    }

    if (line.includes(MAPPING_MARKER)) {
        if (line.includes(ONE_TO_MANY_MARKER)) {
            return LineType.OneToManyMapping
        }
        return LineType.Mapping
    }

    if (line.startsWith(VERSE_SEGMENTS_MARKER)) {
        return LineType.verseSegments
    }

    if (line.match(/^[0-9A-Za-z]/)) {
        return LineType.ChapterVerse
    }

    throw new Error(`Unrecognized line type: ${line}`)
}

function processChapterVerse(line: string, versification: Versification) {
    let parts = line.split(' ')
    if (parts.length < 2) {
        return
    }
    const bookName = parts[0]
    const bookNum = bookIds.indexOf(bookName) + 1
    if (bookNum < 1) {
        return
    }
    const bbb = bookNum.toString().padStart(3, '0')
    parts.shift()
    for (let part of parts) {
        const [chapter, totalVersesString] = part.split(CHAPTER_VERSE_SEPARATOR)
        let bbbccc = bbb + chapter.toString().padStart(3, '0')
        let totalVerses = parseInt(totalVersesString)
        if (isNaN(totalVerses)) {
            continue
        }
        versification.numVersesInChapterMap.set(bbbccc, totalVerses)
        for (let i = 0; i < totalVerses; i++) {
            versification.validVerses.push(bbbccc + (i + 1).toString().padStart(3, '0'))
        }
    }
}

function processMappingLine(line: string, versification: Versification) {
    let [x, org] = line.split(MAPPING_MARKER)
    let [xBook, xChapter, xVerse1, xVerse2] = parseRef(x)
    let [oBook, oChapter, oVerse1, oVerse2] = parseRef(org)
    
    let containsVerseSplits = !!line.match(/[a-z]/) // "NUM 26:1b = NUM 26:1" or "ESG 1:1 = ESG 1:1a"
    let invalidData = [xBook, xChapter, xVerse1, xVerse2, oBook, oChapter, oVerse1, oVerse2].some(n => n < 0 || isNaN(n) || !isFinite(n))
    if (containsVerseSplits || invalidData) {
        return
    }

    let pad = (x: number) => x.toString().padStart(3, '0')

    let createBcv = (bookNum: number, chapterIndex: number, verseIndex: number) => `${pad(bookNum)}${pad(chapterIndex)}${pad(verseIndex)}`

    for (let i=0; i<=(xVerse2-xVerse1); ++i) {
        let obcv = createBcv(oBook + 1, oChapter, oVerse1 + i)
        let xbcv = createBcv(xBook + 1, xChapter, xVerse1 + i)

        let existingOrgToX = versification.orgToXMap.get(obcv) || []
        versification.orgToXMap.set(obcv, [...existingOrgToX, xbcv])

        let existingXToOrg = versification.xToOrgMap.get(xbcv) || []
        versification.xToOrgMap.set(xbcv, [...existingXToOrg, obcv])
    }
}

// 'GEN 2:3-4' => [1, 2, 3, 4]
function parseRef(ref: string) {
    const RE_REF = /(?<book>\w+) (?<chapter>\d+):(?<verse1>\d+)((?<verse2>-\d+))?/

    const match = RE_REF.exec(ref)

    if (!match || !match.groups) {
        console.error(ref + ' does not match /(?<book>\w+) (?<chapter>\d+):($?<verse1>\d+)((?<verse2>-\d+))?/')
        return [-1, -1, -1, -1]
    }

    let book = bookIds.indexOf(match.groups.book)
    let chapter = Number.parseInt(match.groups.chapter)
    let verse1 = Number.parseInt(match.groups.verse1)
    let verse2 = match.groups.verse2 ? -Number.parseInt(match.groups.verse2) : verse1

    return [book, chapter, verse1, verse2]
}

class Versification {
    xToOrgMap: Map<string, string[]>
    orgToXMap: Map<string, string[]>
    numVersesInChapterMap: Map<string, number>
    validVerses: string[]
    
    constructor(
        xToOrgMap: Map<string, string[]>,
        orgToXMap: Map<string, string[]>,
        numVersesInChapterMap: Map<string, number>,
        validVerses: string[])
    {
        this.xToOrgMap = xToOrgMap
        this.orgToXMap = orgToXMap
        this.numVersesInChapterMap = numVersesInChapterMap
        this.validVerses = validVerses
        this.toOrg = this.toOrg.bind(this)
        this.orgToThis = this.orgToThis.bind(this)
        this.numVersesInChapter = this.numVersesInChapter.bind(this)
    }

    toOrg(bbbcccvvv: string) {
        let result = this.xToOrgMap.get(bbbcccvvv)
        if (!result) {
            result = [bbbcccvvv]
        }
        return result
    }

    orgToThis(bbbcccvvv: string) {
        let result = this.orgToXMap.get(bbbcccvvv)
        if (!result) {
            result = [bbbcccvvv]
        }
        return result
    }

    numVersesInChapter(bbbccc: string) {
        let result = this.numVersesInChapterMap.get(bbbccc)
        if (result === undefined) {
            result = 0
        }
        return result
    }
}

class VersificationTable {
    private mappings = new Map<string, Versification>()

    private static instance?: VersificationTable

    private constructor() {
        this.register = this.register.bind(this)
        this.getMapping = this.getMapping.bind(this)
        this.getNames = this.getNames.bind(this)
    }

    static getInstance() {
        if (!VersificationTable.instance) {
            VersificationTable.instance = new VersificationTable()
        }
        return VersificationTable.instance
    }

    register(name: string, mapping: Versification) {
        this.mappings.set(name, mapping)
    }

    getMapping(name: string) {
        return this.mappings.get(name)
    }

    getNames() {
        return Array.from(this.mappings.keys())
    }
}

class VersificationConverter {
    private versificationTable: VersificationTable

    constructor() {
        this.versificationTable = VersificationTable.getInstance()
    }

    registerVersification(name: string, table: string) {
        if (this.versificationTable.getNames().includes(name)) {
            return
        }
        let versification = processFile(table)
        this.versificationTable.register(name, versification)
    }

    /**
     * Get a function that will convert between srcVersification and destVersification.
     * @throws if cannot create function
     */
    getConversionFunction(srcVersificationName: string, destVersificationName: string) {
        let srcMapper = this.versificationTable.getMapping(srcVersificationName)
        if (!srcMapper) {
            throw new Error()
        }
        let srcToOrgFunction = srcMapper.toOrg
        let destMapper = this.versificationTable.getMapping(destVersificationName)
        if (!destMapper) {
            throw new Error()
        }
        let orgToDestFunction = destMapper.orgToThis
    
        return (bbbcccvvv: string) => srcToOrgFunction(bbbcccvvv).flatMap(orgToDestFunction)
    }

    /**
     * Get the number of verses per chapter for every chapter in a versification.
     * @throws if cannot find versification
     */
    getNumberOfVersesPerChapter(versificationName: string) {
        let mapping = this.versificationTable.getMapping(versificationName)
        if (!mapping) {
            throw new Error(`Can't find versification: ${versificationName}`)
        }
        return mapping.numVersesInChapterMap
    }

    /**
     * Get a list of all the valid verses in this versification.
     * @throws if cannot find versification
     */
    getValidVerses(versificationName: string) {
        let mapping = this.versificationTable.getMapping(versificationName)
        if (!mapping) {
            throw new Error()
        }
        return mapping.validVerses
    }
}

let versificationConverter = new VersificationConverter()

versificationConverter.registerVersification('English', engTable)
versificationConverter.registerVersification('Original', orgTable)

function convertReferenceStrings(references: string[], srcVersification: string, destVersification: string) {
    try {
        let conversionFunction = versificationConverter.getConversionFunction(srcVersification, destVersification)
        return references.flatMap(conversionFunction)
    } catch (error) {
        return []
    }
}

export { versificationConverter, processFile, convertReferenceStrings }
