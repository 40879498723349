import React, { Component } from "react"
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react"

interface INumberInput {
    message: string,
    initialValue: number,
    min?: number,
    onEnter: (value: number) => void,
    onEscape: () => void,
}

class NumberInput extends Component<INumberInput> {
    @observable value = ''

    constructor(props: INumberInput) {
        super(props);
        makeObservable(this);
    }

    render() {
        let { min, message, onEnter } = this.props
        let { value } = this
        return (
            <div>
                <input
                    className='difficulty-input'
                    autoFocus
                    required
                    type='number'
                    value={value}
                    min={min}
                    onBlur={e => {
                        onEnter(parseFloat(value))
                    }}
                    onChange={this.onChange.bind(this)}
                    onKeyDown={this.onKeyDown.bind(this)}
                />
                <div className='number-input-text'>
                    <span>{message}</span>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.value = this.props.initialValue.toString()
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.value = e.target.value
    }

    onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        let { onEnter, onEscape } = this.props
        let { value } = this
        
        e.stopPropagation()

        if (e.key === 'Enter') {
            let _value = parseFloat(value)
            onEnter(_value)
            return
        }

        if (e.key === 'Escape') {
            onEscape()
            this.value = ''
        }
    }
}

export default observer(NumberInput)