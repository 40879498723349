import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { t } from 'ttag'

import { VideoSegmentStartingPointButton } from '../utils/Buttons'
import { RootContext } from '../app/RootContext'
import { Root } from '../../models3/Root'
import { SegmentPositionDialog } from './SegmentPositionDialog'
import { EditingSegmentPosition } from '../translation/TranslationRightPane'

import _debug from "debug"; let log = _debug('sltt:VideoSegmentPosition')


interface IVideoSegmentPosition {
    editingSegmentPosition: EditingSegmentPosition,
    setEditingSegmentPosition: (value: EditingSegmentPosition) => void,
    enabled: boolean,
}

class VideoSegmentPosition extends Component<IVideoSegmentPosition> {
    static contextType = RootContext

    render() {
        let { editingSegmentPosition, setEditingSegmentPosition, enabled } = this.props

        let rt: Root | null = this.context
        if (!rt) return null
        let { passage, passageVideo, passageSegment } = rt
        if (!passage || !passageVideo || !passageSegment) return null

        let segmentIndex = passageVideo.segments.findIndex(seg => seg._id === passageSegment!._id)
        if (segmentIndex < 0) return null

        return (
            <div>
                {editingSegmentPosition !== EditingSegmentPosition.None && (
                    <SegmentPositionDialog {...{passage, video: passageVideo, 
                        segmentIndex, editingSegmentPosition, 
                        close: (canceled, time, duration) => { 
                            if (!canceled) {
                                rt!.resetCurrentTime(time, duration)
                            }
                            setEditingSegmentPosition(EditingSegmentPosition.None) 
                        },
                    }} />
                )}
                <VideoSegmentStartingPointButton
                    className='segment-adjust-start-position-button'
                    enabled={enabled && rt.iAmInterpreter}
                    onClick={() => {
                        setEditingSegmentPosition(EditingSegmentPosition.Both)
                    }}
                    tooltip={/* translator: important */ t`Adjust video starting and ending point for this segment`}
                />
            </div>
        )
    }
}

export default observer(VideoSegmentPosition)