import React, { Component } from 'react'
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react'
import { t } from 'ttag'

import TextInput from '../../utils/TextInput'
import { RootContext } from '../../app/RootContext'
import { Root } from '../../../models3/Root'
import { ProjectPlan } from '../../../models3/ProjectModels'

interface IProjectStageAdder {
    addStage: (name: string) => void,
    plan: ProjectPlan,
}

export class ProjectStageAdder extends Component<IProjectStageAdder> {
    @observable adding = false

    static contextType = RootContext


    constructor(props: IProjectStageAdder) {
        super(props);
        makeObservable(this);
    }


    render() {
        let rt: Root = this.context
        if (!rt) return null

        let { validate } = this

        let _addStage = t`Add stage.`

        if (this.adding) {
            return (
                <TextInput
                    placeholder={_addStage}
                    message={t`Type Enter to add new stage or Esc to cancel.`}
                    validate={validate}
                    _onEscape={this.onCancel}
                    _onEnter={this.onEnter} />
            )
        }

        if (!rt.iAmAdmin) return null

        return (
            <div onClick={ () => this.adding = true }
                className='stage-adder'
                data-toggle="tooltip" 
                title={_addStage}>
                    <i className="far fa-fw fa-plus-square"></i>
            </div>
        )
    }

    validate = (value: string) => {
        value = value.trim()
        let { plan } = this.props
        if (plan.stages.find(stage => stage.name === value)) {
            return t`Duplicate name`
        }
        if (value === '') {
            return t`Empty name`
        }
        return ''
    }

    onCancel = () => this.adding = false

    onEnter = (newStageName: string) => {
        if (!this.validate(newStageName)) {
            this.props.addStage(newStageName)
        }
        this.adding = false
    }
}

export default observer(ProjectStageAdder)