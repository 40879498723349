import React, { FunctionComponent } from 'react'

import _debug from "debug"; let log = _debug('sltt:Optional') 

interface IOptional {
    show: boolean,
}

export const Optional: FunctionComponent<IOptional> = ({show, children}) => {
    return show ? (<> {children} </>) : null
}
