import React, { useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { IVideoPosition } from './VideoPositionBar'
import { CancelButton, OKButton, PencilButton } from '../utils/Buttons'
import { useOnClickOutside } from '../utils/Hooks'
import { VideoTimeCodeFormat } from '../../models3/ProjectModels'
import { t } from 'ttag'
import './TimeInput.css'
import { RootContext } from '../app/RootContext'

interface ITimeInput {
    currentTime: any
    videoPosition: IVideoPosition
    searchTime?: number
}

const TimeInput = observer(({ currentTime, videoPosition, searchTime }: ITimeInput) => {
    const rt = useContext(RootContext)
    const { project } = rt!
    const { videoTimeCodeFormat } = project
    const isAtLeast100MinutesDuration = videoPosition.duration >= 5941
    const [value, setValue] = useState(normalizeTimeFormat(currentTime, videoTimeCodeFormat, isAtLeast100MinutesDuration))
    const [editing, setEditing] = useState(false)
    const [tempValue, setTempValue] = useState('')
    const [showPencil, setShowPencil] = useState(false)
    const [validText, setValid] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)

    useOnClickOutside(containerRef, () => {
        handleCancelClick()
    })

    useEffect(() => {
        setValue(normalizeTimeFormat(currentTime, videoTimeCodeFormat, isAtLeast100MinutesDuration))
    }, [currentTime, videoTimeCodeFormat, isAtLeast100MinutesDuration])

    useEffect(() => {
        if (!searchTime) return
        setValue(normalizeTimeFormat(searchTime.toString(), videoTimeCodeFormat, isAtLeast100MinutesDuration))
    }, [searchTime, videoTimeCodeFormat, isAtLeast100MinutesDuration])

    const hidePencil = () => {
        setShowPencil(false)
    }

    const handleEditClick = () => {
        setEditing(true)
        setTempValue(value)
    }

    const handleCancelClick = () => {
        setEditing(false)
    }

    const handleConfirmClick = () => {
        const seconds = getSecondsFromInput(tempValue)
        const maxTimeSeconds = videoPosition.duration

        // Limit the input time to the maximum duration if needed
        const newSeconds = Math.min(parseFloat(seconds), maxTimeSeconds)

        // Set the new time and exit editing mode
        videoPosition.resetCurrentTime(newSeconds)
        setEditing(false)
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = event.target.value

        // If input is empty, return it immediately
        if (!inputValue) {
            setTempValue('')
            return
        }

        // Replace periods with colons
        inputValue = inputValue.replace(/\./g, ':')

        // Remove all characters except digits and colons
        inputValue = inputValue.replace(/[^\d:]/g, '')

        // Ensure time format is valid
        if (/^(\d{0,3}:)?(\d{0,2}:)?(\d{0,3})?$/.test(inputValue)) {
            // Set the input value and validate it
            setTempValue(inputValue)
            setValid(true)
        } else {
            setValid(false)
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            handleConfirmClick()
        } else if (event.key === 'Escape') {
            handleCancelClick()
        }
    }

    const getSecondsFromInput = (value: string) => {
        const parts = value.split(':').map(Number)
        let seconds = 0
        if (parts.length === 1) {
            // If minutes and seconds are provided
            seconds = parts[0] * 60
        }
        else if (parts.length === 2) {
            // If minutes and seconds are provided
            seconds = parts[0] * 60 + parts[1]
        } else if (parts.length === 3) {
            const partsString = value.split(':')
            const milliseconds = partsString[2]
            seconds = parts[0] * 60 + parts[1] + parseFloat('.' + milliseconds.padEnd(3, '0'))
        } else {
            // Handle invalid input or custom formats here
            console.error('Invalid time format: ', value)
        }

        return seconds.toString()
    }



    return (
        <div ref={containerRef} className="time-input" onMouseOver={() => setShowPencil(true)} onMouseLeave={() => setShowPencil(false)}>
            {!editing ? (
                <span className="time-code">
                    {value}
                    <span className="time-code-edit">
                        {showPencil && (
                            <PencilButton
                                enabled={true}
                                className="time-code-edit-button"
                                onClick={handleEditClick}
                                tooltip={t`Edit time code`}
                            />
                        )}
                    </span>
                </span>
            ) : (
                <div className="time-code-input" style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="text"
                        value={tempValue}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                        onBlur={hidePencil}
                        autoFocus
                        style={{ borderColor: validText ? '' : 'red' }}
                    />
                    <div className="time-code-btns">
                        <OKButton enabled={true} onClick={handleConfirmClick} buttonClassName="" className="ok-button" tooltip={t`Jump time`} />
                        <CancelButton enabled={true} onClick={handleCancelClick} className="cancel-button" tooltip={t`Cancel`} />
                    </div>
                </div>
            )}
        </div>
    )
})

const normalizeTimeFormat = (totalSeconds: string, videoTimeCodeFormat: string, isAtLeast100MinutesDuration: boolean) => {
    // Calculate minutes and seconds
    const minutes = Math.floor(parseFloat(totalSeconds) / 60)
    const seconds = Math.floor(parseFloat(totalSeconds) % 60)
    const milliseconds = Math.floor((parseFloat(totalSeconds) - Math.floor(parseFloat(totalSeconds))) * 1000)

    // Format minutes, seconds, and milliseconds into the desired format

    //Check 99+ min Length
    let formattedMinutes = minutes.toString().padStart(2, '0')

    if (isAtLeast100MinutesDuration) {
        formattedMinutes = minutes.toString().padStart(3, '0')
    }

    const formattedSeconds = seconds.toString().padStart(2, '0')
    const formattedMilliseconds = milliseconds.toString().padStart(3, '0')

    // Combine minutes, seconds, and milliseconds into the desired format
    let formattedTime
    if (videoTimeCodeFormat === VideoTimeCodeFormat.timeFormatWithColon) {
        formattedTime = `${formattedMinutes}:${formattedSeconds}:${formattedMilliseconds}`
    } else {
        formattedTime = `${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`
    }
    return formattedTime
}

export default TimeInput
