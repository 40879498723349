import { Project, PassageVideo, PassageSegment, Portion, Passage } from './ProjectModels'
import { RefRange } from '../scrRefs/RefRange'
import { Root } from './Root'
import _ from 'underscore'

import _debug from "debug"; let log = _debug('sltt:ProjectReferences') 

// Keep track of the RefRanges for the latest videos for all passages in the project.
// Allow efficiently getting all the videos that match target references.

export class ProjectReference {
    constructor (
        public refRanges: RefRange[],
        public passageVideo: PassageVideo,
        public startingTime: number,   // time for the segment containing the reference
        public endingTime: number,   
    ) { }

    dbg(project?: Project) {
        let { refRanges, startingTime, endingTime, passageVideo } = this
        let passage = '*unknown*'
        
        if (project) {
            let _portion = project.findPortion(passageVideo._id)
            let _passage = project.findPassage(passageVideo._id)
            passage = `${_portion?.name}/${_passage?.name}`
        }
        
        return { refRanges, startingTime, endingTime, passage }
    }

    info(rt: Root) {
        let { passageVideo, startingTime, endingTime, refRanges } = this

        let portion = rt.project.portions.find(_portion => passageVideo._id.startsWith(_portion._id))
        let passage = portion?.passages.find(_passage => passageVideo._id.startsWith(_passage._id))

        let parts = [portion?.name ?? '', passage?.name ?? '', rt.displayableReferences(refRanges)]

        // Remove redundant parts of reference name if present
        if (parts.length >= 2 && parts[1].startsWith(parts[0])) parts.splice(0, 1)
        if (parts.length >= 2 && parts[1].startsWith(parts[0])) parts.splice(0, 1)
        if (parts.length >= 3 && parts[2].startsWith(parts[1])) parts.splice(1, 1)

        let timestamp = startingTime > 0 ? ` [${startingTime.toFixed(2)}s]` : ''

        let tooltip = `${portion?.name ?? ''} / ${passage?.name ?? ''} ${timestamp}` 

        return { portion, passage, tooltip }
    }
}

export class ProjectReferences {
    private refs: { [bbbccc: string]: Set<ProjectReference> } = {}
    private projectReferences: ProjectReference[] = []
    // private initialized = false
    private project?: Project

    // Call this to update the list of references whenever references are for
    // any portion/passage are changed
    update(rt: Root) {
        log('update')

        this.project = rt.project
        this.refs = {}
        this.projectReferences = []
        this.gatherProjectReferences()
        this.insertProjectReferencesIntoRefs()

        // log('update', this.projectReferences.map(pref => pref.info(rt).tooltip))
    }

    // get a list of all the ProjectReferences that overlap with targetRange
    get(targetRange: RefRange, firstOnly?: boolean): ProjectReference[] {
        let results: ProjectReference[] = []

        for (let bbbccc of targetRange.chapterIterator()) {
            let refSet = this.refs[bbbccc]
            if (refSet) {
                for (let projectReference of refSet) {
                    if (targetRange.overlaps(projectReference.refRanges)) {
                        results.push(projectReference)
                        if (firstOnly) return results
                    }
                }
            }
        }

        return results
    }

    // getForRanges(rt: Root, ranges: RefRange[]): ProjectReference[] {
    //     let results: ProjectReference[] = []

    //     for (let range of ranges) {
    //         let matches = this.get(range)
    //         for (let match of matches) {
    //             match.setup(rt)
    //             if (!results.find(r => r.key === match.key)) {
    //                 results.push(match)
    //             }
    //         }
    //     }
        
    //     results = _.sortBy(results, 'key')

    //     return results
    // }

    private gatherProjectReferences() {
        this.project!.portions.forEach(portion => {
            portion.passages.forEach(passage => {
                let videos = passage.videosNotDeleted
                let video = videos.slice(-1)[0]
                if (video) {
                    this.addReferencesForVideo(portion, passage, video)
                }
            })
        })
    }

    private addReferencesForVideo(portion: Portion, passage: Passage, video: PassageVideo) {
        let endingTime = video.computedDuration
        let startingPoint = video.computedDuration

        // verseReferences: PassageVideoReference { references: RefRange[], position, time } []
        // We process these from the end so that the starting time of one verse can become the ending time of the next.
        let verseReferences = video.getVisibleReferences(passage)
                                .filter(vr => vr.references.length > 0)
                                .reverse()

        for (let verseRef of verseReferences) {
            // ProjectReference { refRanges, passageVideo, startingTime, endingTime }
            let projectReference = new ProjectReference(verseRef.references, video, verseRef.time, endingTime)

            this.projectReferences.push(projectReference)
            endingTime = verseRef.time
            startingPoint = verseRef.time
        }
        
        // let segmentsWithReferences = video.visibleSegments(passage)
        //                                 .filter(seg => seg.references.length > 0 && seg.time < startingPoint)
        //                                 .reverse()
        // for (let segment of segmentsWithReferences) {
        //     let projectReference = new ProjectReference(segment.references, video, segment.time, endingTime)
        //     this.projectReferences.push(projectReference)
        //     endingTime = segment.time
        //     startingPoint = segment.time
        // }
        
        // if (startingPoint > 0 && portion.references.length > 0) {
        //     let projectReference = new ProjectReference(portion.references, video, 0, endingTime)
        //     this.projectReferences.push(projectReference)
        //     endingTime = 0
        //     startingPoint = 0
        // }
    }

    private insertProjectReferencesIntoRefs() {
        this.projectReferences.forEach(projectReference => {
            projectReference.refRanges.forEach(refRange => {
                for (let bbbccc of refRange.chapterIterator()) {
                    let refSet = this.refs[bbbccc]
                    if (!refSet) {
                        refSet = new Set<ProjectReference>()
                        this.refs[bbbccc] = refSet
                    }
                    refSet.add(projectReference)
                }
            })
        })
    }

}