import React, { Component, FunctionComponent } from "react";
import { observer } from "mobx-react";
import BookNameEditor from "./BookNameEditor";
import { Root } from "../../../models3/Root";
import { t } from "ttag";
import '../ProjectSettings.css'

interface IBookNameRow {
    rt: Root,
    uiBookName: string,
    projectBookName: string,
    saveProjectBookName: (bookName: string) => void,
}

class BookNameRow extends Component<IBookNameRow> {
    render() {
        let { rt, uiBookName, projectBookName, saveProjectBookName } = this.props
        let { iAmAdmin, useMobileLayout } = rt

        return (
            <tr className='project-references-book-row'>
                <td className='project-references-table-item'>{ uiBookName }</td>
                <td className='project-references-table-item'>
                    {iAmAdmin && !useMobileLayout ? (
                        <BookNameEditor bookName={projectBookName} save={saveProjectBookName} />
                    ) : (
                        <BookNameView bookName={projectBookName} />
                    )}
                </td>
            </tr>
        )
    }
}

interface IBookNameView {
    bookName: string,
}

export const BookNameView: FunctionComponent<IBookNameView> = ({ bookName }) => (
    <>
        { bookName || <i className='project-references-empty-book-name'>{t`Book name...`}</i> }
    </>
)

export default observer(BookNameRow)