import React, { FC, useState, useContext, useEffect } from 'react'
import { observer, /* inject */ } from 'mobx-react'
import { t } from 'ttag'

import { Root } from '../../models3/Root'
import { VideoCache } from '../../models3/VideoCache'
import { RootContext } from '../app/RootContext'
import { LoadingIcon, CheckIcon } from '../utils/Icons'
import '../utils/Icons.css'
import { VideoCacheRecord } from '../../models3/VideoCacheRecord'
import _ from 'underscore'

import _debug from "debug"; let log = _debug('sltt:UploadReport')

interface IUploadReport {
    rt: Root,
}

async function getUploadVcrs() {
    // first uploads that have not yet started then most recent uploads first
    let sortKey = (vcr: VideoCacheRecord) => 
        vcr.uploadStartTimeMs > 0 ? -vcr.uploadStartTimeMs : -9999999999999

    let vcrs = await VideoCache.getAllVcrs()
    vcrs = vcrs.filter(vcr => vcr.uploadeds.length > 0)
    vcrs = _.sortBy(vcrs, sortKey) 
    return vcrs
}


const UploadReport: FC<IUploadReport> = observer(() => {
    return (
        <div>
            <h3>{t`Upload Report`}</h3>
            <UploadReportBody />
        </div>
    )
})

const UploadReportBody: FC = () => {
    const [vcrs, setVcrs] = useState<VideoCacheRecord[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            const asyncGetAllVcrs = async () => {
                if (!vcrs.length) {
                    setLoading(true)
                }
                let _vcrs = await getUploadVcrs()
                setVcrs(_vcrs)
                setLoading(false)
            }
            asyncGetAllVcrs()
        }, 2000)
        return () => clearInterval(interval)
    }, [vcrs])

    if (loading) {
        return <LoadingIcon className='' />
    }

    return <UploadReportTable {...{ vcrs }} />
}

function formatTime(rt: Root, time: number) {
    if (time <= 0) return '-'

    let date = new Date(time)
    let { dateFormatter } = rt
    return dateFormatter.format(date)
}


interface IUploadReportTable {
    vcrs: VideoCacheRecord[]
}

const UploadReportTable: FC<IUploadReportTable> = ({ vcrs }) => {
    const rt = useContext(RootContext)
    if (!rt) return null
    
    let { dateFormatter } = rt

    let vcrsToDisplay = vcrs.filter((vcr, i) => vcr.needsUploaded || i<40)

    // If we can't find a matching passage we assume this video is not from this project
    vcrsToDisplay = vcrsToDisplay.filter(vcr => vcr.findPassage(rt))

    if (vcrsToDisplay.length === 0) {
        return (
            <p>{t`No videos have been uploaded.`}</p>
        )
    }

    let style: any = { whiteSpace: 'pre'}

    let videoName = (vcr: VideoCacheRecord) => {
        let portionName = vcr.findPortion(rt)?.name || '*unknown*'
        let passageName = vcr.findPassage(rt)?.name || '*unknown*'
        let tag = t`note`

        // Video _ids look like project/time/time/time
        if (vcr._id.split('/').length <= 4) {
            let video = vcr.findVideo(rt)
            tag = video ? video.displayedCreationDate(dateFormatter) : ''
        }

        tag = tag ? `(${tag})` : ''

        return `${portionName} / ${passageName} ${tag}`
    }

    let startingTime = (vcr: VideoCacheRecord) => formatTime(rt, vcr.uploadStartTimeMs)
    let finishingTime = (vcr: VideoCacheRecord) => formatTime(rt, vcr.uploadFinishTimeMs)

    return (
        <div style={style}>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>{t`Video`}</th>
                        <th>{t`Started`}</th>
                        <th>{t`Completed`}</th>
                        <th>{t`Error`}</th>
                    </tr>
                </thead>
                <tbody>
                    {vcrsToDisplay.map(vcr => (
                        <tr key={vcr._id}>
                            <td>{videoName(vcr)}</td>
                            <td>{startingTime(vcr)}</td>
                            <td><UploadMessage {...{ vcr, finishingTime: finishingTime(vcr) }} /></td>
                            <td>{ vcr.error }</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

interface IUploadReportEntry {
    vcr: VideoCacheRecord,
    finishingTime: string,
}

const UploadMessage: FC<IUploadReportEntry> = ({ vcr, finishingTime }) => {
    if (vcr.needsUploaded) {
        return (
            <div>{vcr.numberUploaded} of {vcr.totalBlobs} uploaded</div>
        )
    }

    let uploadRate = vcr.uploadRate()

    return (
        <div className='flex-centered-items'>
            <span>
                {finishingTime}
                {uploadRate > 0 ? ' (' + uploadRate.toFixed(1) + ' KB/s)': ''}
                <CheckIcon className='ok-icon' tooltip='' />
            </span>
        </div>
    )
}

export default UploadReport
