import React, { FC, useEffect } from 'react'
import { Root } from '../../models3/Root'
import { observer } from 'mobx-react'

interface ITRLProjectsLoader {
    rt: Root
}

// TODO: move this to a more appropriate location
// eg. Root initialization and/or timer that checks for updates?
export const TRLProjectsLoader: FC<ITRLProjectsLoader> = observer(({ rt }) => {
    const { project } = rt
    useEffect(() => {
        rt.loadTRLResources().catch(console.error)
    }, [project])

    return (<div className="trl-projects-loader"></div>)
})
