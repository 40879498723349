import React, { Component, FC } from 'react'
import _ from 'underscore'
import { observer } from 'mobx-react'
import { DraggableLocation } from 'react-beautiful-dnd'
import { t } from 'ttag'

import { ProgressSelection, Root } from '../../models3/Root'
import { displayError, systemError } from '../utils/Errors'
import StatusBoard from './StatusBoard'
import { DragAndDropData } from './DragAndDropData'
import { ClearNotifications } from '../notifications/Notifications'
import './StatusBoardEditor.css'
import { ProjectPlanUploader } from '../utils/ProjectPlanUploader'
import DropTarget from '../utils/DropTarget'
import { DropTargetViewLarge } from '../utils/DropTargetView'
import { Dropdown, MenuItem } from "react-bootstrap"
import { Passage } from '../../models3/ProjectModels'

export function passagesMatchingProgressSelection(rt: Root, selection: ProgressSelection | string) {
    let { project, portion, passage } = rt
    let { progressiblePortions } = project
    
    if (selection === ProgressSelection.showAll) {
        return progressiblePortions.flatMap(portion => portion.passages)
    }

    if (selection === ProgressSelection.currentPortion) {
        if (portion?.isProgressible) {
            return portion.passages
        }
        return []
    }

    if (selection === ProgressSelection.currentPassage) {
        if (portion?.isProgressible && passage) {
            return [passage]
        }
        return []
    }

    if (selection.startsWith('#')) {
        return project.passagesMatchingHashtag(selection)
    }

    let _portion = project.findPortion(selection)
    if (_portion) {
        return _portion.passages
    }

    return []
}


import _debug from "debug"; let log = _debug('sltt:StatusEditor')

interface IStatusEditor{
    rt: Root,
    selection: ProgressSelection | string,
    setSelection: (selection: ProgressSelection | string) => void,
    selectPassage: (passage: Passage) => void,
}

const updateStatus = (data: DragAndDropData, source: DraggableLocation, destination: DraggableLocation, draggableId: string) => {
    let startId = source.droppableId
    let finishId = destination.droppableId

    if (startId !== finishId) {
        let passage = findPassage(data, startId, draggableId)
        if (passage !== undefined) {
            let newColumn = data.findColumn(finishId)
            if (newColumn !== undefined) {
                let videos = passage.videosNotDeleted
                if (videos.length > 0) {
                    videos[videos.length - 1].setStatus(newColumn.task.id)
                }
            }
        }
    }
}

const findPassage = (data: DragAndDropData, columnId: string, draggableId: string) => {
    let column = data.findColumn(columnId)
    if (column !== undefined) {
        let passage = column.items.map(e => e.passage)
                                .find(e => e._id === draggableId)
        
        if (passage !== undefined) {
            return passage
        }
    }
}

class StatusBoardEditor extends Component<IStatusEditor> {
    // upload = async (files: FileList) => {
    //     if (files.length !== 1) {
    //         displayError(t`You must drop exactly one file.`)
    //         return
    //     }

    //     let file = files[0]
    //     let planUploader = new ProjectPlanUploader(this.props.rt.project)
    //     try {
    //         await planUploader.uploadProjectPlanFile(file)
    //     } catch(err) {
    //         displayError(err)
    //     }
    // }

    render() {
        let { rt, selection, setSelection, selectPassage } = this.props
        let { project } = rt
        let { plans, displayName } = project
        // let { upload } = this

        let plan = plans[0]
        if (!plan) {
            return null
        }

        // let message = <div>{t`Drop project plan file here to upload.`}</div>
        // let dropTargetView = <DropTargetViewLarge message={message} />

        let passages = passagesMatchingProgressSelection(rt, selection)

        return (
            <div className="status-board-editor">
                <div className='status-board-editor-header'>
                    <h4 className='status-board-editor-project-name'>
                        <b>{displayName}</b>
                    </h4>
                    <div className="portion-selector-container">
                        <div>
                            <ProjectDataFilter {... {rt, selection, setSelection}} />
                        </div>
                        {passages.length !== 0 && (
                            <ClearNotifications {... {rt, passages}} />
                        )}
                    </div>
                </div>
                {/* <DropTarget upload={upload} dropTargetView={dropTargetView}> */}
                    <div className="status-board">
                        <StatusBoard
                            project={project}
                            projectPlan={plan}
                            passages={passages}
                            showLongTaskNames={passages.length > 1}
                            updateStatus={updateStatus}
                            selectPassage={selectPassage}
                            rt={rt}
                        />
                    </div>
                {/* </DropTarget> */}
            </div>
        )
    }
}

function localizedSelection(rt: Root, selection: ProgressSelection | string) {
    switch (selection) {
        case ProgressSelection.showAll:
            return '*' + t`Show All` + '*'
        case ProgressSelection.currentPortion:
            return '*' + t`Current Portion` + '*'
        case ProgressSelection.currentPassage:
            return '*' + t`Current Passage` + '*'
    }

    let portion = rt.project.portions.find(portion => portion._id === selection)
    if (portion) return portion.name
    
    return selection
}

interface IProjectDataFilterDropdown {
    rt: Root,
    selection: ProgressSelection | string, // string = _id of selected portion or hashtag
    setSelection: (selection: ProgressSelection | string) => void,
}

export const ProjectDataFilter: FC<IProjectDataFilterDropdown> = ({ rt, selection, setSelection }) => {
    let { project } = rt
    let { progressiblePortions } = project
    let hashtags = project.hashtagsArray()

    return (
        <Dropdown id='project-data-filter'>
            <Dropdown.Toggle className='project-data-filter-toggle'>
                {localizedSelection(rt, selection)}
            </Dropdown.Toggle>
            <Dropdown.Menu className='project-data-filter-dropdown'>
                <MenuItem className='project-data-filter-item'
                        key={ProgressSelection.showAll}
                        onClick={() => setSelection(ProgressSelection.showAll)}
                        value={ProgressSelection.showAll} >
                    {localizedSelection(rt, ProgressSelection.showAll)}
                </MenuItem>
                <MenuItem className='project-data-filter-item'
                        key={ProgressSelection.currentPortion}
                        onClick={() => setSelection(ProgressSelection.currentPortion)}
                        value={ProgressSelection.currentPortion} >
                    {localizedSelection(rt, ProgressSelection.currentPortion)}
                </MenuItem>
                <MenuItem disabled>_____</MenuItem>
                {progressiblePortions.map(portion => (
                    <MenuItem className='project-data-filter-item'
                            key={portion._id}
                            onClick={() => setSelection(portion._id)}
                            value={portion.name} >
                        {portion.name}
                    </MenuItem>
                ))}
                { hashtags.length ? <MenuItem disabled>_____</MenuItem> : null }
                {hashtags.map(hashtag => (
                    <MenuItem className='project-data-filter-item'
                        key={hashtag}
                        onClick={() => setSelection(hashtag)}
                        value={hashtag} >
                        {hashtag}
                    </MenuItem>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default observer(StatusBoardEditor)