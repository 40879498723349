import { O_TRUNC } from 'constants'
import { observer } from 'mobx-react'
import React, { FC, useState } from 'react'
import { t } from 'ttag'
import { Project } from '../../models3/ProjectModels'

import { Root } from '../../models3/Root'
import { EditButton } from '../utils/Buttons'
import { displayError } from '../utils/Errors'
import { CommentIcon, GenericIcon } from '../utils/Icons'
import { ILocalizedChoice, MakeLocalizedChoice } from '../utils/MakeLocalizedChoice'
import { EditableRichText } from '../utils/RichTextEditor'
import TextInput from '../utils/TextInput'
import './ProjectSettings.css'
import { ProjectPublicationPreferences } from './ProjectPublicationPreferences'

type ProjectPreferencesProps = {
    rt: Root,
}

const ProjectPreferences: FC<ProjectPreferencesProps> = observer(({ rt }) => {
    let { iAmAdmin, iAmInterpreter, project } = rt
    let { displayName, setDisplayName, description, setDescription } = project
    let allowEditing = iAmAdmin

    return (
        <div className='project-preferences-tab'>
            <h3>{t`Project Preferences`}</h3>
            <DisplayName {...{ displayName, setDisplayName, allowEditing }} />
            <ProjectDescription {...{ allowEditing, description, setDescription, project, iAmInterpreter }} />
            <ProjectType {...{ project, allowEditing }} />
            <ProjectRegion {...{ project, allowEditing }} />
            {rt.isTRLProject && <ProjectPublicationPreferences {...{ project, iAmAdmin }} />}
        </div>
    )
})

type DisplayNameProps = {
    allowEditing: boolean,
    displayName: string,
    setDisplayName: (displayName: string) => void,
}

const DisplayName: FC<DisplayNameProps> = observer(({ allowEditing, displayName, setDisplayName }) => {
    let [editing, setEditing ] = useState(false)

    function validate(value: string) {
        value = value.trim()
        if (value === '') {
            return t`Empty name`
        }
        return ''
    }

    return (
        <div className='project-display-name-editor'>
            <GenericIcon iconName="fa-flag" 
                className='project-preferences-icon right-margin-10px' tooltip={t`Project name`} />
            {editing ? (
                <TextInput
                    validate={validate}
                    initialValue={displayName}
                    message={t`Type <Enter> to change project name or type <Esc> to cancel`}
                    _onEnter={name => {
                        if (!validate(name)) {
                            setDisplayName(name)
                        }
                        setEditing(false)
                    }}
                    _onEscape={() => setEditing(false)}
                    allowEmptyValue={false}
                />
            ) : (
                <div className='project-display-name right-margin-10px'>{displayName}</div>
            )}
            {!editing && (
                <EditButton
                    enabled={allowEditing}
                    onClick={() => setEditing(true)}
                    className='project-preferences-edit-button'
                    tooltip={t`Edit`}
                />
            )}
        </div>
    )
})

type ProjectDescriptionProps = {
    allowEditing: boolean,
    description: string,
    setDescription: (value: string) => void,
    project: Project,
    iAmInterpreter: boolean,
}

const ProjectDescription: FC<ProjectDescriptionProps> = observer(({ allowEditing, description, setDescription, project, iAmInterpreter }) => {
    let [editing, setEditing] = useState(false)

    let prefix = 'project-description'
    return (
        <div className='project-description-editor'>
            <CommentIcon className='project-preferences-icon right-margin-10px' tooltip={t`Project description`} />
            <EditableRichText
                savedText={description}
                save={setDescription}
                cancel={() => {}}
                editorOpen={editing}
                setEditorOpen={setEditing}
                prefix={prefix}
            />
            {!editing && (
                <EditButton
                    enabled={allowEditing}
                    onClick={() => setEditing(true)}
                    className='project-preferences-edit-button'
                    tooltip={t`Edit`}
                />
            )}
        </div>
    )
})

const projectTypes: ILocalizedChoice[] = [
    {
        choice: '',
        localizedChoice: t`Project type not set`,
        title: ``,
    },
    {
        choice: 'translation', 
        localizedChoice: t`Translation Project`,
        title: ``, 
    },
    {
        choice: 'additional', 
        localizedChoice: t`Additional Translation Project`,
        title: t`When a team has multiple projects mark all but the first are 'Additional'`,
    },
    {
        choice: 'test_training', 
        localizedChoice: t`Test or Training Project`,
        title: ``,
    },
    {
        choice: 'resource',
        localizedChoice: t`Translation resource`,
        title: ``,
    },
    {
        choice: 'other', 
        localizedChoice: t`Other Project`,
        title: `Discussion forum, resource development, etc.`,
    },
]

type IProjectType = {
    allowEditing: boolean,
    project: Project,
}

const ProjectType: FC<IProjectType> = ({allowEditing, project}) => {
    let [projectType, setProjectType] = useState(project.projectType)

    let choice = projectTypes.find(pt => pt.choice === projectType) ?? projectTypes[0]
    
    return (
        <div className='project-type-editor'>
            <GenericIcon 
                iconName="fa-certificate"
                className='project-preferences-icon right-margin-10px' 
                tooltip={t`Project type`} />
            {allowEditing ? 
                (<MakeLocalizedChoice
                    choices={projectTypes}
                    choice={projectType}
                    setChoice={_choice => {
                        project.setProjectType(_choice).catch(displayError)
                        setProjectType(_choice as any)
                    }} />)
                : 
                <span>{choice.localizedChoice}</span>
            }
        </div>
    )
}

const projectRegions: ILocalizedChoice[] = [
    {
        choice: '',
        localizedChoice: t`Region not set`,
        title: ``,
    },
    {
        choice: 'africa',
        localizedChoice: t`Africa`,
        title: ``,
    },
    {
        choice: 'americas',
        localizedChoice: t`Americas`,
        title: ``,
    },
    {
        choice: 'asia',
        localizedChoice: t`Asia`,
        title: ``,
    },
    {
        choice: 'europe',
        localizedChoice: t`Europe`,
        title: ``,
    },
    {
        choice: 'oceania',
        localizedChoice: t`Oceania`,
        title: ``,
    },
]

type IProjectRegion = {
    allowEditing: boolean,
    project: Project,
}

const ProjectRegion: FC<IProjectRegion> = ({ allowEditing, project }) => {
    let [projectRegion, setProjectRegion] = useState(project.region)

    let choice = projectRegions.find(pr => pr.choice === projectRegion) ?? projectRegions[0]

    return (
        <div className='project-type-editor'>
            <GenericIcon
                iconName="fa-globe"
                className='project-preferences-icon right-margin-10px'
                tooltip={t`Project region`} />
            {allowEditing ?
                (<MakeLocalizedChoice
                    choices={projectRegions}
                    choice={projectRegion}
                    setChoice={_choice => {
                        project.setRegion(_choice).catch(displayError)
                        setProjectRegion(_choice as any)
                    }} />)
                :
                <span>{choice.localizedChoice}</span>
            }
        </div>
    )
}

export default ProjectPreferences