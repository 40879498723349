import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { DragDropContext, DropResult, DraggableLocation } from 'react-beautiful-dnd'

import TaskColumn from './TaskColumn'
import { ProjectPlan, Project, ProjectTask, Passage } from '../../models3/ProjectModels';
import { DragAndDropData, createDataModel } from './DragAndDropData';
import { Root } from '../../models3/Root';

interface IStatusBoard {
    rt: Root,
    project: Project,
    projectPlan: ProjectPlan,
    passages: Passage[],
    showLongTaskNames: boolean,
    updateStatus: (data: DragAndDropData, source: DraggableLocation, destination: DraggableLocation, draggableId: string) => void,
    selectPassage: (passage: Passage) => void,
}

class StatusBoard extends Component<IStatusBoard> {
    data: DragAndDropData

    constructor(props: IStatusBoard) {
        super(props)
        this.data = new DragAndDropData([])
    }
    
    onDragEnd = (result: DropResult) => {
        const { destination, source, draggableId } = result
        if (!destination) {
            return
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return
        }

        this.props.updateStatus(this.data, source, destination, draggableId)
    }
    
    render() {
        let { rt, passages, projectPlan, showLongTaskNames, selectPassage } = this.props
        this.data = createDataModel(rt, passages, projectPlan, showLongTaskNames)

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                {this.data.columns.map(column => (
                    <TaskColumn
                        key={column.id}
                        {...column}
                        rt={rt}
                        updateDifficulty={(difficulty: number) => {
                            column.task.setDifficulty(difficulty)
                        }}
                        selectPassage={selectPassage}
                    />
                ))}
            </DragDropContext>
        )
    }
}

export default observer(StatusBoard)