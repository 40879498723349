export function fmt(obj: any) {
    let keys = Object.keys(obj)
    let parts = keys.map(key => `    ${key} = ` + fmtValue(key, obj[key]))
    return (keys.length > 1 ? '\n' : '') + parts.join('\n')
}

function fmtValue(key: string, value: any) {
    if (value === undefined) return '*undefined*'
    if (value === null) return '*null*'

    if (Number.isInteger(value)) { return value.toFixed(0) }
    
    if (typeof value === 'number') { return value.toFixed(2) }

    if (typeof value === 'string') { return value }

    if (typeof value === 'object') { 
        let keys = Object.keys(value)
        if (keys.includes('_id')) {
            let val =  '...' + value._id.slice(-6)
            if (value.isPatch) val += ' isPatch'
            return val
        }
        return JSON.stringify(value, null, 4)
    }

    return value.toString()
}

export function s(x: any) {
    return JSON.stringify(x, null, 4)
}