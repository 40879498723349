import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Root } from '../../models3/Root'
import { Portion, ensureRanksAreInOrder } from '../../models3/ProjectModels'
import { TRLPortion, TRLResources, TRLUserResponsibilityError, createTRLResource } from '../../models3/TRLModel'
import { LoadingIcon } from '../utils/Icons'
import { ToggleBoxEditorButtons } from './ToggleBoxEditorButtons'
import './TRLPublish.css'
import { TRLPanel } from '../TRL/Browse/TRLPanel'
import { ErrorBoundary } from '../../components/utils/Errors'

interface ITRLPublish {
    rt: Root
    portion: Portion
    onToggleTRL: () => void
}
export const TRLPublish: FC<ITRLPublish> = observer((
    { rt, portion, onToggleTRL }
) => {
    const [error, setError] = useState('')
    const [publishing, setPublishing] = useState(false)
    const [areRanksInOrder, setAreRanksInOrder] = useState(false)

    useEffect(() => {
        ensureRanksAreInOrder(rt.project.portions).then(() => {
            setAreRanksInOrder(true)
        }).catch(console.error)
    }, []);

    const confirmChanges = async () => {
        if (error) {
            onToggleTRL()
            return
        }
        try {
            setPublishing(true)
            await TRLResources.publishPortionAndUpdateResourceList(rt, portion)
            onToggleTRL()
        } catch (e) {
            setPublishing(false)
            handleErrors(e, {})
        }
    }

    const cancelChanges = () => {
        onToggleTRL()
    }

    if (publishing) {
        return <div className="trl-loading-container" title={'Publishing...'}>
            <LoadingIcon className='' />
        </div>
    }

    const createPortionAndResources = () => {
        const defaultResult = {
            trlPortion: undefined,
            trlResources: undefined
        }
        if (error || !areRanksInOrder) {
            return defaultResult
        }
        try {
            const trlResource = createTRLResource(rt)
            const trlPortion = TRLPortion.create(portion, trlResource.primaryLanguage)
            trlResource.portions = [trlPortion]
            const trlResources = [trlResource]
            return { trlPortion, trlResources }
        } catch (e) {
            return handleErrors(e, {
                trlPortion: undefined,
                trlResources: undefined
            })
        }
    }

    const handleErrors = (e: any, defaultResult: any) => {
        if (e instanceof TRLUserResponsibilityError) {
            setError(String(e))
            return defaultResult
        }                
        else {
            throw e
        }
    }

    const { trlPortion, trlResources } = createPortionAndResources()
    // console.log('render TRLPublish', { portion, trlPortion, trlResources, error })

    return <div className="trl-publish-container">
        {error && <div className="trl-error-message">{error}</div>}
        {!error && <div>
            {portion.firstPassageIsThumbnail && <div className="checkbox-container">
                <label className="checkbox-label">
                    <input
                        type="checkbox"
                        id="thumbnail-checkbox"
                        className="checkbox-button checkbox-input"
                        checked={portion.firstPassageIsThumbnail}
                        readOnly
                        disabled
                    />
                    {'Excluding first passage during passage browsing (show it only as portion thumbnail)'}
                </label>
            </div>}
            {trlResources && trlPortion && <ErrorBoundary>
                <TRLPanel
                    trlResources={trlResources}
                    trlPortion={trlPortion}
                />
            </ErrorBoundary>}
        </div>}
        <ToggleBoxEditorButtons {...{ confirmChanges, cancelChanges }} />
    </div>
})
