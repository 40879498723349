import React, { Component } from "react"
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react"

import { PassageVideo } from "../../models3/ProjectModels"
import VideoDownloading from "../utils/VideoDownloading"

interface IVideoPositionViewer {
    video: PassageVideo,
    position: number,
}

class VideoPositionViewer extends Component<IVideoPositionViewer> {    
    @observable src = ''
    @observable vc: HTMLVideoElement | null = null

    constructor(props: IVideoPositionViewer) {
        super(props)
        makeObservable(this);
    }

    render() {
        let { video, position } = this.props

        let { url, creator } = video
        let { src } = this

        if (!src) {
            return (
                <VideoDownloading
                    className='video-position-viewer'
                    videoUrl={url}
                    creator={creator}
                    fontSizePt={18}
                    onEnded={blob => {
                        if (!src) {
                            this.src = window.URL.createObjectURL(blob)
                        }
                    }}
                />
            )
        }

        return (
            <div className='video-position-viewer'>
                <video
                    className='video-position-viewer-video'
                    src={src}
                    ref={vc => {
                        if (vc) {
                            this.vc = vc
                            vc.currentTime = position
                        }
                    }}
                    autoPlay={false}
                />
            </div>
        )
    }

    setPosition(time: number) {
        let { vc } = this
        if (vc) {
            vc.currentTime = time
        }
    }
}

export default observer(VideoPositionViewer)