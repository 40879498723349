import * as React from 'react'
import { Canvas, CanvasPath, CanvasRef, Point, ReactSketchCanvasProps } from 'react-sketch-canvas'
import { fmt } from '../utils/Fmt'

import _debug from "debug"; const log = _debug('sltt:ReactSketchCanvasSLTT')

/**
 * ReactSketchCanvasSLTT is a modified version of ReactSketchCanvas that adds extra functionality for the SLTT project.
 * - Drawing a rectangle
 */

export interface ReactSketchCanvasRefSLTT {
    setDrawMode: (drawMode: DrawMode) => void
    clearCanvas: () => void
    undo: () => void
    // redo: () => void
    // exportImage: (imageType: ExportImageType) => Promise<string>
    // exportSvg: () => Promise<string>
    // exportPaths: () => Promise<CanvasPath[]>
    loadPaths: (paths: CanvasPath[]) => void
    // getSketchingTime: () => Promise<number>
    // resetCanvas: () => void
}

export enum DrawMode { Pencil = 'pencil', Eraser = 'eraser', Rectangle = 'rectangle' }

/**
 * ReactSketchCanvas is a wrapper around Canvas component to provide a controlled way to manage the canvas paths.
 * It provides a set of methods to manage the canvas paths, undo, redo, clear and reset the canvas.
 *
 * @param props - Props for the ReactSketchCanvas component
 * @param ref - Reference to the ReactSketchCanvas component
 *
 * @returns ReactSketchCanvas component
 */
export const ReactSketchCanvasSLTT = React.forwardRef<ReactSketchCanvasRefSLTT, ReactSketchCanvasProps>(
    (props, ref) => {
        const {
            id = 'react-sketch-canvas',
            width = '100%',
            height = '100%',
            className = '',
            canvasColor = 'white',
            strokeColor = 'red',
            backgroundImage = '',
            exportWithBackgroundImage = false,
            preserveBackgroundImageAspectRatio = 'none',
            strokeWidth = 4,
            eraserWidth = 8,
            allowOnlyPointerType = 'all',
            style = {},
            svgStyle = {},
            onChange = (_paths: CanvasPath[]): void => undefined,
            // onStroke = (_path: CanvasPath, _isEraser: boolean): void => undefined,
        } = props

        const svgCanvas = React.createRef<CanvasRef>()
        const [isDrawing, setIsDrawing] = React.useState<boolean>(false)
        const [drawMode, setDrawMode] = React.useState<DrawMode>(DrawMode.Rectangle)
        const [currentPaths, _setCurrentPaths] = React.useState<CanvasPath[]>([])

        function setCurrentPaths(paths: CanvasPath[]) {
            // log('setCurrentPaths', fmt({ paths }))
            _setCurrentPaths(paths)
        }

        React.useEffect(() => {
            onChange(currentPaths)
            // eslint-disable-next-line
        }, [currentPaths])

        function isRectangle(paths: CanvasPath[]) {
            // assume everything made of 4 lines that starts and ends at the same point is a rectangle
            if (paths.length !== 4 || !paths.every(path => path.paths.length === 2)) return false
            return JSON.stringify(paths[0].paths[0]) === JSON.stringify(paths[3].paths[1])
        }

        React.useImperativeHandle(ref, () => ({
            setDrawMode: (drawMode: DrawMode): void => {
                setDrawMode(drawMode)
            },

            clearCanvas: (): void => {
                setCurrentPaths([])
            },

            undo: (): void => {
                // If last drawing action added a rectangle then undo all 4 sides
                const sliceSize = isRectangle(currentPaths.slice(-4)) ? 4 : 1
                setCurrentPaths(currentPaths.slice(0, -sliceSize))
            },

            loadPaths: (paths: CanvasPath[]): void => {
                setCurrentPaths(paths)
            },
        }))

        const handlePointerDown = (point: Point): void => {
            setIsDrawing(true)

            let strokes: CanvasPath[]
            switch (drawMode) {
                case DrawMode.Pencil:
                    strokes = [{
                        paths: [point],
                        strokeWidth,
                        strokeColor,
                        drawMode: true,
                    }]
                    break
                case DrawMode.Eraser:
                    strokes = [{
                        paths: [point],
                        strokeWidth: eraserWidth,
                        strokeColor: '#000000', // Erase using mask
                        drawMode: false,
                    }]
                    break
                case DrawMode.Rectangle:
                    strokes = [
                        // These must be separate paths to avoid the corners of the rectangle being rounded
                        // by the path smoothing code in the svg renderer
                        { paths: [point, point], strokeWidth, strokeColor, drawMode: true },
                        { paths: [point, point], strokeWidth, strokeColor, drawMode: true },
                        { paths: [point, point], strokeWidth, strokeColor, drawMode: true },
                        { paths: [point, point], strokeWidth, strokeColor, drawMode: true },
                    ]
                    break
            }

            setCurrentPaths([...currentPaths, ...strokes])
        }

        const handlePenOrEraserPointerMove = (point: Point): void => {
            const currentStroke = currentPaths.slice(-1)[0]
            const updatedStroke = {
                ...currentStroke,
                paths: [...currentStroke.paths, point],
            }
            setCurrentPaths([...currentPaths.slice(0, -1), updatedStroke])
        }

        const handleRectanglePointerMove = (point: Point): void => {
            const stroke = currentPaths.slice(-4)[0]
            const { x: x0, y: y0 } = stroke.paths[0]
            const { x: x1, y: y1 } = point

            const updatedStrokes = [
                { ...stroke, paths: [{ x: x0, y: y0 }, { x: x1, y: y0 }] },
                { ...stroke, paths: [{ x: x1, y: y0 }, { x: x1, y: y1 }] },
                { ...stroke, paths: [{ x: x1, y: y1 }, { x: x0, y: y1 }] },
                { ...stroke, paths: [{ x: x0, y: y1 }, { x: x0, y: y0 }] },
            ]
            setCurrentPaths([...currentPaths.slice(0, -4), ...updatedStrokes])
        }

        const handlePointerMove = (point: Point): void => {
            if (!isDrawing) return

            if (drawMode === DrawMode.Pencil || drawMode === DrawMode.Eraser) {
                handlePenOrEraserPointerMove(point)
            } else {
                handleRectanglePointerMove(point)
            }
        }

        const handlePointerUp = (): void => {
            if (!isDrawing) { return }
            setIsDrawing(false)
        }

        return (
            <Canvas
                ref={svgCanvas}
                id={id}
                width={width}
                height={height}
                className={className}
                canvasColor={canvasColor}
                backgroundImage={backgroundImage}
                exportWithBackgroundImage={exportWithBackgroundImage}
                preserveBackgroundImageAspectRatio={preserveBackgroundImageAspectRatio}
                allowOnlyPointerType={allowOnlyPointerType}
                style={style}
                svgStyle={svgStyle}
                paths={currentPaths}
                isDrawing={isDrawing}
                onPointerDown={handlePointerDown}
                onPointerMove={handlePointerMove}
                onPointerUp={handlePointerUp}
            />
        )
    })

ReactSketchCanvasSLTT.displayName = '@react-sketch-canvas/ReactSketchCanvas'
