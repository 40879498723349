import React, { Component } from "react"
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react"
import { t } from 'ttag'

import { PassageNote, PassageVideo, PassageSegment } from "../../models3/ProjectModels"
import { VideoTimeline, PositionSetter } from "./VideoTimeline"
import './NoteLocationEditor.css'
import { PaneCloseButton, AdjustNoteMarkerButtons } from "../utils/Buttons"
import VideoTimelinePlayButtons from "./VideoTimelinePlayButtons"
import RangeVideoPlayer from "./RangeVideoPlayer"
import { systemError, displayError } from "../utils/Errors"
import { ViewableVideoCollection } from "./ViewableVideoCollection"
import { VisiblePassageVideo } from "../../models3/VisiblePassageVideo"
import { IRoot } from "../../models3/Root"

import _debug from "debug"; let log = _debug('sltt:NoteLocationEditor')

const  fx = (val?: number) => val?.toFixed(2)


interface INoteLocationEditor {
    noteRoot: IRoot,
    onDoneEditing: () => void,
}

/*
    GLOSSARY

        position - a time measured within one specific video
        time - a time viewed in the main video timeline
        domain - area in the main video +/- 30 seconds from note position.
            startPosition/endPosition for note must lie in this range
        citation - a time range of the SL video (or one of its patches) that this note is about

    Allow the user to adjust the start/stop times for the note citation within
    the main video. 
    
    Also allow adjusting the position of the note itself.
    We do not currently allow the note to be moved to a different video
    or patch video since that is complicated.
 */

function limit(value: number, low: number, high: number) {
    value = Math.max(value, low)
    value = Math.min(value, high)
    return value
}

class NoteLocationEditor extends Component<INoteLocationEditor> {

    @observable isPlaying = false

    @observable currentTime = 0

    rangeVideoPlayer: RangeVideoPlayer | null = null

    @observable note: PassageNote = this.props.noteRoot.note!
    @observable segment: PassageSegment | null = null  // actual segment containing current note

    // These become defined when every the corresponding segment paramater
    // has been changed by the user
    newPosition?: number
    newStartPosition?: number
    newEndPosition?: number

    setters: PositionSetter[] = [] // passed to VideoTimeline to allow updating of positions relating to note
        // in order the are note.startPosition, note.position, note.endPosition, currentTime

    // note 'position' must stay between these limits to keep it on the same video
    lowPosition = 0
    highPosition = 0

    // The following positions are constrained to be in increasing order
    domainStartPosition = 0  // start of area viewable in this editor, this may be negative!
    startPosition = 0        // start of citation for note, this may be negative!
    position = 0             // point of focus for note
    endPosition = 0          // end of citation for note
    domainEndPosition = 0    // end of area viewable in this editor

    // video paramaters
    @observable noteVideo: PassageVideo | null = null
        // base video for note

    @observable currentVideos: ViewableVideoCollection | null = null

    @observable noteSetupComplete = false

    constructor(props: INoteLocationEditor) {
        super(props)

        makeObservable(this);

        this.setupNote(this.props.noteRoot.note!)
            .catch(displayError)
    }

    // We do not save the updated note position until the user navigates away
    // from the current note
    async componentDidUpdate(prevProps: INoteLocationEditor) {
        let { note: newNote } = this.props.noteRoot

        if (this.note._id === newNote!._id) return

        try {
            await this.saveNoteIfChangesExist(prevProps)
        } catch(err) {
            systemError(err)
        }

        await this.setupNote(newNote!)
    }

    async setupNote(note: PassageNote) {
        this.noteSetupComplete = false

        this.setupSetters()
        await this.setupNoteVideo(note)
        this.setPositionLimits()

        this.note = note
     
        this.noteSetupComplete = true
    }

    // Setup noteVideo to contain only the patches were available
    // at the time the note was originally created.
    //
    // Start download process
    async setupNoteVideo(note: PassageNote) {
        let { passage, passageVideo, createNoteIfNonexistent } = this.props.noteRoot
        if (!passage || !passageVideo) return

        await createNoteIfNonexistent()
        let noteVideo = note.toVideo(passage)
        if (!noteVideo) throw Error('could not find video for note')

        // Create a new note video object which contains just the patches that are not
        // newer than the note creation date
        this.noteVideo = new VisiblePassageVideo(passage, passageVideo, note.creationDate)
        log('setupNoteVideo', this.noteVideo)

        this.currentVideos = new ViewableVideoCollection()
        this.currentVideos.setup(passage, this.noteVideo)
        this.currentVideos.download().catch(displayError)
    }

    setupSetters() {
        this.setters = [
            // set startPosition, enforce range domainStartPosition .. position
            new PositionSetter(
                '0',
                () => this.domainStartPosition,
                () => this.position - .05,
                (value: number) => {
                    this.newStartPosition = value
                    this.startPosition = value

                    this.setCurrentTimePosition(value)
                }
            ),

            // set position; in range segmentStartPosition..segmentEndPosition
            new PositionSetter(
                '1',
                () => this.lowPosition,
                () => this.highPosition,
                (value: number) => {
                    this.newPosition = value
                    this.position = value

                    this.setDomainLimits() // reset domain

                    // Since the position has change, the valid value for start and end position have
                    // changed, reset the positions to make sure they are in the valid range
                    this.setters[0].setValue(this.startPosition)
                    this.setters[2].setValue(this.endPosition)

                    this.setCurrentTimePosition(value)
                }
            ),

            // set endPosition, enforce range position .. domainEndPosition
            new PositionSetter(
                '2',
                () => this.position + .05,
                () => this.domainEndPosition,
                (value: number) => {
                    this.newEndPosition = value
                    this.endPosition = value

                    this.setCurrentTimePosition(value)
                }
            ),

            // set currentTime
            new PositionSetter(
                '3',
                () => this.domainStartPosition,
                () => this.domainEndPosition,
                (value: number) => {
                    this.setCurrentTimePosition(value)
                }
            ),
        ]
    }

    // Convert relative position to time and pass to player
    setCurrentTimePosition(currentTimePosition: number) {
        this.currentTime = this.toTime(currentTimePosition, "currentTime")
        //log('setCurrentTimePosition', fx(this.currentTime), this.isPlaying)
        
        if (!this.isPlaying) {
            // if we are not playing move the player to the selected point in time
            this.rangeVideoPlayer?.setCurrentTime(this.currentTime)
        }
    }

    setPositionLimits = () => {
        let { passage, note } = this.props.noteRoot
        if (!passage) return

        let { noteVideo } = this

        let segment = noteVideo!.timeToSegment(passage, note!.time)
        this.segment = segment.actualSegment(passage)
        log('this.segment', JSON.stringify(this.segment.dbg(passage), null, 4))

        this.newPosition = undefined
        this.newStartPosition = undefined
        this.newEndPosition = undefined

        this.position = note!.position
        this.startPosition = note!.startPosition
        this.endPosition = note!.endPosition

        this.setHighLowPositionLimits()

        this.setters[1].value = this.position
        this.setters[1].setValue(this.position)

        this.setDomainLimits()

        this.setters[0].value = this.startPosition
        this.setters[0].setValue(this.startPosition)

        this.setters[2].value = this.endPosition
        this.setters[2].setValue(this.endPosition)

        this.setters[3].value = 0
        let domain = this.domainEndPosition - this.domainStartPosition
        this.setters[3].setValue(this.startPosition + domain/60)
    }

    // Set domain around position which is viewable in this editor
    setDomainLimits() {
        const timeBuffer = 30

        let { passage } = this.props.noteRoot
        let { noteVideo, position, segment } = this
        if (!passage || !noteVideo) return

        // domain start position is position-30 or start of video (whichever is later)
        let originPosition = segment!.timeToPosition(0)
        this.domainStartPosition = Math.max(position - timeBuffer, originPosition)

        // domain end position is position+30 or end of video (whichever is earlier)
        let finalPosition = segment!.timeToPosition(noteVideo.computedDuration)
        this.domainEndPosition = Math.min(position + timeBuffer, finalPosition)
    }

    // We do not allow a note position to move to a different video because that would be complicated.
    setHighLowPositionLimits() {
        let { passage, note } = this.props.noteRoot
        let { noteVideo } = this

        let segmentIndex = noteVideo!.timeToSegmentIndex(note!.time)

        let actualSegment = (si: number) => {
            let seg = noteVideo!.segments[si]
            return seg.actualSegment(passage!)
        }

        let segmentVideoId = (si: number) => {
            let segments = noteVideo!.segments
            if (si < 0 || si >= segments.length) return ''

            let segment = segments[si]
            if (!segment) return ''

            let patchId = segment.videoPatchHistory.slice(-1)[0]
            return patchId || noteVideo!._id
        }

        // Find earliest contiguous segment on same video
        let startSegmentIndex = segmentIndex

        // We have had problems with notes being shift to the beginning.
        // [For now at least] Restrict shifting note to only current segment
        // while (segmentVideoId(startSegmentIndex - 1) === segmentVideoId(startSegmentIndex)) {
        //     --startSegmentIndex
        // }

        this.lowPosition = actualSegment(startSegmentIndex).position

        // Find latest contiguous segment on same video
        let endSegmentIndex = segmentIndex

        // We have had problems with notes being shift to the beginning.
        // [For now at least] Restrict shifting note to only current segment
        // while (segmentVideoId(endSegmentIndex + 1) === segmentVideoId(endSegmentIndex)) {
        //     ++endSegmentIndex
        // }

        this.highPosition = actualSegment(endSegmentIndex).endPosition

        log(`setHighLowPositionLimits [${this.lowPosition.toFixed(2)}...${this.highPosition.toFixed(2)}], index=${startSegmentIndex}...${endSegmentIndex}`)
    }

    async componentWillUnmount() {
        let { onDoneEditing } = this.props
        try {
            await this.saveNoteIfChangesExist(this.props)
        } catch(err) {
            systemError(err)
        }

        onDoneEditing()
    }

    public async saveNoteIfChangesExist(props: INoteLocationEditor) {
        log('saveNoteIfChangesExist')

        let { note } = this
        let { passage } = this.props.noteRoot
        let { noteVideo } = this
        if (!passage || !noteVideo) return

        let { newPosition, newStartPosition, newEndPosition } = this

        if (newPosition == undefined && newStartPosition == undefined && newEndPosition == undefined) return
        log(`saveNoteIfChangesExist values=${newStartPosition}/${newPosition}/${newEndPosition}`)

        await noteVideo.addNote(note)
        await note.setPositions(
            newPosition === undefined ? note.position : newPosition,
            newStartPosition == undefined ? note.startPosition : newStartPosition,
            newEndPosition == undefined ? note.endPosition : newEndPosition)
    }

    toTime(position: number, label?: string) {
        let time = this.segment!.positionToTime(position)
        //log(`toTime ${label}: ${position} => ${time.toFixed(2)}`)
        return time
    }

    render() {
        let { onDoneEditing } = this.props
        let { passage, note, useMobileLayout, playbackRate, setPlaybackRate, iAmInterpreter } = this.props.noteRoot
        let { noteVideo, currentVideos, segment, noteSetupComplete } = this
        log('render', noteVideo, currentVideos)
        
        if (!passage || !noteVideo || !currentVideos || !segment || !noteSetupComplete) { return null }

        // to force re-rendering of toolbar
        let { isPlaying } = this

        // Originally we restricted position editing to only the creator of the note.
        // but this leaves people confused as to why they can't adjust it when it is wrong.
        // let allowAdjustingPositions = username === note.creator

        let allowAdjustingPositions = iAmInterpreter

        return (
            <div className="note-location-editor-content">
                <div className='note-location-editor-video'>
                    <RangeVideoPlayer
                        className='note-location-editor-player'
                        ref={rvp => this.rangeVideoPlayer = rvp}
                        passage={passage}
                        video={noteVideo}
                        currentVideos={currentVideos}
                        startTime={this.toTime(this.startPosition, "startTime")}
                        endTime={this.toTime(this.endPosition, "endTime")}
                        playbackRate={playbackRate}
                        setPlaybackRate={setPlaybackRate}
                        onPlayingStatus={this.onPlayingStatus}
                        onTick={this.setCurrentTime}
                    />
                    <div className="video-timeline-area">
                        <VideoTimelinePlayButtons
                            isPlaying={this.isPlaying}
                            playAll={this.playAll}
                            pause={this.pause}
                        />
                        <div className="video-timeline">
                                <VideoTimeline
                                    setters={this.setters}
                                    domainStartPosition={this.domainStartPosition}
                                    domainEndPosition={this.domainEndPosition}
                                    adjustTime={this.adjustTime}
                                    enabled={!note!.resolved}
                                    allowAdjustingPositions={allowAdjustingPositions}
                                />
                                {!useMobileLayout && (
                                    <VideoTimelineAdjusters
                                        adjustBeginningMarker={delta => {
                                            this.setters.find(s => s.key === '0')!.
                                                setValue(this.startPosition + delta)
                                        } }
                                        adjustEndMarker={delta => {
                                            this.setters.find(s => s.key === '2')!.
                                                setValue(this.endPosition + delta)
                                        } }
                                        enabled={allowAdjustingPositions && !note!.resolved}
                                    />
                                )}
                            </div>
                    </div>
                </div>
                <div className="note-location-editor-close">
                    <PaneCloseButton
                        onClick={() => onDoneEditing()}
                        enabled={true}
                        tooltip={t`Close pane`}
                        className='sl-pane-close-button' />
                </div>
            </div>
        )
    }

    onPlayingStatus = (isPlaying: boolean) => {
        // log('onPlayingStatus', this.isPlaying, isPlaying)
        
        if (this.isPlaying && !isPlaying) {
            log('onPlayingStatus reset')
            // reset to start of citation plus a smidge so we can see cursor
            let position = this.startPosition + (this.domainEndPosition - this.domainStartPosition) / 60

            setTimeout(() => {
                this.setters[3].setValue(position) // after playing, reset to start of citation
            }, 200)
        }
        
        this.isPlaying = isPlaying
    }

    setCurrentTime = (time: number) => {
        let { passage } = this.props.noteRoot
        let { setters, noteVideo, segment } = this
        if (!passage || !noteVideo) return

        let position = segment?.timeToPosition(time) || 0
        setters[3].setValue(position)
        // log('setCurrentTime', time.toFixed(2), fx(position), fx(this.currentTime))
    }

    playAll = async () => {
        this.isPlaying = true
        let { rangeVideoPlayer } = this
        if (!rangeVideoPlayer) return

        let endTime = this.toTime(this.endPosition)
        let startTime = this.currentTime
        if (startTime < this.toTime(this.startPosition) || startTime > this.toTime(this.endPosition)) {
            endTime = this.toTime(this.domainEndPosition)
        } 
        log(`playAll ${fx(startTime)}..${fx(endTime)}`)

        await rangeVideoPlayer.playRange(startTime, endTime)
    }

    playRange = async () => {
        this.isPlaying = true

        let { rangeVideoPlayer, setters, segment } = this
        log('playRange', segment!.time, setters[0].value, setters[2].value)

        let startTime = segment!.positionToTime(setters[0].value)
        let endTime = segment!.positionToTime(setters[2].value)
        if (!rangeVideoPlayer) return

        await rangeVideoPlayer.playRange(startTime, endTime)
    }

    pause = () => {
        let { rangeVideoPlayer } = this
        rangeVideoPlayer && rangeVideoPlayer.stop()
    }

    adjustTime = (currentTime: number) => {
        this.currentTime = currentTime
        log('adjustTime', fx(currentTime))

        let { rangeVideoPlayer, setters } = this
        rangeVideoPlayer && rangeVideoPlayer.setCurrentTime(currentTime)
        setters[3].setValue(currentTime)
    }
}


// const rounded = (num: number, decimalPlaces: number) => parseFloat(num.toFixed(decimalPlaces))

interface IVideoTimelineAdjusters {
    adjustBeginningMarker: (delta: number) => void,
    adjustEndMarker: (delta: number) => void,
    enabled: boolean,
}

export function VideoTimelineAdjusters(props: IVideoTimelineAdjusters) {
    let { adjustBeginningMarker, adjustEndMarker, enabled } = props
    return (
        <div className="note-marker-time-adjusters">
            <AdjustNoteMarkerButtons enabled={enabled} adjustTime={adjustBeginningMarker} />
            <AdjustNoteMarkerButtons enabled={enabled} adjustTime={adjustEndMarker} />
        </div>
    )
}

export default observer(NoteLocationEditor)