
import { RefRange } from "../scrRefs/RefRange"
import API from "../models3/API"
import { MARBLEImages } from "./MARBLEImages"
import { VideoCache } from "../models3/VideoCache"
import { ProjectImages } from './ProjectImages'
import { fmt } from '../components/utils/Fmt'
import { delay } from "../components/utils/delay"

import _debug from "debug"; let log = _debug('sltt:ImageMetadata')

export class ImageDefinition {
    languageCode = ''
    title = ''
    description = ''
}

export class ImageMetadata {
    id = ''
    definitions: ImageDefinition[] = []
    project = ''    // Empty for MARBLE images
    shared = false  // Consultant has approved image for sharing
        
    path = ''       // e.g., "TESTPROJECT"
    fileName = ''   // e.g., "IMG001.jpg"
    private _sortKey = ''  // determines order images are shown to the user
    copyright = ''
    references: RefRange[] = []

    // The url of the thumbnail for MARBLE images, and the url for full-size image for
    // project images. For project images, you must make a call to the video cache
    // with this url to access the actual url of the image.
    thumbnail = ''
    
    isProjectImage = false

    // Create a sort key for image.
    // Storyboards come first, then other project images, finally non project images.
    // If the title of an image begins or ends with a number we treated it as one image
    // in a storyboard.
    setSortKey() {
        let { fileName, isProjectImage, definitions, title, project, references } = this
        if (definitions.length === 0) throw Error('Must set definitions before sortKey')
        if (!fileName) throw Error('Must set fileName before sortKey')

        let prefix = '|' // non project (i.e. MARBLE) images last
        let sequence = '@' + references[0].startRef + '@' + title // order MARBLE images by their reference/title

        if (isProjectImage) {
            prefix = '@' + project + '@' // project images first, ordered by project

            /**
             * If the title begins or ends with a number,
             * we treat the number as a sequence number for a series of storyboard images.
             * Example: 2 Paul in jail => '0002'.
             * These sort to the start because @000 < 0010001 (Genesis 1:1)
             */
            let match = /(\d+)(.*)(\d*)$/.exec(title)
            if (match && match[1]) {
                sequence = '@000' + match[2] + '@' + match[1].padStart(4, '0')
            } else if (match && match[3]) {
                sequence = '@000' + match[2] + '@' + match[3].padStart(4, '0')
            }
        }
        
        let _sortKey = prefix + sequence
        log('_sortKey', fmt({prefix, sequence, isProjectImage}))

        this._sortKey = _sortKey
    }

    get sortKey() {
        let { _sortKey } = this
        if (!_sortKey) throw Error('SortKey was not set')
        return _sortKey
    }

    getDefinition(language: string) {
        let { definitions } = this

        if (definitions.length === 0) return new ImageDefinition()

        let definition = definitions.find(def => def.languageCode === language)
        definition = definition ?? definitions.find(def => def.languageCode === 'en')
        definition = definition ?? definitions[0]

        return definition
    }

    /**
     * Use the title of the English definition as the title for the item.
     */
    get title() {
        return this.getDefinition('en').title.trim()
    }

    // Fetch picture data for image
    async fetchImageSrc() {
        if (!this.isProjectImage) {
            let { path, fileName } = this
            let blob = await MARBLEImages.fetchImage(path, fileName)
            let src = window.URL.createObjectURL(blob)
            return src
        }

        while (true) {
            let response = await VideoCache.queryVideoDownload(this.thumbnail)
            log('fetchImage', response)

            if (response.blob) {
                let src = window.URL.createObjectURL(response.blob)
                return src
            }

            await delay(500)
        }
    }

    // If any metadata item has changed for this image, save it

    update = (references: RefRange[], title: string, copyright: string, shared: boolean) => {
        log('update', fmt({references, title, copyright, shared}))

        this.references = references

        let definition = new ImageDefinition()
        definition.languageCode = 'en'
        definition.title = title
        this.definitions = [definition]

        this.copyright = copyright.trim()

        this.shared = shared

        this.setSortKey()
    }

    fallsInRanges(refRanges: RefRange[]) {
        return refRanges.some(refRange => refRange.overlaps(this.references))
    }
}