import React, { FC, useRef, useState } from 'react'
import { t } from 'ttag'

import { OkEditSegmentButton, CancelEditSegmentButton, DeleteButton } from '../utils/Buttons'
import { useOnClickOutside } from '../utils/Hooks'
import { ImageMetadata } from '../../resources/ImageMetadata'
import { Optional } from '../utils/Optional'
import { ApproveIcon, CopyrightIcon, ReferencesIcon, TitleIcon } from '../utils/Icons'
import { Switch } from '../utils/Switch'
import { confirmAlert } from '../utils/ConfirmAlert'
import { displayError, displayInfo } from '../utils/Errors'
import { ImagesRoot } from '../images/ImagesRoot'
import { ReferenceInput } from '../utils/ReferenceInput'
import { ProjectImages } from '../../resources/ProjectImages'

// setup image-metadata-input-invalid
// add save() to image

import _debug from "debug"; let log = _debug('sltt:ImageMetadataEditor')

interface IMetadataEditor {
    title: string,
    value: string,
    setValue: (value: string) => void,
    validate?: (value: string) => boolean,
}

const MetadataEditor: FC<IMetadataEditor> = ({ title, value, setValue, validate  }) => {
    let valid = validate ? validate(value) : true
    let className = 'image-metadata-input'
    if (!valid) { className += ' image-metadata-input-invalid' }

    return (
        <input type="text"
            className={className}
            onChange={element => setValue(element.target.value)}
            value={value}
            placeholder={title} />
    )
}

const confirmDeleteImage = (irt: ImagesRoot, image: ImageMetadata, close: (saved: boolean) => void) => {
    confirmAlert({
        title: t`Delete image?`,
        message: t`Are you sure you want to permanently delete this image?`,
        confirmLabel: t`Delete`,
        cancelLabel: t`Cancel`,
        onConfirm: async () => {
            try {
                await ProjectImages.deleteProjectImage(image)
                close(false) // must be false or we will recreate image
            } catch (error) {
                displayError(error)
            }
        },
        onCancel: () => { },
    })
}


interface IImageMetadataEditor {
    irt: ImagesRoot,
    close: (saved: boolean) => void,
    image: ImageMetadata,
    showDeleteButton: boolean,
}

let ImageMetadataEditor: FC<IImageMetadataEditor> = ({ irt, close, image, showDeleteButton }) => {
    let [refRanges, setRefRanges] = useState(image.references)
    let [title, setTitle] = useState(image.getDefinition('en').title)
    let [copyright, setCopyright] = useState(image.copyright)
    let [shared, setShared] = useState(image.shared)
    let [errored, setErrored] = useState(false)

    let { iAmConsultant } = irt.rt

    let tReferences = t`References`
    let tTitle = t`Title`
    let tCopyright = t`Copyright`
    
    return (
        <div className="image-metadata-editor">
            <div className="image-metadata-editor-box">
                <div className="image-metadata-editor-box-references">
                    <ReferencesIcon className='images-metadata-icon' tooltip={tReferences} />
                    <ReferenceInput
                        refInput={irt.rt}
                        refs={refRanges}
                        setRefs={setRefRanges}
                        errored={errored}
                        setErrored={setErrored}
                        referenceRequired={true}
                        includeGotoReferenceButton={false} />
                </div>
                <div>
                    <TitleIcon className='images-metadata-icon' tooltip={tTitle} />
                    <MetadataEditor
                        title={tTitle}
                        value={title}
                        setValue={setTitle} />
                </div>
                <div>
                    <CopyrightIcon className='images-metadata-icon' tooltip={tCopyright} />
                    <MetadataEditor
                        title={tCopyright}
                        value={copyright}
                        setValue={setCopyright} />
                </div>
                <Optional show={iAmConsultant}>
                    <Switch
                        className="image-approval"
                        value={shared}
                        setValue={shared => {
                            if (shared && !copyright.trim()) {
                                displayInfo(t`You must enter Copyright information before sharing image`)
                                return
                            }
                            setShared(shared)
                        }}
                        enabled={true}
                        tooltip={t`Approve sharing this image?`}>
                        <ApproveIcon tooltip={undefined} className='segment-pane-icon' />
                    </Switch>
                </Optional>
            </div>
            <div className="image-metadata-editor-buttons">
                <OkEditSegmentButton
                    enabled={!errored && refRanges.length > 0}
                    onClick={() => {
                        image.update(refRanges, title, copyright, shared)
                        close(true)
                    }} />
                <CancelEditSegmentButton
                    enabled={true}
                    onClick={() => close(false)} />
                {showDeleteButton && <DeleteButton
                    className='image-delete-button'
                    tooltip={t`Delete image`}
                    enabled={true}
                    onClick={() => confirmDeleteImage(irt, image, close)} />
                }
            </div>
        </div>
    )
}

export default ImageMetadataEditor
