// Allow user to select which SegmentGloss they want to display/edit

import React, { Component } from 'react'
import {observer} from 'mobx-react'

import _debug from "debug"; let log = _debug('sltt:SegmentGlossIdentitySelector') 

//!!! set editable


interface ISegmentGlossIdentitySelector {
    selected: string,
    identities: string[],
    onChange: (identity: string) => void,
}

class SegmentGlossIdentitySelector extends Component<ISegmentGlossIdentitySelector> {
    render() {
        let { selected, identities } = this.props

        return (
            <select className='custom-select'
                    value={selected}
                    onChange={this.onChange.bind(this)}>
                { identities.map(name => 
                    ( <option key={name} value={name}> { name } </option> ) ) 
            }
            </select>
        )
    }

    onChange(event: React.ChangeEvent<HTMLSelectElement>) {
        let selected = event.target.value
        this.props.onChange(selected)
    }
}

export default observer(SegmentGlossIdentitySelector)
