import React, { Component } from "react";
import { observer } from "mobx-react";
import Draggable, { DraggableData } from 'react-draggable'

import { PassageSegmentLabel, PassageSegment } from "../../models3/ProjectModels";

import './Segments.css'

// Label positions are measured from the top left corner of the label.
// That is why label.x <= 80, and label.y <= 89.
export const defaultLabels = [
    new PassageSegmentLabel(0, 0),
    new PassageSegmentLabel(80, 0),
    new PassageSegmentLabel(0, 89),
    new PassageSegmentLabel(80, 89)
]

interface ISegmentLabelsPosition {
    segmentLabelsDraft: PassageSegmentLabel[],
    setSegmentLabelsDraft: (labels: PassageSegmentLabel[]) => void,
    editingSegmentLabels: boolean,
    segment: PassageSegment,
    videoWidth: number,
}

class SegmentLabelsPosition extends Component<ISegmentLabelsPosition> {
    setLabelsPosition = (data: DraggableData, index: number) => {
        let { parentElement } = data.node
        if (parentElement) {
            let { labels } = this.props.segment
            let { segmentLabelsDraft, setSegmentLabelsDraft } = this.props

            let originalLabelX = labels[index]?.x ?? defaultLabels[index].x
            let originalLabelY = labels[index]?.y ?? defaultLabels[index].y

            let x = (data.x / parentElement.offsetWidth) * 100 + originalLabelX
            let y = (data.y / parentElement.offsetHeight) * 100 + originalLabelY
            let updatedLabels = segmentLabelsDraft.map(label => new PassageSegmentLabel(label.x, label.y, label.xText, label.yText, label.text))
            updatedLabels[index].x = x
            updatedLabels[index].y = y

            setSegmentLabelsDraft(updatedLabels)
        }
    }

    segmentLabelStyle = (label: PassageSegmentLabel) => {
        let { videoWidth } = this.props
        let { x, y } = label
        let style = {
            left: `${x}%`,
            top: `${y}%`,
            fontSize: `${Math.min(videoWidth / 35, 18)}px`,
        }

        return style
    }

    render() {
        let { segment, segmentLabelsDraft, editingSegmentLabels } = this.props
        let { setLabelsPosition, segmentLabelStyle } = this

        let labels = editingSegmentLabels ? segmentLabelsDraft : segment.labels
        let savedLabels = segment.labels

        return labels.map((label, i) => {
            let { text } = label

            // react-draggable uses CSS transforms to adjust the position of
            // draggable elements. We do not want to change the position of
            // draggable elements ourselves, but let react-draggable apply a
            // transform to the original position. Because we cannot uniquely
            // identify labels, we must assume that they always maintain the
            // same ordering.
            let savedLabel = savedLabels[i] || defaultLabels[i]

            if (text) {
                return editingSegmentLabels ? (
                    <Draggable
                        key={i}
                        bounds={'parent'}
                        defaultClassName='draggable-label'
                        defaultClassNameDragging='draggable-label-dragging'
                        onStop={(e, data) => setLabelsPosition(data, i)}
                    >
                        <div className='video-label' style={segmentLabelStyle(savedLabel)}>{text}</div>
                    </Draggable>
                ) : (
                    <div key={i} className='video-label' style={segmentLabelStyle(savedLabel)}>{text}</div>
                )
            }

            return <div key={i} />
        })
    }
}

export default observer(SegmentLabelsPosition)