// Because Quill does not appear to have typescript information I am not able
// to make typechecking work correctly in this module.
// When I try to just ignore certain lines VSC and tsc differ as to what lines are errors.
// @ts-nocheck

import { observer } from "mobx-react";
import React, { FC, useEffect, useRef, useState, useCallback, useMemo } from "react";
import { t } from 'ttag'
import './RichTextEditor.css'
import MagicUrl from './LinkFormatter'
import BlotFormatter from '@enzedonline/quill-blot-formatter2'
import ReactQuill, { Quill } from 'react-quill-new'
import 'quill/dist/quill.snow.css'


import { CancelButton, OKButton, PaneCloseButton, UndoButton } from "./Buttons"
import { isAllowedImage } from "../images/AllowedImages"
import { displayError, systemError, ErrorBoundary, sendBeacon } from "./Errors"
import { fmt } from "./Fmt"

/* Test scenarios

  * PassageDocuments

     - edit/save
     - edit/cancel
     - edit title only
     - edit on two different machines at same time
     - edit different tabs on different machines
     - edit and click away to segments tab ***
     - view/revert to earlier version with <<
     - delete document
     - lock document 
     - drag/drop (large) image
     - paste image
     - insert link
     - all formatting options

  * Project Preferences

 */

import _debug from "debug"; let log = _debug('sltt:EditableRichText')

Quill.register('modules/magicUrl', MagicUrl)
Quill.register('modules/blotFormatter', BlotFormatter)

type IEditableRichText = {
    savedText: string,
    save: (stringifiedState: string, shouldBeacon?: boolean) => void, // frequently has asynchronous operations
    synchronousBackup?: (stringifiedState: string) => void, // must be synchronous
    cancel: () => void,
    editorOpen: boolean,
    setEditorOpen: (value: boolean) => void,
    placeholder?: string,
    prefix: string, // prefix for css classes
    sizeLimit?: number,
    zoom?: number, // 1.0 is normal size
}

// Wrap editor with ErrorBoundary and unexpected dismount protection

export const EditableRichText: FC<IEditableRichText> = 
    ({ savedText, synchronousBackup, save, cancel, editorOpen, setEditorOpen, placeholder, prefix, sizeLimit, zoom }) => 
{
    log('EditableRichText render', fmt({ editorOpen, zoom }))

    const changedTextRef = useRef<string | undefined>(undefined)

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const doSave = changedTextRef.current !== undefined
            if (doSave) {
                const document = changedTextRef.current! // capture before save() clears it
                _save(true) // asynchronous may not complete before page unload
                log('beforeunload', fmt({ doSave, text: document.slice(0, 50) }))
                if (synchronousBackup) {
                    synchronousBackup(document)
                    // Might implement synchronousBackup like so for PassageDocuments:
                    // ```
                    // synchronousBackup={(_text: string) => {
                    //     const finalTitle = trimmedTitle(titleRef) || title
                    //     const trimmedText = _text.trim()
                    //     const stringified = safeStableStringify({ title: finalTitle, text: trimmedText })!
                    //     localStorage.setItem(`backup-pasDoc-${document._id}`, stringified)
                    //     localStorage.backupPasDocHistory = (localStorage.backupPasDocHistory || '') + `${document._id} `
                    //     log('synchronousBackup', stringified.slice(0, 50))
                    // }}
                    // ```
                    // Then in save() after successful, clear the backup
                    // ```
                    // localStorage.removeItem(`backup-pasDoc-${document._id}`) // no longer need backup
                    // localStorage.backupPasDocHistory = localStorage.backupPasDocHistory.replace(`${document._id} `, '')
                    // ```
                }

                // prompt user to Reload or cancel
                // NOTE: as far as I (EricP) can tell,
                // this blocks the page from continuing execution until user clicks something.
                // so the actual time we have to save() is limited before and after that dialog.
                // At least the user has the possibility of saying `cancel`
                // to allow the operation to complete all the way
                event.preventDefault()
                event.returnValue = '' // Essential: Triggers electron `win.webContents.on('will-prevent-unload'`
            }
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    function _save(shouldBeacon = false) {
        // If text has changed, use the changed text. Otherwise, use the saved, i.e. original, text.
        // We must call save() because the parent component may be doing other things in addition to saving the text
        // when the user clicks 'save'.
        // For example, The passage document editor has to save the title for the document if that has
        // has changed.
        const text = changedTextRef.current ?? savedText
        log('_save', text?.slice(0, 50))

        save(text, shouldBeacon)
        
        changedTextRef.current = undefined
        setEditorOpen(false)
    }

    function _cancel() {
        log('cancel')
        changedTextRef.current = undefined // ensure unmount does not save changes
        cancel()
        setEditorOpen(false)
    }

    // If we have unexpected unmount while editing, save changes
    useEffect(() => {
        return () => { 
            let doSave = changedTextRef.current !== undefined
            log('unmount', fmt({doSave, text: changedTextRef.current}))
            doSave && _save() 
        }
    }, [])

    return (
        <div className={`${prefix}__outer-wrapper`}>
            <div className={`${prefix}__inner-wrapper`} >
                <ErrorBoundary
                    fallbackAction={() => {
                        _save()
                        systemError('Rich text editor crashed')
                    }}
                >
                    { editorOpen && <RichTextEditor
                        {...{ text: savedText, placeholder, prefix, save: _save, cancel: _cancel, zoom }}
                        onChange={(text) => { 
                            if (sizeLimit !== undefined && text.length > sizeLimit) {
                                displayError(t`Too large. Remove any large images.`)
                                return
                            }
                            changedTextRef.current = text 
                        }} />}
                    {!editorOpen && <RichTextViewer
                        {...{ text: savedText, prefix, zoom }} />}
                </ErrorBoundary>
            </div>
        </div>
    )
}

function convertToValidHTML(str: string) {
    return str.replace(/(\n|\r)/gi, '<br>')
        .replace(/\t/gi, '&nbsp;')
}

function getFileAsDataUrl(file: File): Promise<string> {
    let reader = new FileReader()
    return new Promise((resolve, reject) => {
        reader.onload = (e: any) => resolve(e.target.result)
        reader.onerror = e => reject(reader.error)
        reader.readAsDataURL(file)
    })
}

const insertImage = (url: string, reactQuillRef: React.RefObject<ReactQuill>) => {
    let quill = reactQuillRef.current?.getEditor()
    if (quill) {
        let range = quill.getSelection(true)
        quill.insertEmbed(range.index, 'image', url, 'user')
        quill.setSelection(range.index + 2, 0, 'user')
    }
}

type IRichTextViewer = {
    text: string,
    prefix: string,
    zoom?: number, // 1.0 is normal size
}

const RichTextViewer: FC<IRichTextViewer> = ({ text, prefix, zoom }) => {
    zoom = zoom ?? 1

    return (
        <div className='rich-text-viewer' style={{fontSize: `${100*zoom}%`}}>
            <ReactQuill
                readOnly={true}
                modules={({ toolbar: false })}
                value={convertToValidHTML(text)}
                className={`${prefix}__editing-area rich-text__editing-area`}
                bounds='.rich-text-editor'
            />
        </div>
    )
}

type IRichTextEditor = {
    text: string,
    onChange: (text: string) => void,
    save: () => void,
    cancel: () => void,
    placeholder?: string,
    prefix: string,
    zoom?: number, // 1.0 is normal size
}

const RichTextEditor: FC<IRichTextEditor> = ({ text, onChange, save, cancel, placeholder, prefix, zoom }) => {
    let editingAreaClassName = `${prefix}__editing-area__editing rich-text__editing-area` 
    
    let reactQuillRef = useRef<ReactQuill>(null)
    
    // We need access to the save function inside a memoized function.
    // The memoized function cannot access the latest version of save by itself,
    // but it can access save if it's stored in a ref.
    let saveRef = useRef(save)

    useEffect(() => { reactQuillRef.current?.focus() }, [])

    let toolbarId = useMemo(() => `toolbar-${Date.now()}`, [])

    // Quill configuration needs to be memoized. If any part of the configuration
    // changes identity, react-quill will rebuild the text editor.
    // https://github.com/zenoamaro/react-quill/issues/596
    const modules = useMemo(() => {
        return ({
            toolbar: {
                container: `#${toolbarId}`,
                handlers: {
                    undo: function(this: { quill?: Quill }) {
                        try {
                            let { quill } = this
                            if (quill) {
                                quill.getModule('history').undo()
                            }
                        } catch (error) {
                            saveRef.current?.()
                            systemError(error)
                        }
                    },
                    // image: function() {
                    //     setImagePaletteOpen(true)
                    // },
                    link: function(this: { quill?: Quill }) {
                        try {
                            let { quill } = this
                            if (quill) {
                                let range = quill.getSelection(true)
                                let contents = quill.getContents(range.index, range.length)
                                let attributes = contents.map((op: any) => op.attributes)
                                let wholeSelectionIsLink = attributes.every((s: any) => s !== undefined && s.link !== undefined)
                                if (range.length === 0) {
                                    let linkText = '🔗'
                                    //!!!
                                    let link = 'https://s3.amazonaws.com/sltt-hosting-prd/index.html'
                                    quill.insertText(range.index, linkText, 'user')
                                    quill.setSelection(range.index, linkText.length, 'user')
                                    quill.format('link', link, 'user')
                                    quill.setSelection(range.index, 0, 'user')
                                } else if (wholeSelectionIsLink) {
                                    // Remove link formatting
                                    quill.format('link', false)
                                } else {
                                    let link = quill.getText(range.index, range.length)
                                    quill.format('link', link, 'user')
                                    quill.setSelection(range.index, 0, 'user')
                                }
                            }
                        } catch (error) {
                            saveRef.current?.()
                            systemError(error)
                        }
                    }
                }
            },
            keyboard: {
                bindings: {
                    saveAndClose: {
                        key: 'Enter',
                        shiftKey: true,
                        handler: function() {
                            saveRef.current?.()
                            save()
                        },
                    }
                }
            },
            magicUrl: true,
            blotFormatter: {
                align: {
                    allowAligning: false,
                },
                resize: {
                    allowResizing: true,
                },
                delete: {
                    allowKeyboardDelete: true,
                },
                image: {
                    allowAltTitleEdit: false,
                    allowCompressor: false /* default false */,
                }
            }
        })
   }, [])

    return (
        <div className='rich-text-editor' style={{ fontSize: `${100 * zoom}%` }}>
            <TextEditorToolbar {...{ save, cancel, id: toolbarId }} />
            <ReactQuill
                readOnly={false}
                defaultValue={convertToValidHTML(text)}
                ref={reactQuillRef}
                onChange={onChange}
                modules={modules}
                placeholder={placeholder}
                className={editingAreaClassName}
                bounds='.rich-text-editor'
            />
        </div>
    )
}

type TextEditorToolbarProps = {
    save: () => void,
    cancel: () => void,
    id: string,
}

const TextEditorToolbar: FC<TextEditorToolbarProps> = ({ save, cancel, id }) => {
    let cmd = navigator.platform.startsWith('Mac') ? '<Command>' : '<Ctrl>'
    return (
        <div id={id} className='text-editor-toolbar'>
            <div className='text-editor-toolbar-left'>
                <button className='ql-bold' title={t`Bold. Shortcut: ${cmd} B`}></button>
                <button className='ql-italic' title={t`Italic. Shortcut: ${cmd} I`}></button>
                <button className='ql-underline' title={t`Underline. Shortcut: ${cmd} U`}></button>
                <button className='ql-list' title={t`Ordered list`} value='ordered'></button>
                <button className='ql-list' title={t`Unordered list`} value='bullet'></button>
                <button className='ql-indent' title={t`Outdent`} value='-1'></button>
                <button className='ql-indent' title={t`Indent`} value='+1'></button>
                <button className='ql-link' title={t`Link/unlink`}></button>
                {/* <button className='ql-image' title={t`Insert image`}></button> */}
                <select className='ql-color' title={t`Font color`}></select>
                <select className='ql-background' title={t`Text background color`}></select>
                <button className='ql-clean' title={t`Clear formatting`}></button>
                <UndoButton
                    onClick={() => {}}
                    buttonClassName='ql-undo'
                    className='text-editor-toolbar-button'
                    tooltip={t`Undo. Shortcut: ${cmd} Z`}
                    enabled={true}
                />
            </div>
            <div className='text-editor-toolbar-right'>
                <OKButton
                    enabled={true}
                    onClick={() => { save() }}
                    buttonClassName='text-editor-save-button'
                    className='text-editor-toolbar-button text-editor-toolbar-right-button'
                    tooltip={t`Save edits and close. Shortcut: <Shift><Enter>`}
                />
                <CancelButton
                    enabled={true}
                    onClick={() => { cancel() }}
                    className='text-editor-cancel-button text-editor-toolbar-button text-editor-toolbar-right-button'
                    tooltip={t`Cancel edits`}
                />
            </div>
        </div>
    )
}
