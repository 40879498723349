import { observer } from "mobx-react"
import React, { useState } from "react"
import { t } from "ttag"
import { ProjectTerm, PassageVideo, Passage } from "../../models3/ProjectModels"
import { IRoot } from "../../models3/Root"
import { LexMeaning } from "../../scrRefs/Lemmas"
import { ResumableVideoRecorder } from "../notes/ResumableVideoRecorder"
import { CancelButton, OKButton, PlayButton, RecordButton } from "../utils/Buttons"
import { displayError } from "../utils/Errors"
import { fmt, s } from "../utils/Fmt"
import { PencilIcon } from "../utils/Icons"

import _debug from "debug"; let log = _debug('sltt:KeyTermGlosses') 

export interface IKeyTermRecording {
    baseUrl: string,
    passage: Passage,
    video: PassageVideo,
}

export async function startRecordingGloss(
    rt: IRoot,
    gloss: string,
    setRecording: (x: IKeyTermRecording | null) => void) 
{
    let { project } = rt

    let glossaryPassage = await ProjectTerm.getPassage(gloss, project)

    let video = glossaryPassage.createVideo(project.name)
    let baseUrl = `${project.name}/${video._id}`
    log('startRecordingGloss', fmt({ glossaryPassage, video, baseUrl }))
    setRecording({ baseUrl, passage: glossaryPassage, video })
}

export async function finishRecordingGloss(
    recording: IKeyTermRecording | null,
    setRecording: (x: IKeyTermRecording | null) => void,
    err: any, 
    blobsCount: number,
    duration: number) 
{
    if (err) {
        log('finishRecording', fmt({ err }))
        setRecording(null)
        displayError(err)
        return
    }

    if (!recording) return
    let { passage, video, baseUrl } = recording

    log('finishRecording', fmt({ passage, video, baseUrl, blobsCount, duration }))
    video.url = baseUrl + '-' + blobsCount
    video.duration = duration

    let _video = await passage.addVideoWithDefaultSegment(video)
    log('finishRecordingGloss', s(_video.dbg(null, 's')))
    
    setRecording(null)
}

interface IKeyTermGlossRecorder {
    rt: IRoot,
    recording: IKeyTermRecording | null,
    setRecording: (x: IKeyTermRecording | null) => void,
}

export const KeyTermGlossRecorder = ({ rt, recording, setRecording }: IKeyTermGlossRecorder) => 
{
    if (!recording) return null

    return (
        <div className="p-n-video">
            <ResumableVideoRecorder
                cancel={() => setRecording(null)}
                url={recording.baseUrl}
                projectName={rt.project.name}
                save={(err, blobsCount, url, duration) => finishRecordingGloss(recording, setRecording, err, blobsCount!, duration!)} />
        </div>
    )
}

interface IKeyTermGlossesTextEditor {
    rt: IRoot,
    projectTerm: ProjectTerm | null,
    meaning: LexMeaning,
    closeEditor: () => void,
}

/*
 * Edit gloss for projec term.
 * If the user enters a gloss and the term does not exist yet, create the term.
 */
export const KeyTermGlossesTextEditor = observer((
    { rt, projectTerm, meaning, closeEditor }: IKeyTermGlossesTextEditor) => 
{
    const [value, setValue] = useState(projectTerm?.glosses ?? '')

    let { project } = rt

    async function save() {
        let _value = value.trim()
        if (projectTerm === null) {
            if (_value === '') return // don't create term if no gloss

            projectTerm = project.createProjectTerm(meaning.lexicalLink)
            await project.addProjectTerm(projectTerm)
        }

        await projectTerm.setGlosses(value, rt.project)
    }

    return (
        <>
            <div className='er-gloss-text-editor-wrapper'>
                <textarea
                    autoFocus
                    value={value}
                    className='er-gloss-text-editor'
                    rows={1}
                    onKeyDown={(e: React.KeyboardEvent) => {
                        if (e.key === 'Enter') { save(); closeEditor() }
                        if (e.key === 'Escape') { closeEditor() }
                    }}
                    onChange={e => setValue(e.target.value)}
                />
                <span>{`Separate glosses with a semicolon (;)`}</span>
            </div>
            <OKButton enabled={true}
                onClick={() => { save().catch(displayError); closeEditor() }}
                buttonClassName=''
                className='er-glosses-ok-button'
                tooltip={`Save`} />
            <CancelButton
                enabled={true}
                onClick={() => closeEditor()}
                className='er-glosses-cancel-button'
                tooltip={`Cancel`} />
        </>
    )
})

interface IKeyTermGloss {
    rt: IRoot,
    gloss: string,
    term: ProjectTerm | null,
    setSignPlaying: (signVideo: PassageVideo) => void,
    setRecording: (recording: IKeyTermRecording | null) => void,
    setGlossShowing: (glossText: string) => void,
}

const KeyTermGloss =
    ({ rt, gloss, term, setSignPlaying, setRecording, setGlossShowing }: IKeyTermGloss) => 
{
    let { iAmConsultant } = rt

    let video: PassageVideo | null = null
    if (term) {
        let passage = term.getPassageIfExists(gloss, rt.project)
        if (passage) {
            video = passage.getDefaultVideo(null)
        }
    }

    // Remember the _id of the gloss being dragged
    function onDragStart(e: React.DragEvent<HTMLDivElement>) {
        log('onDragStart')
        e.dataTransfer.setData('text/plain', gloss)
    }

    return (
        <span>
            <span className='er-gloss-text' 
                    onClick={() => setGlossShowing(gloss)}
                    draggable {...{ onDragStart }}>
                { gloss }
            </span>
            {(!video && iAmConsultant)  && <RecordButton
                enabled={true}
                onClick={() => startRecordingGloss(rt, gloss, setRecording)}
                className='er-record-sense'
                tooltip={/* translator: important */ t`Record sign` + ': ' + gloss}
                selectionPresent={false}
            />}
            { video && <PlayButton
                enabled={true}
                onClick={() => {setSignPlaying(video!) }}
                className='er-play-sense'
                tooltip={/* translator: important */ t`Play sign` + ': ' + gloss}
                selectionPresent={false} />}
        </span>
    )
}

type IKeyTermGlosses = {
    rt: IRoot,
    label?: string,
    term: ProjectTerm | null,
    meaning: LexMeaning,
    setSignPlaying: (signVideo: PassageVideo) => void,
    setRecording: (recording: IKeyTermRecording | null) => void,
    setGlossShowing: (glossText: string) => void,
}

export const KeyTermGlosses = 
    observer(({ rt, label, term, meaning, setSignPlaying, setRecording, setGlossShowing }: IKeyTermGlosses) => 
{
    const [editing, setEditing] = useState(false)
    const { iAmConsultant } = rt

    if (editing) {
        return (
            <div className="keyterms-glosses-editor">
                <KeyTermGlossesTextEditor
                    {...{ rt, projectTerm: term, meaning, closeEditor: () => setEditing(false) }} />
            </div>
        )
    }

    const glosses = term?.getGlosses() ?? []
    const hasGlosses = glosses.length > 0

    return (
        <div className="">
            {label && <span className="keyterms-gloss-label">{label}: </span>}
            {!hasGlosses && iAmConsultant && <span className="keyterms-gloss-empty">???</span>}
            {glosses.map((gloss, i) => (
                <span key={i}>
                    {(i > 0) ? <span>;&nbsp;&nbsp;&nbsp;</span> : ''}
                    <KeyTermGloss {...{ rt, gloss, term, setSignPlaying, setRecording, setGlossShowing }} />
                </span>
            ))}
            {iAmConsultant && 
                <button className="keyterms-gloss-edit-button" onClick={() => setEditing(true)}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<PencilIcon tooltip={t`Edit gloss`} className={''} />
                </button>}
            {(!hasGlosses && iAmConsultant) && <RecordButton
                enabled={false}
                onClick={() => {}}
                className='er-record-sense'
                tooltip={/* translator: important */ t`You must edit the ??? field before you can record a sign`}
                selectionPresent={false}
            />}
        </div>
    )
})