import React, { FC, useState, useContext } from 'react'
import { observer } from 'mobx-react'
import { RootContext } from '../app/RootContext'
import { CopyrightNoticeEditor, IProjectIAmAdmin, LogoChanger } from '../projectSettings/ProjectPublicationPreferences'
import { ToggleEditorConfig } from '../utils/ToggleEditor'
import { ProjectTRLIssue, getProjectTRLIssueTooltip } from '../utils/TRL'
import { ToggleBoxEditorButtons } from './ToggleBoxEditorButtons'

interface ITRLIssueEditor {
    projectTrlIssue: ProjectTRLIssue
    toggleTRLIssue: () => void
}
export const TRLIssueEditor: FC<ITRLIssueEditor> = observer((
    { projectTrlIssue, toggleTRLIssue }
) => {
    const rt = useContext(RootContext)
    if (!rt || projectTrlIssue === ProjectTRLIssue.None) {
        return <div />
    }

    function onFinishedEditing() {
        toggleTRLIssue()
    }
    const trlIssueToolTip = getProjectTRLIssueTooltip(projectTrlIssue)
    const { iAmAdmin, project } = rt
    return <div>
        {projectTrlIssue && <div className="trl-issue-message">{trlIssueToolTip}</div>}
        <div className="trl-issue-resolver">
            {projectTrlIssue === ProjectTRLIssue.MissingCopyrightNotice &&
                <ConfiguredCopyrightNoticeEditor {...{ project, iAmAdmin, onFinishedEditing }} />}
            {projectTrlIssue === ProjectTRLIssue.MissingLogo &&
                <LogoChanger {...{ project, iAmAdmin }} />}
        </div>
    </div>
})
interface IConfiguredEditor extends IProjectIAmAdmin {
    onFinishedEditing: () => void
}
const ConfiguredCopyrightNoticeEditor: FC<IConfiguredEditor> = observer((
    { project, iAmAdmin, onFinishedEditing }
) => {
    const [copyrightNotice, setCopyrightNotice] = useState(project.copyrightNotice)
    const confirmChanges = async () => {
        await project.setCopyrightNotice(copyrightNotice)
        onFinishedEditing()
    }

    const cancelChanges = () => {
        onFinishedEditing()
    }

    return <ToggleEditorConfig
        hideEditorControls={true}
        initialStates={{ isEditing: true }}
        value={copyrightNotice}
        onChangedValue={setCopyrightNotice}
    >
        <CopyrightNoticeEditor {...{ project, iAmAdmin }} />
        <ToggleBoxEditorButtons {...{ confirmChanges, cancelChanges }} />
    </ToggleEditorConfig>
})
