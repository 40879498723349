
import { Root } from '../models3/Root'
import { Passage, PassageVideo, PassageVideoReference, PassageSegment } from '../models3/ProjectModels'
import { displayError, displayInfo, systemError } from '../components/utils/Errors'
import _ from 'underscore'
import { RefRange } from '../scrRefs/RefRange'

// allow drag drop of video to main video area, if no passage selected, create one

// add unit test?

// https://developer.mozilla.org/en-US/docs/Web/API/Document/documentElement
// An Element, Comment, Text are kinds of Node.
// document.documentElement is the root element.


import _debug from "debug"; let log = _debug('sltt:finalcutpro') 

interface IFCPLabel {
    text: string,
    startTime: number,   // start time in clip in seconds
    endTime: number,     // ending time in clip in seconds
    prototype: string,   // 'v' verse number, '1'..'4' segment label
}

// Verse Referebce label
class FCPReferenceLabel implements IFCPLabel {
    public text: string
    public startTime: number
    public endTime: number
    prototype = 'v'

    constructor(rt: Root, reference: PassageVideoReference, endTime: number) 
    {
        this.text = rt.displayableReferences(reference.references)
        this.startTime = reference.time
        this.endTime = endTime
    }
}

class FCPSegmentLabel implements IFCPLabel {
    public text: string
    public startTime: number
    public endTime: number
    public prototype: string

    constructor(segment: PassageSegment, labelIndex: number) {
        this.text = segment.labels[labelIndex].text
        this.startTime = segment.time
        this.endTime = segment.time + segment.endPosition - segment.position
        
        // PassageSegmentLabels are stored bottom left, bottom right, top left, top right
        let labelId = ['1', '2', '3', '4']
        this.prototype = labelId[labelIndex]
    }
}

async function getText(file: File): Promise <string> {
    let reader = new FileReader()
        return new Promise(resolve => {
        reader.onload = (e: any) => resolve(e.target.result)
        reader.readAsText(file)
    })
}

function downloadText(text: string, fileName: string) {
    let blob = new Blob([text], { type: 'text/plain' })
    const blobUrl = URL.createObjectURL(blob)
    let name = 'temp.fcpxml'

    const link = document.createElement("a")

    link.href = blobUrl
    link.download = fileName.split('.')[0] + ' #2.fcpxml'

    document.body.appendChild(link)

    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    )

    document.body.removeChild(link)
}

// Search for unique element by tag name
function elementByName(root: Element, name: string) {
    let elements = [...root.getElementsByTagName(name)]
    if (elements.length !== 1) {
        debugger
        throw Error()
    }

    return elements[0]
}

// Return text of first text-style element
function titleText(element: Element) {
    let tss = [...element.getElementsByTagName('text-style')]
    return tss[0]?.textContent?.trim() || ''
}

function removeElement(element: Element) {
    let parent = element.parentElement
    parent?.removeChild(element)
}

// Remove the text-style-def from this title since we only one to insert it into
// the output document once.
function removeTextStyleDef(title: Element) {
    let defs = [...title.getElementsByTagName('text-style-def')]
    defs.forEach(def => removeElement(def))
}

function escapeHTML(text: string) {
    text = text.replace(/"/g, "&quot;")
    text = text.replace(/</g, "&lt;")
    text = text.replace(/>/g, "&gt;")
    text = text.replace(/&/g, "&amp;")
    return text
}

/**
 * Insert a label into fcpxml document
 */
function insertLabel(
    clip: Element,  // clip to append title to
    label: IFCPLabel, // content to be inserted
    prototypes: Map<string, Element>, // title elements used as prototypes
    ticksQuantization: number,
    ticksPerSecond: number,
    lane: number)
{
    let { text, startTime, endTime, prototype } = label
    
    if (!text.trim()) return // label has not text to insert

    let title = prototypes.get(prototype)
    if (!title) {
        log(`insertLabel: no title prototype found[${prototype}]`)
        return
    }

    let title2 = title.cloneNode(true) as Element // make a copy of prototype title

    // We only want to insert the style definition once so we remove it after we
    // have used it the first time.
    removeTextStyleDef(title) 

    title2.setAttribute('name', escapeHTML(`${prototype} - ${text}`))
    title2.setAttribute('lane', lane.toFixed())
    title2.setAttribute('offset', fcpTime(startTime, ticksQuantization, ticksPerSecond))
    title2.setAttribute('duration', fcpTime(endTime-startTime, ticksQuantization, ticksPerSecond))

    // insert new text
    let textStyleElement = [...title2.getElementsByTagName('text-style')][0]
    if (!textStyleElement) {
        throw Error(`could not find text-style`)
    }

    textStyleElement.innerHTML = escapeHTML(text)

    clip.appendChild(title2)
    clip.appendChild(new Text('\n'))
}

/**
 * Time offsets and durations values in fcpxml are very picky.
 * Take the format attribute of the sequence element.
 * Find the format element in the resource element that has the same id.
 * Extract the frameDuration attribute value, e.g. "1001/24000s".
 * In this case all times must be expressed in ticks of 1/24000s.
 * However times must be a multiple of the first number, e.g. 1001.
 * I think this is equivalent of saying that times must lie on frame boundaries.
 * For example the 11th frame is at time "11011/24000s".
 * FCP will complain when importing xml files if times do not meet these requirements.
 * Simon Cozens did the research on this.
 * 
 * Return ticksQuantization (numerator) and ticksPerSecond (denominator) numbers.
 */
function getFrameDuration(doc: Document) {
    //  <project
    //    <sequence format="r1"
    //  <resources
    //    <format id="r1" frameDuration="1001/24000s"

    let project = elementByName(doc.documentElement, 'project')
    let sequence = elementByName(project, 'sequence')
    let formatId = sequence.getAttribute('format')
    if (!formatId) {
        debugger
        throw Error(`No formatId found for project`)
    }

    let resources = elementByName(doc.documentElement, 'resources')
    let formats = [...resources.getElementsByTagName('format')]
    
    let format = _.find(formats, f => f.getAttribute('id') === formatId)
    if (!format) {
        debugger
        throw Error(`No format for id=${format}`)
    }
    
    let parts = format.getAttribute('frameDuration')?.split('/')
    
    return {
        ticksQuantization: parseInt(parts![0]),
        ticksPerSecond: parseInt(parts![1].slice(0,-1)),
    }
}

// Return quantitized time in ticks in FCP format.
// e.g. "21021/24000s"
function fcpTime(time: number, ticksQuantization: number, ticksPerSecond: number) {
    let ticks = time * ticksPerSecond
    ticks = Math.round(ticks / ticksQuantization) * ticksQuantization
    return `${ticks}/${ticksPerSecond}s`
}

// Find video clip in xml document.
function getClip(doc: Document) {
    let project = elementByName(doc.documentElement, 'project')
    let clips = [...project.getElementsByTagName('asset-clip')]
    if (clips.length === 0) {
        throw Error('No video clip present')
    }
    if (clips.length > 1) {
        throw Error('More than one video clip present')
    }

    return clips[0]
}

/**
 * Remove all the titles in the document.
 * Store them in a map based on text.
 * 'v' - verse numbers
 * '1' - first label listed for segment in SLTT
 * '2, '3', '4' 
 */
function getPrototypes(doc: Document) {
    let titles = new Map<string, Element>()

    for (let title of [...doc.getElementsByTagName('title')]) {
        titles.set(titleText(title), title)
        removeElement(title) // remove prototype titles
    }

    return titles
}

function addMessage(messages: string[], labels: IFCPLabel[], prototypes: Map<string, Element>) {
    if (labels.length === 0) return

    let { prototype } = labels[0]
    let count = labels.length
    let labelType = prototype === 'v' ? 'verses' : `Label ${prototype}'s`

    if (prototypes.get(prototype)) {
        messages.push(`${count} ${labelType} inserted.`)
    } else {
        messages.push(`No title with text "${prototype}" found; ${labelType}'s NOT inserted.`)
    }
}

function addComment(doc: Document, messages: string[], rt: Root, passage: Passage, passageVideo: PassageVideo) {
    let _video = JSON.stringify(passageVideo.dbg(passage, 's'), null, 4)
    let comment = new Comment([...messages, _video].join('\n'))
    doc.documentElement.appendChild(comment)
}

// Transform the fcpxml document by inserting titles in the clip corresponding to each
// verse reference and segment label.
export async function transformDoc(rt: Root, passage: Passage, passageVideo: PassageVideo, doc: Document) {
    let clip = getClip(doc)
    let { ticksQuantization, ticksPerSecond } = getFrameDuration(doc)
    let prototypes = getPrototypes(doc)
    let messages: string[] = []

    let lane = 1
    let references = passageVideo.getVisibleReferences(passage)
    let labels = references.map((reference, i) => 
        new FCPReferenceLabel(rt, reference, 
            i+1 < references.length ? references[i+1].time : passageVideo.duration))
    addMessage(messages, labels, prototypes)

    labels.forEach(label => insertLabel(clip, label, prototypes, ticksQuantization, ticksPerSecond, lane))
    labels.length && ++lane // If any verse references, move to next lane

    let segments = passageVideo.visibleSegments(passage)
    for (let i=0; i<4; ++i) {
        let labels2 = segments
            .filter(segment => segment.labels[i]?.text)
            .map(segment => new FCPSegmentLabel(segment, i))
        addMessage(messages, labels2, prototypes)

        labels2.forEach(label => insertLabel(clip, label, prototypes, ticksQuantization, ticksPerSecond, lane))
        labels2.length && ++lane // If any segment label inserted in this lane, move to next lane
    }

    // update project name
    let project = elementByName(doc.documentElement, 'project')
    project.setAttribute('name', project.getAttribute('name') + ' #2')

    addComment(doc, messages, rt, passage, passageVideo)

    return messages
}

/*
 * Take an exported fcpxml file and insert verse numbers and headings.
 * Download resulting file for reimportation to fcp.
 */
export async function downloadFcpxml(rt: Root, passage: Passage, passageVideo: PassageVideo, file: File) {
    log('downloadFcpxml', file.name)
    
    try {
        let xmlText = await getText(file)

        let domParser = new DOMParser()
        let doc = domParser.parseFromString(xmlText, "text/xml")

        let messages = await transformDoc(rt, passage, passageVideo, doc)
        
        let serializer = new XMLSerializer()
        let xmlOutput = serializer.serializeToString(doc)

        downloadText(xmlOutput, file.name)
        
        if (messages.length) displayInfo(messages.join('\n'))
    } catch (error) {
        displayError(error)
        return        
    }
}

