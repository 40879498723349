// This component controls the recording of videos.
// It displays the video while the recording is happening.
// It pushs video data blobs to videoUploader.
// It calls videoUploader.onRecordingDone when recording is complete.

import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, makeObservable } from 'mobx';
import { t } from 'ttag'

import "./Video.css"
import { VideoUploader } from './VideoUploader'
import { displayError } from '../utils/Errors'
import API from '../../models3/API'
import { fmt } from '../utils/Fmt'

import _debug from "debug"; let log = _debug('sltt:VideoRecorder') 
const intest = (localStorage.getItem('intest') === 'true')

interface IVideoRecorder {
    videoUploader: VideoUploader,
        // The video uploader is passed to us from our parent as a tricky (?!) way to allow
        // the parent to create a new item of an appropriate type when the recording is complete.

    usePauseAndResume?: boolean,
    setRecordingState?: (state: RecordingState) => void,
}

export type RecordingState = 'NOT_INITIALIZED' | 'INITIALIZED' | 'RECORDING' | 'PAUSED' | 'STOPPED'

@observer
export default class VideoRecorder extends Component<IVideoRecorder> {
    private vc: any
    private mediaRecorder: MediaRecorder | null = null
    @observable recordingState: RecordingState = 'NOT_INITIALIZED'
    cancelled = false
    mediaStream: MediaStream | null = null

    constructor(props: IVideoRecorder) {
        super(props)

        makeObservable(this);

        this.errorStop = this.errorStop.bind(this)
        this.stop = this.stop.bind(this)
        this.setupVideoAndAudio = this.setupVideoAndAudio.bind(this)
        this.startRecording = this.startRecording.bind(this)
        this.pause = this.pause.bind(this)
        this.resume = this.resume.bind(this)
    }

    componentDidMount() {
        let _record = async () => {
            // Wait to get the reference to the video element
            while (!this.vc) {
                await new Promise(resolve => setTimeout(resolve, 200))
            }
            
            try {
                await this.setupVideoAndAudio()
                if (!this.props.usePauseAndResume) {
                    await this.startRecording()
                }
            } catch (err) {
                this.errorStop(err)
            }
        }

        setTimeout(_record , 1000)
    }

    componentWillUnmount() {
        let { mediaRecorder, mediaStream } = this
        
        if (mediaRecorder) {
            let { state } = mediaRecorder

            if (state === 'recording' || state === 'paused') {
                this.cancel()
            }
        }

        // Ensure that recording is stopped (turning off red light in toolbar)
        // when recorder component unmounts.
        // Normally this would have already happened in the stop() routine.
        try {
            mediaStream && mediaStream.getTracks().forEach((track) => {
                track.stop()
            })
        } catch (err) {
        }
    }

    render() {
        let { usePauseAndResume } = this.props
        let { recordingState, pause, resume, stop, startRecording } = this
        let startRecordingMessage = /* translator: important */ t`<Space>: Start recording.`
        let continueRecordingMessage = /* translator: important */ t`<Space>: Continue recording.`
        let finishRecordingMessage = /* translator: important */ t`<Enter>: Finish recording.`
        let cancelRecordingMessage = /* translator: important */ t`<Esc>: Cancel recording.`

        if (usePauseAndResume) {
            return (
                <div className='video-recording-area'>
                    { recordingState === 'INITIALIZED' && (
                        <div className='video-recording-area-message'>
                            <div className='note-recorder-message'>
                                {startRecordingMessage}
                                <br />
                                {cancelRecordingMessage}
                            </div>
                        </div>
                    )}
                    { recordingState === 'PAUSED' && (
                        <div className='video-recording-area-message'>
                            <div className='note-recorder-message'>
                                {continueRecordingMessage}
                                <br />
                                {finishRecordingMessage}
                                <br />
                                {cancelRecordingMessage}
                            </div>
                        </div>
                    )}
                    { recordingState === 'STOPPED' && (
                        <div className='video-recording-area-message'>
                            <div className='note-recorder-message'>{t`Uploading...`}</div>
                        </div>
                    )}
                    <video
                        className="video-recorder video-border"
                        ref={(vc) => { this.vc = vc }}
                        autoPlay={true}
                        onClick={e => {
                            if (recordingState === 'INITIALIZED') {
                                startRecording()
                            } else if (recordingState === 'RECORDING') {
                                pause()
                            } else if (recordingState === 'PAUSED') {
                                resume()
                            }
                        }}
                    />
                </div>
            )
        }

        if (recordingState === 'STOPPED') {
            return (<div className='video-message'>{t`Uploading...`}</div>)
        }

        return (
            <video
                className="video-recorder video-border"
                ref={(vc) => { this.vc = vc }}
                autoPlay={true}
                onClick={stop}
            />
        )
    }

    setRecordingState = (state: RecordingState) => {
        log('setRecordingState', state)
        this.recordingState = state

        // Following line has ? because not all callers want to be informed
        this.props.setRecordingState?.(state)
    }

    async setupVideoAndAudio()
    {        
        let { videoUploader } = this.props
        this.setRecordingState('NOT_INITIALIZED')

        try {
            // let frameRate = API.idealFrameRate(videoUploader.projectName)

            log("initializing media stream")
            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: videoUploader.recordAudio,
                video: {
                    //width: { ideal: 1280 },
                    height: { ideal: 480 },
                    //frameRate: { ideal: frameRate },
                }
            })

            this.vc.srcObject = this.mediaStream
            if (videoUploader.recordAudio) {
                this.vc.volume = 0.0   // prevent feedback
            }
            this.setRecordingState('INITIALIZED')
        } catch (err) {
            this.errorStop(err)
        }
    }

    async startRecording() {
        let { videoUploader } = this.props

        try {
            // let frameRate = API.idealFrameRate(videoUploader.projectName)

            if (!this.mediaStream) {
                log('### startRecording failed, no mediaStream')
                return
            }

            log("startRecording")

            let mediaRecorder = new MediaRecorder(this.mediaStream)
            this.mediaRecorder = mediaRecorder

            mediaRecorder.ondataavailable = this.dataAvailable.bind(this)
            
            mediaRecorder.start(10000)
            this.setRecordingState('RECORDING')
        } catch (err) {
            this.errorStop(err)
        }
    }

    // This is an event handler for mediaRecorder
    dataAvailable(event: any) {
        let { cancelled, mediaRecorder } = this

        // If the mediaRecorder is not active then there will be no more blobs
        let lastBlob = this.mediaRecorder?.state === 'inactive'

        log('dataAvailable', fmt({
            state: mediaRecorder?.state,
            lastBlob,
            cancelled,
        }))

        if (cancelled) return

        this.props.videoUploader.pushVideoBlob(event.data, lastBlob).catch(this.errorStop)
    }

    errorStop(err: any) {
        let { videoUploader } = this.props
        log(`errorStop`, err, videoUploader)

        if (videoUploader && !this.cancelled) videoUploader.onRecordingDone(err)
        this.stop()
    }

    // Only called when user has explictily requeted that video recording be permanently
    // stopped.
    // Should not be called when recording has been paused.
    // May be invoked by this control or externally.
    stop() {
        let { mediaRecorder, mediaStream } = this
        if (!mediaRecorder) { log('### stop failed, mediaRecorder not set'); return }
        let { state } = mediaRecorder

        try {
            // state is inactive/recording/paused
            if (state === 'recording' || state === 'paused') {
                mediaRecorder.stop()
            }

            mediaStream && mediaStream.getTracks()[0].stop()
            this.setRecordingState('STOPPED')
        } catch (err) {
            if (!this.cancelled) {
                this.props.videoUploader.onRecordingDone(err)
            }
        }
    }

    pause() {
        log('pause')
        let { mediaRecorder } = this

        if (mediaRecorder === null) {
            log('### mediaRecorder not set, PAUSE action skiped')
            return
        }

        try {
            this.setRecordingState('PAUSED')
            mediaRecorder.pause()
        } catch (err) {
            console.log(err)
        }
    }

    resume() {
        log('resume')
        let { mediaRecorder } = this

        if (mediaRecorder === null) {
            log('### mediaRecorder not set, RESUME action skiped')
            return
        }

        try {
            mediaRecorder.resume()
            this.setRecordingState('RECORDING')
        } catch (err) {
            console.log('### resume failed', err)
        }
    }

    cancel() {
        log('cancel')
        this.cancelled = true
        this.stop()
    }
}