import { useContext } from "react"
import { RootContext } from "../app/RootContext"

// Hook to get permissions for the current user, e.g.
// const { iAmRoot, iAmDeveloper, iAmAdmin, iAmTranslator, iAmConsultant, iAmInterpreter, iAmApprover } = useLoginInfo()
export function useLoginInfo() {
    const rt = useContext(RootContext)
    const { iAmRoot, iAmDeveloper, iAmAdmin, iAmTranslator, 
        iAmConsultant, iAmInterpreter, iAmApprover, useMobileLayout } = rt!
    return { iAmRoot, iAmDeveloper, iAmAdmin, iAmTranslator, 
        iAmConsultant, iAmInterpreter, iAmApprover, useMobileLayout }
}
