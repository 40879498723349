// Play video based on events signaled on Root.
// rt.passageVideo is the video that is played.
// Handles stepping through passages on "play all passages" event

import React, { Component } from 'react'
import { observer } from 'mobx-react'

import './Video.css'
import { Passage, PassageSegment, PassageSegmentLabel, PassageVideo } from '../../models3/ProjectModels'
import { displayError } from '../utils/Errors'
import VideoPlayer from './VideoPlayer'
import SegmentLabelsPosition from '../segments/SegmentLabelsPosition'
import { ViewableVideoCollection } from './ViewableVideoCollection'
import { observable, makeObservable } from 'mobx';
import EventEmitter from 'events'
import { fmt } from '../utils/Fmt'

import _debug from "debug"; let log = _debug('sltt:DraftVideoPlayer')

let f = (x?:number) => x?.toFixed(2)

interface DraftVideoPlayerProps {
    draftVideoPlayer: IDraftVideoPlayer,
    setContainerHeight: (height: number) => void,
}

interface IDraftVideoPlayer extends EventEmitter {
    stopRoot: () => void,
    passage: Passage | null,
    draftVideoSegment: PassageSegment | null,
    passageSegment: PassageSegment | null,
    passageVideo: PassageVideo | null,
    currentVideos: ViewableVideoCollection,
    playing: boolean,
    setPlaying: (playing: boolean) => void,
    setCurrentTime: (currentTime: number) => void,
    resetCurrentTime: (newTime: number) => void,
    currentTime: number,
    playbackRate: number,
    setPlaybackRate: (rate: number) => void,
    selectionStartTime: number,
    selectionEndTime: number,
    username: string,
    selectionPresent: () => boolean,
    videoPlaybackKeydownEnabled: boolean,
}

@observer
export default class DraftVideoPlayer extends Component<DraftVideoPlayerProps> {
    videoPlayer: VideoPlayer | null = null

    resetTime = 0 // If resetTimeRequested, reset time to this point when play ends
    resetTimeRequested = false
    @observable videoWidth = 0
    draftVideoPlayerRef = React.createRef<HTMLDivElement>()

    constructor(props: DraftVideoPlayerProps) {
        super(props)
        makeObservable(this);
        let { draftVideoPlayer } = this.props

        this.play = this.play.bind(this)
        this.stopAndDoNotReset = this.stopAndDoNotReset.bind(this)
        this.stopAndReset = this.stopAndReset.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)
        this.onPlayingStatus = this.onPlayingStatus.bind(this)
        this.setVideoHeight = this.setVideoHeight.bind(this)

        draftVideoPlayer.addListener('play', this.play)
        draftVideoPlayer.addListener('pause', this.stopAndDoNotReset)
        draftVideoPlayer.addListener('stop', this.stopAndReset)
        draftVideoPlayer.addListener('setCurrentTime', this.setCurrentTime)
        draftVideoPlayer.addListener('setPassageVideo', this.setPassageVideo)

        this.setPassageVideo()
    }

    componentWillUnmount() {
        let { draftVideoPlayer } = this.props

        draftVideoPlayer.removeListener('play', this.play)
        draftVideoPlayer.removeListener('pause', this.stopAndDoNotReset)
        draftVideoPlayer.removeListener('stop', this.stopAndReset)
        draftVideoPlayer.removeListener('setCurrentTime', this.setCurrentTime)
        draftVideoPlayer.removeListener('setPassageVideo', this.setPassageVideo)
    }

    componentDidMount() {
        this.setVideoHeight()
    }

    componentDidUpdate() {
        this.setVideoHeight()
    }

    setVideoHeight() {
        let { current } = this.draftVideoPlayerRef
        let { setContainerHeight } = this.props
        if (current) {
            let { width } = current.getBoundingClientRect()
            let height = width * 0.5625   // 16:9 aspect ratio
            setContainerHeight(height)
        }
    }

    // When root.passageVideo changes setup to display the newly
    // chosen value.
    setPassageVideo = () => {
        let { draftVideoPlayer } = this.props
        let { passageVideo, currentVideos } = draftVideoPlayer
        if (!passageVideo) return

        log('setPassageVideo', passageVideo?._id)
        
        let vp = this.getVideoPlayer()
        vp?.stop()

        currentVideos
            .waitUntilDownloaded()
            .then(() => {
                log('passageVideoHasChanged downloaded')
            })
    }

    render() {
        let { draftVideoPlayer } = this.props
        let { passage, currentVideos, passageVideo, setCurrentTime, playbackRate,
                draftVideoSegment: segment, videoPlaybackKeydownEnabled } = draftVideoPlayer

        if (!passageVideo || !passage) { return null }
        
        log(`render ${passageVideo._id}`)

        // Do not allow editing of segment labels
        let segmentLabelsDraft: PassageSegmentLabel[] = []
        let editingSegmentLabels = false
        let setSegmentLabelsDraft = () => {}
        let { videoWidth } = this

        return (
            <div className='draft-video-player'>
                <div className='video-player-container' ref={this.draftVideoPlayerRef}>
                    <VideoPlayer
                        ref={videoPlayer => this.videoPlayer = videoPlayer}
                        passage={passage}
                        video={passageVideo}
                        playbackRate={playbackRate}
                        vvc={currentVideos}
                        onTick={currentTime => setCurrentTime(currentTime)}
                        onPlayingStatus={this.onPlayingStatus}
                        play={this.play}
                        stop={this.stopAndDoNotReset}
                        setVideoWidth={width => this.videoWidth = width}
                        disablePlay={!videoPlaybackKeydownEnabled}
                    >
                        {segment && (
                            <SegmentLabelsPosition {...{
                                segmentLabelsDraft, editingSegmentLabels,
                                setSegmentLabelsDraft, segment, videoWidth
                            }} />)}
                    </VideoPlayer>
                </div>
                <div className='video-player-controls' style={{ flexShrink: 0, flexBasis: '33px' }} />
            </div>
        )
    }

    onPlayingStatus(playing: boolean) {
        let { draftVideoPlayer } = this.props
        let { setPlaying, resetCurrentTime, stopRoot } = draftVideoPlayer
        if (playing === draftVideoPlayer.playing) return

        log('onPlayingStatus', playing)
        setPlaying(playing)

        // If user requested to reset time at end of play, do so
        let { resetTime, resetTimeRequested } = this
        if (!playing && resetTimeRequested) {
            log(`onPlayingStatus resetTimeRequested=${resetTimeRequested}, resetTime=${f(resetTime)}, playing=${playing}`)
            resetCurrentTime(resetTime)
            this.resetTimeRequested = false
            stopRoot()
        }
    }

    // startTime = undefined, means play from current position.
    // endTime = undefined, means play through until end.
    // resetTime = undefined, means when you reach endTime stop there
    //             otherwise go to resetTime.
    // If a selection is present in the timeline and the caller has
    // not specified a startTime, play the selection.

    play(startTime?: number, endTime?: number, resetTime?: number) {
        let { draftVideoPlayer } = this.props
        let { currentTime, selectionStartTime, selectionEndTime, passageVideo } = draftVideoPlayer

        log(`play3draft ${f(startTime)}/${f(endTime)} [resetTime=${f(resetTime)}] [${f(selectionStartTime)}..${f(selectionEndTime)}]`)

        if (startTime === undefined && draftVideoPlayer.selectionPresent()) {
            startTime = selectionStartTime
            endTime = selectionEndTime
            resetTime = startTime
        }

        // If we have a start time that is close to or beyond our best guess for
        // the duration of this video, then assume the user really wants to play
        // from time 0.
        const _startTime = startTime ?? currentTime
        if (passageVideo) {
            const duration = passageVideo.computedDuration
            if (duration > 1 && _startTime + .1 > duration) {
                log('close to end of video, reset to play at start', fmt({ startTime, duration }))
                startTime = 0
            }
        }
        
        startTime = startTime ?? currentTime
        this.resetTimeRequested = (resetTime !== undefined)
        this.resetTime = resetTime ?? 0

        let vp = this.getVideoPlayer()
        vp?.play(startTime, endTime)
            .then(() => this.addViewedBy())
            .catch(displayError)
    }

    async addViewedBy() {
        let { draftVideoPlayer } = this.props
        let { username, passageVideo } = draftVideoPlayer
        
        await passageVideo?.addViewedBy(username)
    }

    stopAndDoNotReset() {
        log("[pause event] stop and don't reset")
        this.resetTimeRequested = false
        let vp = this.getVideoPlayer()
        vp?.stop()
    }

    stopAndReset() {
        log('[stop event] stop and reset')
        this.resetTimeRequested = true
        let vp = this.getVideoPlayer()
        vp?.stop()
    }

    // Invoked externally based on event
    // Sets the currently active video as well as the current time in that video
    setCurrentTime(newTime: number) {
        log('setCurrentTime', newTime)

        let vp = this.getVideoPlayer()
        return vp?.setCurrentTime(newTime) ?? false
    }

    getVideoPlayer() {
        let { videoPlayer } = this
        if (!videoPlayer) log('### no videoPlayer present')
        return videoPlayer
    }
}
