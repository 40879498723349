import React, { Component } from "react";
import { t } from "ttag";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";

import { PassageVideo, PassageSegment } from "../../models3/ProjectModels";
import VideoDownloading from "../utils/VideoDownloading";
import { Root } from "../../models3/Root";
import SegmentViewerTimeline from "./SegmentViewerTimeline";
import { PaneCloseButton, PlayButton, PauseButton } from "../utils/Buttons";

interface IOldSegmentViewer {
    rt: Root,
    originalVideo: PassageVideo,
    originalSegment: PassageSegment,
    video: PassageVideo,
    segment: PassageSegment,
    onDone: () => void,
}

class OldSegmentViewer extends Component<IOldSegmentViewer> {    
    @observable src = ''
    @observable vc: HTMLVideoElement | null = null
    @observable currentTime = 0
    @observable isPlaying = false
    private timerId: NodeJS.Timeout | null = null

    constructor(props: IOldSegmentViewer) {
        super(props)

        makeObservable(this);

        this.onClick = this.onClick.bind(this)
        this.play = this.play.bind(this)
        this.stop = this.stop.bind(this)
        this.timeUpdate = this.timeUpdate.bind(this)
        this.setCurrentTime = this.setCurrentTime.bind(this)
    }

    componentWillUnmount() {
        this.stopUpdater()
    }
    
    render() {
        let { video, segment, onDone, rt, originalSegment, originalVideo } = this.props
        let { dateFormatter } = rt

        let { url, creator } = video
        let { src, onClick, currentTime, setCurrentTime, isPlaying, play, stop } = this

        let start = segment.position
        let end = segment.endPosition
        let actualSrc = `${src}#t=${start},${end}`

        let index = 0
        if (video._id !== originalVideo._id) {
            index = originalSegment.videoPatchHistory.findIndex(e => e === video._id)
            index += 1  // Original video = 0, first patch = 1, etc.
        }

        let suffix = index > 0 ? `-${index}` : ''

        let visibleDateString = video.displayedCreationDate(dateFormatter)
        let videoName = `${visibleDateString}${suffix}`
        return (
            <div className='segment-dialog'>
                <div className='segment-dialog-heading'>{videoName}</div>
                <div className='old-segment-viewer-content'>
                    <div className='old-segment-viewer'>
                        {src ? (
                            <>
                                <video
                                    className='old-segment-viewer-video'
                                    src={actualSrc}
                                    ref={vc => this.vc = vc}
                                    autoPlay={true}
                                    onPlay={e => {
                                        this.isPlaying = true
                                        this.startUpdater()
                                    }}
                                    onError={e => {}}
                                    onEnded={e => {}}
                                    onPause={e => {
                                        this.isPlaying = false
                                        this.stopUpdater()
                                    }}
                                    onClick={onClick}
                                    onCanPlayThrough={e => {}}
                                />
                                <div className='old-segment-viewer-timeline-area'>
                                    {isPlaying ? (
                                        <PauseButton
                                            enabled={true}
                                            onClick={stop}
                                            tooltip={t`Pause.`}
                                            className='old-segment-viewer-pause-button'
                                        />
                                    ) : (
                                        <PlayButton
                                            enabled={true}
                                            selectionPresent={false}
                                            onClick={play}
                                            className='old-segment-viewer-play-button'
                                            tooltip={t`Play`}
                                        />
                                    )}
                                    <SegmentViewerTimeline
                                        domainStart={start}
                                        domainEnd={end}
                                        position={currentTime}
                                        updatePosition={setCurrentTime}
                                    />
                                </div>
                            </>
                        ) : (
                            <VideoDownloading
                                className='media-placeholder'
                                videoUrl={url}
                                creator={creator}
                                fontSizePt={18}
                                onEnded={blob => {
                                    if (!src) {
                                        this.src = window.URL.createObjectURL(blob)
                                    }
                                }}
                            />
                        )}
                    </div>
                    <div className='old-segment-viewer-close'>
                        <PaneCloseButton 
                            onClick={onDone}
                            enabled={true}
                            className=''
                            tooltip={t`Close pane`} />
                    </div>
                </div>
            </div>
        )
    }

    setCurrentTime(time: number) {
        let { vc } = this
        this.stop()
        if (vc) {
            vc.currentTime = time
            this.currentTime = time
        }
    }

    onClick() {
        let { vc } = this
        if (!vc) {
            this.stop()
            return
        }

        if (!vc.paused) {
            this.stop()
        } else {
            this.play()
        }
    }

    play() {
        let { vc } = this

        this.stop()
        if (!vc) {
            return
        }

        let { segment } = this.props
        let start = segment.position
        let end = segment.endPosition
        if (this.closeToEnd(vc.currentTime, end, 0.05)) {
            this.currentTime = start
            vc.currentTime = start   
        }

        let playbackRateString = localStorage.getItem('videoPlaybackRate')
        let playbackRate = playbackRateString ? parseFloat(playbackRateString) : 1.0
        vc.playbackRate = playbackRate

        vc.play()
    }

    stop() {
        let { vc } = this
        
        if (!vc) {
            return
        }
        if (!vc.paused) {
            vc.pause()
        }
    }

    timeUpdate() {
        let { segment } = this.props
        let { vc } = this
        if (!vc) {
            this.stop()
            return
        }

        let end = segment.endPosition
        if (this.closeToEnd(vc.currentTime, end, 0.05)) {
            this.stop()
        } else {
            this.currentTime = vc.currentTime
        }
    }

    closeToEnd(currentTime: number, end: number, limit: number) {
        return this.closeEnough(currentTime, end, limit) || currentTime > end
    }

    closeEnough = (a: number, b: number, limit: number) => Math.abs(a - b) < limit 

    private startUpdater() {
        this.stopUpdater()
        this.timerId = setInterval(this.timeUpdate, 33)
    }

    private stopUpdater() {
        if (this.timerId) {
            clearInterval(this.timerId)
            this.timerId = null
        }
    }
}

export default observer(OldSegmentViewer)