
export function normalizeUsername(username: string) {
    username = username ?? '' // just in case an undefined somehow gets passed
    username = username.replace(/ /g, '')
    username = username.toLowerCase()
    username = username.replace(/^mailto:/, '')  // e.g. "mailto:bob@gmail.com"
    username = username.replace(/^.*</, '') // e.g. "Bob Jones <bob@gmail.com>"
    username = username.replace(/>,?$/, '') // e.g. "Bob Jones <bob@gmail.com>,"

    return username
}
