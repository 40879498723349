import { Passage, PassageVideo, PassageSegment } from "../../models3/ProjectModels"
import { observable, makeObservable } from 'mobx';
import { VideoCache } from "../../models3/VideoCache"
import { delay } from "../utils/delay"

import _debug from "debug"; let log = _debug('sltt:ViewableVideoCollection')

export interface ViewableVideo {
    video: PassageVideo,
    src: string,
    canPlayThrough: boolean,
}

export class ViewableVideoCollection {
    public viewableVideos: ViewableVideo[] = []
    public creator = ''
    @observable public downloadingMessage = "..."
    @observable public downloaded = false
    messages: string[] = []

    constructor() {
        makeObservable(this);
        this.downloadViewableVideo = this.downloadViewableVideo.bind(this)
        this.downloadVideo = this.downloadVideo.bind(this)
        this.download = this.download.bind(this)
    }
    
    setup(passage: Passage | null, passageVideo: PassageVideo | null) {
        log('setup', passageVideo && passageVideo._id)

        this.downloadingMessage = "..."
        this.viewableVideos = []
        if (!passage || !passageVideo) {
            log('!!!setup aborted')
            return
        }

        this.creator = passageVideo.creator

        let patchVideos = passageVideo.segments
            .map(segment => segment.patchVideo(passage))
            .filter(video => video)

        this.viewableVideos = [passageVideo, ...patchVideos]
            .map(video => { return {video, src: ''} as ViewableVideo })

        log('setup count', this.viewableVideos.length)
    }

    // Download main video and all patches
    // When download complete downloadingMessage will be ''
    async download() {
        log('download')
        this.downloaded = false

        this.messages = Array(this.viewableVideos.length).fill('downloading')

        let promises = this.viewableVideos
            .map((vv: ViewableVideo, i: number) => this.downloadViewableVideo(vv, i))
        Promise.all(promises)
    }

    async waitUntilDownloaded() {
        while (true) {
            if (this.downloaded) return
            await delay(500)
        }
    }

    // Download one video.
    downloadViewableVideo = async (vv: ViewableVideo, i: number) => {
        log(`downloadViewableVideo ${vv.video._id}`)

        // Streaming is not ready for general use
        let useStreaming = false
        if (useStreaming) {
            // Use Media source extensions. You need an initialization segment and media
            // segments to feed to a source buffer. Ffmpeg can transcode an mp4 file so
            // that the initialization segment is at the beginning of the video, and the
            // rest of the video is stored as individually decodable fragments
        } else {
            this.downloadVideo(vv, i)
        }
    }

    /** Download all the blobs of a video. Along the way, update the download
     * status of the video. When the video is downloaded completely, create a URL
     * for it. */
    async downloadVideo(vv: ViewableVideo, i: number) {
        const timeout = (ms: number) => new Promise(res => setTimeout(res, ms))
        let _id = vv.video._id
        while (true) {
            // break if the list of videos to be downloaded has changed
            let { viewableVideos } = this
            if (i >= viewableVideos.length) break
            vv = viewableVideos[i]
            if (vv.video._id != _id) break

            if (vv.video.url) {
                let response = await VideoCache.queryVideoDownload(vv.video.url)
                if (response.blob) { // got blob from cache, save it and break
                    log(`downloadViewableVideo success ${vv.video._id}`)
                    vv.src = window.URL.createObjectURL(response.blob)
                    this.messages[i] = ''
                    this.setDownloadingMessage(i, '')

                    if (this.allSrcsPresent) {
                        log('download complete', this.viewableVideos.map(vv => vv.src))
                        this.downloaded = true
                    }

                    break
                }

                this.setDownloadingMessage(i, response.message)
            } else {
                log('waiting for url')
            }

            await timeout(1000)
        }
    }

    get allSrcsPresent() {
        return this.viewableVideos.every(vv => vv.src != '')
    }

    setDownloadingMessage(i: number, message: string) {
        this.messages[i] = message
        let messages = this.messages.filter(m => m)
        
        if (messages.length === 0) {
            this.downloadingMessage = ''
        } else if (messages.length === 1) {
            this.downloadingMessage = messages[0]
        } else {
            this.downloadingMessage = `${messages[0]} (+${messages.length-1})`
        }
    }

    async getBlob(video: PassageVideo) {
        let src = this.viewableVideos.find(vv => vv.video._id === video._id)?.src
        if (src === undefined) { throw new Error('Video not downloaded from VideoCache yet') }

        let response = await fetch(src)
        let blob = await response.blob()
        return blob
    }

    // find(src: string) {
    //     return this.viewableVideos.find(vv => vv.src === src)
    // }

    // undownloadedVideos() {
    //     let { viewableVideos } = this
    //     let videos = viewableVideos.filter(v => !v.src).map(v => v.video)
    //     return videos
    // }

    // setSrc(_id: string, src: string) {
    //     let { viewableVideos } = this
    //     let i = viewableVideos.findIndex(vv => vv.video._id === _id)
    //     if (i < 0) throw Error('setSrc: no viewable video found')
    //     viewableVideos[i].src = src

    //     if (viewableVideos.every(v => v.src)) {
    //         this.downloadingMessage = ""
    //     }
    // }

    dbg() {
        return this.viewableVideos.map(vv => { { return { src: vv.src, video: vv.video._id }}})
    }

}

