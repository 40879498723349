// Information to show uses for all the tour location for the main window.

import React from 'react'

import { Root } from '../../models3/Root'
// import TourVideo from './TourVideoDisplay'
import { t, jt } from 'ttag'
import { RootConsumer } from '../app/RootContext'

let _rt: (Root | null) = null

export const setTourRt = function (rt: Root) {
    _rt = rt
}

export const getTourRt = function () {
    return _rt
}

const boldStartsAt = <RootConsumer>{rt => <strong>{t`Starts At`}</strong>}</RootConsumer>

export const tourSteps: any[] = [
    // Commenting out tourSteps for now because I don't think the tour give enough information
    // to use most features and I don't want to ask localizers to translate if we are
    // not going to use this
    /*
    {
        selector: '.edit-project-settings-button',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                    {t`Click here to edit project settings. This is where you can edit
                    member information and specify book names for references.`}
                    <TourVideo stepName='edit-project-settings-button' />
                </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.edit-portions-button',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Projects are divided into groups of videos called "portions".
                        Portions have a name like "Luke" or "The Prodigal Son".
                        Click to add portions or change the names of portions.
                        You must create at least one portion before you can add a videos.`}
                        <TourVideo stepName='edit-portions-button' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.video-camera-button',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Click here to return to main project editing screen after editing portions or members.`}
                        <TourVideo stepName='video-camera-button' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.portion-selector',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Select the portion to work with.`}
                        <TourVideo stepName='portion-selector' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.passage-adder',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Each portion is divided into "Passages".
                        A passage is a single video. Click to add a new passage to this portion.
                        Give the passage a name like "James 2" or "Older Brother".
                        You must do this before you can add video to the passage.`}
                        <TourVideo stepName='passage-adder' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.passage-button',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Click to select this passage.
                        All commands (play, record, etc.) apply to the selected passage.
                        The name of the passage will appear in bold if there has been any video.`}
                        <TourVideo stepName='' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.passage-button',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`You can drag a video file from your desktop and drop it here to add a new video draft to this passage.`}
                        <TourVideo stepName='passage-button' />
                    </div>
                )}
            </RootConsumer>
        ),
        position: 'bottom',
    },

    {
        selector: '.tour-passage-handle',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Click and drag this handle to reorder this passage in the portion.`}
                        <TourVideo stepName='tour-passage-handle' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.passage-delete-button',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Click to delete this passage. If the passage has any videos present you will be asked to confirm the deletion.`}
                        <TourVideo stepName='passage-delete-button' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-play-button',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Play the currently selected passage.`}
                        <TourVideo stepName='sl-play-button' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-record-button',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Record a new draft of the currently selected passage.`}
                        <TourVideo stepName='sl-record-button' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-create-note-button',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Add a note to the passage at the current position. 
                        The note may be recorded with your webcam or typed.`}
                        <TourVideo stepName='sl-create-note-button' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.note-bar',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Notes are shown in this box. 
                        Each note is represented by an icon at the note's position in the video timeline.
                        Click the icon to view the note or add additional video or written comments to the note.
                        Multiple people can add comments to a note.`}
                        <TourVideo stepName='note-bar' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.passage-video-selector',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`This shows the dates for all video drafts of this passage.
                        Select which draft you wish to view.
                        By default we show the most recent draft of the video.`}
                        <TourVideo stepName='passage-video-selector' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.delete-video-button',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Click the trashcan icon to delete the currently selected video draft.`}
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.video-rate-input-slider',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Select the playback speed for the video. Move the selector upwards for faster playback.`}
                        <TourVideo stepName='video-rate-input-slider' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-adjust-current-time-buttons',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Adjust the current position in the video. Moves forward and backward by one frame or one second.`}
                        <TourVideo stepName='sl-adjust-current-time-buttons' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.video-positionbar',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`The cursor in this box shows the position in the video. 
                        Click on the line or drag the cursor to adjust the video position.`}
                        <TourVideo stepName='video-positionbar' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-create-passage-segment-svg',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Click to create a new segment at the current position in the video timeline.
                        A light blue bar is shown to mark the beginning of the new segment.`}
                        <TourVideo stepName='create-passage-segment' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.segment-selector',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Use the arrows to select a segment OR
                        click in the video timeline to select the corresponding
                        segment.`}
                        <TourVideo stepName='' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-edit-segment-labels-button',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Click this to add, remove, or change labels for this segment.
                        You will see the following dialog ...`}
                        <TourVideo stepName='' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.segment-labels-top-left',
        position: 'left',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Enter the labels for the corners of the video.`}
                        <TourVideo stepName='' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-ok-edit-segment-labels-button',
        position: 'left',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Click here to save your label changes.`}
                        <TourVideo stepName='' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-edit-segment-position-button',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {jt`Click to adjust the ${boldStartsAt} time for this segment.`}
                        <TourVideo stepName='' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    {
        selector: '.sl-play-segment-button',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`Play the video for just this segment.
                        Keyboard shortcut: Command-Space.`}
                        <TourVideo stepName='' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    // {
    //     selector: '.sl-record-segment-button',
    //     position: 'bottom',
    //     content: (<div>Record a new video for this segment. 
    //                    If you like the new video, you can patch the new video into the passage. 
    //                    You can adjust the start/stop times
    //                    to make the patch fit smoothly.<TourVideo stepName='' /></div>)
    // },

    {
        selector: '.sl-dbs-play-button',
        position: 'bottom',
        content: (
            <RootConsumer>
                {rt => (
                    <div>
                        {t`View this segment in another published
                        sign language Bible, e.g. ASLV.
                        If more than one published Bible contains this segment,
                        you can choose which you wish to view.`}
                        <TourVideo stepName='' />
                    </div>
                )}
            </RootConsumer>
        )
    },

    // {
    //     selector: '.sl-play-previous-draft-segment-svg',
    //     position: 'bottom',
    //     content: (<div>
    //                   Play the video for this segment from the PREVIOUS DRAFT.
    //                   This allows you to compare the previous draft of this segment to the current draft.
    //                   By default we play the same segment from the immediately previous draft
    //                   but you can chose an earlier draft.
    //                   Keyboard shortcut: Option-Space.
    //                   <TourVideo stepName='' /></div>)
    // },

    // {
    //     selector: '.segment-caption-heading',
    //     position: 'left',
    //     content: (<div>
    //         A project may optionally enter a caption for this segment.
    //         Some projects use this to enter a back translation of the segment.
    //                  <TourVideo stepName='' /></div>)
    // },

    // {
    //     selector: '.sl-edit-segment-caption-button',
    //     position: 'left',
    //     content: (<div>
    //         Click this to add or edit a caption for this segment.
    //                  <TourVideo stepName='' /></div>)
    // },

    // {
    //     selector: '.sl-dictate-segment-caption-button',
    //     position: 'left',
    //     content: (<div>
    //         Click this to play the segment and transcribe what the interpreter dictates.
    //                  <TourVideo stepName='' /></div>)
    // },
    */

]